import axios from "axios";

const state = {
    events: [],
    items: [],
    sport: {},
    setting:{},
    title:'',
    category:'',
    options:{
        page: 1,
        limit: 10
    },
    criteria:{},
    list: [],
    counts: 0,
    showDetail: false,
    eventDetail: {}
};

const getters = {
    getEvents(state,getters){
        return state.events;
    },
    getEventsList(state,getters){
        return state.list;
    },
    getEventsCriteria(state,getters){
        return state.criteria;
    },
    getOptions(state, getters) {
        return state.options;
    },
    getCounts(state, getters) {
        return state.counts;
    },
    getItems(state,getters){
        return state.items
    },
    getSetting(state,getters){
        return state.setting
    },
    getCategory(state,getters){
        return state.category
    },
    getTitle(state,getters){
        return state.title
    },
    getEventDetail(state, getters) {
        return state.eventDetail;
    },
    getShowDetail(state, getters) {
        return state.showDetail;
    },

};

const actions = {

    async resetEvents (context){
        context.commit('resetEvents');
    },

    async resetEventsList(context){
        context.commit('resetEventsList');
    },

    async fetchStatsSnapshot(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}events/snapshopt-stats`;

        return await axios.get(`${url}`,  {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                console.log(res)
                return {response:res.data}

            }).catch((error) => {
                return {response:"ko"}
            });
    },
    async fetchEventsSnapshot(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}events/snapshopt-events`;

        return await axios.post(`${url}`, {'sport': payload.data.sport,tournament:payload.data.tournament,category:payload.data.category,type:payload.data.type}, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                return {response:"ok"}

            }).catch((error) => {
                return {response:"ko"}
            });
    },
    async findEvent (context, payload) {
        let url = `${context.rootGetters['settings/getUrl']}events/find`;

       return  await axios.post(`${url}`, {'search':payload.search,'lang':payload.lang},{'headers': { 'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`} })
            .then((res) =>{

                return res.data.events;
            }).catch((error) => {
                console.log(error);
            });
    },

    async updateStatusEvent (context, payload) {
        let url = `${context.rootGetters['settings/getUrl']}events/update-status-event`;

       return  await axios.post(`${url}`, {'id':payload.id,'active':payload.status},{'headers': { 'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`} })
            .then((res) =>{

                return res.data.events;
            }).catch((error) => {
                console.log(error);
            });
    },
    async updateOddsEvent (context, payload) {
        let url = `${context.rootGetters['settings/getUrl']}api/request-snapshot`;
        await axios.post(`${url}`, {'event':payload.event, 'type':payload.type},{'headers': { 'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`} })
            .then((res) =>{
                return true
            }).catch((error) => {
                console.log(error);
            });
    },
    async followOddsEvent (context, payload) {
        let url = `${context.rootGetters['settings/getUrl']}feed/read/history`;
        await axios.post(`${url}`, {'_id':payload.paramKey},{'headers': { 'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`} })
            .then((res) =>{
                console.log(res);
            }).catch((error) => {
                console.log(error);
            });
    },
    async followOddsSingle (context, payload) {
        let url = `${context.rootGetters['settings/getUrl']}feed/read`;
        await axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                console.log(res);
            }).catch((error) => {
                console.log(error);
            });
    },
    filterEvents(context,payload){

        //console.log(context.state.categories)




            let items=[];
            var sport='';
            var category='';
            var tournament='';
            var setting=[];
            context.state.events.map(replica => {
                let text = '';
                if(replica.competitors.length>0){
                    //console.log(replica.competitors)
                    let nameCompetitor =[];
                    replica.competitors.forEach(competitor =>{
                        let response = competitor.detail.filter(check => {
                            return check.lang == payload.language
                        });
                        //console.log(response);
                        nameCompetitor.push(response[0].text)
                    })
                    text = nameCompetitor.join(" - ");

                    if(setting.length==0){
                        sport = replica.sport.detail.filter(check => {
                            return    check.lang == payload.language
                        });
                        category = replica.category.detail.filter(check => {
                            return    check.lang == payload.language
                        });
                        tournament = replica.tournament.detail.filter(check => {
                            return    check.lang == payload.language
                        });


                        setting.push({'sport':sport[0].text,
                            'category':category[0].text,
                            'tournament':tournament[0].text,
                            'sport_id':replica.sport.id,
                            'category_id':replica.category.id,
                            'tournament_id':replica.tournament.id});
                        context.commit('setSetting',setting);

                    }



                }
                // console.log(replica)
                items.push({'_id':replica._id,
                    'id':replica.id,
                    'name':text,
                    'active':replica.active,
                    'scheduled':replica.scheduled,
                    'status':replica.status});
        })
        context.commit('setItems',items);

        //console.log(items);
        //return items;
    },


    async fetchEventsList(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}events/events-list`;

        return await axios.post(`${url}`,
            {...payload},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                context.commit('SET_EVENTS_LIST', res.data.data);
                context.commit('SET_COUNTS', res.data.counts);

            }).catch((error) => {
                console.log(error);
            });
    },

    async setOptions(context, payload){
        context.commit("SET_OPTIONS",payload)
    },

    async setCounts(context, payload){
        context.commit("SET_COUNTS",payload)
    },

    async setCriteria(context, payload){
        context.commit("SET_CRITERIA",payload)
    },

    async removeEventDetail(context, payload){
        context.commit("SET_EVENT_DETAIL",{})
    },

    async setEventDetail(context, payload){
        context.commit("SET_EVENT_DETAIL",payload)
    },

    async setShowDetail(context, payload){
        context.commit("SET_SHOW_DETAIL",payload)
    },

};

const mutations = {

    setEvents(state,payload) {
        state.events = payload;
    },
    resetEvents(state) {
        state.events = [];
    },
    resetEventsList(state){
        state.list = [];
    },
    setItems(state,payload) {
        state.items = payload;
    },
    setSetting(state,payload) {
        state.setting = payload;
    },

    setTitle(state,payload) {
        state.title = payload;
    },

    SET_EVENTS_LIST(state, payload) {
        state.list = payload;
    },

    SET_OPTIONS(state, payload) {
        state.options = payload;
    },
    SET_COUNTS(state, payload) {
        state.counts = payload;
    },

    SET_CRITERIA(state, payload) {
        state.criteria = payload;
    },

    SET_EVENT_DETAIL(state, payload) {
        state.eventDetail = payload;
    },
    SET_SHOW_DETAIL(state, payload) {
        state.showDetail = payload;
    },
};



export default {
    namespaced : true,
	state,
	mutations,
	getters,
	actions
}
