<template>
    <div id="initializeLiveSettings">

        <v-container fluid class="pl-0">
            <v-row>
                <v-col cols="12">
                    <template>

                        <div class="col-md-12 col-xs-12">
                            <p>{{$t('generic.initializeLiveSettingsForThisPlatform')}}</p>
                        </div>

                        <div style="display: flex">
                            <v-col cols="2" class="pt-4">
                                <v-btn color="green" class="white--text text-right "
                                       @click="initializeMarketGroupsLiveMet()" v-if="getPlatformId != null"
                                       small
                                       :title="$t('generic.noteInitializeLiveSettingsForThisPlatform')">
                                    <v-icon left dark>save</v-icon>
                                    {{$t('copy')}}
                                </v-btn>
                            </v-col>
                        </div>
                    </template>
                </v-col>
            </v-row>

        </v-container>

    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: "initializeLiveSettings",
        data() {
            return {}
        },

        watch: {},

        computed: {
            ...mapGetters({
                'getLanguage': 'profile/getLanguage',
                'getPlatformId': 'rules/getPlatformId',
                'getSkins': 'skins/getList',
            }),

        },
        async created() {

        },

        mounted() {

        },

        methods: {

            ...mapActions({

                'setPreload': 'utils/setPreload',
                'initializeMarketGroupsLive': 'settings/initializeMarketGroupsLive'
            }),

            ...mapMutations({
                'setLanguage': 'profile/setLanguage',
            }),


            async initializeMarketGroupsLiveMet() {

                const messageWarning = this.$t('generic.noteInitializeLiveSettingsForThisPlatform')
                let isExecuted = confirm(messageWarning);

                if (isExecuted === true) {

                    const sourcePlatformId = +this.getPlatformId;

                    if (sourcePlatformId != null && sourcePlatformId > 0) {
                        this.initializeMarketGroupsLive({platformId: sourcePlatformId})
                        alert(this.$t('operationInProgressCanTakeFewMinutes'));

                    }

                }
            }

        },
    }
</script>

<style scoped>

</style>