<template>
    <div id="limits-page">
        <v-container fluid v-if="getPlatformId > 0">
            <v-layout row>
                <v-flex md12>
                    <v-col cols="12">
                        <v-card outlined elevation-0>
                            <v-row class="title-row">
                                <v-col md="12">
                                    <v-row class="ml-1">
                                        <v-col md="6">
                                            <h2 class="ml-1">{{ getPlatformName() }}</h2>
                                        </v-col>
                                        <v-col md="2" offset="2" v-if="!newRule">
                                            <v-select class="text-capitalize" :items="ruleType" item-text="text"
                                                      id="ruleType"
                                                      item-value="value" :label="this.$t('risk.rule_type')" dense
                                                      outlined
                                                      @change="changeRule()"
                                                      v-model="selectedRule"></v-select>
                                        </v-col>
                                        <v-col md="1" v-if="!newRule">
                                            <v-btn color="primary" id="add" @click="addNewRule()" class="mart-2">{{
                                                $t('generic.add') }}
                                            </v-btn>
                                        </v-col>
                                        <v-col md="1" v-if="!newRule">
                                            <v-btn color="error" id="remove" @click="sure()" class="mart-2">{{
                                                $t('generic.delete') }}
                                            </v-btn>
                                        </v-col>

                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card outlined elevation-0 class="mt-1" v-if="newRule">
                            <v-row>
                                <v-col md="12">
                                    <v-row class="mt-1 ml-1">
                                        <v-col md="4">
                                            <v-text-field class="text-capitalize like-select" v-model="rules.rules_id"
                                                          id="rules_id"
                                                          label="rules_id" dense outlined type="text"></v-text-field>
                                        </v-col>
                                        <v-col md="1" offset="7">
                                            <Transition name="slide-fade">
                                                <v-alert type="success" dense v-if="opendialog"
                                                         transition="slide-x-reverse-transition" class="mr-1">
                                                    Done!
                                                </v-alert>
                                                <v-alert type="error" dense v-if="opendialogerror"
                                                         transition="slide-x-reverse-transition" class="mr-1">
                                                    Error!
                                                </v-alert>
                                            </Transition>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-1 ml-1">
                                        <v-col md="2">

                                            <v-btn color="secondary" id="generateButton"
                                                   @click="saveNewRules(rules.rules_id)">{{ $t('generic.save') }}
                                            </v-btn>

                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card outlined elevation-0 class="mt-1" v-else>
                            <v-row>
                                <v-col md="12" class="containerAlert">
                                    <v-row class="mt-1 ml-1">
                                        <v-col md="2" offset="10">
                                            <Transition name="slide-fade">
                                                <v-alert type="success" dense v-if="opendialog"
                                                         transition="slide-x-reverse-transition" class="mr-1">
                                                    Done!
                                                </v-alert>
                                                <v-alert type="error" dense v-if="opendialogerror"
                                                         transition="slide-x-reverse-transition" class="mr-1">
                                                    Error!
                                                </v-alert>
                                            </Transition>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col md="6">
                                    <v-row class="mt-1 ml-1">
                                        <v-col md="12">
                                            <v-text-field class="text-capitalize like-select" v-model="rules.max_risk"
                                                          id="max_risk"
                                                          :label="this.$t('risk.limits.max_risk')" dense outlined
                                                          maxlength="12" type="number" min="0"
                                                          step="0.01"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-1 ml-1">
                                        <v-col md="6">
                                            <v-text-field class="text-capitalize like-select" v-model="periods.from"
                                                          id="from"
                                                          :label="this.$t('generic.from')" dense outlined
                                                          type="date"></v-text-field>
                                        </v-col>
                                        <v-col md="6">
                                            <v-text-field class="text-capitalize like-select" v-model="periods.to"
                                                          id="to"
                                                          :label="this.$t('generic.to')" dense outlined
                                                          type="date"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-1 ml-1">
                                        <v-col md="6">
                                            <v-text-field class="text-capitalize like-select"
                                                          v-model="periods.time_from"
                                                          id="time_from"
                                                          :label="this.$t('risk.TimeFrom')" dense outlined
                                                          type="time"></v-text-field>
                                        </v-col>
                                        <v-col md="6">
                                            <v-text-field class="text-capitalize like-select" v-model="periods.time_to"
                                                          id="time_to"
                                                          :label="this.$t('risk.TimeTo')" dense outlined
                                                          type="time"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-1 ml-1">
                                        <v-col md="6">
                                            <v-text-field class="text-capitalize like-select"
                                                          v-model="rules.rules_id"
                                                          id="rules_id"
                                                          :label="this.$t('risk.rules_id')" dense outlined readonly
                                                          disabled type="text"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col md="6">
                                            <v-text-field class="text-capitalize like-select"
                                                          v-model="rules.description_rule"
                                                          id="description_rule"
                                                          :label="this.$t('risk.description_rule')" dense outlined
                                                          type="text"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-1 ml-1">
                                        <v-col md="6">
                                            <v-text-field class="text-capitalize like-select"
                                                          v-model="rules.block_max_amount" id="block_max_amount"
                                                          :label="this.$t('risk.limits.block_max_amount')"
                                                          dense outlined maxlength="12" type="number" min="0"
                                                          step="0.01"></v-text-field>
                                        </v-col>
                                        <v-col md="6">
                                            <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                      id="block_max_amount_action"
                                                      item-value="value" dense outlined
                                                      v-model="rules.block_max_amount_action"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-1 ml-4 pb-1 text-capitalize" style="cursor: pointer; border-bottom: 2px solid #575756;margin-bottom: 15px"
                                           v-on:click="togglePrematch">
                                        <h4 style="font-size: 20px">{{ this.$t('bets.prematch') }} <v-icon v-show="!showSectionPrematch" style="color: #000;font-size: 15px">mdi-arrow-right</v-icon><v-icon v-show="showSectionPrematch" style="color: #000;font-size: 15px">mdi-arrow-down</v-icon></h4>
                                    </v-row>
                                    <v-col md="12"  class="no-padding"  v-show="showSectionPrematch">
                                        <v-row class="mt-1 ml-1" v-on:click="singlePrematch">
                                            <v-col md="6" style="cursor: pointer;">
                                                <span>{{ this.$t('bets.single') | capitalize  }} <v-icon v-show="!showSinglePrematch" style="font-size: 15px">mdi-arrow-right</v-icon><v-icon v-show="showSinglePrematch"  style="font-size: 15px">mdi-arrow-down</v-icon></span>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showSinglePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.single_max_total_risk"
                                                              id="single_max_total_risk"
                                                              :label="this.$t('risk.limits.single_max_total_risk')"
                                                              dense outlined maxlength="12" type="number"
                                                              min="0" step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="single_max_total_risk_action"
                                                          item-value="value" dense
                                                          outlined
                                                          v-model="rules.single_max_total_risk_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showSinglePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.single_min_bet" id="single_min_bet"
                                                              :label="this.$t('risk.limits.single_min_bet')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="single_min_bet_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.single_min_bet_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showSinglePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.single_max_bet" id="single_max_bet"
                                                              :label="this.$t('risk.limits.single_max_bet')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="single_max_bet_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.single_max_bet_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showSinglePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.single_max_win" id="single_max_win"
                                                              :label="this.$t('risk.limits.single_max_win')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="single_max_win_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.single_max_win_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showSinglePrematch">
                                            <v-col md="3">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.single_min_odds" id="single_min_odds"
                                                              :label="this.$t('risk.limits.single_min_odds')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="3">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.single_max_odds" id="single_max_odds"
                                                              :label="this.$t('risk.limits.single_max_odds')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="single_max_odds_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.single_max_odds_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showSinglePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.prematch_single_max_percentage_win"
                                                              id="prematch_single_max_percentage_win"
                                                              :label="this.$t('risk.limits.prematch_single_max_percentage_win')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="prematch_single_max_percentage_win_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.prematch_single_max_percentage_win_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showSinglePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.mixed_single_max_percentage_win"
                                                              id="mixed_single_max_percentage_win"
                                                              :label="this.$t('risk.limits.mixed_single_max_percentage_win')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="mixed_single_max_percentage_win_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.mixed_single_max_percentage_win_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showSinglePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.single_max_repeat" id="single_max_repeat"
                                                              :label="this.$t('risk.limits.single_max_repeat')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="single_max_repeat_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.single_max_repeat_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showSinglePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.single_max_repeat_amount"
                                                              id="single_max_repeat_amount"
                                                              :label="this.$t('risk.limits.single_max_repeat_amount')"
                                                              dense outlined maxlength="12" type="number"
                                                              min="0" step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="single_max_repeat_amount_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.single_max_repeat_amount_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showSinglePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.single_prematch_max_in_progress"
                                                              id="single_prematch_max_in_progress"
                                                              :label="this.$t('risk.limits.single_prematch_max_in_progress')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="single_prematch_max_in_progress_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.single_prematch_max_in_progress_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-on:click="multiplePrematch">
                                            <v-col md="6" style="cursor: pointer;">
                                                <span>{{ this.$t('bets.multiple') | capitalize  }} <v-icon v-show="!showMultiplePrematch" style="font-size: 15px">mdi-arrow-right</v-icon><v-icon v-show="showMultiplePrematch"  style="font-size: 15px">mdi-arrow-down</v-icon></span>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showMultiplePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.multiple_max_total_risk"
                                                              id="multiple_max_total_risk"
                                                              :label="this.$t('risk.limits.multiple_max_total_risk')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="multiple_max_total_risk_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.multiple_max_total_risk_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showMultiplePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.multiple_min_bet" id="multiple_min_bet"
                                                              :label="this.$t('risk.limits.multiple_min_bet')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="multiple_min_bet_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.multiple_min_bet_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showMultiplePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.multiple_max_bet" id="multiple_max_bet"
                                                              :label="this.$t('risk.limits.multiple_max_bet')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="multiple_max_bet_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.multiple_max_bet_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showMultiplePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.multiple_max_win" id="multiple_max_win"
                                                              :label="this.$t('risk.limits.multiple_max_win')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="multiple_max_win_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.multiple_max_win_action"></v-select>
                                            </v-col>

                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showMultiplePrematch">
                                            <v-col md="3">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.multiple_min_odds" id="multiple_min_odds"
                                                              :label="this.$t('risk.limits.multiple_min_odds')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="3">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.multiple_max_odds" id="multiple_max_odds"
                                                              :label="this.$t('risk.limits.multiple_max_odds')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="multiple_max_odds_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.multiple_max_odds_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showMultiplePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.prematch_multiple_max_percentage_win"
                                                              id="prematch_multiple_max_percentage_win"
                                                              :label="this.$t('risk.limits.prematch_multiple_max_percentage_win')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="prematch_multiple_max_percentage_win_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.prematch_multiple_max_percentage_win_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showMultiplePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.mixed_multiple_max_percentage_win"
                                                              id="mixed_multiple_max_percentage_win"
                                                              :label="this.$t('risk.limits.mixed_multiple_max_percentage_win')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="mixed_multiple_max_percentage_win_action"
                                                          item-value="value"
                                                          dense outlined
                                                          v-model="rules.mixed_multiple_max_percentage_win_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showMultiplePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.multiple_max_repeat"
                                                              id="multiple_max_repeat"
                                                              :label="this.$t('risk.limits.multiple_max_repeat')"
                                                              dense outlined maxlength="12" type="number"
                                                              min="0" step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="multiple_max_repeat_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.multiple_max_repeat_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showMultiplePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.multiple_max_repeat_amount"
                                                              id="multiple_max_repeat_amount"
                                                              :label="this.$t('risk.limits.multiple_max_repeat_amount')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="multiple_max_repeat_amount_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.multiple_max_repeat_amount_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showMultiplePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.multiple_prematch_max_in_progress"
                                                              id="multiple_prematch_max_in_progress"
                                                              :label="this.$t('risk.limits.multiple_prematch_max_in_progress')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="multiple_prematch_max_in_progress_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.multiple_prematch_max_in_progress_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showMultiplePrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.multiple_mixed_max_in_progress"
                                                              id="multiple_mixed_max_in_progress"
                                                              :label="this.$t('risk.limits.multiple_mixed_max_in_progress')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="multiple_mixed_max_in_progress_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.multiple_mixed_max_in_progress_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-on:click="integralPrematch">
                                            <v-col md="6" style="cursor: pointer;">
                                                <span>{{ this.$t('bets.integral') | capitalize  }} <v-icon v-show="!showIntegralPrematch" style="font-size: 15px">mdi-arrow-right</v-icon><v-icon v-show="showIntegralPrematch"  style="font-size: 15px">mdi-arrow-down</v-icon></span>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-show="showIntegralPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.integral_max_total_risk"
                                                              id="integral_max_total_risk"
                                                              :label="this.$t('risk.limits.integral_max_total_risk')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="integral_max_total_risk_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.integral_max_total_risk_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showIntegralPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.integral_min_bet" id="integral_min_bet"
                                                              :label="this.$t('risk.limits.integral_min_bet')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="integral_min_bet_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.integral_min_bet_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showIntegralPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.integral_max_bet" id="integral_max_bet"
                                                              :label="this.$t('risk.limits.integral_max_bet')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="integral_max_bet_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.integral_max_bet_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showIntegralPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.integral_max_win" id="integral_max_win"
                                                              :label="this.$t('risk.limits.integral_max_win')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="integral_max_win_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.integral_max_win_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showIntegralPrematch">
                                            <v-col md="3">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.integral_min_odds" id="integral_min_odds"
                                                              :label="this.$t('risk.limits.integral_min_odds')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="3">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.integral_max_odds" id="integral_max_odds"
                                                              :label="this.$t('risk.limits.integral_max_odds')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="integral_max_odds_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.integral_max_odds_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showIntegralPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.prematch_integral_max_percentage_win"
                                                              id="prematch_integral_max_percentage_win"
                                                              :label="this.$t('risk.limits.prematch_integral_max_percentage_win')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="prematch_integral_max_percentage_win_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.prematch_integral_max_percentage_win_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showIntegralPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.mixed_integral_max_percentage_win"
                                                              id="mixed_integral_max_percentage_win"
                                                              :label="this.$t('risk.limits.mixed_integral_max_percentage_win')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="mixed_integral_max_percentage_win_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.mixed_integral_max_percentage_win_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showIntegralPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.integral_max_repeat"
                                                              id="integral_max_repeat"
                                                              :label="this.$t('risk.limits.integral_max_repeat')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="integral_max_repeat_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.integral_max_repeat_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showIntegralPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.integral_max_repeat_amount"
                                                              id="integral_max_repeat_amount"
                                                              :label="this.$t('risk.limits.integral_max_repeat_amount')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="integral_max_repeat_amount_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.integral_max_repeat_amount_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showIntegralPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.integral_prematch_max_in_progress"
                                                              id="integral_prematch_max_in_progress"
                                                              :label="this.$t('risk.limits.integral_prematch_max_in_progress')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="integral_prematch_max_in_progress_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.integral_prematch_max_in_progress_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showIntegralPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.integral_mixed_max_in_progress"
                                                              id="integral_mixed_max_in_progress"
                                                              :label="this.$t('risk.limits.integral_mixed_max_in_progress')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="integral_mixed_max_in_progress_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.integral_mixed_max_in_progress_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-1 ml-1" v-on:click="systemPrematch">
                                            <v-col md="6" style="cursor: pointer;">
                                                <span>{{ this.$t('bets.system') | capitalize  }} <v-icon v-show="!showSystemPrematch" style="font-size: 15px">mdi-arrow-right</v-icon><v-icon v-show="showSystemPrematch"  style="font-size: 15px">mdi-arrow-down</v-icon></span>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showSystemPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.system_max_total_risk"
                                                              id="system_max_total_risk"
                                                              :label="this.$t('risk.limits.system_max_total_risk')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="system_max_total_risk_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.system_max_total_risk_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showSystemPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.system_min_bet" id="system_min_bet"
                                                              :label="this.$t('risk.limits.system_min_bet')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="system_min_bet_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.system_min_bet_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showSystemPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.system_max_bet" id="system_max_bet"
                                                              :label="this.$t('risk.limits.system_max_bet')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="system_max_bet_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.system_max_bet_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showSystemPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.system_max_win" id="system_max_win"
                                                              :label="this.$t('risk.limits.system_max_win')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="system_max_win_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.system_max_win_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showSystemPrematch">
                                            <v-col md="3">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.system_min_odds" id="system_min_odds"
                                                              :label="this.$t('risk.limits.system_min_odds')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="3">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.system_max_odds" id="system_max_odds"
                                                              :label="this.$t('risk.limits.system_max_odds')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="system_max_odds_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.system_max_odds_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showSystemPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.prematch_system_max_percentage_win"
                                                              id="prematch_system_max_percentage_win"
                                                              :label="this.$t('risk.limits.prematch_system_max_percentage_win')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="prematch_system_max_percentage_win_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.prematch_system_max_percentage_win_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showSystemPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.mixed_system_max_percentage_win"
                                                              id="mixed_system_max_percentage_win"
                                                              :label="this.$t('risk.limits.mixed_system_max_percentage_win')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="mixed_system_max_percentage_win_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.mixed_system_max_percentage_win_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showSystemPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.system_max_repeat" id="system_max_repeat"
                                                              :label="this.$t('risk.limits.system_max_repeat')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="system_max_repeat_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.system_max_repeat_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showSystemPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.system_max_repeat_amount"
                                                              id="system_max_repeat_amount"
                                                              :label="this.$t('risk.limits.system_max_repeat_amount')"
                                                              dense outlined maxlength="12" type="number"
                                                              min="0" step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="system_max_repeat_amount_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.system_max_repeat_amount_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showSystemPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.system_prematch_max_in_progress"
                                                              id="system_prematch_max_in_progress"
                                                              :label="this.$t('risk.limits.system_prematch_max_in_progress')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="system_prematch_max_in_progress_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.system_prematch_max_in_progress_action"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row  class="mt-1 ml-1" v-show="showSystemPrematch">
                                            <v-col md="6">
                                                <v-text-field class="text-capitalize like-select"
                                                              v-model="rules.system_mixed_max_in_progress"
                                                              id="system_mixed_max_in_progress"
                                                              :label="this.$t('risk.limits.system_mixed_max_in_progress')"
                                                              dense outlined maxlength="12" type="number" min="0"
                                                              step="0.01"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                          id="system_mixed_max_in_progress_action"
                                                          item-value="value" dense outlined
                                                          v-model="rules.system_mixed_max_in_progress_action"></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-row class="mt-4 ml-4 pb-1 text-capitalize"  style="cursor: pointer; border-bottom: 2px solid #575756;margin-bottom: 15px"
                                           v-on:click="toggleLive">
                                        <h4 style="font-size: 20px">{{ $t('bets.live')}} <v-icon v-show="!showSectionLive" style="color: #000;font-size: 15px">mdi-arrow-right</v-icon><v-icon v-show="showSectionLive" style="color: #000;font-size: 15px">mdi-arrow-down</v-icon></h4>
                                    </v-row>
                                    <v-col md="12" class="no-padding" v-show="showSectionLive">
                                        <v-row class="mt-1 ml-1" v-on:click="singleLive">
                                            <v-col md="6" style="cursor: pointer;">
                                                <span>{{ this.$t('bets.single') | capitalize  }} <v-icon v-show="!showSingleLive" style="font-size: 15px">mdi-arrow-right</v-icon><v-icon v-show="showSingleLive"  style="font-size: 15px">mdi-arrow-down</v-icon></span>
                                            </v-col>
                                        </v-row>
                                            <v-row class="mt-1 ml-1" v-show="showSingleLive">
                                                <v-col md="6">
                                                    <v-text-field class="text-capitalize like-select"
                                                                  v-model="rules.live_single_max_bet"
                                                                  id="live_single_max_bet"
                                                                  :label="this.$t('risk.limits.live_single_max_bet')"
                                                                  dense outlined
                                                                  maxlength="12" type="number" min="0" step="0.01"></v-text-field>
                                                </v-col>
                                                <v-col md="6">
                                                    <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                              id="live_single_max_bet_action"
                                                              item-value="value" dense outlined
                                                              v-model="rules.live_single_max_bet_action"></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-1 ml-1" v-show="showSingleLive">
                                                <v-col md="6">
                                                    <v-text-field class="text-capitalize like-select"
                                                                  v-model="rules.live_single_max_win"
                                                                  id="live_single_max_win"
                                                                  :label="this.$t('risk.limits.live_single_max_win')"
                                                                  dense outlined
                                                                  maxlength="12" type="number" min="0" step="0.01"></v-text-field>
                                                </v-col>
                                                <v-col md="6">
                                                    <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                              id="live_single_max_win_action"
                                                              item-value="value" dense outlined
                                                              v-model="rules.live_single_max_win_action"></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-1 ml-1" v-show="showSingleLive">
                                                <v-col md="6">
                                                    <v-text-field class="text-capitalize like-select"
                                                                  v-model="rules.live_single_max_percentage_win"
                                                                  id="live_single_max_percentage_win"
                                                                  :label="this.$t('risk.limits.live_single_max_percentage_win')"
                                                                  dense outlined
                                                                  maxlength="12" type="number" min="0" step="0.01"></v-text-field>
                                                </v-col>
                                                <v-col md="6">
                                                    <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                              id="live_single_max_percentage_win_action"
                                                              item-value="value" dense outlined
                                                              v-model="rules.live_single_max_percentage_win_action"></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-1 ml-1" v-show="showSingleLive">
                                                <v-col md="6">
                                                    <v-text-field class="text-capitalize like-select"
                                                                  v-model="rules.single_live_max_in_progress"
                                                                  id="single_live_max_in_progress"
                                                                  :label="this.$t('risk.limits.single_live_max_in_progress')"
                                                                  dense outlined maxlength="12" type="number" min="0"
                                                                  step="0.01"></v-text-field>
                                                </v-col>
                                                <v-col md="6">
                                                    <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                              id="single_live_max_in_progress_action"
                                                              item-value="value" dense outlined
                                                              v-model="rules.single_live_max_in_progress_action"></v-select>
                                                </v-col>
                                            </v-row>
                                        <v-row class="mt-1 ml-1" v-on:click="multipleLive">
                                            <v-col md="6" style="cursor: pointer;">
                                                <span>{{ this.$t('bets.multiple') | capitalize  }} <v-icon v-show="!showMultipleLive" style="font-size: 15px">mdi-arrow-right</v-icon><v-icon v-show="showMultipleLive"  style="font-size: 15px">mdi-arrow-down</v-icon></span>
                                            </v-col>
                                        </v-row>
                                            <v-row class="mt-1 ml-1" v-show="showMultipleLive">
                                                <v-col md="6">
                                                    <v-text-field class="text-capitalize like-select"
                                                                  v-model="rules.live_multiple_max_bet"
                                                                  id="live_multiple_max_bet"
                                                                  :label="this.$t('risk.limits.live_multiple_max_bet')"
                                                                  dense outlined
                                                                  maxlength="12" type="number" min="0" step="0.01"></v-text-field>
                                                </v-col>
                                                <v-col md="6">
                                                    <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                              id="live_multiple_max_bet_action"
                                                              item-value="value" dense outlined
                                                              v-model="rules.live_multiple_max_bet_action"></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-1 ml-1" v-show="showMultipleLive">
                                                <v-col md="6">
                                                    <v-text-field class="text-capitalize like-select"
                                                                  v-model="rules.live_multiple_max_win"
                                                                  id="live_multiple_max_win"
                                                                  :label="this.$t('risk.limits.live_multiple_max_win')"
                                                                  dense outlined
                                                                  maxlength="12" type="number" min="0" step="0.01"></v-text-field>
                                                </v-col>
                                                <v-col md="6">
                                                    <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                              id="live_multiple_max_win_action"
                                                              item-value="value" dense outlined
                                                              v-model="rules.live_multiple_max_win_action"></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-1 ml-1" v-show="showMultipleLive">
                                                <v-col md="6">
                                                    <v-text-field class="text-capitalize like-select"
                                                                  v-model="rules.live_multiple_max_percentage_win"
                                                                  id="live_multiple_max_percentage_win"
                                                                  :label="this.$t('risk.limits.live_multiple_max_percentage_win')"
                                                                  dense outlined maxlength="12" type="number" min="0"
                                                                  step="0.01"></v-text-field>
                                                </v-col>
                                                <v-col md="6">
                                                    <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                              id="live_multiple_max_percentage_win_action"
                                                              item-value="value" dense outlined
                                                              v-model="rules.live_multiple_max_percentage_win_action"></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-1 ml-1" v-show="showMultipleLive">
                                                <v-col md="6">
                                                    <v-text-field class="text-capitalize like-select"
                                                                  v-model="rules.multiple_live_max_in_progress"
                                                                  id="multiple_live_max_in_progress"
                                                                  :label="this.$t('risk.limits.multiple_live_max_in_progress')"
                                                                  dense outlined maxlength="12" type="number" min="0"
                                                                  step="0.01"></v-text-field>
                                                </v-col>
                                                <v-col md="6">
                                                    <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                              id="multiple_live_max_in_progress_action"
                                                              item-value="value" dense outlined
                                                              v-model="rules.multiple_live_max_in_progress_action"></v-select>
                                                </v-col>
                                            </v-row>
                                        <v-row class="mt-1 ml-1" v-on:click="integralLive">
                                            <v-col md="6" style="cursor: pointer;">
                                                <span>{{ this.$t('bets.integral') | capitalize  }} <v-icon v-show="!showIntegralLive" style="font-size: 15px">mdi-arrow-right</v-icon><v-icon v-show="showIntegralLive"  style="font-size: 15px">mdi-arrow-down</v-icon></span>
                                            </v-col>
                                        </v-row>
                                            <v-row class="mt-1 ml-1" v-show="showIntegralLive">
                                                <v-col md="6">
                                                    <v-text-field class="text-capitalize like-select"
                                                                  v-model="rules.live_integral_max_bet"
                                                                  id="live_integral_max_bet"
                                                                  :label="this.$t('risk.limits.live_integral_max_bet')"
                                                                  dense outlined
                                                                  maxlength="12" type="number" min="0" step="0.01"></v-text-field>
                                                </v-col>
                                                <v-col md="6">
                                                    <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                              id="live_integral_max_bet_action"
                                                              item-value="value" dense outlined
                                                              v-model="rules.live_integral_max_bet_action"></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-1 ml-1" v-show="showIntegralLive">
                                                <v-col md="6">
                                                    <v-text-field class="text-capitalize like-select"
                                                                  v-model="rules.live_integral_max_win"
                                                                  id="live_integral_max_win"
                                                                  :label="this.$t('risk.limits.live_integral_max_win')"
                                                                  dense outlined
                                                                  maxlength="12" type="number" min="0" step="0.01"></v-text-field>
                                                </v-col>
                                                <v-col md="6">
                                                    <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                              id="live_integral_max_win_action"
                                                              item-value="value" dense outlined
                                                              v-model="rules.live_integral_max_win_action"></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-1 ml-1" v-show="showIntegralLive">
                                                <v-col md="6">
                                                    <v-text-field class="text-capitalize like-select"
                                                                  v-model="rules.live_integral_max_percentage_win"
                                                                  id="live_integral_max_percentage_win"
                                                                  :label="this.$t('risk.limits.live_integral_max_percentage_win')"
                                                                  dense outlined maxlength="12" type="number" min="0"
                                                                  step="0.01"></v-text-field>
                                                </v-col>
                                                <v-col md="6">
                                                    <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                              id="live_integral_max_percentage_win_action"
                                                              item-value="value" dense outlined
                                                              v-model="rules.live_integral_max_percentage_win_action"></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-1 ml-1" v-show="showIntegralLive">
                                                <v-col md="6">
                                                    <v-text-field class="text-capitalize like-select"
                                                                  v-model="rules.integral_live_max_in_progress"
                                                                  id="integral_live_max_in_progress"
                                                                  :label="this.$t('risk.limits.integral_live_max_in_progress')"
                                                                  dense outlined maxlength="12" type="number" min="0"
                                                                  step="0.01"></v-text-field>
                                                </v-col>
                                                <v-col md="6">
                                                    <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                              id="integral_live_max_in_progress_action"
                                                              item-value="value" dense outlined
                                                              v-model="rules.integral_live_max_in_progress_action"></v-select>
                                                </v-col>
                                            </v-row>
                                        <v-row class="mt-1 ml-1" v-on:click="systemLive">
                                            <v-col md="6" style="cursor: pointer;">
                                                <span>{{ this.$t('bets.system') | capitalize  }} <v-icon v-show="!showSystemLive" style="font-size: 15px">mdi-arrow-right</v-icon><v-icon v-show="showSystemLive"  style="font-size: 15px">mdi-arrow-down</v-icon></span>
                                            </v-col>
                                        </v-row>
                                            <v-row class="mt-1 ml-1" v-show="showSystemLive">
                                                <v-col md="6">
                                                    <v-text-field class="text-capitalize like-select"
                                                                  v-model="rules.live_system_max_bet"
                                                                  id="live_system_max_bet"
                                                                  :label="this.$t('risk.limits.live_system_max_bet')"
                                                                  dense outlined
                                                                  maxlength="12" type="number" min="0" step="0.01"></v-text-field>
                                                </v-col>
                                                <v-col md="6">
                                                    <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                              id="live_system_max_bet_action"
                                                              item-value="value" dense outlined
                                                              v-model="rules.live_system_max_bet_action"></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-1 ml-1" v-show="showSystemLive">
                                                <v-col md="6">
                                                    <v-text-field class="text-capitalize like-select"
                                                                  v-model="rules.live_system_max_win"
                                                                  id="live_system_max_win"
                                                                  :label="this.$t('risk.limits.live_system_max_win')"
                                                                  dense outlined
                                                                  maxlength="12" type="number" min="0" step="0.01"></v-text-field>
                                                </v-col>
                                                <v-col md="6">
                                                    <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                              id="live_system_max_win_action"
                                                              item-value="value" dense outlined
                                                              v-model="rules.live_system_max_win_action"></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-1 ml-1" v-show="showSystemLive">
                                                <v-col md="6">
                                                    <v-text-field class="text-capitalize like-select"
                                                                  v-model="rules.live_system_max_percentage_win"
                                                                  id="live_system_max_percentage_win"
                                                                  :label="this.$t('risk.limits.live_system_max_percentage_win')"
                                                                  dense outlined maxlength="12" type="number" min="0"
                                                                  step="0.01"></v-text-field>
                                                </v-col>
                                                <v-col md="6">
                                                    <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                              id="live_system_max_percentage_win_action"
                                                              item-value="value" dense outlined
                                                              v-model="rules.live_system_max_percentage_win_action"></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-1 ml-1" v-show="showSystemLive">
                                                <v-col md="6">
                                                    <v-text-field class="text-capitalize like-select"
                                                                  v-model="rules.system_live_max_in_progress"
                                                                  id="system_live_max_in_progress"
                                                                  :label="this.$t('risk.limits.system_live_max_in_progress')"
                                                                  dense outlined maxlength="12" type="number" min="0"
                                                                  step="0.01"></v-text-field>
                                                </v-col>
                                                <v-col md="6">
                                                    <v-select class="text-capitalize" :items="typeOfFilter" item-text="text"
                                                              id="system_live_max_in_progress_action"
                                                              item-value="value" dense outlined
                                                              v-model="rules.system_live_max_in_progress_action"></v-select>
                                                </v-col>
                                            </v-row>
                                    </v-col>
                                            <v-row class="mt-1 mb-1 ml-1">
                                                <v-col md="6">
                                                    <v-btn color="secondary" id="saveButton"
                                                           @click="saveRules(rules.rules_id)">{{ $t('generic.save') }}
                                                    </v-btn>
                                                </v-col>
                                            </v-row>

                                </v-col>

                                <v-col md="6">

                                    <v-row class="mt-1 mr-1">
                                        <v-col md="12">
                                            <v-data-table :headers="headers" :footer-props="footerTable"
                                                          :items="tableItems" search="" item-key="_id"
                                                          class="elevation-0">
                                                <template v-slot:body="{ items }">
                                                    <tbody>
                                                    <tr v-for="item in items" :key="item.idUser">
                                                        <td>{{ item.description }}</td>
                                                        <td>{{ item.from }}</td>
                                                        <td>{{ item.to }}</td>
                                                        <td>{{ item.time_from }}</td>
                                                        <td>{{ item.time_to }}</td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-data-table>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                        </v-card>
                    </v-col>
                </v-flex>
            </v-layout>
        </v-container>
        <div class="text-center">
            <v-dialog v-model="dialogsure" activator="parent" width="auto">
                <v-card>
                    <v-card-text class="text-center pt-4">
                        <h2 class="mt-1">{{$t('generic.areYouSure') | capitalize}}</h2>
                    </v-card-text>
                    <v-card-actions>
                        <v-col md="6">
                            <v-btn color="primary" block @click="dialogsure = false">{{ $t('generic.close') }}</v-btn>
                        </v-col>
                        <v-col md="6">
                            <v-btn color="error" block @click="removeRule()">{{ $t('generic.delete') }}</v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import {mapGetters, mapActions} from 'vuex';
    import _ from "lodash";
    import moment from 'moment-timezone'

    export default {
        name: "Limits",
        data() {
            return {
                platformName: '',
                rules: [],
                periods: [],
                opendialog: false,
                opendialogerror: false,
                dialogsure: false,
                newRule: false,
                skinTimezone: '',
                showSectionPrematch: false,
                showSinglePrematch: false,
                showMultiplePrematch: false,
                showIntegralPrematch: false,
                showSystemPrematch: false,
                showSectionLive: false,
                showSingleLive: false,
                showMultipleLive: false,
                showIntegralLive: false,
                showSystemLive: false,
                filters: [
                    {text: 'Generic', value: "generic"}
                ],
                selected: 'reject',
                typeOfFilter: [
                    {text: this.$t('risk.check'), value: "check"},
                    {text: this.$t('risk.reject'), value: "reject"},
                ],
                ruleType: [],
                tableItems: [],
                selectedRule: "",
                headers: [
                    {text: this.$t('generic.description'), value: 'description'},
                    {text: this.$t('generic.from'), value: 'from'},
                    {text: this.$t('generic.to'), value: 'to'},
                    {text: this.$t('risk.TimeFrom'), value: 'time_from'},
                    {text: this.$t('risk.TimeTo'), value: 'time_to'}
                ],
                footerTable: {
                    disableItemsPerPage: true,
                    itemsPerPageText: this.$t('datatable.itemsPerPageText'),
                    itemsPerPageAllText: this.$t('generic.all'),
                    pageText: '{0}-{1} ' + this.$t('datatable.of') + ' {2}',
                }
            }
        },
        computed: {
            ...mapGetters({
                'getReserve': 'bet/getReserve',
                'getPreload': 'utils/getPreload',
                'getShowDetail': 'bet/getShowDetail',
                'getOptions': 'bet/getOptions',
                'getSkinFilters': 'skins/getSkinFilters',
                'getCountsReserve': "bet/getCountsReserve",
                'getTimezone': 'bet/getTimezone',
                'getPlatformId': 'rules/getPlatformId',
                'getToken': 'profile/getToken',
                'getUrlApi': 'settings/getUrl',
                'getMyBetsAll': 'bet/getMyBetsAll',
                'getList': 'skins/getList',
            }),

        },

        async mounted() {
            await this.refreshData();
            await this.setPreload(true);
            await this.setPreload(false);
            console.log(this.selectedRule);
            // console.log(this.skinTimezone);
        },
        methods: {
            ...mapActions({
                'setPreload': 'utils/setPreload',
                'fetchBets': 'bet/fetchBets',
                'setOptions': 'bet/setOptions',
                'setTimezone': 'bet/setTimezone'
            }),
            async refreshData() {
                await this.getNameRules()
                await this.getRules()
                if (!this.rules.err) {
                    await this.getPeriods()
                    await this.getAllPeriods()
                } else {
                    this.newRule = true;
                }
            },

            //prematch Actions
            togglePrematch() {
                this.showSectionPrematch = !this.showSectionPrematch
            },
            singlePrematch() {
                this.showSinglePrematch = !this.showSinglePrematch
            },
            multiplePrematch() {
                this.showMultiplePrematch = !this.showMultiplePrematch
            },
            integralPrematch() {
                this.showIntegralPrematch = !this.showIntegralPrematch
            },
            systemPrematch() {
                this.showSystemPrematch = !this.showSystemPrematch
            },
            //prematch Live
            toggleLive() {
                this.showSectionLive = !this.showSectionLive
            },
            singleLive() {
                this.showSingleLive = !this.showSingleLive
            },
            multipleLive() {
                this.showMultipleLive = !this.showMultipleLive
            },
            integralLive() {
                this.showIntegralLive = !this.showIntegralLive
            },
            systemLive() {
                this.showSystemLive = !this.showSystemLive
            },

            checkTimezone() {
                let platform = _.find(this.getSkinFilters, {'platform_id': this.getPlatformId})
                if (platform.settings.timezone) {
                    this.skinTimezone = platform.settings.timezone
                    this.setTimezone(platform.settings.timezone)
                }
            },
            getPlatformName() {
                if (this.getPlatformId == 0) {
                    return "Manager";
                } else {
                    let platform = _.find(this.getSkinFilters, {platform_id: this.getPlatformId})

                    if (platform.settings.timezone) {
                        this.skinTimezone = platform.settings.timezone
                        this.setTimezone(platform.settings.timezone)
                    }

                    return platform.name
                }
            },
            addNewRule() {
                this.rules.rules_id = '';
                this.rules.description_rule = '';
                this.newRule = true;
            },
            async sure() {
                this.dialogsure = true;
            },
            removeRule() {
                const url = process.env.VUE_APP_RISK_URL_API + 'rules/' + this.getPlatformId + '/' + this.selectedRule;
                let configs = {
                    method: 'delete',
                    maxBodyLength: Infinity,
                    url: url,
                    headers: {
                        'Authorization': 'Bearer ' + this.getToken,
                        'Content-Type': 'application/json'
                    }
                };
                axios.request(configs)
                    .then((response) => {
                        this.refreshData();
                        console.log(JSON.stringify(response.data));
                        this.dialogsure = false;
                        this.opendialog = true;
                        this.selectedRule = "";
                        setTimeout(() => {
                            this.opendialog = false;
                        }, 2000)
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            },
            changeRule() {
                this.getRules();
                this.getPeriods();
                this.getAllPeriods()
            },

            async getRules() {
                if (this.getPlatformId > 0) {
                    const url = process.env.VUE_APP_RISK_URL_API + 'rules/' + this.getPlatformId + '/' + this.selectedRule;
                    await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${this.getToken}`}})
                        .then(async (res) => {
                            const data = res.data.data;
                            if (data == 'Rule not exists') {
                                this.rules.err = data;
                                this.rules.rules_id = '';
                                this.rules.description_rule = '';
                            } else {
                                this.rules = data;
                            }
                            if (!this.selectedRule) {
                                this.selectedRule = this.rules.rule_id
                            }
                        })
                }
            },
            async getPeriods() {
                if (this.getPlatformId > 0) {
                    const url_date = process.env.VUE_APP_RISK_URL_API + 'rules/period/' + this.getPlatformId + '/' + this.selectedRule;
                    await axios.get(`${url_date}`, {'headers': {'Authorization': `Bearer ${this.getToken}`}})
                        .then(async (res) => {
                            const data = res.data.data;

                            if (data == null) {
                                this.periods = {
                                    "from": new Date().toISOString().slice(0, 10),
                                    "to": new Date().toISOString().slice(0, 10),
                                    "time_from": '',
                                    "time_to": '',
                                };
                            } else {

                                const d = new Date();
                                let time_from;
                                let time_to;

                                if (this.getTimezone != '') {
                                    time_from = moment.utc(d.getFullYear() + '-' + (d.getMonth() + 1).toString().padStart(2, '0') + '-' + (d.getDate().toString().padStart(2, '0')) + ' ' + data.time_from).tz(this.getTimezone).format('HH:mm')
                                    time_to = moment.utc(d.getFullYear() + '-' + (d.getMonth() + 1).toString().padStart(2, '0') + '-' + (d.getDate().toString().padStart(2, '0')) + ' ' + data.time_to).tz(this.getTimezone).format('HH:mm')
                                }else{
                                    time_from = data.time_from
                                    time_to = data.time_to
                                }

                                this.periods = {
                                    "description": data.rules_id,
                                    "from": new Date(data.from).toISOString().slice(0, 10),
                                    "to": new Date(data.to).toISOString().slice(0, 10),
                                    "time_from": time_from,
                                    "time_to": time_to,
                                };
                                console.log(data)
                                console.log(this.periods)
                            }
                        })
                }
            },
            async getNameRules() {
                this.ruleType = [];
                if (this.getPlatformId > 0) {
                    const url = process.env.VUE_APP_RISK_URL_API + 'rules/get-name/' + this.getPlatformId;
                    await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${this.getToken}`}})
                        .then(async (res) => {
                            const data = res.data.data;
                            if (!this.selectedRule) {
                                this.selectedRule = data[0].rules_id;
                            }
                            data.forEach(item => {
                                this.ruleType.push({text: item.rules_id, value: item.rules_id})
                            })
                        }).catch((err) => {
                            this.newRule = true;
                        })
                }
            },
            async saveRules(id) {

                if (this.rules.rules_id == '' || this.rules.description_rule == '' || this.periods.from == '' || this.periods.to == '') {
                    this.opendialogerror = true;
                    window.scrollTo(0, 0);

                    setTimeout(() => {
                        this.opendialogerror = false
                    }, 5000)
                    return
                }


                const url_period = process.env.VUE_APP_RISK_URL_API + 'rules/period/' + this.getPlatformId;
                var formDate = new Date(this.periods.from);
                var toDate = new Date(this.periods.to);
                var data_period = JSON.stringify({
                    "from": formDate.getTime(),
                    "to": toDate.getTime(),
                    "time_from": this.periods.time_from,
                    "time_to": this.periods.time_to,
                    "rules_id": this.rules.rules_id,
                    "timezone": this.skinTimezone,
                    "priority": 0
                })


                let configs = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: url_period,
                    headers: {
                        'Authorization': 'Bearer ' + this.getToken,
                        'Content-Type': 'application/json'
                    },
                    data: data_period
                };

                axios.request(configs)
                    .then((response) => {
                        console.log(JSON.stringify(response.data));
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                const url = process.env.VUE_APP_RISK_URL_API + 'rules/' + this.getPlatformId + '/' + id;
                var data = JSON.stringify({
                    "rules_id": this.rules.rules_id,
                    "description_rule": this.rules.description_rule,
                    "max_risk": this.rules.max_risk,
                    "single_max_total_risk": this.rules.single_max_total_risk,
                    "single_max_total_risk_action": this.rules.single_max_total_risk_action,
                    "single_min_bet": this.rules.single_min_bet,
                    "single_min_bet_action": this.rules.single_min_bet_action,
                    "single_max_bet": this.rules.single_max_bet,
                    "single_max_bet_action": this.rules.single_max_bet_action,
                    "single_max_win": this.rules.single_max_win,
                    "single_max_win_action": this.rules.single_max_win_action,
                    "block_max_amount": this.rules.block_max_amount,
                    "block_max_amount_action": this.rules.block_max_amount_action,
                    "single_min_odds": this.rules.single_min_odds,
                    "single_max_odds": this.rules.single_max_odds,
                    "single_max_odds_action": this.rules.single_max_odds_action,
                    "live_single_max_percentage_win": this.rules.live_single_max_percentage_win,
                    "live_single_max_percentage_win_action": this.rules.live_single_max_percentage_win_action,
                    "live_single_max_bet": this.rules.live_single_max_bet,
                    "live_single_max_bet_action": this.rules.live_single_max_bet_action,
                    "live_single_max_win": this.rules.live_single_max_win,
                    "live_single_max_win_action": this.rules.live_single_max_win_action,
                    "prematch_single_max_percentage_win": this.rules.prematch_single_max_percentage_win,
                    "prematch_single_max_percentage_win_action": this.rules.prematch_single_max_percentage_win_action,
                    "mixed_single_max_percentage_win": this.rules.mixed_single_max_percentage_win,
                    "mixed_single_max_percentage_win_action": this.rules.mixed_single_max_percentage_win_action,
                    "single_max_repeat": this.rules.single_max_repeat,
                    "single_max_repeat_action": this.rules.single_max_repeat_action,
                    "single_max_repeat_amount": this.rules.single_max_repeat_amount,
                    "single_max_repeat_amount_action": this.rules.single_max_repeat_amount_action,
                    "single_live_max_in_progress": this.rules.single_live_max_in_progress,
                    "single_live_max_in_progress_action": this.rules.single_live_max_in_progress_action,
                    "single_prematch_max_in_progress": this.rules.single_prematch_max_in_progress,
                    "single_prematch_max_in_progress_action": this.rules.single_prematch_max_in_progress_action,
                    "multiple_max_total_risk": this.rules.multiple_max_total_risk,
                    "multiple_max_total_risk_action": this.rules.multiple_max_total_risk_action,
                    "multiple_min_bet": this.rules.multiple_min_bet,
                    "multiple_min_bet_action": this.rules.multiple_min_bet_action,
                    "multiple_max_bet": this.rules.multiple_max_bet,
                    "multiple_max_bet_action": this.rules.multiple_max_bet_action,
                    "multiple_max_win": this.rules.multiple_max_win,
                    "multiple_max_win_action": this.rules.multiple_max_win_action,
                    "multiple_min_odds": this.rules.multiple_min_odds,
                    "multiple_max_odds": this.rules.multiple_max_odds,
                    "multiple_max_odds_action": this.rules.multiple_max_odds_action,
                    "live_multiple_max_bet": this.rules.live_multiple_max_bet,
                    "live_multiple_max_bet_action": this.rules.live_multiple_max_bet_action,
                    "live_multiple_max_win": this.rules.live_multiple_max_win,
                    "live_multiple_max_win_action": this.rules.live_multiple_max_win_action,
                    "live_multiple_max_percentage_win": this.rules.live_multiple_max_percentage_win,
                    "live_multiple_max_percentage_win_action": this.rules.live_multiple_max_percentage_win_action,
                    "prematch_multiple_max_percentage_win": this.rules.prematch_multiple_max_percentage_win,
                    "prematch_multiple_max_percentage_win_action": this.rules.prematch_multiple_max_percentage_win_action,
                    "mixed_multiple_max_percentage_win": this.rules.mixed_multiple_max_percentage_win,
                    "mixed_multiple_max_percentage_win_action": this.rules.mixed_multiple_max_percentage_win_action,
                    "multiple_max_repeat": this.rules.multiple_max_repeat,
                    "multiple_max_repeat_action": this.rules.multiple_max_repeat_action,
                    "multiple_max_repeat_amount": this.rules.multiple_max_repeat_amount,
                    "multiple_max_repeat_amount_action": this.rules.multiple_max_repeat_amount_action,
                    "multiple_live_max_in_progress": this.rules.multiple_live_max_in_progress,
                    "multiple_live_max_in_progress_action": this.rules.multiple_live_max_in_progress_action,
                    "multiple_prematch_max_in_progress": this.rules.multiple_prematch_max_in_progress,
                    "multiple_prematch_max_in_progress_action": this.rules.multiple_prematch_max_in_progress_action,
                    "multiple_mixed_max_in_progress": this.rules.multiple_mixed_max_in_progress,
                    "multiple_mixed_max_in_progress_action": this.rules.multiple_mixed_max_in_progress_action,
                    "integral_max_total_risk": this.rules.integral_max_total_risk,
                    "integral_max_total_risk_action": this.rules.integral_max_total_risk_action,
                    "integral_min_bet": this.rules.integral_min_bet,
                    "integral_min_bet_action": this.rules.integral_min_bet_action,
                    "integral_max_bet": this.rules.integral_max_bet,
                    "integral_max_bet_action": this.rules.integral_max_bet_action,
                    "integral_max_win": this.rules.integral_max_win,
                    "integral_max_win_action": this.rules.integral_max_win_action,
                    "integral_min_odds": this.rules.integral_min_odds,
                    "integral_max_odds": this.rules.integral_max_odds,
                    "integral_max_odds_action": this.rules.integral_max_odds_action,
                    "live_integral_max_bet": this.rules.live_integral_max_bet,
                    "live_integral_max_bet_action": this.rules.live_integral_max_bet_action,
                    "live_integral_max_win": this.rules.live_integral_max_win,
                    "live_integral_max_win_action": this.rules.live_integral_max_win_action,
                    "live_integral_max_percentage_win": this.rules.live_integral_max_percentage_win,
                    "live_integral_max_percentage_win_action": this.rules.live_integral_max_percentage_win_action,
                    "prematch_integral_max_percentage_win": this.rules.prematch_integral_max_percentage_win,
                    "prematch_integral_max_percentage_win_action": this.rules.prematch_integral_max_percentage_win_action,
                    "mixed_integral_max_percentage_win": this.rules.mixed_integral_max_percentage_win,
                    "mixed_integral_max_percentage_win_action": this.rules.mixed_integral_max_percentage_win_action,
                    "integral_max_repeat": this.rules.integral_max_repeat,
                    "integral_max_repeat_action": this.rules.integral_max_repeat_action,
                    "integral_max_repeat_amount": this.rules.integral_max_repeat_amount,
                    "integral_max_repeat_amount_action": this.rules.integral_max_repeat_amount_action,
                    "integral_live_max_in_progress": this.rules.integral_live_max_in_progress,
                    "integral_live_max_in_progress_action": this.rules.integral_live_max_in_progress_action,
                    "integral_prematch_max_in_progress": this.rules.integral_prematch_max_in_progress,
                    "integral_prematch_max_in_progress_action": this.rules.integral_prematch_max_in_progress_action,
                    "integral_mixed_max_in_progress": this.rules.integral_mixed_max_in_progress,
                    "integral_mixed_max_in_progress_action": this.rules.integral_mixed_max_in_progress_action,
                    "system_max_total_risk": this.rules.system_max_total_risk,
                    "system_max_total_risk_action": this.rules.system_max_total_risk_action,
                    "system_min_bet": this.rules.system_min_bet,
                    "system_min_bet_action": this.rules.system_min_bet_action,
                    "system_max_bet": this.rules.system_max_bet,
                    "system_max_bet_action": this.rules.system_max_bet_action,
                    "system_max_win": this.rules.system_max_win,
                    "system_max_win_action": this.rules.system_max_win_action,
                    "system_min_odds": this.rules.system_min_odds,
                    "system_max_odds": this.rules.system_max_odds,
                    "system_max_odds_action": this.rules.system_max_odds_action,
                    "live_system_max_bet": this.rules.live_system_max_bet,
                    "live_system_max_bet_action": this.rules.live_system_max_bet_action,
                    "live_system_max_win": this.rules.live_system_max_win,
                    "live_system_max_win_action": this.rules.live_system_max_win_action,
                    "live_system_max_percentage_win": this.rules.live_system_max_percentage_win,
                    "live_system_max_percentage_win_action": this.rules.live_system_max_percentage_win_action,
                    "prematch_system_max_percentage_win": this.rules.prematch_system_max_percentage_win,
                    "prematch_system_max_percentage_win_action": this.rules.prematch_system_max_percentage_win_action,
                    "mixed_system_max_percentage_win": this.rules.mixed_system_max_percentage_win,
                    "mixed_system_max_percentage_win_action": this.rules.mixed_system_max_percentage_win_action,
                    "system_max_repeat": this.rules.system_max_repeat,
                    "system_max_repeat_action": this.rules.system_max_repeat_action,
                    "system_max_repeat_amount": this.rules.system_max_repeat_amount,
                    "system_max_repeat_amount_action": this.rules.system_max_repeat_amount_action,
                    "system_live_max_in_progress": this.rules.system_live_max_in_progress,
                    "system_live_max_in_progress_action": this.rules.system_live_max_in_progress_action,
                    "system_prematch_max_in_progress": this.rules.system_prematch_max_in_progress,
                    "system_prematch_max_in_progress_action": this.rules.system_prematch_max_in_progress_action,
                    "system_mixed_max_in_progress": this.rules.system_mixed_max_in_progress,
                    "system_mixed_max_in_progress_action": this.rules.system_mixed_max_in_progress_action
                })

                let config = {
                    method: 'put',
                    maxBodyLength: Infinity,
                    url: url,
                    headers: {
                        'Authorization': 'Bearer ' + this.getToken,
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                axios.request(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data));
                        window.scrollTo(0, 0);
                        this.opendialog = true;
                        this.refreshData();
                        setTimeout(() => {
                            this.opendialog = false;
                            this.newRule = false;
                        }, 2000)


                    })
                    .catch((error) => {
                        console.log(error);
                        this.opendialogerror = true;
                        window.scrollTo(0, 0);
                        setTimeout(() => {
                            this.opendialogerror = false
                        }, 5000)
                    });

            },
            async saveNewRules(id) {

                if (id == '') {
                    this.opendialogerror = true;
                    setTimeout(() => {
                        this.opendialogerror = false
                    }, 5000)
                    return
                }

                const url = process.env.VUE_APP_RISK_URL_API + 'rules/store';
                this.selectedRule = this.rules.rules_id;
                var data = JSON.stringify({
                    "rules_id": this.rules.rules_id,
                    "description_rule": "Rules for platform id: " + this.getPlatformId,
                    "platform_id": this.getPlatformId,
                    "max_risk": 100000,
                    "single_max_total_risk": 10000,
                    "single_max_total_risk_action": "check",
                    "single_min_bet": 2,
                    "single_min_bet_action": "reject",
                    "single_max_bet": 500,
                    "single_max_bet_action": "check",
                    "single_max_win": 5000,
                    "single_max_win_action": "check",
                    "block_max_amount": 5000,
                    "block_max_amount_action":  "check",
                    "single_min_odds": 1.02,
                    "single_max_odds": 20,
                    "single_max_odds_action": "check",
                    "live_single_max_bet": 2,
                    "live_single_max_bet_action": "reject",
                    "live_single_max_win": 5000,
                    "live_single_max_win_action": "check",
                    "live_single_max_percentage_win": 85,
                    "live_single_max_percentage_win_action": "check",
                    "prematch_single_max_percentage_win": 90,
                    "prematch_single_max_percentage_win_action": "check",
                    "mixed_single_max_percentage_win": 85,
                    "mixed_single_max_percentage_win_action": "check",
                    "single_max_repeat": 20,
                    "single_max_repeat_action": "check",
                    "single_max_repeat_amount": 4,
                    "single_max_repeat_amount_action": "check",
                    "single_live_max_in_progress": 15000,
                    "single_live_max_in_progress_action": "reject",
                    "single_prematch_max_in_progress": 150000,
                    "single_prematch_max_in_progress_action": "reject",
                    "multiple_max_total_risk": 80000,
                    "multiple_max_total_risk_action": "check",
                    "multiple_min_bet": 2,
                    "multiple_min_bet_action": "reject",
                    "multiple_max_bet": 500,
                    "multiple_max_bet_action": "check",
                    "multiple_max_win": 5000,
                    "multiple_max_win_action": "check",
                    "multiple_min_odds": 1.02,
                    "multiple_max_odds": 30,
                    "multiple_max_odds_action": "check",
                    "live_multiple_max_bet": 2,
                    "live_multiple_max_bet_action": "reject",
                    "live_multiple_max_win": 5000,
                    "live_multiple_max_win_action": "check",
                    "live_multiple_max_percentage_win": 85,
                    "live_multiple_max_percentage_win_action": "check",
                    "prematch_multiple_max_percentage_win": 90,
                    "prematch_multiple_max_percentage_win_action": "check",
                    "mixed_multiple_max_percentage_win": 85,
                    "mixed_multiple_max_percentage_win_action": "check",
                    "multiple_max_repeat": 20,
                    "multiple_max_repeat_action": "check",
                    "multiple_max_repeat_amount": 4,
                    "multiple_max_repeat_amount_action": "check",
                    "multiple_live_max_in_progress": 15000,
                    "multiple_live_max_in_progress_action": "reject",
                    "multiple_prematch_max_in_progress": 150000,
                    "multiple_prematch_max_in_progress_action": "reject",
                    "multiple_mixed_max_in_progress": 15000,
                    "multiple_mixed_max_in_progress_action": "reject",
                    "integral_max_total_risk": 80000,
                    "integral_max_total_risk_action": "check",
                    "integral_min_bet": 2,
                    "integral_min_bet_action": "reject",
                    "integral_max_bet": 100,
                    "integral_max_bet_action": "check",
                    "integral_max_win": 3000,
                    "integral_max_win_action": "check",
                    "integral_min_odds": 1.2,
                    "integral_max_odds": 20,
                    "integral_max_odds_action": "check",
                    "live_integral_max_bet": 100,
                    "live_integral_max_bet_action": "check",
                    "live_integral_max_win": 3000,
                    "live_integral_max_win_action": "check",
                    "live_integral_max_percentage_win": 85,
                    "live_integral_max_percentage_win_action": "check",
                    "prematch_integral_max_percentage_win": 90,
                    "prematch_integral_max_percentage_win_action": "check",
                    "mixed_integral_max_percentage_win": 85,
                    "mixed_integral_max_percentage_win_action": "check",
                    "integral_max_repeat": 20,
                    "integral_max_repeat_action": "check",
                    "integral_max_repeat_amount": 4,
                    "integral_max_repeat_amount_action": "check",
                    "integral_live_max_in_progress": 15000,
                    "integral_live_max_in_progress_action": "reject",
                    "integral_prematch_max_in_progress": 15000,
                    "integral_prematch_max_in_progress_action": "reject",
                    "integral_mixed_max_in_progress": 15000,
                    "integral_mixed_max_in_progress_action": "reject",
                    "system_max_total_risk": 80000,
                    "system_max_total_risk_action": "check",
                    "system_min_bet": 2,
                    "system_min_bet_action": "reject",
                    "system_max_bet": 100,
                    "system_max_bet_action": "check",
                    "system_max_win": 3000,
                    "system_max_win_action": "check",
                    "system_min_odds": 1.2,
                    "system_max_odds": 20,
                    "system_max_odds_action": "check",
                    "live_system_max_bet": 100,
                    "live_system_max_bet_action": "check",
                    "live_system_max_win": 3000,
                    "live_system_max_win_action": "check",
                    "live_system_max_percentage_win": 85,
                    "live_system_max_percentage_win_action": "check",
                    "prematch_system_max_percentage_win": 90,
                    "prematch_system_max_percentage_win_action": "check",
                    "mixed_system_max_percentage_win": 85,
                    "mixed_system_max_percentage_win_action": "check",
                    "system_max_repeat": 20,
                    "system_max_repeat_action": "check",
                    "system_max_repeat_amount": 4,
                    "system_max_repeat_amount_action": "check",
                    "system_live_max_in_progress": 15000,
                    "system_live_max_in_progress_action": "reject",
                    "system_prematch_max_in_progress": 15000,
                    "system_prematch_max_in_progress_action": "reject",
                    "system_mixed_max_in_progress": 15000,
                    "system_mixed_max_in_progress_action": "reject",
                })

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: url,
                    headers: {
                        'Authorization': 'Bearer ' + this.getToken,
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                axios.request(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data));
                        this.opendialog = true;
                        this.refreshData();
                        setTimeout(() => {
                            this.opendialog = false;
                        }, 2000);
                        this.newRule = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.opendialogerror = true;
                        setTimeout(() => {
                            this.opendialogerror = false
                        }, 5000)
                    });

            },
            async getAllPeriods() {
                if (this.getPlatformId > 0) {
                    const url_date = process.env.VUE_APP_RISK_URL_API + 'rules/period/' + this.getPlatformId;
                    await axios.get(`${url_date}`, {'headers': {'Authorization': `Bearer ${this.getToken}`}})
                        .then(async (res) => {
                            const data = res.data.data;
                            this.tableItems = [];

                            for (let i = 0; i < data.length; i++) {

                                const d = new Date();
                                let time_from;
                                let time_to;

                                if (this.getTimezone != '') {
                                    time_from = moment.utc(d.getFullYear() + '-' + (d.getMonth() + 1).toString().padStart(2, '0') + '-' + (d.getDate().toString().padStart(2, '0')) + ' ' + data[i].time_from).tz(this.getTimezone).format('HH:mm')
                                    time_to = moment.utc(d.getFullYear() + '-' + (d.getMonth() + 1).toString().padStart(2, '0') + '-' + (d.getDate().toString().padStart(2, '0')) + ' ' + data[i].time_to).tz(this.getTimezone).format('HH:mm')
                                }else{
                                    time_from = data[i].time_from
                                    time_to = data[i].time_to
                                }

                                var item = {
                                    "description": data[i].rules_id,
                                    "from": new Date(data[i].from).toISOString().slice(0, 10),
                                    "to": new Date(data[i].to).toISOString().slice(0, 10),
                                    "time_from": time_from,
                                    "time_to": time_to,
                                };
                                this.tableItems.push(item);
                            }
                        })
                }
            },
        }
    }
</script>

<style scoped>
    .content-bet .v-btn {
        font-size: 12px;
        max-height: 20px;
    }

    .context-bet {
        text-transform: capitalize;
        background-color: #eeeeee;
        padding: 5px;
        border-radius: 4px;
        font-weight: bold;
        font-size: 13px;
    }

    .context-bet.prematch {
        background-color: #eeeeee;
    }

    .context-bet.live {
        background-color: #4ecd00;
        color: white;
    }

    .context-bet.mixed {
        background-color: #ff6600;
        color: white;
    }

    .userInfoLabel {
        background-color: #eeeeee;
        padding: 5px;
        font-weight: bold;
        border-radius: 4px;
        cursor: pointer;
        font-size: 12px;
    }

    .platfomInfoLabel {
        background-color: #297099;
        color: #fff;
        padding: 5px;
        font-weight: bold;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
    }

    .msg-error {
        color: white;
        border-radius: 4px;
        padding: 5px;
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .mdi-thumb-up::before {
        font-size: 20px;
        padding-right: 5px;
    }

    .mdi-thumb-down::before {
        font-size: 20px;
        padding-right: 5px;
    }

    .bk-live {
        /*background: #4ecd00 !important;*/
        background-color: rgba(78, 205, 0, 0.20) !important;
    }

    .bk-live .v-text-field--outlined fieldset {
        background: #ffffff !important;
    }

    #saveButton, #generateButton {
        margin-bottom: 5px !important;
    }
</style>
