<template>
    <div id="combinableMarkets">
        <div>
            <v-container fluid>
                <v-layout row>
                    <v-flex md12>
                        <v-col cols="12" class="container-button">

                            <v-btn v-if="showTree === false" @click="toggleComponent()" class="buttonAction">
                                {{$t('addCombinableMarkets')}}
                            </v-btn>

                            <v-btn v-if="manageFeaturedTournaments === false" @click="toggleComponent()"
                                   class="buttonAction">
                                {{$t('manageCombinableMarkets')}}
                            </v-btn>

                            <v-btn v-if="showTree && showMarketSelector" @click="toggleMarketsSelector()"
                                   class="buttonAction">
                                {{$t('backToTree')}}
                            </v-btn>


                            <v-btn v-if="tournamentSelected" @click="toggleMarketsSelector()" color="blue-grey"
                                   class="buttonAction">
                                {{$t('selectTheMarkets')}}
                            </v-btn>

                        </v-col>
                    </v-flex>
                </v-layout>
            </v-container>
        </div>
        <div v-if="showTree && !showMarketSelector">
            <!-- TODO MOVE IN ANOTHER COMPONENT -->
            <v-container fluid>
                <v-layout row>
                    <v-flex md12>
                        <v-col cols="12">
                            <!--                            <span class="title-table">{{ this.$t('bets.tree') | capitalize}}</span>-->

                            <v-card class="tournament-info">
                                <div>
                                    <div>
                                        <span class="bold-text">{{$t('sportSelected')}}:</span> {{sportName}}
                                        ({{sportSearched}})
                                    </div>
                                    <div>
                                        <span class="bold-text">{{$t('categorySelected')}}:</span> {{categoryName}}
                                        ({{categorySearched}})
                                    </div>
                                    <div>
                                        <span class="bold-text">{{$t('tournamentSelected')}}:</span> {{tournamentName}}
                                        ({{tournamentSelected}})
                                    </div>
                                </div>
                            </v-card>

                            <v-card outlined elevation-0>
                                <v-container fluid>
                                    <v-row>
                                        <v-col cols="12" class="pt-0 pb-0">
                                            <div class="title-table text-right"> * {{$t('generic.itemNotTranslated')}}
                                            </div>
                                        </v-col>
                                        <v-col cols="4">

                                            <div class="title-table">{{$t('list')}} {{$t('sport')}}
                                                ({{getItemsSport.length}})
                                            </div>

                                            <v-progress-linear indeterminate color="secondary darken-2"
                                                               v-if="getPreload"></v-progress-linear>
                                            <v-simple-table dense class="table-theme border-all-theme">
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left">{{$t('id')}}</th>
                                                        <th class="text-left">{{$t('name')}}</th>
                                                        <th class="text-left">{{$t('status')}}</th>
                                                        <th class="text-left">Pos.</th>
                                                        <th class="text-left">{{$t('generic.actions')}}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-for="item in getItemsSport" :key="item.id"
                                                        v-bind:class="{ active_element: (sportSearched == item.id) }">
                                                        <td :title="item.id">{{ item.id }}</td>
                                                        <td>{{ item.name }}</td>
                                                        <td class="actions-element">
                                                            <v-icon title="Cambia status"
                                                                    :class="(item.active) ? 'active' : 'disabled'">
                                                                {{ (item.active) ? 'mdi-plus' : 'mdi-close-thick' }}
                                                            </v-icon>
                                                        </td>
                                                        <td><input v-model.number="item.order" type="number"
                                                                   class="order-input"/>
                                                        </td>
                                                        <td class="actions-element">
                                                            <v-icon title="Show Categories"
                                                                    @click="getCategoriesFromSport(item)"
                                                                    v-if="item.active">zoom_in
                                                            </v-icon>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>

                                        <v-col cols="4" v-if="sportSearched != null && sportSearched != ''">

                                            <div class="title-table">{{$t('list')}} {{$t('category')}}
                                                ({{getItemsCategories.length}})
                                            </div>

                                            <v-progress-linear indeterminate color="secondary darken-2"
                                                               v-if="getPreload"></v-progress-linear>
                                            <v-simple-table dense class="table-theme border-all-theme">
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left">{{$t('id')}}</th>
                                                        <th class="text-left">{{$t('name')}}</th>
                                                        <th class="text-left">{{$t('status')}}</th>
                                                        <th class="text-left">Pos.</th>
                                                        <th class="text-left">{{$t('generic.actions')}}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-for="item in getItemsCategories" :key="item.id"
                                                        v-bind:class="{ active_element: (categorySearched == item.id) }">
                                                        <td :title="item.id">{{ item.id }}</td>
                                                        <td>{{ item.name }}</td>
                                                        <td class="actions-element">

                                                            <v-icon title="Cambia status"
                                                                    :class="(item.active) ? 'active' : 'disabled'">
                                                                {{ (item.active) ? 'check_circle' : 'highlight_off' }}
                                                            </v-icon>
                                                        </td>
                                                        <td><input v-model.number="item.order" type="number"
                                                                   class="order-input"/>
                                                        </td>
                                                        <td class="actions-element">
                                                            <v-icon title="Mostra Tornei"
                                                                    @click="getTournamentsFromCategories(item)"
                                                                    v-if="item.active">zoom_in
                                                            </v-icon>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                        <v-col cols="4" v-if="categorySearched != null && categorySearched != ''">
                                            <div class="title-table">{{$t('list')}} {{$t('bets.tournament')}}
                                                ({{getItemsTournaments.length}})
                                            </div>

                                            <v-progress-linear indeterminate color="secondary darken-2"
                                                               v-if="getPreload"></v-progress-linear>
                                            <v-simple-table dense class="table-theme border-all-theme">
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left">{{$t('id')}}</th>
                                                        <th class="text-left">{{$t('name')}}</th>
                                                        <th class="text-left">{{$t('status')}}</th>
                                                        <th class="text-left">Pos.</th>
                                                        <th class="text-left" v-if="getPlatformId">
                                                            {{$t('selectTournament')}}
                                                        </th>
                                                        <!--                                                    <th class="text-left" v-if="getPlatformId">-->
                                                        <!--                                                        {{$t('generic.delete')}}-->
                                                        <!--                                                    </th>-->
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-for="item in getItemsTournaments" :key="item.id"
                                                        v-bind:class="{ active_element: (tournamentSelected == item.id) }">
                                                        <td :title="item.id">{{ item.id }}</td>
                                                        <td class="name-element-tree">{{ item.name }}</td>
                                                        <td class="actions-element">
                                                            <v-icon title="Cambia status"
                                                                    :class="(item.active) ? 'active' : 'disabled'">
                                                                {{ (item.active) ? 'check_circle' : 'highlight_off' }}
                                                            </v-icon>
                                                        </td>
                                                        <td nowrap><input v-model.number="item.order" type="number"
                                                                          class="order-input"/>
                                                        </td>
                                                        <td>

                                                            <v-icon title="Set Tournament in Evidence" dense
                                                                    @click="setAndLoadCombinableMarkets(item, $event)">
                                                                zoom_in
                                                            </v-icon>


                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-flex>
                </v-layout>
            </v-container>

            <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'"
                        :timeout="snackbar.timeout" :vertical="snackbar.mode === 'vertical'">
                {{ snackbar.text }}
                <v-btn dark text @click="snackbar.snackbar = false">
                    {{$t('close')}}
                </v-btn>
            </v-snackbar>

        </div>

        <div v-if="showTree && showMarketSelector">

            <v-card class="tournament-info">
                <div>
                    <div>
                        <span class="bold-text">{{$t('sportSelected')}}:</span> {{sportName}} ({{sportSearched}})
                    </div>
                    <div>
                        <span class="bold-text">{{$t('categorySelected')}}:</span> {{categoryName}}
                        ({{categorySearched}})
                    </div>
                    <div>
                        <span class="bold-text">{{$t('tournamentSelected')}}:</span> {{tournamentName}}
                        ({{tournamentSelected}})
                    </div>

                    <div style="margin-top: 30px;">
                        <v-btn @click="updateCombinedMarkets()" v-if="tournamentSelected != null">
                            {{$t('updateCombinedMarkets')}}
                        </v-btn>
                    </div>

                </div>
            </v-card>

            <v-container fluid v-if="loadingCombinableMarkets === false">
                <v-row>
                    <v-col cols="6">
                        <v-card>
                            <v-data-table v-if="marketsList != null"
                                          :headers="headers_preview"
                                          :items="marketsList"
                                          class="elevation-1"
                                          :items-per-page="30"
                                          :search="search"
                                          item-key="unique_id"
                            >
                                <template v-slot:top>
                                    <v-text-field
                                            v-model="search"
                                            :label="$t('generic.search')"
                                            class="mx-4"
                                    ></v-text-field>
                                </template>

                                <template v-slot:item.actions="{ item }">

                                    <v-icon @click="addOrRemoveCombinedMarket(item)">
                                        {{ isPresent(item) ? 'mdi-close-thick' : 'mdi-plus' }}
                                    </v-icon>

                                </template>
                            </v-data-table>
                        </v-card>
                    </v-col>

                    <v-col cols="4">
                        <v-card>
                            <v-data-table v-if="combinedMarkets != null"
                                          :headers="headers_markets"
                                          :items="combinedMarkets"
                                          class="elevation-1"
                                          :items-per-page="30"
                                          :search="search"
                                          item-key="unique_id">

                                <template v-slot:item.actions="{ item }">

                                    <v-icon @click="removeCombinableMarkets(item)">
                                        {{ isPresent(item) ? 'mdi-close-thick' : 'mdi-plus' }}
                                    </v-icon>

                                </template>

                                <!--                                <template v-slot:top>-->
                                <!--                                    <v-text-field-->
                                <!--                                            v-model="search"-->
                                <!--                                            :label="$t('generic.search')"-->
                                <!--                                            class="mx-4"-->
                                <!--                                    ></v-text-field>-->
                                <!--                                </template>-->

                                <!--                                <template v-slot:item.actions="{ item }">-->
                                <!--                                    <v-icon @click="addOrRemoveCombinedMarket(item)">mdi-magnify</v-icon>-->
                                <!--                                </template>-->

                            </v-data-table>
                        </v-card>
                    </v-col>

                </v-row>


            </v-container>
        </div>
        <div v-if="manageFeaturedTournaments">
            <!--            <template>-->
            <!--                <combinable-markets-list></combinable-markets-list>-->
            <!--            </template>-->

            <combinable-markets-list></combinable-markets-list>
        </div>
    </div>

</template>
<style>

</style>
<script>
    import {mapActions, mapGetters, mapMutations} from 'vuex';
    import combinableMarketsList from "../../components/betConsole/combinableMarketsList";

    export default {
        name: "CombinableMarkets",

        data() {
            return {
                headers_preview: [
                    {text: this.$t('generic.id'), value: 'unique_id'},
                    {text: this.$t('generic.name'), value: 'name'},
                    {text: this.$t('generic.actions'), value: 'actions'},
                ],
                headers_markets: [
                    {text: this.$t('generic.id'), value: 'id'},
                    {text: this.$t('generic.name'), value: 'name'},
                    {text: this.$t('generic.actions'), value: 'actions'}
                ],


                scope: '',
                sportSearched: '',
                sportName: null,
                sport_Id_Searched: null,
                categorySearched: '',
                categoryName: null,
                tournamentSelected: null,
                tournamentName: null,
                snackbar: {
                    snackbar: false,
                    color: '',
                    mode: '',
                    timeout: 6000,
                    text: '',
                },
                showTree: true,
                manageFeaturedTournaments: false,
                showMarketSelector: false,
                marketsList: [],
                search: '',
                combinedMarkets: [],
                loadingCombinableMarkets: false,
                stopWatching: false
            }
        },

        components: {combinableMarketsList: combinableMarketsList},

        watch: {
            getLanguage: async function (val) {
                await this.setPreload(true);
                await this.setLanguage(val);

                await this.filterSport({'language': val});
                await this.filterCategories({'language': val});
                await this.filterTournaments({'language': val});

                await this.setPreload(false);
            },

            sportSearched(newValue, oldValue) {

                if (this.stopWatching === false) {
                    this.categorySearched = null;
                    this.categoryName = null;
                    this.tournamentSelected = null;
                    this.tournamentName = null;
                }
            },

            categorySearched(newValue, oldValue) {
                if (this.stopWatching === false) {
                    this.tournamentSelected = null;
                    this.tournamentName = null;
                }
            },

            async getMarketsMini() {
                this.marketsList = this.getMarketsMini.markets;
            },
            async showMarketSelector(newValue, oldValue) {

                if (this.stopWatching === false && newValue === true && oldValue === false) {
                    if (this.tournamentSelected) {
                        await this.getCombinableMarketsForTournament(this.tournamentSelected);
                    }
                }
            }
        },

        computed: {
            ...mapGetters({
                'getPreload': 'utils/getPreload',
                'getLanguage': 'profile/getLanguage',

                'getItemsSport': 'sport/getItems',
                'getCategories': 'category/getCategories',
                'getItemsCategories': 'category/getItems',
                'getTournaments': 'tournament/getTournaments',
                'getItemsTournaments': 'tournament/getItems',
                'getPlatformId': 'rules/getPlatformId',
                'getPlatform': 'profile/getPlatform',
                'getMarketsMini': 'utils/getMarketsMini',
            }),
        },
        async created() {

            await this.setPreload(true);
            await this.fetchSports({'language': this.getLanguage});
            await this.fetchNotPublished();
            await this.setPreload(false);

        },
        async mounted() {


            const vm = this;

            this.$root.$on('editCombinableMarketEvent', async (ev) => {

                this.stopWatching = true;

                vm.sportSearched = ev.sportId;
                vm.sportName = ev.sportName;
                vm.sport_Id_Searched = null;
                vm.categorySearched = ev.categoryId;
                vm.categoryName = ev.categoryName;
                vm.tournamentSelected = ev.tournamentId;
                vm.tournamentName = ev.tournamentName;

                await this.setPreload(true);

                const sportSelected = vm.getItemsSport.find(el => {
                    return el.id === +vm.sportSearched
                })

                const sportSelected_id = sportSelected._id;

                vm.sport_Id_Searched = sportSelected_id;

                await this.getMarketsBySportList({
                    'sport': sportSelected_id,
                    'lang': this.getLanguage,
                    'enableSpecifiers': false
                });


                vm.sport_Id_Searched = sportSelected_id;

                vm.loadingCombinableMarkets = false;
                vm.manageFeaturedTournaments = false;
                vm.showMarketSelector = true;
                vm.showTree = true;

                await vm.getCombinableMarketsForTournament(ev.tournamentId);

                await this.setPreload(false);
                this.stopWatching = false;

            })
        },
        beforeDestroy() {

            this.$root.$off('editCombinableMarketEvent');
        },
        methods: {

            ...mapActions({
                'setPreload': 'utils/setPreload',
                'fetchSports': 'sport/fetchSports',
                'filterSport': 'sport/filterSport',
                'fetchCategories': 'category/fetchCategories',
                'filterCategories': 'category/filterCategories',
                'fetchTournaments': 'tournament/fetchTournaments',
                'filterTournaments': 'tournament/filterTournament',
                'resetTournaments': 'tournament/resetTournaments',
                'fetchSkins': 'skins/fetchSkins',
                'fetchNotPublished': 'tree/fetchNotPublished',
                'getMarketsBySportList': 'utils/getMarketsBySportList',
                'getCombinableMarketsByTournamentId': 'market/getCombinableMarketsByTournamentId',
                'updateCombinableMarketsForTournament': 'market/updateCombinableMarketsForTournament'
            }),

            ...mapMutations({
                'setLanguage': 'profile/setLanguage',
            }),

            toggleComponent() {
                this.manageFeaturedTournaments = !this.manageFeaturedTournaments;
                this.showTree = !this.showTree;
            },

            async getCategoriesFromSport(sport) {

                const sport_Id = sport._id;

                await this.setPreload(true);
                this.sportSearched = sport.id;
                this.sportName = sport.name;

                await this.fetchCategories({'language': this.getLanguage, 'paramKey': sport.id});
                await this.resetTournaments();

                await this.getMarketsBySportList({
                    'sport': sport_Id,
                    'lang': this.getLanguage,
                    'enableSpecifiers': false
                });


                await this.setPreload(false);
            },

            async getTournamentsFromCategories(category) {

                const categoryId = category.id;
                const categoryName = category.name;

                await this.setPreload(true);
                this.categorySearched = categoryId;
                this.categoryName = categoryName;
                await this.fetchTournaments({'language': this.getLanguage, 'paramKey': categoryId});

                await this.fetchSkins();
                await this.setPreload(false);

            },

            async deleteCombinableMarketsForPlatform() {

                const vm = this;

                const messageWarning = this.$t('generic.areYouSureToDeleteCombinableMarketsForYourPlatform');

                let isExecuted = confirm(messageWarning);

                if (isExecuted === true) {
                    try {
                        const platformId = vm.getPlatformId;

                        if (platformId == null || platformId == '0' || platformId == 0) {
                            return;
                        }

                        // TODO deleteCombinableMarketsForPlatform

                        this.featuredTournaments = [];

                        this.$swal.fire({
                            position: "center",
                            icon: "success",
                            title: this.$t('successOperation'),
                            showConfirmButton: false,
                            timer: 1000
                        });

                    } catch (e) {
                        console.log(e);
                        vm.$swal.fire(vm.$t('operationNotPerformedCorrectly'), '', 'error');
                    }
                }
            },

            setTournament(item) {

                this.tournamentSelected = item.id != null ? item.id : null;
                this.tournamentName = item.name != null ? item.name : null;
            },

            async getCombinableMarketsForTournament(tournamentId) {

                try {

                    this.combinedMarkets = [];

                    this.loadingCombinableMarkets = true;

                    const platformId = this.getPlatformId;
                    const sportId = this.sportSearched;
                    const categoryId = this.categorySearched;

                    const payload = {
                        platformId: platformId,
                        sportId: sportId,
                        categoryId: categoryId,
                        tournamentId: tournamentId
                    }

                    const response = await this.getCombinableMarketsByTournamentId(payload);

                    const combinedMarketsFromApi = response.combinableMarkets.markets

                    for (const marketId of combinedMarketsFromApi) {

                        const foundElement = this.marketsList.find(el => {
                            return el.id === +marketId
                        })

                        this.combinedMarkets.push({
                            name: foundElement.name,
                            id: marketId.toString()
                        })
                    }

                    this.loadingCombinableMarkets = false;

                } catch (e) {
                    console.log(e)
                    this.loadingCombinableMarkets = true;
                }
            },

            async setAndLoadCombinableMarkets(item) {
                this.setTournament(item);

            },

            removeCombinableMarketsForTournament(item) {

                console.log('todo')
            },

            toggleMarketsSelector() {
                this.showMarketSelector = !this.showMarketSelector;
            },

            addOrRemoveCombinedMarket(item) {

                const market = {
                    name: item.name,
                    id: item.id.toString()
                }

                const foundElement = this.combinedMarkets.find(el => {
                    return el.id === item.id.toString()
                })

                if (foundElement) {
                    this.combinedMarkets = this.combinedMarkets.filter(el => {
                        return el.id != item.id
                    })

                } else {
                    this.combinedMarkets.push(market)
                }

            },


            async updateCombinedMarkets() {

                const vm = this;
                const platformId = this.getPlatformId;
                const sportId = this.sportSearched;
                const categoryId = this.categorySearched;
                const tournamentId = this.tournamentSelected;
                const markets = []

                for (const market of this.combinedMarkets) {
                    markets.push(market.id)
                }

                if (platformId == null || sportId == null || categoryId == null || tournamentId == null) {
                    return null
                }

                try {

                    const response = await this.updateCombinableMarketsForTournament({
                        platformId: platformId,
                        sportId: sportId,
                        categoryId: categoryId,
                        tournamentId: tournamentId,
                        markets: JSON.stringify(markets)
                    })

                    vm.$swal.fire(vm.$t('successOperation'), '', 'success');


                } catch (e) {
                    console.log(e);
                    vm.$swal.fire(this.$t('operationNotPerformedCorrectly'), '', 'error');
                }

            },

            removeCombinableMarkets(item) {

                this.combinedMarkets = this.combinedMarkets.filter(el => {
                    return el.id != item.id
                })
            },

            isPresent(market) {

                const id = market.id.toString();

                const isPresent = this.combinedMarkets.findIndex(el => {
                    return el.id === id
                })

                return isPresent != -1 ? true : false

            }
        },
    }
</script>

<style scoped>

    .v-icon.notranslate.v-icon--link.v-icon--dense.material-icons.theme--light.active.featuredTournament {
        color: red !important;
    }

    .container-button .buttonAction {
        max-width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        margin-right: 10px !important;
    }


    .tournament-info {
        padding: 20px;
    }

    .tournament-info .bold-text {
        font-weight: 700 !important;
    }
</style>
