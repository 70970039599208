import axios from "axios";

const state = {
    urlEndpoint: process.env.VUE_APP_RISK_URL_API,
    continueBeep: false
};

const getters = {

    getUrlEndpoint(state) {
        return state.urlEndpoint
    },
    getContinueBeep(state){
        return state.continueBeep
    }
};

const actions = {

    async getEntityRules(context, payload) {

        const groupType = payload.groupType;
        const entityType = payload.entityType;

        let url = `${context.rootGetters['risk/getUrlEndpoint']}rules-entity/${context.rootGetters['rules/getPlatformId']}/` + groupType + '/' + entityType;

        return await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                //console.log(res)
                return res

            }).catch((error) => {
                console.log(error)
                return error
            });

    },

    async saveEntityRule(context, payload) {

        let url = `${context.rootGetters['risk/getUrlEndpoint']}rules-entity/store`;

        return await axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return res;
            }).catch((error) => {
                console.log(error);

                const errorData = {
                    data: error.response.data,
                    isError: true
                }
                return errorData
            });
    },

    async updateEntityRule(context, payload) {

        const requestPayload = {
            limits_type: payload.limits_type,
            limits: payload.limits,
            limits_action: payload.limits_action
        }

        let url = `${context.rootGetters['risk/getUrlEndpoint']}rules-entity/` + payload._id

        const response = await axios.put(`${url}`,
            requestPayload,
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})

        return response;
    },

    async deleteEntityRule(context, payload) {

        const _id = payload._id;
        let url = `${context.rootGetters['risk/getUrlEndpoint']}rules-entity/` + _id
        const response = await axios.delete(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}});

        return response
    },
    async getEntityUserRules(context, payload) {

        const entityType = payload.entityType
        const idUser = payload.idUser;

        let url = `${context.rootGetters['risk/getUrlEndpoint']}rules-entity-user/${context.rootGetters['rules/getPlatformId']}/` + idUser;
        if (entityType != null && entityType != '') {
            url = url + '/' + entityType
        }

        return await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                //console.log(res)
                return res

            }).catch((error) => {
                console.log(error)
                return error
            });
    },
    async saveEntityUserRule(context, payload) {

        let url = `${context.rootGetters['risk/getUrlEndpoint']}rules-entity-user/store`;

        return await axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return res;
            }).catch((error) => {
                console.log(error);

                const errorData = {
                    data: error.response.data,
                    isError: true
                }
                return errorData
            });
    },

    async updateEntityUserRule(context, payload) {

        const requestPayload = {
            limits_type: payload.limits_type,
            limits: payload.limits,
            limits_action: payload.limits_action
        }

        let url = `${context.rootGetters['risk/getUrlEndpoint']}rules-entity-user/` + payload._id

        const response = await axios.put(`${url}`,
            requestPayload,
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})

        return response;
    },
    async deleteEntityUserRule(context, payload) {

        const _id = payload._id;
        let url = `${context.rootGetters['risk/getUrlEndpoint']}rules-entity-user/` + _id
        const response = await axios.delete(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}});

        return response
    },

};

const mutations = {

    setContinueBeep: (state, value) => {
        state.continueBeep = value != null ? value : false
    }
};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
