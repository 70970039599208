import store from '../store';
import Bets from '../views/bets/Index.vue'
import {checkauth, checkRoute} from "../Helper";
export default [
    {
        path: '/bets/list',
        name: 'bets',
        component: Bets,
        beforeEnter (to,from,next){
            checkRoute('/bets/list').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },

]
