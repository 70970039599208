import axios from "axios";

const state = {};

const getters = {};

const actions = {
    async cacheMarket(context, payload) {
        let url = `${context.rootGetters['settings/getUrlApi']}clearcache`;
        return await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(res => {
                return true
            }).catch(err => {
                console.log(err);
            });
    },
    async cacheSetting(context, payload) {
        let url = `${context.rootGetters['settings/getUrlApi']}clearcachesetting`;
        return await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(res => {
                return true
            }).catch(err => {
                console.log(err);
            });
    },
    async cacheRules(context, payload) {
        let url = `${context.rootGetters['settings/getUrlApi']}clearcacherule`;
        return await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(res => {
                //console.log(res)
                return true
            }).catch(err => {
                console.log(err);
            });
    },
    async cacheQuickbet(context, payload) {
        let url = `${context.rootGetters['settings/getUrlApi']}clearcachequickbet`;
        return await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(res => {
                return true
            }).catch(err => {
                console.log(err);
            });
    },
    async cacheCloudfront(context, payload) {
        let url = `${context.rootGetters['settings/getUrlApi']}clearcachecloudfront`;
        return await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(res => {
                return true
            }).catch(err => {
                console.log(err);
            });
    },

    async clearCachePreviewGroupsOdds(context, payload) {
        const url = `${context.rootGetters['settings/getUrlApi']}clearcachepreviewgroupsodds`;
        return await axios.post(`${url}`, {}, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(res => {
                return true
            }).catch(err => {
                console.log(err);
            });
    },
    async clearCacheTournamentPreview(context, payload) {
        const url = `${context.rootGetters['settings/getUrlApi']}clear-tournament-preview-cache`;
        return await axios.post(`${url}`, {}, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(res => {
                return true
            }).catch(err => {
                console.log(err);
            });
    },
    async clearCacheEventOfDay(context, payload) {
        const url = `${context.rootGetters['settings/getUrlApi']}clear-event-of-day-cache`;
        return await axios.post(`${url}`, {}, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(res => {
                return true
            }).catch(err => {
                console.log(err);
            });
    },

    async clearNextEventsCache(context, payload) {
        const url = `${context.rootGetters['settings/getUrlApi']}clear-next-events-cache`;
        return await axios.post(`${url}`, {}, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(res => {
                return true
            }).catch(err => {
                console.log(err);
            });
    },

    async clearAllNodeCache(context, payload) {
        const url = `${context.rootGetters['settings/getUrlApi']}clear-all-node-cache`;
        return await axios.post(`${url}`, {}, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(res => {
                return true
            }).catch(err => {
                console.log(err);
            });
    },

};

const mutations = {};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
