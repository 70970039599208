<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark>
            <v-toolbar-title>Login</v-toolbar-title>
            <v-spacer></v-spacer>

          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field v-model="form.email" :error-messages="messages.email" prepend-icon="person" label="Email"
                            type="text"></v-text-field>
              <v-text-field v-model="form.password" :error-messages="messages.password" prepend-icon="lock"
                            label="Password" type="password"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.prevent="login">Login</v-btn> <!--BORN152019!-->
          </v-card-actions>
        </v-card>
        <v-snackbar
            v-model="snackbar.snackbar"
            :color="snackbar.color"
            :multi-line="snackbar.mode === 'multi-line'"
            :timeout="snackbar.timeout"
            :vertical="snackbar.mode === 'vertical'"
        >
          {{ snackbar.text }}
          <v-btn
              dark
              text
              @click="snackbar.snackbar = false"
          >
            {{ $vuetify.lang.t('$vuetify.close') }}
          </v-btn>
        </v-snackbar>
      </v-flex>
    </v-layout>
  </v-container>

</template>

<script>
import axios from 'axios'
import router from '@/router'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

export default {
  name: "Login",
  data() {
    return {
      dialog: false,
      form: {
        name: '',
        password: '',
        checkbox: true,

      },
      messages: {
        name: [],
        password: [],
      },
      snackbar: {
        snackbar: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
      },
    }
  },
  props: {
    source: String
  },
  async created() {
    var vue = this
    setTimeout(function () {
      vue.setPreload(false);
    }, 3000);

  },
  computed: {
    ...mapGetters({
      'getAutenticate': 'profile/getAutenticate',
      'getUrl': 'settings/getUrl',

    }),
  },
  methods: {
    ...mapActions({
      'setPreload': 'utils/setPreload',
      'loginAuth': 'profile/asyncFetchData'
    }),
    ...mapMutations({
      'setName': 'profile/setName',
      'setEmail': 'profile/setEmail',
      'setAutenticate': 'profile/setAutenticate',
      'setToken': 'profile/setToken',
      'setRefresh': 'profile/setRefresh',
      'setId': 'profile/setId',
    }),

    async login(){
      let url = "auth/login";
      var payload = this.form;
      this.dialog = true;
      this.messages= {
        name: [],
        password: [],
      }
      //console.log(`${this.getUrl}${url}`);

      /* axios.post(`${this.getUrl}${url}`,  {
           headers: {
               'Content-Type': 'application/json',

           }},payload).then(response => {
           console.log(response)
       }).catch(error => {
           console.log("error", error)
       });*/

      try {
        const  dataresponse = await this.loginAuth(payload);

        if(dataresponse.status===422) {
          let errors = dataresponse.data.error;
          if (errors) {
            //console.log(errors);
            errors.forEach(value => {

              this.messages[value.param] = value.msg
            })

          }
        }


        if(dataresponse.data.message) {
          this.snackbar.color = "error";
          this.snackbar.text = dataresponse.data.message ;//err.response.data.message;
          this.snackbar.snackbar = true;
          this.snackbar.mode = 'multi-line';
          this.snackbar.timeout = 5000;
        }

        if(dataresponse.status===200){
          this.$router.push('/home');
        }

        this.dialog=false
      }catch (e) {
        //this.dialog=false
        console.log(e)
      }


    }
  }
}
</script>

<style scoped>

</style>
