import axios from "axios";
import _ from "lodash"

const state = {
    tree: [],
    active_tournaments: [],
    not_published:[]
};

const getters = {

    getTree(state) {
        return state.tree
    },

    getActiveTournaments(state) {
        return state.active_tournaments
    },

    getNotPublished(state){
        return state.not_published
    }

};

const actions = {
    async fetchNotPublished(context, payload) {

        let url = `${context.rootGetters['settings/getUrlApi']}get-elements-not-published`;

        await axios.post(`${url}`, {}, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})

            .then(res => {
                context.commit('setNotPublished', res.data);
            }).catch(err => {
                    console.log(err);
            });
    },

    async fetchTree(context, payload) {

        let url = `${context.rootGetters['settings/getUrlApi']}get-tree`;

        console.log(context.rootGetters['profile/getLanguage'])

        // Get Tree
        await axios.post(`${url}`, {'filter': 0, 'id_language': context.rootGetters['profile/getLanguage']}, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})

            .then(res => {

                // sono gli sport con quote di antepost, ma inseriti nella parte dell'albero come prematch
                var hybridSports = [];

                let onlyManager = (context.rootGetters['rules/getPlatformId'] == 0);
                if(!onlyManager){
                    var skinRules = _.find(context.rootGetters['skins/getList'], {"platform_id":context.rootGetters['rules/getPlatformId']}).rules
                }

                var managerRules = context.rootGetters['skins/getManager'].rules

                let rulesSchema = {
                    sportManager : (_.filter(managerRules, {'scope': 'sport'}).length > 0),
                    categoryManager : (_.filter(managerRules, {'scope': 'category'}).length > 0),
                    tournamentManager : (_.filter(managerRules, {'scope': 'tournament'}).length > 0),
                    sportSkin : (!onlyManager && _.filter(skinRules, {'scope': 'sport'}).length > 0),
                    categorySkin : (!onlyManager && _.filter(skinRules, {'scope': 'category'}).length > 0),
                    tournamentSkin : (!onlyManager && _.filter(skinRules, {'scope': 'tournament'}).length > 0),

                }

                    var treeTotal = [];
                    var treePrematch = [];
                    var treeAntepost = [];

                    _.forEach( res.data, (sport) => {

                        let status_sport = true;
                        var desc_sport = '';
                        var fallbackDesc_sport = '';
                        var categoriesChildren = [];
                        var categoriesChildrenAntepost = [];

                        /* Override from Rules */
                        if(rulesSchema.sportManager){

                            let rules_to_apply_manager = _.filter(managerRules, {'source': sport._id.toString()})
                            if(rules_to_apply_manager.length > 0){
                                _.forEach(rules_to_apply_manager, function (rule){
                                    if(rule.detail){
                                        if(rule.type == "translation"){
                                            sport.detail = rule.detail
                                        }

                                        if(rule.type == "status"){
                                            status_sport = rule.detail.active
                                        }

                                        if(rule.type == "order"){
                                            sport.order = rule.detail.order
                                        }
                                    }

                                })
                            }

                        }

                        if(rulesSchema.sportSkin){

                            let rules_to_apply_skin = _.filter(skinRules, {'source': sport._id.toString()})
                            if(rules_to_apply_skin.length > 0){
                                _.forEach(rules_to_apply_skin, function (rule){
                                    if(rule.detail){
                                        if(rule.type == "translation"){
                                            sport.detail = rule.detail
                                        }

                                        if(rule.type == "status"){
                                            status_sport = rule.detail.active
                                        }

                                        if(rule.type == "order"){
                                            sport.order = rule.detail.order
                                        }
                                    }

                                })
                            }

                        }

                        if(!status_sport){
                            return;
                        }
                        /* Override from Rules */


                        desc_sport = _.find(sport.detail, {'lang': context.rootGetters['profile/getLanguage']});
                        
                        if (!desc_sport) {
                            fallbackDesc_sport = _.find(sport.detail, {'lang': 'en'})
                            if(!fallbackDesc_sport){
                                fallbackDesc_sport = { text : sport._id }
                                console.log('Fallback error: '+sport._id)
                            }else{
                                console.log('Fallback: '+sport._id)
                            }
                        }

                        _.forEach(sport.sport_categories, function (category) {

                            /* Override from Rules */
                            let status_category = true;

                            if(rulesSchema.categoryManager){
                                let rules_to_apply_manager = _.filter(managerRules, {'source': category.id.toString()})
                                if(rules_to_apply_manager.length > 0){
                                    _.forEach(rules_to_apply_manager, function (rule){
                                        if(rule.detail){
                                            if(rule.type == "translation"){
                                                category.detail = rule.detail
                                            }

                                            if(rule.type == "status"){
                                                status_category = rule.detail.active
                                            }

                                            if(rule.type == "order"){
                                                category.order = rule.detail.order
                                            }
                                        }

                                    })
                                }
                            }

                            if(rulesSchema.categorySkin){
                                let rules_to_apply_skin = _.filter(skinRules, {'source': category.id.toString()})
                                if(rules_to_apply_skin.length > 0){
                                    _.forEach(rules_to_apply_skin, function (rule){
                                        if(rule.detail){
                                            if(rule.type == "translation"){
                                                category.detail = rule.detail
                                            }

                                            if(rule.type == "status"){
                                                status_category = rule.detail.active
                                            }

                                            if(rule.type == "order"){
                                                category.order = rule.detail.order
                                            }

                                        }

                                    })
                                }
                            }

                            if(!status_category){
                                return;
                            }

                            /* Override from Rules */

                            var desc_category = '';
                            var fallbackDesc_category = '';
                            var tournamentsChildren = [];
                            var tournamentsChildrenAntepost = [];

                            desc_category = _.find(category.detail, {'lang': context.rootGetters['profile/getLanguage']});

                            if (!desc_category) {
                                fallbackDesc_category = _.find(category.detail, {'lang': 'en'})
                                if(!fallbackDesc_category){
                                    fallbackDesc_category = { text : category.id }
                                    console.log('Fallback error: '+category.id)
                                }else{
                                    console.log('Fallback: '+category.id)
                                }
                            }

                            _.forEach(category.categories_tournaments, function (tournament) {

                                if (tournament.count_events > 0 || tournament.count_events_antepost > 0) {

                                    /* Override from Rules */
                                    let status_tournament = true;

                                    if(rulesSchema.tournamentManager){
                                        let rules_to_apply_manager = _.filter(managerRules, {'source': tournament.id.toString()})
                                        if(rules_to_apply_manager.length > 0){
                                            _.forEach(rules_to_apply_manager, function (rule){
                                                if(rule.detail){
                                                    if(rule.type == "translation"){
                                                        tournament.detail = rule.detail
                                                    }

                                                    if(rule.type == "status"){
                                                        status_tournament = rule.detail.active
                                                    }

                                                    if(rule.type == "order"){
                                                        tournament.order = rule.detail.order
                                                    }
                                                }

                                            })
                                        }
                                    }

                                    if(rulesSchema.tournamentSkin){
                                        let rules_to_apply_skin = _.filter(skinRules, {'source': tournament.id.toString()})
                                        if(rules_to_apply_skin.length > 0){
                                            _.forEach(rules_to_apply_skin, function (rule){
                                                if(rule.detail){
                                                    if(rule.type == "translation"){
                                                        tournament.detail = rule.detail
                                                    }

                                                    if(rule.type == "status"){
                                                        status_tournament = rule.detail.active
                                                    }

                                                    if(rule.type == "order"){
                                                        tournament.order = rule.detail.order
                                                    }
                                                }

                                            })
                                        }
                                    }

                                    if(!status_tournament){
                                        return;
                                    }

                                    /* Override from Rules */
                                    var desc_tournament = '';
                                    var fallbackDesc_tournament = '';

                                    desc_tournament = _.find(tournament.detail, {'lang': context.rootGetters['profile/getLanguage']});
                                    if (!desc_tournament) {
                                        fallbackDesc_tournament = _.find(tournament.detail, {'lang': 'en'})
                                        if(!fallbackDesc_tournament){
                                            fallbackDesc_tournament = { text : tournament.id }
                                            console.log('Fallback error: '+tournament.id)
                                        }else{
                                            console.log('Fallback: '+tournament.id)
                                        }
                                    }

                                    if(tournament.count_events > 0){

                                        const treeItemTournament = {
                                            id: tournament.id+'#prematch',
                                            icon_id: tournament.id,
                                            type: 'tournament',
                                            name: (desc_tournament) ? desc_tournament.text : fallbackDesc_tournament.text + '(n.t.)',
                                            count: tournament.count_events,
                                            typeOdds: 'prematch',
                                            order: (tournament.order) ? tournament.order:9999
                                        }

                                        tournamentsChildren.push(treeItemTournament);

                                    }



                                    if(tournament.count_events_antepost > 0){

                                        var eventsAntepostChildren = []

                                        _.forEach(tournament.detail_events_antepost, function (event) {

                                            var desc_event = '';
                                            var fallbackDesc_event = '';

                                            desc_event= _.find(event.detail, {'lang': context.rootGetters['profile/getLanguage']});
                                            if (!desc_event) {
                                                fallbackDesc_event = _.find(event.detail, {'lang': 'en'})
                                            }

                                            let name_event = (desc_event) ? desc_event.text:fallbackDesc_event.text

                                            let event_antepost = {
                                                id: event.id+'#antepost',
                                                name: name_event,
                                                type: 'antepost'
                                            }

                                            eventsAntepostChildren.push(event_antepost)

                                        });


                                        const treeItemTournamentAntepost = {
                                            id: tournament.id+'#antepost',
                                            icon_id: tournament.id,
                                            type: 'tournament',
                                            name: (desc_tournament) ? desc_tournament.text : fallbackDesc_tournament + '(n.t.)',
                                            children: [
                                                ...eventsAntepostChildren
                                            ],
                                            count: tournament.count_events_antepost,
                                            typeOdds: 'antepost',
                                            order: (tournament.order) ? tournament.order:9999
                                        }

                                        tournamentsChildrenAntepost.push(treeItemTournamentAntepost);

                                    }
                                }


                            });


                            var tournaments_pm_ordered = _.sortBy(tournamentsChildren, ['order','name'])
                            var tournaments_ap_ordered = _.sortBy(tournamentsChildrenAntepost, ['order','name'])

                            if (category.count_events > 0 ){

                                const treeItemCategory = {
                                    id: category.id+'#prematch',
                                    icon_id: category.id,
                                    type: 'category',
                                    name: (desc_category) ? desc_category.text : fallbackDesc_category.text + '(n.t.)',
                                    children: [
                                        ...tournaments_pm_ordered
                                    ],
                                    count: category.count_events,
                                    typeOdds: 'prematch',
                                    order: (category.order) ? category.order:9999
                                }

                                categoriesChildren.push(treeItemCategory);
                            }


                            if(category.count_events_antepost > 0) {
                                const treeItemCategoryAntepost = {
                                    id: category.id+'#antepost',
                                    icon_id: category.id,
                                    type: 'category',
                                    name: (desc_category) ? desc_category.text : fallbackDesc_category + '(n.t.)',
                                    children: [
                                        ...tournaments_ap_ordered
                                    ],
                                    count: category.count_events_antepost,
                                    typeOdds: 'antepost',
                                    order: (category.order) ? category.order:9999
                                }
                                categoriesChildrenAntepost.push(treeItemCategoryAntepost);
                            }

                        });
                        var categories_pm_ordered = _.sortBy(categoriesChildren, ['order','name'])
                        var categories_ap_ordered = _.sortBy(categoriesChildrenAntepost, ['order','name'])

                        if (sport.count_events > 0){
                            const treeItem = {
                                id: sport._id+'#prematch',
                                icon_id: sport._id,
                                type: 'sport',
                                name: (desc_sport) ? desc_sport.text : fallbackDesc_sport.text + '(n.t.)',
                                children: [
                                    ...categories_pm_ordered
                                ],
                                count: sport.count_events,
                                typeOdds: 'prematch',
                                order: (sport.order) ? sport.order:9999
                            }

                            treePrematch.push(treeItem);
                        }

                        if (sport.count_events_antepost > 0){
                            const treeItemAntepost = {
                                id: sport._id+'#antepost',
                                icon_id: sport._id,
                                type: 'sport',
                                name: (desc_sport) ? 'Antepost '+desc_sport.text : 'Antepost '+fallbackDesc_sport + '(n.t.)',
                                children: [
                                    ...categories_ap_ordered
                                ],
                                count: sport.count_events_antepost,
                                typeOdds: 'antepost',
                                order: (sport.order) ? sport.order:9999
                            }


                            if(hybridSports.includes(sport._id)){

                                // Override Sport definiti come antepost, ma spostati nella parte dell'albero prematch

                                treeItemAntepost.name = (desc_sport) ? desc_sport.text : fallbackDesc_sport + '(n.t.)'
                                treePrematch.push(treeItemAntepost);

                            }else{

                                treeAntepost.push(treeItemAntepost);
                            }


                        }


                    });

                    var tree_ap_ordered = _.sortBy(treeAntepost, ['order','name'])
                    var tree_pm_ordered = _.sortBy(treePrematch, ['order','name'])

                    treeTotal = [
                        ...tree_pm_ordered,
                        ...tree_ap_ordered,
                    ]

                    context.commit('setTree', treeTotal);

            })
            .catch(err => {
                console.log(err);
            });

    },

    async removeTournament(context, payload){

        await context.dispatch('tournament/removeTournamentsPreview',{payload}, {root:true});

        let cleanTournaments = _.remove(state.active_tournaments, function (tourn) {
            return tourn != payload
        });

        console.log(cleanTournaments)

        context.commit('setActiveTournaments', cleanTournaments);
    }


};

const mutations = {
    setTree(state, payload) {
        state.tree = payload;
    },

    setNotPublished(state, payload) {
        state.not_published = payload;
    },

    setActiveTournaments(state, payload) {
        state.active_tournaments = payload;
    },
};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
