import axios from "axios";
import rulesHandler from "../../service/rulesHandler";

const state = {
    categories: [],
    items: [],
    sport: {},
    title: '',


};

const getters = {
    getCategories(state, getters) {
        return state.categories;
    },
    getItems(state, getters) {
        return state.items
    },
    getSport(state, getters) {
        return state.sport
    },
    getTitle(state, getters) {
        return state.title
    }

};

const actions = {
    //fetchCategories: (context, payload) => (paramKey) =>  {
    actionCategoryLive(context, payload) {
        //console.log(context.state.sports);
        context.state.categories[payload.index].detail = payload.detail;
    },
    actionCategoryActive(context, payload) {
        // console.log(payload);
        context.state.categories[payload.index].active = payload.active;
    },
    filterCategories(context, payload) {

        // Filtro gli items da mostrare
        context.dispatch('filterItems', {items: context.state.categories, lang: payload.language}).then((items) =>{
            context.commit('setItems',items);
        })


    },
    async fetchCategory(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}categories/show/${payload.paramKey}`;
        // console.log(url);
        await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                context.commit('setCategory', res.data.data);
                let responseshow = res.data.data.detail.filter(check => {
                    return check.lang == payload.language
                });
                context.commit('setTitle', responseshow[0].text);
                //this.title = responseshow[0].text
            })
            .catch((error) => {
                console.log(error);
            });


    },
    async fetchCategories(context, payload) {

        let url = `${context.rootGetters['settings/getUrlApi']}get-categories`;

        if (payload.paramKey != undefined) {

            await axios.post(`${url}`, {'sport': payload.paramKey},{ 'headers': { 'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`} })
                .then((res) => {

                    // Applico le regole
                    context.dispatch('applyRules',res.data.categories).then((items_with_rules) => {

                        // Salvo gli elementi con le regole applicate
                        context.commit('setCategories',items_with_rules);

                        // Filtro gli items da mostrare
                        context.dispatch('filterItems', {items: items_with_rules, lang: payload.language}).then((items) =>{

                            context.commit('setItems',items);

                        })


                    })

                })
                .catch((error) => {
                    console.log(error);
                });

        } else {

            let items = [];
            context.commit('setItems', items);

        }


    },
    importTournament(context, payload) {
        let urltournament = `${context.rootGetters['settings/getUrl']}tournaments/gettournament/${payload.paramKey}`;
        axios.get(`${urltournament}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },

    async filterItems(context, payload){

        let items_filtered = [];

        await _.forEach(payload.items, function (item) {
            var response = _.find(item.detail, {"lang":payload.lang})
            var textDesc = (response !== undefined) ? response.text : '*'+item.detail[0].text
            items_filtered.push({'id':item.id,'name': textDesc, 'source':item.source, 'detail':item.detail, 'active':item.active, 'order':item.order});
        })

        return _.sortBy(items_filtered, ['order','name'])

    },


    async applyRules(context, items){

        let scope = 'category';
        let onlyManager = true;
        if(context.rootGetters['rules/getPlatformId'] > 0){
            onlyManager = false
            var skinDetail = _.find(context.rootGetters['skins/getList'], {"platform_id":context.rootGetters['rules/getPlatformId']})
        }

        const checkRules = _.filter(context.rootGetters['skins/getManager'].rules, {'scope':scope})
        if(checkRules.length > 0) {

            // console.log('Applico Regole Manager Categorie')

            const rulesManager = _.groupBy(checkRules, 'type');

            await rulesHandler.applyRules(rulesManager,items)

        }else{

            // console.log('Categorie: Nessuna regola per il Manager')

        }

        if(!onlyManager){

            const checkRulesSkin = _.filter(skinDetail.rules, {'scope':scope})
            if(checkRulesSkin.length > 0 ){
                // console.log('Applico Regole Categorie Skin ID: '+context.rootGetters['rules/getPlatformId'])

                const rulesSkin = _.groupBy(checkRulesSkin, 'type');
                await rulesHandler.applyRules(rulesSkin,items)


            }else{
                // console.log('Categorie: Nessuna regola per la skin')
            }
        }

        return items

    }


};

const mutations = {
    setCategories(state, payload) {
        state.categories = payload;
    },
    setItems(state, payload) {
        state.items = payload;
    },
    setSport(state, payload) {
        state.sport = payload;
    },
    setTitle(state, payload) {
        state.title = payload;
    },
    setCategory(state, payload) {
        Vue.set(state.categories, payload);
    },

};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
