<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md12>
          <v-col cols="12">
            <v-card outlined elevation-0 >
              <platforms-filter></platforms-filter>
            </v-card>
            <v-card outlined elevation-0 class="mt-1">
              <bets-review></bets-review>
            </v-card>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import moment from 'moment';
import BetsReview from "@/components/risks/betsReview";
import PlatformsFilter from "@/components/risks/platformsFilter";

export default {
  name: "Risks",
  components: {BetsReview, PlatformsFilter},
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      'getLanguage': 'profile/getLanguage',
      'getToken': "profile/getToken",
      'getPlatformId': "rules/getPlatformId",
      'getOptions': 'bet/getOptions',
    }),

  },

  async mounted() {
    console.log('enzo')
    await this.setPreload(true);
    // await this.fetchBets({page: this.getOptions.page, limit: this.getOptions.limit, platform_id: this.getPlatformId})
    await this.setPreload(false);
  },
  methods: {
    ...mapActions({
      'setPreload': 'utils/setPreload',
      // 'fetchBets': 'bet/fetchBets',
      'setOptions': 'bet/setOptions'
    }),
    ...mapMutations({
      'setLanguage': 'profile/setLanguage',
    }),

  },
}
</script>

<style scoped>

</style>
