import store from '../store';
import OddsNotDefined from '../views/events/OddsNotDefined.vue'
import OddsNotDefinedManualMarket from '../views/events/OddsNotDefinedManualMarket'
import EventsIndex from '../views/events/Index.vue'
import Duplicates from '../views/events/Duplicates.vue'
import EventsCreate from '../views/events/Create.vue'
import ImportGOdds from '../views/events/ImportGOdds.vue'
import {checkauth, checkRoute} from "../Helper";
export default [
    {
        path: '/events/list',
        name: 'events',
        component: EventsIndex,
        beforeEnter (to,from,next){
            checkRoute('/events/list').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },
    {
        path: '/events/create',
        name: 'events',
        component: EventsCreate,
        beforeEnter (to,from,next){
            checkRoute('/events/create').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },
    {
        path: '/events/import/g-odds',
        name: 'events',
        component: ImportGOdds,
        beforeEnter (to,from,next){
            checkRoute('/events/import/g-odds').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },

    {
        path: '/events/odds-not-defined',
        name: 'odds-not-defined',
        component: OddsNotDefined,
        beforeEnter (to,from,next){
            checkRoute('/events/odds-not-defined').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },
    {
        path: '/events/odds-not-defined-manual-market',
        name: 'odds-not-defined-manual-market',
        component: OddsNotDefinedManualMarket,
        beforeEnter (to,from,next){
            checkRoute('/events/odds-not-defined-manual-market').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },
    {
        path: '/events/duplicates',
        name: 'duplicates',
        component: Duplicates,
        beforeEnter (to,from,next){
            checkRoute('/events/duplicates').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },

]
