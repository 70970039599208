<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md12>
          <v-col cols="12">
            <v-card outlined elevation-0 >
              <v-container fluid class="pb-0">
                <v-row align="center">

                  <v-col md="2">
                    <v-dialog ref="dialog" v-model="dialogFromTime" width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field dense v-model="filterFromTime" :label="$t('generic.from')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                      </template>
                      <v-date-picker v-model="filterFromTime" scrollable @input="dialogFromTime = false"></v-date-picker>
                    </v-dialog>
                  </v-col>

                  <v-col md="2">
                    <v-dialog ref="dialog" v-model="dialogToTime" width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field dense v-model="filterToTime" :label="$t('generic.to')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                      </template>
                      <v-date-picker v-model="filterToTime" scrollable @input="dialogToTime = false"></v-date-picker>
                    </v-dialog>
                  </v-col>

                  <v-col class="d-flex" cols="12" md="1">
                    <v-select class="text-capitalize" :items="typeOfContexts" item-text="text" item-value="value"  :label="$t('generic.context')" dense outlined v-model="filterContext"></v-select>
                  </v-col>
                  <v-col class="d-flex" cols="12" md="1">
                    <v-select class="text-capitalize" :items="typeOfBets" item-text="text" item-value="value" :label="$t('bets.type')" dense outlined v-model="filterType"></v-select>
                  </v-col>
<!--                  <v-col class="d-flex" cols="12" md="1" v-if="getPlatformId == 0">-->
<!--                    <v-select class="text-capitalize" :items="this.getSkinFilters" item-text="name" item-value="platform_id"  :label="$t('bets.platform')" dense outlined v-model="filterPlatform"></v-select>-->
<!--                  </v-col>-->

                  <v-col class="d-flex" cols="12" md="" style="margin-top: -20px; justify-content: flex-end;">
                    <v-btn depressed color="primary " @click="resetFilter" class="mr-5">{{ $t('generic.reset') }}</v-btn>
                    <v-btn depressed color="secondary" @click="searchBets" @keyup.enter="searchBets" class="mr-5">{{ $t('generic.search') }}</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-card outlined elevation-0 class="mt-1">

              <v-data-table
                  :headers="headers"
                  :items="result"
                  :items-per-page="100"
                  class="elevation-1"
              >
                <template v-slot:item.profit="{ item }">
                  <v-chip class="ma-2" color="secondary" v-if="item.profit_num > 0">
                    {{ item.profit }}
                  </v-chip>
                  <v-chip class="ma-2" color="red" v-else-if="item.profit_num < 0">
                    {{ item.profit }}
                  </v-chip>
                  <v-chip class="ma-2" v-else>
                    {{ item.profit }}
                  </v-chip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import moment from 'moment';
import BetsList from "@/components/bets/betsList";
import BetsFilter from "@/components/bets/betsFilter";

export default {
  name: "Bets",
  components: {BetsList},
  data() {
    return {
      headers: [
        { text: 'Platform', align: 'start', value: 'name'},
        { text: 'N° Bets', value: 'bets_total' },
        { text: 'Unique Users', value: 'users' },
        { text: 'Amount', value: 'amount' },
        { text: 'Winning', value: 'winning' },
        { text: 'Profit', value: 'profit' },
      ],
      result:[],
      dialogFromTime: false,
      dialogToTime: false,
      filterType: "",
      filterPlatform: "",
      filterContext: "",
      filterFromTime: "",
      filterToTime: "",
      typeOfBets: [
        {text: this.$t('generic.selectOption'), value: ""},
        {text: this.$t('bets.single'), value: "single"},
        {text: this.$t('bets.multiple'), value: "multiple"},
        {text: this.$t('bets.integral'), value: "integral"},
        {text: this.$t('bets.system'), value: "system"}
      ],
      typeOfContexts: [
        {text: this.$t('generic.selectOption'), value: ""},
        {text: this.$t('bets.prematch'), value: "prematch"},
        {text: this.$t('bets.live'), value: "live"},
        {text: this.$t('bets.mixed'), value: "mixed"},
        {text: this.$t('bets.antepost'), value: "antepost"},
      ],
    }
  },
  computed: {
    ...mapGetters({
      'getUrl': 'settings/getUrl',
      'getLanguage': 'profile/getLanguage',
      'getToken': "profile/getToken",
      'getPlatformId': "rules/getPlatformId",
      'getOptions': 'bet/getOptions',
      'getList':'skins/getList',
      'getSkinFilters': 'skins/getSkinFilters',
    }),

  },

  async mounted() {
    await this.setPreload(true);
    // await this.fetchBets({page: this.getOptions.page, limit: this.getOptions.limit, platform_id: this.getPlatformId})
    await this.setPreload(false);
  },
  methods: {
    ...mapActions({
      'setPreload': 'utils/setPreload',
    }),
    ...mapMutations({
      'setLanguage': 'profile/setLanguage',
    }),

    async searchBets(){

      var criteria = {
        ...(this.filterPlatform !== '') && {platform_id: +this.filterPlatform},
        ...(this.filterType !== '') && {type: this.filterType},
        ...(this.filterContext !== '') && {bet_context: this.filterContext},
        ...(this.filterFromTime !== '') && {fromTime: new Date(this.filterFromTime).getTime()},
        ...(this.filterToTime !== '') && {toTime: new Date(this.filterToTime).getTime()+86399000},
      }

      await this.setPreload(true);


      let url = `${this.getUrl}reports/bets`;

      return await axios.post(`${url}`,
          {...criteria},
          {'headers': {'Authorization': `Bearer ${this.getToken}`}})
          .then(async (res) => {

            this.result = []

            for(let skin of this.getSkinFilters){

              if(skin.name == 'All'){
                continue
              }

              var users = _.filter(res.data.data, {'platform_id':skin.platform_id})

              var amount = 0.00
              var winning = 0.00
              var bets_total = 0.00
              var currency = ''

              if(_.size(users) > 0){
                currency = users[0].currency
                for(let user of users){
                  bets_total += user.bets
                  winning += user.winning
                  amount += user.amount
                }
              }

              var profit = (amount-winning)

              var stats = {
                'id': skin.platform_id,
                'name': skin.name,
                'users': _.size(users),
                'amount': amount.toFixed(2)+' '+currency,
                'winning': winning.toFixed(2)+' '+currency,
                'profit': profit.toFixed(2)+' '+currency,
                'profit_num': profit,
                'bets_total': bets_total
              }

              this.result.push(stats)

            }

            await this.setPreload(false);

          }).catch((error) => {
            console.log(error.message);
            console.log(error.stackTrace);
          });

    },

    async resetFilter(){

      this.filterPlatform = ''
      this.filterType = ''
      this.filterContext = ''
      this.filterFromTime = ''
      this.filterToTime = ''

    },

    async getColor(profit){
      if (profit < 0) return 'red'
      else if (profit > 0) return 'green'
      else return 'orange'
    }

  },
}
</script>

<style scoped>

</style>
