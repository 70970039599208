<template>
  <div>
    <v-container fluid fill-height>
      <v-layout justify-center align-center>
        <v-card width="100%" outlined elevation-0>
          <v-card-title>
            {{ $t('generic.administrators') | capitalize}}
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('generic.search')"
            ></v-text-field>
          </v-card-title>

          <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              item-key="_id"
              class="elevation-0"
          >
            <template v-slot:body="{ items }">
              <tbody>
              <tr v-for="item in items" :key="item._id">
                <td>{{ item._id }}</td>

                <td>{{ item.name }}</td>
                <td>{{ fixScope(item.scope) }}</td>
                <td>
                   {{ _.find(getLists,{platform_id:item.platform_id}).name }}

                 </td>

                <td>{{ item.email }}</td>

                <td width="120">
                  <v-icon class="mr-0" @click="editItem(item)">
                    mdi-briefcase-edit
                  </v-icon>
                  <v-icon class="mr-0" @click="deleteItem(item)">
                    mdi-trash-can
                  </v-icon>
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>

        <v-btn
            fab
            bottom
            right
            color="secondary"
            dark
            fixed
            @click.prevent="openNew"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-layout>
    </v-container>
    <v-snackbar
            v-model="snackbar.snackbar"
            :color="snackbar.color"
            :multi-line="snackbar.mode === 'multi-line'"
            :timeout="snackbar.timeout"
            :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <v-btn dark text @click="snackbar = false"> {{ $t('generic.close') }} </v-btn>
    </v-snackbar>
    <v-dialog persistent v-model="dialog" width="1000px">
      <v-card>
        <v-card-title class="grey lighten-4 py-4 title">
          {{ titlelabel | capitalize}}
        </v-card-title>
        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap>
            <v-flex xs6>
              <v-text-field
                  :label="$t('generic.name')"
                  v-model="form.name"
                  :error-messages="messages.name"
              ></v-text-field>
            </v-flex>

            <v-flex xs6>
              <v-text-field
                  :label="$t('generic.email')"
                  v-model="form.email"
                  :error-messages="messages.email"
              ></v-text-field>
            </v-flex>

            <v-flex xs6>
              <v-text-field
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :label="$t('generic.password')"
                  v-model="form.password"
                  :error-messages="messages.password"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
              ></v-text-field>
            </v-flex>
            <v-flex sm6>
              <v-autocomplete
                      v-model="form.platform_id"
                      :items="getLists"
                      item-text="name"
                      item-value="platform_id"
                      :error-messages="messages.platform_id"


                      label="Platform"

              ></v-autocomplete>

            </v-flex>
            <v-flex sm6>
              <v-autocomplete
                      v-model="form.scope"
                      :items="permissions"
                      item-text="name"
                      item-value="_id"
                      :error-messages="messages.scope"


                      label="Scope"

              ></v-autocomplete>

            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-btn color="primary" @click="dialog = false">{{ $t('generic.cancel') }}</v-btn>

          <v-btn color="secondary" @click.prevent="confirm">{{ $t('generic.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogedit" width="1000px">
      <v-card>
        <v-card-title class="grey lighten-4 py-4 title">
          {{ titlelabel | capitalize}}
        </v-card-title>
        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap>
            <v-flex sm6>
              <v-text-field
                  :label="$t('generic.name')"
                  v-model="form.name"
                  :error-messages="messages.name"
              ></v-text-field>
            </v-flex>

            <v-flex sm6>
              <v-text-field
                  :label="$t('generic.email')"
                  v-model="form.email"
                  :error-messages="messages.email"
              ></v-text-field>
            </v-flex>

            <v-flex sm6>
              <v-text-field
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :label="$t('generic.password')"
                  v-model="form.password"
                  :error-messages="messages.password"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
              ></v-text-field>
            </v-flex>
            <v-flex sm6>
              <v-autocomplete
                      v-model="form.platform_id"
                      :items="getLists"
                      item-text="name"
                      item-value="platform_id"
                      :error-messages="messages.platform_id"


                      label="Platform"

              ></v-autocomplete>

            </v-flex>
            <v-flex sm6>
              <v-autocomplete
                      v-model="form.scope"
                      :items="permissions"
                      item-text="name"
                      item-value="_id"
                      :error-messages="messages.scope"


                      label="Scope"

              ></v-autocomplete>

            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-btn color="primary" @click="dialogedit = false">{{ $t('generic.cancel') }}</v-btn>

          <v-btn color="secondary" @click.prevent="update">{{ $t('generic.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogconfirm" max-width="290">
      <v-card>
        <v-card-title class="headline">{{$t('generic.areYouSure') | capitalize}}</v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogconfirm = false">{{ $t('generic.cancel') }}</v-btn>

          <v-btn color="secondary" @click="confirmDelete"> {{ $t('generic.ok') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import  axios from 'axios'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
export default {
  name: "Users",
  data(){
    return {
      items:[],

      scopeLists:[{'id':'administrator','value':'administrator'},{'id':'user','value':'user'}],
      snackbar:{
        snackbar: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
      },
      // ricerca
      search:'',
      pagination:{
        sortBy: 'id',
        descending: true,
        rowsPerPage: 10
      },

      headers: [
        { text: 'Id',value: 'id'},
        { text: this.$t('generic.name'), value: 'name' },
        { text: 'Scope', value: 'scope' },
        { text: 'platform', value: 'platform_id' },
        { text: this.$t('generic.email'), value: 'email' },
        { text: this.$t('generic.actions'), value:'', sortable:false }

      ],

      titlelabel:'',
      dialog: false,
      dialogedit: false,

      preload: false,
      dialogconfirm: false,

      editIndex:'',
      deleteitem:'',
      form:{
        _id:'',
        name:'',
        email:'',
        password:'',
        action:'',
        scope:'',
        platform_id:''
      },
      show1: false,
      messages: {
        email: [],
        name: [],
        password: [],
        platform: [],
        scope: []

      },
      permissions:[]
    }
  },
  created () {
    this.initialize();
    this.resetForm();
  },
  computed:{
    ...mapGetters({
      'getAutenticate':'profile/getAutenticate',
      'getToken':'profile/getToken',
      'getUrl':'settings/getUrl',
      'getLists':'skins/getList',

    }),
  },
  methods:{
    ...mapActions({
      'setPreload': 'utils/setPreload',
    }),
    fixScope(item){
        var check = _.find(this.permissions,{_id:item})
      if(check){
        return check.name
      }else{
        return item
      }
    },
    editItem (item) {

      this.titlelabel = this.$t('generic.edit')+' '+this.$t('generic.user');
      this.resetForm();
      this.dialogedit = true;
      this.editIndex = this.items.indexOf(item);
      this.form = {...item};
      this.form.password = '';

    },
    resetForm(){

      this.form._id='';
      this.form.name='';
      this.form.email='';
      this.form.password='';
      this.form.action='';
      this.form.platform_id='';
    },
    async initialize () {




      await this.setPreload(true);
      let urlprofile = "auth/profiles";

      await this.setPreload(true);
      var res = await  axios.get(`${this.getUrl}${urlprofile}`,{ 'headers': { 'Authorization': `Bearer ${this.getToken}`} })
      this.permissions = res.data.data;

      let url = "auth/all";

      axios.get(`${this.getUrl}${url}`,{ 'headers': { 'Authorization': `Bearer ${this.getToken}`} })

          .then( res => {
            this.items = res.data.data;
            this.setPreload(false);
          })
          .catch(err => {
           // console.log(err.response);
            this.snackbar.color="error";
            this.snackbar.text= err.message;
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout=5000;
            if(err.response.status===401){
              this.setLogout();
              this.$router.push('/login')
            }
            this.setPreload(false);
          });
    },
    openNew() {
      this.form.action = 1;
      this.titlelabel = this.$t('generic.new')+' '+this.$t('generic.user');
      this.resetForm();
      this.dialog = !this.dialog

    },
    confirm(){

      let url = "auth/register";
      let payload = this.form;
      this.setPreload(true);

      this.messages = [];
      axios.post(`${this.getUrl}${url}`,payload,{ 'headers': { 'Authorization': `Bearer ${this.getToken}`} })

          .then( res => {
            this.items = res.data.data;
            this.setPreload(false);
            this.dialog = false;
            this.snackbar.color="success";
            this.snackbar.text= this.$t('generic.user')+' '+this.$t('generic.saved')+' '+this.$t('generic.successfully');
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;
            this.initialize();
            this.resetForm();

          })
          .catch(err => {
            this.setPreload(false);
            if(err.response) {
              let errors = err.response.data.error;
              if (errors) {
                console.log(errors);
                errors.forEach(value => {
                  console.log(value.msg);
                  this.messages[value.param] = value.msg
                })

              }


              this.snackbar.color="error";
              this.snackbar.text= err.message;
              this.snackbar.snackbar = true;
              this.snackbar.mode = 'multi-line';
              this.snackbar.timeout=5000;
              this.preload = false;
              if(err.response.status===401){
                this.setLogout();
                this.$router.push('/login')
              }
            }

          });

    },
    update(){

      let url = "auth/update";
      let payload = this.form;
      this.setPreload(true);
      this.messages = [];

      axios.post(`${this.getUrl}${url}`,payload,{ 'headers': { 'Authorization': `Bearer ${this.getToken}`} })
          .then( res => {

            this.setPreload(false);
            this.dialogedit = false;
            this.snackbar.color="success";
            this.snackbar.text= this.$t('generic.user')+' '+this.$t('generic.updated')+' '+this.$t('generic.successfully');
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout=5000;
            this.initialize();
            this.resetForm();
            Object.assign(this.items[this.editIndex], res.data.data);
          })
          .catch(err => {

            this.setPreload(false);
            if(err.response) {
              let errors = err.response.data.error;
              if (errors) {
                console.log(errors);
                errors.forEach(value => {
                  console.log(value.msg);
                  this.messages[value.param] = value.msg
                })

              }


              this.snackbar.color="error";
              this.snackbar.text= err.message;
              this.snackbar.snackbar = true;
              this.snackbar.mode = 'multi-line';
              this.snackbar.timeout=5000;
              this.preload = false;
              if(err.response.status===401){
                this.setLogout();
                this.$router.push('/login')
              }
            }

          });
    },
    deleteItem (item) {
      this.dialogconfirm=true;
      this.form = item;
    },
    confirmDelete(){
      this.dialogconfirm=false;

      this.setPreload(true);
      let url = "auth/delete";
      this.messages = [];

      axios.delete(`${this.getUrl}${url}/${this.form._id}`,{ 'headers': { 'Authorization': `Bearer ${this.getToken}`}})

          .then( res => {
            const index = this.items.indexOf(this.form)
            this.items.splice(index, 1)
            this.deleteitem='';
            this.snackbar.color="success";
            this.snackbar.text= this.$t('generic.user')+' '+this.$t('generic.deleted')+' '+this.$t('generic.successfully');
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout=5000;
            this.preload = false;
          })
          .catch(err => {

            this.setPreload(false);

            if(err.response) {
              let errors = err.response.data.error;
              if (errors) {
                console.log(errors);
                errors.forEach(value => {
                  console.log(value.msg);
                  this.messages[value.param] = value.msg
                })

              }
              this.snackbar.color="error";
              this.snackbar.text= err.message;
              this.snackbar.snackbar = true;
              this.snackbar.mode = 'multi-line';
              this.snackbar.timeout=5000;
              this.preload = false;
              if(err.response.status===401){
                this.setLogout();
                this.$router.push('/login')
              }
            }
          });

    }
  }
}
</script>

<style scoped></style>
