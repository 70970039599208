<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md12>
          <v-col cols="12">
            <span class="title-table">{{ this.$t('bets.competitor') | capitalize}}</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-col cols="6" class="pt-0 pb-0">
                    <v-text-field
                        autocomplete="new-password"
                        label="Cerca un competitor"
                        placeholder="Ad es. Barcellona"
                        v-model="competitorSearch"
                        @input="search"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-0 pb-0">
                    <div class="title-table text-right"> * Elemento non tradotto nella lingua selezionata</div>
                  </v-col>

                  <v-col cols="12">

                    <div class="title-table">LISTA COMPETITORS ({{getCompetitors.length}})</div>

                    <v-progress-linear indeterminate color="secondary darken-2" v-if="getPreload"></v-progress-linear>
                    <v-simple-table dense class="table-theme border-all-theme">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">ID</th>
                            <th class="text-left">Nazione</th>
                            <th class="text-left">Abbr.</th>
                            <th class="text-left">Nome</th>
                            <th class="text-left">Azioni</th>
                          </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in getCompetitors" :key="item.id">
                          <td :title="item.source">{{ item.id }}</td>
                          <td>{{ item.country_code }}</td>
                          <td>{{ item.abbreviation }}</td>
                          <td>{{ getNameTranslated(item.detail) }}</td>
                          <td class="actions-element">
                            <v-icon title="Modifica Traduzioni" @click="translateDialog(item, 'translation', 'competitor', item.id)">create</v-icon>
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                  </v-col>


                </v-row>
              </v-container>

            </v-card>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>

    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :vertical="snackbar.mode === 'vertical'">
      {{ snackbar.text }}
      <v-btn dark text @click="snackbar.snackbar = false">
        Chiudi
      </v-btn>
    </v-snackbar>

    <v-dialog persistent v-model="dialog" width="1000px">
      <v-card>
        <v-card-title class="grey lighten-4 py-4 title">
          {{ titleDialog }}
        </v-card-title>
        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap>

            <v-flex xs6 v-for="(field, i) in this.formToSubmit" :key="i">
              <v-text-field :label="field.lang" v-model="field.text" outlined></v-text-field>
            </v-flex>

          </v-layout>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="dialog = false">Indietro</v-btn>
          <v-btn color="secondary" @click.prevent="submitRule">Conferma</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>
<style>

</style>
<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import _ from 'lodash'
import menuSport from "@/components/sports/menu";

export default {
  name: "Competitors",

  data() {
    return {
      dialog: false,
      competitorSearch: '',
      titleDialog: "",
      formToSubmit:[],
      typeRule : '',
      source: '',
      scope: '',
      snackbar:{
        snackbar: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
      },

    }
  },

  components:{menuSport},

  watch: {
    getLanguage: async function (val) {
      await this.setPreload(true);
      await this.setLanguage(val);
      await this.setPreload(false);
    },
  },

  computed: {
    ...mapGetters({
      'getToken': 'profile/getToken',
      'getUrl': 'settings/getUrl',
      'getPreload': 'utils/getPreload',
      'getLanguage': 'profile/getLanguage',
      'getLanguagesList': 'profile/getLanguagesList',
      'getSports': 'sport/getSports',
      'getItemsSport': 'sport/getItems',
      'getPlatformId': 'rules/getPlatformId',
      'getCompetitors': 'competitors/getList',
    }),


  },
  async beforeDestroy() {
    await this.$root.$store.commit('competitors/resetCompetitorsList');
  },


  methods: {

    ...mapActions({
      'setPreload': 'utils/setPreload',
      'fetchSports': 'sport/fetchSports',
      'filterSport': 'sport/filterSport',
      'updateRules': 'skins/updateRules',
      'fetchSkins': 'skins/fetchSkins',
      'fetchCompetitors': 'competitors/fetchCompetitors',
    }),

    ...mapMutations({
      'setLanguage': 'profile/setLanguage',
      'resetCompetitorsList': 'competitors/resetCompetitorsList'
    }),

    search () {
      if(this.competitorSearch.length > 2){
        this.fetchCompetitors({search: this.competitorSearch})
      }
    },
    getNameTranslated(collection) {
      return _.find(collection, {"lang": this.getLanguage}).text;
    },
    translateDialog(item, type, scope, source){

      var vue = this
      vue.titleDialog = `${'rules'}`
      vue.formToSubmit = []

      _.forEach(this.getLanguagesList, function (lang){

        let text = _.find(item.detail, {"lang": lang.abbr})

        let object = {
          lang: lang.abbr,
          text: (text) ? text.text : ''
        }

        vue.formToSubmit.push(object)

      });

      vue.source = source
      vue.typeRule = type
      vue.scope = scope
      vue.dialog = true;
    },

    async submitRule(){

      var vue = this;
      await this.setPreload(true);

      var payload = {
        "typeRule": this.typeRule,
        "source": this.source,
        "scope": this.scope,
        "rule": this.formToSubmit
      }

      await this.updateRules(payload)
          .then((res) => {
            console.log('Tutto ok')

            this.snackbar.color = "success";
            this.snackbar.text = "Regola aggiornata con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;


          }).catch((err)=>{

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });

      this.dialogConfirm = false
      await this.fetchSkins()
      await this.setPreload(false)

    },


  },
}
</script>

<style scoped>

</style>
