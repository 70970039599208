<template>
  <div>
    <v-container fluid>
      <v-layout row>

<!--        <v-flex md12>-->
<!--          <menuBetConsole></menuBetConsole>-->
<!--        </v-flex>-->

        <v-flex md12>
          <v-col cols="12">
            <span class="title-table">{{ this.$t('generic.cashout') | capitalize}}</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-flex md9>
                    <v-col cols="12" v-if="getPlatformId > 0 && rulesCashout.length == 0">
                      <v-btn block depressed color="secondary" class="mr-2" @click="importFromManager()">
                        IMPORTA DA MANAGER
                      </v-btn>
                    </v-col>
                    <v-col col="12" class="pt-0 pb-0">
                      <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Cerca"
                          single-line
                          hide-details
                      ></v-text-field>
                      <br/>
                      <v-data-table
                          :search="search"
                          :headers="headers"
                          :items="rulesCashout"
                          :items-per-page="30"
                          class="elevation-1"
                      >
                        <template v-slot:item.type="{ item }">
                          {{ getOption(item.type) }}
                        </template>
                        <template v-slot:item.detail.context="{ item }">
                          {{getProducer(item.detail.context)}}
                        </template>


                        <template v-slot:item.actions="{ item }">
                          <!--<v-icon
                              small
                              class="mr-2"
                              @click="editItem(item)"
                          >
                            mdi-pencil
                          </v-icon>-->
                          <v-icon
                              small
                              @click="deleteItem(item)"
                          >
                            mdi-delete
                          </v-icon>
                        </template>

                      </v-data-table>
                    </v-col>
                  </v-flex>
                  <v-flex md3>
                    <span class="title-table">Aggiungi un nuova riga cashout</span>
                    <v-col cols="12" class="pb-0">
                      <v-autocomplete
                          v-model="producerSelected"
                          :items="getProducers"
                          item-text="name"
                          item-value="id"
                          outlined
                          dense
                          chips
                          small-chips
                          :label="this.$t('generic.context')"
                          deletable-chips

                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <v-autocomplete
                          v-model="optionSelected"
                          :items="getOptions"
                          item-text="text"
                          item-value="value"
                          outlined
                          dense
                          chips
                          small-chips
                          :label="this.$t('options')"
                          deletable-chips

                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" class="pt-0 pb-0">
                      <v-text-field
                          :label="this.$t('value')"
                          placeholder="ad es. 5"
                          outlined
                          dense
                          v-model="valueForm"
                          type="number"
                          oninput="if(this.value < 0) this.value = 0;"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-btn block depressed color="secondary" class="mr-2" @click="createNewRule">
                        {{ $t('generic.add') }}
                      </v-btn>
                    </v-col>
                  </v-flex>

                </v-row>
              </v-container>

            </v-card>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>

    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'"
                :timeout="snackbar.timeout" :vertical="snackbar.mode === 'vertical'">
      {{ snackbar.text }}
      <v-btn dark text @click="snackbar.snackbar = false">
        Chiudi
      </v-btn>
    </v-snackbar>

    <v-dialog persistent v-model="dialog" width="1000px">
      <v-card>
        <v-card-title class="grey lighten-4 py-4 title">
          {{ titleDialog }}
        </v-card-title>
        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap>
            <p>
              Aggiorna il valori della seguente regola del cashout
            </p>

            <v-flex xs12>
              <v-autocomplete
                      v-model="formToSubmit.producerSelected"
                      :items="getProducers"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      chips
                      small-chips
                      :label="this.$t('generic.context')"
                      deletable-chips

              ></v-autocomplete>

            </v-flex>
            <v-flex xs12>
              <v-autocomplete
                      v-model="formToSubmit.optionSelected"
                      :items="getOptions"
                      item-text="text"
                      item-value="value"
                      outlined
                      dense
                      chips
                      small-chips
                      :label="this.$t('options')"
                      deletable-chips

              ></v-autocomplete>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                      :label="this.$t('value')"
                      placeholder="ad es. 5"
                      outlined
                      dense
                      v-model="formToSubmit.valueForm"
                      type="number"
                      oninput="if(this.value < 0) this.value = 0;"
              ></v-text-field>
            </v-flex>

          </v-layout>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="dialog = false">Indietro</v-btn>
          <v-btn color="secondary" @click.prevent="submitRule">Conferma</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirm" max-width="550">
      <v-card>

        <v-card-title class="headline">{{ titleDialog }}</v-card-title>

        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap style="line-height: 50px">
            <h4>Desideri eliminare la regola selezionata ?</h4>
          </v-layout>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="dialogConfirm = false">
            Annulla
          </v-btn>

          <v-btn color="secondary" @click="submitDelete">
            Conferma
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>
<style>

</style>
<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import _ from 'lodash'
import menuBetConsole from "@/components/betConsole/menu";

export default {
  name: "Cashout",

  data() {
    return {
      search: '',
      rulesCashout: [],
      newMinute: null,
      newPercentage: null,
      producerSelected: 0,
      optionSelected:0,
      dialog: false,
      dialogConfirm: false,
      titleDialog: "",
      itemToEdit: {
        detail: {
          minute: null,
          percentage: null,
        }
      },
      formToSubmit: {},
      typeRule: '',
      source: '',
      scope: '',
      valueForm:'',
      snackbar: {
        snackbar: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
      },
      headers: [
        {text: 'Contesto', value:'detail.context'},
        {text: 'Option', value:'type'},
        {text: 'Valore', value: 'detail.value'},
        {text: 'Azioni', value: 'actions', sortable: false},
      ],
      getOptions:[
        {text: 'Minimi eventi giocabili', value:'min_event'},
        {text: 'Minime quote giocabili', value:'min_odds'},
        {text: 'Percentuale fissa', value:'percentage'},
        {text: 'Massimi eventi giocabili', value:'max_event'},
      ]

    }
  },

  components: {menuBetConsole},

  computed: {
    ...mapGetters({
      'getToken': 'profile/getToken',
      'getUrl': 'settings/getUrl',
      'getUrlApi': 'settings/getUrlApi',
      'getPreload': 'utils/getPreload',
      'getPlatformId': 'rules/getPlatformId',
      'getManager': 'skins/getManager',
      'getListSkins': 'skins/getList',
      'getProducers': 'producers/getList',
    }),


  },
  async created() {
    await this.setPreload(true);
    await this.fetchSkins()
    // await this.fetchProducers();
    await this.getRulesCashout(this.getPlatformId, this.producerSelected)
    await this.setPreload(false);
  },


  methods: {

    ...mapActions({
      'setPreload': 'utils/setPreload',
      'updateRules': 'skins/updateRules',
      'deleteRules': 'skins/deleteRules',
      'fetchSkins': 'skins/fetchSkins',
      'importFromManagerRules': 'skins/importFromManagerRules',
      'fetchProducers': 'producers/fetchProducers',
      'cacheRules': 'cache/cacheRules',
    }),

    ...mapMutations({
      'setLanguage': 'profile/setLanguage',
    }),


    async getRulesCashout(platformId, producer){
      var vue = this
      var skin = await _.find(vue.getListSkins, {"platform_id": platformId});
      console.log("skin",skin)
      vue.rulesCashout = _.filter(skin.rules,(rule) =>{
        return rule.scope == 'cashout'
      })
    },

    async importFromManager(){

      var vue = this

      var payload = {

        "scope": "cashout"
      }

      await this.importFromManagerRules(payload)
          .then(async(res) => {
            console.log('Tutto ok')

            this.snackbar.color = "success";
            this.snackbar.text = "Regole importate con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;
            await this.cacheRules()
            await this.fetchSkins()
            await this.getRulesCashout(this.getPlatformId, this.producerSelected)


          }).catch((err) => {

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });

      await this.fetchSkins()
      await this.setPreload(false)

    },
    getProducer(desc){
     return this.getProducers[desc].name

    },
    getOption(desc){


      return _.find(this.getOptions,{value:desc}).text


    },

    async createNewRule() {

      var vue = this
      vue.formToSubmit = {}
      /*vue.typeRule = vue.optionSelected
      vue.scope = 'cashout'
      vue.source = vue.producerSelected+'#'+vue.optionSelected*/

      if (vue.valueForm === '' || vue.valueForm == null) {
        alert('Errore: Inserire un valore');
        return
      }

      if (vue.optionSelected === '' || vue.optionSelected == null) {
        alert('Errore: Inserire option');
        return
      }

      if (vue.producerSelected === '' || vue.producerSelected == null) {
        alert('Errore: Inserire producer');
        return
      }

      var payload = {
        "typeRule": vue.optionSelected,
        "source": vue.producerSelected+'#'+vue.optionSelected,
        "scope": "cashout",
        "rule": {value:vue.valueForm,context:vue.producerSelected}
      }

      // console.log(payload);

      await this.updateRules(payload)
          .then(async (res) => {
            console.log('Tutto ok')

            this.snackbar.color = "success";
            this.snackbar.text = "Regola aggiornata con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            await this.cacheRules()
            await this.fetchSkins()
            await this.getRulesCashout(this.getPlatformId, this.producerSelected)
            /*setTimeout(function () {

              this.newMinute = null
              this.newPercentage = null
              vue.getRulesCashout(vue.getPlatformId, vue.producerSelected)


            }, 500);*/

          }).catch((err) => {

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });

      await this.fetchSkins()
      await this.setPreload(false)

    },

    editItem(item) {

      var vue = this;
      vue.dialog = true;

      vue.titleDialog = "Modifica Impostazioni Cashout";

      vue.itemToEdit = item;

      vue.typeRule = item.type;
      vue.source = item.source;
      vue.scope = item.scope;

      vue.formToSubmit = {
        producerSelected: vue.itemToEdit.detail.context,
        optionSelected: vue.itemToEdit.type,
        valueForm: vue.itemToEdit.detail.value,

      }

    },

    deleteItem(item) {

      var vue = this;
      vue.dialogConfirm = true;

      vue.titleDialog = "Elimina regola";

      vue.itemToEdit = item;


      vue.typeRule = item.type;
      vue.source = item.source;
      vue.scope = item.scope;

    },

    async submitRule() {

      var vue = this;
      await this.setPreload(true);

      var payload = {
        "typeRule": this.typeRule,
        "source": this.source,
        "scope": this.scope,
        "rule": this.formToSubmit
      }

      await this.updateRules(payload)
          .then(async (res) => {
            console.log('Tutto ok')

            this.snackbar.color = "success";
            this.snackbar.text = "Regola aggiornata con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            await this.cacheRules()

          }).catch((err) => {

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });


      await this.fetchSkins()
      await this.getRulesCashout(this.getPlatformId, this.producerSelected)
      vue.resetFields()
      vue.dialog = false;
      await this.setPreload(false)
    },

    async submitDelete() {

      var vue = this;
      await this.setPreload(true);

      var payload = {
        "typeRule": this.typeRule,
        "source": this.source,
        "scope": this.scope,
        "rule": this.itemToEdit
      }

      await this.deleteRules(payload)
          .then(async (res) => {
            console.log('Tutto ok')

            this.snackbar.color = "success";
            this.snackbar.text = "Regola aggiornata con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;
            await this.cacheRules()
            await this.fetchSkins()
            await this.getRulesCashout(this.getPlatformId, this.producerSelected)

          }).catch((err) => {

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });



      vue.resetFields()
      vue.dialogConfirm = false;
      await this.setPreload(false)
    },


    resetFields() {

      var vue = this;

      vue.itemToEdit = {
        detail: {
          minute: null,
          percentage: null,
        }
      }

      vue.formToSubmit = {}
      vue.typeRule = ''
      vue.source = ''
      vue.scope = ''

    },

    async getCashout(){
      await this.setPreload(true);
      if(this.producerSelected != null){
        await this.getRulesCashout(this.getPlatformId, this.producerSelected)
      }
      await this.setPreload(false);
    }

  },
}
</script>

<style scoped>

</style>
