import axios from "axios";
import rulesHandler from "../../service/rulesHandler";

const state = {

    items: [],
    sports: [],




};

const getters = {

    getItems(state,getters){
        return state.items
    },
    getSports(state,getters){
        return state.sports
    },


};

const actions = {
    //fetchCategories: (context, payload) => (paramKey) =>  {
    actionSportLive(context,payload) {
        //console.log(context.state.sports);
        context.state.sports[payload.index].detail = payload.detail;
    },
    actionSportActive(context,payload) {
       // console.log(payload);
        context.state.sports[payload.index].active = payload.active;
    },
    filterSport(context,payload){

        // Filtro gli items da mostrare
        context.dispatch('filterItems', {items: context.state.sports, lang: payload.language}).then((items) =>{
            context.commit('setItems',items);
        })

    },
    async fetchCategorySport(context,payload){

        let url = `${context.rootGetters['settings/getUrlApi']}get-categories`;

        // Get Tree
        return axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})


            .then(async res => {
                return res.data.categories


            })
            .catch(err => {
                console.log(err);
            });
    },
    async fetchCategorySportManual(context,payload){

        let url = `${context.rootGetters['settings/getUrlApi']}get-categories-manual`;

        // Get Tree
        return axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})


            .then(async res => {
                return res.data.categories


            })
            .catch(err => {
                console.log(err);
            });
    },
    async fetchTournamentByCategory(context,payload){

        let url = `${context.rootGetters['settings/getUrlApi']}get-tournaments-category`;

        // Get Tree
        return axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})


            .then(async res => {
                console.log(res)
                return res.data.data

                /*var onlyManager = (context.rootGetters['settings/getPlatformId'] == 0);
                var rules = {
                    manager: context.rootGetters['rules/getRulesManager'],
                    skin: (!onlyManager) ? context.rootGetters['rules/getRulesSkin'] : [],
                    lang: context.rootGetters['settings/getLanguage']
                }

                var promise = []
                for(let tourn of res.data.data){
                    promise.push(rulesHandler.applyRulesToObject(tourn,rules,onlyManager,tourn.id))
                }

                var filtered = []
                await Promise.all(promise).then((result) =>{
                    filtered = filter(result, (tournament)=>{
                        return (tournament.status_rule == undefined || tournament.status_rule == true)
                    })
                })

                return filtered*/


                //return res.data.data
                //console.log(res)
                //context.commit('setTree', tree);

            })
            .catch(err => {
                console.log(err);
            });
    },
    async fetchSports (context, payload) {

        let urlsport = `${context.rootGetters['settings/getUrlApi']}get-sports`;
        await axios.post(`${urlsport}`,{},{ 'headers': { 'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`} })

            .then( res => {
                // 1220243093
                //console.log(res.data.data)
                // Applico le regole
                context.dispatch('applyRules',res.data.data).then((items_with_rules) => {

                    // Salvo gli elementi con le regole applicate
                    context.commit('setSports',items_with_rules);

                    // Filtro gli items da mostrare
                    context.dispatch('filterItems', {items: items_with_rules, lang: payload.language}).then((items) =>{

                        context.commit('setItems',items);

                    })

                })


            })
            .catch(err => {
                console.log(err);

                /*if(err.response.status===401){
                    this.setLogout();
                    this.$router.push('/login')
                }*/
            });



    },

    async filterItems(context, payload){

        let items_filtered = [];

        await _.forEach(payload.items, function (item) {
            var response = _.find(item.detail, {"lang":payload.lang})
            var textDesc = (response !== undefined) ? response.text : '*'+item.detail[0].text
            items_filtered.push({'id':item.id,'name': textDesc, '_id':item._id, 'detail':item.detail, 'active':item.active, 'order':item.order});
        })

        return _.sortBy(items_filtered, ['order','name'])

    },

    async applyRules(context, items){

        let scope = 'sport';
        let onlyManager = true;
        //console.log("id",context.rootGetters['rules/getPlatformId'])
        //console.log("list",context.rootGetters['skins/getList'])
        if(context.rootGetters['rules/getPlatformId'] > 0){
            onlyManager = false
            var skinDetail = _.find(context.rootGetters['skins/getList'], {"platform_id":context.rootGetters['rules/getPlatformId']})
        }
        //console.log(context.rootGetters['skins/getList'])

        const checkRules = _.filter(context.rootGetters['skins/getManager'].rules, {'scope':scope})
        if(checkRules.length > 0){

            // console.log('Applico Regole Manager Sports')

            const rulesManager = _.groupBy(checkRules, 'type');

            await rulesHandler.applyRules(rulesManager,items)

        }else{

            // console.log('Sport: Nessuna regola per il Manager')

        }

        if(!onlyManager){

            const checkRulesSkin = _.filter(skinDetail.rules, {'scope':scope})

            if(checkRulesSkin.length > 0 ){
                // console.log('Applico Regole Sports Skin ID: '+context.rootGetters['rules/getPlatformId'])

                const rulesSkin = _.groupBy(checkRulesSkin, 'type');

                await rulesHandler.applyRules(rulesSkin,items)

            }else{

                // console.log('Sport: Nessuna regola per la Skin')

            }
        }

        return items

    },
    async getSportFrom_IdSport(context, payload){

         let sports = context.rootGetters['sport/getSports'];

         let sportFound = sports.filter(sport => {
             return sport._id == payload._id;
         })

        if(sportFound.length > 0){
            return sportFound[0]
        }else{
            return null;
        }
    }

};

const mutations = {

    setItems(state,payload) {
        state.items = payload;
    },
    setSports(state,payload) {
        state.sports = payload;
    },



};



export default {
    namespaced : true,
	state,
	mutations,
	getters,
	actions
}
