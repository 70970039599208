import BetConsole from '@/views/betConsole/Index.vue'
import Cart from '@/views/betConsole/Cart.vue'
import Bonus from '@/views/betConsole/Bonus.vue'
import Shockabsorber from '@/views/betConsole/Shockabsorber.vue'
import Cashout from '@/views/betConsole/Cashout.vue'
import Cache from '@/views/betConsole/Cache.vue'
import MarketViewSettingV2 from '@/views/betConsole/MarketViewSettingV2'
import MarketButtonSizeSettings from '@/views/betConsole/MarketButtonSizeSettings'
import FeaturedTournament from '@/views/betConsole/FeaturedTournament'
import CombinableMarkets from '@/views/betConsole/CombinableMarkets'

import {checkRoute} from "../Helper";

export default [
    {
        path: '/betconsole',
        name: 'BetConsole',
        component: BetConsole,
        beforeEnter(to, from, next) {
            checkRoute('/betconsole').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    {
        path: '/betconsole/cart',
        name: 'BetConsoleCart',
        component: Cart,
        beforeEnter(to, from, next) {
            checkRoute('/betconsole/cart').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    {
        path: '/betconsole/bonus',
        name: 'Bonus',
        component: Bonus,
        beforeEnter(to, from, next) {
            checkRoute('/betconsole/bonus').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    {
        path: '/betconsole/shockabsorber',
        name: 'Shock absorber',
        component: Shockabsorber,
        beforeEnter(to, from, next) {
            checkRoute('/betconsole/shockabsorber').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    {
        path: '/betconsole/cashout',
        name: 'Cashout',
        component: Cashout,
        beforeEnter(to, from, next) {
            checkRoute('/betconsole/cashout').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    {
        path: '/betconsole/cache',
        name: 'Cache',
        component: Cache,
        beforeEnter(to, from, next) {
            checkRoute('/betconsole/cache').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    // {
    //     path: '/betconsole/marketViewSetting',
    //     name: 'MarketViewSetting',
    //     component: MarketViewSetting,
    //     beforeEnter(to, from, next) {
    //         checkRoute('/betconsole/marketViewSetting').then(response => {
    //                 // console.log(response)
    //                 if (response) {
    //                     //console.log("login")
    //                     next();
    //                 } else {
    //                     //console.log("next")
    //                     next('/login');
    //                 }
    //                 ;
    //             }
    //         )
    //     }
    // },
    {
        path: '/betconsole/marketViewSettingV2',
        name: 'marketViewSettingV2',
        component: MarketViewSettingV2,
        beforeEnter(to, from, next) {
            checkRoute('/betconsole/marketViewSettingV2').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    {
        path: '/betconsole/marketButtonSizeSettings',
        name: 'marketButtonSizeSettings',
        component: MarketButtonSizeSettings,
        beforeEnter(to, from, next) {
            checkRoute('/betconsole/marketButtonSizeSettings').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    {
        path: '/betconsole/featuredTournament',
        name: 'featuredTournament',
        component: FeaturedTournament,
        beforeEnter(to, from, next) {
            checkRoute('/betconsole/featuredTournament').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    {
        path: '/betconsole/combinableMarkets',
        name: 'combinedMarkets',
        component: CombinableMarkets,
        beforeEnter(to, from, next) {
            checkRoute('/betconsole/combinableMarkets').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    }
]
