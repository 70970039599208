import axios from "axios";

const state = {
    list: [
        {id: 0, name: 'Prematch & Antepost'},
        {id: 1, name: 'Live'},
    ]
};

const getters = {
    getList: state => {
        return state.list;
    },

};

const actions = {
};

const mutations = {
};



export default {
    namespaced : true,
	state,
	mutations,
	getters,
	actions
}
