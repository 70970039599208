import axios from "axios";
import _ from "lodash";


const state = {
    lists: [],

};

const getters = {

    getLists: state => {
        return state.lists;
    },

};

const actions = {

    async fetchStamps(context, payload) {
        //console.log("CONTEXT",payload)
        let url = `${context.rootGetters['settings/getUrlRule']}stamps/list`;
        await axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(res => {


                context.commit('setLists', res.data.data);

            })
            .catch(err => {
                console.log(err);

                if (err.response.status === 401) {
                    context.commit('profile/setLogout', null, {root: true});
                    window.location.href = '/#/login';
                }

            });
    },
    async fetchMarket(context, payload) {
        //console.log("CONTEXT",payload)
        let url = `${context.rootGetters['settings/getUrlRule']}api/get-markets-sports-printodds`;

        return await axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(res => {
                return res.data.markets

            })
            .catch(err => {
                console.log(err);

                if (err.response.status === 401) {
                    context.commit('profile/setLogout', null, {root: true});
                    window.location.href = '/#/login';
                }

            });
    },
    async fetchMarketManual(context, payload) {
        //console.log("CONTEXT",payload)
        let url = `${context.rootGetters['settings/getUrlRule']}api/get-markets-sports-printodds-manual`;

        return await axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(res => {
                return res.data.markets

            })
            .catch(err => {
                console.log(err);

                if (err.response.status === 401) {
                    context.commit('profile/setLogout', null, {root: true});
                    window.location.href = '/#/login';
                }

            });
    },

    async fetchMarketOutcome(context, payload) {
        //console.log("CONTEXT",payload)
        let url = `${context.rootGetters['settings/getUrlRule']}api/get-markets-sports-printodds-outcome`;

        return await axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(res => {
                return res.data.markets

            })
            .catch(err => {
                console.log(err);

                if (err.response.status === 401) {
                    context.commit('profile/setLogout', null, {root: true});
                    window.location.href = '/#/login';
                }

            });
    },

    async fetchCategorySport(context, payload) {

        let url = `${context.rootGetters['settings/getUrlApi']}/api/get-categories`;

        // Get Tree
        return axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['settings/getToken']}`}})


            .then(async res => {

                var onlyManager = (context.rootGetters['settings/getPlatformId'] == 0);
                var rules = {
                    manager: context.rootGetters['rules/getRulesManager'],
                    skin: (!onlyManager) ? context.rootGetters['rules/getRulesSkin'] : [],
                    lang: context.rootGetters['settings/getLanguage']
                }

                var promise = []
                for (let cat of res.data.categories) {
                    promise.push(rulesHandler.applyRulesToObject(cat, rules, onlyManager, cat.id))
                }

                var filtered = []
                await Promise.all(promise).then((result) => {
                    filtered = _.filter(result, (category) => {
                        return (category.status_rule == undefined || category.status_rule == true)
                    })
                })
                return filtered

            })
            .catch(err => {
                console.log(err);
            });
    },

    async fetchPrintTemplates(context, {payload, isManager = false}) {
        let url;

        if (isManager) {
            url = `${context.rootGetters['settings/getUrl']}skins/platforms/0/prints/templates`;
        } else {
            const platformId = payload.platformId;
            url = `${context.rootGetters['settings/getUrl']}skins/platforms/${platformId}/prints/templates`;
        }

        return await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(async (res) => {
                return res.data.result;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    async fetchManagerPrintTemplates(context, payload) {
        return context.dispatch('fetchPrintTemplates', {payload, isManager: true});
    },

    async fetchPlatformPrintTemplates(context, payload) {
        return context.dispatch('fetchPrintTemplates', {payload});
    },

    async createPrintTemplate(context, payload,) {
        const url = `${context.rootGetters['settings/getUrl']}skins/prints/templates`;

        return await axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(async (res) => {
                return res.data.result;
            })

    },

    async updatePrintTemplate(context, payload) {
        const url = `${context.rootGetters['settings/getUrl']}skins/prints/templates`;

        return await axios.put(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(async (res) => {
                return res.data.result;
            })

    },

    async deletePrintTemplate(context, payload) {
        const encodedTemplateName = encodeURIComponent(payload);
        const url = `${context.rootGetters['settings/getUrl']}skins/prints/templates/${encodedTemplateName}`;

        return await axios.delete(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(async (res) => {
                return res.data.result;
            })

    },

    async fetchMarketsBySportPlatform(context, payload) {
        const url = `${context.rootGetters['settings/getUrl']}api/get-markets-sports-printodds-v2`;

        return axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(async (res) => {
                return res.data.result.markets;
            })
            .catch((err) => {
                console.log(err);
            });
    },
};

const mutations = {
    setLists(state, payload) {
        state.lists = payload;
    }

};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
