<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md12>
          <v-col cols="12">
            <span class="title-table"></span>
            <v-card outlined elevation-0 class="mt-1">
              <v-card-text class="pt-5">
                <template>
                  <div>
                    <v-container fluid class="pb-0">
                      <v-row align="center">

                        <v-col md="2">
                          <v-text-field class="text-capitalize" label="source" dense v-model="form.source" outlined :error-messages="messages.source"></v-text-field>
                        </v-col>
                        <v-col md="2">
                          <!--<v-text-field class="text-capitalize" label="source" dense v-model="source" outlined></v-text-field>-->
                          <v-select class="text-capitalize" :items="selectionType"  :label="$t('generic.type')" dense outlined v-model="form.type" :error-messages="messages.type"></v-select>
                        </v-col>


                        <v-col md="2" style="padding-top: 0;margin-top: 0">

                          <v-btn depressed color="secondary" @click="searchEvents"   > {{ $t('createevent') }}</v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </template>
              </v-card-text>
            </v-card>
            <v-card outlined elevation-0 class="mt-1">
              <v-card-text class="pt-5">
                <template>
                  <div>
                    <v-container fluid class="pb-0">
                      <v-row align="center">






                        <v-col class="d-flex" cols="12" md="2">
                          <v-select class="text-capitalize" :items="selectionType"  :label="$t('generic.type')" dense outlined small-chips v-model="formsnapshot.type" :error-messages="messages.type"></v-select>
                        </v-col>
                        <v-col class="d-flex" cols="12" md="2">
                          <v-autocomplete
                                  v-model="formsnapshot.sport" :items="getItemsSport" item-text="name" item-value="id"
                                  outlined dense chips small-chips :label="$t('sport')" @input="getCategoriesFromSport()"

                          ></v-autocomplete>
                        </v-col>
                        <v-col class="d-flex" cols="12" md="2">
                          <v-autocomplete
                                  v-model="formsnapshot.category" :items="getItemsCategories" item-text="name" item-value="id"
                                  outlined dense chips small-chips :label="$t('category')" @input="getTournamentsFromCategories()" clearable
                          ></v-autocomplete>
                        </v-col>
                        <v-col class="d-flex" cols="12" md="2">
                          <v-autocomplete
                                  v-model="formsnapshot.tournament" :items="getItemsTournaments" item-text="name" item-value="id"
                                  outlined dense chips small-chips :label="$t('tournament')" clearable
                          ></v-autocomplete>
                        </v-col>
                        <v-col class="d-flex" cols="12" md="2">

                            <!--<v-text-field class="text-capitalize" label="source" dense v-model="source" outlined></v-text-field>-->
                            <v-chip class="ma-2" v-if="stats.waiting ">Waiting {{ stats.waiting}}</v-chip>


                        </v-col>


                        <v-col class="d-flex" cols="12" md="" style="margin-top: -20px; justify-content: flex-end;">
                          <!--<v-btn depressed color="primary " @click="resetFilter" class="mr-5">{{ $t('generic.reset') }}</v-btn>-->
                          <v-btn depressed color="secondary" @click="snapshotEvents"  class="mr-5">SNAPSHOT</v-btn>
                        </v-col>

                      </v-row>
                    </v-container>
                  </div>
                </template>
              </v-card-text>
            </v-card>

          </v-col>
        </v-flex>
      </v-layout>
    </v-container>

    <v-snackbar
            v-model="snackbar.snackbar"
            :color="snackbar.color"
            :multi-line="snackbar.mode === 'multi-line'"
            :timeout="snackbar.timeout"
            :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <v-btn dark text @click="snackbar = false"> {{ $t('generic.close') }} </v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';




export default {
  name: "events",

  data() {
    return {
      form:{
        source:"",
        type:''
      },
      messages:{
        source:"",
        type:''
      },
      formsnapshot:{
        sport:8412615417,
        category:'',
        tournament:'',
        type:"Events"

      },
      snackbar:{
        snackbar: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
      },
        selectionType:[
                'Events','League','Outright'
        ],
      stats:[]

    }
  },
  computed: {
    ...mapGetters({
      'getLanguage': 'profile/getLanguage',
      'getToken': "profile/getToken",
      'getPlatformId': "rules/getPlatformId",
      'getOptions': 'bet/getOptions',
      'getUrlApi': 'settings/getUrl',
      'getEventsList': 'event/getEventsList',
      'getSports': 'sport/getSports',
      'getItemsSport': 'sport/getItems',
      'getCategories': 'category/getCategories',
      'getItemsCategories': 'category/getItems',
      'getTournaments': 'tournament/getTournaments',
      'getItemsTournaments': 'tournament/getItems',

    }),

  },

  async created() {
    await this.setPreload(true);
    await this.fetchSports({'language': this.getLanguage});
    await this.fetchCategories({'language': this.getLanguage, 'paramKey': this.formsnapshot.sport});

    var stats = await this.fetchStatsSnapshot({});

    this.stats = stats.response.result
    await this.setPreload(false);
  },
  methods: {
    ...mapActions({
      'setPreload': 'utils/setPreload',
      'recoveryBetSettlement': 'bet/recoveryBetSettlement',
      'defineOdd': 'bet/defineOdd',
      'fetchSports': 'sport/fetchSports',
      'fetchCategories': 'category/fetchCategories',
      'fetchTournaments': 'tournament/fetchTournaments',
      'fetchEventsSnapshot': 'event/fetchEventsSnapshot',
      'fetchStatsSnapshot': 'event/fetchStatsSnapshot',
    }),
    ...mapMutations({
      'setLanguage': 'profile/setLanguage',
    }),
    async getCategoriesFromSport() {
      await this.setPreload(true);
      await this.fetchCategories({'language': this.getLanguage, 'paramKey': this.formsnapshot.sport});
      this.formsnapshot.category=''
      this.formsnapshot.tournament=''
      await this.setPreload(false);
    },

    async getTournamentsFromCategories() {
      await this.setPreload(true);
      await this.fetchTournaments({'language': this.getLanguage, 'paramKey': this.formsnapshot.category});
      this.formsnapshot.tournament=''
      await this.setPreload(false);
    },
    async snapshotEvents(){
      await this.setPreload(true);
      var response = await this.fetchEventsSnapshot({'data': this.formsnapshot});
      if(response.response=="ok"){
        this.snackbar.color = "success";
        this.snackbar.text = "Event in Queue";
        this.snackbar.snackbar = true;
        this.snackbar.mode = 'multi-line';
        this.snackbar.timeout = 5000;
      }
      if(response.response=="ko"){
        this.snackbar.color = "error";
        this.snackbar.text = "Queue error";
        this.snackbar.snackbar = true;
        this.snackbar.mode = 'multi-line';
        this.snackbar.timeout = 5000;
      }
      await this.setPreload(false);
    },
    async searchEvents() {
      this.messages.source = ""
      this.messages.type = ""
      if (this.form.source == "") {
        this.messages.source = "Source mandatory "
      }
      if (this.form.type == "") {
        this.messages.type = "Type mandatory "
      }
      if (this.form.type == "" || this.form.source == "") {
        return
      }
      this.setPreload(true);
      let url = `${this.getUrlApi}events/get-fixture-lsport`;
      axios.post(`${url}`, {
        type: this.form.type,
        source: this.form.source
      }, {'headers': {'Authorization': `Bearer ${this.getToken}`}}).then((result) => {
        this.setPreload(false);
        this.snackbar.color = "success";
        this.snackbar.text = "Event in Queue";
        this.snackbar.snackbar = true;
        this.snackbar.mode = 'multi-line';
        this.snackbar.timeout = 5000;

      })

    }
  }



}
</script>

<style scoped>
.event-desc{
  font-size: 14px;
  padding-left: 10px;
  font-weight: 700;
}
.info-label{
  font-size: 11px;
  color: #888888;
}
</style>
