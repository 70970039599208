import axios from "axios";

const state = {
    autenticate:0,
    name : '',
    email: '',
    token: localStorage.getItem('token') || '',
    refresh:localStorage.getItem('refresh') || '',
    expired:localStorage.getItem('expired') || '',
    id:'',
    language: localStorage.getItem('language') || 'en',
    languagesList: [],
    platform_id:'',
    permissions:[]

};

const getters = {
    getName: state => {
        return state.name;
    },
    getEmail: state => {
        return state.email;
    },

    getAutenticate: state => {
        return state.autenticate;
    },
    getToken: state => {
        return state.token;
    },
    getId: state => {
        return state.id;
    },
    getLanguage: state => {
        return state.language;
    },
    getLanguagesList: state => {
        return state.languagesList;
    },
    getRefresh: state => {
        return state.refresh;
    },
    getExpired: state => {
        return state.expired;
    },
    getPlatform: state => {
        return state.platform_id;
    },
    getPermissions: state => {
        return state.permissions;
    }

};

const actions = {

    async fetchLanguagesList (context) {

        let url = `${context.rootGetters['settings/getUrl']}lang/`;
        await axios.get(`${url}`,{ 'headers': { 'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`} })
            .then( res => {

                context.commit('setLanguagesList',res.data.data);

            })
            .catch(err => {
                console.log(err);
            });
    },
    logout : async(context) => {

        context.commit('setEmail', '');
        context.commit('setName', '');
        context.commit('setAutenticate', 0);
        context.commit('setToken', '');
        context.commit('setRefresh', '');
        context.commit('setId', '');
        context.commit('setExpired', '');
        context.commit('setPlatform', '');
        context.commit('setPermissions', []);
        //context.commit('setLanguage', '');
        localStorage.removeItem('token');
        localStorage.removeItem('refresh');
        localStorage.removeItem('expired');
        localStorage.removeItem('language');
        localStorage.removeItem('userLoggedId');
        localStorage.removeItem('accept_reserve');

    },
    refreshToken : (context,payload) => {
        let url = "auth/token";
        var payload = {token:localStorage.getItem('refresh')};//store.getters["profile/getRefresh"]
        //console.log(`${store.getters["settings/getUrl"]}${url}`);

        return axios.post(`${context.rootGetters["settings/getUrl"]}${url}`,payload)
            .then( success => {

                if(success.status===200){

                    context.commit('setEmail',success.data.email);
                    context.commit('setName',success.data.name);
                    context.commit('setAutenticate', 1);
                    context.commit('setToken',success.data.accessToken);
                    context.commit('setId',success.data._id);
                    context.commit('setExpired',success.data.expired);
                    context.commit('setPlatform',success.data.platform_id);
                    context.commit('setPermissions',JSON.parse(success.data.permissions));
                    localStorage.setItem('token',success.data.accessToken);
                    localStorage.setItem('expired',success.data.expired);
                    localStorage.setItem('userLoggedId',success.data._id);
                }
                return success.data;

            })
            .then((data)=>{
                return {'status':true,'token':data.accessToken}
            })
            .catch(err => {
                console.log(err)
                return {'status':false}


            });


    },
    asyncFetchData : async(context,payload) => {

        let url = "auth/login";
        return axios.post(`${context.rootGetters['settings/getUrl']}${url}`, payload)
            .then(success => {
                //console.log(success.data)

                if(success.status===200){

                    context.commit('setEmail',success.data.email);
                    context.commit('setName',success.data.name);
                    context.commit('setAutenticate', 1);
                    context.commit('setToken',success.data.accessToken);
                    context.commit('setRefresh',success.data.refreshToken);
                    context.commit('setId',success.data._id);
                    context.commit('setExpired',success.data.expired);
                    context.commit('setPlatform',success.data.platform_id);
                    context.commit('rules/setPlatformId',success.data.platform_id,{root:true});
                    context.commit('setPermissions',JSON.parse(success.data.permissions));
                    localStorage.setItem('token',success.data.accessToken);
                    localStorage.setItem('refresh',success.data.refreshToken);
                    localStorage.setItem('expired',success.data.expired);
                    localStorage.setItem('userLoggedId',success.data._id);
                }
                return success

            }).
            catch(error => {

                return  error.response
            });


    },

};

const mutations = {
    setName: (state,value) => {
        state.name= value;
    },
    setEmail: (state,value) => {
        state.email= value;
    },
    setId: (state,value) => {
        state.id= value;
    },
    setAutenticate: (state,value) => {
        state.autenticate= value;
    },

    setLogout: (state) => {
        //axios.defaults.headers.common['Authorization'] = "";
        //localStorage.removeItem('access_token');
        state.autenticate=0;
        state.name= '';
        state.email= '';
        state.id= '';
        //state.permission_id='';
        state.token='';
        state.refresh='';
        state.platform_id='';
    },
    setLanguage: (state,value) => {
        localStorage.setItem('language',value);
        state.language = value;
    },

    setLanguagesList: (state,value) => {
        state.languagesList = value;
    },
    setToken: (state,value) => {
        state.token= value;
    },
    setRefresh: (state,value) => {
        state.refresh= value;
    },
    setExpired: (state,value) => {
        state.expired= value;
    },
    setPlatform: (state,value) => {
        state.platform_id= value;
    },
    setPermissions: (state,value) => {
        state.permissions= value;
    },

};



export default {
    namespaced : true,
	state,
	mutations,
	getters,
	actions
}
