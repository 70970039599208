<template>

  <div>
    <v-container fluid fill-height>
      <v-layout justify-center align-center>
        <v-card width="100%" outlined elevation-0>
          <v-card-title>
            Multimedia

            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="search" label="Cerca.."></v-text-field>
          </v-card-title>

          <v-data-table :headers="headers" :items="getLists" :search="search" item-key="_id" class="elevation-0">
            <template v-slot:body="{ items }">
              <tbody>
              <tr v-for="item in items" :key="item._id">

                <td>{{ item.name }}</td>
                <td>{{ item.type }}</td>
                <td class="pt-2 pb-2"><img :src="item.url" style="max-height: 200px"></td>
                <td width="120" >
                  <v-icon class="mr-0" @click="openEdit(item)">
                    mdi-briefcase-edit
                  </v-icon>
                  <v-icon class="mr-0" @click="deleteItem(item)">
                    mdi-trash-can
                  </v-icon>
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>

        <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :vertical="snackbar.mode === 'vertical'">
          {{ snackbar.text }}
          <v-btn dark text @click="snackbar.snackbar = false">
            Chiudi
          </v-btn>
        </v-snackbar>

        <v-btn fab bottom right color="secondary" dark fixed @click.prevent="openNew">
          <v-icon>add</v-icon>
        </v-btn>
      </v-layout>
    </v-container>

    <v-dialog persistent v-model="dialog" width="1000px">
      <v-card>
        <v-card-title class="grey lighten-4 py-4 title">
          {{ titleDialog }}
        </v-card-title>
        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap>

            <v-flex xs12>
              <v-text-field :label="$t('name')" v-model="form.name" :error-messages="messages.name"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-select :error-messages="messages.type"
                      v-model="form.type"
                      :items="statuses"
                      item-text="name"
                      item-value="value"
                      :label="$t('type')"

              ></v-select>

            </v-flex>
            <v-flex xs12 >
             <div >
              <v-img :src="form.url" v-if="form.url!=''" max-height="200"
                     max-width="300"  ></v-img>
             </div>
            </v-flex>
            <v-flex xs12>
              <v-file-input
                      :error-messages="messages.url"
                      show-size
                      counter
                      accept="image/*"
                      label="Image (logo W 200px x H 100px),(banner  W 800px x H 100px)"
                      @change="selectFile"
              ></v-file-input>
            </v-flex>





          </v-layout>
        </v-container>

        <v-card-actions justify="end">
          <v-btn color="primary" @click="dialog = false">{{$t('generic.close')}}</v-btn>
          <v-btn color="secondary" @click.prevent="submit">{{$t('generic.save')}}</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-dialog v-model="dialogConfirm" max-width="290">
      <v-card>

        <v-card-title class="headline">{{ titleDialog }}</v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="dialogConfirm = false">
            {{$t('generic.close')}}
          </v-btn>

          <v-btn color="secondary" @click="confirmDelete">
            {{$t('generic.yes')}}
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import timezone from '@/assets/timezone.json'
export default {
  name: "Multimedia",
  data() {
    return {
      timezones: timezone,
      search: '',
      statuses: [
        {name:'Banner', value: 'Banner'},
        {name:'Logo', value: 'Logo'}
      ],
      pagination: {
        sortBy: 'platform_id',
        descending: true,
        rowsPerPage: 10
      },

      headers: [

        {text: 'Nome', value: 'name'},
        {text: 'Type', value: 'type'},
        {text: 'Banner', value: 'banner'},
        {text: 'Action', value: ''}
      ],

      snackbar:{
        snackbar: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
      },

      titleDialog: '',

      dialog: false,
      dialogConfirm: false,

      idSkin: -1,
      actionForm : '',

      textOrintation:'',
      form:{
        platform_id: 0,
        name : '',
        type : '',
        url: '',
        _id:''

      },
      textgroup:'',
      deleteitem: '',
      show1: false,
      messages: {
        name: [],
        url: [],
        type: [],
        /*settings: {
          agent_type: '',
          id_partner: '',
          skin_id: '',
          save_endpoint: '',
          print_endpoint: ''
        }*/
      },
      orientations:[],
      sheets:[{size:"A4",value:9},{size:"A3",value:8}],
      textSheet:'',
      sportMarkets:[],
      textGroup:'',
      sportCategories:[],
      categoryTournament:[],
      currentFile: undefined,
      progress: 0,
      fileInfos: []
    }
  },
  async created() {
    //this.resetForm();
    await this.setPreload(true);
    var stamps = await this.fetchList({id:this.getPlatformId})
    await this.setPreload(false);




  },
  computed: {
    ...mapGetters({
      'getAutenticate': 'profile/getAutenticate',
      'getToken': 'profile/getToken',
      'getLanguage': 'profile/getLanguage',
      'getUrl': 'settings/getUrl',
      'getUrlApi': 'settings/getUrlApi',
      'getLists': 'multimedia/getList',
      'getPreload': 'utils/getPreload',
      'getPlatformId': 'rules/getPlatformId',
    }),
  },
  methods: {
    ...mapMutations({

    }),
    ...mapActions({
      'setPreload': 'utils/setPreload',

      'cacheRules': 'cache/cacheRules',
      'fetchList': 'multimedia/fetchList'
    }),
    async selectFile(file) {

      this.progress = 0;
      this.currentFile = file;
      let formData = new FormData();
      var url = this.getUrl+'multimedia/upload'
      formData.append("file", file);
      formData.append("platform_id", this.getPlatformId);
      await axios.post(url, formData, {'headers': {'Authorization': `Bearer ${this.getToken}`,'Content-Type':'multipart/form-data'}})
              .then(res => {

                this.form.url = res.data.url.url

              })


    },

    openNew() {
      this.titleDialog = this.$t('generic.new');
      this.resetForm()

      this.actionForm = 'create';
      this.dialog = true;
    },
    async categorySelect(){
      var listCategories = await this.fetchCategorySport({sport:sport})
      this.sportCategories=[]
      if(_.size(listCategories)>0){
        _.forEach(listCategories,list =>{
          if(list.active){
            let nameCat = _.find(list.detail,{lang:this.getLanguage}).text
            this.sportCategories.push({id:list.id,name:nameCat})
          }
        })
      }
      this.sportCategories= _.sortBy(this.sportCategories,['name'])
    },
    async sportSelect(){
      var markets = await this.fetchMarket({enableSpecifiers: true, lang: this.getLanguage, sport: -1, id: this.form.sport})
      this.sportMarkets = markets

      //console.log(this.form.category)
      /*var listTournaments = await this.fetchTournamentByCategory({categories:JSON.stringify(this.form.category)})
      this.categoryTournament=[]
      if(_.size(listTournaments)>0){
        _.forEach(listTournaments,list =>{
          if(list.active){
            let nameCat = _.find(list.detail,{lang:this.getLanguage}).text
            this.categoryTournament.push({id:list.id,name:nameCat})
          }
        })
      }*/
      //categoryTournament
    },
    putOnOtherFields(){
      this.form.settings.save_endpoint = this.form.website+'/bookie/gaim/sport/'
      this.form.settings.print_endpoint = this.form.website+'/receipts/receiptGeneral8mm.jsp?externalId='
    },

    openEdit(item) {
      this.titleDialog = this.$t('generic.edit');
      this.actionForm = 'update';


      this.form.name = item.name
      this.form.type = item.type
      this.form.url = item.url
      this.form._id = item._id

      this.dialog = true;
    },

    async submit() {

      await this.setPreload(true);
      this.form.platform_id = this.getPlatformId
      var url = '';
      if(this.actionForm == "create"){
        url = this.getUrlApi+'printodds/multimedia'
      }else if(this.actionForm == "update"){
        url = this.getUrlApi+'printodds/multimedia/update'
      }

      let payload = this.form;
      this.messages = [];

      await axios.post(url, payload, {'headers': {'Authorization': `Bearer ${this.getToken}`}})
          .then(res => {

            //this.fetchMultimedia();
            this.fetchList({id:this.getPlatformId})
            this.dialog = false;

            this.snackbar.color = "success";
            this.snackbar.text = this.$t("generic.successfully");
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            //this.resetForm();

          })

          .catch(err => {

            if (err.response) {

              var msg_error = '';

              if (err.response.data.error != undefined) {

                err.response.data.error.forEach(value => {
                  this.messages[value.param] = value.msg
                })
                msg_error = err.response.statusText;
              }else{
                msg_error = err.response.data.message
              }

              this.snackbar.color = "error";
              this.snackbar.text = msg_error;
              this.snackbar.snackbar = true;
              this.snackbar.mode = 'multi-line';
              this.snackbar.timeout = 5000;
              this.setPreload(false);
              if (err.response.status === 401) {
                this.setLogout();
                this.$router.push('/login')
              }
            }
          });
      await this.setPreload(false);

    },

    resetForm() {

      this.form ={
                platform_id: 0,
                name : '',
                type : '',
                url: '',
                _id:''

      }
      this.messages= {
              name: [],
                url: [],
                type: [],

      }
    },

    deleteItem(item) {
      this.dialogConfirm = true;
      this.titleDialog = 'Sei sicuro ?'
      this.idSkin = item._id;
    },
    async confirmDelete() {

      this.dialogConfirm = false;
      await this.setPreload(true);
      var url = this.getUrlApi+'printodds/multimedia/delete/'+this.idSkin
      this.messages = [];

      axios.delete(url, {'headers': {'Authorization': `Bearer ${this.getToken}`}})

          .then(res => {

            this.fetchList({id:this.getPlatformId})

            this.snackbar.color = "success";
            this.snackbar.text = this.$t("generic.successfully");
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            this.setPreload(false);

          })
          .catch(err => {

            console.log(err)
            if (err.response) {

              var msg_error = '';

              if (err.response.data.error != undefined) {

                err.response.data.error.forEach(value => {
                  this.messages[value.param] = value.msg
                })

                msg_error = err.response.statusText;

              }else{

                msg_error = err.response.data.message

              }


              this.snackbar.color = "error";
              this.snackbar.text = msg_error;
              this.snackbar.snackbar = true;
              this.snackbar.mode = 'multi-line';
              this.snackbar.timeout = 5000;

              this.setPreload(false);

              if (err.response.status === 401) {
                this.setLogout();
                this.$router.push('/login')
              }

            }


          });

    }
  },
}
</script>

<style scoped>

</style>
