import axios from "axios";

const state = {
    nome: process.env.VUE_APP_SITE_NAME,
    drawer: true,
    url: process.env.VUE_APP_URL,
    urlApi: process.env.VUE_APP_URL_API,
    urlRule: process.env.VUE_APP_URL_RULE,
    betApiUrl: process.env.VUE_APP_BET_API_URL,
    dialog: false, // Gestione pop Up
    subDialog: false, // Gestione pop Up
    operation: '',
    subOperation: '',
    message: '', // testo messaggio
    errors: {}, // collezione errori
};

const getters = {
    getDrawer: state => {
        return state.drawer;
    },
    getName: state => {
        return state.nome;
    },
    getUrl: state => {
        return state.url;
    },
    getUrlApi: state => {
        return state.urlApi;
    },
    getBetApiUrl: state => {
        return state.betApiUrl;
    },
    getUrlRule: state => {
        return state.urlRule;
    },
    getDialog: state => {
        return state.dialog;
    },
    getSubDialog: state => {
        return state.subDialog;
    },
    getOperation: state => {
        return state.operation;
    },
    getSubOperation: state => {
        return state.subOperation;
    },
    getErrors: state => {
        return state.errors;
    },
    getMessage: state => {
        return state.message;
    }
};

const actions = {
    async setPreload(context, payload) {
        context.commit("setPreload", payload)
    },
    async copySetting(context, payload) {


        let url = `${context.rootGetters['settings/getUrlRule']}skins/update/rules/copy`;
        //console.log(url)
        return await axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return res
                // console.log(res);
            }).catch((error) => {
                // console.log(error);
            });

    },
    async copyPreviewPrematchSetting(context, payload) {
        const url = `${context.rootGetters['settings/getUrlRule']}skins/update/rules/copy/preview-prematch`;
        return axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },
    async copyPreviewPrematchMarketsSettingsFromManager(context, payload){
        const url = `${context.rootGetters['settings/getUrlRule']}settings/sport/groupodds/mainpreview/copy/prematch`;
        return axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },
    async copyMarketsGroupsWithOutcomeSettings(context, payload){
        const url = `${context.rootGetters['settings/getUrlRule']}settings/sport/groupodds/v2/copy-group-outcome-settings`;
        return axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },

    async initializeMarketGroupsLive(context, payload){
        const url = `${context.rootGetters['settings/getUrlRule']}settings/sport/groupodds/markets/initialize-market-group-live`;
        return axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    }
};

const mutations = {
    setDrawer: (state, value) => {
        state.drawer = value;
    },
    setDialog: (state, value) => {
        state.dialog = value;
    },
    setSubDialog: (state, value) => {
        state.subDialog = value;
    },
    setOperation: (state, value) => {
        state.operation = value;
    },
    setSubOperation: (state, value) => {
        state.subOperation = value;
    },
    setErrors: (state, value) => {
        state.errors = value;
    },
    setMessage: (state, value) => {
        state.errors = value;
    },
    setClearError: (state) => {
        //axios.defaults.headers.common['Authorization'] = "";
        //localStorage.removeItem('access_token');
        state.errors = {};
    }
};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
