import axios from "axios";
import rulesHandler from "../../service/rulesHandler";
import _ from "lodash";

const state = {
    tournaments: [],
    items: [],
    eventsPreview: [],
};

const getters = {
    getTournaments(state, getters) {
        return state.tournaments;
    },
    getItems(state, getters) {
        return state.items
    },
    getEventsPreview(state) {
        return state.eventsPreview;
    },
};

const actions = {

    filterTournament(context, payload) {

        // Filtro gli items da mostrare
        context.dispatch('filterItems', {items: context.state.tournaments, lang: payload.language}).then((items) => {
            context.commit('setItems', items);
        })


    },
    async oddChangeSocket(context, payload) {

        console.log('Socket OddChange Preview', payload)

        var eventsStored = {}

        if (context.state.eventsPreview.length > 0 && !_.isEmpty(payload)) {

            for (let preview of context.state.eventsPreview) {
                if (preview.events_source.includes((payload._attributes.event_id))) {
                    eventsStored = Object.assign(eventsStored, preview)
                    break
                }
            }

            if (!_.isEmpty(eventsStored)) {

                var event = await _.find(eventsStored.data.events, {"source": payload._attributes.event_id});

                await Promise.all(
                    Object.keys(event.odds).map(async (index) => {
                        var odd = event.odds[index]

                        if (!odd.freeze) {

                            var payload_market = await _.find(payload.odds.market, function (market) {

                                var id_market = market._attributes.id;
                                var market_unique_id = market._attributes.unique_id
                                if (market_unique_id == undefined || market_unique_id == '') {
                                    market_unique_id = (market._attributes.specifiers != undefined && market._attributes.specifiers != '') ? id_market + '#' + market._attributes.specifiers : id_market
                                }

                                if (market_unique_id == odd.unique_id) {
                                    return market
                                }

                            });

                            if (payload_market != undefined) {

                                //Todo Update from outcome unique id
                                odd.status_to_refresh = (payload_market._attributes.status == "1")

                                for (let outcome_to_update of odd.odds) {
                                    var outcome = await _.find(payload_market.outcome, function (out) {
                                        if (out._attributes.id == outcome_to_update.id_outcome) {
                                            return out
                                        }
                                    })

                                    if (outcome != undefined) {
                                        outcome_to_update.outcome_value_to_refresh = outcome._attributes.odds
                                        outcome_to_update.outcome_status_to_refresh = (outcome._attributes.active == "1")
                                    }
                                }

                            }

                        }
                    })
                )

                event.odds = await rulesHandler.refreshOdds(event.odds)

            }


        }


    },
    async betStopSocket(context, payload) {

        //  console.log(payload)
    },
    async fixtureChangeSocket(context, payload) {

        //  console.log(payload)
    },
    async fetchMultiTournaments(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}tournaments/get-multi-tournament/`;

        if (payload.categories) {

            await axios.post(`${url}`,
                {'data': payload},
                {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
                .then((res) => {

                    context.commit('setTournaments', res.data.tournaments);
                    let items = [];
                    res.data.tournaments.map(replica => {
                        let response = replica.detail.filter(check => {
                            return check.lang == payload.language
                        });

                        let category_name = replica.category_detail[0].detail.filter(check => {
                            return check.lang == payload.language
                        });

                        let sport_name = replica.sport_detail[0].detail.filter(check => {
                            return check.lang == payload.language
                        });

                        items.push({
                            '_id': replica._id,
                            'id': replica.id,
                            'name': response[0].text,
                            'active': replica.active,
                            'sport': sport_name[0].text,
                            'category': category_name[0].text
                        });
                    })
                    context.commit('setItems', items);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {

            let items = [];
            context.commit('setItems', items);
        }
    },

    async fetchTournaments(context, payload) {

        let url = `${context.rootGetters['settings/getUrlApi']}get-tournaments`;

        await axios.post(`${url}`, {'category': payload.paramKey}, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                // Applico le regole
                context.dispatch('applyRules', res.data.data).then((items_with_rules) => {

                    // Salvo gli elementi con le regole applicate
                    context.commit('setTournaments', items_with_rules);

                    // Filtro gli items da mostrare
                    context.dispatch('filterItems', {items: items_with_rules, lang: payload.language}).then((items) => {

                        context.commit('setItems', items);

                    })


                })

            })
            .catch((error) => {
                console.log(error);
            });

    },
    async fetchTournament(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}tournament/show/${payload.paramKey}`;
        // console.log(url);
        await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                context.commit('setCategory', res.data.data);
                let responseshow = res.data.data.detail.filter(check => {

                    return check.lang == payload.language
                });
                context.commit('setTitle', responseshow[0].text);
                //this.title = responseshow[0].text
            })
            .catch((error) => {
                console.log(error);
            });


    },
    importTournament(context, payload) {
        let urltournament = `${context.rootGetters['settings/getUrl']}tournaments/gettournament/${payload.paramKey}`;
        axios.get(`${urltournament}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },

    async fetchEventsPreview(context, payload) {

        // console.log('[INFO] Last updated 17-03-2023')

        var skinsList = context.rootGetters['skins/getList']
        var platformId = context.rootGetters['rules/getPlatformId']
        var lang = context.rootGetters['profile/getLanguage']

        let url = `${context.rootGetters['settings/getUrl']}events/getodds/tournament/adminpreview`;
        // let url = `${context.rootGetters['settings/getUrl']}events/getodds/tournament/preview`;
        console.log(url)
        await axios.post(`${url}`, {
            'tournament': payload.paramKey,
            "platform": context.rootGetters['rules/getPlatformId'],
            "type": payload.type,
            "section": "sport",
            "lang": lang,
            "filter": "-1"
        }, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(async (res) => {

                console.log('#### DATA PREVIEW');
                console.log(res.data);

                var producer = 0;

                var events_num = 0;
                if (res.data.events.length > 0) {

                    var eventsCollections = res.data.events;

                    var sport_detail = res.data.tournament[0].sport_detail;
                    var category_detail = res.data.tournament[0].category_detail;
                    var tournament_detail = res.data.tournament[0];

                    var tree_structure = {
                        sport: res.data.tournament[0].sport_detail.id,
                        category: res.data.tournament[0].category_detail.id,
                        tournament: res.data.tournament[0].id
                    }

                    tree_structure.treeMultiplicity = await rulesHandler.getTreeMultiplicity(tree_structure, skinsList, platformId, producer)

                    var market_detail = res.data.settings;
                    var specifiers = _.orderBy(market_detail.specifiers, ['sort'], ['asc'])
                    market_detail.specifiers = specifiers

                    var sport = await rulesHandler.getInfoWithRules(sport_detail, skinsList, platformId, lang)
                    var category = await rulesHandler.getInfoWithRules(category_detail, skinsList, platformId, lang)
                    var tournament = await rulesHandler.getInfoWithRules(tournament_detail, skinsList, platformId, lang)

                    var eventsAll = [];
                    var eventsPreviewSources = []

                    for (let event of eventsCollections) {

                        if (event == null) {
                            continue
                        }

                        eventsPreviewSources.push(event.id)

                        var competitors = {}
                        var oddsArray = [];
                        var description = ''

                        switch (event.type) {
                            case "match":

                                competitors = {
                                    home: {
                                        ...await rulesHandler.getCompetitorsWithRules(event.competitors[0], skinsList, platformId, lang)
                                    },
                                    away: {
                                        ...await rulesHandler.getCompetitorsWithRules(event.competitors[1], skinsList, platformId, lang)
                                    },
                                }


                                if (event.odds) {

                                    oddsArray = await rulesHandler.applyRulesToOdds(event.odds, event.id, event.type, tree_structure, skinsList, platformId, producer, lang)

                                } else {

                                    oddsArray = {
                                        id_market: null,
                                        void: true
                                    }

                                }
                                break;

                            // case "stage":
                            // case "season":
                            // case "simple:tournament":
                            //
                            //     var desc_parent = _.find(event.parent, {'lang': lang});
                            //     if (!desc_parent) {
                            //         desc_parent = _.find(event.parent, {'lang': 'en'});
                            //     }
                            //
                            //     var desc_event = _.find(event.detail, {'lang': lang});
                            //     if (!desc_event) {
                            //         desc_event = _.find(event.detail, {'lang': 'en'});
                            //     }
                            //
                            //     let d_parent = (desc_parent) ? desc_parent.text : 'Parent Detail non presente';
                            //     let d_event = (desc_event) ? desc_event.text : 'Event Detail non presente';
                            //
                            //     description = d_parent + ' - ' + d_event;
                            //
                            //     oddsArray = {
                            //         id_market: null,
                            //         void: true
                            //     }
                            //
                            //     break;
                        }

                        description = competitors.home.name + ' vs ' + competitors.away.name

                        var single_event = {
                            id: event.id,
                            scheduled: event.scheduled,
                            day: new Date(event.scheduled).toDateString(),
                            time: new Date(event.scheduled).getTime(),
                            // tournament_around: event.tournament_around,
                            status: (_.has(event, 'sport_event_status')) ? event.sport_event_status._attributes.status : 0,
                            competitors: competitors,
                            odds: {...oddsArray},
                            type: event.type,
                            description: description,
                            odds_count: event.count
                        }

                        eventsAll.push(single_event)

                    }

                    eventsAll.sort(function (a, b) {
                        return new Date(a.scheduled) - new Date(b.scheduled);
                    });


                    var markets = [];

                    _.forEach(market_detail.specifiers, function (market, key) {

                        var desc_market = _.find(market.detail, {'lang': lang});
                        if (!desc_market) {
                            desc_market = _.find(market.detail, {'lang': 'en'});
                        }

                        var singleMarket = {
                            id: market.unique_id,
                            name: desc_market.text
                        }

                        markets.push(singleMarket)

                    });


                    const data_events = {
                        sport: {
                            ...sport
                        },
                        category: {
                            ...category
                        },
                        tournament: {
                            ...tournament
                        },
                        events: {
                            ...eventsAll
                        },
                        events_sources: [
                            ...eventsPreviewSources
                        ],
                        message: {
                            num_events: eventsCollections.length
                        },
                        markets: {
                            ...markets
                        },
                        source: payload.source
                    }

                    console.log(data_events);

                    context.commit('setEventsPreview', data_events);

                } else {

                    const data_events = {
                        tournament: {
                            source: payload.paramKey
                        },
                        message: {
                            num_events: events_num
                        },
                        source: payload.source

                    }

                    context.commit('setEventsPreview', data_events);

                }


            })
            .catch((error) => {
                console.log(error);
            });
    },


    async resetEventsPreview(context) {
        context.commit('resetEventsPreview');
    },


    async filterItems(context, payload) {

        let items_filtered = [];

        await _.forEach(payload.items, function (item) {
            var response = _.find(item.detail, {"lang": payload.lang})
            var textDesc = (response !== undefined) ? response.text : '*' + item.detail[0].text
            items_filtered.push({
                'id': item.id,
                'name': textDesc,
                'source': item.source,
                'detail': item.detail,
                'active': item.active,
                'order': item.order
            });
        })

        return _.sortBy(items_filtered, ['order', 'name'])

    },
    async addTurnament(context, payload) {

        state.items.push({
            active: true,
            name: payload.name
        });

        return

    },

    async applyRules(context, items) {

        let scope = 'tournament';
        let onlyManager = true;
        if (context.rootGetters['rules/getPlatformId'] > 0) {
            onlyManager = false
            var skinDetail = _.find(context.rootGetters['skins/getList'], {"platform_id": context.rootGetters['rules/getPlatformId']})
        }


        const checkRules = _.filter(context.rootGetters['skins/getManager'].rules, {'scope': scope})
        if (checkRules.length > 0) {

            // console.log('Applico Regole Manager Tornei')

            const rulesManager = _.groupBy(checkRules, 'type');
            await rulesHandler.applyRules(rulesManager, items)

        } else {

            // console.log('Tornei: Nessuna regola per il Manager')

        }

        if (!onlyManager) {

            const checkRulesSkin = _.filter(skinDetail.rules, {'scope': scope})
            if (checkRulesSkin.length > 0) {
                // console.log('Applico Regole Tornei Skin ID: '+context.rootGetters['rules/getPlatformId'])

                const rulesSkin = _.groupBy(checkRulesSkin, 'type');
                await rulesHandler.applyRules(rulesSkin, items)

            } else {
                // console.log('Tornei: Nessuna regola per la skin')
            }
        }

        return items

    },

    async resetTournaments(context) {
        context.commit('setTournaments', {});
        context.commit('setItems', {});
    },

    async removeTournamentsPreview(context, payload) {

        if (Array.isArray(payload.payload)) {
            var data = payload.payload
        } else {
            var data = [payload.payload]
        }

        let to_remove = []
        await _.forEach(data, function (tournament) {
            // let tournament_source = tournament.split("#");
            to_remove.push(tournament)
        });


        let tournament = await _.remove(context.state.eventsPreview, function (tourn) {
            return !to_remove.includes(tourn.tournament)
        });


        await context.commit('setEventsPreviewDirect', tournament)
    },
    async getFeaturedTournament(context, payload) {

        const platformId = payload.platformId;
        const url = `${context.rootGetters['settings/getUrl']}tournaments/administration/featuredtournament/platform/` + platformId;

        return await axios.get(`${url}`,
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}});
    },

    async setFeaturedTournament(context, payload) {

        const url = `${context.rootGetters['settings/getUrl']}tournaments/administration/featuredtournament`

        return await axios.post(`${url}`,
            payload,
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}});
    },
    async updateFeaturedTournament(context, payload) {

        const url = `${context.rootGetters['settings/getUrl']}tournaments/administration/featuredtournament/`+ payload._id

        return await axios.put(`${url}`,
            payload,
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}});

    },

    async deleteFeaturedTournament(context, payload) {

        const url = `${context.rootGetters['settings/getUrl']}tournaments/administration/featuredtournament/` + payload._id

        return await axios.delete(`${url}`,
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}});
    },

    async deleteFeaturedTournamentsForPlatformId(context, payload) {

        const url = `${context.rootGetters['settings/getUrl']}tournaments/administration/featuredtournament/platform/` + payload.platformId

        return await axios.delete(`${url}`,
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}});
    },

};

const mutations = {
    setTournaments(state, payload) {
        state.tournaments = payload;
    },
    setItems(state, payload) {
        state.items = payload;
    },
    resetEventsPreview(state) {
        state.eventsPreview = [];
    },
    setEventsPreview(state, payload) {

        var events = {};
        if (payload.message.num_events > 0) {

            events = {
                events_source: payload.events_sources,
                tournament: payload.source,
                data: payload
            }

        } else {

            events = {
                data: payload
            }

        }

        state.eventsPreview.unshift(events);
    },
    setEventsPreviewSources(state, payload) {
        state.eventsPreviewSources = payload
    },
    setEventsPreviewDirect(state, payload) {
        state.eventsPreview = payload
    },

};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
