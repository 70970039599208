import store from '../store';
import Themes from '../views/skin/Themes.vue'
import {checkauth, checkauthManager, checkRoute} from "../Helper";
export default [
    {
        path: '/themes',
        name: 'themes',
        component: Themes,
        beforeEnter (to,from,next){
            checkRoute('/themes').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },

]
