import axios from "axios";

const state = {

    items: [],
    matchs: [],




};

const getters = {

    getItems(state,getters){
        return state.items
    },
    getMatchs(state,getters){
        return state.matchs
    },


};

const actions = {
    //fetchCategories: (context, payload) => (paramKey) =>  {
    actionSportLive(context,payload) {
        //console.log(context.state.sports);
        context.state.sports[payload.index].detail = payload.detail;
    },
    actionSportActive(context,payload) {
       // console.log(payload);
        context.state.sports[payload.index].active = payload.active;
    },
    filterSport(context,payload){

        //console.log(context.state.categories)
        let items=[];

        context.state.sports.map(replica => {
            let response = replica.detail.filter(check => {
                return    check.lang == payload.language
            });
            items.push({'_id':replica._id,'id':replica.id,'name':response[0].text,'active':replica.active});
        })
        context.commit('setItems',items);
        //console.log(items);
        //return items;
    },
    async fetchMatchs (context, payload) {

        //matchs/sport/it/sport_event/2020-07-28/schedules
        let urlsport = `${context.rootGetters['settings/getUrl']}matchs/sport/${payload.language}/sport_event/${payload.date}/schedules`;
        await axios.get(`${urlsport}`,{ 'headers': { 'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`} })

            .then( res => {
                //console.log((res.data.response.schedule.sport_event))
                context.commit('setMatchs',res.data.response.schedule.sport_event);
                return true;
               /* context.commit('setSports',res.data.data);
                let items=[];
                res.data.data.map(replica => {
                    let response = replica.detail.filter(check => {
                        return    check.lang ==payload.language
                    });
                    items.push({'_id':replica._id,'id':replica.id,'name':response[0].text,'active':replica.active});
                })
                context.commit('setItems',items);*/

            })
            .catch(err => {
                console.log(err);

                /*if(err.response.status===401){
                    this.setLogout();
                    this.$router.push('/login')
                }*/
            });



    },




};

const mutations = {

    setItems(state,payload) {
        state.items = payload;
    },
    setMatchs(state,payload) {
        state.matchs = payload;
    },



};



export default {
    namespaced : true,
	state,
	mutations,
	getters,
	actions
}
