<template>
    <div id="outcomeOrder">
        <v-container fluid>
            <v-layout row>
                <v-flex md12>
                    <v-col cols="12">
                        <span class="title-table">{{ this.$t('generic.markets') | capitalize}}</span>
                        <v-card outlined elevation-0>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="2">
                                        <v-autocomplete
                                                v-model="searchSport"
                                                :items="getItemsSport"
                                                item-text="name"
                                                item-value="_id"
                                                outlined
                                                dense
                                                chips
                                                small-chips
                                                :label="$t('generic.sport')"
                                                deletable-chips
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="8" v-if="Object.keys(this.marketToEdit).length !== 0">
                                        <v-btn color="blue-grey" class="white--text" :style="'float: right;'"
                                               @click="updateMarket()">
                                            <v-icon left dark>save</v-icon>
                                            {{ $t('generic.save') }}
                                        </v-btn>
                                    </v-col>
                                    <!--<v-col cols="2">
                                      <v-btn color="blue-grey" class="white&#45;&#45;text text-right " @click="clearcache()">
                                        <v-icon left dark>save</v-icon>
                                        {{ $t('generic.clearcache') }}
                                      </v-btn>
                                    </v-col>-->
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-card>
                                            <v-data-table
                                                    :headers="headers"
                                                    :items="getMarketsMini.markets"
                                                    class="elevation-1"
                                                    :items-per-page="30"
                                                    :search="search"
                                                    item-key="unique_id"
                                            >
                                                <template v-slot:top>
                                                    <v-text-field
                                                            v-model="search"
                                                            :label="$t('generic.search')"
                                                            class="mx-4"
                                                    ></v-text-field>
                                                </template>

                                                <template v-slot:item.actions="{ item }">
                                                    <v-icon @click="editMarket(item)">mdi-magnify</v-icon>
                                                </template>
                                            </v-data-table>
                                        </v-card>

                                    </v-col>
                                    <v-col cols="6">
                                        <v-card v-if="Object.keys(this.marketToEdit).length !== 0">
                                            <v-card-title>
                                                <span class="market-name-box">{{ this.marketToEdit.name }}</span>
                                                <span class="market-position">
<!--                                            <vue-numeric-input v-model="marketToEdit.sort"-->
                                                    <!--                                                               controlsType="updown"></vue-numeric-input>-->
                        </span>
                                            </v-card-title>
                                            <hr>
                                            <v-row class="pa-3" :style="'max-height: 915px;overflow-y: scroll;'">
                                                <v-col cols="12" :style="'background: aliceblue;'">
                                                    {{this.$t('generic.name') | capitalize}}
                                                </v-col>
                                                <!--                                                <v-col cols="3" v-for="(detail, i) in this.marketToEdit.detail" :key="i" :style="'background: aliceblue;'">-->
                                                <!--                                                    <v-checkbox-->
                                                <!--                                                            v-model="detail.translated"-->
                                                <!--                                                            :label="$t('generic.translated')|capitalize"-->
                                                <!--                                                            color="red"-->
                                                <!--                                                            hide-details-->
                                                <!--                                                            class="mb-2"-->
                                                <!--                                                    ></v-checkbox>-->
                                                <!--                                                    <v-text-field-->
                                                <!--                                                            :label="detail.lang"-->
                                                <!--                                                            :placeholder="detail.lang"-->
                                                <!--                                                            v-model="detail.text"-->
                                                <!--                                                            outlined-->
                                                <!--                                                            dense-->
                                                <!--                                                    ></v-text-field>-->
                                                <!--                                                </v-col>-->
                                                <v-col cols="12" :style="'background: #e0e0e0;'">
                                                    {{this.$t('generic.outcomes') | capitalize}}
                                                </v-col>
                                                <!--                                                <v-col cols="3" :style="'background: #e0e0e0;'" class="no-padding no-margin">-->
                                                <!--                                                    <v-checkbox-->
                                                <!--                                                            v-model="showTranslations"-->
                                                <!--                                                            :label="`${$t('showTranslations')}`"-->
                                                <!--                                                            :style="'margin: 8px'"-->
                                                <!--                                                            dense-->
                                                <!--                                                    ></v-checkbox>-->
                                                <!--                                                </v-col>-->
                                                <v-col cols="12" v-for="(outcome, j) in this.marketToEdit.outcomes"
                                                       :key="j">
                                                    <div class="outcome-row">
                                                        <span class="font-weight-bold">{{outcome.name}}</span>
                                                        <vue-numeric-input v-model="outcome.sort" controlsType="updown"
                                                                           :style="'float:right;'"></vue-numeric-input>
                                                        <hr class="mt-2">
                                                        <!--                                                        <v-row v-if="showTranslations">-->
                                                        <!--                                                            <v-col cols="3" v-for="(name, i) in outcome.detail" :key="i">-->
                                                        <!--                                                                <v-checkbox-->
                                                        <!--                                                                        v-model="name.translated"-->
                                                        <!--                                                                        :label="$t('generic.translated')|capitalize"-->
                                                        <!--                                                                        color="red"-->
                                                        <!--                                                                        hide-details-->
                                                        <!--                                                                        class="mb-2"-->
                                                        <!--                                                                ></v-checkbox>-->
                                                        <!--                                                                <v-text-field-->
                                                        <!--                                                                        :label="name.lang"-->
                                                        <!--                                                                        :placeholder="name.lang"-->
                                                        <!--                                                                        v-model="name.text"-->
                                                        <!--                                                                        outlined-->
                                                        <!--                                                                        dense-->
                                                        <!--                                                                ></v-text-field>-->
                                                        <!--                                                            </v-col>-->
                                                        <!--                                                        </v-row>-->

                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-flex>
            </v-layout>
        </v-container>
    </div>

</template>
<style>

</style>
<script>
    import {mapActions, mapGetters, mapMutations} from 'vuex';
    import _ from 'lodash'

    export default {
        name: "OutcomeOrder",
        data() {
            return {
                headers: [
                    {text: this.$t('generic.id'), value: 'unique_id'},
                    {text: this.$t('generic.name'), value: 'name'},
                    {text: this.$t('generic.actions'), value: 'actions'},
                ],
                search: '',
                searchSport: null,
                marketToEdit: {},
                showTranslations: false,
            }
        },
        watch: {
            getLanguage: async function (val) {
                await this.setPreload(true);
                await this.setLanguage(val);
                await this.filterSport({'language': val});
                await this.setPreload(false);
            },
            searchSport() {
                this.getData();
            }
        },
        computed: {
            ...mapGetters({
                'getLanguage': 'profile/getLanguage',
                'getItemsSport': 'sport/getItems',
                'getMarketsMini': 'utils/getMarketsMini',
            }),
        },
        async created() {
            await this.setPreload(true);
            await this.fetchSports({'language': this.getLanguage});
            await this.setPreload(false);
        },
        methods: {
            ...mapActions({
                'fetchSports': 'sport/fetchSports',
                'filterSport': 'sport/filterSport',
                'setPreload': 'utils/setPreload',
                'updateSingleMarket': 'utils/updateSingleMarket',
                'getMarketsBySportList': 'utils/getMarketsBySportList',
                'fetchMarketBySport': 'market/fetchMarketBySport',
                'updateMarketOutcomesOrder': 'utils/updateMarketOutcomesOrder',
                'getSportFrom_IdSport': 'sport/getSportFrom_IdSport',
                'getMarketOutcomeOrder': 'utils/getMarketOutcomeOrder'
            }),

            ...mapMutations({}),

            getData() {
                this.getSettingsGroup();
            },
            async getSettingsGroup() {
                await this.setPreload(true);

                if (this.searchSport != null) {
                    await this.getMarketsBySportList({
                        'sport': this.searchSport,
                        'lang': this.getLanguage,
                        'enableSpecifiers': false
                    });
                }

                await this.setPreload(false);
            },

            getNameTranslated(collection) {
                return _.find(collection, {"lang": this.getLanguage}).text;
            },

            async editMarket(item) {

                if (this.searchSport != null) {

                    const idMarket = item.id;

                    const result = await this.fetchMarketBySport({
                        sportId: this.searchSport,
                        marketId: idMarket,
                        language: this.getLanguage
                    })

                    const sport = await this.getSportFrom_IdSport({_id: this.searchSport});
                    const sportId = sport.id;

                    const outcomeOrderResponse = await this.getMarketOutcomeOrder({
                        sportId: sportId,
                        marketId: idMarket
                    });

                    // console.log('outcomeOrderResponse')
                    // console.log(outcomeOrderResponse)

                    const outcomeOrders = outcomeOrderResponse.order != null && outcomeOrderResponse.order.length > 0 ? outcomeOrderResponse.order : [];

                    const mapOutcomeIdSort = new Map();

                    for (const outcomeOrder of outcomeOrders) {
                        mapOutcomeIdSort.set(outcomeOrder.outcomeId, outcomeOrder.sort);
                    }

                    // console.log('map')
                    // console.log(mapOutcomeIdSort)


                    this.marketToEdit = {...result}

                    // _.forEach(this.marketToEdit.outcomes, (out) => {
                    //     _.forEach(out.detail, (detail) => {
                    //         if (detail.translated == undefined) {
                    //             detail.translated = false
                    //         }
                    //     })
                    // })

                    this.marketToEdit.outcomes.forEach(outcome => {
                        // console.log('outcomeId')
                        // console.log(outcome.id)
                        const sortInfo = mapOutcomeIdSort.get(outcome.id.toString());
                        // console.log('sortINfo')
                        // console.log(sortInfo)
                        if (sortInfo != null) {
                            outcome.sort = sortInfo;
                        } else {
                            outcome.sort = 999;
                        }
                        // if (outcome.sort == null) {
                        //     outcome.sort = 999;
                        // }
                    });

                    this.marketToEdit.outcomes = _.orderBy(this.marketToEdit.outcomes, ['sort'], ['asc'])
                }
            },
            async updateMarket() {


                if (this.searchSport != null) {
                    await this.setPreload(true);

                    // await this.updateSingleMarket({'data':this.marketToEdit})

                    // console.log('market to edit')
                    // console.log(this.marketToEdit)


                    const sport = await this.getSportFrom_IdSport({_id: this.searchSport});
                    const sportId = sport.id;


                    const outcomes = this.marketToEdit.outcomes

                    // const outcomes = this.marketToEdit.outcomes.filter(outcome => {
                    //     return outcome.sort != null;
                    // })

                    // this.marketToEdit.outcomes.forEach(outcome => {
                    //     if(outcome.sort == null){
                    //         outcome.sort = 999;
                    //     }
                    // });

                    const arrayOutcomeToEdit = [];
                    this.marketToEdit.outcomes.forEach(outcome => {
                        arrayOutcomeToEdit.push({
                            id: outcome.id,
                            name: outcome.name,
                            sort: outcome.sort
                        })
                    });

                    // console.log('arrayOutcomeToEdit')
                    // console.log(arrayOutcomeToEdit)

                    const payload = {
                        sportId: sportId,
                        marketId: this.marketToEdit.id,
                        marketName: this.marketToEdit.name,
                        outcomes: arrayOutcomeToEdit
                    }

                    const result = await this.updateMarketOutcomesOrder(payload);
                    // console.log('result');
                    // console.log(result);
                    if (result.message === "success") {
                        this.$swal.fire(this.$t('successOperation'), '', 'success')
                    } else {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: this.$t('operationNotPerformedCorrectly')
                        })
                    }
                    await this.setPreload(false);
                }

            },
        },
    }
</script>

<style scoped>

</style>
