import { render, staticRenderFns } from "./entityLimitList.vue?vue&type=template&id=386de689&scoped=true"
import script from "./entityLimitList.vue?vue&type=script&lang=js"
export * from "./entityLimitList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "386de689",
  null
  
)

export default component.exports