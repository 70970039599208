<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md12>
          <v-col cols="12">
            <v-card outlined elevation-0 v-if="getPlatformId == 0" >
              <v-container fluid class="pb-0">
                <v-row align="center">
                  <v-col class="d-flex" cols="12" md="1">
                    <v-select class="text-capitalize" :items="this.getSkinFilters" item-text="name" item-value="platform_id"  :label="$t('bets.platform')" dense outlined v-model="filterPlatform"></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-card outlined elevation-0 class="mt-1">
              <v-container fluid>
                <v-row dense>
                  <v-col cols="4">
                    <apexchart v-if="loaded" type="pie" height="350" :options="chartOptions_type" :series="[this.single,this.multiple,this.integral,this.system]"></apexchart>
                  </v-col>
                  <v-col cols="4">
                    <apexchart v-if="loaded" type="pie" height="350" :options="chartOptions_context" :series="[this.prematch,this.live,this.mixed,this.antepost]"></apexchart>
                  </v-col>
                  <v-col cols="4">
                    <apexchart v-if="loaded" type="bar" height="350" :options="chartOptions_totals" :series="[{name:'Amount Played',data:[+this.amount_total.toFixed(2)]},{name:'Winning total By Users',data:[+this.winning_total.toFixed(2)]},{name:'Net Total',data:[+this.net_total.toFixed(2)]}]"></apexchart>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-card outlined elevation-0 class="mt-1">
              <apexchart v-if="loaded" type="line" height="350" :options="chartOptions" :series="series"></apexchart>
            </v-card>
            <v-card outlined elevation-0 class="mt-1">
              <apexchart v-if="loaded" type="line" height="350" :options="chartOptions" :series="series_amount"></apexchart>
            </v-card>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios'
  import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
  import moment from 'moment';
  import BetsList from "@/components/bets/betsList";
  import { Line } from 'vue-chartjs'

  export default {
    extends: Line,
    name: "trendPlatform",
    components: {BetsList, LineChart:Line},
    data() {
      return {
        loaded:false,
        result:[],
        dialogFromTime: false,
        dialogToTime: false,
        filterType: "",
        filterPlatform: "",
        filterContext: "",
        filterFromTime: moment().subtract(1, 'w').format('YYYY-MM-DD'),
        filterToTime: moment().format('YYYY-MM-DD'),
        typeOfBets: [
          {text: this.$t('generic.selectOption'), value: ""},
          {text: this.$t('bets.single'), value: "single"},
          {text: this.$t('bets.multiple'), value: "multiple"},
          {text: this.$t('bets.integral'), value: "integral"},
          {text: this.$t('bets.system'), value: "system"}
        ],
        typeOfContexts: [
          {text: this.$t('generic.selectOption'), value: ""},
          {text: this.$t('bets.prematch'), value: "prematch"},
          {text: this.$t('bets.live'), value: "live"},
          {text: this.$t('bets.mixed'), value: "mixed"},
          {text: this.$t('bets.antepost'), value: "antepost"},
        ],
        dataResponse:[],
        series: [],
        series_amount: [],
        amount_total:0,
        winning_total:0,
        net_total:0,
        void_total:0,
        single: 0,
        multiple: 0,
        integral: 0,
        system: 0,
        prematch: 0,
        live: 0,
        mixed: 0,
        antepost: 0,
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight'
          },
          title: {
            text: 'Bet Trends',
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          xaxis: {
            categories: [],
          }
        },
        chartOptions_type:{
          chart: {
            width: 380,
            type: 'pie',
          },
          labels: ['Single', 'Multiple', 'Integral', 'System'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
        chartOptions_context:{
          chart: {
            width: 380,
            type: 'pie',
          },
          labels: ['Prematch', 'Live', 'Mixed', 'Antepost'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
        chartOptions_totals: {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: [''],
          },
          fill: {
            opacity: 1
          },
        },
      }
    },
    computed: {
      ...mapGetters({
        'getUrl': 'settings/getUrl',
        'getLanguage': 'profile/getLanguage',
        'getToken': "profile/getToken",
        'getPlatformId': "rules/getPlatformId",
        'getOptions': 'bet/getOptions',
        'getList':'skins/getList',
        'getSkinFilters': 'skins/getSkinFilters',
      }),

    },

    async mounted() {
      this.loaded = false
      await this.setPreload(true);

      this.filterPlatform = this.getPlatformId;

      // await this.fetchBets({page: this.getOptions.page, limit: this.getOptions.limit, platform_id: this.getPlatformId})
      await this.setPreload(false);
      await this.getStats();
    },
      watch: {
          filterPlatform(newValue, oldValue){
              this.getStats();
          }
      },
    methods: {
      ...mapActions({
        'setPreload': 'utils/setPreload',
      }),
      ...mapMutations({
        'setLanguage': 'profile/setLanguage',
      }),

      async getStats(){

        var criteria = {
          ...(this.filterPlatform !== '') && {platform_id: +this.filterPlatform},
          ...(this.filterType !== '') && {type: this.filterType},
          ...(this.filterContext !== '') && {bet_context: this.filterContext},
          ...(this.filterFromTime !== '') && {fromTime: new Date(this.filterFromTime).getTime()},
          ...(this.filterToTime !== '') && {toTime: new Date(this.filterToTime).getTime()+86399000},
        }

        if(this.getPlatformId == 0 && !this.filterPlatform > 0){
          return
        }

        if(this.filterFromTime == '' || this.filterToTime == ''){
          alert('Select Range Date')
          return
        }

        await this.setPreload(true);


        let url = `${this.getUrl}reports/bets-trends`;

        return await axios.post(`${url}`,
                {...criteria},
                {'headers': {'Authorization': `Bearer ${this.getToken}`}})
                .then(async (res) => {

                  this.dataResponse = res.data.data

                  var days = await this.getDaysArray(new Date(this.filterFromTime).getTime(), new Date(this.filterToTime).getTime()+86399000)
                  var promises = []
                  for(let day of days){
                    promises.push(await this.getDetailByDay(day))
                  }

                  await Promise.all(promises).then((result)=>{

                    var ordered = _.orderBy(result, ['date'])

                    var series_num_bets = {
                      name: 'Number of Bets',
                      data: []
                    }

                    var series_num_users = {
                      name: 'Number of Unique Users',
                      data: []
                    }

                    var series_amount = {
                      name: 'Amount',
                      data: []
                    }

                    var series_winning = {
                      name: 'Winning',
                      data: []
                    }

                    var series_net = {
                      name: 'Net',
                      data: []
                    }

                    this.amount_total = 0;
                    this.winning_total = 0;
                    this.net_total = 0;
                    this.prematch = 0;
                    this.live = 0;
                    this.mixed = 0;
                    this.antepost = 0;
                    this.single = 0;
                    this.multiple = 0;
                    this.system = 0;
                    this.integral = 0;

                    for(let result of ordered){
                      series_num_bets.data.push(result.num_bets)
                      series_num_users.data.push(result.unique_users)

                      series_amount.data.push(+result.amount_played.toFixed(2))
                      this.amount_total += +result.amount_played.toFixed(2)

                      series_winning.data.push(+result.winning.toFixed(2))
                      this.winning_total += +result.winning.toFixed(2)

                      series_net.data.push(+result.net.toFixed(2))
                      this.net_total += +result.net.toFixed(2)

                      this.prematch += (result.count_by_context.prematch) ? result.count_by_context.prematch : 0
                      this.live += (result.count_by_context.live) ? result.count_by_context.live : 0
                      this.mixed += (result.count_by_context.mixed) ? result.count_by_context.mixed : 0
                      this.antepost += (result.count_by_context.antepost) ? result.count_by_context.antepost : 0

                      this.single += (result.count_by_type.single) ? result.count_by_type.single : 0
                      this.multiple += (result.count_by_type.multiple) ? result.count_by_type.multiple : 0
                      this.system += (result.count_by_type.system) ? result.count_by_type.system : 0
                      this.integral += (result.count_by_type.integral) ? result.count_by_type.integral : 0

                      this.chartOptions.xaxis.categories.push(result.date_formatted)
                    }

                    this.series = [series_num_bets,series_num_users]
                    this.series_amount = [series_amount,series_winning,series_net]
                    this.loaded = true
                  }).catch((e)=>{
                    console.log(e.message)
                    console.log(e.stackTrace)
                  })

                  await this.setPreload(false);

                }).catch((error) => {
                  console.log(error.message);
                  console.log(error.stackTrace);
                });

      },

      async resetFilter(){

        this.filterPlatform = ''
        this.filterType = ''
        this.filterContext = ''
        this.filterFromTime = ''
        this.filterToTime = ''

      },

      async getColor(profit){
        if (profit < 0) return 'red'
        else if (profit > 0) return 'green'
        else return 'orange'
      },

      async getDaysArray (start, end){
        for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
          arr.push(new Date(dt));
        }
        return arr;
      },

      async getDetailByDay (date){
        return new Promise((resolve,reject) =>{
          try {

            var stats = {
              num_bets: 0,
              amount_played: 0,
              winning: 0,
              net: 0,
              date: date,
              unique_users: 0,
              count_by_type:{},
              count_by_context:{}
            }

            var date_formatted = date.toLocaleDateString()
            stats.date_formatted = date_formatted

            var split_date = date_formatted.split('/')

            var find = _.find(this.dataResponse,{'day':+split_date[0],'month':+split_date[1],'year':+split_date[2]})
            if(find){
              stats.num_bets = find.bets_total
              stats.amount_played = find.amount_played
              stats.winning = (find.winning) ? find.winning : 0
              stats.net = stats.amount_played - stats.winning

              var count_by_type = _.countBy(find.bets_list, 'type');
              var count_by_context = _.countBy(find.bets_list, 'bet_context');

              stats.count_by_type = count_by_type
              stats.count_by_context = count_by_context

              var group = _.groupBy(find.bets_list, 'user_id')
              stats.unique_users = _.size(group)
            }

            console.log(stats)

            resolve(stats)

          }catch (e) {
            console.log(e.message)
            console.log(e.stackTrace)
          }

        })
      }


    },
  }
</script>

<style scoped>

</style>
