<template>
  <div>
    <v-container fluid>
      <v-layout row>

        <!--        <v-flex md12>-->
        <!--          <menu-sport></menu-sport>-->
        <!--        </v-flex>-->

        <v-flex md12>
          <v-col cols="12">
            <span class="title-table">{{ this.$t('bets.tree') | capitalize}}</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" class="pt-0 pb-0">
                    <div class="title-table text-right"> * Elemento non tradotto nella lingua selezionata</div>
                  </v-col>

                  <v-col cols="4">

                    <div class="title-table">LISTA SPORTS ({{getItemsSport.length}})</div>

                    <v-progress-linear indeterminate color="secondary darken-2" v-if="getPreload"></v-progress-linear>
                    <v-simple-table dense class="table-theme border-all-theme">
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th class="text-left">ID</th>
                          <th class="text-left">Nome</th>
                          <th class="text-left">Status</th>
                          <th class="text-left">Pos.</th>
                          <th class="text-left">Azioni</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in getItemsSport" :key="item.id" v-bind:class="{ active_element: (sportSearched == item.id) }">
                          <td :title="item.id">{{ item.id }}</td>
                          <td>{{ item.name }}</td>
                          <td class="actions-element">
                            <v-icon v-if="item.active == undefined" title="Pubblica Sport con Categorie e Tornei Appartenenti" class="undefined" @click="publishDialog('sport', item.id)">help_outline</v-icon>
                            <v-icon v-else title="Cambia status" :class="(item.active) ? 'active' : 'disabled'" @click="changeStatusDialog(item, 'status', 'sport', item.id)">{{ (item.active) ? 'check_circle' : 'highlight_off' }}</v-icon>
                          </td>
                          <td><input v-model.number="item.order" type="number" class="order-input"/> <v-icon class="v-icon-color" title="Salva ordinamento" @click="changeOrder(item, 'order', 'sport', item.id)">swap_vert</v-icon></td>
                          <td class="actions-element">
                            <v-icon title="Statscore" v-if="getPlatform == 0" @click="statscoreDialog(item)">mdi-monitor-screenshot</v-icon>
                            <v-icon title="StatscorePrematch" v-if="getPlatform == 0" @click="statscoreprematchDialog(item)">mdi-monitor</v-icon>
                            <v-icon title="Modifica Traduzioni" @click="translateDialog(item, 'translation', 'sport', item.id)">create</v-icon>

                            <v-icon title="Mostra Categorie" @click="getCategoriesFromSport(item.id)" v-if="item.active">zoom_in</v-icon>
                            <v-icon class="text-warning" title="Ci sono elementi non pubblicati" v-if="checkPublished(item.id, 'sport')" @click="getCategoriesFromSport(item.id)">find_replace</v-icon>
                            <v-icon class="active" title="Pubblica tutto" v-if="checkPublished(item.id, 'sport')" @click="publishAll('sport', item.id)">send</v-icon>
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                  </v-col>

                  <v-col cols="4">

                    <div class="title-table">LISTA CATEGORIE ({{getItemsCategories.length}})</div>

                    <v-progress-linear indeterminate color="secondary darken-2" v-if="getPreload"></v-progress-linear>
                    <v-simple-table dense class="table-theme border-all-theme">
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th class="text-left">ID</th>
                          <th class="text-left">Nome</th>
                          <th class="text-left">Status</th>
                          <th class="text-left">Pos.</th>
                          <th class="text-left">Azioni</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in getItemsCategories" :key="item.id" v-bind:class="{ active_element: (categorySearched == item.id) }">
                          <td :title="item.id">{{ item.id }}</td>
                          <td>{{ item.name }}</td>
                          <td class="actions-element">
                            <v-icon v-if="item.active == undefined" title="Pubblica Categoria con Tornei Appartenenti" class="undefined" @click="publishDialog('category', item.id)">help_outline</v-icon>
                            <v-icon v-else title="Cambia status" :class="(item.active) ? 'active' : 'disabled'" @click="changeStatusDialog(item, 'status', 'category', item.id)">{{ (item.active) ? 'check_circle' : 'highlight_off' }}</v-icon>
                          </td>
                          <td><input v-model.number="item.order" type="number" class="order-input"/> <v-icon class="v-icon-color" title="Salva ordinamento" @click="changeOrder(item, 'order', 'category', item.id)">swap_vert</v-icon></td>
                          <td class="actions-element">
                            <v-icon title="Modifica Traduzioni" @click="translateDialog(item, 'translation', 'category', item.id)">create</v-icon>
                            <v-icon title="Mostra Tornei" @click="getTournamentsFromCategories(item.id)" v-if="item.active">zoom_in</v-icon>
                            <v-icon class="text-warning" title="Ci sono elementi non pubblicati" v-if="checkPublished(item.id, 'category')" @click="getTournamentsFromCategories(item.id)">find_replace</v-icon>
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                  </v-col>

                  <v-col cols="4">

                    <div class="title-table">LISTA TORNEI ({{getItemsTournaments.length}})</div>

                    <v-progress-linear indeterminate color="secondary darken-2" v-if="getPreload"></v-progress-linear>
                    <v-simple-table dense class="table-theme border-all-theme">
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th class="text-left">ID</th>
                          <th class="text-left">Nome</th>
                          <th class="text-left">Status</th>
                          <th class="text-left">Pos.</th>
                          <th class="text-left" v-if="getPlatformId">Fav.</th>
                          <th class="text-left">Azioni</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in getItemsTournaments" :key="item.id">
                          <td :title="item.id">{{ item.id }}</td>
                          <td class="name-element-tree">{{ item.name }}</td>
                          <td class="actions-element">
                            <v-icon v-if="item.active == undefined" title="Pubblica Torneo" class="undefined" @click="publishDialog('tournament', item.id)">help_outline</v-icon>
                            <v-icon v-else title="Cambia status" :class="(item.active) ? 'active' : 'disabled'" @click="changeStatusDialog(item, 'status', 'tournament', item.id)">{{ (item.active) ? 'check_circle' : 'highlight_off' }}</v-icon>
                          </td>
                          <td nowrap><input v-model.number="item.order" type="number" class="order-input"/> <v-icon class="v-icon-color" title="Salva ordinamento" @click="changeOrder(item, 'order', 'tournament', item.id)">swap_vert</v-icon></td>
                          <td class="favourite-element"  v-if="getPlatformId" >
                            <v-icon title="Set favourite" dense :class="checkFavourite(item.id) ? 'active' : 'disabled'" @click="changeStatusFavourite(item,$event)">
                              {{ (item.active) ? 'star_rate' : 'highlight_off' }}
                            </v-icon>
                          </td>
                          <td class="actions-element" nowrap>
                            <v-icon title="Modifica Traduzioni" @click="translateDialog(item, 'translation', 'tournament', item.id)">create</v-icon>
                            <v-icon title="Modifica Abbreviazioni" @click="abbreviationDialog(item, 'abbreviation', 'tournament', item.id)">bookmark</v-icon>
                            <!--<v-icon title="Modifica Traduzioni" @click="translateDialog(item, 'translation', 'tournament', item.id)">bookmark_check_outline</v-icon>-->
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                  </v-col>

                </v-row>
              </v-container>

            </v-card>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>

    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :vertical="snackbar.mode === 'vertical'">
      {{ snackbar.text }}
      <v-btn dark text @click="snackbar.snackbar = false">
        Chiudi
      </v-btn>
    </v-snackbar>

    <v-dialog persistent v-model="dialogabbreviation" width="1000px">
      <v-card>
        <v-card-title class="grey lighten-4 py-4 title">
          {{ titleDialog }}
        </v-card-title>
        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap>

            <v-flex xs6 v-for="(field, i) in this.formToSubmit" :key="i">
              <v-text-field :label="field.lang" v-model="field.text" outlined></v-text-field>
            </v-flex>

          </v-layout>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="dialogabbreviation = false">Indietro</v-btn>
          <v-btn color="secondary" @click.prevent="submitRuleAbbreviation">Conferma</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog_statscore" width="1000px">
      <v-card>
        <v-card-title class="grey lighten-4 py-4 title">
          {{ titleDialog }}
        </v-card-title>
        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap>
            <v-flex xs12>
              <label >Live</label>
            </v-flex>
            <v-flex xs6>
              <v-select :items="statusStatscore" label="Active" v-model="formStatscore.status" outlined>
              </v-select>
            </v-flex>

            <v-flex xs6>
              <v-text-field label="Configuration ID" v-model="formStatscore.configuration" outlined></v-text-field>
            </v-flex>

          </v-layout>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="dialog_statscore = false">Indietro</v-btn>
          <v-btn color="secondary" @click.prevent="submitRulStatscore">Conferma</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog_statscoreprematch" width="1000px">
      <v-card>
        <v-card-title class="grey lighten-4 py-4 title">
          {{ titleDialog }}
        </v-card-title>
        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap>
            <v-flex xs12>
              <label>Prematch</label>
            </v-flex>
            <v-flex xs6>
              <v-select :items="statusStatscorePrematch" label="Active" v-model="formStatscorePrematch.status" outlined>
              </v-select>
            </v-flex>

            <v-flex xs6>
              <v-text-field label="Configuration ID" v-model="formStatscorePrematch.configuration" outlined></v-text-field>
            </v-flex>

          </v-layout>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="dialog_statscoreprematch = false">Indietro</v-btn>
          <v-btn color="secondary" @click.prevent="submitRulStatscoreprematch">Conferma</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialog" width="1000px">
      <v-card>
        <v-card-title class="grey lighten-4 py-4 title">
          {{ titleDialog }}
        </v-card-title>
        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap>

            <v-flex xs6 v-for="(field, i) in this.formToSubmit" :key="i">
              <v-text-field :label="field.lang" v-model="field.text" outlined></v-text-field>
            </v-flex>

          </v-layout>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="dialog = false">Indietro</v-btn>
          <v-btn color="secondary" @click.prevent="submitRule">Conferma</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirm" max-width="550">
      <v-card>

        <v-card-title class="headline">{{ titleDialog }}</v-card-title>

        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap style="line-height: 50px">
            <h4>Desideri cambiare lo status dell'elemento selezionato ?</h4>
            <code>{{changeStatus}}</code>
            <h5>N.B. Tutti i figli di questo elemento subiranno la stessa variazione di status</h5>
          </v-layout>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="dialogConfirm = false">
            Annulla
          </v-btn>

          <v-btn color="secondary" @click="submitRule">
            Conferma
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogPub" max-width="500">
      <v-card>
        <v-card-title class="headline">{{ titleDialog }}</v-card-title>
        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap>
            <h4>Desideri pubblicare l'elemento con il seguente status ?</h4>
            <v-select v-model="statusToPub" :items="statuses" item-text="text" item-value="value" outlined>
            </v-select>
          </v-layout>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="dialogPub = false">
            Annulla
          </v-btn>

          <v-btn color="secondary" @click="confirmPublish()">
            Conferma
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>
<style>

</style>
<script>
  import axios from 'axios'
  import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
  import _ from 'lodash'
  import menuSport from "@/components/sports/menu";

  export default {
    name: "Settings",

    data() {
      return {
        dialog: false,
        dialogabbreviation: false,
        dialogConfirm: false,
        dialogPub: false,
        dialog_statscore: false,
        dialog_statscoreprematch: false,
        titleDialog: "",
        changeStatus: "",
        formToSubmit:[],
        typeRule : '',
        source: '',
        scope: '',
        sportSearched: '',
        categorySearched: '',
        statusToPub: true,
        statuses: [
          {text:"Attivo", value: true},
          {text:"Disattivato", value: false}
        ],
        formStatscore:{
          type: 'statscore',
          scope: 'sport',
          source: '',
          configuration: '',
          status: false,
          operation: 'create',
          id_skin: 0
        },
        formStatscorePrematch:{
          type: 'statscoreprematch',
          scope: 'sport',
          source: '',
          configuration: '',
          status: false,
          operation: 'create',
          id_skin: 0
        },
        statusStatscore: [true, false],
        snackbar:{
          snackbar: false,
          color: '',
          mode: '',
          timeout: 6000,
          text: '',
        },
        statusStatscorePrematch: [true, false],
        snackbar:{
          snackbar: false,
          color: '',
          mode: '',
          timeout: 6000,
          text: '',
        },

      }
    },

    components:{menuSport},

    watch: {
      getLanguage: async function (val) {
        await this.setPreload(true);
        await this.setLanguage(val);


        await this.filterSport({'language': val});
        await this.filterCategories({'language': val});
        await this.filterTournaments({'language': val});

        await this.setPreload(false);
      },
    },

    computed: {
      ...mapGetters({
        'getToken': 'profile/getToken',
        'getUrl': 'settings/getUrl',
        'getPreload': 'utils/getPreload',
        'getLanguage': 'profile/getLanguage',
        'getLanguagesList': 'profile/getLanguagesList',
        'getSports': 'sport/getSports',
        'getItemsSport': 'sport/getItems',
        'getCategories': 'category/getCategories',
        'getItemsCategories': 'category/getItems',
        'getTournaments': 'tournament/getTournaments',
        'getItemsTournaments': 'tournament/getItems',
        'getPlatformId': 'rules/getPlatformId',
        'getNotPublished': 'tree/getNotPublished',
        'getList': 'skins/getList',
        'getManager': 'skins/getManager',
        'getPlatform': 'profile/getPlatform'
      }),


    },
    async created() {
      //console.log(this.getLanguage)
      await this.setPreload(true);
      /* await this.filterSport({'language': this.getLanguage});
       await this.filterCategories({'language': this.getLanguage});
       await this.filterTournaments({'language': this.getLanguage});*/
      await this.fetchSports({'language': this.getLanguage});
      await this.fetchNotPublished();
      await this.setPreload(false);

    },


    methods: {

      ...mapActions({
        'setPreload': 'utils/setPreload',
        'fetchSports': 'sport/fetchSports',
        'filterSport': 'sport/filterSport',
        'fetchCategories': 'category/fetchCategories',
        'filterCategories': 'category/filterCategories',
        'fetchTournaments': 'tournament/fetchTournaments',
        'filterTournaments': 'tournament/filterTournament',
        'resetTournaments': 'tournament/resetTournaments',
        'updateRules': 'skins/updateRules',
        'updateRulesAbbreviation': 'skins/updateRulesAbbreviation',
        'publishElement': 'skins/publishElement',
        'fetchSkins': 'skins/fetchSkins',
        'fetchNotPublished': 'tree/fetchNotPublished',
        'updateRulesFavourite': 'skins/updateRulesFavourite',
        'cacheRules': 'cache/cacheRules',
        'updateRulesStatscore': 'skins/updateRulesStatscore',
        'updateRulesStatscorePrematch': 'skins/updateRulesStatscorePrematch',
      }),

      ...mapMutations({
        'setLanguage': 'profile/setLanguage',
      }),
      checkFavourite(tournament){
        var manager = _.find(this.getList,{platform_id:this.getPlatformId})

        var check = _.find(manager.rules,{scope:'tournament',type:'favourite',source:tournament})
        if(check){

          return true

        }else{
          return false
        }

      },
      async getCategoriesFromSport(sport){
        await this.setPreload(true);
        this.sportSearched = sport
        await this.fetchCategories({'language': this.getLanguage, 'paramKey':sport});
        await this.resetTournaments()
        await this.setPreload(false);
      },

      async getTournamentsFromCategories(category){

        await this.setPreload(true);
        this.categorySearched = category
        await this.fetchTournaments({'language': this.getLanguage, 'paramKey':category});
        await this.fetchSkins();
        await this.setPreload(false);

      },

      checkPublished(source, scope){
        let result = false

        if(scope == "sport"){
          if(_.find(this.getNotPublished.categories, { 'sport': source})){
            result = true
          }else if(_.find(this.getNotPublished.tournaments, { 'sport': source})){
            result = true
          }
        }

        if(scope == "category"){
          if(_.find(this.getNotPublished.tournaments, { 'category': source})){
            result = true
          }
        }

        return result

      },
      statscoreDialog(item) {

        var vue = this
        vue.titleDialog = "Abilita statscore"

        this.formStatscore = {
          type: 'statscore',
          scope: 'sport',
          source: '',
          configuration: '',
          status: false,
          operation: 'create',
          id_skin: 0
        }

        this.formStatscore.source = item.id;
        this.formStatscore.id_skin = this.getPlatformId;

        var checkSkinRules;
        var checkSkin;

        if (this.getPlatformId > 0) {
          checkSkinRules = _.find(this.getList, {"platform_id": this.getPlatformId})
          checkSkin = _.find(checkSkinRules.rules, {"type": "statscore", "source": +item.id})
          if (checkSkin) {
            this.formStatscore = {
              type: 'statscore',
              scope: 'sport',
              source: checkSkin.source,
              configuration: checkSkin.configuration,
              status: checkSkin.status,
              operation: 'update',
            }
          }
        }

        if (!checkSkin){
          //cerca nel manager
          var check = _.find(this.getManager.rules, {"type": "statscore", "source": +item.id})
          if (check) {
            this.formStatscore = {
              type: 'statscore',
              scope: 'sport',
              source: check.source,
              configuration: check.configuration,
              status: check.status,
              operation: 'update',
              id_skin: 0
            }
          }
        }
        vue.dialog_statscore = true;
      },
      statscoreprematchDialog(item) {

        var vue = this
        vue.titleDialog = "Abilita statscore prematch"

        this.formStatscorePrematch = {
          type: 'statscoreprematch',
          scope: 'sport',
          source: '',
          configuration: '',
          status: false,
          operation: 'create',
          id_skin: 0
        }

        this.formStatscorePrematch.source = item.id;
        this.formStatscorePrematch.id_skin = this.getPlatformId;

        var checkSkinRules;
        var checkSkin;

        if (this.getPlatformId > 0) {
          checkSkinRules = _.find(this.getList, {"platform_id": this.getPlatformId})
          checkSkin = _.find(checkSkinRules.rules, {"type": "statscoreprematch", "source": +item.id})
          if (checkSkin) {
            this.formStatscorePrematch = {
              type: 'statscoreprematch',
              scope: 'sport',
              source: checkSkin.source,
              configuration: checkSkin.configuration,
              status: checkSkin.status,
              operation: 'update',
            }
          }
        }

        if (!checkSkin){
          //cerca nel manager
          var check = _.find(this.getManager.rules, {"type": "statscoreprematch", "source": +item.id})
          if (check) {
            this.formStatscorePrematch = {
              type: 'statscoreprematch',
              scope: 'sport',
              source: check.source,
              configuration: check.configuration,
              status: check.status,
              operation: 'update',
              id_skin: 0
            }
          }
        }
        vue.dialog_statscoreprematch = true;
      },
      translateDialog(item, type, scope, source){

        var vue = this
        vue.titleDialog = "Aggiungi nuova regola"
        vue.formToSubmit = []

        _.forEach(this.getLanguagesList, function (lang){

          let text = _.find(item.detail, {"lang": lang.abbr})

          let object = {
            lang: lang.abbr,
            text: (text) ? text.text : ''
          }

          vue.formToSubmit.push(object)

        });

        vue.source = source
        vue.typeRule = type
        vue.scope = scope
        vue.dialog = true;
      },
      abbreviationDialog(item, type, scope, source){
        var vue = this
        vue.titleDialog = "Aggiungi nuova abbreviazione"
        vue.formToSubmit = []

        _.forEach(this.getLanguagesList, function (lang){

          let text = _.find(item.detail, {"lang": lang.abbr})

          let object = {
            lang: lang.abbr,
            text: (text) ? text.text : ''
          }
          vue.formToSubmit.push(object)

        });

        var abbrManager = _.find(this.getList,{platform_id: 0})

        if(abbrManager) {
          var printOdds = _.find(abbrManager.printodds, {source: _.toString(source)})
          if(printOdds){
            vue.formToSubmit = printOdds.detail
          }
        }


        if(this.getPlatformId!=0){
          var abbrPlatform = _.find(this.getList,{platform_id: this.getPlatformId})
          if(abbrPlatform) {
            var printOddsPLatform = _.find(abbrPlatform.printodds, {source: _.toString(source)})
            if(printOddsPLatform){
              vue.formToSubmit = printOddsPLatform.detail
            }
          }
        }
        //item.detail = vue.formToSubmit
        //console.log(item.detail)





        vue.source = source
        vue.typeRule = type
        vue.scope = scope
        vue.dialogabbreviation = true;
      },

      async submitRule(){

        var vue = this;
        await this.setPreload(true);

        var payload = {
          "typeRule": this.typeRule,
          "source": this.source,
          "scope": this.scope,
          "rule": this.formToSubmit
        }

        await this.updateRules(payload)
                .then(async (res) => {
                  this.snackbar.color = "success";
                  this.snackbar.text = "Regola aggiornata con successo";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;
                  await this.cacheRules()

                  setTimeout(function(){

                    vue.fetchSports({'language': vue.getLanguage});
                    if(vue.sportSearched != ''){
                      vue.fetchCategories({'language': vue.getLanguage, 'paramKey':vue.sportSearched});
                    }

                    if(vue.categorySearched != ''){
                      vue.fetchTournaments({'language': vue.getLanguage, 'paramKey':vue.categorySearched});
                    }

                  }, 500);


                }).catch((err)=>{

                  this.snackbar.color = "error";
                  this.snackbar.text = "C'è stato un errore nel salvataggio";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;

                  console.log(err)
                });

        this.dialogConfirm = false
        await this.fetchSkins()
        await this.setPreload(false)

      },
      async submitRulStatscore(){

        var vue = this;
        await this.setPreload(true);

        await this.updateRulesStatscore(this.formStatscore)
                .then(async (res) => {
                  console.log('Tutto ok')

                  this.snackbar.color = "success";
                  this.snackbar.text = "Regola aggiornata con successo";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;
                  await this.cacheRules()

                  this.dialog_statscore = false

                }).catch((err)=>{

                  this.snackbar.color = "error";
                  this.snackbar.text = "C'è stato un errore nel salvataggio";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;

                  console.log(err)
                });

        await this.fetchSkins()
        await this.setPreload(false)

      },
      async submitRulStatscoreprematch(){

        var vue = this;
        await this.setPreload(true);

        await this.updateRulesStatscorePrematch(this.formStatscorePrematch)
                .then(async (res) => {
                  this.snackbar.color = "success";
                  this.snackbar.text = "Regola aggiornata con successo";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;
                  await this.cacheRules()

                  this.dialog_statscore = false

                }).catch((err)=>{

                  this.snackbar.color = "error";
                  this.snackbar.text = "C'è stato un errore nel salvataggio";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;

                  console.log(err)
                });

        await this.fetchSkins()
        await this.setPreload(false)

      },
      async submitRuleAbbreviation(){

        var vue = this;
        await this.setPreload(true);

        var payload = {
          "typeRule": this.typeRule,
          "source": this.source,
          "scope": this.scope,
          "printodds": this.formToSubmit
        }

        await this.updateRulesAbbreviation(payload)
                .then((res) => {
                  console.log('Tutto ok')

                  this.snackbar.color = "success";
                  this.snackbar.text = "Abbreviazione aggiornata con successo";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;

                  setTimeout(function(){

                    vue.fetchSports({'language': vue.getLanguage});
                    if(vue.sportSearched != ''){
                      vue.fetchCategories({'language': vue.getLanguage, 'paramKey':vue.sportSearched});
                    }

                    if(vue.categorySearched != ''){
                      vue.fetchTournaments({'language': vue.getLanguage, 'paramKey':vue.categorySearched});
                    }

                  }, 500);


                }).catch((err)=>{

                  this.snackbar.color = "error";
                  this.snackbar.text = "C'è stato un errore nel salvataggio";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;

                  console.log(err)
                });

        this.dialogabbreviation = false
        await this.fetchSkins()
        await this.setPreload(false)

      },


      changeStatusDialog(item, type, scope, source){
        var vue = this

        vue.titleDialog = "Gestisci status"
        if(item.active){
          vue.changeStatus = "Attivo >> Disattivato"
        }else{
          vue.changeStatus = "Disattivato >> Attivo"
        }

        vue.formToSubmit = {'active': !item.active}

        vue.typeRule = type
        vue.scope = scope
        vue.source = source

        vue.dialogConfirm = true

      },
      async changeStatusFavourite(item,event){

        var status = this.checkFavourite(item.id)


        var payload = {status: status,type: 'favourite',platform:this.getPlatformId,scope:"tournament",source:item.id};
        var response = await this.updateRulesFavourite(payload)

        event.target.classList.toggle('active');
        event.target.classList.toggle('disabled');
        await this.cacheRules()


      },

      publishDialog(scope, source){
        var vue = this

        vue.titleDialog = "Pubblica elemento"
        vue.scope = scope
        vue.source = source
        vue.formToSubmit = []

        vue.dialogPub = true
      },

      async publishAll(scope, source){

        var vue = this;
        vue.source = source
        vue.scope = scope
        vue.statusToPub = true

        await this.confirmPublish()
      },

      async confirmPublish(){
        var vue = this;

        var payload = {
          "source": vue.source,
          "scope": vue.scope,
          "active": vue.statusToPub
        }

        await this.setPreload(true);

        await this.publishElement(payload)
                .then(async (res) => {
                  console.log('Tutto ok')

                  this.snackbar.color = "success";
                  this.snackbar.text = "Regola aggiornata con successo";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;

                  this.fetchNotPublished();

                  await this.cacheRules()

                  setTimeout(function(){

                    vue.fetchSports({'language': vue.getLanguage});
                    if(vue.sportSearched != ''){
                      vue.fetchCategories({'language': vue.getLanguage, 'paramKey':vue.sportSearched});
                    }

                    if(vue.categorySearched != ''){
                      vue.fetchTournaments({'language': vue.getLanguage, 'paramKey':vue.categorySearched});
                    }

                    vue.dialogPub = false

                  }, 500);


                }).catch((err)=>{

                  this.snackbar.color = "error";
                  this.snackbar.text = "C'è stato un errore nel salvataggio";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;

                  console.log(err)
                });

        await this.setPreload(false);
      },


      async changeOrder(item, type, scope, source){
        var vue = this;

        var payload = {
          "typeRule": type,
          "source": source,
          "scope": scope,
          "rule": {
            "order": item.order
          }
        }

        await this.setPreload(true);

        await this.updateRules(payload)
                .then(async (res) => {
                  console.log('Tutto ok')

                  this.fetchSkins()
                  this.snackbar.color = "success";
                  this.snackbar.text = "Regola aggiornata con successo";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;

                  await this.cacheRules()

                  setTimeout(function(){

                    vue.fetchSports({'language': vue.getLanguage});
                    if(vue.sportSearched != ''){
                      vue.fetchCategories({'language': vue.getLanguage, 'paramKey':vue.sportSearched});
                    }

                    if(vue.categorySearched != ''){
                      vue.fetchTournaments({'language': vue.getLanguage, 'paramKey':vue.categorySearched});
                    }

                    vue.dialogPub = false

                  }, 500);


                }).catch((err)=>{

                  this.snackbar.color = "error";
                  this.snackbar.text = "C'è stato un errore nel salvataggio";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;

                  console.log(err)
                });

        await this.setPreload(false);
      },


    },
  }
</script>

<style scoped>

</style>
