<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md10>
          <v-col cols="12">
            <v-card outlined elevation-0 v-if="getCalendar.bookable.length > 0">
              <v-container fluid>
                <p>Eventi da aggiungere <v-btn small color="primary" elevation="2" @click="bookAll()" v-if="getCalendar.bookable.length > 0">{{$t('generic.add')}} {{$t('generic.all')}}</v-btn></p>
                <v-simple-table dense style="max-height:500px;overflow-y: auto;">
                  <thead>
                  <tr>
                    <th class="text-left">
                      Id
                    </th>
                    <th class="text-left">
                      Data
                    </th>
                    <th class="text-left">
                      Sport
                    </th>
                    <th class="text-left">
                      Categoria
                    </th>
                    <th class="text-left">
                      Torneo
                    </th>
                    <th class="text-left">
                      Match
                    </th>
                    <th class="text-left">
                      Status
                    </th>
                    <th class="text-left">
                      Azioni
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in getCalendar.bookable" :key="item.attributes.id">
                    <td>{{ item.attributes.id }}</td>
                    <td>{{ item.attributes.scheduled |  moment("DD/MM/YYYY HH:mm") }}</td>
                    <td>{{ item.sport.name }}</td>
                    <td>{{ item.category.name }}</td>
                    <td>{{ item.tournament.name }}</td>
                    <td>
                      {{
                        (item.competitors == undefined) ? '' : item.competitors[0].attributes.name + ' - ' + item.competitors[1].attributes.name
                      }}
                    </td>
                    <td>{{ item.attributes.status }}</td>
                    <td><v-icon @click="sendBookEvent(item.attributes.id)">mdi-plus-box</v-icon></td>
                  </tr>
                  </tbody>
                </v-simple-table>

              </v-container>
            </v-card>
            <br/>
            <v-card outlined elevation-0 v-if="getCalendar.booked.length > 0">
              <v-container fluid>
                <p>Eventi già prenotati</p>
                <v-simple-table dense style="max-height:500px;overflow-y: auto;">
                  <thead>
                  <tr>
                    <th class="text-left">
                      Id
                    </th>
                    <th class="text-left">
                      Data
                    </th>
                    <th class="text-left">
                      Sport
                    </th>
                    <th class="text-left">
                      Categoria
                    </th>
                    <th class="text-left">
                      Torneo
                    </th>
                    <th class="text-left">
                      Match
                    </th>
                    <th class="text-left">
                      Status
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in getCalendar.booked" :key="item.attributes.id">
                    <td>{{ item.attributes.id }}</td>
                    <td>{{ item.attributes.scheduled |  moment("DD/MM/YYYY HH:mm") }}</td>
                    <td>{{ item.sport.name }}</td>
                    <td>{{ item.category.name }}</td>
                    <td>{{ item.tournament.name }}</td>
                    <td>
                      {{
                        (item.competitors == undefined) ? '' : item.competitors[0].attributes.name + ' - ' + item.competitors[1].attributes.name
                      }}
                    </td>
                    <td>{{ item.attributes.status }}</td>
                  </tr>
                  </tbody>
                </v-simple-table>
              </v-container>
            </v-card>
            <br/>
<!--            <v-card outlined elevation-0>-->
<!--              <v-container fluid>-->
<!--                <p>Eventi acquistabili</p>-->
<!--                <v-simple-table dense>-->
<!--                  <thead>-->
<!--                  <tr>-->
<!--                    <th class="text-left">-->
<!--                      Id-->
<!--                    </th>-->
<!--                    <th class="text-left">-->
<!--                      Data-->
<!--                    </th>-->
<!--                    <th class="text-left">-->
<!--                      Sport-->
<!--                    </th>-->
<!--                    <th class="text-left">-->
<!--                      Categoria-->
<!--                    </th>-->
<!--                    <th class="text-left">-->
<!--                      Torneo-->
<!--                    </th>-->
<!--                    <th class="text-left">-->
<!--                      Match-->
<!--                    </th>-->
<!--                    <th class="text-left">-->
<!--                      Status-->
<!--                    </th>-->
<!--                  </tr>-->
<!--                  </thead>-->
<!--                  <tbody>-->
<!--                  <tr v-for="item in getCalendar.buyable" :key="item.attributes.id">-->
<!--                    <td>{{ item.attributes.id }}</td>-->
<!--                    <td>{{ item.attributes.scheduled |  moment("DD/MM/YYYY HH:mm") }}</td>-->
<!--                    <td>{{ item.sport.name }}</td>-->
<!--                    <td>{{ item.category.name }}</td>-->
<!--                    <td>{{ item.tournament.name }}</td>-->
<!--                    <td>-->
<!--                      {{-->
<!--                        (item.competitors == undefined) ? '' : item.competitors[0].attributes.name + ' - ' + item.competitors[1].attributes.name-->
<!--                      }}-->
<!--                    </td>-->
<!--                    <td>{{ item.attributes.status }}</td>-->
<!--                  </tr>-->
<!--                  </tbody>-->
<!--                </v-simple-table>-->
<!--              </v-container>-->
<!--            </v-card>-->
<!--            <br/>-->
<!--            <v-card outlined elevation-0>-->
<!--              <v-container fluid>-->
<!--                <p>Eventi non disponibili per il live</p>-->
<!--                <v-simple-table dense>-->
<!--                  <thead>-->
<!--                  <tr>-->
<!--                    <th class="text-left">-->
<!--                      Id-->
<!--                    </th>-->
<!--                    <th class="text-left">-->
<!--                      Data-->
<!--                    </th>-->
<!--                    <th class="text-left">-->
<!--                      Sport-->
<!--                    </th>-->
<!--                    <th class="text-left">-->
<!--                      Categoria-->
<!--                    </th>-->
<!--                    <th class="text-left">-->
<!--                      Torneo-->
<!--                    </th>-->
<!--                    <th class="text-left">-->
<!--                      Match-->
<!--                    </th>-->
<!--                    <th class="text-left">-->
<!--                      Status-->
<!--                    </th>-->
<!--                  </tr>-->
<!--                  </thead>-->
<!--                  <tbody>-->
<!--                  <tr v-for="item in getCalendar.not_available" :key="item.attributes.id" v-if="new Date(item.attributes.scheduled).getTime() > new Date().getTime()">-->
<!--                    <td>{{ item.attributes.id }}</td>-->
<!--                    <td>{{ item.attributes.scheduled |  moment("DD/MM/YYYY HH:mm") }}</td>-->
<!--                    <td>{{ item.sport.name }}</td>-->
<!--                    <td>{{ item.category.name }}</td>-->
<!--                    <td>{{ item.tournament.name }}</td>-->
<!--                    <td>-->
<!--                      {{-->
<!--                        (item.competitors == undefined) ? '' : item.competitors[0].attributes.name + ' - ' + item.competitors[1].attributes.name-->
<!--                      }}-->
<!--                    </td>-->
<!--                    <td>{{ item.attributes.status }}</td>-->
<!--                  </tr>-->
<!--                  </tbody>-->
<!--                </v-simple-table>-->
<!--              </v-container>-->
<!--            </v-card>-->
          </v-col>
        </v-flex>
        <v-flex md2>
          <v-col cols="12">
            <v-row justify="center">
              <v-card outlined elevation-0>
                <div class="pa-2">
                  <p>Seleziona una data</p>
                  <v-date-picker v-model="dateToSearch"></v-date-picker>
                  <v-btn block color="accent" elevation="2" @click="searchEvents()">CERCA</v-btn>
                </div>
              </v-card>
            </v-row>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<style>

</style>
<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import _ from 'lodash'

export default {
  data() {
    return {
      actualSearch: null,
      dateToSearch: new Date().toLocaleDateString('en-CA')
    }
  },

  watch: {
    getLanguage: async function (val) {

    },


  },

  computed: {
    ...mapGetters({
      'getToken': 'profile/getToken',
      'getUrl': 'settings/getUrl',
      'getLanguage': 'profile/getLanguage',
      'getPlatformId': 'rules/getPlatformId',
      'getCalendar': 'live/getCalendar'
    }),


  },
  async created() {
    await this.setPreload(true);
    await this.fetchCalendar({'language': this.getLanguage, 'date': this.dateToSearch})
    this.actualSearch = this.dateToSearch
    await this.setPreload(false);
  },


  methods: {

    ...mapActions({
      'setPreload': 'utils/setPreload',
      'fetchCalendar': 'live/fetchCalendar',
      'bookEvent': 'live/bookEvent',
      'autoBookAll': 'live/autoBookAll'
    }),

    ...mapMutations({}),

    async sendBookEvent(eventId){
      await this.setPreload(true);
      const result = await this.bookEvent({'id': eventId})
      if(result.result){
        await this.fetchCalendar({'language': this.getLanguage, 'date': this.dateToSearch})
      }else{
        alert(result.message)
      }
      await this.setPreload(false);
    },

    async searchEvents(){
      if(this.actualSearch == this.dateToSearch){
        return
      }else{
        this.actualSearch = this.dateToSearch
        await this.setPreload(true);
        await this.fetchCalendar({'language': this.getLanguage, 'date': this.dateToSearch})
        await this.setPreload(false);
      }

    },


    async bookAll(){
        await this.setPreload(true);
        await this.autoBookAll({'language': this.getLanguage, 'date': this.dateToSearch})
        await this.setPreload(false);

    }

  },
}
</script>

<style scoped>

</style>
