<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md12>

          <v-col cols="12">
            <v-card v-if="getItems.length>0" elevation="0" outlined id="live" >
              <v-tabs
                      dark
                      background-color="primary "
                      show-arrows

              >
                <v-tabs-slider color="primary "></v-tabs-slider>




                <v-tab v-for="item in getItems" :key="item.source" class="d-flex flex-column" @click="changeLive(item.source)"  >
                 <!-- <div class="d-flex align-start flex-column">
                    <v-img
                            :src="sportPath+item.id+'.png'"></v-img>
                  </div>-->

                  <div class="d-flex align-start flex-column iconeSport">
                    <v-img

                           :src='getIcon(item.id)'
                    ></v-img>
                  </div>
                  <div class="d-flex align-end flex-column">{{ item.name}}<br>{{ item.source}}</div>

                </v-tab>



              </v-tabs>
            </v-card>
            <v-card outlined elevation-0 >
              <v-container fluid>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">
                        ID
                      </th>
                      <th class="text-left">
                        Time
                      </th>
                      <th class="text-left">
                        Desc
                      </th>
                      <th class="text-left">
                        Status
                      </th>
                      <th class="text-left">
                        Path
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="item in getEvents"
                        :key="item.source"
                    >
                      <td>{{ item.source }}</td>
                      <td>{{ item.scheduled }}</td>
                      <td>{{ item.competitors[0].detail[0].text }} vs {{ item.competitors[1].detail[0].text }}</td>
                      <td>{{ item.sport_event_status._attributes.status }}</td>
                      <td>{{ item.sport }} - {{ item.category}} - {{ item.tournament }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>

              </v-container>
            </v-card>

          </v-col>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<style>

</style>
<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import _ from 'lodash'

export default {
  data() {
    return {
      sportPath: process.env.VUE_APP_URL_CLOUDFRONT + 'rocky/images/sport_flat/',
    }
  },

  watch: {

  },

  computed: {
    ...mapGetters({
      'getToken': 'profile/getToken',
      'getUrl': 'settings/getUrl',
      'getLanguage': 'profile/getLanguage',
      'getPlatformId': 'rules/getPlatformId',
      'getEvents': 'live/getEvents',
      'getItems': 'sport/getItems'
    }),


  },
  async created() {
    await this.setPreload(true);
    await this.fetchEvents({'language': this.getLanguage, 'sport':'sr:sport:1',platform:this.getPlatformId})
    await this.fetchSports({})
    await this.setPreload(false);
  },


  methods: {

    ...mapActions({
      'setPreload': 'utils/setPreload',
      'fetchEvents': 'live/fetchEvents',
      'fetchSports': 'sport/fetchSports',
    }),

    ...mapMutations({}),
    // getIcon(img) {
    //
    //
    //   let path = `@/assets/images/${img}.svg`
    //
    //   try {
    //     return require(`@/assets/svg/${img}.svg`)
    //   } catch (e) {
    //     return require(`@/assets/svg/cup.svg`)
    //   }
    // },

    async changeLive(sport){

      await this.setPreload(true);
      await this.fetchEvents({'language': this.getLanguage, 'sport':sport,platform:this.getPlatformId})

      await this.setPreload(false);
    }

  },
}
</script>
<style >
  #live .v-tabs-bar {
    border-radius: inherit;
    height: auto!important;
  }
  .iconeSport .v-image__image{

  }
  .iconeSport .v-image__image{
    padding:10px;
  }
  .iconeSport .v-responsive__content{
    width:60px!important; opacity: 0.5;
  }

  .iconeSport .v-image__image--cover{
    opacity: 0.5;
  }
  .iconeSport{
    font-size:0.50rem
  }
  .countSportLive{
    font-size:0.70rem;right: 0;
  }
  .titleSport{
    font-size:0.9rem

  }
  #live .v-tab--active{
    color:#ffffff;
  }

</style>
