import Navigation from '../components/core/Navigation.vue';
import Toolbar from '../components/core/Toolbar.vue';




function setupComponents(Vue){


    Vue.component('navigation', Navigation);
    Vue.component('toolbar', Toolbar);

}


export {
    setupComponents
}
