<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md12>
          <v-col cols="12">
            <v-row align="center">
              <v-col class="d-flex" cols="12" md="4">
                <v-select class="mt-0"
                        v-model="type" :items="types" item-text="value" item-value="type"
                        outlined dense

                ></v-select>
              </v-col>
              <v-col class="d-flex mt-0 pt-0" cols="12" md="4">
                <v-btn depressed color="secondary" @click="find()" class="mt-0"  >{{ $t('generic.search') }}</v-btn>
              </v-col>

            </v-row>
            <v-row align="center">
              <v-col class="d-flex" cols="12" md="" >
                <!--<v-btn depressed color="primary " @click="resetFilter" class="mr-5">{{ $t('generic.reset') }}</v-btn>-->
                <v-btn depressed color="secondary" @click="save()"  class="mr-5">{{ $t('applyall') }}</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-flex>
        <v-flex md12>
          <v-col cols="12">
            <span class="title-table"></span>

            <v-card outlined elevation-0 class="mt-1">
             <!-- <v-card-title>
                <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                ></v-text-field>
              </v-card-title>-->
              <v-data-table
                      :headers="headers"
                      :items="listEvents"
                      class="elevation-1 head-capitalize"
                      hide-default-footer
                      disable-pagination
                      item-key="id"
                      item-value="id"
                      v-model="selected"
                      show-select>

                <template v-slot:item.id="{ item }">
                  <span class="font-weight-bold">{{ item.id }}</span>
                </template>

                <template v-slot:item.quickbet="{ item }">
                  <span class="text-uppercase">{{ item.quickbet }}</span>
                </template>
                <template v-slot:item.source="{ item }">
                  <span class="text-uppercase">{{ item.source[0].value }}</span>
                </template>

                <template v-slot:item.scheduled="{ item }">
                  <span v-if="item.scheduled != null">{{ new Date(item.scheduled).toLocaleString() }}</span>
                </template>

                <template v-slot:item.event="{ item }">
                  {{getDescription(item)}}
                </template>

                <template v-slot:item.type="{ item }">

                  {{$t(getEventType(item.type)) | capitalize}}
                </template>

                <template v-slot:item.sport="{ item }">

                  {{ getDetail(item.sport.detail) }}
                </template>

                <template v-slot:item.category="{ item }">
                  {{ getDetail(item.category.detail) }}
                </template>

                <template v-slot:item.tournament="{ item }">
                  {{ getDetail(item.tournament.detail) }}
                </template>
                <template v-slot:item.odds="{ item }">

                  <span v-if="_.size(item.virtuals)>0" style="white-space: pre; ">

                     <v-text-field v-for="el in _.orderBy(item.virtuals[0].outcome,['alias'],['asc'])"
                                   :label="el.alias"
                                   v-model="el._attributes.odds"
                                   type="number"
                                   style="display: inline-block"
                     >
                    </v-text-field>
                  </span>
                  <span v-else-if="_.size(item.odds)>0" style="white-space: pre; ">

                     <v-text-field v-for="el in _.orderBy(item.odds[0].outcome,['alias'],['asc'])"
                                   :label="el.alias"
                                   v-model="el._attributes.odds"
                                   type="number"
                                   style="display: inline-block"
                     >
                    </v-text-field>
                  </span>


              </template>





              </v-data-table>


            </v-card>
          </v-col>
        </v-flex>
      </v-layout>
      <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :vertical="snackbar.mode === 'vertical'">
        {{ snackbar.text }}
        <v-btn dark text @click="snackbar.snackbar = false">
          Chiudi
        </v-btn>
      </v-snackbar>
    </v-container>

    <div class="text-center">
      <v-dialog v-model="dialogDefineSign" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Status
          </v-card-title>

          <v-card-text class="pt-5">
            <p class="font-weight-bold">{{this.descriptionToDefine}}</p>
            <v-select

                :items="results"
                item-text="name"
                item-value="value"
                filled
                v-model="resultToDefine"
            ></v-select>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions v-if="resultToDefine != ''">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="sendResult()">
              {{ $t('generic.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import _ from "lodash";
import { ObjectID } from 'bson';



export default {
  name: "Matchmanual",
  components: {},
  data() {
    return {
      antepost: [],
      selected: [],
      types:[],
      mixed: [],
      type:'',
      search: '',
      headers: [
        {text: this.$t('generic.id'), value: 'id',  sortable: false},
        {text: this.$t('quickbet'), value: 'quickbet' },
        {text: "source", value: 'source' },
        {text: this.$t('bets.date'), value: 'scheduled',sortable: true},
        {text: this.$t('bets.event'), value: 'event', sortable: true},
        {text: this.$t('bets.type'), value: 'type', },
        {text: this.$t('sport'), value: 'sport',  sortable: false},
        {text: this.$t('category'), value: 'category',  sortable: false},
        {text: this.$t('tournament'), value: 'tournament',  sortable: false},
        {text: this.$t('markets'), value: 'odds',  sortable: false},


      ],

    results:[
        {'name': this.$t('generic.selectOption'), 'value':''},
        {'name': "Not started yet", 'value':1},
        {'name': "In progress", 'value':2},
        {'name': "Finished", 'value':3},
        {'name': "Cancelled", 'value':4},
        {'name': "Postponed", 'value':5},
        {'name': "Interrupted", 'value':6},
        {'name': "Abandoned", 'value':7},
        {'name': "Coverage lost", 'value':8},
        {'name': "About to start", 'value':9},
      ],
      dialogDefineSign: false,
      signToDefine:{},
      resultToDefine: "",
      descriptionToDefine: "",
      listEvents:[],
      element:{},
      market:{},
      snackbar:{
        snackbar: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      'getLanguage': 'profile/getLanguage',
      'getToken': "profile/getToken",
      'getPlatformId': "rules/getPlatformId",
      'getOptions': 'bet/getOptions',
      'getUrlApi': 'settings/getUrl',
      'getEventsList': 'event/getEventsList',
      'getItems': 'market/getLists',
      'getEvents':  'eventmanual/getEvents',
      'getUrl': 'settings/getUrl',
    }),


  },

  async created() {
    await this.setPreload(true);

    this.element = _.find(this.getItems,{_id:this.$route.params.id})
    this.type="eventnotlocked"

    this.market = await this.fetchMarketOutcome({market:this.element.markets})

    await this.fetchEvents({element:this.element,type:this.type,platform:this.getPlatformId})
    this.listEvents = this.getEvents
    this.listEvents.forEach((event)=>{
      if(_.size(event.virtuals)>0) {
        this.selected.push(event)
      }
    })

    this.types=[
    {
      type:"eventlocked",
      value: this.$t('eventlocked'),
    },
    {
      type:"eventnotlocked",
      value:this.$t('eventnotlocked'),
    },
      {
        type:"allevents",
        value:this.$t('allevents'),
      }
    ]



    await this.setPreload(false);
  },
  watch: {

    selected: {
      handler: function(val,newval) {
        if(_.size(val)!=_.size(newval)) {

          this.listEvents.forEach((event)=>{
            /*if(_.size(event.virtuals)>0) {

              this.selected.push(event)
            }*/
            if(_.isArray(event.virtuals) && _.size(event.virtuals)==0){
              event.virtuals=null
            }
            let checkEvent = _.find(val,{id:event.id})

            if(checkEvent){
              if(!checkEvent.virtuals){
                let outcome = []
                _.forEach(this.market.outcomes,out =>{

                  let odd = _.find(this.element.odds,{id:out.id})

                  var _id = new ObjectID();
                  if(odd && odd.value) {
                    outcome.push({
                      _attributes: {
                        id: out.id,
                        external_id: _.toString(_id),
                        status: 1,
                        odds: _.toString(odd.value),
                        lastUpdate: new Date().toISOString(),
                        result: 0
                      },
                      detail: out.detail,
                      alias: out.name,
                      unique_id: `${checkEvent.id};${this.market.id};${out.id}`
                    })
                  }
                })
                checkEvent.virtuals = []
                checkEvent.virtuals.push(
                        {
                          "_attributes": {
                            "id": this.market.id,
                            "specifiers": "",
                            "market": this.market.detail,
                            "unique_id": _.toString(this.market.id),
                            "_id": this.market._id
                          },
                          "outcome": outcome,
                          "defined": false,
                          "platform":this.getPlatformId
                        }
                )

              }

            }else {
              event.virtuals=null

            }
          })



        }

        //console.log('value change: ', val,newval)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      'setPreload': 'utils/setPreload',
      'recoveryBetSettlement': 'bet/recoveryBetSettlement',
      'defineOdd': 'bet/defineOdd',
      'fetchEvents':'eventmanual/fetchEvents',
      'fetchMarketOutcome': 'market/fetchMarket',
    }),
    ...mapMutations({
      'setLanguage': 'profile/setLanguage',

    }),
    async find() {
      await this.setPreload(true);
      await this.fetchEvents({element:this.element,type:this.type,platform:this.getPlatformId})
      this.listEvents = this.getEvents
      this.listEvents.forEach((event)=>{
        if(_.size(event.virtuals)>0) {
          this.selected.push(event)
        }
      })
      await this.setPreload(false);

    },
    getDescription(item){
      if(item.type == 'match'){
        var home = _.find(item.competitors[0].detail, {'lang':this.getLanguage})
        var away = _.find(item.competitors[1].detail, {'lang':this.getLanguage})
        return home.text+' - '+away.text
      }else if(item.type == 'antepost'){
        var tournament = _.find(item.tournament.detail, {'lang':this.getLanguage})
        return 'Antepost '+tournament.text
      }
    },
    getEventType(type){
      switch (type){
        case 'match':
          return 'prematch'
        case 'antepost':
          return 'antepost'
      }
    },
    getDetail(detail){
      var detail = _.find(detail, {'lang':this.getLanguage})
      return detail.text
    },
    save(){
      let lists = _.map(this.listEvents,'id')
      let url = this.getUrl + 'markets/manual/storefreeze'
      this.setPreload(true);
      axios.post(url, {elements:this.selected,manual:this.$route.params.id,lists:lists,platform:this.getPlatformId}, {'headers': {'Authorization': `Bearer ${this.getToken}`}})
              .then(async (res) => {

                /*await this.fetchSkins({})
                var rule = _.find(this.getSkins, {platform_id: this.getPlatformId})
                this.listTemplates = (rule.templates) ? rule.templates : []
                */
                this.dialog = false;

                this.snackbar.color = "success";
                this.snackbar.text = this.$t("generic.successfully");
                this.snackbar.snackbar = true;
                this.snackbar.mode = 'multi-line';
                this.snackbar.timeout = 5000;
                //await this.fetchMarketManualList({platform_id:this.getPlatformId})
                this.setPreload(false);

              })

              .catch(err => {
                console.log(err)
                if (err.response) {

                  var msg_error = '';

                  if (err.response.data.error != undefined) {

                    err.response.data.error.forEach(value => {
                      this.messages[value.param] = value.msg
                    })

                    msg_error = err.response.statusText;

                  } else {

                    msg_error = err.response.data.message

                  }


                  this.snackbar.color = "error";
                  this.snackbar.text = msg_error;
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;

                  this.setPreload(false);

                  if (err.response.status === 401) {
                    this.setLogout();
                    this.$router.push('/login')
                  }

                }

              });
    }





  },
}
</script>

<style scoped>
.event-desc{
  font-size: 14px;
  padding-left: 10px;
  font-weight: 700;
}
.info-label{
  font-size: 11px;
  color: #888888;
}
</style>
