import _ from "lodash"

async function applyRules(rules, items) {
    try {

        _.forEach(rules, function (rulesGroup, index) {

            switch (index) {
                case "translation":
                    _.forEach(rulesGroup, function (rule, index) {

                        var index_of_item = _.findIndex(items, {'id': +rule.source});
                        if (index_of_item > -1)
                            items[index_of_item].detail = rule.detail

                    })
                    break
                case "status":
                    _.forEach(rulesGroup, function (rule, index) {

                        var index_of_item = _.findIndex(items, {'id': +rule.source});
                        if (index_of_item > -1)
                            items[index_of_item].active = rule.detail.active

                    })
                    break
                case "order":
                    _.forEach(rulesGroup, function (rule, index) {

                        var index_of_item = _.findIndex(items, {'id': +rule.source});
                        if (index_of_item > -1)
                            if(rule.detail && rule.detail.order) {
                                items[index_of_item].order = rule.detail.order
                            }

                    })
                    break
            }

        });

    }catch (e){
        console.log(e.message)
        console.log(e.stack)
    }

}

async function applyRulesToOdds(odds, eventSource, eventType, tree_structure, skinsList, platformId, producer, lang, mode = "preview") {

    try {

        var oddsArray = [];

        for (let odd of odds) {

            // var id_market = (mode == "detail") ? odd._attributes.id : odd._attributes.id
            // var market_unique_id = (mode == "detail") ? odd._attributes.unique_id : odd._attributes.unique_id
            // var status = (mode == "detail") ? "1" : odd._attributes.status
            // var marketObj = (mode == "detail") ? odd._attributes.market : odd._attributes.market
            // var oddOutcomes = (mode == "detail") ? odd.outcome : odd.outcome



            var id_market = odd._attributes.id
            var market_unique_id = odd._attributes.unique_id.toString()
            var status = "1"
            var marketObj = (odd.detail != undefined) ? odd.detail : odd._attributes.market
            var oddOutcomes = odd.outcome

            if(market_unique_id == undefined || market_unique_id == ''){
                market_unique_id = (odd._attributes.specifiers != undefined && odd._attributes.specifiers != '')? id_market+'#'+odd._attributes.specifiers : id_market
            }

            var prefixOutcome = eventSource+';'+market_unique_id+';';

            var multiplicity = await getTreeMultiplicity(tree_structure, skinsList, platformId, producer, market_unique_id, tree_structure.treeMultiplicity);
            var multiplicityEvent = await getEventMultiplicity(eventSource, skinsList, platformId, producer);
            var multiplicityMarketEvent = await getEventMultiplicity(eventSource, skinsList, platformId, producer, market_unique_id);

            var freeze_odds = await getEventFreeze(eventSource, skinsList, platformId, producer, market_unique_id);
            
            var key_from_rules = await getTreeKey(tree_structure, skinsList, platformId, producer, id_market.toString())
            var key_from_event = await getEventKey(eventSource, skinsList, platformId, producer, market_unique_id);
            var keyToApply = undefined

            if(key_from_event != undefined){
                keyToApply = key_from_event
            }else if(key_from_rules != undefined){
                keyToApply = key_from_rules
            }

            var multiplicity_with_rules = '';

            if(multiplicityMarketEvent != undefined){
                multiplicity_with_rules = multiplicityMarketEvent
            }else if(multiplicityEvent != undefined){
                multiplicity_with_rules = multiplicityEvent
            }else{
                multiplicity_with_rules = multiplicity
            }

            var split_multi = multiplicity_with_rules.split('-');
            var multiplicity_min = split_multi[0];
            var multiplicity_max = split_multi[1];


            if (status == null) {

                var marketObject = {
                    id_market: id_market,
                    unique_id: market_unique_id,
                    multiplicity: multiplicity_with_rules,
                    multiplicity_min: multiplicity_min,
                    multiplicity_max: multiplicity_max,
                    void: true,
                    active: false
                }

                oddsArray.push(marketObject)

            } else {

                if(freeze_odds != undefined){

                    var desc_market = _.find(marketObj, {'lang': lang});
                    if (!desc_market) {
                        desc_market = _.find(marketObj, {'lang': 'en'});
                    }

                    freeze_odds.name_market = (desc_market) ? desc_market.text : 'Market non tradotto'

                    for (let outcome of oddOutcomes) {

                        var id_outcome = (mode == "detail") ? outcome._attributes.id : null

                        if (outcome == null) {

                            console.log("##ERRORE: outcome market_id: " + id_market);

                        } else {


                            if (outcome.detail == undefined || outcome.detail == null || outcome.detail.length == 0) {
                                var desc_outcome_name = undefined;
                            } else {
                                var desc_outcome_name = _.find(outcome.detail, {'lang': lang});
                                if (!desc_outcome_name) {
                                    console.log('Fallback Inglese Outcome')
                                    console.log(outcome)
                                    desc_outcome_name = _.find(outcome.detail, {'lang': 'en'});
                                }
                            }

                            var outcomeToFind = _.find(freeze_odds.odds, {'id_outcome':id_outcome})
                            if(outcomeToFind){
                                outcomeToFind.outcome_name = (desc_outcome_name) ? desc_outcome_name.text : outcome.alias

                            }

                        }
                    }

                    var marketObject = {
                        ...freeze_odds
                    }

                    oddsArray.push(marketObject)

                    continue

                }

                var oddsMarket = []
                var keyPercent = 0.00;
                var odds_total = 0.00;
                var id_market = id_market;
                var specifier = (odd._attributes.specifiers != undefined) ? odd._attributes.specifiers : 'not_spec';

                var desc_market = _.find(marketObj, {'lang': lang});
                if (!desc_market) {
                    desc_market = _.find(marketObj, {'lang': 'en'});
                }

                if (oddOutcomes != undefined && oddOutcomes != null && oddOutcomes.length > 0) {

                    // Variable to show or not Market
                    var exitsOdds = false;

                    for (let outcome_to_sum of oddOutcomes) {
                        if (outcome_to_sum != null && outcome_to_sum._attributes != undefined && outcome_to_sum._attributes.odds != undefined) {
                            odds_total += parseFloat(outcome_to_sum._attributes.odds)
                        }
                    }

                    for (let outcome of oddOutcomes) {
                        if (outcome == null) {

                            console.log(oddOutcomes)
                            console.log(outcome)
                            console.log("##ERRORE: outcome market_id: " + id_market);

                        }else if(outcome._attributes == undefined){

                            if (outcome.detail == undefined || outcome.detail == null || outcome.detail.length == 0) {
                                var desc_outcome_name = undefined;
                            } else {
                                var desc_outcome_name = _.find(outcome.detail, {'lang': lang});
                                if (!desc_outcome_name) {
                                    console.log('Fallback Inglese Outcome')
                                    console.log(outcome)
                                    desc_outcome_name = _.find(outcome.detail, {'lang': 'en'});
                                }
                            }

                            var oddObject = {
                                id_market: id_market,
                                market_unique_id: market_unique_id,
                                market_name: (desc_market) ? desc_market.text : 'Market non tradotto',
                                id_outcome: outcome.id,
                                unique_id: prefixOutcome+outcome.id,
                                outcome_alias: outcome.alias,
                                outcome_name: (desc_outcome_name) ? desc_outcome_name.text : outcome.alias,
                                outcome_odd_value: 'lock',
                                outcome_odd_value_for_order: 999999999999999,
                                outcome_key_value: 0,
                                active: false,
                                status: 0,
                                multiplicity: multiplicity_with_rules,
                                multiplicity_min: multiplicity_min,
                                multiplicity_max: multiplicity_max,
                            }

                            oddsMarket.push(oddObject);

                            continue
                        }else{

                            var id_outcome = outcome._attributes.id
                            var outcome_unique_id = outcome.unique_id


                            if (outcome.detail == undefined || outcome.detail == null || outcome.detail.length == 0) {
                                var desc_outcome_name = undefined;
                            } else {
                                var desc_outcome_name = _.find(outcome.detail, {'lang': lang});
                                if (!desc_outcome_name) {
                                    console.log('Fallback Inglese Outcome')
                                    console.log(outcome)
                                    desc_outcome_name = _.find(outcome.detail, {'lang': 'en'});
                                }
                            }

                            if (outcome._attributes != undefined && outcome._attributes.odds != undefined && outcome._attributes.odds != 0.00 && outcome._attributes.odds >= 1.01) {

                                let key_value = 100 / parseFloat(outcome._attributes.odds);
                                let odd_percent = parseFloat(outcome._attributes.odds) * 100 / odds_total

                                var oddObject = {
                                    id_market: id_market,
                                    market_unique_id: market_unique_id,
                                    market_name: (desc_market) ? desc_market.text : 'Market non tradotto',
                                    id_outcome: id_outcome,
                                    unique_id: outcome_unique_id,
                                    outcome_alias: outcome.alias,
                                    ext_id: outcome._attributes.external_id,
                                    outcome_name: (desc_outcome_name) ? desc_outcome_name.text : outcome.alias,
                                    outcome_odd_value: parseFloat(outcome._attributes.odds).toFixed(2),
                                    outcome_odd_value_original: parseFloat(outcome._attributes.odds).toFixed(2),
                                    outcome_odd_percent: parseFloat(odd_percent).toFixed(2),
                                    outcome_odd_percent_original: parseFloat(odd_percent).toFixed(2),
                                    outcome_key_value: key_value,
                                    outcome_key_value_original: key_value,
                                    outcome_odd_value_for_order: parseFloat(outcome._attributes.odds),
                                    active: true,
                                    multiplicity: multiplicity_with_rules,
                                    multiplicity_min: multiplicity_min,
                                    multiplicity_max: multiplicity_max,
                                    type: 'prematch'
                                }

                                keyPercent += key_value;

                                exitsOdds = true

                            }else{

                                var oddObject = {
                                    id_market: id_market,
                                    market_unique_id: market_unique_id,
                                    id_outcome: id_outcome,
                                    unique_id: outcome_unique_id,
                                    outcome_alias: outcome.alias,
                                    outcome_name: (desc_outcome_name) ? desc_outcome_name.text : undefined,
                                    outcome_odd_value: 'lock',
                                    outcome_odd_value_for_order: 999999999999999,
                                    outcome_key_value: 0,
                                    active: false,
                                    status: 0,
                                    multiplicity: multiplicity_with_rules,
                                    multiplicity_min: multiplicity_min,
                                    multiplicity_max: multiplicity_max,
                                }

                            }

                            oddsMarket.push(oddObject);

                        }

                    }

                }else{

                    var marketObject = {
                        id_market: id_market,
                        unique_id: market_unique_id,
                        multiplicity: multiplicity_with_rules,
                        multiplicity_min: multiplicity_min,
                        multiplicity_max: multiplicity_max,
                        void: true,
                        active: false
                    }

                    oddsArray.push(marketObject)
                    continue

                }


                if(keyToApply == undefined && keyPercent < 105){
                    keyToApply = 105
                }

                // Forzo la key
                if(keyToApply != undefined){

                    var key_percent_original = keyPercent
                    var key_percent_fixed_original = parseFloat(keyPercent).toFixed(0)
                    var odds_total_original = odds_total
                    var newkey = parseFloat(keyToApply).toFixed(0)

                    keyPercent =  parseFloat(keyToApply).toFixed(0)
                    odds_total = odds_total_original *  key_percent_original / newkey

                    for(let odd of oddsMarket){
                        var new_odd_value = odd.outcome_odd_percent*odds_total/100;
                        odd.outcome_odd_value = (new_odd_value < 1.01) ? 'lock' : new_odd_value.toFixed(2)
                        odd.outcome_odd_value_original = new_odd_value.toFixed(2)
                        odd.outcome_key_value = 100/odd.outcome_odd_value
                        odd.outcome_key_value_original = 100/odd.outcome_odd_value
                        odd.outcome_odd_percent_original = odd.outcome_odd_percent
                        odd.outcome_odd_value_for_order = parseFloat(new_odd_value.toFixed(2))
                    }


                    var marketObject = {
                        id_market: id_market,
                        unique_id: market_unique_id,
                        multiplicity: multiplicity_with_rules,
                        multiplicity_min: multiplicity_min,
                        multiplicity_max: multiplicity_max,
                        void: false,
                        name_market: (desc_market) ? desc_market.text : 'Market non tradotto',
                        specifiers: specifier,
                        odds: (eventType != 'match' && oddsMarket.length >= 4 && mode == "detail") ? _.orderBy(oddsMarket,['outcome_odd_value_for_order'],['asc']) : oddsMarket,
                        odds_total: odds_total,
                        odds_total_original: odds_total,
                        key_from_rules: keyToApply,
                        key_percent: keyPercent,
                        key_percent_original: keyPercent,
                        key_percent_fixed: parseFloat(keyPercent).toFixed(0),
                        key_percent_fixed_original: parseFloat(keyPercent).toFixed(0),
                        key_locked: true,
                        override: true,
                        showMarket: exitsOdds,
                        freeze:false,
                        active: (status == '1')
                    }


                }else{

                    var marketObject = {
                        id_market: id_market,
                        unique_id: market_unique_id,
                        multiplicity: multiplicity_with_rules,
                        multiplicity_min: multiplicity_min,
                        multiplicity_max: multiplicity_max,
                        void: false,
                        name_market: (desc_market) ? desc_market.text : 'Market non tradotto',
                        specifiers: specifier,
                        odds: (eventType != 'match' && oddsMarket.length >= 4 && mode == "detail") ? _.orderBy(oddsMarket,['outcome_odd_value_for_order'],['asc']) : oddsMarket,
                        odds_total: odds_total,
                        odds_total_original: odds_total,
                        key_from_rules: key_from_rules,
                        key_percent: keyPercent,
                        key_percent_original: keyPercent,
                        key_percent_fixed: parseFloat(keyPercent).toFixed(0),
                        key_percent_fixed_original: parseFloat(keyPercent).toFixed(2),
                        key_locked: false,
                        override: false,
                        showMarket: exitsOdds,
                        freeze:false,
                        active: (status == '1')
                    }


                }


                oddsArray.push(marketObject)


            }

        }

        return oddsArray

    }catch (e){
        console.log(e.message)
        console.log(e.stack)
    }

}


async function refreshOdds(oddsMarkets, mode = "preview") {

    try {

        await Promise.all(Object.keys(oddsMarkets).map(async (index) => {

                var market = oddsMarkets[index];

                if(!market.freeze && market.status_to_refresh != undefined){

                    market.active = market.status_to_refresh

                    var odds_total = 0.00;
                    var keyPercent = 0.00;

                    for (let outcome_to_sum of market.odds) {
                        outcome_to_sum.active = outcome_to_sum.outcome_status_to_refresh;
                        if (outcome_to_sum != null && outcome_to_sum.outcome_value_to_refresh != undefined && outcome_to_sum.active) {
                            odds_total += parseFloat(outcome_to_sum.outcome_value_to_refresh)
                        }
                    }

                    await Promise.all(market.odds.map(async (outcome) => {

                        if(outcome.active){

                            outcome.outcome_odd_value_backup = outcome.outcome_odd_value

                            var change = 'equal'
                            if(parseFloat(outcome.outcome_value_to_refresh) > parseFloat(outcome.outcome_odd_value)){
                                change = 'up';
                            }else if(parseFloat(outcome.outcome_value_to_refresh) < parseFloat(outcome.outcome_odd_value)){
                                change = 'down';
                            }

                            outcome.type_change = change

                            let key_value = 100 / parseFloat(outcome.outcome_value_to_refresh);
                            let odd_percent = parseFloat(outcome.outcome_value_to_refresh) * 100 / odds_total

                            outcome.outcome_odd_value = parseFloat(outcome.outcome_value_to_refresh).toFixed(2)
                            outcome.outcome_odd_value_original = parseFloat(outcome.outcome_value_to_refresh).toFixed(2)
                            outcome.outcome_odd_percent = parseFloat(odd_percent).toFixed(2)
                            outcome.outcome_odd_percent_original = parseFloat(odd_percent).toFixed(2)
                            outcome.outcome_odd_value_for_order = parseFloat(outcome.outcome_value_to_refresh)

                            keyPercent += key_value;

                        }

                        })
                    )

                    if(market.override && market.key_locked) {

                        var key_percent_original = keyPercent
                        var key_percent_fixed_original = parseFloat(keyPercent).toFixed(0)
                        var odds_total_original = odds_total
                        var newkey = parseFloat(market.key_from_rules).toFixed(0)

                        keyPercent = parseFloat(market.key_from_rules).toFixed(0)
                        odds_total = odds_total_original * key_percent_original / newkey

                        await Promise.all(market.odds.map(async (odd) => {

                            if(odd.active) {
                                var new_odd_value = odd.outcome_odd_percent * odds_total / 100;

                                var change = 'equal'
                                if (parseFloat(new_odd_value.toFixed(2)) > parseFloat(odd.outcome_odd_value_backup)) {
                                    change = 'up';
                                } else if (parseFloat(new_odd_value.toFixed(2)) < parseFloat(odd.outcome_odd_value_backup)) {
                                    change = 'down';
                                }

                                odd.type_change = change

                                odd.outcome_odd_value = new_odd_value.toFixed(2)
                                odd.outcome_odd_value_original = new_odd_value.toFixed(2)
                                odd.outcome_odd_value_for_order = parseFloat(new_odd_value.toFixed(2))
                                odd.outcome_key_value = 100 / odd.outcome_odd_value
                                odd.outcome_key_value_original = 100 / odd.outcome_odd_value
                            }

                            })
                        )
                    }else{

                        market.key_percent = keyPercent
                        market.key_percent_original = keyPercent
                        market.key_percent_fixed = parseFloat(keyPercent).toFixed(0)
                        market.key_percent_fixed_original =parseFloat(keyPercent).toFixed(2)
                        market.odds_total = odds_total
                        market.odds_total_original = odds_total

                    }

                }

            })
        )

        return oddsMarkets

    }catch (e){
        console.log(e.message)
        console.log(e.stack)
    }

}

async function getEventMultiplicity(eventSource, skinsList, platformId, producer, market = "all") {

    try {

        // Level Event
        var eventAllManagerMultiplicity = await _.filter(_.find(skinsList, {"platform_id": 0}).rules, function (rule) {
            if (rule.type == "multiplicity" && rule.scope == "event#" + producer && rule.source == eventSource && rule.detail.market == market) {
                return rule.detail.multiplicity
            }
        });

        if(platformId > 0){
            var eventAllSkinMultiplicity = await _.filter(_.find(skinsList, {"platform_id": platformId}).rules, function (rule) {
                if (rule.type == "multiplicity" && rule.scope == "event#" + producer && rule.source == eventSource && rule.detail.market == market) {
                    return rule.detail.multiplicity
                }
            });
        }

        if(eventAllSkinMultiplicity != undefined && eventAllSkinMultiplicity.length == 1){
            // console.log('#Event Skin Mult', eventAllSkinMultiplicity[0].detail.multiplicity)
            return eventAllSkinMultiplicity[0].detail.multiplicity
        }else if(eventAllManagerMultiplicity != undefined && eventAllManagerMultiplicity.length == 1){
            // console.log('#Event Manager Mult', eventAllManagerMultiplicity[0].detail.multiplicity)
            return eventAllManagerMultiplicity[0].detail.multiplicity
        }else{
            return undefined
        }

    }catch (e){
        console.log(e.message)
        console.log(e.stack)
    }


}

async function getTreeKey(tree_structure, skinsList, platformId, producer, market) {

    try {

        var treeKey = undefined

        // Level Sport
        var sportManagerKey = await _.filter(_.find(skinsList, {"platform_id": 0}).rules, function (rule) {
            if (rule.type == "key" && rule.scope == "sport#" + producer && rule.source == tree_structure.sport.toString() && rule.detail.market == market) {
                return rule.detail.key
            }
        });

        if(platformId > 0){
            var sportSkinKey = await _.filter(_.find(skinsList, {"platform_id": platformId}).rules, function (rule) {
                if (rule.type == "key" && rule.scope == "sport#" + producer && rule.source == tree_structure.sport.toString() && rule.detail.market == market) {
                    return rule.detail.key
                }
            });
        }

        // Level Category
        var categoryManagerKey = await _.filter(_.find(skinsList, {"platform_id": 0}).rules, function (rule) {
            if (rule.type == "key" && rule.scope == "category#" + producer && rule.source == tree_structure.category.toString() && rule.detail.market == market) {
                return rule.detail.key
            }
        });

        if(platformId > 0){
            var categorySkinKey = await _.filter(_.find(skinsList, {"platform_id": platformId}).rules, function (rule) {
                if (rule.type == "key" && rule.scope == "category#" + producer && rule.source == tree_structure.category.toString() && rule.detail.market == market) {
                    return rule.detail.key
                }
            });
        }

        // Level Tournament
        var tournamentManagerKey = await _.filter(_.find(skinsList, {"platform_id": 0}).rules, function (rule) {
            if (rule.type == "key" && rule.scope == "tournament#" + producer && rule.source == tree_structure.tournament.toString() && rule.detail.market == market) {
                return rule.detail.key
            }
        });

        if(platformId > 0){
            var tournamentSkinKey = await _.filter(_.find(skinsList, {"platform_id": platformId}).rules, function (rule) {
                if (rule.type == "key" && rule.scope == "tournament#" + producer && rule.source == tree_structure.tournament.toString() && rule.detail.market == market) {
                    return rule.detail.key
                }
            });
        }

        if(tournamentSkinKey != undefined && tournamentSkinKey.length == 1){

            treeKey = tournamentSkinKey[0].detail.key

        }else if(tournamentManagerKey != undefined && tournamentManagerKey.length == 1){

            treeKey = tournamentManagerKey[0].detail.key

        }else if(categorySkinKey != undefined && categorySkinKey.length == 1){

            treeKey = categorySkinKey[0].detail.key

        }else if(categoryManagerKey != undefined && categoryManagerKey.length == 1){

            treeKey = categoryManagerKey[0].detail.key

        }else if(sportSkinKey != undefined && sportSkinKey.length == 1){

            treeKey = sportSkinKey[0].detail.key

        }else if(sportManagerKey != undefined && sportManagerKey.length == 1){

            treeKey = sportManagerKey[0].detail.key

        }

        return treeKey

    }catch (e){
        console.log(e.message)
        console.log(e.stack)
    }


}

async function getEventKey(eventSource, skinsList, platformId, producer, market) {

    try {

        // Level Event
        var eventAllManagerKey = await _.filter(_.find(skinsList, {"platform_id": 0}).rules, function (rule) {
            if (rule.type == "key" && rule.scope == "event#" + producer && rule.source == eventSource && rule.detail.market == market) {
                return rule.detail.key
            }
        });

        if(platformId > 0){
            var eventAllSkinKey = await _.filter(_.find(skinsList, {"platform_id": platformId}).rules, function (rule) {
                if (rule.type == "key" && rule.scope == "event#" + producer && rule.source == eventSource && rule.detail.market == market) {
                    return rule.detail.key
                }
            });
        }

        if(eventAllSkinKey != undefined && eventAllSkinKey.length == 1){
            return eventAllSkinKey[0].detail.key
        }else if(eventAllManagerKey != undefined && eventAllManagerKey.length == 1){
            return eventAllManagerKey[0].detail.key
        }else{
            return undefined
        }

    }catch (e){
        console.log(e.message)
        console.log(e.stack)
    }


}

async function getEventFreeze(eventSource, skinsList, platformId, producer, market) {

    try {

        // Level Event
        var eventAllManagerFreeze = await _.filter(_.find(skinsList, {"platform_id": 0}).rules, function (rule) {
            if (rule.type == "freeze" && rule.scope == "event#" + producer && rule.source == eventSource && rule.detail.market == market) {
                return rule.detail.odds
            }
        });

        if(platformId > 0){
            var eventAllSkinFreeze = await _.filter(_.find(skinsList, {"platform_id": platformId}).rules, function (rule) {
                if (rule.type == "freeze" && rule.scope == "event#" + producer && rule.source == eventSource && rule.detail.market == market) {
                    return rule.detail.odds
                }
            });
        }

        if(eventAllSkinFreeze != undefined && eventAllSkinFreeze.length == 1){
            return eventAllSkinFreeze[0].detail.odds
        }else if(eventAllManagerFreeze != undefined && eventAllManagerFreeze.length == 1){
            return eventAllManagerFreeze[0].detail.odds
        }else{
            return undefined
        }

    }catch (e){
        console.log(e.message)
        console.log(e.stack)
    }


}

async function getTreeMultiplicity(tree_structure, skinsList, platformId, producer, market = "all", treeMultiplicityDefault = undefined ) {

    try {

        var treeMultiplicity = (treeMultiplicityDefault != undefined)? treeMultiplicityDefault : await getDefaultMultiplicity(skinsList, platformId, producer);

        // Level Sport
        var sportManagerMultiplicity = await _.filter(_.find(skinsList, {"platform_id": 0}).rules, function (rule) {
            if (rule.type == "multiplicity" && rule.scope == "sport#" + producer && rule.source == tree_structure.sport && rule.detail.market == market) {
                return rule.detail.multiplicity
            }
        });

        if(platformId > 0){
            var sportSkinMultiplicity = await _.filter(_.find(skinsList, {"platform_id": platformId}).rules, function (rule) {
                if (rule.type == "multiplicity" && rule.scope == "sport#" + producer && rule.source == tree_structure.sport && rule.detail.market == market) {
                    return rule.detail.multiplicity
                }
            });
        }

        // Level Category
        var categoryManagerMultiplicity = await _.filter(_.find(skinsList, {"platform_id": 0}).rules, function (rule) {
            if (rule.type == "multiplicity" && rule.scope == "category#" + producer && rule.source == tree_structure.category && rule.detail.market == market) {
                return rule.detail.multiplicity
            }
        });

        if(platformId > 0){
            var categorySkinMultiplicity = await _.filter(_.find(skinsList, {"platform_id": platformId}).rules, function (rule) {
                if (rule.type == "multiplicity" && rule.scope == "category#" + producer && rule.source == tree_structure.category && rule.detail.market == market) {
                    return rule.detail.multiplicity
                }
            });
        }

        // Level Tournament
        var tournamentManagerMultiplicity = await _.filter(_.find(skinsList, {"platform_id": 0}).rules, function (rule) {
            if (rule.type == "multiplicity" && rule.scope == "tournament#" + producer && rule.source == tree_structure.tournament && rule.detail.market == market) {
                return rule.detail.multiplicity
            }
        });

        if(platformId > 0){
            var tournamentSkinMultiplicity = await _.filter(_.find(skinsList, {"platform_id": platformId}).rules, function (rule) {
                if (rule.type == "multiplicity" && rule.scope == "tournament#" + producer && rule.source == tree_structure.tournament && rule.detail.market == market) {
                    return rule.detail.multiplicity
                }
            });
        }

        if(tournamentSkinMultiplicity != undefined && tournamentSkinMultiplicity.length == 1){
            // console.log('#Tournament Skin Mult', tournamentSkinMultiplicity[0].detail.multiplicity)
            treeMultiplicity = tournamentSkinMultiplicity[0].detail.multiplicity
        }else if(tournamentManagerMultiplicity != undefined && tournamentManagerMultiplicity.length == 1){
            // console.log('#Tournament Manager Mult', tournamentManagerMultiplicity[0].detail.multiplicity)
            treeMultiplicity = tournamentManagerMultiplicity[0].detail.multiplicity
        }else if(categorySkinMultiplicity != undefined && categorySkinMultiplicity.length == 1){
            // console.log('#Category Skin Mult', categorySkinMultiplicity[0].detail.multiplicity)
            treeMultiplicity = categorySkinMultiplicity[0].detail.multiplicity
        }else if(categoryManagerMultiplicity != undefined && categoryManagerMultiplicity.length == 1){
            // console.log('#Category Manager Mult', categoryManagerMultiplicity[0].detail.multiplicity)
            treeMultiplicity = categoryManagerMultiplicity[0].detail.multiplicity
        }else if(sportSkinMultiplicity != undefined && sportSkinMultiplicity.length == 1){
            // console.log('#Sport Skin Mult', sportSkinMultiplicity[0].detail.multiplicity)
            treeMultiplicity = sportSkinMultiplicity[0].detail.multiplicity
        }else if(sportManagerMultiplicity != undefined && sportManagerMultiplicity.length == 1){
            // console.log('#Sport Manager Mult', sportManagerMultiplicity[0].detail.multiplicity)
            treeMultiplicity = sportManagerMultiplicity[0].detail.multiplicity
        }
        //
        // console.log(market)
        // console.log(treeMultiplicity)

        return treeMultiplicity

    }catch (e){
        console.log(e.message)
        console.log(e.stack)
    }


}

async function getDefaultMultiplicity(skinsList, platformId, producer) {

    try {

        var rule_default_manager = _.filter(_.find(skinsList, {"platform_id": 0}).rules, function (rule) {
            if (rule.type == "multiplicity" && rule.scope == "default#" + producer) {
                return rule
            }
        });


        if(platformId > 0){

            var rule_default_skin = _.filter(_.find(skinsList, {"platform_id": platformId}).rules, function (rule) {
                if (rule.type == "multiplicity" && rule.scope == "default#" + producer) {
                    return rule
                }
            });

            if (rule_default_skin.length == 1) {
                return rule_default_skin[0].detail.multiplicity
            }else{
                return rule_default_manager[0].detail.multiplicity
            }


        }else{

            return rule_default_manager[0].detail.multiplicity
        }

    }catch (e){
        console.log(e.message)
        console.log(e.stack)
    }
}

async function getInfoWithRules(element,skinsList, platformId, lang) {

    try {

        var manager = _.find(skinsList, {"platform_id": 0});

        var desc_element = _.find(element.detail, { 'lang': lang});
        if(!desc_element){
            desc_element = _.find(element.detail, { 'lang': 'en'});
        }
        var element_obj = {
            id : element.id,
            name: desc_element.text
        }

        const checkRulesSportManager = _.filter(manager.rules, {'id':element.id})
        if(checkRulesSportManager.length > 0){
            for(let rule of checkRulesSportManager){
                if(rule == "translation"){
                    element_obj.name = rule.detail
                }
            }
        }

        if(platformId > 0){
            const checkRulesSportSkin = _.filter(_.find(skinsList, {"platform_id": platformId}).rules, {'id':element.id})
            if(checkRulesSportSkin.length > 0){
                for(let rule of checkRulesSportSkin){
                    if(rule == "translation"){
                        element_obj.name = rule.detail
                    }
                }
            }
        }

        return element_obj;

    }catch (e){
        console.log(e.message)
        console.log(e.stack)
    }


}

async function getCompetitorsWithRules(competitor,skinsList, platformId,lang) {

    try {
        var competitorObj = (_.has(competitor, 'detail')) ? competitor : 'n.d.';

        if (competitorObj != 'n.d.') {

            var desc = _.find(competitorObj.detail, {'lang': lang});
            if (!desc) {
                desc = _.find(competitorObj.detail, {'lang': 'en'});
            }

            return {
                id: competitorObj.id,
                name: desc.text
            }

        } else {

            return {
                id: 'n.d.',
                source: 'n.d.',
                name: 'n.d.'
            }

        }
    }catch (e){

        console.log(e.message)
        console.log(e.stack)

    }

}

export default {
    applyRules,
    applyRulesToOdds,
    refreshOdds,
    getDefaultMultiplicity,
    getTreeMultiplicity,
    getInfoWithRules,
    getCompetitorsWithRules
}