import store from '../store';
import ReportBets from '../views/reports/Bets.vue'
import ReportTrends from '../views/reports/Trends.vue'
import {checkauth, checkRoute} from "../Helper";
export default [
    {
        path: '/reports/bets',
        name: 'report-bets',
        component: ReportBets,
        beforeEnter (to,from,next){
            checkRoute('/reports/bets').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },
    {
        path: '/reports/trends',
        name: 'report-trends',
        component: ReportTrends,
        beforeEnter (to,from,next){
            checkRoute('/reports/trends').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },

]
