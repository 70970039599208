import store from '../store';
import Login from '../views/auth/Login.vue'
export default [
    {
        path: '/login',
        name: 'login',
        component: Login,
        beforeEnter (to,from,next){
            if(store.getters["profile/getAutenticate"]==1){
                next('/home');
            }else {
                next();
            }
        }
    }


]
