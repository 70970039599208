<template>
    <div class="translations-markets-override">
        <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <span class="title-table">{{ $t('overrideMarketTranslations') | capitalize }}</span>
                    <v-card outlined elevation-0>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="2">
                                    <v-autocomplete
                                            v-model="searchSport"
                                            :items="sports"
                                            item-text="name"
                                            item-value="_id"
                                            outlined
                                            dense
                                            chips
                                            small-chips
                                            :label="$t('generic.sport')"
                                            deletable-chips>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-card>
                                        <v-data-table v-if="searchSport"
                                                      :headers="headers"
                                                      :items="getMarketsMini.markets"
                                                      class="elevation-1"
                                                      :items-per-page="30"
                                                      :search="search"
                                                      item-key="unique_id"
                                        >
                                            <template v-slot:top>
                                                <v-text-field
                                                        v-model="search"
                                                        :label="$t('generic.search')"
                                                        class="mx-4"
                                                ></v-text-field>
                                            </template>
                                            <template v-slot:item.actions="{ item }">
                                                <v-icon @click="editMarket(item)">mdi-magnify</v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-col>
                                <v-col cols="6" v-if="marketToEdit">
                                    <v-card>
                                        <v-card-title>
                                            <span class="market-name-box">{{ selectedMarketName != null ? selectedMarketName : '' }}</span>
                                        </v-card-title>
                                        <hr>
                                        <v-row class="pa-3" style="max-height: 915px; overflow-y: scroll;">
                                            <v-col cols="12" style="background: aliceblue;">
                                                <TranslationField
                                                        v-for="lang in languages"
                                                        :key="lang"
                                                        :label="$t(lang)"
                                                        v-model="marketToEdit[lang]"
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-btn @click="save()" color="primary" style="margin:6px;">
                                                    {{ $t('save') }}
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import TranslationField from '@/components/utils/TranslationField';
    import {mapActions, mapGetters} from 'vuex';

    export default {
        name: 'TranslationsMarketsOverride',
        components: {
            TranslationField,
        },
        data() {
            return {
                headers: [
                    {text: this.$t('generic.id'), value: 'unique_id'},
                    {text: this.$t('generic.name'), value: 'name'},
                    {text: this.$t('generic.actions'), value: 'actions'},
                ],
                search: '',
                searchSport: null,
                marketToEdit: null,
                sports: [],
                sportId: null,
                languages: ['it', 'en', 'es', 'pt', 'fr', 'tr', 'cn', 'de'],
                selectedMarketName: null
            };
        },
        watch: {
            getLanguage: {
                async handler(val) {
                    await this.setPreload(true);
                    await this.filterSport({language: val});
                    await this.setPreload(false);
                },
                immediate: true,
            },
            searchSport: 'fetchMarkets',
            getItemsSport(newValue) {
                this.sports = newValue;
                this.marketToEdit = null;
            },
        },
        computed: {
            ...mapGetters({
                getLanguage: 'profile/getLanguage',
                getItemsSport: 'sport/getItems',
                getMarkets: 'utils/getMarkets',
                getPlatformId: 'rules/getPlatformId',
                getMarketsMini: 'utils/getMarketsMini',
            }),
        },
        async mounted() {
            await this.setPreload(true);
            await this.fetchSkins();
            await this.fetchSports({language: this.getLanguage});
            await this.setPreload(false);
        },
        methods: {
            ...mapActions({
                fetchSkins: 'skins/fetchSkins',
                fetchSports: 'sport/fetchSports',
                filterSport: 'sport/filterSport',
                getMarketsBySportList: 'utils/getMarketsBySportList',
                setPreload: 'utils/setPreload',
                fetchMarketTranslations: 'market/fetchMarketTranslations',
                saveOrUpdateMarketTranslation: 'market/saveOrUpdateMarketTranslation',
            }),
            async fetchMarkets() {
                if (this.searchSport) {
                    await this.setPreload(true);
                    await this.getMarketsBySportList({
                        sport: this.searchSport,
                        lang: this.getLanguage,
                        enableSpecifiers: false,
                    });

                    const selectedSport = this.sports.find(sport => sport._id === this.searchSport);
                    if (selectedSport) {
                        this.sportId = selectedSport.id;
                    }

                    this.marketToEdit = null;
                    await this.setPreload(false);
                } else {
                    this.marketToEdit = null;
                }
            },
            async editMarket(item) {

                this.selectedMarketName = item.name;

                const result = await this.fetchMarketTranslations({
                    platformId: this.getPlatformId,
                    sportId: this.sportId,
                    marketId: item.id,
                });

                this.marketToEdit = result?.data?.translations ?? {
                    sportId: this.sportId,
                    marketId: item.id,
                    platformId: this.getPlatformId,
                    it: null,
                    en: null,
                    es: null,
                    pt: null,
                    fr: null,
                    tr: null,
                    cn: null,
                    de: null,
                };
            },
            async save() {
                try {
                    await this.saveOrUpdateMarketTranslation({
                        sportId: this.sportId,
                        marketId: this.marketToEdit.marketId,
                        platformId: this.getPlatformId,
                        it: this.marketToEdit.it,
                        en: this.marketToEdit.en,
                        es: this.marketToEdit.es,
                        pt: this.marketToEdit.pt,
                        fr: this.marketToEdit.fr,
                        tr: this.marketToEdit.tr,
                        cn: this.marketToEdit.cn,
                        de: this.marketToEdit.de,
                    });

                    this.$swal.fire({
                        title: this.$t('success'),
                        text: this.$t('successOperation'),
                        icon: 'success',
                        confirmButtonText: 'OK',
                    });
                } catch (e) {
                    console.error(e);
                    this.$swal.fire({
                        title: this.$t('error'),
                        text: this.$t('changesHaveNotBeenSaved'),
                        icon: 'error',
                        confirmButtonText: 'OK',
                    });
                }
            },
        },
    };
</script>

<style scoped>
    .translations-markets-override {

    }
</style>
