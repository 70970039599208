<template>
  <div>
    <v-card-title>
      {{ $t('risk.usersList') }}
      <v-spacer></v-spacer>
      <!--    <div class="statusUserSelectorContainer">-->
      <!--      <v-select-->
      <!--              label="Status"-->
      <!--              density="compact"-->
      <!--              :items="typeStatus"-->
      <!--              v-model="typeStatusSelected">-->
      <!--      </v-select>-->
      <!--    </div>-->
      <div class="typeOfUsersSelectorContainer">
        <v-select
            :label="this.$t('user.type_user')"
            density="compact"
            :items="typeUsers"
            v-model="typeUserSelected">
        </v-select>
      </div>

      <div class="search-container">
        <v-text-field v-model="search" append-icon="search"
                      :label="this.$t('generic.search')"></v-text-field>
      </div>

      <div class="search-container">
        <v-text-field v-model="searchById" append-icon="search"
                      :label="this.$t('searchById')"></v-text-field>
      </div>

      <div class="search-container">
        <v-text-field v-model="searchByLogin" append-icon="search"
                      :label="this.$t('searchByLogin')"></v-text-field>
      </div>
    </v-card-title>

    <v-data-table :headers="headers" :footer-props="footerTable" :items="filterItems()" :search="search"
                  item-key="_id"
                  class="elevation-0"
                  disable-pagination hide-default-footer
    >
      <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="item in items" :key="item.userId">
          <td>{{ item.userId }}</td>
          <td>{{ item.userName }}</td>
          <td>{{ item.email }}</td>
          <!--        <td>{{ item.agencyNet }}</td>-->
          <!--        <td>{{ item.operatorNet }}</td>-->
          <td>{{ item.ancestorUserName }}</td>
          <td>{{ item.profileType }}</td>
          <td :class="[(isUserBlockedStatus(item.blocked) == true) ? 'lockedStatus' : 'unlockedStatus']">
            {{ getTranslatedStatus(item.blocked) }}
          </td>

          <td width="60" v-if="item.blocked === false">
            <v-icon class="mr-0" @click="blockUser(item)" title="Lock">
              mdi-lock
            </v-icon>
          </td>

          <td width="60" v-else>
            <v-icon class="mr-0" @click="unlockUser(item)">
              mdi-lock-open
            </v-icon>
          </td>
          <!--        Subnet-->
          <td width="60" v-if="item.blockedSubnet === false">
            <v-icon class="mr-0" @click="blockUserSubnet(item)" title="Lock">
              mdi-lock
            </v-icon>
          </td>

          <td width="60" v-else>
            <v-icon class="mr-0" @click="unlockUserSubnet(item)">
              mdi-lock-open
            </v-icon>
          </td>

          <td width="60">
            <v-icon class="mr-0" @click="openMessageDialog(item)" title="Note">
              mdi-note-text
            </v-icon>
          </td>

          <td width="60">
            <!--          <v-icon class="mr-0" @click="detailUsers(item.userId,item.userName,Math.random())" title="Detail">-->
            <v-icon class="mr-0" @click="detailUsers(item.userId,item.userName,item.ancestorID)" title="Detail">
              mdi-account-circle
            </v-icon>
          </td>

          <td width="60">
            <v-icon class="mr-0" @click="detailEntityLimit(item)" title="Edit">
              mdi-account-edit
            </v-icon>
          </td>
        </tr>
        </tbody>
      </template>
    </v-data-table>
    <div class="text-center pt-3 pb-3">
      <v-pagination
          v-model="pagination.page"
          :current-page="pagination.page"
          :length="+(pagination.counts / pagination.itemsPerPage).toFixed(0)"
          :total-visible="10"
          @input="getNewPage(pagination.page)"
      ></v-pagination>
    </div>
    <v-row justify="center">
      <v-dialog persistent v-model="dialog" v-if="idUser !=null && login != null" :scrim="false"
                transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="closeDetail()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="text-capitalize">{{ login }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-spacer></v-spacer>
          <limits-component v-if="idUser !=null && login !=null" :userID="idUser"
                            :login="login" :ancestorID="ancestorID"></limits-component>
        </v-card>
      </v-dialog>
    </v-row>

    <userNote v-if="userTargetNote != null" :userId="userIdTargetNote" :platformId="platformIdTargetNote"
              :user="userTargetNote"></userNote>

    <entity-user-limits-modal v-if="userTargetForEntityLimit != null"
                              :userTarget=userTargetForEntityLimit></entity-user-limits-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import LimitsComponent from "../../components/risks/limits";
import userNote from "../../components/risks/userNote";
import EntityUserLimitsModal from "../../components/risks/entityUserLimitListModal"

export default {
  name: "bookieUserList",
  components: {LimitsComponent, userNote, EntityUserLimitsModal},
  props: {
    skin_id: {
      type: String,
      required: true,
      default: null
    }
  },
  data() {
    return {
      icons: [
        {'text': 'Black and white', value: "B/W"},
        {'text': 'Color', value: "Color"}
      ],
      search: '',
      searchById: '',
      searchByLogin: '',
      pagination: {
        sortBy: 'idUser',
        descending: true,
        itemsPerPage: 10,
        page: 1,
        counts: 0
      },
      headers: [
        {text: this.$t('id'), value: 'userId'},
        {text: this.$t('user.login'), value: 'userName'},
        // {text: this.$t('name'), value: 'name'},
        // {text: this.$t('user.surname'), value: 'surname'},
        {text: this.$t('email'), value: 'email'},
        // {text: this.$t('user.agencyLogin'), value: 'agencyLogin'},
        // {text: this.$t('user.agencyNet'), value: 'agencyNet'},
        // {text: this.$t('user.operatorLogin'), value: 'operatorLogin'},
        // {text: this.$t('user.operatorNet'), value: 'operatorNet'},
        {text: 'Ancestor', value: 'ancestorUserName'},
        {text: this.$t('type'), value: 'profileType'},
        {text: this.$t('status'), value: 'blocked'},
        {text: 'User', value: ''},
        {text: 'Subnet', value: ''},
        {text: this.$t('note'), value: ''},
        {text: this.$t('bets.detail'), value: ''},
        {text: this.$t('generic.edit'), value: ''}
      ],
      footerTable: {
        disableItemsPerPage: true,
        itemsPerPageText: this.$t('datatable.itemsPerPageText'),
        itemsPerPageAllText: this.$t('generic.all'),
        pageText: '{0}-{1} ' + this.$t('datatable.of') + ' {2}',
      },
      snackbar: {
        snackbar: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
      },
      users: [],
      blocked: [],
      usersFiltered: [],
      typeUsers: [
        {text: this.$t('generic.all'), value: 'ALL'},
        {text: this.$t('user.user'), value: 'Player'},
        {text: this.$t('user.agency'), value: 'Agency'},
        {text: this.$t('user.operator'), value: 'Operator'},
      ],
      typeUserSelected: 'ALL',
      typeStatus: [
        {text: this.$t('generic.all'), value: 'ALL'},
        {text: this.$t('user.unlocked'), value: 'UNLOCKED'},
        {text: this.$t('user.locked'), value: 'LOCKED'},
      ],
      typeStatusSelected: 'ALL',
      alertSuccessEnabled: false,
      alertErrorEnabled: false,
      dialogNote: false,
      userTargetNote: null,
      userIdTargetNote: '',
      platformIdTargetNote: '',
      userTargetReadNote: null,
      userIdTargetReadNotes: null,
      dialog: false,
      idUser: null,
      login: null,
      userID: null,
      ancestorID: null,
      userTargetForEntityLimit: null
    }
  },
  async mounted() {

    await this.getUsersList();

    this.$root.$on('closeDialogUserLimit', () => {
      this.dialog = false;
    })

    this.$root.$on('closedWriteNoteDialog', () => {
      this.dialogNote = false;
      this.userTargetNote = null;
      this.userIdTargetNote = null;
      this.platformIdTargetNote = null;
    })

    this.$root.$on('closedUserNotes', () => {
      this.userTargetReadNote = null;
      this.userIdTargetReadNotes = null;
      this.platformIdTargetNote = null;
    })

    this.$root.$on('closedUpdateEntityUserLimitDialog', () => {
      this.userTargetForEntityLimit = null;
    })

    this.$root.$on('resetValueInLimit', () => {
      console.log('resettiamo3')

      this.dialog = false;
      this.idUser = null;
      this.login = null;
      this.userID = null;
      this.ancestorID = null;
      this.userTargetForEntityLimit = null

      console.log('ancest')
      console.log(this.ancestorID)
    })

  },
  async beforeDestroy() {
    this.$root.$off('closeDialogUserLimit');
    this.$root.$off('closedWriteNoteDialog');
    this.$root.$off('closedUserNotes');
    this.$root.$off('closedUpdateEntityUserLimitDialog');
    this.$root.$off('resetValueInLimit');
  },
  watch: {
    typeUserSelected(newValue, oldValue) {
      this.getUsersList();
      this.filterItems();
    },
    typeStatusSelected(newValue, oldValue) {
      this.getUsersList();
      this.filterItems();
    },
    searchById(newValue, oldValue) {
      this.getUsersList();
      this.filterItems();
    },
    searchByLogin(newValue, oldValue) {

      if (newValue && newValue.length >= 3) {
        this.getUsersList();
        this.filterItems();
        return;
      }

      if (oldValue.length > 0 && newValue.length === 0) {
        this.getUsersList();
        this.filterItems();
      }
    }
  },
  computed: {
    ...mapGetters({
      'getPlatformId': 'rules/getPlatformId'
    }),
  },
  methods: {

    ...mapActions({
      'setPreload': 'utils/setPreload',
      'getUsers': 'skins/getSignUpUsers',
      'blockUserAction': 'skins/blockUser',
      'unlockUserAction': 'skins/unlockUser',
      'blockUserActionSubnet': 'skins/blockUserSubnet',
      'unlockUserActionSubnet': 'skins/unlockUserSubnet'
    }),
    detailUsers(id, login, ancestor) {
      this.userID = id;
      this.idUser = id;
      this.ancestorID = ancestor;
      this.login = login;
      this.dialog = true;
    },
    closeDetail() {
      this.dialog = false;
      this.userID = null;
      this.ancestorID = null;
      this.idUser = null;
      this.login = null;
    },
    openMessageDialog(user) {
      this.dialogNote = true;
      this.userTargetNote = user;
      this.userIdTargetNote = user.userId;
      this.platformIdTargetNote = this.getPlatformId;
    },
    showSuccessAlert() {
      this.alertSuccessEnabled = true;
      setTimeout(() => {
        this.alertSuccessEnabled = false
      }, 2000)
    },
    showErrorAlert() {
      this.alertErrorEnabled = true;
      setTimeout(() => {
        this.alertErrorEnabled = false
      }, 5000)
    },
    getTranslatedUserType(type) {

      let translatedType = this.$t('user.user');
      switch (type) {

        case "2":
          break
        case "3":
          translatedType = this.$t('user.operator');
          break
        case "4":
          translatedType = this.$t('user.agency');
          break

      }
      return translatedType;
    },
    isUserBlockedStatus(blockedStatus) {
      return blockedStatus
    },
    getTranslatedStatus(blockedStatus) {

      if (blockedStatus == true) {
        return this.$t('user.blocked')
      } else {
        return this.$t('user.unlocked')
      }
    },
    async getUsersList() {

      const managerId = 0;
      await this.setPreload(true);

      let userTypes = ""
      switch (this.typeUserSelected) {
        case "ALL":
          userTypes = ""
          break
        case "Player":
          userTypes = "PLAYER"
          break
        case "Cashier":
          userTypes = "CASHIER"
          break
        case "Agency":
          userTypes = "AGENCY"
          break
        case "Agency Marketing":
          userTypes = "AGENCY_MARKETING"
          break
        case "Operator":
          userTypes = "OPERATOR"
          break
        case "Promoter":
          userTypes = "PROMOTER"
          break
        case "Master":
          userTypes = "MASTER"
          break
        default:
          userTypes = ""
          break
      }

      const requestObj = {
        skin_id: this.skin_id, //BOOKIE
        platformId: this.getPlatformId, //ROCKY
        userType: userTypes,
        page: this.pagination.page,
        status: this.typeStatusSelected,
        userName: this.searchByLogin,
        userId: this.searchById,
      }

      const requestObjbl = {
        platformId: this.getPlatformId
      }

      const response = await this.getUsers(requestObj);
      const usersBlocked = response.usersBlocked;
      this.pagination.counts = usersBlocked != null && usersBlocked.length > 0 ? usersBlocked[0].total : 1;
      if (usersBlocked != null && usersBlocked.length > 0) {
        this.users = usersBlocked;
      }

      await this.setPreload(false);

    },
    async getNewPage(page) {
      this.pagination.page = page
      await this.getUsersList();
    },
    filterItems() {
      // this.getUsersList();
      // this.pagination.page = 1;
      let users = this.users;
      // switch (this.typeStatusSelected) {
      //   case "ALL":
      //     break
      //   case "UNLOCKED":
      //     users = this.users.filter(user => {
      //       return user.blocked === false
      //     })
      //     break
      //   case "LOCKED":
      //     users = this.users.filter(user => {
      //       return user.blocked === true
      //     })
      //     break
      // }
      //
      // if (this.searchById != null && this.searchById != '') {
      //   users = this.users.filter(user => {
      //     return user.idUser === this.searchById
      //   })
      // }
      //
      // if (this.searchByLogin != null && this.searchByLogin !== '') {
      //   users = this.users.filter(user => {
      //     // return user.login == this.searchByLogin
      //     return user.login.includes(this.searchByLogin)
      //   })
      // }

      return users
    },

    async blockUser(user) {

      const idUser = user.userId;

      const result = await this.blockUserAction(user);

      let executed = false;

      if (result != null && result.data != null) {
        executed = result.data.result;
      }

      if (executed == true) {

        this.showSuccessAlert();

        const userIndex = this.users.findIndex(user => {
          return user.userId == idUser
        });

        if (userIndex != -1) {
          this.users[userIndex].blocked = true;
        }

      } else {
        this.showErrorAlert();
      }
    },

    async unlockUser(user) {

      // console.log('user: ', user)

      const idUser = user.userId;
      const result = await this.unlockUserAction(user);

      let executed = false;

      if (result != null && result.data != null) {
        executed = result.data.result;
      }

      if (executed == true) {

        this.showSuccessAlert();
        const userIndex = this.users.findIndex(user => {
          return user.userId == idUser
        });

        if (userIndex != -1) {
          this.users[userIndex].blocked = false;
        }

      } else {
        this.showErrorAlert();
      }
    },

    async blockUserSubnet(user) {

      const idUser = user.userId;

      const result = await this.blockUserActionSubnet(user);

      let executed = false;

      if (result != null && result.data != null) {
        executed = result.data.result;
      }

      if (executed == true) {

        this.showSuccessAlert();

        const userIndex = this.users.findIndex(user => {
          return user.userId == idUser
        });

        if (userIndex != -1) {
          this.users[userIndex].blocked = true;
          this.users[userIndex].blockedSubnet = true;
        }

      } else {
        this.showErrorAlert();
      }
    },

    async unlockUserSubnet(user) {

      // console.log('user: ', user)

      const idUser = user.userId;
      const result = await this.unlockUserActionSubnet(user);

      let executed = false;

      if (result != null && result.data != null) {
        executed = result.data.result;
      }

      if (executed == true) {

        this.showSuccessAlert();
        const userIndex = this.users.findIndex(user => {
          return user.userId == idUser
        });

        if (userIndex != -1) {
          this.users[userIndex].blocked = false;
          this.users[userIndex].blockedSubnet = false;
        }

      } else {
        this.showErrorAlert();
      }
    },

    detailEntityLimit(user) {
      this.userTargetForEntityLimit = null;
      this.userTargetForEntityLimit = user;
    }
  },
}
</script>
