<template>

  <div>
    <v-container fluid fill-height>
      <v-layout justify-center align-center>
        <v-card width="100%" outlined elevation-0>
          <v-card-title>
            Cache
            <v-spacer></v-spacer>

          </v-card-title>
          <v-data-table :headers="headers" :items="themes" :search="search" item-key="_id" class="elevation-0">
            <template v-slot:body="{ items }">
              <tbody>
              <tr v-for="(item,index) in items" :key="index" >
                <td>{{ item.name }}</td>
                <td width="120">

                  <v-icon class="mr-0" @click="clearCache(item)">
                    mdi-trash-can
                  </v-icon>
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>

        <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'"
                    :timeout="snackbar.timeout" :vertical="snackbar.mode === 'vertical'">
          {{ snackbar.text }}
          <v-btn dark text @click="snackbar.snackbar = false">
            Chiudi
          </v-btn>
        </v-snackbar>


      </v-layout>
    </v-container>






  </div>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import _ from "lodash";

export default {
  name: "Themes",
  data() {
    return {
      themes: [
        {name:"Cloudfront",type:"Manager"},
        {name:"Rules",type:"All"},
        {name:"Settings",type:"All"},
        {name:"Markets",type:"All"},
        {name:"PreviewGroupsOdds",type:"All"},
        {name:"Preview Tournament Cache", type:"All"},
        {name:"Event of Day Cache", type:"All"},
        {name:"Next events cache", type: "All"},
        {name:"Clear all node cache", type: "All"}
      ],
      search: '',
      pagination: {
        sortBy: 'code',
        descending: true,
        rowsPerPage: 10
      },
      headers: [

        {text: 'Name', value: 'name'},
        {text: 'Action', value: '', sortable: false}
      ],

      snackbar: {
        snackbar: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
      },

      titleDialog: '',

      dialog: false,
      dialogConfirm: false,
      dialogEditTheme: false,
      dialogColorPicker: false,
      dialogColorPickerVuetify: false,

      colorPicker: '',
      keyColor: '',

      actionForm: '',

      form: {
        code: '',
      },
      deleteitem: '',
      show1: false,

      themeCode: '',
      messages: {
        code: [],
      },
    }
  },
  created() {

    if(this.getPlatform!=0){
      this.themes = _.filter(this.themes,el =>{
        return el.type!="Manager"
      })

    }
  },
  computed: {
    ...mapGetters({
      'getAutenticate': 'profile/getAutenticate',
      'getToken': 'profile/getToken',
      'getUrl': 'settings/getUrl',
      'getUrlRule': 'settings/getUrlRule',
      'getPlatform': 'profile/getPlatform',
      'getPreload': 'utils/getPreload',
    }),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      'setPreload': 'utils/setPreload',
      'fetchSkins': 'skins/fetchSkins',
      'cacheMarkets': 'cache/cacheMarket',
      'cacheSetting': 'cache/cacheSetting',
      'cacheRules': 'cache/cacheRules',
      'cacheCloudfront': 'cache/cacheCloudfront',
      'clearCachePreviewGroupsOdds': 'cache/clearCachePreviewGroupsOdds',
      'clearCacheTournamentPreview': 'cache/clearCacheTournamentPreview',
      'clearCacheEventOfDay': 'cache/clearCacheEventOfDay',
      'clearNextEventsCache': 'cache/clearNextEventsCache',
      'clearAllNodeCache': 'cache/clearAllNodeCache'
    }),


    openNew() {
      this.titleDialog = 'Nuovo Tema';
      this.resetForm()
      this.actionForm = 'create';
      this.dialog = true;
    },
    async clearCache(item){
      await this.setPreload(true);
      switch(item.name){
        case "Settings":
          await this.cacheSetting()
          await this.setPreload(false);
          break;
        case "Markets":
          await this.cacheMarkets()
          await this.setPreload(false);
          break;
        case "Rules":
          await this.cacheRules()
          await this.setPreload(false);
          break;
        case "Cloudfront":
          await this.cacheCloudfront()
          await this.setPreload(false);
          break;
        case "PreviewGroupsOdds":
          await this.clearCachePreviewGroupsOdds();
          await this.setPreload(false);
          break;
        case "Preview Tournament Cache":
            await this.clearCacheTournamentPreview();
            await this.setPreload(false);
            break;
        case "Event of Day Cache":
          await this.clearCacheEventOfDay();
          await this.setPreload(false);
          break;
        case "Clear all node cache":
          await this.clearAllNodeCache();
          await this.setPreload(false);
          break;
        case "Next events cache":
          await this.clearNextEventsCache();
          await this.setPreload(false);
          break;
      }

    },
    openColorPicker(value, key, mode){
      this.dialogColorPicker = true
      this.colorPicker = value
      this.keyColor = key
      this.modeColor = mode
    },

    saveColorPicker(){

      if(this.modeColor == 'betconsole'){
        this.form.colors.betconsole[this.keyColor] = this.colorPicker
      }else if(this.modeColor.includes('vuetify')){
        var split = this.modeColor.split('#')
        if(split[1] == 'dark'){
          this.form.colors.vuetify.dark[this.keyColor] = this.colorPicker
        }else if(split[1] == 'light'){
          this.form.colors.vuetify.light[this.keyColor] = this.colorPicker
        }
      }

      this.dialogColorPicker = false
      this.colorPicker = ''
      this.keyColor = ''

    },

    async updateTheme() {

      this.setPreload(true);
      let url = `${this.getUrl}themes/update/`;
      await axios.post(`${url}`, {code: this.form.code, colors: JSON.stringify(this.form.colors)},{'headers': {'Authorization': `Bearer ${this.getToken}`}})
          .then(res => {

            this.dialogEditTheme = false

            this.snackbar.color = "success";
            this.snackbar.text = (this.actionForm == "create") ? "Tema inserito con successo" : "Tema aggiornato con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            this.fetchThemes();
            this.resetForm();

            this.setPreload(false);

          })
          .catch(err => {
            console.log(err)
            if (err.response) {

              var msg_error = '';

              if (err.response.data.error != undefined) {

                err.response.data.error.forEach(value => {
                  this.messages[value.param] = value.msg
                })

                msg_error = err.response.statusText;

              } else {

                msg_error = err.response.data.message

              }


              this.dialogEditTheme = false
              this.snackbar.color = "error";
              this.snackbar.text = msg_error;
              this.snackbar.snackbar = true;
              this.snackbar.mode = 'multi-line';
              this.snackbar.timeout = 5000;

              this.setPreload(false);

              if (err.response.status === 401) {
                this.setLogout();
                this.$router.push('/login')
              }

            }

          });


    },


    openEdit(item) {
      this.form.colors = item.colors
      this.titleDialog = 'Modifica tema';
      this.actionForm = 'update';
      this.form.code = item.code
      if (this.form.colors == undefined) {
        this.form.colors = {
          betconsole: {
            "--text-color": "#fff",
            "--text-color-secondary": "#a9a9a9",
            "--border-color": "#4e4e4e",
            "--header-color": "#333333",
            "--hover-color": "#477ccc",
            "--active-color": "#fed430",
            "--cta-color": "#fed430",
            "--main-bg-color": "#333333",
            "--bg-dark-color": "#1e1e1e",
            "--primary-color": "#477ccc",
            "--secondary-color": "#fff",
            "--third-color": "#292d2f",
            "--fourth-color": "#646464",
            "--red-color": "#ff0000",
            "--red-alpha-color": "#ff4747",
            "--title-color": "#fff",
            "--row-hover": "#272727",
            "--row-odd": "#333",
            "--row-even": "#3c3c3c",
            "--loader-color": "#828282",
            "--loader-bg": "#1a1a1a",
            "--first-level-odds": "#1d1d1d",
            "--first-level-hover": "#2688e3",
            "--first-level-odds-active": "#2688e3",
            "--second-level-odds": "#212121",
            "--second-level-hover": "#477ccc",
            "--second-level-odds-active": "#212121",
            "--third-level-odds": "#3c3c3c",
            "--third-level-hover": "#477ccc",
            "--third-level-odds-active": "#477ccc",
            "--light-color": "#b7b7b7",
            "--search-bg": "#fff",
            "--search-text": "#000",
            "--odd-header-date-color": "#fff",
            "--odd-header-sign-color": "#b7b7b7",
            "--odd-bg": "#0e0e0e91",
            "--odd-bg-blocked": "#1d1d1d",
            "--odd-bg-activated": "#fed430",
            "--odd-bg-text": "#000",
            "--odd-bg-hover": "#565656",
            "--odd-text": "#efefef",
            "--odd-antepost-bg": "#252525",
            "--odd-other-odds": "#212121",
            "--odd-other-odds-opened": "#477ccc",
            "--odd-mult-1": "#ff9e06",
            "--odd-mult-2": "#f17030",
            "--odd-mult-3": "#a05c5e",
            "--odd-mult-4": "#00afec",
            "--odd-mult-5": "#31a02b",
            "--odd-mult-6": "#b9ff6f",
            "--odd-mult-7": "#183c80",
            "--odd-mult-8": "#e3004c",
            "--odd-mult-9": "#13096d",
            "--odd-mult-10": "#ffffff",
            "--legend-bg": "#3c3c3c",
            "--legend-txt": "#fff",
            "--cart-type-active-bg": "#fed430",
            "--cart-type-active-text": "#000",
            "--cart-type-bg": "#2f2f2f",
            "--cart-type-text": "#989898",
            "--cart-summary-bg": "#2f2f2f",
            "--cart-row-odd": "#333",
            "--cart-row-even": "#3c3c3c",
            "--cart-row-info": "#b7b7b7",
            "--cart-row-desc": "#fff",
            "--cart-row-odds-sign": "#fed430",
            "--cart-row-odds-value": "#fed430",
            "--cart-base-bg": "#fff",
            "--cart-base-bg-active": "#fed430",
            "--cart-base-color": "#212121",
            "--cart-text": "#b7b7b7",
            "--cart-header-bg": "#1e1e1e",
            "--cart-header-section-active-bg": "#1e1e1e",
            "--cart-header-section-active-text": "#fff",
            "--cart-header-section-bg": "#000",
            "--cart-header-section-text": "#989898",
            "--cart-summary-system-bg": "#989898",
            "--cart-summary-system-text": "#000",
            "--cart-actions-bg": "#dadada",
            "--cart-reset-button": "#8e2828",
            "--cart-reset-button-hover": "#c72323",
            "--cart-book-button": "#2a4c9e",
            "--cart-book-button-hover": "#20387d",
            "--cart-confirm-button": "#41ab39",
            "--cart-confirm-button-text": "#fff",
            "--cart-confirm-button-hover": "#6dca66",
            "--cart-buttons-bg": "#fff",
            "--cart-element-row-odd": "#fff",
            "--cart-element-row-even": "#fff",
            "--cart-quick-actions-bg": "#dadada",
            "--cart-quick-actions-button": "#212121",
            "--cart-quick-actions-button-hover": "#3c3c3c",
            "--cart-notify-bg": "#212121",
            "--cart-notify-text": "#fff",
            "--cart-changed-bg": "#fed430",
            "--cart-changed-text": "#000",
            "--fixed-cart-bg": "#fed430",
            "--fixed-cart-color": "#212121",
            "--market-box-header-bg": "#b7b7b7",
            "--market-box-odd-sign-bg": "#3a3a3a",
            "--market-box-odd-sign-bg-hover": "#535353",
            "--market-box-odd-sign-text": "#b3b3b3",
            "--market-box-odd-value-bg": "#1d1d1d",
            "--market-box-odd-value-text": "#e6e3e3",
            "--market-box-odd-value-bg-hover": "#3b3b3b",
            "--market-box-odd-border": "#92929261"
          },
          vuetify: {
            "light": {
              "primary": "#1976D2",
              "secondary": "#424242",
              "accent": "#82B1FF",
              "error": "#FF5252",
              "info": "#d00000",
              "success": "#4CAF50",
              "warning": "#FFC107"
            },
            "dark": {
              "primary": "#1976D2",
              "secondary": "#424242",
              "accent": "#82B1FF",
              "error": "#FF5252",
              "info": "#d00000",
              "success": "#4CAF50",
              "warning": "#FFC107"
            }
          }
        }
      } else {
        this.form.colors = item.colors
      }

      this.dialogEditTheme = true;
    },

    async submit() {

      await this.setPreload(true);

      var url = '';
      if (this.actionForm == "create") {
        url = this.getUrl + 'themes/create/'
        if (this.form.code == '') {

          this.snackbar.color = "error";
          this.snackbar.text = 'Code campo richiesto';
          this.snackbar.snackbar = true;
          this.snackbar.mode = 'multi-line';
          this.snackbar.timeout = 5000;

          this.setPreload(false);
        }
      } else if (this.actionForm == "update") {
        url = this.getUrl + 'themes/update/'
      }

      let payload = this.form;
      this.messages = [];

      await axios.post(url, payload, {'headers': {'Authorization': `Bearer ${this.getToken}`}})
          .then(res => {

            this.fetchThemes();
            this.dialog = false;

            this.snackbar.color = "success";
            this.snackbar.text = (this.actionForm == "create") ? "Tema inserito con successo" : "Tema aggiornato con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            this.resetForm();

          })

          .catch(err => {
            console.log(err)
            if (err.response) {

              var msg_error = '';

              if (err.response.data.error != undefined) {

                err.response.data.error.forEach(value => {
                  this.messages[value.param] = value.msg
                })

                msg_error = err.response.statusText;

              } else {

                msg_error = err.response.data.message

              }


              this.snackbar.color = "error";
              this.snackbar.text = msg_error;
              this.snackbar.snackbar = true;
              this.snackbar.mode = 'multi-line';
              this.snackbar.timeout = 5000;

              this.setPreload(false);

              if (err.response.status === 401) {
                this.setLogout();
                this.$router.push('/login')
              }

            }

          });

      await this.setPreload(false);

    },

    resetForm() {
      this.form.code = '';
      this.messages = []
    },

    deleteItem(item) {
      this.dialogConfirm = true;
      this.titleDialog = 'Sei sicuro ?'
      this.themeCode = item.code;
    },
    async confirmDelete() {

      this.dialogConfirm = false;
      await this.setPreload(true);
      var url = this.getUrl + 'themes/delete/' + this.themeCode
      this.messages = [];

      axios.delete(url, {'headers': {'Authorization': `Bearer ${this.getToken}`}})

          .then(res => {

            this.fetchThemes()

            this.snackbar.color = "success";
            this.snackbar.text = "Tema Eliminato con  successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            this.setPreload(false);

          })
          .catch(err => {

            console.log(err)
            if (err.response) {

              var msg_error = '';

              if (err.response.data.error != undefined) {

                err.response.data.error.forEach(value => {
                  this.messages[value.param] = value.msg
                })

                msg_error = err.response.statusText;

              } else {

                msg_error = err.response.data.message

              }


              this.snackbar.color = "error";
              this.snackbar.text = msg_error;
              this.snackbar.snackbar = true;
              this.snackbar.mode = 'multi-line';
              this.snackbar.timeout = 5000;

              this.setPreload(false);

              if (err.response.status === 401) {
                this.setLogout();
                this.$router.push('/login')
              }

            }


          });

    }
  },
}
</script>

<style scoped>

</style>
