import axios from "axios";
import store from "../index";
//import Message from '@/modules/message'
const state = {

    io:{},

};

const getters = {


};

const actions = {

    socket_login : async(context,data) => {
        var params = {
            room: process.env.VUE_APP_PLATFORM_ID
        }
        context.rootState.socket.io.emit('join',params,() => {
             // console.log(`Platform join this channel ${process.env.VUE_APP_PLATFORM_ID}`)
        })
    },
    SOCKET_ODDS_CHANGE_LIVE: async(context,data) => {
        // console.log(data)
        // console.log("ODD_CHANGE",data)
       /* if(data._attributes.event_id == "sr:match:23496067"){
            console.log(data.sport_event_status.clock._attributes.match_time)
        }
        if(context.rootGetters['tournament/getEventsPreview'].length > 0){
            context.dispatch('tournament/oddChangeSocket', data, {root:true})
        }

        if(context.rootGetters['utils/getMatchDetailDialog'] == true){
            context.dispatch('utils/oddChangeSocket', data, {root:true})
        }*/

    },
    /*SOCKET_BET_STOP: async(context,data) => {
        context.dispatch('tournament/betStopSocket', data, {root:true})
    },*/
    SOCKET_FIXTURE_CHANGE_LIVE: async(context,data) => {
        // console.log("FIXTURE",data)
        //context.dispatch('tournament/fixtureChangeSocket', data, {root:true})
    },
    SOCKET_UPDATE_RULES: async(context,data) => {
        // console.log("UPDATE_RULES",data)
        //context.dispatch('skins/updateRulesSocket', data, {root:true})
    },
    SOCKET_SETTLEMENT_LIVE: async(context,data) => {
        // console.log("SETTLEMENT_LIVE",data)
        //context.dispatch('skins/updateRulesSocket', data, {root:true})
    },
    SOCKET_LIVESCORE_LIVE: async(context,data) => {
        // console.log("LIVESCORE_LIVE",data)
        //context.dispatch('skins/updateRulesSocket', data, {root:true})
    },
    SOCKET_NEW_EVENT_LIVE: async(context,data) => {
        // console.log("NEW_EVENT_LIVE",data)
        //context.dispatch('skins/updateRulesSocket', data, {root:true})
    },








};

const mutations = {

    SOCKET_HELLO: async (context,data) =>{
        console.log(data)
    },

    /*SOCKET_MESSAGE_ORDER: (state,message) => {

    //console.log("state order",state)
    //console.log("message order",message)
        state.MESSAGE_ORDER= message;

    },*/
    /*SOCKET_MESSAGE_CRON: (state,message) => {

        console.log("state cron",state)
        console.log("message cron",message)
        state.MESSAGE_ORDER= message;

    },*/

   /* SOCKET_MESSAGE_UPDATE_ORDER: (state,message) => {


        //update_item()
        //this.dispatch('socket/update_item',{});
        //this.$store.dispatch('update_item')

        state.MESSAGE_UPDATE_ORDER= message;

    },*/

    /*MESSAGE_ORDER: (state,message) => {

       // console.log(state)
       // console.log(message)
        state.MESSAGE_ORDER= message;

    },*/
    setSocket:(state,socket) => {
        state.io = socket;
        //console.log("socket connect 2")
    },




};

const modules= {
  //  Message
}



export default {
    namespaced : true,
	state,
	mutations,
	getters,
	actions,
    modules

}
