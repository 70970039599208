<template>
    <div id="outcomeSettingsForMarketGroup">
        <v-container fluid>
            <v-row>
                <v-col cols="2">
                    <v-autocomplete
                            v-model="searchSport"
                            :items="getItemsSport"
                            item-text="name"
                            item-value="_id"
                            outlined
                            dense
                            chips
                            small-chips
                            :label="$t('generic.sport')"
                            deletable-chips
                            :key="'sportListKey'+sportListKey"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="2">
                    <v-autocomplete
                            v-model="searchProducer"
                            :items="getProducers"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                            chips
                            small-chips
                            :label="$t('generic.context')"
                            deletable-chips
                            :key="'producersListKey'+producersListKey"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="2">
                    <v-select
                            v-model="selectedMarketGroup"
                            :items="marketsGroups"
                            item-value="_id"
                            item-text="name"
                            :label="$t('group')|capitalize"
                            outlined
                            dense>
                    </v-select>
                </v-col>


            </v-row>
        </v-container>

        <v-container fluid v-if="searchSport != -1 && searchProducer === 0">
            <v-row>
                <v-col cols="6">
                    <v-card :key="'key_left_card_'+keyLeftCard">
                        <v-data-table
                                :headers="headers_preview"
                                :items="marketsList"
                                class="elevation-1"
                                :items-per-page="30"
                                :search="search"
                                item-key="unique_id"
                                :key="dataTableKey+dataTableKeyIndex">
                            <template v-slot:top>
                                <v-text-field
                                        v-model="search"
                                        :label="$t('generic.search')"
                                        class="mx-4">
                                </v-text-field>
                            </template>
                            <template v-slot:item.name="{ item }">
                                                  <span v-if="getIfActiveMarkets(item.id)">
                                                    <v-badge dot inline left
                                                             color="green"></v-badge>
                                                  </span>
                                {{ item.name }}
                            </template>

                            <template v-slot:item.isMainVisible="{ item }">
                                                  <span v-if="getIfDesktopMainMarketActive(item.id)">
                                                    <v-badge dot inline left color="green"></v-badge>
                                                  </span>
                            </template>

                            <template v-slot:item.isMainVisibleMobile="{ item }">
                                                  <span v-if="getIfMobileMainMarketActive(item.id)">
                                                    <v-badge dot inline left color="green"></v-badge>
                                                  </span>
                            </template>

                            <template v-slot:item.orderInMainSection="{ item }">
                                {{ item.orderInMainSection }}
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-icon v-if="selectedMarketGroup != null" @click="editMarketV2(item)">mdi-magnify
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
                <v-col cols="6" v-if="selectedMarketGroup != null && selectedMarketGroup != ''">
                    <v-card>
                        <v-card-title>
                            <span class="market-name-box">{{ this.marketToEdit.name }}</span>
                            <span style="font-weight: bold" v-if="isAHugeMarket===true"> HUGE MARKET</span>
                        </v-card-title>
                        <hr>
                        <div id="marketSettingContainer" class="col-md-12"
                             v-if="this.marketToEdit != null && this.marketToEdit.name != null">

                            <div style="display: flex">
                                <v-checkbox
                                        :label="$t('marketActive')"
                                        v-model="marketActive">
                                </v-checkbox>
                            </div>
                            <div id="typeViewContainer">
                                <v-row>
                                    <v-col cols="12" style="display: inline-flex; padding: 0px">
                                        <v-col md="3">
                                            <v-checkbox
                                                    label="Main preview"
                                                    v-model="mainPreview">
                                            </v-checkbox>
                                        </v-col>
                                        <v-col md="3">
                                            <v-checkbox
                                                    label="Main preview mobile"
                                                    v-model="mainPreviewMobile">
                                            </v-checkbox>
                                        </v-col>
                                    </v-col>
                                </v-row>
                            </div>
                            <div id="selectorSizeOddsContainer">
                                <div id="sizeOddsXlSelectorContainer">
                                    <v-select
                                            :items="sizeOddsXlView"
                                            v-model="selectedSizeXlOddButton"
                                            label="Desktop view">
                                    </v-select>
                                </div>
                                <div id="sizeOddsLgSelectorContainer">
                                    <v-select
                                            :items="sizeOddsLgView"
                                            v-model="selectedSizeLgOddButton"
                                            label="Laptop view">
                                    </v-select>
                                </div>
                                <div id="sizeOddsMdSelectorContainer">
                                    <v-select
                                            :items="sizeOddsMdView"
                                            v-model="selectedSizeMdOddButton"
                                            label="Tablet view">
                                    </v-select>
                                </div>
                            </div>
                            <div id="marketOrderInMainContainer">
                                <label>Desktop order</label>
                                <vue-numeric-input style="margin-left: 26px;"
                                                   v-model="orderMarketInMainPreview"
                                                   controlsType="updown"></vue-numeric-input>
                                <label style="margin-left: 26px;">Mobile order</label>
                                <vue-numeric-input style="margin-left: 26px;"
                                                   v-model="orderMarketMobileInMainPreview"
                                                   controlsType="updown"></vue-numeric-input>
                            </div>


                            <!--                            <v-btn color="blue-grey" class="white&#45;&#45;text text-right mt-2"-->
                            <!--                                   @click="updateSettingViewForMarket()">-->
                            <!--                                <v-icon left dark>save</v-icon>-->
                            <!--                                {{$t('generic.save')}}-->
                            <!--                            </v-btn>-->
                        </div>
                        <hr>
                    </v-card>
                    <v-card>
                        <v-card-title>
                            <span class="market-name-box">{{ this.marketToEdit.name }}</span>
                        </v-card-title>
                        <hr>
                        <v-row v-for="item in this.marketToEditArray">

                            <v-col cols="md-auto" v-for="col in item">
                                <div class="outcome-box" style="border: 0">
                                    <v-checkbox
                                            :label="`${col.show} ${col.specifiers}`"
                                            v-model="col.active">
                                    </v-checkbox>
                                </div>
                            </v-col>
                            <v-col cols="md-12">
                                <div style="margin-top: 20px;margin-left: 16px;">

                                    <template v-if="item != null && item.length > 0">
                                        <vue-numeric-input v-model="item[0].sort"
                                                           controlsType="updown" style="margin-right: 14px;">
                                        </vue-numeric-input>
                                    </template>
                                    <v-btn color="blue-grey" class="white--text text-right mt-2" style="margin-right: 10px" @click="selectAll(item)">
                                        <v-icon left dark>mdi-select-all</v-icon>
                                        {{ $t('selectAll') }}
                                    </v-btn>

                                    <v-btn color="blue-grey" class="white--text text-right mt-2" style="margin-right: 10px" @click="deselectAll(item)">
                                        <v-icon left dark>mdi-select-all</v-icon>
                                        {{ $t('deselectAll') }}
                                    </v-btn>

                                    <v-btn color="blue-grey" class="white--text text-right mt-2"
                                           @click="updateMarketSettingAndOutcomeSettings(item)">
                                        <v-icon left dark>save</v-icon>
                                        {{ $t('generic.save') }}
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-container fluid v-if="searchSport != -1 && searchProducer === 1">
            <v-row>
                <v-col cols="6">
                    <v-card :key="'key_left_card_'+keyLeftCard">
                        <v-data-table
                                :headers="header_live"
                                :items="marketsList"
                                class="elevation-1"
                                :items-per-page="30"
                                :search="search"
                                item-key="unique_id"
                                :key="dataTableKey+dataTableKeyIndex">
                            <template v-slot:top>
                                <v-text-field
                                        v-model="search"
                                        :label="$t('generic.search')"
                                        class="mx-4">
                                </v-text-field>
                            </template>

                            <template v-slot:item.active="{ item }">
                                                  <span v-if="getIfActiveMarkets(item.id)">
                                                    <v-badge dot inline left
                                                             color="green"></v-badge>
                                                  </span>
                            </template>
                            <template v-slot:item.name="{ item }">
                                {{ item.name }}
                            </template>


                            <template v-slot:item.orderInMainSection="{ item }">
                                {{ item.orderInMainSection }}
                            </template>


                            <template v-slot:item.actions="{ item }">
                                <v-icon v-if="selectedMarketGroup != null" @click="editMarketV2Live(item)">mdi-magnify
                                </v-icon>
                            </template>


                            <template v-slot:item.quickAdd="{ item }" v-if="selectedMarketGroup!= null">
                                <div  v-if="!getIfActiveMarkets(item.id)" @click="addMarketsToActivate(item)" style="cursor: pointer">
                                    <v-icon >mdi-plus
                                    </v-icon>
                                    /
                                    <v-icon>
                                        mdi-minus-box
                                    </v-icon>
                                </div>

                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>

                <v-col cols="6" v-if="selectedMarketGroup != null && selectedMarketGroup != ''">

                    <v-card v-if="marketsToActivate.length > 0">
                        <div id="quickMarketsToActivateInfo" class="col-md-12"
                             style="padding: 0px; padding-top:6px; padding-bottom: 6px;">

                            <!--                        <div class="col-md-12" style="padding: 0px" v-for="market of marketsToActivate">-->
                            <!--                                <span style="font-weight: bold"> Name: {{market.name}} </span>  <span style="font-weight: bold"> [</span> {{market.id}} <span style="font-weight: bold"> ]</span>-->
                            <!--                        </div>-->
                            <h3>Markets that will be enabled </h3>
                            <v-data-table
                                    :headers="headers_manage_quickly_markets"
                                    :items="marketsToActivate"

                                    class="elevation-1"
                            ></v-data-table>

                            <v-col cols="12" style="padding:0px; padding-top: 6px;">
                                <v-btn color="blue" @click="addMarketQuickly()">Add markets quickly
                                </v-btn>
                            </v-col>

                        </div>
                    </v-card>
                    <v-card>
                        <v-card-title>
                            <span class="market-name-box">{{ this.marketToEdit.name }}</span>
                            <span style="font-weight: bold" v-if="isAHugeMarket===true"> HUGE MARKET</span>
                        </v-card-title>
                        <hr>

                        <div id="marketSettingContainer" class="col-md-12"
                             v-if="this.marketToEdit != null && this.marketToEdit.name != null">

                            <div style="display: flex">
                                <v-checkbox
                                        :label="$t('marketActive')"
                                        v-model="marketActive">
                                </v-checkbox>
                            </div>


                            <div id="marketOrderInMainContainer">
                                <label>Desktop order</label>
                                <vue-numeric-input style="margin-left: 26px;"
                                                   v-model="orderMarketInMainPreview"
                                                   controlsType="updown"></vue-numeric-input>
                                <label style="margin-left: 26px;">Mobile order</label>
                                <vue-numeric-input style="margin-left: 26px;"
                                                   v-model="orderMarketMobileInMainPreview"
                                                   controlsType="updown"></vue-numeric-input>
                            </div>

                            <v-btn color="blue-grey" class="white--text text-right mt-2"
                                   @click="saveMarketViewSettingLive()">
                                <v-icon left dark>save</v-icon>
                                {{$t('generic.save')}}
                            </v-btn>
                        </div>
                        <hr>
                    </v-card>

                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<!-- todo API for get outcome selected , mainDesktop selected, mainMobileSelected that need to be called in mounted -->
<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import _ from "lodash";

    export default {
        name: "outcomeSettingsForMarketGroup",
        data() {
            return {
                headers_preview: [
                    {text: this.$t('generic.id'), value: 'unique_id'},
                    {text: this.$t('generic.name'), value: 'name'},
                    {text: this.$t('generic.isMainVisible'), value: 'isMainVisible'},
                    {text: this.$t('generic.isMainVisibleMobile'), value: 'isMainVisibleMobile'},
                    {text: this.$t('generic.orderInMainSection'), value: 'orderInMainSection'},
                    {text: this.$t('generic.actions'), value: 'actions'},
                ],
                header_live: [
                    {text: this.$t('generic.id'), value: 'unique_id'},
                    {text: this.$t('generic.active'), value: 'active'},
                    {text: this.$t('generic.name'), value: 'name'},
                    {text: this.$t('generic.orderInMainSection'), value: 'orderInMainSection'},

                    {text: this.$t('generic.actions'), value: 'actions'},
                    {text: this.$t('generic.quickAdd'), value: 'quickAdd'},
                ],
                headers_manage_quickly_markets: [
                    {text: this.$t('generic.id'), value: 'id'},
                    {text: this.$t('generic.name'), value: 'name'},
                ],
                search: '',
                searchSport: -1,
                searchProducer: -1,
                searchType: 'previewOdds',
                type: [{text: "Preview", value: "previewOdds"}],
                dataGroups: {},
                marketToEdit: {},
                marketToEditArray: [],
                listPlatform: [],
                marketsList: [],
                dataTableKey: 'dataTable_',
                dataTableKeyIndex: 1,
                mainPreview: false,
                mainPreviewMobile: false,
                sizeOddsMdView: ['md-2', 'md-3', 'md-4', 'md-6', 'md-12'],
                sizeOddsLgView: ['lg-2', 'lg-3', 'lg-4', 'lg-6', 'lg-12'],
                sizeOddsXlView: ['xl-2', 'xl-3', 'xl-4', 'xl-6', 'xl-12'],
                selectedSizeMdOddButton: 'md-2',
                selectedSizeLgOddButton: 'lg-2',
                selectedSizeXlOddButton: 'xl-2',
                orderMarketInMainPreview: 1,
                orderMarketMobileInMainPreview: 1,
                sort: 1,
                marketsGroups: [],
                selectedMarketGroup: null,
                outcomesSelected: [],
                keyLeftCard: 1,
                sportListKey: 1,
                producersListKey: 1,
                marketsIdActived: [],
                mainMarketsDesktop: [],
                mainMarketsMobile: [],
                marketActive: false,
                isAHugeMarket: false,
                marketsOrders: [],
                marketsToActivate: []
            }
        },

        watch: {
            getLanguage: async function (val) {
                await this.setPreload(true);
                await this.setLanguage(val);
                await this.filterSport({'language': val});
                await this.setPreload(false);
            },

            searchSport() {
                this.selectedMarketGroup = null;
                this.outcomesSelected = [];
                this.marketsIdActived = [];
                this.mainMarketsDesktop = [];
                this.mainMarketsMobile = [];
                this.getData()
            },

            getItemsSport() {
                this.sportListKey = this.sportListKey + 1
            },

            getProducers() {
                this.producersListKey = this.producersListKey + 1
            },

            searchProducer() {
                this.selectedMarketGroup = null;
                this.outcomesSelected = [];
                this.marketsIdActived = [];
                this.mainMarketsDesktop = [];
                this.mainMarketsMobile = [];
                this.getData()
            },

            searchType() {
                this.selectedMarketGroup = null;
                this.outcomesSelected = [];
                this.marketsIdActived = [];
                this.mainMarketsDesktop = [];
                this.mainMarketsMobile = [];
                this.getData()
            },
            getMarketsMini() {
                this.marketsList = this.getMarketsMini.markets
            },
            async selectedMarketGroup(newValue, oldValue) {


                this.marketsToActivate = [];

                if (this.searchProducer === 0) { // todo move me in a separate function
                    this.marketToEdit = {};
                    this.marketToEditArray = [];
                    this.outcomesSelected = [];
                    this.marketsIdActived = [];
                    this.mainMarketsDesktop = [];
                    this.mainMarketsMobile = [];
                    if (newValue != null && newValue != '') {
                        this.keyLeftCard = this.keyLeftCard + 1;

                        const sport = await this.getSportFrom_IdSport({_id: this.searchSport});
                        const sportId = sport.id;

                        const payloadRequestOutcomeEnabled = {
                            'platformId': this.getPlatformId,
                            'sportId': sportId,
                            'groupId': newValue, // todo fix me
                            'typeMatch': this.getProducers[this.searchProducer].name == 'Prematch & Antepost' ? 'prematch' : 'live',
                        }
                        const promiseArray = [];
                        promiseArray.push(this.getMarketIdsWithOutcomeEnabled(payloadRequestOutcomeEnabled));
                        promiseArray.push(this.getMarketsViewSettingsOrderForGroupId(payloadRequestOutcomeEnabled));

                        const promisesResults = await Promise.all(promiseArray);

                        const marketsIdWithOutcomeEnabled = promisesResults[0];
                        const marketViewSettingsOrders = promisesResults[1];

                        this.marketsIdActived = marketsIdWithOutcomeEnabled

                        this.marketsOrders = marketViewSettingsOrders.data || []

                        const marketsInEvidence = this.marketsList.filter(market => {
                            return this.marketsIdActived.includes(market.id)
                        })

                        const marketsNotInEvidence = this.marketsList.filter(market => {
                            return !this.marketsIdActived.includes(market.id)
                        })


                        let marketsOrderedTotal = [];

                        marketsOrderedTotal = marketsInEvidence.concat(marketsNotInEvidence);
                        this.marketsList = marketsOrderedTotal;

                        this.marketsList.map(market => {
                            market.orderInMainSection = this.getOrderInMainSection(market.id)
                            market.isInEvidence = this.marketsIdActived.includes(market.id)
                        })

                        this.marketsList = _.orderBy(this.marketsList, ['inEvidence', 'orderInMainSection'], ['desc', 'asc']);

                        const desktopMainMarketsIds = await this.getMainMarketsForPlatform({
                            'platform': this.getPlatformId,
                            'sportId': sportId,
                            'groupId': this.selectedMarketGroup,
                            "typeMatch": this.getProducers[this.searchProducer].name == 'Prematch & Antepost' ? 'prematch' : 'live'
                        });

                        // console.log('desktopMainMarketsIds')
                        // console.log(desktopMainMarketsIds)

                        for (const market of desktopMainMarketsIds) {
                            this.mainMarketsDesktop.push(market.marketId)
                        }
                        const mobileMainMarketsIds = await this.getMainMarketsMobileForPlatform({
                            'platform': this.getPlatformId,
                            'sportId': sportId,
                            'groupId': this.selectedMarketGroup,
                            "typeMatch": this.getProducers[this.searchProducer].name == 'Prematch & Antepost' ? 'prematch' : 'live'
                        });

                        for (const market of mobileMainMarketsIds) {
                            this.mainMarketsMobile.push(market.marketId)
                        }

                        // console.log('mobileMainMarketsIds')
                        // console.log(mobileMainMarketsIds)
                    }
                } else if (this.searchProducer === 1) { // todo move me in a separate function
                    this.marketToEdit = {};
                    this.marketToEditArray = [];
                    this.outcomesSelected = [];
                    this.marketsIdActived = [];
                    this.mainMarketsDesktop = [];
                    this.mainMarketsMobile = [];
                    if (newValue != null && newValue != '') {
                        this.keyLeftCard = this.keyLeftCard + 1;

                        const sport = await this.getSportFrom_IdSport({_id: this.searchSport});
                        const sportId = sport.id;

                        const payloadRequestOutcomeEnabled = {
                            'platformId': this.getPlatformId,
                            'sportId': sportId,
                            'groupId': newValue,
                            'typeMatch': this.getProducers[this.searchProducer].name == 'Prematch & Antepost' ? 'prematch' : 'live',
                        }
                        const promiseArray = [];
                        promiseArray.push(this.getMarketsViewSettingsOrderForGroupId(payloadRequestOutcomeEnabled)); // todo delete me or sobstitute with getMarketsViewSettingsActiveForGroupId

                        const promisesResults = await Promise.all(promiseArray);

                        const marketViewSettingsOrders = promisesResults[0];


                        this.marketsOrders = marketViewSettingsOrders.data || []

                        // todo call an api that get all the market view settings ENABLED for that group

                        const response = await this.getMarketsViewSettingsActiveForGroupId(payloadRequestOutcomeEnabled)
                        this.marketsIdActived = response.marketIds;

                        const marketsInEvidence = this.marketsList.filter(market => {
                            return this.marketsIdActived.includes(market.id)
                        })

                        const marketsNotInEvidence = this.marketsList.filter(market => {
                            return !this.marketsIdActived.includes(market.id)
                        })


                        let marketsOrderedTotal = [];

                        marketsOrderedTotal = marketsInEvidence.concat(marketsNotInEvidence);
                        this.marketsList = marketsOrderedTotal;

                        this.marketsList.map(market => {
                            market.orderInMainSection = this.getOrderInMainSection(market.id)
                            market.isActive = this.marketsIdActived.includes(market.id)
                        })

                        this.marketsList = _.orderBy(this.marketsList, ['isActive', 'orderInMainSection'], ['desc', 'asc']);


                    }
                }


            }
        },

        computed: {
            ...mapGetters({
                'getLanguage': 'profile/getLanguage',
                'getItemsSport': 'sport/getItems',
                'getProducers': 'producers/getList',
                'getPlatformId': 'rules/getPlatformId',
                'getSkins': 'skins/getList',
                'getMarketsMini': 'utils/getMarketsMini',
            }),

        },
        async created() {

        },

        async mounted() {
            await this.setPreload(true);
            await this.fetchSports({'language': this.getLanguage});
            if (this.searchProducer != -1 && this.searchSport != -1) {
                await this.getSettingsGroup();
            }

            //todo get markets active for desktop and mobile and set it (do API)

            // if (_.size(this.getSkins) > 0) {
            //     this.listPlatform = _.filter(this.getSkins, (el) => {
            //         return el.platform_id != 0
            //     })
            // }
            this.marketsList = this.getMarketsMini.markets
            await this.setPreload(false);
        },

        methods: {

            ...mapActions({
                'fetchSports': 'sport/fetchSports',
                'filterSport': 'sport/filterSport',
                'setPreload': 'utils/setPreload',
                'getMarketsBySportList': 'utils/getMarketsBySportList',
                'fetchMarketBySport': 'market/fetchMarketBySport',
                'getSportFrom_IdSport': 'sport/getSportFrom_IdSport',
                'getMainMarketSettingInfoWithDefaultValues': 'utils/getMainMarketSettingInfoWithDefaultValues',
                'updateMainMarketSetting': 'utils/updateMainMarketSetting',
                'updateMarketOutcomeSetting': 'utils/updateMarketOutcomeSetting',
                'fetchSettingsGroupsMarketV2': 'utils/fetchSettingsGroupsMarketV2',
                'getMarketOutcomeViewSetting': 'utils/getMarketOutcomeViewSetting',
                'getMarketIdsWithOutcomeEnabled': 'utils/getMarketIdsWithOutcomeEnabled',
                'getMarketsViewSettingsOrderForGroupId': 'utils/getMarketsViewSettingsOrderForGroupId',
                'getMainMarketsForPlatform': 'utils/getMainMarketsForPlatform',
                'getMainMarketsMobileForPlatform': 'utils/getMainMarketsMobileForPlatform',
                'getMarketsViewSettingsActiveForGroupId': 'utils/getMarketsViewSettingsActiveForGroupId',
                'enableMarketsViewSettings': 'utils/enableMarketsViewSettings'
            }),

            ...mapMutations({
                'setLanguage': 'profile/setLanguage',
            }),

            getData() {
                if (this.searchProducer != -1 && this.searchSport != -1) {
                    this.dataGroups = {}
                    this.getSettingsGroup();
                }
            },
            async getSettingsGroup() { // todo more actions in async
                await this.setPreload(true);

                await this.getMarketsBySportList({
                    'sport': this.searchSport,
                    'lang': this.getLanguage,
                    'enableSpecifiers': false
                });

                const sport = await this.getSportFrom_IdSport({_id: this.searchSport});
                const sportId = sport.id;

                const marketsGroups = await this.fetchSettingsGroupsMarketV2({
                    'sportId': sportId,
                    'platformId': this.getPlatformId,
                    "type": this.getProducers[this.searchProducer].name == 'Prematch & Antepost' ? 'prematch' : 'live',
                });

                this.marketsGroups = [];

                if (marketsGroups != null && marketsGroups.length > 0) {
                    this.marketsGroups = _.orderBy(marketsGroups, 'sort', 'asc');
                }

                let newGroup = {
                    platform: this.getPlatformId,
                    section: "sport",
                    source: this.searchSport,
                    type: this.searchType,
                    specifiers: []
                }
                this.dataGroups = newGroup


                await this.setPreload(false);
            },

            getIfActiveMarkets(id) {

                if (this.marketsIdActived.includes(id)) {
                    return true
                } else {
                    return false;
                }
            },
            getIfDesktopMainMarketActive(id) {
                if (this.mainMarketsDesktop.includes(id)) {
                    return true;
                } else {
                    return false;
                }
            },
            getIfMobileMainMarketActive(id) {
                if (this.mainMarketsMobile.includes(id)) {
                    return true;
                } else {
                    return false
                }
            },

            async updateOutcomeSettings(items) {

                // await this.setPreload(true);

                const selectedItems = items.filter(item => {
                    return item.active === true
                })

                const oddsList = [];

                for (const itemSelected of selectedItems) {
                    oddsList.push(itemSelected.key)
                }

                const specifier = items[0].specifiers != null && items[0].specifiers != '' ? items[0].specifiers : 'noSpec';

                const vm = this;
                const sport = await this.getSportFrom_IdSport({_id: this.dataGroups.source});
                const sportId = sport.id;

                const dataObj = {
                    platform: this.dataGroups.platform,
                    sportId: sportId,
                    groupId: this.selectedMarketGroup,
                    marketId: this.marketToEdit.id,
                    specifier: specifier,
                    oddsList: oddsList,
                    typeMatch: this.getProducers[this.searchProducer].name == 'Prematch & Antepost' ? 'prematch' : 'live',
                    sort: items[0].sort
                }

                try {
                    const response = await this.updateMarketOutcomeSetting(dataObj);
                    vm.$swal.fire(this.$t('successOperation'), '', 'success')
                } catch (e) {
                    console.log(e);

                    vm.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: this.$t('operationNotPerformedCorrectly')
                    })
                }


                // this.updateMarketOutcomeSetting(dataObj).then(async response => {
                //
                //     const findMarket = vm.marketsList.filter(market => {
                //         return market.id == vm.marketToEdit.id
                //     })
                //
                //     if (findMarket) {
                //         findMarket.isMainVisible = vm.mainPreview;
                //         findMarket.isMainVisibleMobile = vm.mainPreviewMobile;
                //     }
                //
                //
                //     const saveMarketList = vm.marketsList;
                //     vm.marketsList = null;
                //     vm.marketsList = saveMarketList;
                //     vm.dataTableKeyIndex = vm.dataTableKeyIndex + 1;
                //
                //     vm.$swal.fire(this.$t('successOperation'), '', 'success')
                //
                // }).catch(async error => {
                //     console.log(error);
                //
                //     vm.$swal.fire({
                //         icon: 'error',
                //         title: 'Oops...',
                //         text: this.$t('operationNotPerformedCorrectly')
                //     })
                // })

                // await this.getSettingsGroup();
                // await this.setPreload(false);
            },

            async updateMarketSettingAndOutcomeSettings(items) {
                await this.setPreload(true);

                if (this.isAHugeMarket === false) {
                    await this.updateOutcomeSettings(items);
                }

                await this.updateSettingViewForMarket();
                await this.setPreload(false);
            },


            async selectAll(item){

                let specifier = item && item[0] && item[0].specifiers  ?  item[0].specifiers : "";

                const toConsider = this.marketToEditArray.find(el => {return el[0].specifiers === specifier});

                if(toConsider){
                    for(const el of toConsider){
                        el.active = true
                    }
                }
            },
            async deselectAll(item){

                let specifier = item && item[0] && item[0].specifiers  ?  item[0].specifiers : "";

                const toConsider = this.marketToEditArray.find(el => {return el[0].specifiers === specifier});

                if(toConsider){
                    for(const el of toConsider){
                        el.active = false
                    }
                }
            },

            async editMarketV2(item) {
                await this.setPreload(true);
                this.outcomesSelected = [];

                await this.getMarketViewSetting(item);
                await this.getMarketOutcomeSettings(item);

                const idMarket = item.id;

                const result = await this.fetchMarketBySport({
                    sportId: this.searchSport,
                    marketId: idMarket,
                    language: this.getLanguage
                })

                this.isAHugeMarket = result.isAHugeMarket
                this.marketToEdit = {...result}

                if (_.size(result.values) > 0) {
                    result.values = result.values.sort(function (a, b) {
                        return Number(a.value) - Number(b.value)
                    });
                }

                if (_.size(result.values) > 0) {

                    var groupOdds = []
                    _.forEach(result.values, val => {
                        var arrayElements = []
                        _.forEach(result.outcomes, out => {
                            _.assignIn(out, {active: false})

                            let isCheckedOutcome = false;

                            const findSettingsForThisSpecifier = this.outcomesSelected.find(setting => {
                                return setting.specifier == val.value;
                            })

                            const enabledOutcomes = findSettingsForThisSpecifier != null ? findSettingsForThisSpecifier.outcomesEnabled : [];
                            const sortNew = findSettingsForThisSpecifier != null ? findSettingsForThisSpecifier.sort : undefined;

                            if (enabledOutcomes.includes(out.id.toString())) {
                                isCheckedOutcome = true;
                            }

                            arrayElements.push({
                                name: `${result.name}`,
                                show: out.name,
                                key: out.id,
                                detail: this.marketToEdit.detail,
                                outcomes: this.marketToEdit.outcomes,
                                id: this.marketToEdit.id,
                                specifiers: val.value,
                                unique_id: `${this.marketToEdit.unique_id}#${val.value}`,
                                sort: sortNew,
                                active: isCheckedOutcome
                            })

                        })
                        groupOdds.push(arrayElements)

                    })
                    //console.log(groupOdds)
                    this.marketToEditArray = groupOdds
                } else {

                    var arrayElements = []
                    var groupOdds = [];
                    _.forEach(result.outcomes, val => {

                        _.assignIn(val, {active: false})

                        let isCheckedOutcome = false;


                        const findSettingsForThisSpecifier = this.outcomesSelected.find(setting => {
                            return setting.specifier == 'noSpec'
                        })


                        const enabledOutcomes = findSettingsForThisSpecifier != null ? findSettingsForThisSpecifier.outcomesEnabled : [];
                        const sortNew = findSettingsForThisSpecifier != null ? findSettingsForThisSpecifier.sort : undefined;

                        if (enabledOutcomes.includes(val.id.toString())) {
                            isCheckedOutcome = true;
                        }

                        arrayElements.push({
                            name: `${val.name}`,
                            show: `${val.name}`,
                            key: val.id,
                            detail: this.marketToEdit.detail,
                            outcomes: this.marketToEdit.outcomes,
                            id: this.marketToEdit.id,
                            specifiers: "",
                            unique_id: `${this.marketToEdit.unique_id}`,
                            // sort: (checkPresent) ? checkPresent.sort : 0,
                            sort: sortNew,
                            active: isCheckedOutcome
                        })

                    })
                    groupOdds.push(arrayElements)

                    this.marketToEditArray = groupOdds
                }
                await this.setPreload(false);
            },

            async editMarketV2Live(item) {
                await this.setPreload(true);
                this.outcomesSelected = [];
                await this.getMarketViewSetting(item);
                await this.setPreload(false);
            },

            async getMarketViewSetting(item) {
                const sport = await this.getSportFrom_IdSport({_id: this.dataGroups.source});
                const sportId = sport.id;

                const payload = {
                    platform: this.dataGroups.platform,
                    sportId: sportId,
                    groupId: this.selectedMarketGroup,
                    marketId: item.id,
                    typeMatch: this.getProducers[this.searchProducer].name == 'Prematch & Antepost' ? 'prematch' : 'live',
                }

                const marketSettingResponse = await this.getMainMarketSettingInfoWithDefaultValues(payload);

                if (marketSettingResponse.data.data.length > 0) {
                    this.mainPreview = marketSettingResponse.data.data[0].isMainVisible;
                    this.mainPreviewMobile = marketSettingResponse.data.data[0].isMainVisibleMobile;
                    this.groupSpreadsInMain = marketSettingResponse.data.data[0].groupSpreadsInMain;
                    this.selectedTypeOfView = marketSettingResponse.data.data[0].typeView;
                    this.selectedSizeMdOddButton = marketSettingResponse.data.data[0].sizeOddButtonMd;
                    this.selectedSizeLgOddButton = marketSettingResponse.data.data[0].sizeOddButtonLg;
                    this.selectedSizeXlOddButton = marketSettingResponse.data.data[0].sizeOddButtonXl;
                    this.orderMarketInMainPreview = marketSettingResponse.data.data[0].orderInMainSection;
                    this.orderMarketMobileInMainPreview = marketSettingResponse.data.data[0].orderMobileInMainSection;

                    this.marketActive = marketSettingResponse.data.data[0].active;

                } else {
                    this.mainPreview = marketSettingResponse.data.data.isMainVisible;
                    this.mainPreviewMobile = marketSettingResponse.data.data.isMainVisibleMobile;
                    this.groupSpreadsInMain = marketSettingResponse.data.data.groupSpreadsInMain;
                    this.selectedTypeOfView = marketSettingResponse.data.data.typeView;
                    this.selectedSizeMdOddButton = marketSettingResponse.data.data.sizeOddButtonMd;
                    this.selectedSizeLgOddButton = marketSettingResponse.data.data.sizeOddButtonLg;
                    this.selectedSizeXlOddButton = marketSettingResponse.data.data.sizeOddButtonXl;
                    this.orderMarketInMainPreview = marketSettingResponse.data.data.orderInMainSection;
                    this.orderMarketMobileInMainPreview = marketSettingResponse.data.data.orderMobileInMainSection;
                    this.marketActive = marketSettingResponse.data.data.active;

                }


                // if(marketSettingResponse.data.data != null && marketSettingResponse.data.data.length > 0){
                //     for(const setting of marketSettingResponse.data.data){
                //
                //         const info = {
                //             specifier: setting.specifier,
                //             outcomesEnabled: setting.oddsList
                //         }
                //         // console.log('setting')
                //         // console.log(setting)
                //         this.outcomesSelected.push(info)
                //     }
                // }


                //this.outcomesSelected = marketSettingResponse.data.data[0].oddsList;

                this.marketToEdit = {...item}
            },


            async getMarketOutcomeSettings(item) {

                const sport = await this.getSportFrom_IdSport({_id: this.dataGroups.source});
                const sportId = sport.id;

                const payload = {
                    platform: this.dataGroups.platform,
                    sportId: sportId,
                    groupId: this.selectedMarketGroup,
                    marketId: item.id,
                    typeMatch: this.getProducers[this.searchProducer].name == 'Prematch & Antepost' ? 'prematch' : 'live',
                }

                const marketSettingResponse = await this.getMarketOutcomeViewSetting(payload);
                // console.log('marketSettingResponse')
                // console.log(marketSettingResponse)

                if (marketSettingResponse.data.data != null && marketSettingResponse.data.data.length > 0) {
                    for (const setting of marketSettingResponse.data.data) {

                        const info = {
                            specifier: setting.specifier,
                            outcomesEnabled: setting.oddsList,
                            sort: setting.sort != null ? setting.sort : 1
                        }
                        // console.log('setting')
                        // console.log(setting)
                        this.outcomesSelected.push(info)
                    }
                }
                // this.marketToEdit = {...item}

            },

            async updateSettingViewForMarket() {

                const vm = this;
                const sport = await this.getSportFrom_IdSport({_id: this.dataGroups.source});
                const sportId = sport.id;

                const dataObj = {
                    platform: this.dataGroups.platform,
                    sportId: sportId,
                    groupId: this.selectedMarketGroup,
                    marketId: this.marketToEdit.id,
                    typeMatch: this.getProducers[this.searchProducer].name == 'Prematch & Antepost' ? 'prematch' : 'live',
                    mainPreview: this.mainPreview,
                    mainPreviewMobile: this.mainPreviewMobile,
                    sizeOddButtonMd: this.selectedSizeMdOddButton,
                    sizeOddButtonLg: this.selectedSizeLgOddButton,
                    sizeOddButtonXl: this.selectedSizeXlOddButton,
                    orderInMainSection: this.orderMarketInMainPreview,
                    orderMobileInMainSection: this.orderMarketMobileInMainPreview,
                    active: this.marketActive
                }


                try {
                    const response = await this.updateMainMarketSetting(dataObj);

                    const findMarket = vm.marketsList.filter(market => {
                        return market.id == vm.marketToEdit.id
                    })

                    if (findMarket) {
                        findMarket.isMainVisible = vm.mainPreview;
                        findMarket.isMainVisibleMobile = vm.mainPreviewMobile;
                    }

                    const saveMarketList = vm.marketsList;
                    vm.marketsList = null;
                    vm.marketsList = saveMarketList;
                    vm.dataTableKeyIndex = vm.dataTableKeyIndex + 1;

                    vm.$swal.fire(this.$t('successOperation'), '', 'success')
                } catch (e) {
                    console.log(e);

                    vm.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: this.$t('operationNotPerformedCorrectly')
                    })
                }

                // this.updateMainMarketSetting(dataObj).then(async response => {
                //
                //     // vm.marketsList.forEach(market => {
                //     //
                //     //     if (market.id == vm.marketToEdit.id) {
                //     //         market.isMainVisible = vm.mainPreview;
                //     //         market.isMainVisibleMobile = vm.mainPreviewMobile;
                //     //     }
                //     // })
                //
                //     const findMarket = vm.marketsList.filter(market => { //todo do this in one part, there are 2 parts in this module that do the same thing
                //         return market.id == vm.marketToEdit.id
                //     })
                //
                //     if (findMarket) {
                //         findMarket.isMainVisible = vm.mainPreview;
                //         findMarket.isMainVisibleMobile = vm.mainPreviewMobile;
                //     }
                //
                //     const saveMarketList = vm.marketsList;
                //     vm.marketsList = null;
                //     vm.marketsList = saveMarketList;
                //     vm.dataTableKeyIndex = vm.dataTableKeyIndex + 1;
                //
                //     vm.$swal.fire(this.$t('successOperation'), '', 'success')
                //
                // }).catch(async error => {
                //     console.log(error);
                //
                //     vm.$swal.fire({
                //         icon: 'error',
                //         title: 'Oops...',
                //         text: this.$t('operationNotPerformedCorrectly')
                //     })
                // })
            },
            getOrderInMainSection(marketId) {
                let order = 999;

                const findMarket = this.marketsOrders.find(el => {
                    return el.marketId === marketId
                })

                if (findMarket) {
                    order = findMarket.orderInMainSection
                }

                return order;
            },
            getOrderInMobileSection(marketId) {
                let order = 999;

                const findMarket = this.marketsOrders.find(el => {
                    return el.marketId === marketId
                })

                if (findMarket) {
                    order = findMarket.orderMobileInMainSection
                }

                return order;
            },
            saveMarketViewSettingLive() {
                this.updateSettingViewForMarket();
            },
            addMarketsToActivate(item) {
                const findMarket = this.marketsToActivate.find(el => {
                    return el.id === item.id
                })

                if (findMarket) {
                    this.marketsToActivate = this.marketsToActivate.filter(el => {
                        return el.id != item.id
                    })
                } else {
                    this.marketsToActivate.push({id: item.id, name: item.name})
                }
            },
            async addMarketQuickly(){

                const vm = this;
                const sport = await this.getSportFrom_IdSport({_id: this.dataGroups.source});
                const sportId = sport.id;

                const marketsIds  = [];

                for(const market of this.marketsToActivate){
                    marketsIds.push(market.id)
                }

                const dataObj = {
                    platformId: this.dataGroups.platform,
                    sportId: sportId,
                    groupId: this.selectedMarketGroup,
                    typeMatch: this.getProducers[this.searchProducer].name == 'Prematch & Antepost' ? 'prematch' : 'live',
                    marketIds: marketsIds
                }

                try {
                    const response = await vm.enableMarketsViewSettings(dataObj);

                    if(response && response.data && response.data.success === true){
                        vm.$swal.fire(this.$t('successOperation'), '', 'success')
                    }else{
                        vm.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: vm.$t('operationNotPerformedCorrectly')
                        })
                    }

                } catch (e) {
                    console.log(e);

                    vm.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: vm.$t('operationNotPerformedCorrectly')
                    })
                }
            }
        },

    }
</script>

<style scoped>

</style>