<template>
    <div>

        <v-icon v-if="type_btn == 'icon'" :title="$t('userInfo')" @click="getRiskUser(platform_id, user_id)" class="float-left">mdi-account</v-icon>
        <v-btn v-if="type_btn == 'btn'" block depressed color="#00abe0" size="x-small" @click="getRiskUser(platform_id, user_id)" class="white--text">{{ $t('userInfo') }}</v-btn>

        <v-dialog transition="dialog-bottom-transition" max-width="60%" v-model="modalUserInfo" @click:outside="removeModalUserInfo">
            <v-card>
                <v-toolbar color="primary" dark class="text-uppercase" dense>
                    {{ $t('userInfo') }}
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="removeModalUserInfo">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col md="4" class="pt-8">
                            <apexchart v-if="loaded" :options="chart_percentage_context" :series="[{ data: [ {x: 'Prematch', y: dataRisk.prematch.percentage.toFixed(0), fillColor: '#008FFB'}, {x: 'Live', y: dataRisk.live.percentage.toFixed(0), fillColor: '#4ECD00'}, {x: 'Mixed', y: dataRisk.mixed.percentage.toFixed(0), fillColor: '#FF6600'} ] }]"></apexchart>
                        </v-col>

                        <v-col md="4" class="pt-8">
                            <apexchart v-if="loaded" :options="chart_percentage_context_type" :series="[ { name: $t('bets.single'), data: [dataRisk.prematch.single.percentage, dataRisk.live.single.percentage, dataRisk.mixed.single.percentage] },{ name: $t('bets.multiple'), data: [dataRisk.prematch.multiple.percentage, dataRisk.live.multiple.percentage, dataRisk.mixed.multiple.percentage] },{ name: $t('bets.integral'), data: [dataRisk.prematch.integral.percentage, dataRisk.live.integral.percentage, dataRisk.mixed.integral.percentage] },{ name: $t('bets.system'), data: [dataRisk.prematch.system.percentage, dataRisk.live.system.percentage, dataRisk.mixed.system.percentage] } ]"></apexchart>
                        </v-col>

                        <v-col md="4" class="pt-8">
                            <apexchart v-if="loaded" :options="chart_amounts" :series="[ played, win, lose ]"></apexchart>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col md="12">
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left">{{ $t('sport') }}</th>
                                        <th class="text-left">{{ $t('bets.tournament') }}</th>
                                        <th class="text-left">{{ $t('playedSport') }}</th>
                                        <th class="text-left">{{ $t('playedSportProgress') }}</th>
                                        <th class="text-left">{{ $t('bets.profit') }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="item in userTotals">
                                        <td>{{ item.sport_name }}</td>
                                        <td>{{ item.category_name }} / {{ item.tournament_name }}</td>
                                        <td>{{ Math.abs(item.sport_id_amount).toFixed(2) }}</td>
                                        <td>{{ Math.abs(item.sport_id_amount_progress).toFixed(2) }}</td>
                                        <td v-if="item.win !== undefined">{{ item.win.toFixed(2) }}</td>
                                        <td v-else>0.00</td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
    import _ from 'lodash';
    import axios from "axios";

    export default {
        name: "userInfo",
        props: {
            platform_id: {
                type: Number
            },
            user_id: {
                type: Number
            },
            bet: {
                type: Object
            },
            type_btn: {
                type: String
            }
        },
        data() {
            return {
                modalUserInfo: false,
                dataRisk: {},
                userTotals: {},
                loaded:false,
                played: 0,
                win: 0,
                lose: 0,
                chart_percentage_context: {
                    title: {
                        text: this.$t('percentagePlayedContext'),
                        align: 'left'
                    },
                    chart: {
                        type: 'bar',
                        height: 100
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 0,
                            horizontal: true,
                        }
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return val + "%"
                            }
                        }
                    },
                    dataLabels: {
                        enabled: false
                    }
                },
                chart_percentage_context_type: {
                    title: {
                        text: this.$t('percentagePlayedContextType'),
                        align: 'left'
                    },
                    chart: {
                        type: 'bar',
                        height: 100,
                        stacked: true
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            dataLabels: {
                                total: {
                                    enabled: false,
                                    offsetX: -30,
                                    style: {
                                        fontSize: '12px',
                                        fontWeight: 900
                                    }
                                }
                            }
                        },
                    },
                    stroke: {
                        width: 1,
                        colors: ['#fff']
                    },
                    xaxis: {
                        categories: ['Prematch', 'Live', 'Mixed'],
                        labels: {
                            formatter: function (val) {
                                return val + "%"
                            }
                        }
                    },
                    yaxis: {
                        title: {
                            text: undefined
                        },
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return val + "%"
                            }
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    }
                },
                chart_amounts: {
                    title: {
                        text: this.$t('userPlayAnalysis'),
                        align: 'left'
                    },
                    chart: {
                        type: 'pie',
                        width: 380,
                    },
                    labels: [this.$t('bets.progress'), this.$t('bets.win'), this.$t('bets.lose')],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },
            }
        },
        computed: {
            ...mapGetters({
                'getPreload': 'utils/getPreload',
                'getUrlApi': 'settings/getUrl',
                'getToken':'profile/getToken',
            }),
        },
        methods: {
            ...mapActions({
                'setPreload': 'utils/setPreload',
            }),
            async getRiskUser(platform_id, user_id) {
                const vm = this;

                await this.setPreload(true);

                if(platform_id > 0) {

                    // if (process.env.NODE_ENV == 'local') {
                    //     platform_id = 54;
                    // }

                    const url = process.env.VUE_APP_RISK_URL_API + 'risk/user/' + platform_id + '/' + user_id;

                    await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${this.getToken}`}})
                        .then(async (res) => {
                            const data = res.data.data;

                            this.dataRisk = data;

                            // console.log(this.dataRisk);

                            this.played = _.round(Math.abs(data.amount_single_potential_win + data.amount_multiple_potential_win + data.amount_integral_potential_win + data.amount_system_potential_win), 2);
                            this.win = _.round(Math.abs(data.amount_win), 2);
                            this.lose = _.round(Math.abs(data.amount_lose), 2);

                            // console.log(data);

                            await this.getRiskUserTotal(platform_id, user_id);

                            vm.modalUserInfo = true;
                            await vm.setPreload(false);
                            vm.loaded = true;
                        }).catch( async error => {
                            vm.$swal.fire(vm.$t('operationNotPerformedCorrectly'), '', 'error');
                            await vm.setPreload(false);
                        })
                }
            },
            async getRiskUserTotal(platform_id, user_id) {

                // if (process.env.NODE_ENV == 'local') {
                //     platform_id = 54;
                // }

                const date_seven_day_ago = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
                const seven_day_ago = parseInt((new Date(date_seven_day_ago).getTime() / 1000).toFixed(0));
                const now = parseInt((new Date().getTime() / 1000).toFixed(0));

                const url = process.env.VUE_APP_RISK_URL_API + 'risk/total_day/' + platform_id + '/' + user_id;

                let payload = JSON.stringify({
                    "timestamp_start": seven_day_ago,
                    "timestamp_end": now
                });

                var config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: url,
                    headers: {
                        'Authorization': `Bearer ${this.getToken}`,
                        'Content-Type': 'application/json'
                    },
                    data : payload
                };

                await axios(config)
                    .then(async (res) => {
                        const data = res.data.data;

                        // console.log(data);
                        this.userTotals = data;
                    })
            },
            async removeModalUserInfo() {
                this.modalUserInfo = false;
            },
        },
    }
</script>
