import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import AuthRoute from './auth'
import AdministratorRoute from './administrators'
import SkinRoute from './skin'
import CategoryRoute from './categories'
import TournamentRoute from './tournament'
import EventRoute from './event'
import SportRoute from './sports'
import OddsRoute from './odds'
import RuleRoute from "./rule";
import BetConsoleRoute from "./betConsole";
import LiveRoute from "./live";
import BetsRoute from "./bets";
import ThemesRoute from "./themes";
import StampRoute from "./stamps";
import ReportsRoute from "./reports";
import store from "../store";
import RiskRoute from "./risk";
import UserRoute from "./user";

import {checkauth} from '../Helper'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'intro',
        beforeEnter (to,from,next){
            checkauth().then( response =>{
                    if(!response){
                        //console.log("login")
                        next('/login');
                    }else{
                        //console.log("home")
                        next('/home');
                    };
                }

            )
        }
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        beforeEnter (to,from,next){
            checkauth().then( response =>{
                    if(!response){
                        //console.log("login")
                        next('/login');
                    }else{
                        //console.log("home")
                        next();
                    };
                }

            )

        }
    },
    ...AuthRoute,
    ...AdministratorRoute,
    ...SkinRoute,
    ...CategoryRoute,
    ...TournamentRoute,
    ...SportRoute,
    ...EventRoute,
    ...OddsRoute,
    ...RuleRoute,
    ...BetConsoleRoute,
    ...LiveRoute,
    ...BetsRoute,
    ...ThemesRoute,
    ...StampRoute,
    ...ReportsRoute,
    ...RiskRoute,
    ...UserRoute
]

const router = new VueRouter({
    routes
})

/*
router.beforeEach((to, from, next) => {
    if (!isAuthenticated) next('/login')
    else next()
})
*/
export default router
