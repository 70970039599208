<template>
  <v-col cols="12">
    <span class="title-table">Menu</span>
    <v-card outlined elevation-0 class="pa-1">
      <v-btn depressed :color="(this.$route.path != '/betconsole/cart') ? 'primary' : 'secondary'" to="/betconsole/cart" class="mr-2">
        {{ $t('bets.cart') }}
      </v-btn>
      <v-btn depressed :color="(this.$route.path != '/betconsole/bonus') ? 'primary' : 'secondary'" to="/betconsole/bonus" class="mr-2">
        {{ $t('bets.bonus') }}
      </v-btn>
    </v-card>
  </v-col>


</template>

<script>
export default {
  name: "menu"
}
</script>

<style scoped>

</style>
