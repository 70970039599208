<template>
    <div id="users-list">
        <div class="alert-container">

            <transition name="fade" v-if="alertSuccessEnabled">
                <v-alert type="success">
                    {{$t('successOperation')}}
                </v-alert>
            </transition>

            <transition name="fade" v-if="alertErrorEnabled">
                <v-alert type="error">
                    {{$t('operationNotPerformedCorrectly')}}
                </v-alert>
            </transition>
        </div>

        <v-container fluid fill-height>
            <v-layout justify-center align-center>
                <v-card width="100%" outlined elevation-0 v-model="getSkinFilters">
                    <bookieUserList v-if="!skin.signUp"></bookieUserList>
                    <signUpUserList v-if="skin.signUp" :skin_id="skin.settings.skin_id"></signUpUserList>
                </v-card>

                <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color"
                            :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout"
                            :vertical="snackbar.mode === 'vertical'">
                    {{ snackbar.text }}
                    <v-btn dark text @click="snackbar.snackbar = false">
                        {{$t('generic.close')}}
                    </v-btn>
                </v-snackbar>
            </v-layout>
        </v-container>
    </div>

</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import LimitsComponent from "../../components/risks/limits";
    import userNote from "../../components/risks/userNote";
    import EntityUserLimitsModal from "../../components/risks/entityUserLimitListModal"
    import bookieUserList from "../../components/userList/bookieUserList";
    import signUpUserList from "../../components/userList/signUpUserList";
    import _ from "lodash";

    export default {
        name: "UsersList",
        components: {LimitsComponent, userNote, EntityUserLimitsModal, bookieUserList, signUpUserList},
        data() {
            return {
                icons: [
                    {'text': 'Black and white', value: "B/W"},
                    {'text': 'Color', value: "Color"}
                ],
                search: '',
                searchById: '',
                searchByLogin: '',
                pagination: {
                    sortBy: 'idUser',
                    descending: true,
                    rowsPerPage: 10
                },
                snackbar: {
                    snackbar: false,
                    color: '',
                    mode: '',
                    timeout: 6000,
                    text: '',
                },
                alertSuccessEnabled: false,
                alertErrorEnabled: false,
                dialogNote: false,
                userTargetNote: null,
                userIdTargetNote: '',
                platformIdTargetNote: '',
                userTargetReadNote: null,
                userIdTargetReadNotes: null,
                dialog: false,
                idUser: null,
                login: null,
                userID: null,
                userTargetForEntityLimit: null,
                skin:{},
                signUp : false
            }
        },
        async mounted() {
            await this.getPlatformsignUp(this.getPlatformId)
            this.$root.$on('closeDialogUserLimit', () => {
                this.dialog = false;
            })

            this.$root.$on('closedWriteNoteDialog', () => {
                this.dialogNote = false;
                this.userTargetNote = null;
                this.userIdTargetNote = null;
                this.platformIdTargetNote = null;
            })

            this.$root.$on('closedUserNotes', () => {
                this.userTargetReadNote = null;
                this.userIdTargetReadNotes = null;
                this.platformIdTargetNote = null;
            })

            this.$root.$on('closedUpdateEntityUserLimitDialog', () => {
                this.userTargetForEntityLimit = null;
            })
        },
        async beforeDestroy() {
            this.$root.$off('closeDialogUserLimit');
            this.$root.$off('closedWriteNoteDialog');
            this.$root.$off('closedUserNotes');
            this.$root.$off('closedUpdateEntityUserLimitDialog');
        },
        watch: {
            getSkinFilters(newValue, oldValue){
                this.getPlatformsignUp(this.getPlatformId)
            }
        },
        computed: {
            ...mapGetters({
                'getPlatformId': 'rules/getPlatformId',
                'getSkinFilters': 'skins/getSkinFilters'
            }),
        },
        methods: {
            ...mapActions({
                'setPreload': 'utils/setPreload',
                'blockUserAction': 'skins/blockUser',
                'unlockUserAction': 'skins/unlockUser'
            }),
            detailUsers(id, login) {
                this.userID = id;
                this.idUser = id;
                this.login = login;
                this.dialog = true;
            },
            closeDetail() {
                this.dialog = false;
                this.userID = null;
                this.idUser = null;
                this.login = null;
            },
            openMessageDialog(user) {
                this.dialogNote = true;
                this.userTargetNote = user;
                this.userIdTargetNote = user.idUser;
                this.platformIdTargetNote = this.getPlatformId;
            },
            showSuccessAlert() {
                this.alertSuccessEnabled = true;
                setTimeout(() => {
                    this.alertSuccessEnabled = false
                }, 2000)
            },
            showErrorAlert() {
                this.alertErrorEnabled = true;
                setTimeout(() => {
                    this.alertErrorEnabled = false
                }, 5000)
            },
            getPlatformsignUp(platform_id) {
                this.skin = _.find(this.getSkinFilters, {platform_id: platform_id})
            },
            getTranslatedUserType(type) {

                let translatedType = this.$t('user.user');
                switch (type) {

                    case "2":
                        break
                    case "3":
                        translatedType = this.$t('user.operator');
                        break
                    case "4":
                        translatedType = this.$t('user.agency');
                        break

                }
                return translatedType;
            },
            isUserBlockedStatus(blockedStatus) {
                return blockedStatus
            },
            getTranslatedStatus(blockedStatus) {

                if (blockedStatus == true) {
                    return this.$t('user.blocked')
                } else {
                    return this.$t('user.unlocked')
                }
            },

            detailEntityLimit(user) {
                this.userTargetForEntityLimit = null;
                this.userTargetForEntityLimit = user;
            }
        },
    }
</script>

<style scoped>

</style>
