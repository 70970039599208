<template>
  <div>
    <v-dialog transition="dialog-bottom-transition" max-width="80%" v-model="getShowDetail" @click:outside="removeDetail">
      <v-card>
        <v-toolbar color="primary" dark class="text-uppercase" dense>
          {{ $t('bets.list') }}
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="removeDetail">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-layout row>
              <v-flex md6>
                <v-col cols="12">
                  <v-card outlined elevation-0>
                    <v-simple-table dense>
                      <thead class="secondary">
                      <tr>
                        <th class="text-left text-uppercase">{{ $t('bets.detail') }}</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
  import _ from 'lodash';
  import BetDetail from "@/components/bets/betDetail";

  export default {
    name: "betsList",
    components: {BetDetail},
    data() {
      return {
        snackbar: false,
        snackbar_message: '',
        totalAmount: 0.00,
        totalWin: 0.00,
        totalPotWin: 0.00,
        totalNet: 0.00,
        dialog: false,
        betToShow: {},
        headers: [
          {text: '', value: 'device', width: '6%', sortable: false},
          {text: this.$t('bets.code'), value: 'code', width: '12%', sortable: false},
          {text: this.$t('bets.type'), value: 'type', width: "5%"},
          {text: this.$t('bets.events'), value: 'odds_sources', width: "5%"},
          {text: this.$t('bets.amount'), value: 'amount', width: "7%", sortable: false},
          {text: this.$t('bets.potential')+' '+this.$t('bets.winning'), value: 'win', width: "7%", sortable: false},
          {text: this.$t('bets.real_winning'), value: 'winning_real', width: "7%", sortable: false},
          {text: this.$t('bets.date'), value: 'created_at', width: "8%"},
          {text: this.$t('bets.platform'), value: 'platform_id', width: "4%"},
          {text: this.$t('generic.context'), value: 'bet_context', width: "5%"},
          {text: this.$t('bets.user'), value: 'user_id', width: "5%"},
          // {text: this.$t('bets.signDefined'), value: 'defined', width: "3%"},
          {text: this.$t('bets.result'), value: 'result', width: "7%"},
          {text: this.$t('bets.status'), value: 'status', width: "5%"},
          {text: this.$t('bets.payed'), value: 'payed', width: "5%"},
          {text: this.$t('bets.actions'), value: 'actions', sortable: false, width: "10%"},
        ],
        options: {},
        betToReview: '',
        dialogReview: false
      }
    },
    computed: {
      ...mapGetters({
        'getBets': 'bet/getBets',
        'getPreload': 'utils/getPreload',
        'getShowDetail': 'bet/getShowDetail',
        'getOptions': 'bet/getOptions',
        'getSkinFilters': 'skins/getSkinFilters',
        'getCounts': "bet/getCounts",
        'getCriteria': "bet/getCriteria",
        'getTimezone':'bet/getTimezone',
        'getPlatformId': 'rules/getPlatformId',
      }),
    },

    methods: {
      ...mapActions({
        'setBetDetail': 'bet/setBetDetail',
        'setShowDetail': 'bet/setShowDetail',
        'defineBet': 'bet/defineBet',
        'defineVoid': 'bet/defineVoid',
        'requestRollback': 'bet/requestRollback',
        'fetchBets': 'bet/fetchBets',
        'setPreload': 'utils/setPreload',
        'removeBetDetail': 'bet/removeBetDetail',
        'sendResultToPlatform': 'bet/sendResultToPlatform',
        /*'sendReviewBet': 'bet/sendReviewBet',*/
        'fetchReviewCounts': 'bet/fetchReviewCounts',
        'sendAcceptBet':'bet/sendAcceptBet',
        'sendRejectBet':'bet/sendRejectBet',
      }),
      async showDetail(bet) {
        await this.setShowDetail(false)
        await this.setBetDetail(bet)
        await this.setShowDetail(true)
      },
      async defineAuto(bet) {
        await this.setPreload(true);
        var result = await this.defineBet({'code':bet.code});
        if(result.data.data || result.data.updated){
          this.snackbar = true
          this.snackbar_message = 'bets.betDefinedSuccessfully'
        }else{
          this.snackbar = true
          this.snackbar_message = 'bets.betNotDefinedAutomatically'
        }
        // await this.fetchBets({page: this.getOptions.page, limit: this.getOptions.limit, platform_id: this.getPlatformId})
        await this.setPreload(false);
      },
      async sendRollback(bet) {
        await this.setPreload(true);
        await this.requestRollback({'code':bet.code});
        this.getCriteria.page = this.getOptions.page
        this.getCriteria.limit = this.getOptions.limit
        this.getCriteria.platform_id = (this.getPlatformId > 0) ? this.getPlatformId : this.getOptions.platform_id
        await this.fetchBets(this.getCriteria)
        await this.setPreload(false);
      },
      async makeVoid(bet) {
        await this.setPreload(true);
        await this.defineVoid({'code':bet.code});
        this.getCriteria.page = this.getOptions.page
        this.getCriteria.limit = this.getOptions.limit
        this.getCriteria.platform_id = (this.getPlatformId > 0) ? this.getPlatformId : this.getOptions.platform_id
        await this.fetchBets(this.getCriteria)
        await this.setPreload(false);
      },
      async sendToPlatform(bet) {
        await this.setPreload(true);
        const result = await this.sendResultToPlatform({'bet_code':bet.code});
        if(result.result){
          this.snackbar = true
          this.snackbar_message = 'bets.betSentSuccessfully'
        }
        await this.setPreload(false);
      },
      async betDecision(mode) {
        await this.setPreload(true);
        // const result = await this.sendReviewBet({'code':this.betToReview, 'mode':mode});
        let result ;

        try {

          if(mode === 'accept'){
            result = await this.sendAcceptBet({'code':this.betToReview});
          }else if (mode === 'reject'){
            result = await this.sendRejectBet({'code':this.betToReview});
          }

          if(result.data.result){
            this.snackbar = true
            this.snackbar_message = 'bets.betSentSuccessfully'
            this.betToReview = ''
            this.dialogReview = false
          }else{
            this.snackbar = true
            this.snackbar_message = 'error'
            this.betToReview = ''
            this.dialogReview = false
          }
        }catch (e) {
          console.log(e)
        }

        await this.fetchReviewCounts()
        await this.fetchBets(this.getCriteria)
        await this.setPreload(false);
      },
      getPlatformName(platform_id) {
        var platform = _.find(this.getSkinFilters, {platform_id: platform_id})
        return platform.name
      },
      getWinning(bet) {
        switch (bet.type) {
          case('single'):
          case('multiple'):
            return bet.currencySymbol +' '+parseFloat(bet._multiple_detail.winning_total).toFixed(2)
          case('integral'):
            return bet.currencySymbol +' '+parseFloat(bet._integral_detail.winning_total_max).toFixed(2)
          case('system'):
            return bet.currencySymbol +' '+parseFloat(bet._system_detail.winning_all_total).toFixed(2)
        }
      },
      getDefined(bet){
        var defined = _.countBy(bet.odds, {'defined':true})
        if(defined.true != undefined){
          return defined.true+'/'+bet.odds.length
        }else {
          return '0/'+bet.odds.length
        }

      },
      async getNewPage(){
        this.getCriteria.page = this.getOptions.page
        this.getCriteria.limit = this.getOptions.limit

        await this.setPreload(true);
        await this.fetchBets(this.getCriteria)
        await this.setPreload(false);
      },
      getTotalAmount(){
        var total = _.sumBy(this.getBets, 'amount_real')
        return (total != undefined)? total : 0.00
      },
      getTotalRealWinning(){
        var total = _.sumBy(this.getBets, 'winning_real')
        return (total != undefined)? total : 0.00
      },
      getPotentialWinning(){
        var result = 0.00;
        var pending_bets = _.filter(this.getBets, {'result':'pending'})
        _.forEach(pending_bets, (bet)=>{
          switch (bet.type) {
            case('single'):
            case('multiple'):
              result += bet._multiple_detail.winning_total
              break
            case('integral'):
              result += bet._integral_detail.winning_total_max
              break
            case('system'):
              result += bet._system_detail.winning_all_total
              break
          }
        })

        return result
      },
      getTotalNet(){
        var total_amount = _.sumBy(this.getBets, 'amount_real')
        if(!total_amount)
          total_amount = 0.00

        var total_winning = _.sumBy(this.getBets, 'winning_real')
        if(!total_winning)
          total_winning = 0.00

        return total_amount - total_winning
      },
      calculatePagination(count, limit){

        var divide = (count / limit)
        var rest = divide % 1

        if(rest > 0){
          divide = divide - rest
          divide = +divide.toFixed(0) + 1
        }

        return +divide
      }
    }
  }
</script>

<style scoped>

  .context{
    font-weight: bold;
    padding: 0 5px 0 2px;
    cursor: default;
    font-size: 10px;
    text-transform: uppercase;
    margin-right: 5px;
  }
  .live-label{
    font-style: italic;
    background: #c90000;
    color: #fff;
  }
  .prematch-label{
    background: #748300;
    color: #fff;
  }

  .mixed-label{
    background: #0048cd;
    color: #fff;
  }

  .button-review{
    flex: 0 0 48%;
    border: 1px solid #7e7e7e;
    border-radius: 3px;
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
  }

  .label-review{
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
  }
</style>
