<template id="card-collapse">
  <div >
    <v-container fluid class="pb-0">
      <v-row>
        <v-col md="11" class="pt-1">
          <h2>{{ getPlatformName(getPlatformId) }}</h2>
        </v-col>
        <v-col md="1" class="pt-1" >
          <v-btn class="primary btn-collapse" v-on:click="toggle">
            <v-icon size="small" v-show="showSection">mdi-minus</v-icon>
            <v-icon size="small" v-show="!showSection">mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-show="showSection">
        <v-col md="6">
          <v-row>
            <v-col md="6">
              <v-select class="text-capitalize" :items="typeOfBets" item-text="text" item-value="value"  :label="$t('generic.type')" dense outlined v-model="filterBet"></v-select>
            </v-col>

            <v-col md="6">
              <v-select class="text-capitalize" :items="typeOfAlerts" item-text="text" item-value="value"  :label="$t('generic.alerts')" dense outlined v-model="filterAlert"></v-select>
            </v-col>

            <v-col md="3">
              <v-checkbox class="no-padding no-margin" v-model="continueBeep" @change="setContinueBeep"
                          :label="$t('setContinueBeep')"></v-checkbox>
            </v-col>
            <v-col md="3">
              <v-btn depressed :color="btn_reserve_color" @click="checkReserve" class="mr-5 white--text">{{ btn_reserve }}</v-btn>
            </v-col>

          </v-row>

        </v-col>
        <v-col md="3">
          <apexchart v-if="loaded" type="bar" height="200" :options="chartOptions_totals" :series="[{name:$t('risk.AmountPlayed'),data:[played]},{name:$t('risk.win'),data:[win]},{name:$t('risk.lose'),data:[lose]}]"></apexchart>
        </v-col>
        <v-col md="3">
          <apexchart v-if="loaded" type="bar" height="200" :options="chartOptions_totals_progress" :series="[{name:'Prematch',data:[prematch_progress]},{name:$t('risk.mixed'),data:[mixed_progress]},{name:'Live',data:[live_progress]}]"></apexchart>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import _ from "lodash";
import axios from "axios";
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "platformsFilter",
  components: { Bar },
  data() {
    return {
      filterBet: 'all',
      filterAlert: "no",
      loaded:false,
      played: 0,
      win: 0,
      lose: 0,
      net: 0,
      win_progress: 0,
      prematch_progress: 0,
      mixed_progress: 0,
      live_progress: 0,
      amount_single_in_progress: 0,
      amount_multiple_in_progress: 0,
      amount_integral_in_progress: 0,
      amount_system_in_progress: 0,
      interval: null,
      interval2: null,
      btn_reserve: this.$t('generic.start_reserve'),
      btn_reserve_color: 'secondary',
      control_reserve: false,
      showSection: true,
      typeOfBets: [
        {text: this.$t('generic.all'), value: "all"},
        {text: this.$t('bets.prematch'), value: "prematch"},
        {text: this.$t('bets.live'), value: "live"},
      ],
      typeOfAlerts: [
        {text: this.$t('generic.no'), value: "no"},
        {text: this.$t('generic.beep'), value: "beep"}
      ],
      chartOptions_totals: {
        title: {
          text: this.$t('risk.PlatformRisk'),
          align: 'left'
        },
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [''],
        },
        fill: {
          opacity: 1
        },
      },
      chartOptions_totals_progress: {
        title: {
          text: this.$t('risk.PlatformRiskProgress'),
          align: 'left'
        },
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [''],
        },
        fill: {
          opacity: 1
        },
      },
      continueBeep: true
    }
  },
  computed: {
    ...mapGetters({
      'getBets': 'bet/getBets',
      'getOptions': 'bet/getOptions',
      'getSkinFilters': 'skins/getSkinFilters',
      'getPlatformId': 'rules/getPlatformId',
      'getList':'skins/getList',
      'getTimezone':'bet/getTimezone',
      'getToken':'profile/getToken',
    }),
  },

  async mounted() {
    await this.getAnalisysPlatform();
    await this.pingReserve();

    this.interval = setInterval(await this.getAnalisysPlatform, 60000);
    this.interval2 = setInterval(await this.pingReserve, 60000);

    this.setContinueBeep(true); // start with default continue beep

    // setTimeout(async () => {
    //   await this.pingReserve();
    // }, 60000);

  },
  beforeDestroy() {
    if (this.interval != null) {
      clearInterval(this.interval);
    }

    if (this.interval2 != null) {
      clearInterval(this.interval2);
    }
  },
  methods: {
    ...mapActions({
      'setPreload': 'utils/setPreload',
      'fetchBets': 'bet/fetchBets',
      'sendBookieDefined': 'bet/sendBookie',
      'setCriteria': 'bet/setCriteria',
      'setTimezone':'bet/setTimezone',
    }),

    getPlatformName(platform_id) {
      if(platform_id == 0){
        return "Manager";
      }else{

        // console.log(platform_id)

        // if (process.env.NODE_ENV === 'local') {
        //   platform_id = 43;
        // }

        let platform = _.find(this.getSkinFilters, {platform_id: platform_id})
        return platform.name
      }
    },


    setContinueBeep(ev){
      // console.log('ev')
      // console.log(ev)
      this.$store.commit('risk/setContinueBeep', ev)
    },
    toggle() {
      this.showSection = !this.showSection
    },
    async checkReserve() {
      if(!this.control_reserve){
        await this.loginReserve();
      }else{
        await this.logoutReserve();
      }
    },

    async loginReserve(){
      let vm = this;

      // if(this.getPlatformId == 43){
      //   this.getPlatformId = 54;
      // }

      const user_id = localStorage.getItem('userLoggedId');

      localStorage.setItem('accept_reserve', 'true');

      const url = process.env.VUE_APP_VALIDATE_URL_API + 'users/' +  this.getPlatformId + '/' + user_id + '/' + this.filterBet;

      console.log(url);

      await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${this.getToken}`}})
              .then(async (res) => {

                if(res.data.messages == 'Ok'){
                  vm.btn_reserve = this.$t('generic.stop_reserve');
                  this.control_reserve = true;
                  this.btn_reserve_color = '#ff0000';
                }else{
                  vm.btn_reserve = this.$t('generic.start_reserve');
                  this.control_reserve = false;
                  this.btn_reserve_color = 'secondary';
                }

              })
              .catch((error) => {
                console.log(error);
              });
    },

    async logoutReserve(){
      let vm = this;

      const user_id = localStorage.getItem('userLoggedId');

      const url = process.env.VUE_APP_VALIDATE_URL_API + 'users/delete/' +  this.getPlatformId + '/' + user_id;

      await axios.delete(`${url}`, {'headers': {'Authorization': `Bearer ${this.getToken}`}})
              .then(async (res) => {

                if(res.data.messages == 'Ok'){
                  vm.btn_reserve = this.$t('generic.start_reserve');
                  this.control_reserve = false;
                  this.btn_reserve_color = 'secondary';
                  localStorage.removeItem('accept_reserve');
                }else{
                  vm.btn_reserve = this.$t('generic.stop_reserve');
                  this.control_reserve = true;
                  this.btn_reserve_color = '#ff0000';
                  localStorage.setItem('accept_reserve', 'true');
                }

              })
              .catch((error) => {
                console.log(error);
              });
    },

    async pingReserve(){
      let vm = this;

      const user_id = localStorage.getItem('userLoggedId');

      const url = process.env.VUE_APP_VALIDATE_URL_API + 'users/ping/' +  this.getPlatformId + '/' + user_id;

      await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${this.getToken}`}})
              .then(async (res) => {

                if(res.data.messages == 'Logged'){
                  vm.btn_reserve = this.$t('generic.stop_reserve');
                  this.control_reserve = true;
                  this.btn_reserve_color = '#ff0000';
                  localStorage.setItem('accept_reserve', 'true');
                }else{
                  vm.btn_reserve = this.$t('generic.start_reserve');
                  this.control_reserve = false;
                  this.btn_reserve_color = 'secondary';
                  localStorage.removeItem('accept_reserve');
                }

              })
              .catch((error) => {
                console.log(error);
              });
    },

    async getAnalisysPlatform() {

      this.loaded = false
      // await this.setPreload(true);

      if(this.getPlatformId > 0){

        // if(process.env.NODE_ENV == 'local'){
        //   platform_id = 54;
        // }

        const url = process.env.VUE_APP_RISK_URL_API + 'risk/platform/' + this.getPlatformId;

        await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${this.getToken}`}})
                .then(async (res) => {

                  const data = res.data.data;

                  this.played = _.round(Math.abs(data.amount_single_potential_win + data.amount_multiple_potential_win + data.amount_integral_potential_win, data.amount_system_potential_win), 2);
                  this.win = _.round(Math.abs(data.amount_win), 2);
                  this.lose = _.round(Math.abs(data.amount_lose), 2);
                  this.net = _.round(Math.abs(data.net), 2);
                  this.win_progress = _.round(Math.abs(data.live.potential) + Math.abs(data.mixed.potential) + Math.abs(data.prematch.potential), 2);

                  this.prematch_progress = _.round(Math.abs(data.prematch.potential), 2);
                  this.mixed_progress = _.round(Math.abs(data.mixed.potential), 2);
                  this.live_progress = _.round(Math.abs(data.live.potential), 2);

                  this.amount_single_in_progress = _.round(Math.abs(data.amount_single_potential_win), 2);
                  this.amount_multiple_in_progress = _.round(Math.abs(data.amount_multiple_potential_win), 2);
                  this.amount_system_in_progress = _.round(Math.abs(data.amount_system_potential_win), 2);
                  this.amount_integral_in_progress = _.round(Math.abs(data.amount_integral_potential_win), 2);

                  await this.setPreload(false);
                  this.loaded = true
                })
                .catch((error) => {
                  console.log(error);
                });
      }else{
        this.loaded = false
      }
    },

      checkTimezone(){
      var platform = _.find(this.getList, {'platform_id':this.getPlatformId})
      if(this.enableTimezone){
        if(platform.settings.timezone){
          this.skinTimezone = platform.settings.timezone
          this.setTimezone(platform.settings.timezone)
        }
      }else{
        this.skinTimezone = ''
        this.setTimezone('')
      }
    },
    async handleKeyDown(e) {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        await this.searchBets()
      }
    },
  },
  created() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }
}
</script>

<style scoped>

</style>
