<template>
  <div>
    <v-container fluid>
      <v-layout row>

        <v-flex md12>
          <v-col cols="12">
            <span class="title-table">{{ this.$t('bets.multiplicity') | capitalize}}</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-flex md9>
                    <v-col col="12" class="pt-0 pb-0">
                      <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Cerca"
                          single-line
                          hide-details
                      ></v-text-field>
                      <br/>
                      <v-data-table
                          :search="search"
                          :headers="headers"
                          :items="rulesMultiplicity"
                          :items-per-page="10"
                          class="elevation-1"
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-icon
                              small
                              class="mr-2"
                              @click="editItem(item)"
                              v-if="getPlatformId == 0 || getPlatformId == item.platform_id"
                          >
                            mdi-pencil
                          </v-icon>
                          <v-icon
                              small
                              @click="deleteItem(item)"
                              v-if="getPlatformId == 0 || getPlatformId == item.platform_id"
                          >
                            mdi-delete
                          </v-icon>
                        </template>

                      </v-data-table>
                    </v-col>
                  </v-flex>
                  <v-flex md3>
                    <v-col cols="12">
                      <v-autocomplete
                          v-model="producerSelected"
                          :items="getProducers"
                          item-text="name"
                          item-value="id"
                          outlined
                          dense
                          chips
                          small-chips
                          :label="$t('generic.context')"
                          deletable-chips
                      ></v-autocomplete>
                    </v-col>

                    <span class="text-help">La legatura di default è settata dal manager o personalizzata per la singola skin.
                    E' valida per tutti i livelli (sport, category, tournament, event) ed ogni Market, a meno di regole specifiche come quelle in basso elencate</span>
                    <v-col cols="12" class="pb-0">
                        <v-text-field
                          label="Legatura di Default"
                          placeholder="ad es. 1-30"
                          outlined
                          dense
                          append-outer-icon="mdi-content-save"
                          @click:append-outer="saveMultiplicityDefault"
                          v-model="defaultMultiplicity"
                      ></v-text-field>
                    </v-col>
                    <span class="title-table">Aggiungi una nuova regola</span>
                    <v-col cols="12" class="pb-0">
                      <v-autocomplete
                          v-model="sportSelected"
                          :items="getItemsSport"
                          item-text="name"
                          item-value="id"
                          outlined
                          dense
                          chips
                          small-chips
                          label="Sport"
                          @input="getCategoriesFromSport();getMarketsFromSport()"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-autocomplete
                          v-model="marketSelected"
                          :items="marketsList"
                          item-text="name"
                          item-value="id"
                          outlined
                          dense
                          chips
                          small-chips
                          label="Market"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-text-field
                          label="Legatura"
                          placeholder="ad es. 1-30"
                          outlined
                          dense
                          v-model="newMultiplicity"
                      ></v-text-field>
                    </v-col>
                    <span class="title-table">Nello specifico</span>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-autocomplete
                          v-model="categorySelected"
                          :items="getItemsCategories"
                          item-text="name"
                          item-value="id"
                          outlined
                          dense
                          chips
                          small-chips
                          label="Categoria"
                          @input="getTournamentsFromCategories()"
                          @change="getTournamentsFromCategories()"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-autocomplete
                          v-model="tournamentSelected"
                          :items="getItemsTournaments"
                          item-text="name"
                          item-value="id"
                          outlined
                          dense
                          chips
                          small-chips
                          label="Torneo"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-btn block depressed color="secondary" class="mr-2" @click="createNewRule">
                        {{$t('generic.add')}}
                      </v-btn>
                    </v-col>
                  </v-flex>

                </v-row>
              </v-container>

            </v-card>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>

    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :vertical="snackbar.mode === 'vertical'">
      {{ snackbar.text }}
      <v-btn dark text @click="snackbar.snackbar = false">
        Chiudi
      </v-btn>
    </v-snackbar>

    <v-dialog persistent v-model="dialog" width="1000px">
      <v-card>
        <v-card-title class="grey lighten-4 py-4 title">
          {{ titleDialog }}
        </v-card-title>
        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap>
            <p>
              Aggiorna il valore della molteplicità per il market <strong>{{this.itemToEdit.market_name}}</strong>
              per il seguente campo di applicazione <br/><strong>{{this.itemToEdit.detail.path_complete}}</strong>
            </p>

            <v-flex xs12>
              <v-text-field label="Legatura" v-model="formToSubmit.multiplicity" outlined></v-text-field>
            </v-flex>

          </v-layout>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="dialog = false">Indietro</v-btn>
          <v-btn color="secondary" @click.prevent="submitRule">Conferma</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirm" max-width="550">
      <v-card>

        <v-card-title class="headline">{{ titleDialog }}</v-card-title>

        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap style="line-height: 50px">
            <h4>Desideri eliminare la regola selezionata ?</h4>
          </v-layout>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="dialogConfirm = false">
            Annulla
          </v-btn>

          <v-btn color="secondary" @click="submitDelete">
            Conferma
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>



  </div>
</template>
<style>

</style>
<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import _ from 'lodash'
import menuSport from "@/components/sports/menu";

export default {
  name: "Multiplicities",

  data() {
    return {
      search:'',
      infoMultiplicities: [],
      rulesMultiplicity:[],
      producerSelected: 0,
      defaultMultiplicity: '',
      newMultiplicity: '',
      sportSelected: '',
      categorySelected: '',
      tournamentSelected: '',
      marketSelected: '',
      eventSelected: '',
      dialog: false,
      dialogConfirm: false,
      dialogPub: false,
      titleDialog: "",
      changeStatus: "",
      itemToEdit:{
        market_name:'',
        detail:{
          path_complete:'',
          multiplicity:''
        }
      },
      formToSubmit:{},
      typeRule : '',
      source: '',
      scope: '',
      snackbar:{
        snackbar: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
      },
      headers: [
        { text: 'Proprietario', value: 'user'},
        { text: 'Campo di Applicazione', value: 'detail.path_complete'},
        { text: 'Market', value: 'market_name'},
        { text: 'Molteplicità', value: 'detail.multiplicity'},
        { text: 'Azioni', value: 'actions', sortable: false },
      ],
      marketsList: []

    }
  },

  components:{menuSport},

  watch: {
    getLanguage: async function (val) {
      window.location.reload()
    },
    getMarketsMini() {
      this.marketsList = this.getMarketsMini != null ? this.getMarketsMini.markets : []
    },
  },

  computed: {
    ...mapGetters({
      'getToken': 'profile/getToken',
      'getUrl': 'settings/getUrl',
      'getUrlApi': 'settings/getUrlApi',
      'getPreload': 'utils/getPreload',
      'getLanguage': 'profile/getLanguage',
      'getLanguagesList': 'profile/getLanguagesList',
      'getSports': 'sport/getSports',
      'getItemsSport': 'sport/getItems',
      'getCategories': 'category/getCategories',
      'getItemsCategories': 'category/getItems',
      'getTournaments': 'tournament/getTournaments',
      'getItemsTournaments': 'tournament/getItems',
      'getPlatformId': 'rules/getPlatformId',
      'getManager': 'skins/getManager',
      'getListSkins': 'skins/getList',
      'getProducers': 'producers/getList',
      'getMarkets': 'utils/getMarkets',
      'getMarketsMini': 'utils/getMarketsMini'
    }),


  },
  async created() {
    await this.setPreload(true);
    await this.getInfoMultiplicities();
    await this.fetchSkins()
    await this.getDefaultMultiplicity(this.getPlatformId, "0");
    await this.fetchSports({'language': this.getLanguage});
    await this.getRulesMultiplicity(this.getPlatformId, "0")
    await this.setPreload(false);
  },


  methods: {

    ...mapActions({
      'setPreload': 'utils/setPreload',
      'fetchSports': 'sport/fetchSports',
      'filterSport': 'sport/filterSport',
      'fetchCategories': 'category/fetchCategories',
      'filterCategories': 'category/filterCategories',
      'fetchTournaments': 'tournament/fetchTournaments',
      'filterTournaments': 'tournament/filterTournament',
      'resetTournaments': 'tournament/resetTournaments',
      'updateRules': 'skins/updateRules',
      'deleteRules': 'skins/deleteRules',
      'publishElement': 'skins/publishElement',
      'fetchSkins': 'skins/fetchSkins',
      'getMarketsBySport': 'utils/getMarketsBySport',
      'cacheRules': 'cache/cacheRules',
      'getMarketsBySportList': 'utils/getMarketsBySportList',
    }),

    ...mapMutations({
      'setLanguage': 'profile/setLanguage',
    }),

    async getInfoMultiplicities(){

      await axios.post(`${this.getUrlApi}info-multiplicities`,
          {"lang": this.getLanguage},
          {'headers': {'Authorization': `Bearer ${this.getToken}`}, "Content-Type": "application/json"})

          .then(res => {
            this.infoMultiplicities = res.data
          })
          .catch(err => {
            console.log("errore", err);
          })
    },

    async getDefaultMultiplicity(skinId, producer){
      var vue = this

      var skin = await _.find(vue.getListSkins, {"platform_id":skinId});
      var rule_default = await _.filter(skin.rules, function(rule){
        if(rule.type == "multiplicity" && rule.scope == "default#"+producer){
          return rule
        }
      });

      if(rule_default.length == 1){
        vue.defaultMultiplicity = rule_default[0].detail.multiplicity
      }else{

        var rule_default = await _.filter(vue.getManager.rules, function(rule){
          if(rule.type == "multiplicity" && rule.scope == "default#"+producer){
            return rule
          }
        });

        vue.defaultMultiplicity = rule_default[0].detail.multiplicity
      }
    },

    async getRulesMultiplicity(skinId, producer){
      var vue = this
      vue.rulesMultiplicity = []

      var rules_manager = [];
      var rules_skin = [];
      var rules_total = [];

      if(skinId == 0){
        // Get Manager Rules
        for(let ruleManager of vue.getManager.rules){
          if(ruleManager.type === "multiplicity" && ruleManager.source !== "default" && ruleManager.scope.includes(producer)){

            var market_uid_m = ruleManager.detail.market.toString()
            var market_id_m = ''
            var spec_m = ''



            if(market_uid_m != "all"){

              if(market_uid_m.includes('#')){
                let split_m = market_uid_m.split('#')
                market_id_m = split_m[0]
                spec_m = split_m[1]
              }else {
                market_id_m = market_uid_m
              }



              var detailMarket = _.find(vue.infoMultiplicities.markets, (market)=>{
                return market.id.toString() == market_id_m
              })

              if(detailMarket != undefined){
                var text = _.find(detailMarket.detail, {'lang':this.getLanguage})
              }


            }


            ruleManager.detail.path_complete = ruleManager.detail.description
            ruleManager.market_name = (market_uid_m == "all") ? 'ALL' : ((spec_m != '') ? (text != null && text.text != null ? text.text  : 'ERROR')+' '+spec_m : (text != null && text.text != null ? text.text : 'ERROR'))
            ruleManager.user = "Manager";
            ruleManager.platform_id = skinId;
            rules_manager.push(ruleManager)
          }
        }
      }


      // Override by Rules skin
      if(skinId > 0){
        var skin = await _.find(vue.getListSkins, {"platform_id":skinId});

        for(let ruleSkin of skin.rules){
          if(ruleSkin.type === "multiplicity" && ruleSkin.source !== "default" && ruleSkin.scope.includes(producer)){

            // rules_manager = await _.remove(rules_manager, function (rule_manager){
            //   if(!(rule_manager.source === ruleSkin.source && rule_manager.scope === ruleSkin.scope && rule_manager.detail.market === ruleSkin.detail.market)){
            //     return rule_manager
            //   }
            // })

            var market_uid_m = ruleSkin.detail.market.toString()
            var market_id_m = ''
            var spec_m = ''

            if(market_uid_m.includes('#')){
              let split_m = market_uid_m.split('#')
              market_id_m = split_m[0]
              spec_m = split_m[1]
            }else {
              market_id_m = market_uid_m
            }



            var detailMarket = _.find(vue.infoMultiplicities.markets, (market)=>{
              return market.id.toString() == market_id_m
            })

            if(detailMarket != undefined){
              var text = _.find(detailMarket.detail, {'lang':this.getLanguage})
            }


            ruleSkin.detail.path_complete = ruleSkin.detail.description
            ruleSkin.market_name = (market_uid_m == "all") ? 'ALL' : ((spec_m != '') ? text.text+' '+spec_m : text.text)
            ruleSkin.user = skin.name;
            ruleSkin.platform_id = skinId;

            rules_skin.push(ruleSkin)
          }
        }


        rules_total.push(...rules_manager)
        rules_total.push(...rules_skin)

      }else{

        rules_total.push(...rules_manager)

      }

      vue.rulesMultiplicity = rules_total


    },

    async saveMultiplicityDefault(){
      var vue = this;

      await this.setPreload(true);
      var payload = {
        "typeRule": "multiplicity",
        "source": "default",
        "scope": "default#"+this.producerSelected.toString(),
        "rule": {"market":"default", "multiplicity": vue.defaultMultiplicity}
      }

      await this.updateRules(payload)
          .then(async (res) => {
            console.log('Tutto ok')

            this.snackbar.color = "success";
            this.snackbar.text = "Regola aggiornata con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            await this.cacheRules()

            setTimeout(function(){

              vue.fetchSports({'language': vue.getLanguage});
              if(vue.sportSearched != ''){
                vue.fetchCategories({'language': vue.getLanguage, 'paramKey':vue.sportSearched});
              }

              if(vue.categorySearched != ''){
                vue.fetchTournaments({'language': vue.getLanguage, 'paramKey':vue.categorySearched});
              }

            }, 500);


          }).catch((err)=>{

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });

      await this.fetchSkins()
      await this.setPreload(false)


    },

    async getCategoriesFromSport(){
      await this.setPreload(true);
      await this.fetchCategories({'language': this.getLanguage, 'paramKey':this.sportSelected});
      await this.resetTournaments()
      await this.setPreload(false);
    },

    async getMarketsFromSport(){
      await this.setPreload(true);
      var sport = _.find(this.getItemsSport, {'id':this.sportSelected});

      // await this.getMarketsBySport({ deprecated
      //   'sport': sport._id,
      //   "product_id": this.producerSelected,
      //   'lang': this.getLanguage,
      //   'enableSpecifiers': false
      // });

      await this.getMarketsBySportList({
        'sport': sport._id,
        'lang': this.getLanguage,
        'enableSpecifiers': false
      });

      await this.setPreload(false);
    },

    async getTournamentsFromCategories(){
      await this.setPreload(true);
      await this.fetchTournaments({'language': this.getLanguage, 'paramKey':this.categorySelected});
      await this.setPreload(false);
    },

    async getEventsFromTournaments(){
      await this.setPreload(true);
      // await this.fetchTournaments({'language': this.getLanguage, 'paramKey':this.tournamentSelected});
      await this.setPreload(false);
    },

    async createNewRule(){

      var vue = this
      var path = '';
      var description = ''
      vue.formToSubmit = {}
      vue.typeRule = 'multiplicity'

      if(vue.tournamentSelected != '' && vue.tournamentSelected != undefined){

        var sport = _.find(this.getItemsSport, {'id':vue.sportSelected});
        var category = _.find(this.getItemsCategories, {'id':vue.categorySelected});
        var tournament = _.find(this.getItemsTournaments, {'id':vue.tournamentSelected});
        description = sport.name+' > '+category.name+' > '+tournament.name
        path = vue.sportSelected+'#'+vue.categorySelected+'#'+vue.tournamentSelected
        vue.scope = 'tournament#'+vue.producerSelected.toString()
        vue.source = vue.tournamentSelected

      }else if(vue.categorySelected != '' && vue.categorySelected != undefined){

        var sport = _.find(this.getItemsSport, {'id':vue.sportSelected});
        var category = _.find(this.getItemsCategories, {'id':vue.categorySelected});

        description = sport.name+' > '+category.name
        path = vue.sportSelected + '#' + vue.categorySelected
        vue.scope = 'category#'+vue.producerSelected.toString()
        vue.source = vue.categorySelected

      }else if(vue.sportSelected != '' && vue.sportSelected != undefined){

        var sport = _.find(this.getItemsSport, {'id':vue.sportSelected});

        description = sport.name
        path = vue.sportSelected
        vue.scope = 'sport#'+vue.producerSelected.toString()
        vue.source = vue.sportSelected

      }else{
        alert('Errore: Selezionare almeno lo sport');
        return
      }

      if(vue.newMultiplicity == ''){
        alert('Errore: Inserire Legatura');
        return
      }

      if(vue.marketSelected == ''){
        vue.marketSelected = 'all'
      }


      var payload = {
        "typeRule": vue.typeRule,
        "source": vue.source,
        "scope": vue.scope,
        "rule": {"market":vue.marketSelected.toString(), "multiplicity": vue.newMultiplicity, 'path': path, 'description': description}
      }

      await this.updateRules(payload)
          .then(async (res) => {
            console.log('Tutto ok')

            this.snackbar.color = "success";
            this.snackbar.text = "Regola aggiornata con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;
            await this.cacheRules()

            setTimeout(function(){

              this.newMultiplicity = ''
              this.marketSelected = ''

              vue.getRulesMultiplicity(vue.getPlatformId, vue.producerSelected)

            }, 500);

          }).catch((err)=>{

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });

      await this.fetchSkins()
      await this.setPreload(false)

    },

    editItem(item){

      var vue = this;
      vue.dialog = true;

      vue.titleDialog = "Modifica molteplicità";

      vue.itemToEdit = item;

      vue.typeRule = item.type;
      vue.source = item.source;
      vue.scope = item.scope;

      vue.formToSubmit = {
        market: item.detail.market.toString(),
        multiplicity: vue.itemToEdit.detail.multiplicity,
        path: item.detail.path,
        old_rule: item.detail,
        description: vue.itemToEdit.detail.description
      }

    },

    deleteItem(item){

      var vue = this;
      vue.dialogConfirm = true;

      vue.titleDialog = "Elimina regola";

      vue.itemToEdit = item;


      vue.typeRule = item.type;
      vue.source = item.source;
      vue.scope = item.scope;

    },

    async submitRule(){

      var vue = this;
      await this.setPreload(true);

      var payload = {
        "typeRule": this.typeRule,
        "source": this.source,
        "scope": this.scope,
        "rule": this.formToSubmit
      }

      await this.updateRules(payload)
          .then(async (res) => {
            console.log('Tutto ok')

            this.snackbar.color = "success";
            this.snackbar.text = "Regola aggiornata con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            await this.cacheRules()
            setTimeout(function(){

              vue.getRulesMultiplicity(vue.getPlatformId, vue.producerSelected)

            }, 500);


          }).catch((err)=>{

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });

      vue.resetFields()
      vue.dialog = false;
      await this.fetchSkins()
      await this.setPreload(false)
    },

    async submitDelete(){

      var vue = this;
      await this.setPreload(true);

      var payload = {
        "typeRule": this.typeRule,
        "source": this.source,
        "scope": this.scope,
        "rule": this.itemToEdit
      }

      await this.deleteRules(payload)
          .then(async (res) => {
            console.log('Tutto ok')

            this.snackbar.color = "success";
            this.snackbar.text = "Regola aggiornata con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;
            await this.cacheRules()
            setTimeout(function(){

              vue.getRulesMultiplicity(vue.getPlatformId, vue.producerSelected)

            }, 500);


          }).catch((err)=>{

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });


      vue.dialogConfirm = false;

      vue.resetFields()
      await this.fetchSkins()
      await this.setPreload(false)
    },


    resetFields(){

      var vue = this;

      vue.itemToEdit = {
        market_name:'',
            detail:{
          path_complete:'',
              multiplicity:''
        }
      }

      vue.formToSubmit = {}
      vue.typeRule = ''
      vue.source = ''
      vue.scope = ''

    }

  },
}
</script>

<style scoped>

</style>
