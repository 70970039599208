<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md12>
          <v-col cols="12">
            <v-card outlined elevation-0 >
              <bets-filter></bets-filter>
            </v-card>
            <v-card outlined elevation-0 class="mt-1">
              <bets-list></bets-list>
            </v-card>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import moment from 'moment';
import BetsList from "@/components/bets/betsList";
import BetsFilter from "@/components/bets/betsFilter";

export default {
  name: "Bets",
  components: {BetsFilter, BetsList},
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      'getLanguage': 'profile/getLanguage',
      'getToken': "profile/getToken",
      'getPlatformId': "rules/getPlatformId",
      'getOptions': 'bet/getOptions',
    }),

  },

  async mounted() {
    // await this.setPreload(true);
    // await this.fetchBets({page: this.getOptions.page, limit: this.getOptions.limit, platform_id: this.getPlatformId})
    // await this.setPreload(false);
  },
  methods: {
    ...mapActions({
      'setPreload': 'utils/setPreload',
      'fetchBets': 'bet/fetchBets',
      'setOptions': 'bet/setOptions'
    }),
    ...mapMutations({
      'setLanguage': 'profile/setLanguage',
    }),

  },
}
</script>

<style scoped>

</style>
