<template>
  <div>
    <v-dialog transition="dialog-bottom-transition" max-width="80%" v-model="getShowDetail" @click:outside="removeDetail">
      <v-card>
        <v-toolbar color="primary" dark class="text-uppercase" dense>
          {{ bet.code }}
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="removeDetail">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-layout row>
              <v-flex md6>
                <v-col cols="12">
                  <v-card outlined elevation-0>
                    <v-simple-table dense>
                      <thead class="secondary">
                      <tr>
                        <th class="text-left text-uppercase">{{ $t('bets.detail') }}</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.id') }}</td>
                        <td class="text-uppercase">{{ (bet._id != undefined) ? bet._id : '' }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.code') }}</td>
                        <td class="" v-if="!bet.betCode">{{ (bet.code != undefined) ? bet.code : '' }}</td>
                        <td class="" v-else>{{ (bet.betCode != undefined) ? bet.betCode : '' }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.user') }}</td>
                        <td class="text-uppercase">{{ (bet.user_id != undefined) ? bet.user_id : '' }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.type') }}</td>
                        <td class="text-uppercase">{{ (bet.type != undefined) ? this.$t('bets.'+bet.type) : '' }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold text-capitalize">{{  $t('generic.context') }}</td>
                        <td class="text-uppercase">{{ (bet.bet_context != undefined) ? this.$t('bets.'+bet.bet_context) : '' }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.events') }}</td>
                        <td class="text-uppercase">{{ (bet.odds_sources  != undefined) ? bet.odds_sources.length  : '' }}</td>
                      </tr>

                      <tr v-if="bet.type == 'single' || bet.type == 'multiple'">
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.odds') }}</td>
                        <td class="text-uppercase">{{ (bet.detail  != undefined) ? bet.detail.odds_total.toFixed(2)  : '' }}</td>
                      </tr>

                      <tr v-if="bet.type == 'integral' || bet.type == 'system'">
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.combinations') }}</td>
                        <td class="text-uppercase">{{ (bet.detail  != undefined) ? bet.detail.combinations  : '' }}</td>
                      </tr>

                      <!-- Amount -->
                      <tr v-if="bet.type == 'single' || bet.type == 'multiple'">
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.amount') }}</td>
                        <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.amount  : 0.00 }}</td>
                      </tr>

                      <tr v-else-if="bet.type == 'integral'">
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.amount') }}</td>
                        <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.amount_total  : 0.00 }}</td>
                      </tr>

                      <tr v-else-if="bet.type == 'system'">
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.amount') }}</td>
                        <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.amount_system.toFixed(2)  : 0.00 }}</td>
                      </tr>
                      <!-- End Amount -->

                      <!-- Amount x bet-->
                      <tr v-if="bet.amountXBet != null">
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.amountXBet') }}</td>
                        <td class="text-uppercase">{{bet.currencySymbol}} {{ bet.amountXBet.toFixed(2) }}</td>
                      </tr>

                      <!-- Amount x bet -->

                      <tr v-if="bet.type == 'single' || bet.type == 'multiple'">
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.winning') }}</td>
                        <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.winning.toFixed(2)  : 0.00 }} </td>
                      </tr>

                      <tr v-if="bet.type == 'integral' || bet.type == 'system'">
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.bonusMin') }}</td>
                        <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.bonus_min.toFixed(2)  : 0.00 }}</td>
                      </tr>

                      <tr v-if="bet.type == 'integral' || bet.type == 'system'">
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.winning_min') }}</td>
                        <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.winning_total_min.toFixed(2)  : 0.00 }}</td>
                      </tr>

                      <tr v-if="bet.type == 'integral' || bet.type == 'system'">
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.bonusMax') }}</td>
                        <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.bonus_max.toFixed(2)  : 0.00 }}</td>
                      </tr>

                      <tr v-if="bet.type == 'integral'">
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.winning_max') }}</td>
                        <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.winning_total_max.toFixed(2)  : 0.00 }}</td>
                      </tr>

                      <tr v-if="bet.type == 'system'">
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.winning_max') }}</td>
                        <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.winning_all_total.toFixed(2)  : 0.00 }}</td>
                      </tr>

                      <tr v-if="bet.type == 'single' || bet.type == 'multiple'">
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.bonus') }}</td>
                        <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.bonus.toFixed(2)  : 0.00 }}</td>
                      </tr>
                      <tr v-if="bet.type == 'single' || bet.type == 'multiple'">
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.winningTotal') }} </td>
                        <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.winning_total.toFixed(2)  : 0.00 }}</td>
                      </tr>


                      <template v-if="bet.percentageTaxValueOnWon && bet.percentageTaxValueOnWon > 0">
                        <tr v-if="bet.type == 'single' || bet.type == 'multiple'">
                          <td class="font-weight-bold text-capitalize">{{  $t('bets.expectedTotalTaxedWinnings') }} </td>
                          <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.expectedTotalTaxedWinnings.toFixed(2)  : 0.00 }}</td>
                        </tr>


                        <tr v-if="bet.type == 'single' || bet.type == 'multiple'">
                          <td class="font-weight-bold text-capitalize">{{  $t('bets.expectedProfitTaxed') }} </td>
                          <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.expectedProfitTaxed.toFixed(2)  : 0.00 }}</td>
                        </tr>

                        <tr v-if="bet.type == 'single' || bet.type == 'multiple'">
                          <td class="font-weight-bold text-capitalize">{{  $t('bets.expectedTaxOnWonAmount') }} </td>
                          <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.expectedTaxOnWonAmount.toFixed(2)  : 0.00 }}</td>
                        </tr>
                      </template>

                      <template v-if="bet.percentageTaxValueOnWon && bet.percentageTaxValueOnWon > 0">
                        <tr v-if="bet.type == 'integral'">
                          <td class="font-weight-bold text-capitalize">{{  $t('bets.taxOnWonAmountMin') }} </td>
                          <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.taxOnWonAmountMin.toFixed(2)  : 0.00 }}</td>
                        </tr>


                        <tr v-if="bet.type == 'integral'">
                          <td class="font-weight-bold text-capitalize">{{  $t('bets.taxOnWonAmountMax') }} </td>
                          <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.taxOnWonAmountMax.toFixed(2)  : 0.00 }}</td>
                        </tr>

                        <tr v-if="bet.type == 'integral'">
                          <td class="font-weight-bold text-capitalize">{{  $t('bets.winning_total_min_taxed') }} </td>
                          <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.winning_total_min_taxed.toFixed(2)  : 0.00 }}</td>
                        </tr>

                        <tr v-if="bet.type == 'integral'">
                          <td class="font-weight-bold text-capitalize">{{  $t('bets.winning_total_max_taxed') }} </td>
                          <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.winning_total_max_taxed.toFixed(2)  : 0.00 }}</td>
                        </tr>


                        <tr v-if="bet.type == 'integral'">
                          <td class="font-weight-bold text-capitalize">{{  $t('bets.profit_min_taxed') }} </td>
                          <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.profit_min_taxed.toFixed(2)  : 0.00 }}</td>
                        </tr>


                        <tr v-if="bet.type == 'integral'">
                          <td class="font-weight-bold text-capitalize">{{  $t('bets.profit_max_taxed') }} </td>
                          <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.profit_max_taxed.toFixed(2)  : 0.00 }}</td>
                        </tr>
                      </template>

                      <template v-if="bet.percentageTaxValueOnWon && bet.percentageTaxValueOnWon > 0">
                        <!--                        <tr v-if="bet.type == 'system'">-->
                        <!--                          <td class="font-weight-bold text-capitalize">{{  $t('bets.winning_min_taxed') }} </td>-->
                        <!--                          <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.winning_min_taxed.toFixed(2)  : 0.00 }}</td>-->
                        <!--                        </tr>-->

                        <!--                        <tr v-if="bet.type == 'system'">-->
                        <!--                          <td class="font-weight-bold text-capitalize">{{  $t('bets.winning_max_taxed') }} </td>-->
                        <!--                          <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.winning_max_taxed.toFixed(2)  : 0.00 }}</td>-->
                        <!--                        </tr>-->


                        <tr v-if="bet.type == 'system'">
                          <td class="font-weight-bold text-capitalize">{{  $t('bets.winning_total_min_taxed') }} </td>
                          <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.winning_total_min_taxed.toFixed(2)  : 0.00 }}</td>
                        </tr>


                        <tr v-if="bet.type == 'system'">
                          <td class="font-weight-bold text-capitalize">{{  $t('bets.winning_total_max_taxed') }} </td>
                          <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.winning_total_max_taxed.toFixed(2)  : 0.00 }}</td>
                        </tr>


                        <!--                        <tr v-if="bet.type == 'system'">-->
                        <!--                          <td class="font-weight-bold text-capitalize">{{  $t('bets.winning_all_taxed') }} </td>-->
                        <!--                          <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.winning_all_taxed.toFixed(2)  : 0.00 }}</td>-->
                        <!--                        </tr>-->


                        <tr v-if="bet.type == 'system'">
                          <td class="font-weight-bold text-capitalize">{{  $t('bets.winning_all_total_taxed') }} </td>
                          <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.winning_all_total_taxed.toFixed(2)  : 0.00 }}</td>
                        </tr>


                        <!--                        <tr v-if="bet.type == 'system'">-->
                        <!--                          <td class="font-weight-bold text-capitalize">{{  $t('bets.taxOnWonAmountMin') }} </td>-->
                        <!--                          <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.taxOnWonAmountMin.toFixed(2)  : 0.00 }}</td>-->
                        <!--                        </tr>-->


                        <!--                        <tr v-if="bet.type == 'system'">-->
                        <!--                          <td class="font-weight-bold text-capitalize">{{  $t('bets.taxOnWonAmountMax') }} </td>-->
                        <!--                          <td class="text-uppercase">{{bet.currencySymbol}} {{ (bet.detail  != undefined) ? bet.detail.taxOnWonAmountMax.toFixed(2)  : 0.00 }}</td>-->
                        <!--                        </tr>-->

                      </template>


                      <template v-if="bet.percentageTaxValueOnWon && bet.percentageTaxValueOnWon > 0">
                        <tr v-if="bet.result == 'win' || bet.result == 'void'">
                          <td class="font-weight-bold text-capitalize">{{  $t('bets.winning_real_without_tax') }} <span v-if="bet.void_detail != undefined || bet.result == 'void'" :title="$t('bets.someSignsVoid')" class="blue--text">*</span></td>
                          <td class="text-uppercase font-weight-bold">{{bet.currencySymbol}} {{ (bet.winning_real_without_tax  != undefined) ? bet.winning_real_without_tax.toFixed(2)  : 0.00 }}</td>
                        </tr>
                      </template>

                      <tr v-if="bet.result == 'win' || bet.result == 'void'">
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.real_winning') }} <span v-if="bet.void_detail != undefined || bet.result == 'void'" :title="$t('bets.someSignsVoid')" class="blue--text">*</span></td>
                        <td class="text-uppercase font-weight-bold">{{bet.currencySymbol}} {{ (bet.winning_real  != undefined) ? bet.winning_real.toFixed(2)  : 0.00 }}</td>
                      </tr>

                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
              </v-flex>
              <v-flex md6>
                <v-col cols="12">
                  <v-card outlined elevation-0>
                    <v-simple-table dense>
                      <thead class="secondary">
                      <tr>
                        <th class="text-left text-uppercase">{{ $t('bets.actions') }}</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.status') }}</td>
                        <td class="text-uppercase"><v-icon v-if="bet.status == 'review'" @click="dialogReview = true;betToReview = bet.code">mdi-thumbs-up-down</v-icon> {{ (bet.status != undefined) ? this.$t('bets.'+bet.status) : '' }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.result') }}
                          <span v-if="bet.void_detail != undefined" :title="$t('bets.someSignsVoid')" class="blue--text">*</span>
                        </td>
                        <td class="text-uppercase">
                          <span v-if="bet.result == 'pending'" class="list-pending"><div class="circle-pending"></div> {{ $t('bets.'+bet.result) | capitalize}}</span>
                          <span v-if="bet.result == 'lose'" class="red--text"><v-icon small color="red">mdi-close-circle</v-icon> {{ $t('bets.'+bet.result) | capitalize}}</span>
                          <span v-if="bet.result == 'win'" class="green--text"><v-icon small color="green">mdi-checkbox-marked-circle</v-icon> {{ $t('bets.'+bet.result) | capitalize}}</span>
                          <span v-if="bet.result == 'void'" class="blue--text"><v-icon small color="blue">mdi-alert-circle</v-icon> {{ $t('bets.'+bet.result) | capitalize}}</span>
                          <span v-if="bet.result == 'cancelled'" class="blue-grey--text"><v-icon small color="blue-grey">mdi-alert-circle</v-icon> {{ $t('bets.'+bet.result) | capitalize}}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.cashout') }}</td>
                        <td class="text-uppercase">
                          <span v-if="!bet.cashout">
                            <v-icon small color="red">mdi-close-circle</v-icon> {{ this.$t('generic.no') }}
                          </span>
                          <span v-else="bet.cashout">
                            <v-icon small color="green">mdi-checkbox-marked-circle</v-icon> {{ this.$t('generic.yes') }}
                          </span>
                          <small v.if="bet.cashout">({{ (bet.sent_time > 0 ) ? new Date(bet.sent_time).toLocaleString() : '' }})</small>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.payed') }}</td>
                        <td class="text-uppercase">{{ (bet.payed != undefined && bet.payed == true) ? this.$t('generic.yes') : this.$t('generic.no') }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.created') }}</td>
                        <td class="text-uppercase">{{ (bet.created_at != undefined) ? new Date(bet.created_at).toLocaleString()  : '' }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold text-capitalize">{{  $t('bets.max_bet_time') }}</td>
                        <td class="text-uppercase">{{ (bet.max_bet_time > 0) ? new Date(bet.max_bet_time).toLocaleString() : '' }}</td>
                      </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
                <v-col cols="12" v-if="bet.type == 'system'">
                  <v-card outlined elevation-0>
                    <v-simple-table dense>
                      <thead class="secondary">
                      <tr>
                        <th class="text-left text-uppercase">{{ $t('bets.system') }}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="text-uppercase header-table-small">{{$t('bets.multiplicity')}}</td>
                        <td class="text-uppercase header-table-small">{{$t('bets.amount') }}</td>
                        <td class="text-uppercase header-table-small">{{$t('bets.combinations') }}</td>
                        <td class="text-uppercase header-table-small">{{$t('bets.real_winning')}}</td>
                        <td class="text-uppercase header-table-small">{{$t('bets.result')}}</td>
                      </tr>
                      <tr v-for="(part,index) in bet.system_parts" :key="index" v-if="part.checked">
                        <td class="font-weight-bold text-center">{{part.multiplicity}}</td>
                        <td class="text-uppercase">{{bet.currencySymbol}} {{part.totalAmount.toFixed(2)}}</td>
                        <td class="text-uppercase text-center">{{part.num_combo}}</td>
                        <td class="text-uppercase">{{bet.currencySymbol}} {{ (part.winning_real != undefined) ? part.winning_real.toFixed(2) : 0.00}}</td>
                        <td class="text-uppercase"><span v-if="part.result == undefined" class="list-pending"><div class="circle-pending"></div> {{ $t('bets.pending') | capitalize}}</span>
                          <span v-if="part.result == 'lose'" class="red--text"><v-icon small color="red">mdi-close-circle</v-icon> {{ $t('bets.'+part.result) | capitalize}}</span>
                          <span v-if="part.result == 'win'" class="green--text"><v-icon small color="green">mdi-checkbox-marked-circle</v-icon> {{ $t('bets.'+part.result) | capitalize}}</span>
                          <span v-if="part.result == 'void'" class="blue--text"><v-icon small color="blue">mdi-alert-circle</v-icon> {{ $t('bets.'+part.result) | capitalize}}</span></td>
                      </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
                <v-col cols="12" v-if="bet.changed_detail != undefined">
                  <v-card outlined elevation-0>
                    <v-simple-table dense>
                      <thead class="secondary">
                      <tr>
                        <th class="text-left text-uppercase"></th>
                        <th>{{$t('bets.odds') | capitalize}}</th>
                        <th>{{$t('percentage') | capitalize}}</th>
                        <th>{{$t('bets.real_winning') | capitalize}}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="text-uppercase">{{bet.changed_detail.description}}</td>
                        <td class="text-uppercase">{{bet.changed_detail.odds}}</td>
                        <td class="text-uppercase">{{bet.changed_detail.winPercentage}} %</td>
                        <td class="text-uppercase">{{bet.currencySymbol}} {{bet.winning_real.toFixed(2)}}</td>
                      </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card outlined elevation-0>
                    <v-simple-table dense>
                      <thead class="secondary">
                      <tr>
                        <th class="text-uppercase">{{$t('device')}}</th>
                        <th class="text-uppercase">{{$t('platform') | capitalize}}</th>
                        <th class="text-uppercase">{{$t('browser') | capitalize}}</th>
                        <th class="text-uppercase">{{$t('ip') | capitalize}}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          <template v-if="bet.extra_data && bet.extra_data.isDesktop">
                            <v-icon title="Desktop">mdi-desktop-mac</v-icon> Desktop
                          </template>
                          <template v-if="bet.extra_data && bet.extra_data.isMobile">
                            <v-icon title="Desktop">mdi-cellphone</v-icon> Mobile
                          </template>
                          <template v-if="bet.extra_data && bet.extra_data.isTablet">
                            <v-icon title="Desktop">mdi-tablet-ipad</v-icon> Tablet
                          </template>
                        </td>
                        <td>
                          <template v-if="bet.extra_data && bet.extra_data.isAndroid">
                            <v-icon>mdi-android</v-icon>
                            {{bet.extra_data.platform + ' - ' + bet.extra_data.os }}
                          </template>
                          <template v-if="bet.extra_data && bet.extra_data.isMac">
                            <v-icon>mdi-apple-ios</v-icon>
                            {{bet.extra_data.platform + ' - ' + bet.extra_data.os }}
                          </template>
                          <template v-if="bet.extra_data && bet.extra_data.isWindows">
                            <v-icon>mdi-microsoft-windows</v-icon>
                            {{bet.extra_data.platform + ' - ' + bet.extra_data.os }}
                          </template>
                        </td>
                        <td>
                          <template v-if="bet.extra_data && bet.extra_data.isChrome">
                            <v-icon :title="bet.extra_data.browser">mdi-google-chrome</v-icon> {{bet.extra_data.browser}}
                          </template>

                          <template v-if="bet.extra_data && bet.extra_data.isEdge">
                            <v-icon :title="bet.extra_data.browser">mdi-microsoft-edge</v-icon> {{bet.extra_data.browser}}
                          </template>

                          <template v-if="bet.extra_data && bet.extra_data.isFirefox">
                            <v-icon :title="bet.extra_data.browser">mdi-microsoft-firefox</v-icon> {{bet.extra_data.browser}}
                          </template>

                          <template v-if="bet.extra_data && bet.extra_data.isOpera">
                            <v-icon :title="bet.extra_data.browser">mdi-opera</v-icon> {{bet.extra_data.browser}}
                          </template>

                          <template v-if="bet.extra_data && bet.extra_data.isSafari">
                            <v-icon :title="bet.extra_data.browser">mdi-apple-safari</v-icon> {{bet.extra_data.browser}}
                          </template>
                        </td>

                        <td>
                          <template v-if="bet.extra_data && bet.extra_data.ip">
                            {{bet.extra_data.ip}}  <v-icon @click="lookupIp(bet.extra_data.ip.toLocaleString())">mdi-card-search-outline</v-icon>
                          </template>
                        </td>
                      </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card outlined elevation-0>
                    <span class="font-weight-bold text-capitalize">{{$t('betHistory')}}</span>
                    <v-btn elevation="0" x-small @click="showHideBetHistory">+/-</v-btn>
                    <template v-if="!isBetHistoryHidden">
                      <v-simple-table dense>
                        <thead class="secondary" >
                        <tr>
                          <th class="text-uppercase">{{$t('time')}}</th>
                          <th class="text-uppercase">{{$t('description') | capitalize}}</th>
                          <th class="text-uppercase">{{$t('user') | capitalize}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(history,index) in bet.history" :key="index">
                          <td class="text">{{ (getTimezone != '') ? new Date(history.time).toLocaleString(undefined,{timeZone: getTimezone}) : new Date(history.time).toLocaleString() }}</td>
                          <td class="text">{{history.description}}</td>
                          <td class="text">{{history.user}}</td>
                        </tr>
                        </tbody>
                      </v-simple-table>
                    </template>
                  </v-card>
                </v-col>

              </v-flex>
              <v-flex md12>
                <v-col cols="12">
                  <v-card outlined elevation-0>
                    <v-simple-table dense>
                      <thead class="secondary">
                      <tr>
                        <th class="text-left text-uppercase">&nbsp;</th>
                        <th class="text-left text-uppercase">{{ $t('bets.id') }}</th>
                        <th class="text-left text-uppercase">{{ $t('bets.match') }}</th>
                        <th class="text-left text-uppercase">&nbsp;</th>
                        <th class="text-left text-uppercase">{{ $t('bets.tournament') }}</th>
                        <th class="text-left text-uppercase">{{ $t('generic.markets') }}</th>
                        <th class="text-left text-uppercase">{{ $t('bets.sign') }}</th>
                        <th class="text-left text-uppercase">{{ $t('bets.odd') }}</th>
                        <th class="text-left text-uppercase">{{ $t('bets.multiplicity') | truncate(4,'.')}}</th>
                        <th class="text-left text-uppercase">{{ $t('bets.deadline') }}</th>
                        <th class="text-center text-uppercase">{{ $t('bets.result') }}</th>
                        <th class="text-center text-uppercase">{{$t('score')}}</th>
                        <!--                        <th class="text-center text-uppercase" v-if="!getDefined(bet)">{{ $t('bets.actions') }}</th>-->
                        <th class="text-center text-uppercase" v-if="actionsbet == 'true'">{{ $t('bets.actions') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="odd in bet.odds" :key="odd.unique_id">
                        <td><span v-if="odd.review"><v-icon small>mdi-alert</v-icon></span></td>
                        <td>{{odd.event_source}}</td>
                        <td>
                          <span v-if="odd.type == 'live'" class="live-label">Live</span>
                          <span>{{odd.event_description}}</span>
                        </td>
                        <td>
                          <span v-if="odd.type == 'live'" style="color: #000;font-weight: 600;">
                            <v-icon small>mdi-target</v-icon>{{(odd.result_live) ? odd.result_live : ''}} <v-icon small>mdi-clock-time-eight-outline</v-icon>{{ (odd.time_live) ? odd.time_live+"'" : ''}}</span>
                        </td>
                        <td>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }"><v-icon small v-bind="attrs" v-on="on">mdi-loupe</v-icon></template>
                            <span>{{odd.sport.name}} > {{odd.category.name}} > {{odd.tournament.name}}</span>
                          </v-tooltip>
                          {{ (odd.tournament != undefined && odd.tournament.name) ? odd.tournament.name : 'n.d.' }}
                        </td>

                        <td>
                          <span>{{odd.market_name != null ? odd.market_name : ""}} {{getSpread(odd.market_unique_id)}}</span>
                        </td>

                        <td>
                          <!--                          <v-tooltip right>-->
                          <!--                          <template v-slot:activator="{ on, attrs }"><v-icon small v-bind="attrs" v-on="on">mdi-loupe</v-icon></template>-->
                          <!--                          <span>{{odd.market_name}} {{getSpread(odd.market_unique_id)}}</span>-->
                          <!--                          </v-tooltip>-->
                          {{ (odd.outcome_alias != undefined && odd.outcome_alias != '') ? odd.outcome_alias : odd.outcome_name}}
                        </td>
                        <td>{{odd.outcome_odd_value}}</td>
                        <td>{{odd.multiplicity_min}}</td>
                        <td>{{ (odd.time > 0 ) ? new Date(odd.time).toLocaleString() : '' }}</td>
                        <td>
                          <div v-if="odd.result == undefined" :title="$t('bets.pending')"><div class="circle-pending"></div></div>
                          <div v-else-if="odd.void" :title="$t('bets.void')"><v-icon small color="blue">mdi-alert-circle</v-icon></div>
                          <div v-else-if="odd.cancelled" :title="$t('bets.cancelled')"><v-icon small color="yellow">mdi-alert-circle</v-icon></div>
                          <div v-else-if="odd.result" :title="$t('bets.win')"><v-icon small color="green">mdi-checkbox-marked-circle</v-icon></div>
                          <div v-else-if="!odd.result" :title="$t('bets.lose')"><v-icon small color="red">mdi-close-circle</v-icon></div>
                          <div v-if="odd.half_won" :title="$t('bets.halfWon')"><v-icon small color="green">mdi-star-half</v-icon></div>
                          <div v-else-if="odd.half_lost" :title="$t('bets.halfLost')"><v-icon small color="red">mdi-star-half</v-icon></div>
                        </td>
                        <td class="justify-center text-center">
                          <template v-if="odd.match_result">
                            [{{odd.match_result}}]
                          </template>
                        </td>
                        <!--                        <td v-if="odd.result == undefined" class="text-center" :title="$t('bets.pending')"><div class="circle-pending"></div></td>-->
                        <!--                        <td v-else-if="odd.void" class="text-center" :title="$t('bets.void')"><v-icon small color="blue">mdi-alert-circle</v-icon></td>-->
                        <!--                        <td v-else-if="odd.cancelled" class="text-center" :title="$t('bets.cancelled')"><v-icon small color="yellow">mdi-alert-circle</v-icon></td>-->
                        <!--                        <td v-else-if="odd.result" class="text-center" :title="$t('bets.win')"><v-icon small color="green">mdi-checkbox-marked-circle</v-icon></td>-->
                        <!--                        <td v-else-if="!odd.result" class="text-center" :title="$t('bets.lose')"><v-icon small color="red">mdi-close-circle</v-icon></td>-->

                        <!--                        <td v-if="!getDefined(bet)">-->
                        <td class="justify-center text-center" v-if="actionsbet === 'true'">
                          <!--                          <v-icon v-if="odd.result != undefined" :title="$t('bets.defineAllBetsWithThisOdd')" @click="defineAllBetsWithEvent(odd.event_source)">mdi-check-all</v-icon>-->
                          <v-icon v-if="bet.result !== 'cancelled'" :title="$t('bets.defineResult')" @click="defineResult(odd.event_source, odd.unique_id, odd.event_description, (odd.outcome_alias != undefined) ? odd.outcome_alias : odd.outcome_name, odd.tournament, odd.market_name)">mdi-auto-fix</v-icon>
                          <!--                          <v-icon v-if="new Date().getTime() > odd.time " :title="$t('bets.defineResult')" @click="defineResult(odd.event_source, odd.unique_id, odd.event_description, (odd.outcome_alias != undefined) ? odd.outcome_alias : odd.outcome_name, odd.tournament, odd.market_name)">mdi-auto-fix</v-icon>-->
                          <v-icon v-if="odd.result === undefined && new Date().getTime() > odd.time " :title="$t('bets.recoveryOddsResult')" @click="recoverySettlement(odd.event_source)">mdi-backup-restore</v-icon>
                        </td>
                      </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <!--        <v-card-actions class="justify-end">-->
        <!--          <v-btn text @click="removeDetail">{{ this.$t('generic.close') }}</v-btn>-->
        <!--        </v-card-actions>-->
      </v-card>
      <div class="text-center">
        <v-dialog v-model="dialogDefineSign" width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2">
              {{$t('bets.defineResult')}}
            </v-card-title>

            <v-card-text class="pt-5">
              <p class="font-weight-bold">{{this.descriptionToDefine}}</p>
              <v-select
                      :items="results"
                      item-text="name"
                      item-value="value"
                      filled
                      v-model="resultToDefine"
              ></v-select>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions v-if="resultToDefine != ''">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="sendResult()">
                {{ $t('generic.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <v-dialog v-model="dialogReview" width="500" height="500">
          <v-card>
            <v-card-title>
              <span class="text-h5"></span>
            </v-card-title>
            <v-card-text>
              <v-flex style="display: flex">

                <div class="button-review" style="background: #748300" @click="betDecision('accept')">
                  <v-icon large>mdi-thumb-up</v-icon> <span class="label-review">{{$t('accept')}}</span>
                </div>

                <div class="button-review" style="background: #c90000" @click="betDecision('reject')">
                  <v-icon large>mdi-thumb-down</v-icon> <span class="label-review">{{$t('reject')}}</span>
                </div>

              </v-flex>

            </v-card-text>
          </v-card>
        </v-dialog>

      </div>

    </v-dialog>
  </div>
</template>

<script>
  import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
  import _ from 'lodash';

  export default {
    name: "betDetail",
    props: {
      actionsbet: {
        type: String,
        default: "true"
      }
    },
    data() {
      return {
        results:[
          {'name': this.$t('generic.selectOption'), 'value':''},
          {'name': this.$t('bets.win'), 'value':'win'},
          {'name': this.$t('bets.lose'), 'value':'lose'},
          {'name': this.$t('bets.void'), 'value':'void'},
          {'name': this.$t('bets.cancelled'), 'value':'cancelled'},
          {'name': this.$t('bets.halfLost'), 'value':'halfLost'},
          {'name': this.$t('bets.halfWon'), 'value':'halfWon'},
        ],
        tooltip: false,
        dialogDefineSign: false,
        signToDefine:{},
        resultToDefine: "",
        descriptionToDefine: "",
        betToReview: '',
        dialogReview: false,
        isBetHistoryHidden: true
      }
    },
    computed: {
      ...mapGetters({
        'bet': 'bet/getBetDetail',
        'getShowDetail': 'bet/getShowDetail',
        'getPlatformId': "rules/getPlatformId",
        'getCriteria': "bet/getCriteria",
      }),
    },

    methods: {

      ...mapActions({
        'setBetDetail': 'bet/setBetDetail',
        'setShowDetail': 'bet/setShowDetail',
        'setPreload': 'utils/setPreload',
        'defineBetsFromEvents': 'bet/defineBetsFromEvents',
        'fetchBets': 'bet/fetchBets',
        'recoveryBetSettlement': 'bet/recoveryBetSettlement',
        'removeBetDetail': 'bet/removeBetDetail',
        'defineOdd': 'bet/defineOdd',
        'defineOddInBet': 'bet/defineOddInBet',
        'fetchBetDetail': 'bet/fetchBetDetail',
        /*'sendReviewBet': 'bet/sendReviewBet',*/
        'fetchReviewCounts': 'bet/fetchReviewCounts',
        'sendAcceptBet':'bet/sendAcceptBet',
        'sendRejectBet':'bet/sendRejectBet',
      }),

      async betDecision(mode) {
        await this.setPreload(true);
        //const result = await this.sendReviewBet({'code':this.betToReview, 'mode':mode});
        let result ;

        try {

          if(mode === 'accept'){
            result = await this.sendAcceptBet({'code': this.betToReview});
          }else if (mode === 'reject'){
            result = await this.sendRejectBet({'code': this.betToReview});
          }

          if(result.data.result){
            this.betToReview = ''
            this.dialogReview = false
            await this.fetchReviewCounts()
            await this.fetchBets(this.getCriteria)
            await this.removeDetail()
          }else{
            this.betToReview = ''
            this.dialogReview = false
          }

        }catch (e) {
          console.log(e)
        }

        await this.setPreload(false);
      },

      async removeDetail() {
        await this.removeBetDetail({})
        await this.setShowDetail(false)
      },

      async defineAllBetsWithEvent(eventId){
        await this.setPreload(true);
        await this.defineBetsFromEvents({event_id : eventId})
        await this.fetchBets({skip: 0, limit: 50, platform_id: this.getPlatformId})
        await this.setPreload(false);
      },

      async recoverySettlement(eventId){
        await this.setPreload(true);
        await this.recoveryBetSettlement({event_id : eventId})
        await this.setPreload(false);
      },

      async defineResult(eventId, unique_id, desc, alias, tournament, market){
        await this.setPreload(true);
        this.resultToDefine = ''
        this.signToDefine = {
          event_id: eventId,
          odd_unique_id: unique_id,
          betCode: this.bet.code
        }
        if(desc == undefined){
          desc = tournament.name + ' - ' + market
        }
        this.descriptionToDefine = desc + ' - '+ alias
        this.dialogDefineSign = true
        await this.setPreload(false);
      },

      async sendResult(){
        await this.setPreload(true);
        this.signToDefine.result = this.resultToDefine
        const result = await this.defineOddInBet(this.signToDefine)

        setTimeout(async () =>{

          if(result){
            this.dialogDefineSign = false
            await this.fetchBetDetail({code: this.bet.code})
          }

          await this.setPreload(false);

        }, 1500);

      },

      getIdEvent(eventId){
        let split = eventId.split(':')
        return split[2]
      },

      getDefined(bet){
        var defined = _.countBy(bet.odds, {'defined':true})
        if(defined.true != undefined && defined.true == bet.odds.length){
          return true
        }else {
          return false
        }

      },

      getSpread(spec) {
        var result = ''

        if(spec.includes('#')){

          var split = spec.split('#')
          if(split[1].includes(':')){
            result = '('+split[1]+')'
          }else if(split[1].includes('(') && split[1].includes('.')){
            result = ' ['+split[1]+'] '
          }else if(split[1] != ''){
            result = split[1]
          }

          if(_.size(split) > 2){
            return result + ' ' + split[2]
          }else {
            return result
          }

        }else {
          return  result
        }
      },

      lookupIp(ip){
        let url = 'https://whatismyipaddress.com/ip/'+ip
        window.open(url, '_blank').focus()
      },

      showHideBetHistory: function () {
        console.log('func')
        if (this.isBetHistoryHidden) {
          this.isBetHistoryHidden=false;
        }else{
          this.isBetHistoryHidden=true;
        }
      }
    },
    mounted() {
      console.log(this.actionsbet)
    }
  }
</script>

<style scoped>
  .live-label{
    font-weight: bold;
    font-style: italic;
    background: #c90000;
    padding: 0 5px 0 2px;
    transition: all 0.5s ease-in-out;
    -webkit-animation: blink-live 1s infinite ease-in-out;
    animation: blink-live 1s infinite ease-in-out;
    cursor: default;
    font-size: 10px;
    text-transform: uppercase;
    color: #fff;
    margin-right: 5px;
  }

  .button-review{
    flex: 0 0 48%;
    border: 1px solid #7e7e7e;
    border-radius: 3px;
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
  }

  .label-review{
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
  }
</style>
