<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md12>
          <v-col cols="12">
            <span class="title-table"></span>
            <v-card outlined elevation-0 class="mt-1">
              <v-card-text class="pt-5">
                <SearchEvents></SearchEvents>
              </v-card-text>
            </v-card>
            <v-card outlined elevation-0 class="mt-1">
              <EventsList></EventsList>
            </v-card>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>

    <div class="text-center">
      <v-dialog v-model="dialogDefineSign" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{$t('bets.defineResult')}}
          </v-card-title>

          <v-card-text class="pt-5">
            <p class="font-weight-bold">{{this.descriptionToDefine}}</p>
            <v-select
                :items="results"
                item-text="name"
                item-value="value"
                filled
                v-model="resultToDefine"
            ></v-select>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions v-if="resultToDefine != ''">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="sendResult()">
              {{ $t('generic.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import SearchEvents from "@/components/events/Search";
import EventsList from "@/components/events/eventsList";


export default {
  name: "events",
  components: {EventsList, SearchEvents},
  data() {
    return {
      antepost: [],
      mixed: [],
      results:[
        {'name': this.$t('generic.selectOption'), 'value':''},
        {'name': this.$t('bets.win'), 'value':'win'},
        {'name': this.$t('bets.lose'), 'value':'lose'},
        {'name': this.$t('bets.void'), 'value':'void'},
        {'name': this.$t('bets.cancelled'), 'value':'cancelled'},
        {'name': this.$t('bets.halfLost'), 'value':'halfLost'},
        {'name': this.$t('bets.halfWon'), 'value':'halfWon'},
      ],
      dialogDefineSign: false,
      signToDefine:{},
      resultToDefine: "",
      descriptionToDefine: ""
    }
  },
  computed: {
    ...mapGetters({
      'getLanguage': 'profile/getLanguage',
      'getToken': "profile/getToken",
      'getPlatformId': "rules/getPlatformId",
      'getOptions': 'bet/getOptions',
      'getUrlApi': 'settings/getUrl',
      'getEventsList': 'event/getEventsList',
      'getBetApiUrl': 'settings/getBetApiUrl',
    }),

  },

  async created() {
    await this.setPreload(true);
    await this.fetchOddsNotDefined()
    await this.setPreload(false);
  },
  methods: {
    ...mapActions({
      'setPreload': 'utils/setPreload',
      'recoveryBetSettlement': 'bet/recoveryBetSettlement',
      'defineOdd': 'bet/defineOdd',
    }),
    ...mapMutations({
      'setLanguage': 'profile/setLanguage',
    }),

    async fetchOddsNotDefined() {

      var vue = this
      var mixed = []
      var mixed_sources = []
      var antepost = []
      var antepost_sources = []

      let url = `${this.getBetApiUrl}bet/odds-not-defined`;
      axios.post(`${url}`, {}, {'headers': {'Authorization': `Bearer ${this.getToken}`}}).then((result) => {

        _.forEach(result.data.odds, (odd) => {

          var oddObj = JSON.parse(JSON.stringify(odd))
          switch (oddObj.type) {
            case "antepost":
              if (!antepost_sources.includes(oddObj.unique_id)) {
                antepost.push(oddObj)
                antepost_sources.push(oddObj.unique_id)
              }
              break
            case "live":
            case "prematch":
              if (!mixed_sources.includes(oddObj.unique_id)) {
                mixed.push(oddObj)
                mixed_sources.push(oddObj.unique_id)
              }
              break
          }
        })

        var mixed_ordered = _.orderBy(mixed, ['time'], ['asc'])

        vue.mixed = _.groupBy(mixed_ordered, (item) => {
          return [item['event_id'], item['time']];
        });

        // vue.mixed = _.groupBy(mixed_ordered, ['event_id','time']);
        vue.antepost = _.groupBy(antepost, 'event_id');


      })

    },

    async defineResult(eventId, unique_id, desc, alias, market){
      await this.setPreload(true);
      this.resultToDefine = ''
      this.signToDefine = {
        event_id: eventId,
        odd_unique_id: unique_id,
      }
      // if(desc == undefined){
      //   desc = tournament.name + ' - ' + market
      // }
      this.descriptionToDefine = desc + ' - '+ alias
      this.dialogDefineSign = true
      await this.setPreload(false);
    },

    async recoverySettlement(eventId){
      await this.setPreload(true);
      await this.recoveryBetSettlement({event_id : eventId})
      await this.setPreload(false);
    },

    async sendResult(){
      await this.setPreload(true);
      this.signToDefine.result = this.resultToDefine
      const result = await this.defineOdd(this.signToDefine)

      setTimeout(async () =>{

        if(result){
          this.dialogDefineSign = false
          await this.fetchOddsNotDefined()
        }

        await this.setPreload(false);

      }, 1500);

    },
    searchOnGoogle(key){
      let url = 'https://www.google.com/search?q='+key
      window.open(url, '_blank').focus()
    },

    getSpread(spec) {
      if(spec.includes('#')){
        var split = spec.split('#')
        if(split[1].includes(':')){
          return '('+split[1]+')'
        }else if(split[1].includes('(') && split[1].includes('.')){
          return ' ['+split[1]+'] '
        }else if(split[1] != ''){
          return split[1]
        }else {
          return ''
        }
      }else {
        return ''
      }
    }


  },
}
</script>

<style scoped>
.event-desc{
  font-size: 14px;
  padding-left: 10px;
  font-weight: 700;
}
.info-label{
  font-size: 11px;
  color: #888888;
}
</style>
