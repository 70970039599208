
import {checkRoute} from "../Helper";

export default [
    // {
    //     path: '/users',
    //     name: 'Users list',
    //     component: UsersList,
    //     beforeEnter(to, from, next) {
    //         checkRoute('/users').then(response => {
    //                 //console.log("response",response)
    //                 if (response) {
    //                     //console.log("login")
    //                     next();
    //                 } else {
    //                     //console.log("next")
    //                     next('/login');
    //                 }
    //             }
    //         )
    //     }
    // }

]
