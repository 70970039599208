<template>
  <div>
    <v-container fluid>
      <v-layout row>

        <v-flex md12>
          <v-col cols="12">
            <span class="title-table">{{ this.$t('generic.oddsGroup') | capitalize}}</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-col cols="2">
                    <v-autocomplete
                            v-model="searchSport"
                            :items="getItemsSport"
                            item-text="name"
                            item-value="_id"
                            outlined
                            dense
                            chips
                            small-chips
                            :label="$t('generic.sport')"
                            deletable-chips
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                    <v-autocomplete
                            v-model="searchProducer"
                            :items="getProducers"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                            chips
                            small-chips
                            :label="$t('generic.context')"
                            deletable-chips
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                    <v-select
                            v-model="searchType"
                            :items="type"
                            item-value="value"
                            item-text="text"
                            :label="$t('generic.type')|capitalize"
                            outlined
                            dense
                    ></v-select>
                  </v-col>
                  <!--<v-col cols="2">
                    <v-btn color="blue-grey" class="white&#45;&#45;text text-right " @click="clearcache()">
                      <v-icon left dark>save</v-icon>
                      {{ $t('generic.clearcache') }}
                    </v-btn>
                  </v-col>-->

                  <!--<v-col cols="4" v-if="searchSport != -1 && searchProducer != -1 && searchType != '' ">
                    <v-btn color="blue-grey" class="white&#45;&#45;text text-right" @click="updateGroup()">
                      <v-icon left dark>save</v-icon>
                      {{ $t('generic.save') }}
                    </v-btn>
                  </v-col>-->
                </v-row>
                <v-row v-if="getPlatform==0">
                  <v-col cols="2">
                    <v-autocomplete
                            v-model="duplicateId"
                            :items="listPlatform"
                            item-text="name"
                            item-value="platform_id"
                            outlined
                            dense
                            label="Copy from manager to platform selected"
                            :error-messages="textduplice"

                    ></v-autocomplete>
                  </v-col>


                  <v-col cols="2">
                    <v-btn color="blue-grey" class="white--text text-right " @click="copysetting()">
                      <v-icon left dark>save</v-icon>
                      {{ $t('generic.copysetting') }}
                    </v-btn>
                  </v-col>

                  <!--<v-col cols="4" v-if="searchSport != -1 && searchProducer != -1 && searchType != '' ">
                    <v-btn color="blue-grey" class="white&#45;&#45;text text-right" @click="updateGroup()">
                      <v-icon left dark>save</v-icon>
                      {{ $t('generic.save') }}
                    </v-btn>
                  </v-col>-->
                </v-row>
              </v-container>

              <v-container fluid v-if="searchSport != -1 && searchProducer != -1 && searchType == 'groupOdds'">
                <v-row align="center">

                  <v-col cols="12">

                    <v-tabs vertical class="simple-border">

                      <v-tab v-for="(group, index) in dataGroups.specifiers" class="tab-group-left" :key="group._id">
                        <v-icon left>
                          mdi-view-dashboard
                        </v-icon>
                        {{ getNameTranslated(group.name) }}
                      </v-tab>

                      <v-tab-item v-for="(group_content, index_content) in dataGroups.specifiers" :key="index_content">
                        <v-card flat>
                          <v-form>
                            <v-container>

                              <v-row justify="end" dense>
                                <v-btn color="blue-grey" class="white--text text-right" @click="updateGroupSingle(group_content._id)">
                                  <v-icon left dark>save</v-icon>
                                  {{ $t('generic.save') }}
                                </v-btn>

                                <v-btn color="red" class="white--text" @click="removeFromGroup(index_content, group_content._id)"
                                       style="margin-left: 10px;">
                                  <v-icon left dark>delete</v-icon>
                                  {{ $t('generic.delete') }}
                                </v-btn>
                              </v-row>

                              <v-row justify="start" dense>
                                <v-col cols="12"><h5>Ordinamento</h5></v-col>
                                <v-col cols="3">
                                  <vue-numeric-input v-model="group_content.sort" controlsType="updown"></vue-numeric-input>
                                </v-col>
                              </v-row>

                              <v-row justify="center" dense>
                                <v-col cols="12"><h5>Nome del gruppo</h5></v-col>
                                <v-col cols="3" v-for="(name,index) in group_content.name" :key="index">
                                  <v-text-field
                                          :label="name.lang"
                                          :placeholder="name.lang"
                                          v-model="name.text"
                                          outlined
                                          dense
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row justify="center" dense>
                                <v-col cols="6">
                                  <v-btn small color="primary" class="white--text text-right"
                                         @click="insertAll(index_content)">
                                    <v-icon left dark>add_circle</v-icon>
                                    {{$t('generic.add')}} {{$t('generic.all')}}
                                  </v-btn>
                                </v-col>
                                <v-col cols="6" class="text-right">
                                  <h5>Market Associati</h5>
                                </v-col>
                                <v-col cols="6">
                                  <v-data-table
                                          :headers="headers"
                                          :items="getMarkets.markets"
                                          class="elevation-1"
                                          :items-per-page="10"
                                          :search="searchGroup1"
                                          item-key="unique_id"
                                  >
                                    <template v-slot:top>
                                      <v-text-field
                                              v-model="searchGroup1"
                                              :label="$t('generic.search')"
                                              class="mx-4"
                                      ></v-text-field>
                                    </template>


                                    <template v-slot:item.actions="{ item }">
                                      <div v-if="!alreadyAdded(item.unique_id,index_content)">
                                        <v-icon @click="addMarket(item,index_content)">mdi-plus-box</v-icon>
                                      </div>
                                    </template>

                                  </v-data-table>
                                </v-col>
                                <v-col cols="6">
                                  <v-data-table
                                          :headers="headers"
                                          :items="group_content.markets"
                                          class="elevation-1"
                                          :items-per-page="10"
                                          :search="searchGroup2"
                                          item-key="unique_id"
                                  >
                                    <template v-slot:top>
                                      <v-text-field
                                              v-model="searchGroup2"
                                              :label="$t('generic.search')"
                                              class="mx-4"
                                      ></v-text-field>
                                    </template>

                                    <template v-slot:item.actions="{ item }">
                                      <v-icon @click="removeMarket(item.unique_id,index_content)">mdi-close-circle
                                      </v-icon>
                                      <v-icon @click="sortMarket(item.unique_id,index_content, 'up')"
                                              v-if="item.sort > 1">mdi-chevron-double-up
                                      </v-icon>
                                      <v-icon @click="sortMarket(item.unique_id,index_content, 'down')"
                                              v-if="item.sort < group_content.markets.length">
                                        mdi-chevron-double-down
                                      </v-icon>
                                    </template>

                                  </v-data-table>
                                </v-col>
                              </v-row>


                            </v-container>
                          </v-form>

                        </v-card>
                      </v-tab-item>

                      <v-tab class="tab-group-left" v-if="showAdd">
                        <v-icon left>
                          mdi-plus
                        </v-icon>
                        Nuovo Gruppo
                      </v-tab>

                      <v-tab-item v-if="showAdd">
                        <v-card flat>
                          <v-form>
                            <v-container>
                              <v-row justify="center">
                                <v-col cols="12" sm="12" md="8">

                                  <v-text-field label="Nome Gruppo" placeholder="ad es. Popolari"
                                                v-model="newGroupName">
                                  </v-text-field>

                                </v-col>
                                <v-col cols="12" sm="12" md="8" class="text-right">

                                  <v-btn color="blue-grey" class="white--text" @click="createNewGroup()">
                                    Salva
                                    <v-icon right dark>save</v-icon>
                                  </v-btn>

                                </v-col>

                              </v-row>
                            </v-container>
                          </v-form>
                        </v-card>
                      </v-tab-item>
                    </v-tabs>
                  </v-col>
                </v-row>
              </v-container>

              <v-container fluid v-if="searchSport != -1 && searchProducer != -1 && searchType == 'previewOdds'">
                <v-row>
                  <v-col cols="6">
                    <v-card>


                      <v-data-table
                              :headers="headers"
                              :items="getMarkets.markets"
                              class="elevation-1"
                              :items-per-page="30"
                              :search="search"
                              item-key="unique_id"
                      >
                        <template v-slot:top>
                          <v-text-field
                                  v-model="search"
                                  :label="$t('generic.search')"
                                  class="mx-4"
                          ></v-text-field>
                        </template>

                        <template v-slot:item.name="{ item }">
                          <span v-if="getIfActiveMarkets(item.unique_id)">
                            <v-badge dot inline left color="green"></v-badge>
                          </span>
                          {{ item.name }}
                        </template>

                        <template v-slot:item.actions="{ item }">

                          <v-icon @click="editMarket(item)">mdi-magnify</v-icon>
                        </template>

                      </v-data-table>
                    </v-card>

                  </v-col>
                  <v-col cols="6">
                    <v-card>
                      <v-card-title>
                        <span class="market-name-box">{{ this.marketToEdit.name }}</span>
                        <!--<span class="market-position">
                          <vue-numeric-input v-model="marketToEdit.sort" controlsType="updown"></vue-numeric-input>
                        </span>--></v-card-title>
                      <hr>
                      <!--<v-row>
                        <v-col cols="md-auto" v-for="(outcome, i) in this.marketToEdit.outcomes" :key="i">
                          <div class="outcome-box">
                            <v-checkbox
                                :label="`${outcome.complete}`"
                                v-model="outcome.active"
                            ></v-checkbox>
                            <vue-numeric-input v-model="outcome.sort" controlsType="updown"></vue-numeric-input>
                          </div>
                        </v-col>
                      </v-row>-->
                      <v-row v-for="item in this.marketToEditArray">

                        <v-col cols="md-auto" v-for="col in item" >
                          <div class="outcome-box" style="border: 0">

                            <v-checkbox
                                    :label="`${col.show} ${col.specifiers}`"
                                    v-model="col.active"
                            ></v-checkbox>
                          </div>
                        </v-col>
                        <v-col cols="md-auto" >
                          <div><vue-numeric-input v-model="item[0].sort" controlsType="updown"></vue-numeric-input></div>


                          <v-btn color="blue-grey" class="white--text text-right mt-2" @click="updateGroupSinglePreview(item)">
                            <v-icon left dark>save</v-icon>
                            {{ $t('generic.save') }}
                          </v-btn>


                        </v-col>
                      </v-row>
                      <!--<v-row v-for="col in this.marketToEditArray">

                        <v-col cols="md-auto" >
                          <div class="outcome-box">

                            <v-checkbox
                                    :label="`${col.name} ${col.specifiers}`"
                                    v-model="col.active"
                            ></v-checkbox>
                            <vue-numeric-input v-model="col.sort" controlsType="updown"></vue-numeric-input>
                          </div>
                        </v-col>
                        <v-col cols="md-auto" >


                            <v-btn color="blue-grey" class="white&#45;&#45;text text-right" @click="updateGroupSinglePreview(col)">
                              <v-icon left dark>save</v-icon>
                              {{ $t('generic.save') }}
                            </v-btn>


                        </v-col>
                      </v-row>-->

                    </v-card>
                  </v-col>
                </v-row>

                <!--                <v-expansion-panels>-->
                <!--                  <v-expansion-panel-->
                <!--                      v-for="(market,i) in getMarkets.markets"-->
                <!--                      :key="i"-->
                <!--                      v-if="market.outcomes.length > 0"-->
                <!--                  >-->
                <!--                    <v-expansion-panel-header>-->
                <!--                    <span class="font-weight-bold">-->
                <!--                      <span v-if="getIfActiveMarkets(dataGroups.specifiers, market.id)">-->
                <!--                        <v-badge dot inline left color="green"></v-badge>-->
                <!--                      </span> {{ market.name }}</span>-->
                <!--                    </v-expansion-panel-header>-->
                <!--                    <v-expansion-panel-content>-->
                <!--                      <v-row>-->
                <!--                        <v-col cols="md-auto" v-for="(outcome, i) in market.outcomes" :key="i">-->
                <!--                          <v-checkbox-->
                <!--                              :input-value="getIfActiveOutcomes(dataGroups.specifiers, market.id, outcome.id)"-->
                <!--                              :label="`${outcome.name}`"-->
                <!--                              @change="updateOutcome(market.id, outcome.id, getIfActiveOutcomes(dataGroups.specifiers, market.id, outcome.id))"-->
                <!--                          ></v-checkbox>-->
                <!--                        </v-col>-->
                <!--                      </v-row>-->
                <!--                    </v-expansion-panel-content>-->
                <!--                  </v-expansion-panel>-->
                <!--                </v-expansion-panels>-->
              </v-container>
            </v-card>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<style>

</style>
<script>
  import axios from 'axios'
  import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
  import _ from 'lodash'
  import menuSport from "@/components/sports/menu";

  export default {
    name: "OddsGroup",
    data() {
      return {
        headers: [
          {text: this.$t('generic.id'), value: 'unique_id'},
          {text: this.$t('generic.name'), value: 'name'},
          {text: this.$t('generic.actions'), value: 'actions'},
        ],
        search: '',
        searchGroup1: '',
        searchGroup2: '',
        searchSport: -1,
        searchProducer: -1,
        searchType: '',
        // searchSport: "60703069a936cb5d3422bb59",
        // searchProducer: 0,
        // searchType: 'groupOdds',
        newGroupName: '',
        type: [
          {text: "Gruppi di Quote", value: "groupOdds"},
          {text: "Preview", value: "previewOdds"}
        ],
        dataGroups: {},
        dataPreview: [],
        showAdd: false,
        marketToEdit: {},
        marketToEditArray:[],
        listPlatform:[],
        duplicateId:'',
        textduplice:''

      }
    },

    watch: {
      getLanguage: async function (val) {
        await this.setPreload(true);
        await this.setLanguage(val);
        await this.filterSport({'language': val});
        await this.setPreload(false);
      },

      searchSport() {
        this.getData()
      },

      searchProducer() {
        this.getData()
      },

      searchType() {
        this.getData()
      }

    },

    computed: {
      ...mapGetters({
        'getToken': 'profile/getToken',
        'getUrl': 'settings/getUrl',
        'getLanguage': 'profile/getLanguage',
        'getSports': 'sport/getSports',
        'getItemsSport': 'sport/getItems',
        'getMarkets': 'utils/getMarkets',
        'getSettingsGroupsMarket': 'utils/getSettingsGroupsMarket',
        'getProducers': 'producers/getList',
        'getPlatformId': 'rules/getPlatformId',
        'getSkins': 'skins/getList',
        'getPlatform': "profile/getPlatform",
      }),

    },
    async created() {
      await this.setPreload(true);
      await this.fetchSports({'language': this.getLanguage});
      if (this.searchProducer != -1 && this.searchSport != -1 && this.searchType != '') {
        await this.getSettingsGroup();
      }
      await this.setPreload(false);

      if(_.size(this.getSkins)>0){
        this.listPlatform = _.filter(this.getSkins,(el)=>{
          return el.platform_id!=0
        })
      }
      //console.log("this.listPlatform",this.listPlatform)
    },


    methods: {

      ...mapActions({
        'fetchSports': 'sport/fetchSports',
        'filterSport': 'sport/filterSport',
        'fetchProducers': 'producers/fetchProducers',
        'getMarketsBySport': 'utils/getMarketsBySport',
        'setPreload': 'utils/setPreload',
        'fetchSettingsGroupsMarket': 'utils/fetchSettingsGroupsMarket',
        'createNewGroupOdds': 'utils/createNewGroupOdds',
        'updateGroupOdds': 'utils/updateGroupOdds',
        'updateGroupOddsSingle': 'utils/updateGroupOddsSingle',
        'updatePreviewOddsSingle': 'utils/updatePreviewOddsSingle',
        'deleteSpecifierFromGroup': 'utils/deleteSpecifierFromGroup',
        'refactoringSettingsOdds': 'utils/refactoringSettingsOdds',
        'cacheMarkets': 'cache/cacheMarket',
        'cacheSetting': 'cache/cacheSetting',
        'copySetting': 'settings/copySetting',


      }),

      ...mapMutations({}),

      getData() {
        if (this.searchProducer != -1 && this.searchSport != -1 && this.searchType != '') {
          this.dataGroups = {}
          this.getSettingsGroup();
        }
      },
      async clearcache(){
        await this.setPreload(true);
        //await this.cacheMarkets()
        await this.cacheSetting()
        await this.setPreload(false);

      },
      async copysetting(){
        await this.setPreload(true);
        //await this.cacheMarkets()
        this.textduplice=''
        if(!this.duplicateId){
          this.textduplice='Select platform'
          return
        }

        await this.copySetting({id:this.duplicateId})
        await this.setPreload(false);

      },



      async getSettingsGroup() {
        await this.setPreload(true);

        await this.getMarketsBySport({
          'sport': this.searchSport,
          'lang': this.getLanguage,
          'enableSpecifiers': false
        });
        //(this.searchType != "previewOdds")
        await this.fetchSettingsGroupsMarket({
          'sport': this.searchSport,
          "product_id": this.searchProducer,
          'lang': this.getLanguage,
          'type': this.searchType,
          'platform': this.getPlatformId,
        });

        if (this.searchType == "previewOdds") {

          if (this.getSettingsGroupsMarket.specifiers != undefined && this.getSettingsGroupsMarket.specifiers.length > 0) {

            this.dataGroups = this.getSettingsGroupsMarket;

          } else {

            let newGroup = {
              platform: this.getPlatformId,
              section: "sport",
              source: this.searchSport,
              type: this.searchType,
              specifiers: []
            }
            this.dataGroups = newGroup

          }

        } else {

          // _.isObject(this.getSettingsGroupsMarket) && this.getSettingsGroupsMarket.specifiers.length > 0
          if (this.getSettingsGroupsMarket == []) {

            let newGroup = {
              platform: this.getPlatformId,
              section: "sport",
              source: this.searchSport,
              type: this.searchType,
              specifiers: []
            }
            this.dataGroups = newGroup

          } else {


            _.forEach(this.getSettingsGroupsMarket.specifiers, (group) => {
              var sort = 1;
              _.forEach(group.markets, (mark) => {
                mark.sort = sort
                sort++
              })
            })

            this.dataGroups = this.getSettingsGroupsMarket;


          }


        }

        this.showAdd = true;
        await this.setPreload(false);
      },

      async removeFromGroup(id,group) {
        var id_setting = this.dataGroups._id
        await this.setPreload(true);

        await this.deleteSpecifierFromGroup({group_id: group,
          setting_id: id_setting});
        await this.setPreload(false);
        //console.log(this.dataGroups.specifiers[key])
        await this.getSettingsGroup();



        this.dataGroups.specifiers.splice(id,1)

      },

      getNameTranslated(collection) {
        return _.find(collection, {"lang": this.getLanguage}).text;
      },

      getIfActiveMarkets(id) {
        //var market =_.split(id, '#');
        //var market_to_find = _.find(this.dataGroups.specifiers, {"unique_id": id.toString()})
        var market_to_find = _.find(this.dataGroups.specifiers, function(o) {
          return (new RegExp (id.toString()).test( o.unique_id ));
        });
        if (market_to_find && _.has(market_to_find, 'outcomes') && market_to_find.outcomes.length > 0) {
          return true
        } else {
          return false
        }
      },


      getIfActiveOutcomes(marketId, idOutcome) {

        var market = _.find(this.dataGroups.specifiers, {"id": marketId})
        if (market) {
          return !!_.find(market.outcomes, {"id": idOutcome});
        } else {
          return false
        }
      },

      updateOutcome(marketId, idOutcome, prevValue) {

        var market = _.find(this.dataGroups.specifiers, {"id": marketId});

        if (prevValue) {
          //Remove Outcome
          _.remove(market.outcomes, function (outcome) {
            return outcome.id == idOutcome;
          });

        } else {

          //Add Outcome
          if (market) {

            let newOutcome = {
              id: idOutcome,
              sort: market.outcomes.length + 1
            }

            market.outcomes.push(newOutcome);

          } else {

            let newOutcome = {
              id: idOutcome,
              sort: 1
            }

            let newMarket = {
              id: marketId,
              sort: this.dataGroups.specifiers.length + 1,
              outcomes: [
                newOutcome
              ]
            }

            this.dataGroups.specifiers.push(newMarket)

          }


        }
      },

      async createNewGroup() {
        await this.setPreload(true);
        var payload = {
          'section': "sport",
          'sport': this.searchSport,
          'platform': this.getPlatformId,
          "type": this.searchType,
          "product_id": this.searchProducer,
          "name": this.newGroupName
        }
        await this.createNewGroupOdds(payload);
        // await this.refactoringSettingsOdds({data: this.dataGroups, producer: this.searchProducer, type: this.searchType});
        await this.getSettingsGroup();
        this.newGroupName = '';
      },

      async updateGroup() {
        await this.setPreload(true);

        if (this.searchType == 'previewOdds') {
          var marketToUpdate = _.find(this.dataGroups.specifiers, {"unique_id": this.marketToEdit.unique_id})
          if (marketToUpdate) {
            Object.assign(marketToUpdate, this.marketToEdit);
          } else {
            this.dataGroups.specifiers.push(this.marketToEdit)
          }
        } else {

        }

        await this.updateGroupOdds({data: this.dataGroups, producer: this.searchProducer});
        // await this.refactoringSettingsOdds({data: this.dataGroups, producer: this.searchProducer, type: this.searchType});
        await this.getSettingsGroup();
        await this.setPreload(false);
      },
      async updateGroupSinglePreview(item){

        await this.setPreload(true);
        await this.updatePreviewOddsSingle({data: item,
          platform: this.dataGroups.platform,
          product_id: this.searchProducer,
          section: this.dataGroups.section,
          source: this.dataGroups.source,
          type: this.dataGroups.type});
        await this.setPreload(false);
        //console.log(this.dataGroups.specifiers[key])
        await this.getSettingsGroup();

      },
      async updateGroupSingle(key){
        //console.log(key)

        //console.log(this.dataGroups)
        await this.setPreload(true);
        if(this.searchType="groupOdds"){
          var specifierUpdate = _.find(this.dataGroups.specifiers, {"_id": key})
          await this.updateGroupOddsSingle({data: specifierUpdate,
            platform: this.dataGroups.platform,
            product_id: this.dataGroups.product_id,
            section: this.dataGroups.section,
            source: this.dataGroups.source,
            type: this.dataGroups.type,
            key:key});
          await this.setPreload(false);
          //console.log(this.dataGroups.specifiers[key])
          await this.getSettingsGroup();
        }
      },

      insertAll(index) {
        var group = this.dataGroups.specifiers[index]
        group.markets = this.getMarkets.markets
      },

      editMarket(item) {
        //console.log(item)
        this.marketToEdit = {...item}

        item.values =item.values.sort(function(a, b){return Number(a.value)-Number(b.value)});
        if(_.size(item.values)>0){

          var groupOdds= []
          _.forEach(item.values,val =>{
            var arrayElements= []
            _.forEach(item.outcomes,out =>{
              _.assignIn(out,{active:true})

              var checkPresent = _.find(this.dataGroups.specifiers,{unique_id:`${this.marketToEdit.unique_id}#${val.value}`})

              var chekmini
              if(checkPresent){

                chekmini = _.find(checkPresent.outcomes,{id:+out.id})

              }
              arrayElements.push({name:`${item.name}`,
                show:out.name,
                key:out.id,
                detail:this.marketToEdit.detail,
                outcomes:this.marketToEdit.outcomes,
                id:this.marketToEdit.id,
                specifiers:val.value,
                unique_id:`${this.marketToEdit.unique_id}#${val.value}`,
                sort:(chekmini)?chekmini.sort:0,
                active:(chekmini)?true:false})

            })
            groupOdds.push(arrayElements)
          })
          //console.log(groupOdds)
          this.marketToEditArray=groupOdds
        }else{
          var arrayElements= []
          var groupOdds=[];
          _.forEach(item.outcomes,val =>{
            //console.log(val)
            //console.log("dtagroup",this.dataGroups.specifiers);
            _.assignIn(val,{active:true})
            var checkPresent = _.find(this.dataGroups.specifiers,{outcomes:[{id:+val.id}]})
            //console.log("checkPresent",checkPresent)
            arrayElements.push({name:`${val.name}`,
              show:`${val.name}`,
              key:val.id,
              detail:this.marketToEdit.detail,
              outcomes:this.marketToEdit.outcomes,
              id:this.marketToEdit.id,
              specifiers:"",
              unique_id:`${this.marketToEdit.unique_id}`,
              sort:(checkPresent)?checkPresent.sort:0,
              active:(checkPresent)?true:false})

          })
          groupOdds.push(arrayElements)

          this.marketToEditArray=groupOdds
          //console.log(this.marketToEditArray)
        }
        /*var arrayMulti = [];
        this.marketToEdit.unique_id = this.marketToEdit.unique_id.toString()
        var market = _.find(this.dataGroups.specifiers, {"unique_id": this.marketToEdit.unique_id.toString()})
        if (market) {

          this.marketToEdit.sort = (market.sort != undefined) ? market.sort : 0

          _.forEach(this.marketToEdit.outcomes, (out) => {

            var outcomeToFind = _.find(market.outcomes, {'id': out.id})
            if (outcomeToFind) {
              out.sort = outcomeToFind.sort
              out.active = outcomeToFind.active
            }

          })

        } else {

          this.marketToEdit.sort = 0

          _.forEach(this.marketToEdit.outcomes, (out, index) => {
            out.active = false
            out.sort = index
          })
        }
        this.marketToEdit.outcomes.sort((a, b) => a.sort - b.sort)*/
      },


      alreadyAdded(unique_id, index_content) {
        var check = _.find(this.dataGroups.specifiers[index_content].markets, (mark) => {
          return mark.unique_id.toString() == unique_id.toString()
        })
        return (check != undefined)
      },

      addMarket(item, index_content) {
        var lastIdx = this.dataGroups.specifiers[index_content].markets.length +1;
        item.sort = (lastIdx == 1) ? 1 : lastIdx+1
        item.unique_id = item.unique_id.toString()
        this.dataGroups.specifiers[index_content].markets.push(item)
      },

      removeMarket(unique_id, index_content) {
        var vue = this
        var index = _.findIndex(this.dataGroups.specifiers[index_content].markets, function (o) {
          return o.unique_id.toString() == unique_id.toString();
        });
        if (index > -1) {
          this.dataGroups.specifiers[index_content].markets.splice(index, 1)
        }

        var sort = 1;
        _.forEach(this.getSettingsGroupsMarket.specifiers[index_content].markets, (mark) => {
          mark.sort = sort
          sort++
        })

      },

      sortMarket(unique_id, index_content, mode) {

        var marketsArray = this.dataGroups.specifiers[index_content].markets;

        var index = _.findIndex(marketsArray, function(o) { return o.unique_id.toString() == unique_id.toString(); });

        if(mode == 'up' && index > -1){

          var tempMarket = {... marketsArray[index-1]}
          tempMarket.sort++
          var actualMarket = {... marketsArray[index]}
          actualMarket.sort--

          marketsArray[index-1] = actualMarket
          marketsArray[index] = tempMarket

        }else if(mode == 'down' && index > -1){

          var tempMarket = {... marketsArray[index+1]}
          tempMarket.sort--
          var actualMarket = {... marketsArray[index]}
          actualMarket.sort++

          marketsArray[index+1] = actualMarket
          marketsArray[index] = tempMarket

        }

        this.dataGroups.specifiers[index_content].markets = [...marketsArray]


      }

    },
  }
</script>

<style scoped>

</style>
