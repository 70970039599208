<template>

  <div>
    <v-container fluid fill-height>
      <v-layout justify-center align-center>
        <v-card width="100%" outlined elevation-0>
          <v-card-title>
            History rules
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="search" label="Cerca.."></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="getHistory" :search="search" item-key="_id" class="elevation-0">

            <template v-slot:body="{ items }">
              <tbody>
              <tr>
                <td></td>
                <td><!--<v-select
                        :items="getPlatform"
                        label=""
                        hide-details
                        clearable
                        @change="chooseFilter($event,'platform')"

                ></v-select>-->
                  <v-autocomplete
                          :items="getPlatform"
                          label=""
                          @change="chooseFilter($event,'platform')"
                          clearable
                  ></v-autocomplete>
                </td>
                <td><!--<v-select
                        :items="getEmail"
                        label=""
                        hide-details
                        clearable

                        @change="chooseFilter($event,'email')"

                ></v-select>-->
                  <v-autocomplete
                          :items="getEmail"
                          label=""
                          clearable
                          @change="chooseFilter($event,'email')"
                  ></v-autocomplete>
                </td>
                <td></td>
                <td><!--<v-select
                        :items="getScope"
                        label=""
                        hide-details
                        clearable
                        @change="chooseFilter($event,'scope')"

                ></v-select>-->
                  <v-autocomplete

                          :items="getScope"
                          clearable

                          label=""
                          @change="chooseFilter($event,'scope')"
                  ></v-autocomplete>
                </td>
                <td><!--<v-select
                        :items="getSource"
                        label=""
                        hide-details
                        clearable
                        @change="chooseFilter($event,'source')"

                ></v-select>-->
                  <v-autocomplete

                          :items="getSource"
                          clearable

                          label=""
                          @change="chooseFilter($event,'source')"
                  ></v-autocomplete>
                </td>
                <td><!--<v-select
                        :items="getType"
                        label=""
                        hide-details
                        clearable
                        @change="chooseFilter($event,'type')"

                ></v-select>-->
                  <v-autocomplete

                          :items="getType"

                          clearable
                          label=""
                          @change="chooseFilter($event,'type')"
                  ></v-autocomplete>
                </td>
                <td></td>
              </tr>
              <tr v-for="item in items" :key="item._id">
                <td>{{ item.date }}</td>
                <td>{{ item.platform }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.scope }}</td>
                <td>{{ item.source }}</td>
                <td>{{ item.type }}</td>
                <td>
                  <json-viewer
                          :value="item.detail"
                          :expand-depth=5
                          copyable
                          boxed
                          sort></json-viewer>
                </td>


              </tr>
              </tbody>
            </template>

          </v-data-table>
        </v-card>

        <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :vertical="snackbar.mode === 'vertical'">
          {{ snackbar.text }}
          <v-btn dark text @click="snackbar.snackbar = false">
            Chiudi
          </v-btn>
        </v-snackbar>


      </v-layout>
    </v-container>


  </div>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

export default {
  name: "Skins",
  data() {
    return {

      search: '',
      statuses: [
        {'text':'Attivo', value: true},
        {'text':'Non Attivo', value: false}
      ],
      pagination: {
        sortBy: 'date',
        descending: true,
        rowsPerPage: 10
      },
      headers: [
        {text: 'Data', value: 'date'},
        {text: 'Platform', value: 'platform'},
        {text: 'Email', value: 'email'},
        {text: 'Name', value: 'name'},
        {text: 'Scope', value: 'scope'},
        {text: 'Source', value: 'source'},
        {text: 'Type', value: 'type'},
        {text: 'Detail', value: 'detail'}
      ],
      getHistory:[],
      getPlatform:[],
      getEmail:[],
      getScope:[],
      getSource:[],
      getType:[],
      filters:[],
      dataEntry:[],

      snackbar:{
        snackbar: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
      },

      titleDialog: '',

      dialog: false,
      dialogConfirm: false,

      idSkin: -1,
      actionForm : '',

      form: {
        name: '',
        website: '',
        active: ''
      },

      deleteitem: '',

      show1: false,
      messages: {
        name: [],
        website: [],
        active: []
      },
    }
  },
  created() {
    axios.get(`${this.getUrl}history`,
            {
              'headers': {'Authorization': `Bearer ${this.getToken}`},
              "Content-Type": "application/json"
            })

            .then(res => {
              //console.log(res.data);
              this.getHistory=res.data.data
              this.dataEntry=res.data.data
              this.getPlatform=_.map(res.data.data,'platform')
              this.getEmail=_.map(res.data.data,'email')
              this.getScope=_.map(res.data.data,'scope')
              this.getSource=_.map(res.data.data,'source')
              this.getType=_.map(res.data.data,'type')

              //resolve(res);
            })
            .catch(err => {
              console.log("errore", err);
              /*if (err.response.status === 401) {

                  //this.setLogout();
                  context.commit('profile/setLogout', {}, {root: true})
                  this.$router.push('/login')
              }
              throw Error(err.response.status + ":Posts Not Found");*/
            });
  },
  computed: {
    ...mapGetters({
      'getAutenticate': 'profile/getAutenticate',
      'getToken': 'profile/getToken',
      'getUrl': 'settings/getUrl',
      'getPreload': 'utils/getPreload',
      'getSkins': 'skins/getList',
    }),
  },
  methods: {
    ...mapMutations({

    }),
    ...mapActions({
      'setPreload': 'utils/setPreload',

    }),
    chooseFilter(event,value){
      console.log("event",event)
      console.log("value",value)
      this.getHistory= this.dataEntry
      var check = _.find(this.filters,filter =>{
        return filter.key == value
      })
      if(!check){

        this.filters.push({key:value,value:event})
      }else{
        if(!event){
          this.filters = _.filter(this.filters,filter=>{
            return filter.key!=value
          })
        }else{
          _.assign(check,{value:event})
        }
      }

      var platform = _.find(this.filters,{key:"platform"});
      if(platform){
        this.getHistory = _.filter(this.getHistory,history=>{
              return history.platform==platform.value
        });
      }

      var email = _.find(this.filters,{key:"email"});
      if(email){
        this.getHistory = _.filter(this.getHistory,history=>{
          return history.email==email.value
        });
      }

      var scope = _.find(this.filters,{key:"scope"});
      if(scope){
        this.getHistory = _.filter(this.getHistory,history=>{
          return history.scope==scope.value
        });
      }

      var source = _.find(this.filters,{key:"source"});
      if(source){
        this.getHistory = _.filter(this.getHistory,history=>{
          return history.source==source.value
        });
      }

      var type = _.find(this.filters,{key:"type"});
      if(type){
        this.getHistory = _.filter(this.getHistory,history=>{
          return history.type==type.value
        });
      }

      console.log("filters",this.filters)
      console.log("history",this.getHistory)





    }








  }
}
</script>

<style scoped>

</style>
