<template>
  <div>
      <v-container fluid>
          <v-layout row>

            <v-flex md12>
              <menuBetConsole></menuBetConsole>
            </v-flex>

            <v-flex md12>
              <v-col cols="12">
                <v-card outlined elevation-0>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="2">
                        <v-autocomplete
                            v-model="searchSport"
                            :items="getItemsSport"
                            item-text="name"
                            item-value="source"
                            outlined
                            dense
                            chips
                            small-chips
                            label="Sport"
                            deletable-chips
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2">
                        <v-autocomplete
                            v-model="searchProducer"
                            :items="getProducers"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                            chips
                            small-chips
                            label="Producer"
                            deletable-chips
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2">
                        <v-select
                            v-model="searchType"
                            :items="type"
                            item-value="value"
                            item-text="text"
                            label="Tipo"
                            outlined
                            dense
                        ></v-select>
                      </v-col>
                      <v-col cols="4" v-if="searchSport != 0 && searchProducer != 0 && searchType != 0 ">
                        <v-btn color="blue-grey" class="white--text text-right"  @click="updateGroup()">
                          <v-icon left dark>save</v-icon>
                          Salva tutto
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-container fluid v-if="searchSport != 0 && searchProducer != 0 && searchType == 'groupOdds'">
                    <v-row align="center">

                      <v-col cols="12">

                        <v-tabs vertical class="simple-border">

                          <v-tab v-for="(group, index) in dataGroups" class="tab-group-left" :key="group._id">
                            <v-icon left>
                              mdi-view-dashboard
                            </v-icon>
                            {{ getNameTranslated(group.name) }}
                          </v-tab>

                          <v-tab-item v-for="(group_content, index_content) in dataGroups" :key="group_content._id">
                            <v-card flat>
                              <v-form>
                                <v-container>
                                  <v-row justify="end" dense>
                                    <v-btn color="red" class="white--text"  @click="removeFromGroup(group_content._id)" style="margin-left: 10px;">
                                      <v-icon left dark>delete</v-icon>
                                      Elimina
                                    </v-btn>
                                  </v-row>

                                  <v-row justify="start" dense>
                                    <v-col cols="12"><h5>Ordinamento</h5></v-col>
                                    <v-col cols="3">
                                      <vue-numeric-input v-model="group_content.sort" controlsType="updown"></vue-numeric-input>
                                    </v-col>
                                  </v-row>

                                  <v-row justify="center" dense>
                                    <v-col cols="12"><h5>Nome del gruppo</h5></v-col>
                                    <v-col cols="3" v-for="(name,index) in group_content.name" :key="index">
                                      <v-text-field
                                          :label="name.lang"
                                          :placeholder="name.lang"
                                          v-model="name.text"
                                          outlined
                                          dense
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>

                                  <v-row justify="center" dense>
                                    <v-col cols="6"><h5>Market Associati</h5></v-col>
                                    <v-col cols="6" class="text-right">
                                      <v-btn small color="primary" class="white--text text-right"  @click="insertAll(group_content._id)">
                                      <v-icon left dark>add_circle</v-icon> {{$t('generic.add')}} {{$t('generic.all')}}
                                      </v-btn>
                                    </v-col>
                                    <v-combobox
                                        clearable
                                        hide-selected
                                        multiple
                                        persistent-hint
                                        small-chips
                                        deletable-chips
                                        solo
                                        :items="getMarkets.markets"
                                        item-text="name"
                                        item-value="unique_id"
                                        v-model="group_content.specifiers"
                                    ></v-combobox>
                                  </v-row>


                                </v-container>
                              </v-form>

                            </v-card>
                          </v-tab-item>

                          <v-tab class="tab-group-left" v-if="showAdd">
                            <v-icon left>
                              mdi-plus
                            </v-icon>
                            Nuovo Gruppo
                          </v-tab>

                          <v-tab-item  v-if="showAdd">
                            <v-card flat>
                              <v-form>
                                <v-container>
                                  <v-row justify="center">
                                    <v-col cols="12" sm="12" md="8">

                                      <v-text-field label="Nome Gruppo" placeholder="ad es. Popolari" v-model="newGroupName">
                                      </v-text-field>

                                    </v-col>
                                    <v-col cols="12" sm="12" md="8" class="text-right">

                                      <v-btn color="blue-grey" class="white--text" @click="createNewGroup()">
                                        Salva
                                        <v-icon right dark>save</v-icon>
                                      </v-btn>

                                    </v-col>

                                  </v-row>
                                </v-container>
                              </v-form>
                            </v-card>
                          </v-tab-item>
                        </v-tabs>
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-container fluid v-if="searchSport != 0 && searchProducer != 0 && searchType == 'previewOdds'">
                    <v-expansion-panels>
                      <v-expansion-panel
                          v-for="(market,i) in getMarkets.markets"
                          :key="i"
                      >
                        <v-expansion-panel-header>
                    <span class="font-weight-bold">
                      <span v-if="getIfActiveMarkets(dataGroups.specifiers, market.id)">
                        <v-badge dot inline left color="green"></v-badge>
                      </span> {{market.name}}</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row>
                            <v-col cols="md-auto"  v-for="(outcome, i) in market.outcomes" :key="i">
                              <v-checkbox
                                  :input-value="getIfActiveOutcomes(dataGroups.specifiers, market.id, outcome.id)"
                                  :label="`${outcome.name}`"
                                  @change="updateOutcome(market.id, outcome.id, getIfActiveOutcomes(dataGroups.specifiers, market.id, outcome.id))"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-container>
                </v-card>
              </v-col>
          </v-flex>
        </v-layout>
      </v-container>
  </div>
</template>
<style>

</style>
<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import _ from 'lodash'
import menuBetConsole from "@/components/betConsole/menu";

export default {
  name: "BetConsole",
  components: {menuBetConsole},
  data() {
    return {
      // searchSport: "sr:sport:1",
      searchSport: [0],
      // searchProducer: 3,
      searchProducer: [0],
      searchType: '',
      newGroupName: '',
      type: [
          {text:"Gruppi di Quote", value:"groupOdds"},
          {text:"Preview", value:"previewOdds"}
      ],
      dataGroups: {},
      dataPreview: [],
      showAdd: false
    }
  },

  watch: {
    getLanguage: async function (val) {
      await this.setPreload(true);
      await this.setLanguage(val);
      await this.filterSport({'language': val});
      await this.setPreload(false);
    },

    searchSport() {
      this.getData()
    },

    searchProducer() {
      this.getData()
    },

    searchType() {
      this.getData()
    }

  },

  computed: {
    ...mapGetters({
      'getToken': 'profile/getToken',
      'getUrl': 'settings/getUrl',
      'getLanguage': 'profile/getLanguage',
      'getSports': 'sport/getSports',
      'getItemsSport': 'sport/getItems',
      'getMarkets': 'utils/getMarkets',
      'getSettingsGroupsMarket': 'utils/getSettingsGroupsMarket',
      'getProducers': 'producers/getList',
      'getPlatformId': 'rules/getPlatformId',
    }),


  },
  async created() {
    await this.setPreload(true);
    await this.fetchSports({'language': this.getLanguage});
    await this.fetchProducers();
    if (this.searchProducer != 0 && this.searchSport != '' && this.searchType != '') {
      await this.getSettingsGroup();
    }
    await this.setPreload(false);
  },


  methods: {

    ...mapActions({
      'fetchSports': 'sport/fetchSports',
      'filterSport': 'sport/filterSport',
      'fetchProducers': 'producers/fetchProducers',
      'getMarketsBySport': 'utils/getMarketsBySport',
      'setPreload': 'utils/setPreload',
      'fetchSettingsGroupsMarket': 'utils/fetchSettingsGroupsMarket',
      'createNewGroupOdds': 'utils/createNewGroupOdds',
      'updateGroupOdds': 'utils/updateGroupOdds',
      'refactoringSettingsOdds': 'utils/refactoringSettingsOdds'

    }),

    ...mapMutations({}),

    getData(){
      if (this.searchSport != 0 && this.searchProducer != 0 && this.searchType != '') {
        this.dataGroups = {}
        this.getSettingsGroup();
      }
    },

    async getSettingsGroup() {
      await this.setPreload(true);
      await this.getMarketsBySport({
        'sport': this.searchSport,
        "product_id": this.searchProducer,
        'lang': this.getLanguage
      });
      await this.fetchSettingsGroupsMarket({
        'sport': this.searchSport,
        "product_id": this.searchProducer,
        'lang': this.getLanguage,
        'type': this.searchType,
        'platform': this.getPlatformId,
      });

      if(this.searchType == "previewOdds"){

        if(this.getSettingsGroupsMarket.specifiers != undefined && this.getSettingsGroupsMarket.specifiers.length > 0){

          this.dataGroups = this.getSettingsGroupsMarket;

        }else{

          let newGroup = {
            platform: this.getPlatformId,
            section: "sport",
            source: this.searchSport,
            type: this.searchType,
            specifiers: []
          }
          this.dataGroups = newGroup

        }

      }else{

        if(this.getSettingsGroupsMarket.length > 0){
          this.dataGroups = this.getSettingsGroupsMarket;
        }else{

          // let newGroup = {
          //   platform: 0,
          //   section: "sport",
          //   source: this.searchSport,
          //   type: this.searchType,
          //   specifiers: []
          // }
          // this.dataGroups = newGroup
        }


      }

      this.showAdd = true;
      await this.setPreload(false);
    },

    removeFromGroup(id){
      this.dataGroups = _.remove(this.dataGroups, function(group) {
        return group._id != id;
      });
    },

    getNameTranslated(collection) {
      return _.find(collection, {"lang": this.getLanguage}).text;
    },

    getIfActiveMarkets(collection,id) {
      var market_to_find = _.find(collection, {"id": id})
      if(market_to_find && _.has(market_to_find, 'outcomes') && market_to_find.outcomes.length > 0){
        return true
      }else{
        return false
      }
    },


    getIfActiveOutcomes(collectionMarket, marketId, idOutcome){

      var market = _.find(collectionMarket, {"id": marketId})
      if(market){
        return !!_.find(market.outcomes, {"id": idOutcome});
      }else{
        return false
      }
    },

    updateOutcome(marketId, idOutcome, prevValue){

      var market = _.find(this.dataGroups.specifiers, {"id": marketId});

      if(prevValue){
        //Remove Outcome
        _.remove(market.outcomes,  function(outcome) {
          return outcome.id == idOutcome;
        });

      }else{

        //Add Outcome
        if(market){

          let newOutcome = {
            id : idOutcome,
            sort: market.outcomes.length+1
          }

          market.outcomes.push(newOutcome);

        }else{

          let newOutcome = {
            id : idOutcome,
            sort: 1
          }

          let newMarket = {
            id: marketId,
            sort: this.dataGroups.specifiers.length+1,
            outcomes: [
              newOutcome
            ]
          }

          this.dataGroups.specifiers.push(newMarket)

        }


      }
    },

    async createNewGroup() {
      await this.setPreload(true);
      var payload = {
        'section': "sport",
        'sport': this.searchSport,
        'platform': this.getPlatformId,
        "type": this.searchType,
        "product_id": this.searchProducer,
        "name": this.newGroupName
      }
      await this.createNewGroupOdds(payload);
      // await this.refactoringSettingsOdds({data: this.dataGroups, producer: this.searchProducer, type: this.searchType});
      await this.getSettingsGroup();
      this.newGroupName = '';
    },

    async updateGroup(){
      await this.setPreload(true);
      await this.updateGroupOdds({data: this.dataGroups, producer: this.searchProducer});
      await this.refactoringSettingsOdds({data: this.dataGroups, producer: this.searchProducer, type: this.searchType});
      await this.getSettingsGroup();
      await this.setPreload(false);
    },

    insertAll(id){
      var group = _.find(this.dataGroups,{'_id': id})
      group.specifiers = this.getMarkets.markets
    }

  },
}
</script>

<style scoped>

</style>
