<template>


    <div>

            <v-container fluid fill-height>

                <v-layout justify-center align-center>
<!--                    <navigation ></navigation>-->
                    <toolbar ></toolbar>
                    <v-card width="100%" outlined elevation-0>
                        <v-card-title>
                            <v-breadcrumbs :items="breads" class="ma-0 pa-0" >
                                <template v-slot:item="{ item }">
                                    <v-breadcrumbs-item
                                            :to="item.to"
                                            :disabled="item.disabled"
                                    >
                                        {{ item.text.toUpperCase() }}
                                    </v-breadcrumbs-item>
                                </template>
                            </v-breadcrumbs>
                            <v-spacer></v-spacer>
                            <v-text-field
                                    v-model="search"
                                    append-icon="search"
                                    label="Search"

                            ></v-text-field>
                        </v-card-title>
                        <v-data-table
                                :headers="headers"
                                :items="getItems"
                                :search="search"
                                item-key="_id"
                                class="elevation-0"
                        >
                            <template  v-slot:body="{ items }">
                                <tbody>
                                <tr v-for="item in items" :key="item._id">
                                    <td>{{ item._id }} </td>
                                    <td>{{ item.id }} </td>

                                    <td>{{ item.name }} <v-icon size="15"
                                            class="mr-0"
                                            @click="editCategory(item)"
                                    >
                                        mdi-marker
                                    </v-icon>



                                    </td>
                                    <td><v-switch color="secondary" dense class="mb-0 mt-2"
                                                  v-model="item.active"
                                                  @change="changeState(item)"
                                    ></v-switch></td>
                                    <td width="120">

                                        <v-icon v-if="item.active"
                                                class="mr-0"
                                                to="/tournaments/`${item.id}`"
                                                @click="showtournament(item)"
                                        >
                                            mdi-card-search-outline
                                        </v-icon>
                                        <!--<v-icon
                                                class="mr-0"
                                                @click="editItem(item)"
                                        >
                                            mdi-briefcase-edit
                                        </v-icon>
                                        <v-icon
                                                class="mr-0"
                                                @click="deleteItem(item)"
                                        >
                                            mdi-trash-can
                                        </v-icon>-->


                                    </td>

                                </tr>
                                </tbody>
                            </template>




                        </v-data-table>
                    </v-card>
                    <v-snackbar
                            v-model="snackbar.snackbar"
                            :color="snackbar.color"
                            :multi-line="snackbar.mode === 'multi-line'"
                            :timeout="snackbar.timeout"
                            :vertical="snackbar.mode === 'vertical'"
                    >
                        {{ snackbar.text }}
                        <v-btn
                                dark
                                text
                                @click="snackbar = false"
                        >
                            Close
                        </v-btn>
                    </v-snackbar>
                    <!--<v-btn
                            fab
                            bottom
                            right
                            color="secondary"
                            dark
                            fixed
                            @click.prevent="openNew"
                    >

                        <v-icon>add</v-icon>
                    </v-btn>-->
                </v-layout>

            </v-container>

        <v-dialog persistent v-model="dialog" width="1000px">
            <v-card>
                <v-card-title
                        class="grey lighten-4 py-4 title"
                >
                    {{ titlelabel }}
                </v-card-title>
                <v-container grid-list-sm class="pa-4">
                    <v-layout row wrap>
                        <v-flex xs6>
                            <v-text-field

                                    label="Nome"
                                    v-model="form.name"
                                    :error-messages="messages.name"

                            ></v-text-field>
                        </v-flex>


                        <v-flex xs6 >
                            <v-text-field

                                    label="Email"
                                    v-model="form.email"
                                    :error-messages="messages.email"

                            ></v-text-field>
                        </v-flex>

                        <v-flex xs6 >
                            <v-text-field
                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    label="Password"
                                    v-model="form.password"
                                    :error-messages="messages.password"
                                    :type="show1 ? 'text' : 'password'"
                                    @click:append="show1 = !show1"

                            ></v-text-field>
                        </v-flex>

                    </v-layout>
                </v-container>
                <v-card-actions>

                    <v-btn color="primary"  @click="dialog = false">Annulla</v-btn>

                    <v-btn color="secondary" @click.prevent="confirm">Conferma</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialogedit" width="1000px">
            <v-card>
                <v-card-title
                        class="grey lighten-4 py-4 title"
                >
                    {{ titlelabel }}
                </v-card-title>
                <v-container grid-list-sm class="pa-4">
                    <v-layout row wrap>
                        <v-flex sm6>
                            <v-text-field

                                    label="Nome"
                                    v-model="form.name"
                                    :error-messages="messages.name"

                            ></v-text-field>
                        </v-flex>


                        <v-flex sm6 >
                            <v-text-field

                                    label="Email"
                                    v-model="form.email"
                                    :error-messages="messages.email"

                            ></v-text-field>
                        </v-flex>

                        <v-flex sm6 >
                            <v-text-field
                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    label="Password"
                                    v-model="form.password"
                                    :error-messages="messages.password"
                                    :type="show1 ? 'text' : 'password'"
                                    @click:append="show1 = !show1"

                            ></v-text-field>
                        </v-flex>

                    </v-layout>
                </v-container>
                <v-card-actions>

                    <v-btn color="primary"  @click="dialogedit = false">Annulla</v-btn>

                    <v-btn color="secondary" @click.prevent="update">Conferma</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialogeditsport" width="500px">
            <v-card>
                <v-card-title
                        class="grey lighten-4 py-4 title"
                >
                    Sport
                </v-card-title>
                <v-container grid-list-sm class="pa-4">
                    <v-layout row wrap>
                        <v-flex sm12>
                            <v-text-field

                                    label="Nome"
                                    v-model="form.name"
                                    :error-messages="messages.name"

                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-container>
                <v-card-actions>

                    <v-btn color="primary"  @click="dialogeditsport = false">Annulla</v-btn>

                    <v-btn color="secondary" @click.prevent="updatesport">Conferma</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialogeditcategory" width="500px">
            <v-card>
                <v-card-title
                        class="grey lighten-4 py-4 title"
                >
                    Category
                </v-card-title>
                <v-container grid-list-sm class="pa-4">
                    <v-layout row wrap>
                        <v-flex sm12>
                            <v-text-field

                                    label="Nome"
                                    v-model="form.name"
                                    :error-messages="messages.name"

                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-container>
                <v-card-actions>

                    <v-btn color="primary"  @click="dialogeditcategory = false">Annulla</v-btn>

                    <v-btn color="secondary" @click.prevent="updatecategory">Conferma</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
                v-model="dialogconfirm"
                max-width="290"
        >
            <v-card>
                <v-card-title class="headline">Seri sicuro?</v-card-title>



                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            color="primary"

                            @click="dialogconfirm = false"
                    >
                        Annulla
                    </v-btn>

                    <v-btn
                            color="secondary"

                            @click="confirmDelete"
                    >
                        Conferma
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import  axios from 'axios'
    import {mapGetters,mapMutations,mapState,mapActions} from 'vuex';
    //import router from "../../router";
    //console.log(router)
    export default {
        name: "Categories",

        data(){
            return {
                breads: [

                    {
                        text: 'sport',
                        disabled: false,
                        to: '/sports',
                        exact: true

                    },
                    {
                        text: '',
                        disabled: true,
                        href: '#',
                    },
                ],
                items:[],
                fetchCategories:[],
                show:{},
                title:'',
                //paramKey: this.$route.params.id,


                snackbar:{
                    snackbar: false,
                    color: '',
                    mode: '',
                    timeout: 6000,
                    text: '',
                },
                // ricerca
                search:'',
                pagination:{
                    sortBy: 'id',
                    descending: true,
                    rowsPerPage: 10
                },

                headers: [
                    {text: '_Id',value: '_id'},
                    {text: 'Id',value: 'id'},

                    { text: 'Nome', value: 'name' },
                    { text: 'Status', value: 'active' },
                    { text: 'Action', value:'',sortable:false }

                ],

                titlelabel:'',
                dialog: false,
                dialogedit: false,
                dialogeditsport: false,
                dialogeditcategory: false,

                preload: false,
                dialogconfirm: false,

                editIndex:'',
                deleteitem:'',
                form:{
                    _id:'',
                    id:'',
                    name:'',
                    lang:''
                },
                show1: false,
                messages: {
                    email: [],
                    name: [],
                    password: []

                },
            }
        },

        watch:{
            getLanguage: async function(val){
                this.preload = true;
                await this.setLanguage(val);
                await this.filterCategories({'language': val});
                this.preload = false;

                this.filterSport({'language': val});
                this.breads[1].text=this.getTitle;

            },
            paramKey: async function (val){
               // console.log(val)
                this.preload = true;
                await this.fetchCategorie({'paramKey': this.paramKey, 'language': this.getLanguage});
                await this.fetchSport({'paramKey': this.paramKey, 'language': this.getLanguage});
                this.importTournament({'paramKey': this.paramKey});
                this.breads[1].text=this.getTitle;
                this.preload = false;

            },
            /*'$route'(to,from){
                console.log(to.params.id);
                this.paramKey=to.params.id;
            }*/
        },

        computed:{
            ...mapGetters({
                'getAutenticate':'profile/getAutenticate',
                'getToken':'profile/getToken',
                'getUrl':'settings/getUrl',
                'getLanguage':'profile/getLanguage',
                'getCategories':'category/getCategories',
                'getItems':'category/getItems',
                'getTitle':'category/getTitle',

            }),

            paramKey:function() {

                if(this.$route.params.id) {
                    return this.$route.params.id;
                }

            },



        },
        async mounted  () {
            this.preload = true;
            await this.fetchCategorie({'paramKey': this.$route.params.id, 'language': this.getLanguage});
            await this.fetchSport({'paramKey': this.$route.params.id, 'language': this.getLanguage});
            //this.importTournament({'paramKey': this.$route.params.id});
            this.breads[1].text=this.getTitle;
            this.preload = false;
        },
        methods:{
            ...mapActions({
                'fetchCategorie':'category/fetchCategories',
                'filterCategories':'category/filterCategories',
                'filterSport':'category/filterSport',
                'fetchSport':'category/fetchSport',
                'importTournament':'category/importTournament',
                'actionCategoryActive': 'category/actionCategoryActive',
                'actionCategoryLive': 'category/actionCategoryLive',

            }),
            ...mapMutations({
                'setLanguage': 'profile/setLanguage',
                'setTitle': 'category/setTitle',
                'setSport': 'category/setSport',
                'setCategories': 'category/setCategories',
                'setPreload': 'settings/setPreload',


            }),
            changeState(item){
                //console.log(item);

                this.form.id=item.id;
                this.form.active=item.active;
                this.editIndex = this.getItems.indexOf(item);

                let url = "categories/updateactive";

                let payload = this.form;

                this.messages = [];

                axios.post(`${this.getUrl}${url}`,payload,{ 'headers': { 'Authorization': `Bearer ${this.getToken}`} })
                    .then( res => {


                        let pass= {'index':this.editIndex,'active':res.data.data.active};
                        this.actionCategoryActive(pass);

                        //this.getItems[this.editIndex].name= res.data.title;

                        //this.items.push(res.data)
                        this.snackbar.color="success";
                        this.snackbar.text= "Update effettuato con successo";
                        this.snackbar.snackbar = true;
                        this.snackbar.mode = 'multi-line';
                        this.snackbar.timeout=5000;
                    })
                    .catch(err => {

                        if(err.response) {
                            let errors = err.response.data.error;
                            if (errors) {
                                console.log(errors);
                                errors.forEach(value => {
                                    console.log(value.msg);
                                    this.messages[value.param] = value.msg
                                })

                            }


                            this.snackbar.color="error";
                            this.snackbar.text= err.message;
                            this.snackbar.snackbar = true;
                            this.snackbar.mode = 'multi-line';
                            this.snackbar.timeout=5000;
                            this.preload = false;
                            if(err.response.status===401){
                                this.setLogout();
                                this.$router.push('/login')
                            }
                        }

                    });
            },
            showtournament(item){
                this.$router.push(`/tournaments/${item.id}`)
            },
            editItem (item) {
                this.titlelabel = 'Edit User';
                this.resetForm();
                this.dialogedit = true;
                //console.log(item);
                this.editIndex = this.items.indexOf(item);
                this.form = item;
                this.form.password = '';
                //console.log(this.editIndex);
            },
            editSport () {
                this.form.name=this.getTitle;
                this.dialogeditsport = true;
            },
            editCategory (item) {
                //console.log(item);
                this.form.name=item.name;
                this.form.id=item.id;
                this.editIndex = this.getItems.indexOf(item);
                this.dialogeditcategory = true;
            },
            resetForm(){
                this.form._id='';
                this.form.name='';
                this.form.email='';
                this.form.password='';
                this.form.action='';


            },

            openNew() {
                this.form.action = 1;
                this.titlelabel = 'Nuovo utente';
                this.form = [];
                //this.resetForm();

                this.dialog = !this.dialog

            },
            confirm(){


                let url = "auth/register";
                let payload = this.form;
                this.preload = true;
                this.messages = [];
                axios.post(`${this.getUrl}${url}`,payload,{ 'headers': { 'Authorization': `Bearer ${this.getToken}`} })

                    .then( res => {
                        this.items = res.data.data;
                        this.preload = false;
                        this.dialog = false;

                        //this.items.push(res.data)
                        this.snackbar.color="success";
                        this.snackbar.text= "Utente inserito con  successo";
                        this.snackbar.snackbar = true;
                        this.snackbar.mode = 'multi-line';
                        this.snackbar.timeout=5000;
                        this.initialize();
                        this.resetForm();

                    })
                    .catch(err => {
                        if(err.response) {
                            let errors = err.response.data.error;
                            if (errors) {
                                console.log(errors);
                                errors.forEach(value => {
                                    console.log(value.msg);
                                    this.messages[value.param] = value.msg
                                })

                            }


                            this.snackbar.color="error";
                            this.snackbar.text= err.message;
                            this.snackbar.snackbar = true;
                            this.snackbar.mode = 'multi-line';
                            this.snackbar.timeout=5000;
                            this.preload = false;
                            if(err.response.status===401){
                                this.setLogout();
                                this.$router.push('/login')
                            }
                        }

                    });

            },
            update(){

                let url = "auth/update";
                let payload = this.form;
                this.preload = true;
                this.messages = [];

                axios.post(`${this.getUrl}${url}`,payload,{ 'headers': { 'Authorization': `Bearer ${this.getToken}`} })
                    .then( res => {

                        //this.items = res.data.data;
                        this.preload = false;
                        this.dialogedit = false;

                        //this.items.push(res.data)
                        this.snackbar.color="success";
                        this.snackbar.text= "Utente Modificato con  successo";
                        this.snackbar.snackbar = true;
                        this.snackbar.mode = 'multi-line';
                        this.snackbar.timeout=5000;
                        this.initialize();
                        this.resetForm();

                        //this.items[this.editIndex].quality={};

                        Object.assign(this.items[this.editIndex], res.data.data);
                    })
                    .catch(err => {

                        if(err.response) {
                            let errors = err.response.data.error;
                            if (errors) {
                                console.log(errors);
                                errors.forEach(value => {
                                    console.log(value.msg);
                                    this.messages[value.param] = value.msg
                                })

                            }


                            this.snackbar.color="error";
                            this.snackbar.text= err.message;
                            this.snackbar.snackbar = true;
                            this.snackbar.mode = 'multi-line';
                            this.snackbar.timeout=5000;
                            this.preload = false;
                            if(err.response.status===401){
                                this.setLogout();
                                this.$router.push('/login')
                            }
                        }

                    });
            },
            updatesport(){

                this.form.id=this.paramKey;
                this.form.lang=this.getLanguage;

                let url = "sports/update";

                let payload = this.form;
                this.preload = true;
                this.messages = [];

                axios.post(`${this.getUrl}${url}`,payload,{ 'headers': { 'Authorization': `Bearer ${this.getToken}`} })
                    .then( res => {

                        //this.items = res.data.data;
                       // console.log(res.data);
                        this.preload = false;
                        this.dialogeditsport = false;
                        //this.show = res.data.data;
                        //this.title = res.data.title;
                        //console.log(res.data.data);
                        this.setSport(res.data.data);
                        this.setTitle(res.data.title);

                        //this.items.push(res.data)
                        this.snackbar.color="success";
                        this.snackbar.text= "Update effettuato con successo";
                        this.snackbar.snackbar = true;
                        this.snackbar.mode = 'multi-line';
                        this.snackbar.timeout=5000;
                        //this.initialize();
                        //this.resetForm();

                        //this.items[this.editIndex].quality={};
                        //Object.assign(this.items[this.editIndex], res.data.data);
                    })
                    .catch(err => {

                        if(err.response) {
                            let errors = err.response.data.error;
                            if (errors) {
                                console.log(errors);
                                errors.forEach(value => {
                                    console.log(value.msg);
                                    this.messages[value.param] = value.msg
                                })

                            }


                            this.snackbar.color="error";
                            this.snackbar.text= err.message;
                            this.snackbar.snackbar = true;
                            this.snackbar.mode = 'multi-line';
                            this.snackbar.timeout=5000;
                            this.preload = false;
                            if(err.response.status===401){
                                this.setLogout();
                                this.$router.push('/login')
                            }
                        }

                    });
            },
            updatecategory(){

                this.form.sport=this.paramKey;
                this.form.lang=this.getLanguage;

                let url = "categories/update";

                let payload = this.form;
                this.preload = true;
                this.messages = [];

                axios.post(`${this.getUrl}${url}`,payload,{ 'headers': { 'Authorization': `Bearer ${this.getToken}`} })
                    .then( res => {

                        this.preload = false;
                        this.dialogeditcategory = false;
                        this.snackbar.color="success";
                        this.snackbar.text= "Update effettuato con successo";
                        this.snackbar.snackbar = true;
                        this.snackbar.mode = 'multi-line';
                        this.snackbar.timeout=5000;
                        //this.filterCategories({'language': val});
                        //this.updateInline({'data':res.data.data,'index':this.editIndex});
                        //Object.assign(this.getItems[this.editIndex], res.data.data);
                        //Object.assign(this.getItems[this.editIndex], res.data.data);

                        let pass= {'index':this.editIndex,'detail':res.data.data.detail};
                        this.actionCategoryLive(pass);
                        this.getItems[this.editIndex].name= res.data.title;
                    })
                    .catch(err => {

                        if(err.response) {
                            let errors = err.response.data.error;
                            if (errors) {
                                console.log(errors);
                                errors.forEach(value => {
                                    console.log(value.msg);
                                    this.messages[value.param] = value.msg
                                })

                            }


                            this.snackbar.color="error";
                            this.snackbar.text= err.message;
                            this.snackbar.snackbar = true;
                            this.snackbar.mode = 'multi-line';
                            this.snackbar.timeout=5000;
                            this.preload = false;
                            if(err.response.status===401){
                                this.setLogout();
                                this.$router.push('/login')
                            }
                        }

                    });
            },
            deleteItem (item) {
                this.dialogconfirm=true;

                this.form = item;

            },
            confirmDelete(){
                this.dialogconfirm=false;
                this.preload = true;
                let url = "auth/delete";

                this.messages = [];

                axios.delete(`${this.getUrl}${url}/${this.form._id}`,{ 'headers': { 'Authorization': `Bearer ${this.getToken}`}})


                    .then( res => {


                        const index = this.items.indexOf(this.form)
                        this.items.splice(index, 1)
                        this.deleteitem='';
                        this.snackbar.color="success";
                        this.snackbar.text= "Utente Eliminato con  successo";
                        this.snackbar.snackbar = true;
                        this.snackbar.mode = 'multi-line';
                        this.snackbar.timeout=5000;
                        //confirm('Are you sure you want to delete this item?') && this.items.splice(index, 1)
                        //this.form.permission=pluck(res.data.permissions,id);
                        this.preload = false;


                    })
                    .catch(err => {

                        if(err.response) {
                            let errors = err.response.data.error;
                            if (errors) {
                                console.log(errors);
                                errors.forEach(value => {
                                    console.log(value.msg);
                                    this.messages[value.param] = value.msg
                                })

                            }


                            this.snackbar.color="error";
                            this.snackbar.text= err.message;
                            this.snackbar.snackbar = true;
                            this.snackbar.mode = 'multi-line';
                            this.snackbar.timeout=5000;
                            this.preload = false;
                            if(err.response.status===401){
                                this.setLogout();
                                this.$router.push('/login')
                            }
                        }




                    });

            }
        }
    }
</script>

<style scoped>

</style>
