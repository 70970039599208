<template>
    <v-app>
        <v-container fluid>
            <v-layout row justify-center>
                <v-flex md12 class="pa-3">
                    <v-card elevation="16">
                        <v-card-title>
                            <v-col cols="12" class="pa-0">
                                <h3>{{$t('importOdds')}}</h3>
                            </v-col>
                        </v-card-title>
                        <v-card-text>
                            <v-layout row>
                                <v-flex md3>
                                    <v-col cols="12">
                                        <v-autocomplete
                                                v-model="selectedOption"
                                                :items="options"
                                                label="Select an option"
                                                clearable
                                                @change="onSelectChange"
                                        ></v-autocomplete>
                                    </v-col>
                                </v-flex>
                                <v-flex md3>
                                    <v-col cols="12">
                                        <v-autocomplete
                                                v-if="selectedOption && secondOptions.length > 0"
                                                v-model="selectedSecondOption"
                                                :items="secondOptions"
                                                item-value="_id"
                                                item-text="name"
                                                label="Select a second option"
                                                clearable
                                        ></v-autocomplete>
                                    </v-col>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                        <v-card-actions>
                            <v-col cols="12" class="text-right">
                                <v-btn
                                        v-if="selectedOption && selectedSecondOption"
                                        color="primary"
                                        class="mt-2"
                                        @click="executeImport"
                                >
                                    {{ $t('generic.save') }}
                                </v-btn>
                            </v-col>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </v-app>
</template>

<script>
    import axios from 'axios';
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
                selectedOption: null,
                options: ['Planetwin', 'Sisal'],
                secondOptions: [],
                selectedSecondOption: null
            };
        },
        computed: {
            ...mapGetters({
                'getLanguage': 'profile/getLanguage',
                'getToken': 'profile/getToken',
                'getPlatformId': "rules/getPlatformId",
                'getOptions': 'bet/getOptions',
                'getUrlApi': 'settings/getUrl',
                'getEventsList': 'event/getEventsList',
                'getBetApiUrl': 'settings/getBetApiUrl',
            }),

        },
        methods: {
            executeImport() {
                axios.post(this.getUrlApi+'gfeed/'+this.selectedOption.toLowerCase()+'/crawlers',{"leagueId":this.selectedSecondOption},{'headers': {'Authorization': 'Bearer '+this.getToken}})
            },
            onSelectChange() {
                if(this.selectedOption){
                    axios.get(this.getUrlApi+'gfeed/league-matchs', { params: { reference: this.selectedOption.toLowerCase() },'headers': {'Authorization': 'Bearer '+this.getToken}})
                        .then(response => {
                            this.secondOptions = response.data;
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        });
                }

            }
        }
    };
</script>

<style>
    v-app {
        background-color: #f5f5f5;
    }
    v-container {
        margin-top: 20px;
    }
    v-select {
        margin-bottom: 20px;
    }
    .mt-2 {
        margin-top: 16px;
    }
    .text-right {
        text-align: right;
    }
</style>