<template>
    <div id="entity-limit-list-component">
        <!--        <div class="alert-container">-->

        <!--            <transition name="fade" v-if="alertSuccessEnabled">-->
        <!--                <v-alert type="success">-->
        <!--                    {{$t('successOperation')}}-->
        <!--                </v-alert>-->
        <!--            </transition>-->

        <!--            <transition name="fade" v-if="alertErrorEnabled">-->
        <!--                <v-alert type="error">-->
        <!--                    {{$t('operationNotPerformedCorrectly')}}-->
        <!--                </v-alert>-->
        <!--            </transition>-->
        <!--        </div>-->

        <v-container fluid fill-height>
            <v-layout justify-center align-center>
                <v-card width="100%" outlined elevation-0>
                    <v-card-title>
                        {{$t('risk.entityLimitList')}}
                        <v-spacer></v-spacer>
                    </v-card-title>

                    <v-row>
                        <v-col md="12" style="display:flex;">
                            <v-col md="1" v-if="userTarget == null">
                                <div class="groupRuleSelectorContainer">
                                    <v-select
                                            :label="this.$t('risk.userTypeRules')"
                                            density="compact"
                                            :items="groupType"
                                            v-model="groupTypeSelected">
                                    </v-select>
                                </div>
                            </v-col>

                            <v-col md="1">
                                <div class="entityTypeSelectorContainer">
                                    <v-select
                                            :label="this.$t('risk.entityType')"
                                            density="compact"
                                            :items="entity_type"
                                            v-model="entity_type_selected">
                                    </v-select>
                                </div>
                            </v-col>

                            <v-col md="2">
                                <div class="entityContextSelectorContainer">
                                    <v-select
                                            :label="this.$t('generic.context')"
                                            density="compact"
                                            :items="entity_context"
                                            v-model="entity_context_selected">
                                    </v-select>
                                </div>
                            </v-col>

                            <v-col md="2">
                                <div class="limitsTypeSelectorContainer">
                                    <v-select
                                            :label="this.$t('risk.typeOfLimit')"
                                            density="compact"
                                            :items="limits_type"
                                            v-model="limits_type_selected">
                                    </v-select>
                                </div>
                            </v-col>

                            <v-col md="2">
                                <div class="limitActionSelectorContainer">
                                    <v-select
                                            :label="this.$t('risk.typeOfAction')"
                                            density="compact"
                                            :items="limit_action"
                                            v-model="limit_action_selected">
                                    </v-select>
                                </div>
                            </v-col>

                            <v-col md="2">
                                <div class="searchByEntityIdContainer">
                                    <v-text-field v-model="searchByEntityId" append-icon="search"
                                                  :label="this.$t('risk.searchByEntityId')">
                                    </v-text-field>
                                </div>
                            </v-col>

                            <v-col md="2">
                                <div class="search-container">
                                    <v-text-field v-model="search" append-icon="search"
                                                  :label="this.$t('generic.search')">
                                    </v-text-field>
                                </div>
                            </v-col>
                        </v-col>
                    </v-row>

                    <v-data-table :headers="headers" :items="filterItems()" :search="search" item-key="_id"
                                  class="elevation-0">

                        <template v-slot:body="{ items }">
                            <tbody>
                            <tr v-for="item in items" :key="item._id">
                                <td v-if="userTarget == null">{{ item.group }}</td>
                                <td>{{ item.entity_type }}</td>
                                <td>{{ item.entity_id }}</td>
                                <td>{{ item.entity_context }}</td>
                                <td>{{ item.entity_name}}</td>
                                <td>{{ item.limits_type }}</td>
                                <td>{{ item.limits }}</td>
                                <td>{{ item.limits_action }}</td>

                                <td>
                                    <v-icon class="mr-0" @click="editRule(item)">
                                        mdi-account-edit
                                    </v-icon>
                                </td>
                                <!--                                <td width="60">-->
                                <!--                                    <v-icon class="mr-0" @click="deleteRule(item)">-->
                                <!--                                        mdi-delete-->
                                <!--                                    </v-icon>-->
                                <!--                                </td>-->
                            </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </v-card>

            </v-layout>
        </v-container>

        <updateEntityLimitDialog v-if="entityLimitToEdit != null" :entityLimitToEdit="entityLimitToEdit"
                                 :entity_id="entity_id" :userTarget="userTarget">
        </updateEntityLimitDialog>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import UpdateEntityLimitDialog from '@/components/risks/updateEntityLimitDialog'

    export default {
        name: "EntityLimitList",
        components: {UpdateEntityLimitDialog},
        props: {
            userTarget: {
                type: Object,
                required: false,
                default: null
            }
        },
        data() {
            return {
                icons: [
                    {'text': 'Black and white', value: "B/W"},
                    {'text': 'Color', value: "Color"}
                ],
                search: '',
                searchByEntityId: '',
                headers: [],
                alertSuccessEnabled: false,
                alertErrorEnabled: false,
                groupType: [
                    {text: this.$t('generic.all'), value: 'all'},
                    {text: this.$t('user.agency'), value: 'agency'},
                    {text: this.$t('user.user'), value: 'user'},
                ],
                groupTypeSelected: 'all',
                entity_type: [
                    {text: this.$t('generic.all'), value: 'all'},
                    {text: this.$t('sport'), value: 'sport'},
                    {text: this.$t('category'), value: 'category'},
                    {text: this.$t('tournament'), value: 'tournament'},
                    {text: this.$t('event'), value: 'event'},
                ],
                entity_type_selected: 'all',
                entity_context: [
                    {text: this.$t('generic.all'), value: 'all'},
                    {text: this.$t('bets.live'), value: 'live'},
                    {text: this.$t('bets.prematch'), value: 'prematch'},
                    {text: this.$t('bets.mixed'), value: 'mixed'},
                ],
                entity_context_selected: 'all',
                limits_type: [
                    {text: this.$t('generic.all'), value: 'all'},
                    {text: this.$t('percentage'), value: 'percentage'},
                    {text: this.$t('bets.winning'), value: 'amount'}
                ],
                limits_type_selected: 'all',
                limit_action: [
                    {text: this.$t('generic.all'), value: 'all'},
                    {text: this.$t('risk.check'), value: 'check'},
                    {text: this.$t('risk.reject'), value: 'reject'}
                ],
                limit_action_selected: 'all',
                rulesList: [],
                entityLimitToEdit: null,
                entity_id: null,
            }
        },
        async mounted() {

            await this.getEntityRuleList();

            this.$root.$on('closedUpdateEntityLimitDialog', () => {
                this.entityLimitToEdit = null;
                this.entity_id = null;
            })

            this.$root.$on('updateEntityRuleList', () => {
                this.getEntityRuleList();
            })

            await this.buildHeaderTable();
        },
        async beforeDestroy() {
            this.$root.$off('closedUpdateEntityLimitDialog')
            this.$root.$off('updateEntityRuleList')
        },
        watch: {
            async groupTypeSelected(newValue, oldValue) {
                await this.getEntityRuleList();
            },
            async entity_type_selected(newValue, oldValue) {
                await this.getEntityRuleList();
            }
        },
        computed: {
            ...mapGetters({
                'getPlatformId': 'rules/getPlatformId'
            }),
        },
        methods: {

            ...mapActions({
                'setPreload': 'utils/setPreload',
                'getEntityRulesAction': 'risk/getEntityRules',
                'getEntityUserRulesAction': 'risk/getEntityUserRules',
                'deleteEntityRule': 'risk/deleteEntityRule'
            }),
            async buildHeaderTable() {

                this.headers = [];
                if (this.userTarget == null) {
                    this.headers.push({text: this.$t('risk.userTypeRules'), value: 'group'});
                }
                this.headers.push({text: this.$t('risk.entityType'), value: 'entity_type'});
                this.headers.push({text: this.$t('risk.entityId'), value: 'entity_id'});
                this.headers.push({text: this.$t('generic.context'), value: 'entity_context'});
                this.headers.push({text: this.$t('generic.name'), value: 'entity_name'});
                this.headers.push({text: this.$t('risk.typeOfLimit'), value: 'limits_type'});
                this.headers.push({text: this.$t('value'), value: 'limits'});
                this.headers.push({text: this.$t('risk.typeOfAction'), value: 'limits_action'});
            },
            async buildPayloadForGenericRules() {

                const groupType = this.groupTypeSelected;
                let entityType = this.entity_type_selected;

                if (entityType == 'all') {
                    entityType = '';
                }
                const payload = {
                    groupType: groupType,
                    entityType: entityType
                }
                return payload;
            },
            async buildPayloadForUserRules() {

                let entityType = this.entity_type_selected;

                if (entityType == 'all') {
                    entityType = '';
                }

                const payload = {
                    entityType: entityType,
                    idUser: this.userTarget.idUser ? this.userTarget.idUser : this.userTarget.userId
                }
                return payload;
            },

            async buildPayloadForTakeRules() {

                let payload = null;

                if (this.userTarget == null) {
                    payload = await this.buildPayloadForGenericRules();
                } else {
                    payload = await this.buildPayloadForUserRules();
                }
                return payload;

            },
            async getEntityRules(payload) {
                let response = null;

                if (this.userTarget == null) {
                    response = await this.getEntityRulesAction(payload);
                } else {
                    response = await this.getEntityUserRulesAction(payload);
                }
                return response;
            },
            async getEntityRuleList() {

                const payload = await this.buildPayloadForTakeRules();

                this.setPreload(true);

                const response = await this.getEntityRules(payload);

                if (response && response.data && response.data.data && response.data.data.length > 0) {
                    this.rulesList = response.data.data
                } else {
                    this.rulesList = []
                }
                this.setPreload(false);
            },


            filterItems() {

                let filteredRules = this.rulesList;

                if (this.entity_context_selected != 'all') {
                    filteredRules = filteredRules.filter(rule => {
                        return rule.entity_context == this.entity_context_selected
                    })
                }

                if (this.limits_type_selected != 'all') {
                    filteredRules = filteredRules.filter(rule => {
                        return rule.limits_type == this.limits_type_selected
                    })
                }

                if (this.limit_action_selected != 'all') {
                    filteredRules = filteredRules.filter(rule => {
                        return rule.limits_action == this.limit_action_selected
                    })
                }

                if (this.searchByEntityId != null && this.searchByEntityId != '') {
                    filteredRules = filteredRules.filter(rule => {
                        return rule.entity_id == this.searchByEntityId
                    })
                }

                return filteredRules;
            },

            editRule(item) {
                this.entityLimitToEdit = item;
                this.entity_id = item._id;
            },
            async deleteRule(item) {
                const vm = this;

                const payload = {
                    _id: item._id,
                }

                this.$swal.fire({
                    title: vm.$t('generic.areYouSure'),
                    text: vm.$t('generic.youWontBeAbleToRevertThis'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: vm.$t('generic.yesDeleteIt'),
                    cancelButtonText: vm.$t('generic.noCancel'),
                    reverseButtons: true
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await this.setPreload(true);
                        const response = await this.deleteEntityRule(payload);
                        await this.setPreload(false);

                        if (response && response.data && response.data.messages && response.data.messages == 'Rules entity deleted') {
                            vm.getEntityRuleList();
                            vm.$swal.fire(vm.$t('successOperation'), '', 'success');
                        } else {
                            vm.$swal.fire(vm.$t('operationNotPerformedCorrectly'), '', 'error')
                        }
                    } else if (result.dismiss === vm.$swal.DismissReason.cancel) {
                        vm.$swal.fire(vm.$t('changesHaveNotBeenSaved'), '', 'info')
                    }
                })
            },
            // showSuccessAlert() {
            //     this.alertSuccessEnabled = true;
            //     setTimeout(() => {
            //         this.alertSuccessEnabled = false
            //     }, 2000)
            // },
            // showErrorAlert() {
            //     this.alertErrorEnabled = true;
            //     setTimeout(() => {
            //         this.alertErrorEnabled = false
            //     }, 5000)
            // },
        },
    }
</script>

<style scoped>

</style>
