import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import profile from './modules/profile.module';
import settings from './modules/settings.module';
import sport from './modules/sport.module';
import category from './modules/category.module';
import tournament from './modules/tournament.module';
import event from './modules/event.module';
import eventmanual from './modules/eventmanual.module';
import match from './modules/match.module';
import utils from './modules/utils.module';
import producers from './modules/producers.module';
import tree from './modules/tree.module';
import skins from './modules/skins.module';
import competitors from './modules/competitors.module';
import socket from './modules/socket.module';
import rules from './modules/rules.module';
import live from './modules/live.module';
import bet from './modules/bet.module';
import cache from './modules/cache.module';
import stamps from './modules/stamps.module';
import market from './modules/market.module';
import multimedia from './modules/multimedia.module';
import users from './modules/users.module'
import risk from './modules/risk.module'
//import database from './modules/database.module';

Vue.use(Vuex)
export default new Vuex.Store({
    plugins: [createPersistedState({paths: ['rules']})],
    modules: {
        settings,
        profile,
        category,
        sport,
        tournament,
        event,
        match,
        tree,
        utils,
        producers,
        skins,
        competitors,
        socket,
        rules,
        live,
        bet,
        cache,
        stamps,
        multimedia,
        market,
        eventmanual,
        users,
        risk
    }
})
