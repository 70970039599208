<template>
  <div>
    <div class="d-flex">
      <div class="flex-grow-1 text-left pa-3 text-help">
      </div>
      <div class="flex-grow-0 pa-3 text-help">
        <!--{{$t('generic.totalResults')}} {{getCounts}}-->
      </div>
    </div>

    <v-data-table :headers="headers" :items="getBookeds" class="elevation-1 head-capitalize" :loading="getPreload" disable-pagination hide-default-footer>

      <template v-slot:item.id="{ item }">
        <span class="font-weight-bold">{{ item.id }}</span>
      </template>

      <template v-slot:item.quickbet="{ item }">
        <span class="text-uppercase">{{ item.quickbet }}</span>
      </template>
      <template v-slot:item.source="{ item }">
            <span class="text-uppercase">{{ item.source[0].value }}</span>
      </template>

      <template v-slot:item.scheduled="{ item }">
        <span v-if="item.scheduled != null">{{ new Date(item.scheduled).toLocaleString() }}</span>
      </template>

      <template v-slot:item.event="{ item }">
        {{getDescription(item)}}
      </template>

      <template v-slot:item.type="{ item }">
        {{$t(getEventType(item.type)) | capitalize}}
      </template>

      <template v-slot:item.sport="{ item }">
        {{ getDetail(item.sport.detail) }}
      </template>

      <template v-slot:item.category="{ item }">
        {{ getDetail(item.category.detail) }}
      </template>

      <template v-slot:item.tournament="{ item }">
        {{ getDetail(item.tournament.detail) }}
      </template>

      <template v-slot:item.status="{ item }">
        {{ $t(getStatus(item.sport_event_status._attributes.status)) | capitalize}}
      </template>

      <template v-slot:item.actions="{ item }">
        <span class="actions-element">
        <v-icon   title="Cambia status" :class="(checkElement(item,'event')) ? 'active' : 'disabled'" @click="changeStatusDisable(item, 'event',$event)">check_circle</v-icon>

          <!--<v-icon :title="$t('bets.detail')" @click="showDetail(item.id)">mdi-file-find</v-icon>-->
        </span>
      </template>

    </v-data-table>

    <!--<div class="text-center pt-3 pb-3">
      <v-pagination
          v-model="getOptions.page"
          :length="+(getCounts / getOptions.limit).toFixed(0)"
          :total-visible="20"
          @input="getNewPage()"
      ></v-pagination>
    </div>

    <EventDetail></EventDetail>

    <v-snackbar v-model="snackbar">
      {{$t(this.snackbar_message)}}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          {{$t('generic.close')}}
        </v-btn>
      </template>
    </v-snackbar>-->

  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import _ from 'lodash';
import EventDetail from "@/components/events/eventDetail";
import axios from "axios";


export default {
  name: "eventsList",
  components: {EventDetail},
  data() {
    return {
      snackbar: false,
      snackbar_message: '',
      dialog: false,
      eventToShow: {},
      headers: [
        {text: this.$t('generic.id'), value: 'id', width: '3%', sortable: false},
        {text: this.$t('quickbet'), value: 'quickbet', width: "3%"},
        {text: "source", value: 'source', width: "3%"},
        {text: this.$t('bets.date'), value: 'scheduled', width: "7%"},
        {text: this.$t('bets.event'), value: 'event', width: "17%"},
        {text: this.$t('bets.type'), value: 'type', width: "3%"},
        {text: this.$t('sport'), value: 'sport', width: "5%", sortable: false},
        {text: this.$t('category'), value: 'category', width: "5%", sortable: false},
        {text: this.$t('tournament'), value: 'tournament', width: "8%", sortable: false},
        {text: this.$t('bets.status'), value: 'status', width: "8%"},
        {text: this.$t('bets.actions'), value: 'actions', sortable: false, width: "10%"},
      ],
      options: {},
    }
  },
  computed: {
    ...mapGetters({
      'getLanguage': 'profile/getLanguage',
      'getToken': 'profile/getToken',
      'getEventsList': 'event/getEventsList',
      'getPreload': 'utils/getPreload',
      'getShowDetail': 'bet/getShowDetail',
      'getOptions': 'event/getOptions',
      'getCounts': "event/getCounts",
      'getCriteria': "event/getEventsCriteria",
      'getUrl': "settings/getUrl",
      'getBookeds': "live/getBookeds",
      'getPlatformId': 'rules/getPlatformId',
      'getRulesLives': 'skins/getRulesLives',

    }),
  },
  async created() {
    await this.setPreload(true);


    await this.fetchRules();
    await this.setPreload(false);

  },
  methods: {
    ...mapActions({
      'setEventDetail': 'event/setEventDetail',
      'setShowDetail': 'event/setShowDetail',
      'fetchEventsList': 'event/fetchEventsList',
      'setPreload': 'utils/setPreload',
      'defineOdd': 'bet/defineOdd',
      'updateRulesLive': 'skins/updateRulesLive',
      'fetchRules': 'skins/fetchRules',

    }),

    async changeStatusDisable(item,scope,event) {
      //console.log("EVENT",event)
      var platformUsed = this.getPlatformId

      var ruleManager = _.find(this.getRulesLives, (el) => {
        //return el.type=="disable" && el.scope=="sport" && el.status==true && el.id==id
        return el.id == +item.id && el.scope == scope && el.platform == 0
      })

     /* console.log(platformUsed,ruleManager,item,scope,event)
      return*/
      if (platformUsed == 0) {

        if (ruleManager) {

          await this.updateRulesLive({
            operation: 'delete',
            id: +item.id,
            type: 'disable',
            scope: scope,
            platform: this.getPlatformId
          })
          event.target.classList.remove('disabled');
          event.target.classList.add('active');


        } else {
          await this.updateRulesLive({
            operation: 'create',
            id: +item.id,
            type: 'disable',
            scope: scope,
            platform: this.getPlatformId
          })
          event.target.classList.remove('active');
          event.target.classList.add('disabled');
        }


      } else {

        var rispostaCheck = this.checkElement(item,"event")

        var ruleSkin = _.find(this.getRulesLives, (el) => {
          //return el.type=="disable" && el.scope=="sport" && el.status==true && el.id==id
          return el.id == +item.id && el.platform == this.getPlatformId && el.scope == scope
        })
        if(!ruleSkin){
          if(!rispostaCheck){
            await this.updateRulesLive({
              operation: 'create',
              id: +item.id,
              type: 'active',
              scope: scope,
              platform: this.getPlatformId
            })
            event.target.classList.remove('disabled');
            event.target.classList.add('active');
          }else{
            await this.updateRulesLive({
              operation: 'create',
              id: +item.id,
              type: 'disable',
              scope: scope,
              platform: this.getPlatformId
            })
            event.target.classList.remove('active');
            event.target.classList.add('disabled');
          }
        }else{
          if(!rispostaCheck){
            await this.updateRulesLive({
              operation: 'update',
              id: +item.id,
              type: 'active',
              scope: scope,
              platform: this.getPlatformId
            })
            event.target.classList.remove('disabled');
            event.target.classList.add('active');
          }else{
            await this.updateRulesLive({
              operation: 'update',
              id: +item.id,
              type: 'disable',
              scope: scope,
              platform: this.getPlatformId
            })
            event.target.classList.remove('active');
            event.target.classList.add('disabled');
          }
        }



        /*var ruleSkin = _.find(this.getRulesLives, (el) => {
          //return el.type=="disable" && el.scope=="sport" && el.status==true && el.id==id
          return el.id == +item.id && el.platform == this.getPlatformId && el.scope == scope
        })


        if (ruleSkin && ruleSkin.type == 'disable') {
          await this.updateRulesLive({
            operation: 'update',
            id: +item.id,
            type: 'active',
            scope: scope,
            platform: this.getPlatformId
          })
          event.target.classList.remove('disabled');
          event.target.classList.add('active');
        }
        if (ruleSkin && ruleSkin.type == 'active') {
          await this.updateRulesLive({
            operation: 'update',
            id: +item.id,
            type: 'disable',
            scope: scope,
            platform: this.getPlatformId
          })
          event.target.classList.remove('active');
          event.target.classList.add('disabled');
        }
        console.log("ruleSkin",ruleSkin);
        console.log("ruleManager",ruleManager);
        return
        if (!ruleSkin && !ruleManager) {

          await this.updateRulesLive({
            operation: 'create',
            id: +item.id,
            type: 'disable',
            scope: scope,
            platform: this.getPlatformId
          })
          event.target.classList.remove('active');
          event.target.classList.add('disabled');
        }else if(!ruleSkin && ruleManager){
          var rispostaCheck = this.checkElement(item,"event")
          await this.updateRulesLive({
            operation: 'create',
            id: +item.id,
            type: 'active',
            scope: scope,
            platform: this.getPlatformId
          })
          event.target.classList.remove('disabled');
          event.target.classList.add('active');
        }

*/
      }
      return
      //console.log("ruleSkin",ruleSkin)
      /*var ruleSkin = _.find(this.getRulesLives,(el)=>{
        //return el.type=="disable" && el.scope=="sport" && el.status==true && el.id==id
        return  el.id==+item.id && el.type=="disable" && el.platform == this.getPlatformId
      })

      console.log("ID",this.getPlatformId)
      if(rule){


          await this.updateRulesLive({operation:'delete',id:+item.id,type:'disable',scope:scope,platform: this.getPlatformId})
        event.target.classList.remove('disabled');
        event.target.classList.add('active');




      }else{
        await this.updateRulesLive({operation:'create',id:+item.id,type:'disable',scope:scope,platform: this.getPlatformId})
        event.target.classList.remove('active');
        event.target.classList.add('disabled');
      }*/
    },
    checkElement(item,scope){

       console.log("RULES",this.getRulesLives)
      /* console.log("id",id)
       console.log("scope",scope)
       console.log("platform",this.getPlatformId)*/
      //console.log("ITEM",item)
      var status = true
      var category = item.category.id
      var tournament = item.tournament.id
      var sport = item.sport.id
      var elements = [
              {scope:"event",id:item.id},
              {scope:"tournament",id:tournament},
              {scope:"category",id:category},
              {scope:"sport",id:sport}
              ]
      var status = true
      var found = false
      for (var element of elements){

        if(found){
          break
        }

        var ruleManager = _.find(this.getRulesLives,(el)=>{
          //return el.type=="disable" && el.scope=="sport" && el.status==true && el.id==id

          return  el.id==+element.id  && el.scope==element.scope && el.platform==0
        })

        if(ruleManager){
         // console.log("ruleManager",ruleManager)
        }
        if(ruleManager && ruleManager.type=="disable"){

          status = false
          found = true

        }
        if(this.getPlatformId!=0) {
          var rule = _.find(this.getRulesLives, (el) => {
            //return el.type=="disable" && el.scope=="sport" && el.status==true && el.id==id
            return el.id == +item.id && el.scope == scope && el.platform == +this.getPlatformId
          })

          if (rule && rule.type == "disable") {
            status = false
            found = true
          } else if (rule && rule.type == "active") {
            status = true
            found = true
          }
        }
      }
      if(item.id==2570554878){
        //console.log(status)
      }


      //console.log(status)


      //status=false
      return status
    },
    async showDetail(event) {

      await this.setShowDetail(false)
      await this.setPreload(true)

      let url = `${this.getUrl}events/getevents/${event}`;
      await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${this.getToken}`}})
      .then(async (res) => {
        await this.setEventDetail(res.data.events)
      })
      .catch((error) => {
        console.log(error);
      });

      this.$root.$emit('getMarkets')
      await this.setPreload(false)
      await this.setShowDetail(true)

    },
    async getNewPage() {
      this.getCriteria.page = this.getOptions.page
      this.getCriteria.limit = this.getOptions.limit
      this.getCriteria.platform_id = this.getOptions.platform_id

      await this.setPreload(true);
      await this.fetchEventsList(this.getCriteria)
      await this.setPreload(false);
    },

    getDescription(item){
      if(item.type == 'match'){
        var home = _.find(item.competitors[0].detail, {'lang':this.getLanguage})
        var away = _.find(item.competitors[1].detail, {'lang':this.getLanguage})
        return home.text+' - '+away.text
      }else if(item.type == 'antepost'){
        var tournament = _.find(item.tournament.detail, {'lang':this.getLanguage})
        return 'Antepost '+tournament.text
      }
    },

    getDetail(detail){
      var detail = _.find(detail, {'lang':this.getLanguage})
      return detail.text
    },

    getStatus(status){
      switch (status) {
        case 1:
          return 'nsy'
        case 2:
          return 'inProgress'
        case 3:
          return 'finished'
        case 4:
          return 'cancelled'
        case 5:
          return 'postponed'
        case 6:
          return 'interrupted'
        case 7:
          return 'abandoned'
        case 8:
          return 'coverageLost'
        case 9:
          return 'aboutToStart'
      }
    },

    getEventType(type){
      switch (type){
        case 'match':
          return 'prematch'
        case 'antepost':
          return 'antepost'
      }
    }
  }

}
</script>

<style scoped>

</style>