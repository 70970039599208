<template>
    <div id="marketButtonSizeSettings">
        <v-container fluid>
            <v-layout row>
                <v-flex md12>
                    <v-col cols="12">
                        <span class="title-table">{{ this.$t('generic.marketButtonSettings') | capitalize}}</span>
                        <v-card outlined elevation-0>
                            <div id="marketButtonSettings">
                                <v-container fluid v-if="getPlatformId != null && getPlatformId >= 0">
                                    <v-row>
                                        <v-col cols="6">
                                            <v-card :key="'key_left_card_'+keyLeftCard">
                                                <v-data-table
                                                        :headers="headers_preview"
                                                        :items="marketsList"
                                                        class="elevation-1"
                                                        :items-per-page="30"
                                                        :search="search"
                                                        item-key="id"
                                                        :key="dataTableKey+dataTableKeyIndex">
                                                    <template v-slot:top>
                                                        <v-text-field
                                                                v-model="search"
                                                                :label="$t('generic.search')"
                                                                class="mx-4">
                                                        </v-text-field>
                                                    </template>
                                                    <template v-slot:item.name="{ item }">
                                                        {{ item.name }}
                                                    </template>

                                                    <template v-slot:item.actions="{ item }">
                                                        <v-icon
                                                                @click="editMarketV2(item)">mdi-magnify
                                                        </v-icon>
                                                    </template>
                                                </v-data-table>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="6" v-if="marketToEdit != null ">
                                            <v-card>
                                                <v-card-title>
                                                    <span class="market-name-box">{{ this.marketToEdit.name }}</span>
                                                </v-card-title>
                                                <hr>
                                                <div id="marketSettingContainer" class="col-md-12">
                                                    <div id="selectorSizeOddsContainer" v-if="loadingResponse == false">
                                                        <div id="sizeOddsXlSelectorContainer">
                                                            <v-select
                                                                    :items="sizeOddsXlView"
                                                                    v-model="selectedSizeXlOddButton"
                                                                    label="Desktop view">
                                                            </v-select>
                                                        </div>
                                                        <div id="sizeOddsLgSelectorContainer">
                                                            <v-select
                                                                    :items="sizeOddsLgView"
                                                                    v-model="selectedSizeLgOddButton"
                                                                    label="Laptop view">
                                                            </v-select>
                                                        </div>
                                                        <div id="sizeOddsMdSelectorContainer">
                                                            <v-select
                                                                    :items="sizeOddsMdView"
                                                                    v-model="selectedSizeMdOddButton"
                                                                    label="Tablet view">
                                                            </v-select>
                                                        </div>
                                                    </div>

                                                    <v-btn color="blue-grey" class="white--text text-right mt-2"
                                                           @click="saveMarketButtonSetting()">
                                                        <v-icon left dark>save</v-icon>
                                                        {{$t('generic.save')}}
                                                    </v-btn>
                                                </div>
                                                <hr>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </div>
                        </v-card>
                    </v-col>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>
<style>

</style>
<script>
    import {mapActions, mapGetters} from 'vuex';

    export default {
        name: "MarketButtonSizeSettings",
        data() {
            return {
                headers_preview: [
                    {text: this.$t('generic.id'), value: 'id'},
                    {text: this.$t('generic.name'), value: 'name'},
                    {text: this.$t('generic.actions'), value: 'actions'},
                ],
                search: '',
                marketToEdit: null,
                marketsList: [],
                dataTableKey: 'dataTable_',
                dataTableKeyIndex: 1,
                sizeOddsMdView: ['md-2', 'md-3', 'md-4', 'md-6', 'md-12'],
                sizeOddsLgView: ['lg-2', 'lg-3', 'lg-4', 'lg-6', 'lg-12'],
                sizeOddsXlView: ['xl-2', 'xl-3', 'xl-4', 'xl-6', 'xl-12'],
                selectedSizeMdOddButton: 'md-2',
                selectedSizeLgOddButton: 'lg-2',
                selectedSizeXlOddButton: 'xl-2',
                keyLeftCard: 1,
                loadingResponse: false
            }
        },
        computed: {
            ...mapGetters({
                'getLanguage': 'profile/getLanguage',
                'getPlatformId': 'rules/getPlatformId'
            }),

        },
        async mounted() {

            const marketListResponse = await this.getAllMarketsList({lang: this.getLanguage});
            const marketList = marketListResponse.data.markets;

            this.marketsList = marketList;
        },

        methods: {
            ...mapActions({
                'getAllMarketsList': 'utils/getAllMarketsList',
                'getMarketButtonSetting': 'utils/getMarketButtonSetting',
                'saveMarketButtonSettingAction': 'utils/saveMarketButtonSetting'
            }),

            async editMarketV2(item) {
                this.marketToEdit = item;

                const platformId = this.getPlatformId;
                const marketId = this.marketToEdit.id;
                const payload = {
                    platformId: platformId,
                    marketId: marketId
                }

                this.loadingResponse = true;

                const response = await this.getMarketButtonSetting(payload);

                this.selectedSizeMdOddButton = response.data.data.sizeOddButtonMd,
                    this.selectedSizeLgOddButton = response.data.data.sizeOddButtonLg,
                    this.selectedSizeXlOddButton = response.data.data.sizeOddButtonXl;

                this.loadingResponse = false;

            },
            async saveMarketButtonSetting() {

                if (this.getPlatformId == null || this.getPlatformId < 0) {
                    console.log('error no platform');
                    return;
                }

                if (this.marketToEdit == null) {
                    console.log('error no market selected');
                    return;
                }

                const payload = {
                    platformId: this.getPlatformId,
                    marketId: this.marketToEdit.id,
                    sizeOddButtonMd: this.selectedSizeMdOddButton,
                    sizeOddButtonLg: this.selectedSizeLgOddButton,
                    sizeOddButtonXl: this.selectedSizeXlOddButton
                }

                const response = await this.saveMarketButtonSettingAction(payload);

                if (response.data.messages === 'success') {
                    this.$swal.fire(this.$t('successOperation'), '', 'success')
                } else {
                    this.$swal.fire(this.$t('operationNotPerformedCorrectly'), '', 'error');
                }
            }

        },
    }
</script>

<style scoped>

</style>
