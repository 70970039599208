import axios from "axios";
import rulesHandler from "@/service/rulesHandler";
import _ from "lodash";

const state = {
    preload: false,
    odds: [],
    matchDetail: [],
    matchDetailDialog: [],
    historyDetail: [],
    historyDetailDialog: [],
    markets: [],
    groupsMarket: [],
    settingsGroupsMarket: [],
    marketsMini: []
};

const getters = {
    getPreload: state => {
        return state.preload;
    },
    getOdds(state) {
        return state.odds;
    },

    getMatchDetail(state) {
        return state.matchDetail;
    },

    getMatchDetailDialog(state) {
        return state.matchDetailDialog;
    },

    getMarkets(state) {
        return state.markets
    },

    getGroupsMarket(state) {
        return state.groupsMarket
    },

    getSettingsGroupsMarket(state) {
        return state.settingsGroupsMarket
    },

    getHistoryDetail(state) {
        return state.historyDetail;
    },

    getHistoryDetailDialog(state) {
        return state.historyDetailDialog;
    },

    getMarketsMini(state) {
        return state.marketsMini;
    }
};

const actions = {
    async setPreload(context, payload) {
        context.commit("setPreload", payload)
    },

    async oddChangeSocket(context, payload) {

        console.log('Socket OddChange Detail', payload)

        if (!_.isEmpty(context.state.matchDetail)) {

            var detailMatch = context.state.matchDetail;

            if (detailMatch.events.source == payload._attributes.event_id) {

                await Promise.all(detailMatch.events.odds.map(async (oddGroup) => {

                    await Promise.all(oddGroup.odds.map(async (odd) => {

                        if (!odd.freeze) {

                            var payload_market = await _.find(payload.odds.market, function (market) {

                                var id_market = market._attributes.id;
                                var market_unique_id = market._attributes.unique_id
                                if (market_unique_id == undefined || market_unique_id == '') {
                                    market_unique_id = (market._attributes.specifiers != undefined && market._attributes.specifiers != '') ? id_market + '#' + market._attributes.specifiers : id_market
                                }

                                if (market_unique_id == odd.unique_id) {
                                    return market
                                }

                            });


                            if (payload_market != undefined) {

                                //Todo Update from outcome unique id
                                odd.status_to_refresh = (payload_market._attributes.status == "1")

                                for (let outcome_to_update of odd.odds) {
                                    var outcome = await _.find(payload_market.outcome, function (out) {
                                        if (out._attributes.id == outcome_to_update.id_outcome) {
                                            return out
                                        }
                                    })

                                    if (outcome != undefined) {
                                        outcome_to_update.outcome_value_to_refresh = outcome._attributes.odds
                                        outcome_to_update.outcome_status_to_refresh = (outcome._attributes.active == "1")
                                    }

                                }

                            }

                        }

                    }))

                    oddGroup.odds = await rulesHandler.refreshOdds(oddGroup.odds)

                }))

            }

        }

    },

    async getOddsEvent(context, payload) {

        var skinsList = context.rootGetters['skins/getList']
        var platformId = context.rootGetters['rules/getPlatformId']
        var lang = context.rootGetters['profile/getLanguage']


        // let url = `${context.rootGetters['settings/getUrl']}events/getodds/tournament/event`;
        let url = `${context.rootGetters['settings/getUrl']}events/getodds/tournament/adminevent`;
        let type = '';
        switch (payload.type) {
            case "match":
                type = "groupOdds"
                break
            // case "stage":
            //     type = "antepostGroupOdds"
            //     break
            // case "season":
            //     type = "antepostSeasonOdds"
            //     break
            // case "simple_tournament":
            //     type = "antepostSimpleTournOdds"
            //     break
        }

        await axios.post(`${url}`, {
            'event': payload.paramKey,
            "platform": context.rootGetters['rules/getPlatformId'],
            "type": type,
            "section": "sport",
            "typegroup": "detail",
            "sport": payload.sport,
            "lang": lang,
            "tournament": payload.tournament,
            "group": payload.group,
            "product_id": (payload.status == 2) ? 1 : 0

        }, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(async (res) => {

                console.log('#### DATA PREVIEW');
                console.log(res);

                var producer = 0;

                if (res.data.events != undefined) {

                    var eventDetail = res.data.events;

                    var sport_detail = res.data.events.sport;
                    var category_detail = res.data.events.category;
                    var tournament_detail = res.data.events.tournament;

                    var settings = res.data.settings

                    var tree_structure = {
                        sport: sport_detail.id,
                        category: category_detail.id,
                        tournament: tournament_detail.id
                    }

                    tree_structure.treeMultiplicity = await rulesHandler.getTreeMultiplicity(tree_structure, skinsList, platformId, producer)

                    var sport = await rulesHandler.getInfoWithRules(sport_detail, skinsList, platformId, lang)
                    var category = await rulesHandler.getInfoWithRules(category_detail, skinsList, platformId, lang)
                    var tournament = await rulesHandler.getInfoWithRules(tournament_detail, skinsList, platformId, lang)

                    var competitors = {
                        home: {
                            ...await rulesHandler.getCompetitorsWithRules(eventDetail.competitors[0], skinsList, platformId, lang)
                        },
                        away: {
                            ...await rulesHandler.getCompetitorsWithRules(eventDetail.competitors[1], skinsList, platformId, lang)
                        },
                    }

                    var description = ''

                    if (eventDetail.odds) {

                        var oddsGroupedArray = [];

                        for (let groupOdd of eventDetail.odds) {

                            var oddsArray = [];

                            oddsArray = await rulesHandler.applyRulesToOdds(groupOdd.markets, eventDetail.id, eventDetail.type, tree_structure, skinsList, platformId, producer, lang, "detail")

                            var desc_group = _.find(groupOdd.name, {'lang': context.rootGetters['profile/getLanguage']});
                            if (!desc_group) {
                                desc_group = _.find(groupOdd.name, {'lang': 'en'});
                            }

                            let group = {
                                name: (desc_group) ? desc_group.text : 'Gruppo senza nome',
                                odds: (eventDetail.type != 'match') ? _.orderBy(oddsArray, ['odds.length'], ['desc']) : oddsArray
                            }


                            oddsGroupedArray.push(group)

                        }

                    }


                    description = competitors.home.name + ' vs ' + competitors.away.name

                    var single_event = {
                        id: eventDetail.id,
                        competitors: competitors,
                        scheduled: eventDetail.scheduled,
                        day: new Date(eventDetail.scheduled).toDateString(),
                        time: new Date(eventDetail.scheduled).getTime(),
                        type: eventDetail.type,
                        description: description,
                        odds: oddsGroupedArray
                    }


                    const data_events = {
                        sport: {
                            ...sport
                        },
                        category: {
                            ...category
                        },
                        tournament: {
                            ...tournament
                        },
                        events: {
                            ...single_event
                        },
                        settings: {
                            ...settings
                        }
                    }

                    //  console.log(data_events)
                    context.commit('setMatchDetail', data_events);


                } else {

                    const data_events = {
                        tournament: {
                            source: payload.paramKey
                        },
                    }

                    context.commit('setMatchDetail', data_events);

                }


            })
            .catch((error) => {
                console.log(error);
            });


    },

    async getOddsAntepostEvent(context, payload) {

        var skinsList = context.rootGetters['skins/getList']
        var platformId = context.rootGetters['rules/getPlatformId']
        var lang = context.rootGetters['profile/getLanguage']


        let url = `${context.rootGetters['settings/getUrl']}events/getodds/antepost/event`;

        await axios.post(`${url}`, {
            'event': +payload.paramKey,
            "platform": context.rootGetters['rules/getPlatformId'],
            "type": payload.type,
        }, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(async (res) => {

                console.log('#### DATA PREVIEW');
                console.log(res);

                var producer = 0;

                if (res.data.events != undefined) {

                    var eventDetail = res.data.events[0];

                    var sport_detail = res.data.events[0].sport;
                    var category_detail = res.data.events[0].category;
                    var tournament_detail = res.data.events[0].tournament;

                    var settings = []

                    var tree_structure = {
                        sport: sport_detail.id,
                        category: category_detail.id,
                        tournament: tournament_detail.id
                    }

                    tree_structure.treeMultiplicity = await rulesHandler.getTreeMultiplicity(tree_structure, skinsList, platformId, producer)

                    var sport = await rulesHandler.getInfoWithRules(sport_detail, skinsList, platformId, lang)
                    var category = await rulesHandler.getInfoWithRules(category_detail, skinsList, platformId, lang)
                    var tournament = await rulesHandler.getInfoWithRules(tournament_detail, skinsList, platformId, lang)

                    // var competitors = {
                    //     home: {
                    //         ...await rulesHandler.getCompetitorsWithRules(eventDetail.competitors[0],skinsList,platformId,lang)
                    //     },
                    //     away: {
                    //         ...await rulesHandler.getCompetitorsWithRules(eventDetail.competitors[1],skinsList,platformId,lang)
                    //     },
                    // }

                    var competitors = {}

                    if (eventDetail.odds) {

                        var oddsArray = await rulesHandler.applyRulesToOdds(eventDetail.odds, eventDetail.id, eventDetail.type, tree_structure, skinsList, platformId, producer, lang, "detail")

                    }

                    var single_event = {
                        active: eventDetail.active,
                        id: eventDetail.id,
                        competitors: competitors,
                        scheduled: eventDetail.scheduled,
                        day: new Date(eventDetail.scheduled).toDateString(),
                        time: new Date(eventDetail.scheduled).getTime(),
                        type: eventDetail.type,
                        odds: oddsArray
                    }


                    const data_events = {
                        sport: {
                            ...sport
                        },
                        category: {
                            ...category
                        },
                        tournament: {
                            ...tournament
                        },
                        events: {
                            ...single_event
                        },
                        settings: {
                            ...settings
                        }
                    }


                    context.commit('setMatchDetail', data_events);


                } else {

                    const data_events = {
                        tournament: {
                            source: payload.paramKey
                        },
                    }

                    context.commit('setMatchDetail', data_events);

                }


            })
            .catch((error) => {
                console.log(error);
            });


    },
    async apiGetMarketsBySportList(context, payload) {
        const url = `${context.rootGetters['settings/getUrlApi']}get-markets-sports-minilist/`;
        return axios.post(`${url}`, {
            'sport': (payload.sport != null) ? payload.sport : -1,
            "lang": payload.lang,
            "enableSpecifiers": (payload.enableSpecifiers != undefined) ? payload.enableSpecifiers : true
        }, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },
    async apiGetMarketsBySport(context, payload) { //DEPRECATED: avoid to use this, the JSON Response is too Heavy ! -> use apiGetMarketsBySportList and change logic
        const url = `${context.rootGetters['settings/getUrlApi']}get-markets-sports/`;
        return axios.post(`${url}`, {
            'sport': (payload.sport != null) ? payload.sport : -1,
            "lang": payload.lang,
            "enableSpecifiers": (payload.enableSpecifiers != undefined) ? payload.enableSpecifiers : true
        }, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },
    async getMarketsBySport(context, payload) {  //DEPRECATED: avoid to use this, the JSON Response is too Heavy ! -> use apiGetMarketsBySportList and change logic
        await context.dispatch('apiGetMarketsBySport', payload)
            .then((res) => {
                context.commit('setMarkets', res.data);
            }).catch((error) => {
                console.log(error);
            });
    },

    async getMarketsBySportList(context, payload) {
        await context.dispatch('apiGetMarketsBySportList', payload)
            .then((res) => {
                context.commit('setMarketsMini', res.data);
            }).catch((error) => {
                console.log(error);
            });
    },
    async getAllMarketsList(context, payload) {
        const url = `${context.rootGetters['settings/getUrlApi']}get-all-markets-mini/`;
        return axios.post(`${url}`, {
            "lang": payload.lang
        }, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },

    async getMarketsBySportQuickbet(context, payload) {

        let url = `${context.rootGetters['settings/getUrlApi']}get-markets-sports-quickbet-admin/`;
        await axios.post(`${url}`, {
            'sport': (payload.sport != null) ? payload.sport : -1,
            "lang": payload.lang,
            "id": payload.id,
            "platform_id": payload.platform_id,
            "enableSpecifiers": (payload.enableSpecifiers != undefined) ? payload.enableSpecifiers : true
        }, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                context.commit('setMarkets', res.data);

            }).catch((error) => {
                console.log(error);
            });

    },

    async getAllMarkets(context, payload){
        let url = `${context.rootGetters['settings/getUrl']}markets/list/all?language=`+context.rootGetters['profile/getLanguage'];

        if(payload.excludeHugeMarkets === true){
            url +='&excludeHugeMarkets=true'
        }

        return axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },

    async getOutcomeCodesByMarket(context, payload){

        let url = `${context.rootGetters['settings/getUrl']}markets/outcome/code/getOutcomeCodesByMarket`;
        let data = {
            platformId: context.rootGetters['rules/getPlatformId'],
            marketId: payload.marketId
        }

        return axios.post(`${url}`, data, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },

    async updateBulk(context, payload){
        let url = `${context.rootGetters['settings/getUrl']}markets/outcome/code/updateBulk`;
        return axios.post(`${url}`, payload.data, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },

    async getMainMarketsForPlatform(context, payload) {
        const urlForGetMainMarkets = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/mainpreview/get-main-markets-for-platform`;

        try {
            const response = await axios.post(`${urlForGetMainMarkets}`, {
                "platform": payload.platform,
                "sportId": payload.sportId,
                "groupId": payload.groupId,
                "typeMatch": payload.typeMatch,
            }, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})

            return response.data.mainMarkets
        } catch (e) {
            console.log(e)
        }
    },

    async getMainMarketsMobileForPlatform(context, payload) {
        const urlForGetMainMarkets = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/mainpreview/get-main-mobile-markets-for-platform`;

        try {
            const response = await axios.post(`${urlForGetMainMarkets}`, {
                "platform": payload.platform,
                "sportId": payload.sportId,
                "groupId": payload.groupId,
                "typeMatch": payload.typeMatch,
            }, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})

            return response.data.mainMarkets
        } catch (e) {
            console.log(e);
        }
    },
    async getMarketsBySportQuickbetList(context, payload) {

        let url = `${context.rootGetters['settings/getUrlApi']}get-markets-sports-quickbet-outcome/`;
        await axios.post(`${url}`, {
            'sport': (payload.sport != null) ? payload.sport : -1,
            "lang": payload.lang,
            "id": payload.id,
            "platform_id": payload.platform_id,
            "enableSpecifiers": (payload.enableSpecifiers != undefined) ? payload.enableSpecifiers : true
        }, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                context.commit('setMarkets', res.data.markets);

            }).catch((error) => {
                console.log(error);
            });
    },

    async createNewGroupOdds(context, payload) {

        var group = []

        let urlLang = `${context.rootGetters['settings/getUrl']}lang/`;
        await axios.get(`${urlLang}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((result_lang) => {
                _.forEach(result_lang.data.data, (lang) => {
                    let obj = {
                        lang: lang.abbr,
                        text: payload.name
                    }

                    group.push(obj);
                })
            }).catch((error) => {
                // console.log(error);
                return 'error'
            });


        var payload_request = {
            "section": payload.section,
            "source": payload.sport,
            "platform": payload.platform,
            "type": String(payload.type),
            "product_id": String(payload.product_id),
            "group": JSON.stringify(group)
        }

        let url = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/new-group/`;
        await axios.post(`${url}`, payload_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                // console.log(res);
                return 'group_created'
            }).catch((error) => {
                // console.log(error);
            });


    },

    async createNewGroupOddsV2(context, payload) {

        const payload_request = {
            "platformId": payload.platformId,
            "sportId": payload.sportId,
            "type": payload.type,
            "name": payload.name,
            "sort": payload.sort,
            "languages": payload.languages
        };

        let url = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/new-group-v2/`;
        await axios.post(`${url}`, payload_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                // console.log(res);
                return 'group_created'
            }).catch((error) => {
                console.log(error);
            });
    },

    async updateMarketGroup(context, payload) {

        const data_request = payload;

        let url = `${context.rootGetters['settings/getUrl']}markets/marketGroup`;
        await axios.put(`${url}`, data_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                console.log(res.data);
                return 'market_group_updated'
            }).catch((error) => {
                console.log(error);
            });
    },

    async deleteMarketGroup(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}settings/marketGroup/`;
        await axios.delete(`${url}`, {'data': {_id: payload._id}, 'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return 'market_group_deleted'
            }).catch((error) => {
                console.log(error);
            });
    },

    async fetchSettingsGroupsMarketV2(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/lists-v2?platformId=` + payload.platformId + `&sportId=` + payload.sportId + `&type=` + payload.type;
        return await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return res.data.data
            }).catch((error) => {
                console.log(error);
            });
    },

    async updateSingleMarket(context, payload) {

        var data_request = {
            id: payload.data.id,
            detail: JSON.stringify(payload.data.detail),
            outcomes: JSON.stringify(payload.data.outcomes)
        }


        let url = `${context.rootGetters['settings/getUrl']}markets/updateOne/`;
        await axios.post(`${url}`, data_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                console.log(res.data);
                return 'group_updated'
            }).catch((error) => {
                console.log(error);
            });


    },
    async updatePrintMarket(context, payload) {

        var data_request = {
            market: payload.data.id,
            skin: payload.skin,
            detail: JSON.stringify(payload.data)

        }


        let url = `${context.rootGetters['settings/getUrl']}markets/updatePrintOne/`;
        await axios.post(`${url}`, data_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                console.log(res.data);
                dispatch('/settings/getUrlRule', null /*or whatever payload*/, {root: true})
                return 'group_updated'
            }).catch((error) => {
                console.log(error);
            });


    },

    async checkExistsCode(context, payload) {

        var data_request = {
            id: payload.data.id,
            //detail: JSON.stringify(payload.data.detail),
            codes: JSON.stringify(payload.data),
            platform_id: payload.platform_id
        }
        //console.log(data_request)
        let url = `${context.rootGetters['settings/getUrl']}markets/code/checkExist/`;
        return await axios.post(`${url}`, data_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                return res.data
            }).catch((error) => {
                console.log(error);
            });


    },
    async deletecodes(context, payload) {


        //console.log(data_request)
        let url = `${context.rootGetters['settings/getUrl']}markets/code/delete/platform/${payload.id}`;
        return await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                return res.data
            }).catch((error) => {
                console.log(error);
            });


    },
    async updateSingleCode(context, payload) {

        var data_request = {
            id: payload.data.id,
            //detail: JSON.stringify(payload.data.detail),
            outcomes: JSON.stringify(payload.data.outcomes)
        }


        let url = `${context.rootGetters['settings/getUrl']}markets/code/updateOne/`;
        await axios.post(`${url}`, data_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                return 'group_updated'
            }).catch((error) => {
                console.log(error);
            });


    },

    async updateGroupOdds(context, payload) {

        var array_data = [];
        var platform = context.rootGetters['rules/getPlatformId'];
        var section = '';
        var source = '';
        var type = '';
        var product_id = payload.producer;

        var init_setup = false;

        if (_.isObject(payload.data)) {

            var data = payload.data

            if (!init_setup) {
                platform = data.platform
                section = data.section
                source = data.source
                type = data.type
            }

            array_data = data.specifiers

        } else {

            if (payload.data.length > 0) {

                _.forEach(payload.data, (data, i) => {

                    if (!init_setup) {
                        platform = data.platform
                        section = data.section
                        source = data.source
                        type = data.type
                    }

                    var specifiers = _.remove(data.specifiers, function (spec) {
                        return spec.outcomes.length == 0;
                    });

                    array_data = data.specifiers

                })

            } else {


                var specifiers = _.remove(payload.data.specifiers, function (spec) {
                    return spec.outcomes.length == 0;
                });

                platform = payload.data.platform
                section = payload.data.section
                source = payload.data.source
                type = payload.data.type
                array_data = payload.data.specifiers

            }
        }


        var data_request = {
            platform: platform,
            section: section,
            source: source,
            type: type,
            product_id: product_id.toString(),
            data_to_update: JSON.stringify(array_data)
        }


        let url = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/update/`;
        await axios.post(`${url}`, data_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                //console.log(res);
                return 'group_updated'
            }).catch((error) => {
                console.log(error);
            });


    },
    async updateGroupOddsSingle(context, payload) {
        var data_request = {
            platform: payload.platform,
            key: payload.key,
            section: payload.section,
            source: payload.source,
            type: payload.type,
            product_id: payload.product_id.toString(),
            data_to_update: JSON.stringify(payload.data)
        }

        //console.log(payload.data)
        let url = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/update/single`;
        await axios.post(`${url}`, data_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                // console.log(res);
                return 'group_updated'
            }).catch((error) => {
                console.log(error);
            });


    },

    async updateMainMarketSetting(context, payload) {
        const data_request = {
            platform: payload.platform,
            sportId: payload.sportId,
            groupId: payload.groupId,
            marketId: payload.marketId,
            specifier: payload.specifier,
            oddsList: payload.oddsList,
            typeMatch: payload.typeMatch,
            isMainVisible: payload.mainPreview,
            isMainVisibleMobile: payload.mainPreviewMobile,
            typeView: payload.typeView,
            sizeOddButtonMd: payload.sizeOddButtonMd,
            sizeOddButtonLg: payload.sizeOddButtonLg,
            sizeOddButtonXl: payload.sizeOddButtonXl,
            groupSpreadsInMain: payload.groupSpreadsInMain,
            orderInMainSection: payload.orderInMainSection,
            orderMobileInMainSection: payload.orderMobileInMainSection,
            active: payload.active
        }

        const url = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/markets/update-market-view-setting`;
        return axios.post(`${url}`, data_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },

    async updateMarketOutcomeSetting(context, payload) {
        const data_request = {
            platform: payload.platform,
            sportId: payload.sportId,
            groupId: payload.groupId,
            marketId: payload.marketId,
            specifier: payload.specifier,
            oddsList: payload.oddsList,
            typeMatch: payload.typeMatch,
            sort: payload.sort
        }

        const url = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/markets/update-market-outcome-view-setting`;
        return axios.post(`${url}`, data_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },
    async isMarketVisibleInMain(context, payload) {

        const data_request = {
            platform: payload.platform,
            sportId: payload.sportId,
            marketId: payload.marketId,
            typeMatch: payload.typeMatch
        }

        const url = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/mainpreview/check-visible`;
        return axios.post(`${url}`, data_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },
    async getMainMarketSettingInfo(context, payload) {

        const data_request = {
            platform: payload.platform,
            sportId: payload.sportId,
            groupId: payload.groupId,
            marketId: payload.marketId,
            typeMatch: payload.typeMatch
        }

        const url = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/mainpreview/get-market-view-setting`;
        return axios.post(`${url}`, data_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },

    async getMainMarketSettingInfoWithDefaultValues(context, payload) { //todo rename
        const response = await context.dispatch('getMainMarketSettingInfo', payload); //todo rename

        if (response.data.data == null || response.data.data.length == 0) {
            response.data.data = {
                isMainVisible: false,
                isMainVisibleMobile: false,
                groupSpreadsInMain: false,
                typeView: 'extended',
                sizeOddButtonMd: 'md-2',
                sizeOddButtonLg: 'lg-2',
                sizeOddButtonXl: 'xl-2',
                orderInMainSection: 1,
                orderMobileInMainSection: 1
            }
        }
        return response;
    },

    async getMarketOutcomeViewSetting(context, payload) {

        const data_request = {
            platform: payload.platform,
            sportId: payload.sportId,
            groupId: payload.groupId,
            marketId: payload.marketId,
            typeMatch: payload.typeMatch
        }

        const url = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/mainpreview/get-market-outcome-view-setting`; //todo change that mainpreview to other things
        return axios.post(`${url}`, data_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },

    async getMarketIdsWithOutcomeEnabled(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/mainpreview/markets-ids-with-enabled-outcome?platform=` + payload.platformId + `&sportId=` + payload.sportId + `&groupId=` + payload.groupId + `&typeMatch=` + payload.typeMatch;
        return await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return res.data.marketIds
            }).catch((error) => {
                console.log(error);
            });
    },

    async getMarketsViewSettingsOrderForGroupId(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/mainpreview/get-market-view-setting-orders`;
        return await axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return res.data
            }).catch((error) => {
                console.log(error);
            });
    },

    async getMarketsViewSettingsActiveForGroupId(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/mainpreview/getEnabledMarkets`;
        return await axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return res.data
            }).catch((error) => {
                console.log(error);
            });
    },
    async updatePreviewOddsSingle(context, payload) {
        var data_request = {
            platform: payload.platform,
            section: payload.section,
            source: payload.source,
            type: payload.type,
            product_id: payload.product_id.toString(),
            data_to_update: JSON.stringify(payload.data)
        }


        let url = `${context.rootGetters['settings/getUrl']}settings/sport/previewodds/update/single`;
        await axios.post(`${url}`, data_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                //console.log(res);
                return 'group_updated'
            }).catch((error) => {
                console.log(error);
            });


    },
    async deleteSpecifierFromGroup(context, payload) {
        var data_request = {
            group_id: payload.group_id,
            setting_id: payload.setting_id
        }
        //console.log(data_request)

        let url = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/delete/specifiers`;
        await axios.post(`${url}`, data_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                //console.log(res);
                return 'group_updated'
            }).catch((error) => {
                console.log(error);
            });
    },


    async refactoringSettingsOdds(context, payload) {

        if (payload.type == "groupOdds") {
            var data_request = {
                platform: context.rootGetters['rules/getPlatformId'],
                section: payload.data[0].section,
                sport: payload.data[0].source,
                type: payload.data[0].type,
                product_id: payload.producer,
            }
        } else {

            var data_request = {
                platform: context.rootGetters['rules/getPlatformId'],
                section: payload.data.section,
                sport: payload.data.source,
                type: payload.data.type,
                product_id: payload.producer,
            }
        }


        /*let url = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/schema/`;
        await axios.post(`${url}`, data_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                console.log(res);
                return 'group_updated'
            }).catch((error) => {
                console.log(error);
            });*/


    },

    async fetchSettingsGroupsMarket(context, payload) {
        let url = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/lists/`;
        await axios.post(`${url}`, {
            "section": "sport",
            'sport_id': payload.sport,
            "lang": payload.lang,
            "product_id": payload.product_id,
            "platform": payload.platform,
            "type": payload.type
        }, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                if (res.data.length == 0) {

                    var data_to_save = [];

                } else if (res.data[0].type == "previewOdds") {

                    var data_to_save = res.data[0];

                } else {

                    var data_to_save = res.data[0];

                    // var data = res.data[0].specifiers
                    //
                    // var data_to_save = _.sortBy(data, 'sort');
                    //
                    // _.forEach(data_to_save, function (marketsGroup) {
                    //     _.forEach(marketsGroup.markets, function (market) {
                    //
                    //         var desc = _.find(market.detail, {'lang':payload.lang})
                    //         if(!desc){
                    //             desc = _.find(market.detail, {'lang':'en'})
                    //         }
                    //
                    //         if (market.specifiers != undefined && market.specifiers != '') {
                    //             let spec = {
                    //                 name: desc.text + ' # ' + market.specifiers,
                    //                 unique_id: market.unique_id,
                    //                 id: market.id
                    //             }
                    //
                    //             _.assign(market, spec);
                    //         } else {
                    //
                    //             let name = {
                    //                 name: desc.text,
                    //                 unique_id: market.unique_id,
                    //                 id: market.id
                    //             }
                    //
                    //             _.assign(market, name);
                    //
                    //         }
                    //
                    //     });
                    //
                    // });

                }

                // console.log(data_to_save)

                context.commit('setSettingsGroupsMarket', data_to_save);

            }).catch((error) => {
                console.log(error.message);
                console.log(error.stackTrace);
            });
    },
    async setMatchDetailDialog(context, payload) {
        context.commit("setMatchDetailDialog", payload)
    },

    async resetMatchDetail(context, payload) {
        context.commit("resetMatchDetail")
    },

    async setHistoryDetail(context, payload) {
        context.commit("setHistoryDetail", payload)
    },

    async setHistoryDetailDialog(context, payload) {
        context.commit("setHistoryDetailDialog", payload)
    },

    async fetchHistory(context, payload) {

        let url = `${context.rootGetters['settings/getUrlApi']}feed-messages`;
        await axios.post(`${url}`, {'event': payload.event,}, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(async function (res) {

                var allMessages = []

                await _.forEach(res.data, async function (message) {

                    var allOdds = []

                    // console.log(message.feed.odds)
                    if (message.feed.odds) {

                        await _.forEach(message.feed.odds.market, async function (market) {
                            // console.log(market)
                            await _.forEach(market.outcome, async function (odd) {
                                let singleOdd = {
                                    market_id: market._attributes.id,
                                    market_status: market._attributes.status,
                                    outcome_id: odd._attributes.id,
                                    outcome_value: odd._attributes.odds,
                                    specifiers: (market._attributes.specifiers) ? market._attributes.specifiers : '',
                                    active: odd._attributes.active,
                                }
                                allOdds.push(singleOdd)

                            })
                        })
                    }

                    await allMessages.push({
                        id: message.id,
                        type: message.type,
                        data: message.data,
                        _attributes: message.feed._attributes,
                        sport_event_status: message.feed.sport_event_status,
                        odds: [
                            ...allOdds
                        ]
                    })

                })

                context.commit("setHistoryDetail", allMessages)

            }).catch((e) => {
                console.log(e)

            })

    },


    async updateMarketOutcomesOrder(context, payload) {

        const data_request = payload;
        const url = `${context.rootGetters['settings/getUrl']}markets/outcome/order`;

        return axios.post(`${url}`, data_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                //console.log(res.data);
                return res.data
            }).catch((error) => {
                console.log(error);
            });
    },

    async getMarketOutcomeOrder(context, payload) {

        const data_request = payload;

        let url = `${context.rootGetters['settings/getUrl']}markets/outcome/get-order-info`;
        return axios.post(`${url}`, data_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return res.data
            }).catch((error) => {
                console.log(error);
            });
    },

    async getMarketButtonSetting(context, payload) {
        const url = `${context.rootGetters['settings/getUrl']}markets/buttonsize/platform/${payload.platformId}/market/${payload.marketId}`;

        return axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },

    async saveMarketButtonSetting(context, payload) {

        const url = `${context.rootGetters['settings/getUrl']}markets/buttonsize/platform/${payload.platformId}/market/${payload.marketId}`;
        const payloadRequest = {
            platformId: payload.platformId,
            marketId: payload.marketId,
            sizeOddButtonMd: payload.sizeOddButtonMd,
            sizeOddButtonLg: payload.sizeOddButtonLg,
            sizeOddButtonXl: payload.sizeOddButtonXl
        }

        return axios.post(`${url}`, payloadRequest,  {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },

    async enableMarketsViewSettings(context, payload) {
        const data_request = {
            platformId: payload.platformId,
            sportId: payload.sportId,
            groupId: payload.groupId,
            marketIds: JSON.stringify(payload.marketIds),
            typeMatch: payload.typeMatch
        }

        const url = `${context.rootGetters['settings/getUrl']}settings/sport/groupodds/markets/enable-market-view-settings`;
        return axios.post(`${url}`, data_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
    },

};

const mutations = {

    setPreload: (state, value) => {
        state.preload = value;
    },
    setMarkets(state, payload) {
        //  console.log(payload)
        state.markets = payload;
    },
    setGroupsMarket(state, payload) {
        state.groupsMarket = payload;
    },
    setSettingsGroupsMarket(state, payload) {
        state.settingsGroupsMarket = payload;
    },
    setMatchDetail(state, payload) {
        state.matchDetail = payload;
    },
    setMatchDetailDialog(state, payload) {
        state.matchDetailDialog = payload;
    },

    setOdds(state, payload) {
        state.odds = payload;
    },

    resetMatchDetail(state) {
        state.matchDetail = [];
    },

    resetOdds(state) {
        state.Odds = [];
    },

    setHistoryDetail(state, payload) {
        state.historyDetail = payload;
    },

    setHistoryDetailDialog(state, payload) {
        state.historyDetailDialog = payload;
    },

    setMarketsMini(state, payload) {
        state.marketsMini = payload;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
