<template>
    <div>
        <div>
            <v-container fluid>
                <v-layout row>
                    <v-flex md12>
                        <v-col cols="12">
                            <v-btn v-if="showTree === false" @click="toggleComponent()" class="buttonAction">
                                {{$t('addFeaturedTournament')}}
                            </v-btn>

                            <v-btn v-if="manageFeaturedTournaments === false" @click="toggleComponent()"
                                   class="buttonAction">
                                {{$t('manageTournament')}}
                            </v-btn>
                        </v-col>
                    </v-flex>
                </v-layout>
            </v-container>
        </div>
        <div v-if="showTree">
            <v-container fluid>
                <v-layout row>
                    <v-flex md12>
                        <v-col cols="12">
                            <span class="title-table">{{ this.$t('bets.tree') | capitalize}}</span>
                            <v-card outlined elevation-0>
                                <v-container fluid>
                                    <v-row>
                                        <v-col cols="12" class="pt-0 pb-0">
                                            <div class="title-table text-right"> * {{$t('generic.itemNotTranslated')}}
                                            </div>
                                        </v-col>
                                        <v-col cols="4">

                                            <div class="title-table">{{$t('list')}} {{$t('sport')}}
                                                ({{getItemsSport.length}})
                                            </div>

                                            <v-progress-linear indeterminate color="secondary darken-2"
                                                               v-if="getPreload"></v-progress-linear>
                                            <v-simple-table dense class="table-theme border-all-theme">
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left">{{$t('id')}}</th>
                                                        <th class="text-left">{{$t('name')}}</th>
                                                        <th class="text-left">{{$t('status')}}</th>
                                                        <th class="text-left">Pos.</th>
                                                        <th class="text-left">{{$t('generic.actions')}}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-for="item in getItemsSport" :key="item.id"
                                                        v-bind:class="{ active_element: (sportSearched == item.id) }">
                                                        <td :title="item.id">{{ item.id }}</td>
                                                        <td>{{ item.name }}</td>
                                                        <td class="actions-element">
                                                            <v-icon title="Cambia status"
                                                                    :class="(item.active) ? 'active' : 'disabled'">
                                                                {{ (item.active) ? 'check_circle' : 'highlight_off' }}
                                                            </v-icon>
                                                        </td>
                                                        <td><input v-model.number="item.order" type="number"
                                                                   class="order-input"/>
                                                        </td>
                                                        <td class="actions-element">
                                                            <v-icon title="Show Categories"
                                                                    @click="getCategoriesFromSport(item.id)"
                                                                    v-if="item.active">zoom_in
                                                            </v-icon>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>

                                        <v-col cols="4">

                                            <div class="title-table">{{$t('list')}} {{$t('category')}}
                                                ({{getItemsCategories.length}})
                                            </div>

                                            <v-progress-linear indeterminate color="secondary darken-2"
                                                               v-if="getPreload"></v-progress-linear>
                                            <v-simple-table dense class="table-theme border-all-theme">
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left">{{$t('id')}}</th>
                                                        <th class="text-left">{{$t('name')}}</th>
                                                        <th class="text-left">{{$t('status')}}</th>
                                                        <th class="text-left">Pos.</th>
                                                        <th class="text-left">{{$t('generic.actions')}}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-for="item in getItemsCategories" :key="item.id"
                                                        v-bind:class="{ active_element: (categorySearched == item.id) }">
                                                        <td :title="item.id">{{ item.id }}</td>
                                                        <td>{{ item.name }}</td>
                                                        <td class="actions-element">

                                                            <v-icon title="Cambia status"
                                                                    :class="(item.active) ? 'active' : 'disabled'">
                                                                {{ (item.active) ? 'check_circle' : 'highlight_off' }}
                                                            </v-icon>
                                                        </td>
                                                        <td><input v-model.number="item.order" type="number"
                                                                   class="order-input"/>
                                                        </td>
                                                        <td class="actions-element">
                                                            <v-icon title="Mostra Tornei"
                                                                    @click="getTournamentsFromCategories(item.id)"
                                                                    v-if="item.active">zoom_in
                                                            </v-icon>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                        <v-col cols="4">
                                            <div class="title-table">{{$t('list')}} {{$t('bets.tournament')}}
                                                ({{getItemsTournaments.length}})
                                            </div>

                                            <v-progress-linear indeterminate color="secondary darken-2"
                                                               v-if="getPreload"></v-progress-linear>
                                            <v-simple-table dense class="table-theme border-all-theme">
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left">{{$t('id')}}</th>
                                                        <th class="text-left">{{$t('name')}}</th>
                                                        <th class="text-left">{{$t('status')}}</th>
                                                        <th class="text-left">Pos.</th>
                                                        <th class="text-left" v-if="getPlatformId">
                                                            {{$t('generic.featuredTournament')}}
                                                        </th>
                                                        <!--                                                    <th class="text-left" v-if="getPlatformId">-->
                                                        <!--                                                        {{$t('generic.delete')}}-->
                                                        <!--                                                    </th>-->
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-for="item in getItemsTournaments" :key="item.id">
                                                        <td :title="item.id">{{ item.id }}</td>
                                                        <td class="name-element-tree">{{ item.name }}</td>
                                                        <td class="actions-element">
                                                            <v-icon title="Cambia status"
                                                                    :class="(item.active) ? 'active' : 'disabled'">
                                                                {{ (item.active) ? 'check_circle' : 'highlight_off' }}
                                                            </v-icon>
                                                        </td>
                                                        <td nowrap><input v-model.number="item.order" type="number"
                                                                          class="order-input"/>
                                                        </td>
                                                        <td>

                                                            <v-icon title="Set Tournament in Evidence" dense
                                                                    :class="[isAFeaturedTournament(item.id) ? 'active' : 'disabled', 'featuredTournament']"
                                                                    @click="setTournamentInEvidence(item,$event)">
                                                                star_rate
                                                            </v-icon>

                                                        </td>
                                                        <!--                                                    <td>-->
                                                        <!--                                                        <v-icon title="Remove Tournament in Evidence"-->
                                                        <!--                                                                @click="removeTournamentInEvidence(item,$event)">-->
                                                        <!--                                                            mdi-close-->
                                                        <!--                                                        </v-icon>-->
                                                        <!--                                                    </td>-->
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-flex>
                </v-layout>
            </v-container>

            <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'"
                        :timeout="snackbar.timeout" :vertical="snackbar.mode === 'vertical'">
                {{ snackbar.text }}
                <v-btn dark text @click="snackbar.snackbar = false">
                    {{$t('close')}}
                </v-btn>
            </v-snackbar>

        </div>
        <div v-if="manageFeaturedTournaments">
            <v-container fluid>
                <v-layout row>
                    <v-flex md12>
                        <v-col cols="12">
                            <v-card outlined elevation-0>
                                <v-container fluid>
                                    <v-row>
                                        <v-col cols="12">

                                            <v-col cols="6">
                                                <div class="title-table" style="font-size: 16px;">{{
                                                    this.$t('manageTournament') | capitalize}}
                                                </div>
                                            </v-col>

                                            <v-col cols="6">
                                                <v-btn elevated color="red" style="color: white"
                                                       @click="deleteFeaturedTournamentsForPlatform()">
                                                    {{this.$t('deleteAll')}}
                                                </v-btn>
                                            </v-col>
                                        </v-col>

                                        <v-col cols="6">
                                            <div><span style="font-size: 12px;font-weight: 700">* {{$t('theHighestPriorityTournamentsWillBeDisplayedFirst')}}</span>
                                            </div>

                                            <v-progress-linear indeterminate color="secondary darken-2"
                                                               v-if="getPreload"></v-progress-linear>
                                            <v-simple-table dense class="table-theme border-all-theme">
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left">{{$t('sportId')}}</th>
                                                        <th class="text-left">{{$t('sportName')}}</th>
                                                        <th class="text-left">{{$t('categoryId')}}</th>
                                                        <th class="text-left">{{$t('tournamentId')}}</th>
                                                        <th class="text-left">{{$t('tournamentName')}}</th>
                                                        <th class="text-left">{{$t('priority')}}</th>
                                                        <th class="text-left" style="text-transform: capitalize">
                                                            {{$t('generic.save')}}
                                                        </th>
                                                        <th class="text-left" style="text-transform: capitalize">
                                                            {{$t('generic.delete')}}
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-for="item in featuredTournaments" :key="item._id">
                                                        <td :title="item.id">{{ item.sportId }}</td>
                                                        <td>{{ item.sportName }}</td>
                                                        <td>{{ item.categoryId }}</td>
                                                        <td>{{ item.tournamentId }}</td>
                                                        <td>{{ item.tournamentName }}</td>

                                                        <td><input v-model.number="item.order" type="number"
                                                                   class="order-input"/></td>
                                                        <td class="actions-element">
                                                            <v-icon title="Save" style="cursor:pointer"
                                                                    @click="updateTournamentInEvidence(item)">
                                                                check_circle
                                                            </v-icon>
                                                        </td>

                                                        <td class="actions-element">
                                                            <v-icon title="Delete" style="cursor:pointer"
                                                                    @click="removeTournamentInEvidence(item)">
                                                                check_circle
                                                            </v-icon>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-flex>
                </v-layout>
            </v-container>
        </div>
    </div>

</template>
<style>

</style>
<script>
    import {mapActions, mapGetters, mapMutations} from 'vuex';

    export default {
        name: "FeaturedTournamentSetting",

        data() {
            return {
                dialog: false,
                source: '',
                scope: '',
                sportSearched: '',
                categorySearched: '',
                snackbar: {
                    snackbar: false,
                    color: '',
                    mode: '',
                    timeout: 6000,
                    text: '',
                },
                snackbar: {
                    snackbar: false,
                    color: '',
                    mode: '',
                    timeout: 6000,
                    text: '',
                },
                featuredTournaments: [],
                featuredTournamentsForCategory: [],
                showTree: true,
                manageFeaturedTournaments: false
            }
        },

        components: {},

        watch: {
            getLanguage: async function (val) {
                await this.setPreload(true);
                await this.setLanguage(val);

                await this.filterSport({'language': val});
                await this.filterCategories({'language': val});
                await this.filterTournaments({'language': val});

                await this.setPreload(false);
            }
        },

        computed: {
            ...mapGetters({
                'getPreload': 'utils/getPreload',
                'getLanguage': 'profile/getLanguage',
                'getLanguagesList': 'profile/getLanguagesList',
                'getItemsSport': 'sport/getItems',
                'getCategories': 'category/getCategories',
                'getItemsCategories': 'category/getItems',
                'getTournaments': 'tournament/getTournaments',
                'getItemsTournaments': 'tournament/getItems',
                'getPlatformId': 'rules/getPlatformId',
                'getPlatform': 'profile/getPlatform'
            }),
        },
        async created() {

            await this.setPreload(true);
            await this.fetchSports({'language': this.getLanguage});
            await this.fetchNotPublished();
            await this.setPreload(false);

        },
        async mounted() {
            await this.loadFeaturedTournaments();
        },
        methods: {

            ...mapActions({
                'setPreload': 'utils/setPreload',
                'fetchSports': 'sport/fetchSports',
                'filterSport': 'sport/filterSport',
                'fetchCategories': 'category/fetchCategories',
                'filterCategories': 'category/filterCategories',
                'fetchTournaments': 'tournament/fetchTournaments',
                'filterTournaments': 'tournament/filterTournament',
                'resetTournaments': 'tournament/resetTournaments',
                'updateRules': 'skins/updateRules',
                'fetchSkins': 'skins/fetchSkins',
                'fetchNotPublished': 'tree/fetchNotPublished',
                'getFeaturedTournament': 'tournament/getFeaturedTournament',
                'setFeaturedTournament': 'tournament/setFeaturedTournament',
                'updateFeaturedTournament': 'tournament/updateFeaturedTournament',
                'deleteFeaturedTournament': 'tournament/deleteFeaturedTournament',
                'deleteFeaturedTournamentsForPlatformId': 'tournament/deleteFeaturedTournamentsForPlatformId'
            }),

            ...mapMutations({
                'setLanguage': 'profile/setLanguage',
            }),

            toggleComponent() {

                this.featuredTournamentsForCategory = [];
                this.featuredTournaments = [];
                this.loadFeaturedTournaments();

                this.manageFeaturedTournaments = !this.manageFeaturedTournaments;
                this.showTree = !this.showTree;
            },

            async loadFeaturedTournaments() {

                const platformId = this.getPlatformId;
                const featuredTournamentsResponse = await this.getFeaturedTournament({platformId: platformId});
                const featuredTournaments = featuredTournamentsResponse.data.featuredTournaments;

                this.featuredTournaments = [];
                this.featuredTournaments = featuredTournaments;

                try {
                    await this.getFilteredFeaturedTournamentActive();
                } catch (e) {
                    this.featuredTournamentsForCategory = [];
                    console.log(e)
                }
            },

            async getCategoriesFromSport(sport) {
                await this.setPreload(true);
                this.sportSearched = sport
                await this.fetchCategories({'language': this.getLanguage, 'paramKey': sport});
                await this.resetTournaments()
                await this.setPreload(false);
            },

            async getTournamentsFromCategories(category) {

                await this.setPreload(true);
                this.categorySearched = category
                await this.fetchTournaments({'language': this.getLanguage, 'paramKey': category});

                try {
                    await this.getFilteredFeaturedTournamentActive();
                } catch (e) {
                    this.featuredTournamentsForCategory = [];
                    console.log(e)
                }

                await this.fetchSkins();
                await this.setPreload(false);

            },

            async setTournamentInEvidence(item, event) {

                const platformId = this.getPlatformId;

                if (platformId == null || platformId == '0' || platformId == 0) {
                    return;
                }

                const idTournament = item.id;
                const nameTournament = item.name;
                const order = item.order;

                const featuredTournament = {
                    tournamentId: idTournament,
                    nameTournament: nameTournament,
                    order: order,
                    platformId: platformId
                }

                try {

                    const response = await this.setFeaturedTournament(featuredTournament);
                    await this.loadFeaturedTournaments();

                    this.$swal.fire({
                        position: "center",
                        icon: "success",
                        title: this.$t('successOperation'),
                        showConfirmButton: false,
                        timer: 1000
                    });


                } catch (e) {
                    console.log(e);
                    this.$swal.fire(this.$t('operationNotPerformedCorrectly'), '', 'error');
                }
            },
            async updateTournamentInEvidence(item, event) {

                const platformId = this.getPlatformId;

                if (platformId == null || platformId == '0' || platformId == 0) {
                    return;
                }

                try {

                    const response = await this.updateFeaturedTournament(item);

                    this.$swal.fire({
                        position: "center",
                        icon: "success",
                        title: this.$t('successOperation'),
                        showConfirmButton: false,
                        timer: 1000
                    });

                } catch (e) {
                    console.log(e);
                    this.$swal.fire(this.$t('operationNotPerformedCorrectly'), '', 'error');
                }
            },
            async removeTournamentInEvidence(item, event) {

                const platformId = this.getPlatformId;

                if (platformId == null || platformId == '0' || platformId == 0) {
                    return;
                }

                try {

                    const response = await this.deleteFeaturedTournament({_id: item._id});
                    this.featuredTournaments = this.featuredTournaments.filter(element => {
                        return element._id != item._id
                    })

                    this.$swal.fire({
                        position: "center",
                        icon: "success",
                        title: this.$t('successOperation'),
                        showConfirmButton: false,
                        timer: 1000
                    });

                } catch (e) {
                    console.log(e);
                    this.$swal.fire(this.$t('operationNotPerformedCorrectly'), '', 'error');
                }
            },

            getFilteredFeaturedTournamentActive() {

                const all = this.featuredTournaments;

                const filtered = all.filter(element => {
                    return element.sportId === this.sportSearched.toString() && element.categoryId === this.categorySearched.toString()
                })

                this.featuredTournamentsForCategory = filtered;
                return filtered

            },
            updateOrRemoveFeaturedTournament(elem) {

                if (this.isAFeaturedTournament(elem.id)) {
                    this.removeTournamentInEvidence(elem);
                } else {
                    this.setTournamentInEvidence(elem);
                }
            },
            async deleteFeaturedTournamentsForPlatform() {

                const vm = this;

                const messageWarning = this.$t('generic.areYouSureToDeleteTheseFeaturedTournaments');

                let isExecuted = confirm(messageWarning);

                if (isExecuted === true) {
                    try {
                        const platformId = vm.getPlatformId;

                        if (platformId == null || platformId == '0' || platformId == 0) {
                            return;
                        }

                        const response = await vm.deleteFeaturedTournamentsForPlatformId({platformId: platformId});
                        this.featuredTournaments = [];

                        this.$swal.fire({
                            position: "center",
                            icon: "success",
                            title: this.$t('successOperation'),
                            showConfirmButton: false,
                            timer: 1000
                        });

                    } catch (e) {
                        console.log(e);
                        vm.$swal.fire(vm.$t('operationNotPerformedCorrectly'), '', 'error');
                    }
                }
            },
            isAFeaturedTournament(tournamentId) {

                const findTournament = this.featuredTournamentsForCategory.find(elem => {
                    return elem.tournamentId === tournamentId.toString()
                })
                if (findTournament != null) {
                    return true;
                } else {
                    return false;
                }
            },

        },
    }
</script>

<style scoped>

    .v-icon.notranslate.v-icon--link.v-icon--dense.material-icons.theme--light.active.featuredTournament {
        color: red !important;
    }

    .buttonAction {

        max-width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
    }
</style>
