import store from '../store';
import Skin from '../views/skin/Skin.vue'
import {checkauth, checkauthManager, checkRoute} from "../Helper";
export default [
    {
        path: '/skins',
        name: 'skins',
        component: Skin,
        beforeEnter (to,from,next){
            checkRoute('/skins').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },

]
