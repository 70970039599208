<template>
  <div>
    <v-container fluid>
        <v-layout row>
        <v-flex md12 class="text-center">
            <trendPlatform></trendPlatform>
        </v-flex>
    </v-layout>
<!--        <v-layout row>-->
<!--        <v-flex md4 class="text-center">-->
<!--&lt;!&ndash;          <img src="@/assets/rocky.png" alt="Bentornato">&ndash;&gt;-->
<!--          <BetPlayedForPlatform></BetPlayedForPlatform>-->
<!--        </v-flex>-->
<!--      </v-layout>-->
    </v-container>
  </div>
</template>
<script>
    import BetPlayedForPlatform from "@/components/charts/betPlayedForPlatform";
    import trendPlatform from "@/components/charts/trendsPlatform";
    export default {
      components: {BetPlayedForPlatform, trendPlatform},
      props: {
            source: String,
        },
    }
</script>
