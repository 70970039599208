<template>
  <div>

    <v-dialog v-model="getShowDetail" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-btn icon dark @click="closeDetail()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="closeDetail()">
              {{ $t('generic.save') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-container class="grey lighten-5 no-padding">
          <v-row class="mb-6">
            <v-col sm="6" md="6">
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <td>{{$t('event') | capitalize}}</td>
                    <td>{{getDescription(getEventDetail)}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('date') | capitalize}}</td>
                    <td>{{ (getEventDetail.scheduled != null) ? new Date(getEventDetail.scheduled).toLocaleString() : '' }}</td>
                  </tr>
                  <tr>
                    <td>{{$t('status') | capitalize}}</td>
                    <td>{{ $t(getStatus(getEventDetail.sport_event_status._attributes.status)) | capitalize}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('liveBooked') | capitalize}}</td>
                    <td>{{ (getEventDetail.type == 'match') ? checkLive(getEventDetail.subscription_live) : 'False' | capitalize}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('created_at')}}</td>
                    <td>{{ new Date(getEventDetail.created).toLocaleString() }}</td>
                  </tr>
                  <tr>
                    <td>{{$t('updated_at')}}</td>
                    <td>{{ new Date(getEventDetail.updated).toLocaleString() }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col sm="6" md="6">
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <td>{{$t('bets.type') | capitalize}}</td>
                    <td>{{$t(getEventDetail.type) | capitalize}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('sport')}}</td>
                    <td>{{getDetail(getEventDetail.sport.detail)}} [{{getEventDetail.sport.id}}]</td>
                  </tr>
                  <tr>
                    <td>{{$t('category')}}</td>
                    <td>{{getDetail(getEventDetail.category.detail)}} [{{getEventDetail.category.id}}]</td>
                  </tr>
                  <tr>
                    <td>{{$t('tournament')}}</td>
                    <td>{{getDetail(getEventDetail.tournament.detail)}} [{{getEventDetail.tournament.id}}]</td>
                  </tr>
                  <tr>
                    <td>{{$t('generic.id') | capitalize}}</td>
                    <td>{{getEventDetail.id}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('quickbet') | capitalize}}</td>
                    <td>{{getEventDetail.quickbet}}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col sm="12" md="12" class="pa-5">
              <span class="title-table">{{$t('search')}}</span>
              <v-autocomplete
                  v-model="markets_filtered" :items="this.markets" deletable-chips item-value="id" item-text="text"
                  outlined dense chips small-chips :label="$t('generic.markets')" multiple>
              </v-autocomplete>
              <template v-if="markets_filtered.length > 0">
                <div v-for="(mrkt,index) in getEventDetail.odds" v-if="markets_filtered.includes(mrkt._attributes.id)">
                  <v-simple-table dense class="mt-2">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left text-black">
                          {{ getDetail(mrkt._attributes.market) }} {{mrkt._attributes.specifiers}}
                        </th>
                        <th class="text-left text-black">
                          {{$t('generic.id')}}
                        </th>
                        <th class="text-left text-black">
                          {{$t('uniqueId')}}
                        </th>
                        <th class="text-left text-black">
                          {{$t('ext_Id')}}
                        </th>
                        <th class="text-left text-black">
                          {{$t('value')}}
                        </th>
                        <th class="text-left text-black">
                          {{$t('status')}}
                        </th>
                        <th class="text-left text-black">
                          {{$t('result')}}
                        </th>
                        <th class="text-left text-black">
                          {{$t('lastUpdate')}}
                        </th>
                        <th class="text-left text-black">

                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="outcome in mrkt.outcome" :key="outcome.unique_id">
                        <td>{{ outcome.alias }}</td>
                        <td>{{ outcome._attributes.id }}</td>
                        <td>{{ outcome.unique_id }}</td>
                        <td>{{ outcome._attributes.external_id }}</td>
                        <td>{{ outcome._attributes.odds }}</td>
                        <td>{{ $t(getStatusOutcome(outcome._attributes.status)) | capitalize}}</td>
                        <td>{{ $t(getResultOutcome(outcome._attributes.result,outcome._attributes.status)) | capitalize}}</td>
                        <td>{{ outcome._attributes.lastUpdate }}</td>
                        <td>
                          <v-icon :title="$t('bets.defineResult')" @click="defineResult(outcome.unique_id, getDescription(getEventDetail), mrkt, outcome.alias)">mdi-pencil</v-icon></td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <div class="text-center">
      <v-dialog v-model="dialogDefineSign" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{$t('bets.defineResult')}}
          </v-card-title>

          <v-card-text class="pt-5">
            <p class="font-weight-bold">{{this.descriptionToDefine}}</p>
            <v-select
                :items="results"
                item-text="name"
                item-value="value"
                filled
                v-model="resultToDefine"
                :label="$t('bets.result')"
            ></v-select>
            <v-select
                :items="statuses"
                item-text="name"
                item-value="value"
                filled
                v-model="statusToDefine"
                :label="$t('bets.status')"
            ></v-select>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions v-if="((statusToDefine == 3 && resultToDefine != '') || (statusToDefine != 3 && resultToDefine == '')) && (statusToDefine != '' && resultToDefine != '')">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="sendResult()">
              {{ $t('generic.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-snackbar v-model="snackbar">
      {{$t(this.snackbar_message)}}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          {{$t('generic.close')}}
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import _ from 'lodash';

export default {
  name: "eventDetail",
  components: {},
  data() {
    return {
      markets_filtered:[],
      markets: [],
      snackbar: false,
      snackbar_message: '',
      dialog: false,
      eventToShow: {},
      headers: [
        {text: this.$t('generic.id'), value: 'id', width: '3%', sortable: false},
        {text: this.$t('quickbet'), value: 'quickbet', width: "3%"},
        {text: this.$t('bets.date'), value: 'scheduled', width: "7%"},
        {text: this.$t('bets.event'), value: 'event', width: "20%"},
        {text: this.$t('bets.type'), value: 'type', width: "3%"},
        {text: this.$t('sport'), value: 'sport', width: "5%", sortable: false},
        {text: this.$t('category'), value: 'category', width: "5%", sortable: false},
        {text: this.$t('tournament'), value: 'tournament', width: "8%", sortable: false},
        {text: this.$t('bets.status'), value: 'status', width: "8%"},
        {text: this.$t('bets.actions'), value: 'actions', sortable: false, width: "10%"},
      ],
      options: {},
      results:[
        {'name': this.$t('generic.selectOption'), 'value':''},
        {'name': this.$t('bets.win'), 'value':'win'},
        {'name': this.$t('bets.lose'), 'value':'lose'},
        {'name': this.$t('bets.void'), 'value':'void'},
        {'name': this.$t('bets.cancelled'), 'value':'cancelled'},
        {'name': this.$t('bets.halfLost'), 'value':'halfLost'},
        {'name': this.$t('bets.halfWon'), 'value':'halfWon'},
      ],
      statuses:[
        {'name': this.$t('generic.selectOption'), 'value':''},
        {'name': this.$t('open'), 'value':1},
        {'name': this.$t('suspended'), 'value':2},
        {'name': this.$t('settled'), 'value':3},
      ],
      tooltip: false,
      dialogDefineSign: false,
      signToDefine:{},
      resultToDefine: "",
      statusToDefine: "",
      descriptionToDefine: ""
    }
  },
  computed: {
    ...mapGetters({
      'getLanguage': 'profile/getLanguage',
      'getPreload': 'utils/getPreload',
      'getEventDetail': 'event/getEventDetail',
      'getShowDetail': 'event/getShowDetail',
    }),
  },

  mounted() {
    this.$root.$on('getMarkets', () => {
      this.getEventsMarkets()
    })
  },
  methods: {
    ...mapActions({
      'setShowDetail': 'event/setShowDetail',
      'setPreload': 'utils/setPreload',
      'removeEventDetail': 'event/removeEventDetail',
      'defineOdd': 'bet/defineOdd',
    }),
    async closeDetail(){
      await this.removeEventDetail({})
      await this.setShowDetail(false)
    },
    async showDetail(bet) {
      await this.setShowDetail(false)
      await this.setBetDetail(bet)
      await this.setShowDetail(true)
    },
    async getNewPage() {
      this.getCriteria.page = this.getOptions.page
      this.getCriteria.limit = this.getOptions.limit
      this.getCriteria.platform_id = this.getOptions.platform_id

      await this.setPreload(true);
      await this.fetchEventsList(this.getCriteria)
      await this.setPreload(false);
    },

    getDescription(item){
      if(item.type == 'match'){
        var home = _.find(item.competitors[0].detail, {'lang':this.getLanguage})
        var away = _.find(item.competitors[1].detail, {'lang':this.getLanguage})
        return home.text+' - '+away.text
      }else if(item.type == 'antepost'){
        var tournament = _.find(item.tournament.detail, {'lang':this.getLanguage})
        return 'Antepost '+tournament.text
      }
    },

    getDetail(detail){
      var detail = _.find(detail, {'lang':this.getLanguage})
      return detail.text
    },

    getStatus(status){
      switch (status) {
        case 1:
          return 'nsy'
        case 2:
          return 'inProgress'
        case 3:
          return 'finished'
        case 4:
          return 'cancelled'
        case 5:
          return 'postponed'
        case 6:
          return 'interrupted'
        case 7:
          return 'abandoned'
        case 8:
          return 'coverageLost'
        case 9:
          return 'aboutToStart'
      }
    },

    getStatusOutcome(status){
      switch (status) {
        case 1:
          return 'open'
        case 2:
          return 'suspended'
        case 3:
          return 'settled'
      }
    },

    getResultOutcome(result,status){
      if(status == 3){
        switch (result) {
          case -1:
            return 'cancelled'
          case 1:
            return 'lose'
          case 2:
            return 'win'
          case 3:
            return 'refund'
          case 4:
            return 'halfLost'
          case 5:
            return 'halfWon'
        }
      }else{
        return 'notDefined'
      }

    },

    getEventType(type){
      switch (type){
        case 'match':
          return 'prematch'
        case 'antepost':
          return 'antepost'
      }
    },

    checkLive(subsc){
      return subsc.Type == 1 && subsc.Status == 1;
    },

    getEventsMarkets(){
      var unique = []
      this.markets = []
      this.markets_filtered = []

      _.forEach(this.getEventDetail.odds, (market)=>{
        if(!unique.includes(market._attributes.id)){
          let name = _.find(market._attributes.market, {'lang':this.getLanguage})
          unique.push(market._attributes.id)
          this.markets.push({'id':market._attributes.id, 'text': name.text})
        }
      })
    },
    async defineResult(outcome, event, market, alias){
      var split = outcome.split(';')
      await this.setPreload(true);
      this.resultToDefine = ''
      this.statusToDefine = ''
      this.signToDefine = {
        event_id: +split[0],
        odd_unique_id: outcome,
      }

      this.descriptionToDefine = event + ' | ' + this.getDetail(market._attributes.market) + market._attributes.specifiers + ' > ' + alias
      this.dialogDefineSign = true
      await this.setPreload(false);
    },
    async sendResult(){
      await this.setPreload(true);
      this.signToDefine.result = this.resultToDefine
      this.signToDefine.status = this.statusToDefine
      const result = await this.defineOdd(this.signToDefine)

      setTimeout(async () =>{

        if(result){
          this.updateTableOdd(this.signToDefine)
          this.dialogDefineSign = false
        }

        await this.setPreload(false);

      }, 1500);

    },
    async updateTableOdd(sign){
      console.log(sign)
      var split = sign.odd_unique_id.split(';')
      var market_unique_id = split[1]

      var marketToUpdate = _.find(this.getEventDetail.odds, (market)=>{
        return market._attributes.unique_id.toString() == market_unique_id.toString()
      })

      if(marketToUpdate){
        var outcome_to_update = _.find(marketToUpdate.outcome, {'unique_id': sign.odd_unique_id})

        if(outcome_to_update){
          let result = ''
          if(sign.status == 3){
            switch (sign.result) {
              case 'cancelled':
                result = -1
                break
              case 'lose':
                result = 1
                break
              case 'win':
                result = 2
                break
              case 'void':
                result = 3
                break
              case 'halfLost':
                result = 4
                break
              case 'halfWon':
                result = 5
                break
            }
          }

          outcome_to_update._attributes.result = (sign.status == 3) ? result : ''
          outcome_to_update._attributes.status = sign.status
        }

      }

    }
  },

  watch: {
    resultToDefine: function (newValue, oldValue){
      if(newValue != ''){
        this.statusToDefine = 3
      }else{
        this.statusToDefine = ''
      }
    },
  }

}
</script>

<style scoped>

</style>