<template>
    <div id="entityUserLimitListModal-component">
        <v-dialog transition="dialog-bottom-transition" max-width="60%" v-model="activeDialog"
                  @keydown.esc="closeEntityUserLimitListModal" fullscreen>
            <v-card>
                <v-toolbar color="primary" dark class="text-uppercase" dense>
                    {{ $t('userRules') }}
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark>
                            <v-icon @click="closeEntityUserLimitListModal">mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-container fluid v-if="getPlatformId > 0">
                        <v-layout row>
                            <v-flex md12>
                                <v-col cols="12">
                                    <v-card outlined elevation-0>
                                        <v-row class="title-row">
                                            <v-col md="12" style="height: 80px;">
                                                <v-row class="ml-1">
                                                    <v-col md="4">
                                                        <h2 class="ml-1" style="margin-top: 16px">
                                                            {{getPlatformName()}}</h2>
                                                    </v-col>
                                                    <v-col md="2" offset="1">
                                                    </v-col>
                                                    <v-col md="2">
                                                    </v-col>
                                                    <v-col md="2">
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row class="title-row">
                                            <v-col md="12" style="height: 80px;">
                                                <v-row class="ml-1">
                                                    <v-col md="2">
                                                        <v-text-field
                                                                v-if="userTarget.idUser"
                                                                :value="userTarget.idUser"
                                                                :label="$t('user.id')"
                                                                :readonly="true"
                                                        ></v-text-field>
                                                        <v-text-field
                                                                v-else
                                                                :value="userTarget.userId"
                                                                :label="$t('user.id')"
                                                                :readonly="true"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col md="2">
                                                        <v-text-field
                                                                v-if="userTarget.login"
                                                                :value="userTarget.login"
                                                                :label="$t('user.login')"
                                                                :readonly="true"
                                                        ></v-text-field>

                                                        <v-text-field
                                                                v-else
                                                                :value="userTarget.userName"
                                                                :label="$t('user.login')"
                                                                :readonly="true"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col md="2">
                                                    </v-col>

                                                    <v-col md="2">

                                                    </v-col>
                                                    <v-col md="4" class="container-action-btn">
                                                        <v-btn color="primary" v-if="enableRulesListComponent"
                                                               @click="showAddRuleComponent()"
                                                               class="mart-2 actionButton">
                                                            {{ $t('risk.addRule') }}
                                                        </v-btn>

                                                        <v-btn color="primary" v-if="enableCreateRuleComponent"
                                                               @click="showRuleComponent()"
                                                               class="mart-2 actionButton">
                                                            {{ $t('risk.showRules') }}
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-flex>
                        </v-layout>
                    </v-container>

                    <entity-limit-list v-if="enableRulesListComponent && userTarget != null"
                                       :userTarget=userTarget></entity-limit-list>
                    <entity-limit-create v-if="enableCreateRuleComponent && userTarget != null"
                                         :userTarget=userTarget></entity-limit-create>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import EntityLimitList from "./entityLimitList";
    import EntityLimitCreate from "./entityLimitCreate"
    import _ from "lodash";

    export default {
        name: "EntityUserLimitListModal",
        props: {
            userTarget: {
                type: Object,
                required: true
            }
        },
        components: {
            EntityLimitList,
            EntityLimitCreate
        },
        data() {
            return {
                activeDialog: true,
                enableCreateRuleComponent: false,
                enableRulesListComponent: true
            }
        },
        async mounted() {

        },
        beforeDestroy() {

        },
        computed: {
            ...mapGetters({
                'getPlatformId': "rules/getPlatformId",
                'getSkinFilters': 'skins/getSkinFilters',
                'getPlatform': "profile/getPlatform",
            }),
        },
        watch: {},
        methods: {
            ...mapActions({}),

            async closeEntityUserLimitListModal() {
                this.activeDialog = false;
                await this.$root.$emit('closedUpdateEntityUserLimitDialog');
            },
            getPlatformName() {
                if (this.getPlatformId == 0) {
                    return "Manager";
                } else {
                    let platform = _.find(this.getSkinFilters, {platform_id: this.getPlatformId})
                    return (platform && platform.name) ? platform.name : 'Error name skin'
                }
            },
            showRuleComponent() {
                this.enableCreateRuleComponent = false;
                this.enableRulesListComponent = true;
            },
            showAddRuleComponent() {
                this.enableCreateRuleComponent = true;
                this.enableRulesListComponent = false;
            },
        },
    }
</script>

<style scoped>
    .container-action-btn {
        margin-top: 10px;
    }

    .container-action-btn .actionButton {
        margin-right: 20px;
        float: right;
    }
</style>

