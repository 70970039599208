import axios from "axios";
import _ from "lodash";
import user from "../../router/user";

const state = {
    manager: [],
    list: [],
    list_simple: [],
    ruleslives: []
};

const getters = {
    getManager: state => {
        return state.manager;
    },
    getList: state => {
        return state.list;
    },
    getListSimple: state => {
        return state.list_simple;
    },
    getRulesLives: state => {
        return state.ruleslives;
    },

    getSkinFilters: state => {

        var skins = [{name: 'All', platform_id: ""}]
        _.forEach(state.list, (skin) => {
            if (skin.active && skin.platform_id > 0) {
                skins.push({'name': skin.name, 'platform_id': skin.platform_id, 'settings': skin.settings, 'signUp': skin.signUp,'ai_safebet': skin.ai_safebet})
            }
        })

        return skins
    }

};

const actions = {
    async updateRulesSocket(context, payload) {

        console.log("RULES", payload)
    },
    async fetchSkins(context) {

        let url = `${context.rootGetters['settings/getUrlRule']}skins/`;
        await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(res => {

                let manager = _.find(res.data.data, {'platform_id': 0});
                var skins = _.orderBy(res.data.data, ['name'])

                context.commit('setManager', manager);
                context.commit('setSkinsList', skins);

            })
            .catch(err => {
                console.log(err);

                if (err.response.status === 401) {
                    context.commit('profile/setLogout', null, {root: true});
                    window.location.href = '/#/login';
                }

            });
    },
    async fetchSkinsSimple(context) {

        let url = `${context.rootGetters['settings/getUrlRule']}skins/list-skin/`;
        await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(res => {

                let manager = _.find(res.data.data, {'platform_id': 0});
                var skins = _.orderBy(res.data.data, ['name'])

                context.commit('setManager', manager);
                context.commit('setSkinsListSimple', skins);

            })
            .catch(err => {
                console.log(err);

                if (err.response.status === 401) {
                    context.commit('profile/setLogout', null, {root: true});
                    window.location.href = '/#/login';
                }

            });
    },
    async fetchRules(context) {

        let url = `${context.rootGetters['settings/getUrlApi']}get-rules-live/`;
        await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(res => {
                //  console.log("data",res.data.data)
                context.commit('setRulesLives', res.data.data);


            })
            .catch(err => {
                console.log(err);

                if (err.response.status === 401) {
                    context.commit('profile/setLogout', null, {root: true});
                    window.location.href = '/#/login';
                }

            });
    },

    async updateRulesDisable(context, payload) {
        let url = `${context.rootGetters['settings/getUrlRule']}skins/update/rules/disable`;

        await axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                // console.log(res);
            }).catch((error) => {
                // console.log(error);
            });
    },
    async updateRulesFavourite(context, payload) {
        let url = `${context.rootGetters['settings/getUrlRule']}skins/update/rules/favourite`;

        await axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                // console.log(res);
            }).catch((error) => {
                // console.log(error);
            });
    },
    async updateRulesLive(context, payload) {
        let url = `${context.rootGetters['settings/getUrlRule']}skins/update/rules/live`;

        await axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                context.commit('setRulesLives', res.data.data);

            }).catch((error) => {
                // console.log(error);
            });
    },
    async updateRulesStatscore(context, payload) {


        let url = `${context.rootGetters['settings/getUrlRule']}skins/update-rules-statscore/${context.rootGetters['rules/getPlatformId']}`;

        await axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                // console.log(res);
            }).catch((error) => {
                // console.log(error);
            });


        fetch('https://api.ipify.org?format=json')
            .then(x => x.json())
            .then((ip) => {


                var log_payload = {
                    user_id: context.rootGetters['profile/getId'],
                    rule_id: context.rootGetters['rules/getRuleId'],
                    platform: context.rootGetters['rules/getPlatformId'],
                    type: payload.typeRule,
                    scope: payload.scope,
                    source: payload.source,
                    ip: ip.ip,
                    detail: JSON.stringify(detail_log)
                }


                let url = `${context.rootGetters['settings/getUrl']}history/store`;
                axios.post(`${url}`, log_payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
                    .then((res) => {
                        // console.log(res);
                    }).catch((error) => {
                    // console.log(error);
                });


            });


    },
    async updateRulesStatscorePrematch(context, payload) {


        let url = `${context.rootGetters['settings/getUrlRule']}skins/update-rules-statscoreprematch/${context.rootGetters['rules/getPlatformId']}`;

        await axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                // console.log(res);
            }).catch((error) => {
                // console.log(error);
            });


        fetch('https://api.ipify.org?format=json')
            .then(x => x.json())
            .then((ip) => {


                var log_payload = {
                    user_id: context.rootGetters['profile/getId'],
                    rule_id: context.rootGetters['rules/getRuleId'],
                    platform: context.rootGetters['rules/getPlatformId'],
                    type: payload.typeRule,
                    scope: payload.scope,
                    source: payload.source,
                    ip: ip.ip,
                    detail: JSON.stringify(detail_log)
                }


                let url = `${context.rootGetters['settings/getUrl']}history/store`;
                axios.post(`${url}`, log_payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
                    .then((res) => {
                        // console.log(res);
                    }).catch((error) => {
                    // console.log(error);
                });


            });


    },


    async updateRules(context, payload) {

        if (payload.rule.old_rule != undefined) {

            var detail_log = {
                operation: "update",
                new: payload.rule,
                old: payload.rule.old_rule
            }

            delete payload.rule.old_rule;
        } else {
            var detail_log = {
                operation: "create",
                rule: payload.rule,
            }
        }


        var payload_request = {
            "typeRule": payload.typeRule,
            "scope": payload.scope,
            "source": payload.source,
            "rule": JSON.stringify(payload.rule)
        }


        let url = `${context.rootGetters['settings/getUrlRule']}skins/update-rules/${context.rootGetters['rules/getPlatformId']}`;

        await axios.post(`${url}`, payload_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                // console.log(res);
            }).catch((error) => {
                // console.log(error);
            });


        fetch('https://api.ipify.org?format=json')
            .then(x => x.json())
            .then((ip) => {


                var log_payload = {
                    user_id: context.rootGetters['profile/getId'],
                    rule_id: context.rootGetters['rules/getRuleId'],
                    platform: context.rootGetters['rules/getPlatformId'],
                    type: payload.typeRule,
                    scope: payload.scope,
                    source: payload.source,
                    ip: ip.ip,
                    detail: JSON.stringify(detail_log)
                }


                let url = `${context.rootGetters['settings/getUrl']}history/store`;
                axios.post(`${url}`, log_payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
                    .then((res) => {
                        // console.log(res);
                    }).catch((error) => {
                    // console.log(error);
                });


            });


    },

    async updateRulesAbbreviation(context, payload) {


        var payload_request = {
            "typeRule": payload.typeRule,
            "scope": payload.scope,
            "source": payload.source,
            "printodds": JSON.stringify(payload.printodds)
        }


        let url = `${context.rootGetters['settings/getUrlRule']}skins/update-rules-abbreviation/${context.rootGetters['rules/getPlatformId']}`;

        await axios.post(`${url}`, payload_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                // console.log(res);
            }).catch((error) => {
                // console.log(error);
            });


        /*fetch('https://api.ipify.org?format=json')
            .then(x => x.json())
            .then(( ip ) => {


                var log_payload = {
                    user_id: context.rootGetters['profile/getId'],
                    rule_id: context.rootGetters['rules/getRuleId'],
                    platform: context.rootGetters['rules/getPlatformId'],
                    type: payload.typeRule,
                    scope: payload.scope,
                    source: payload.source,
                    ip: ip.ip,
                    detail: JSON.stringify(detail_log)
                }


                let url = `${context.rootGetters['settings/getUrl']}history/store`;
                axios.post(`${url}`, log_payload,{'headers': { 'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`} })
                    .then((res) =>{
                        // console.log(res);
                    }).catch((error) => {
                    // console.log(error);
                });


            });*/


    },

    async deleteRules(context, payload) {

        var payload_request = {
            "typeRule": payload.typeRule,
            "scope": payload.scope,
            "source": payload.source,
            "rule": JSON.stringify(payload.rule)
        }

        let url = `${context.rootGetters['settings/getUrl']}skins/delete-rules/${context.rootGetters['rules/getPlatformId']}`;
        await axios.post(`${url}`, payload_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                // console.log(res);
            }).catch((error) => {
                // console.log(error);
            });


        fetch('https://api.ipify.org?format=json')
            .then(x => x.json())
            .then((ip) => {

                let detail_log = {
                    operation: "delete",
                    rule: payload.rule.detail
                }

                var log_payload = {
                    user_id: context.rootGetters['profile/getId'],
                    rule_id: context.rootGetters['rules/getPlatformId'],
                    platform: context.rootGetters['rules/getPlatformId'],
                    type: payload.typeRule,
                    scope: payload.scope,
                    source: payload.source,
                    ip: ip.ip,
                    detail: JSON.stringify(detail_log)
                }


                let url = `${context.rootGetters['settings/getUrl']}history/store`;
                axios.post(`${url}`, log_payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
                    .then((res) => {
                        // console.log(res);
                    }).catch((error) => {
                    // console.log(error);
                });


            });


    },


    async publishElement(context, payload) {

        var payload_request = {
            "scope": payload.scope,
            "source": payload.source,
            "active": payload.active
        }


        let url = `${context.rootGetters['settings/getUrl']}skins/publish`;
        await axios.post(`${url}`, payload_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                // console.log(res);
            }).catch((error) => {
                // console.log(error);
            });


    },

    async importFromManagerRules(context, payload) {


        var payload_request = {

            "scope": payload.scope
        }

        let url = `${context.rootGetters['settings/getUrl']}skins/import-rules-from-manager/${context.rootGetters['rules/getPlatformId']}`;

        await axios.post(`${url}`, payload_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                // console.log(res);
            }).catch((error) => {
                // console.log(error);
            });


    },
    async importRulesShockFromManager(context, payload) {


        var payload_request = {

            "scope": payload.scope
        }

        let url = `${context.rootGetters['settings/getUrl']}skins/import-rules-shock-from-manager/${context.rootGetters['rules/getPlatformId']}`;

        await axios.post(`${url}`, payload_request, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                // console.log(res);
            }).catch((error) => {
                // console.log(error);
            });


    },

    async checkIfEventIsEnabled(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}skins/event/check-enabled`;

        return await axios.post(`${url}`,
            {...payload},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return res
            }).catch((error) => {
                console.log(error);
            });
    },
    async getUsers(context, payload) {

        const platformId = payload.platformId;
        const userType =  payload.userType;

        const requestObj = {
            platformId: platformId,
            userType: userType
        }
        const url = `${context.rootGetters['settings/getUrl']}users/get-users-list`;
        return await axios.get(`${url}`,  {params: requestObj,'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(res => {
                return res
            })
            .catch(err => {
                console.log(err);
            });
    },
    async getSignUpUsers(context, payload){
        const platformId = payload.platformId;
        const userType =  payload.userType;
        const page =  payload.page;
        const skin_id =  payload.skin_id;
        const status = payload.status;
        const userName = payload.userName;
        const userId = payload.userId;
        const requestObj = {
            platformId: platformId,
            userType: userType,
            page: page,
            skin_id: skin_id,
            status : status,
            userName : userName,
            userId : userId,
        }


            let url = `${context.rootGetters['settings/getUrl']}users/get-users-list-signup`;
            return await axios.get(`${url}`,  {params: requestObj,'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
                .then(res => {
                    return res.data
                })
                .catch(err => {
                    console.log(err);
                });


    },
    async getNewSignUpUsers(context, payload){
        const platformId = payload.platformId;
        const userType =  payload.userType;
        const page =  payload.page;
        const skin_id =  payload.skin_id;
        const status = payload.status;
        const userName = payload.userName;
        const userId = payload.userId;
        const requestObj = {
            platformId: platformId,
            userType: userType,
            page: page,
            skin_id: skin_id,
            status : status,
            userName : userName,
            userId : userId,
        }


            let url = `${context.rootGetters['settings/getUrl']}users/get-new-users-list-signup`;
            return await axios.get(`${url}`,  {params: requestObj,'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
                .then(res => {
                    return res.data
                })
                .catch(err => {
                    console.log(err);
                });


    },
    async blockUser(context, payload) {
        const platformId = (!payload.platform_id) ? context.rootGetters['rules/getPlatformId'] : payload.platform_id;

        const targetUser = payload.idUser ? payload.idUser : payload.userId;
        const url = `${context.rootGetters['settings/getUrl']}users/block`

        const dataRequest = {
            platformId: platformId,
            idUser: targetUser,
            subnet: false
        }
        return await axios.post(`${url}`,
            {...dataRequest},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                if(res && res.data && res.data.result && res.data.result == true){
                    const payloadReq = {
                        idUser: targetUser,
                        platformId: platformId
                    }
                    context.commit('users/ADD_USER_IN_BLOCKED_STATUS_FOR_A_PLATFORM', payloadReq, {root: true});
                }

                return res
            }).catch((error) => {
                console.log(error);
            });
    },
    async unlockUser(context, payload) {
        const platformId = (!payload.platform_id) ? context.rootGetters['rules/getPlatformId'] : payload.platform_id;
        const targetUser = payload.idUser ? payload.idUser : payload.userId;
        const url = `${context.rootGetters['settings/getUrl']}users/unlock`

        const dataRequest = {
            platformId: platformId,
            idUser: targetUser,
            subnet: false
        }

        return await axios.post(`${url}`,
            {...dataRequest},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                console.log('La risposta è res:')
                console.log(res)



                if(res && res.data && res.data.result && res.data.result == true){
                    const payloadReq = {
                        idUser: targetUser,
                        platformId: platformId
                    }
                    context.commit('users/REMOVE_USER_IN_BLOCKED_STATUS_FOR_A_PLATFORM', payloadReq, {root: true});

                }
                return res
            }).catch((error) => {
                console.log(error);
            });
    },

    async blockUserSubnet(context, payload) {
        const platformId = (!payload.platform_id) ? context.rootGetters['rules/getPlatformId'] : payload.platform_id;

        const targetUser = payload.idUser ? payload.idUser : payload.userId;
        const url = `${context.rootGetters['settings/getUrl']}users/blockSubnet`

        const dataRequest = {
            platformId: platformId,
            idUser: targetUser,
            subnet: true
        }
        console.log(dataRequest)
        return await axios.post(`${url}`,
            {...dataRequest},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                if(res && res.data && res.data.result && res.data.result == true){
                    const payloadReq = {
                        idUser: targetUser,
                        platformId: platformId
                    }
                    context.commit('users/ADD_USER_IN_BLOCKED_STATUS_FOR_A_PLATFORM', payloadReq, {root: true});
                }

                return res
            }).catch((error) => {
                console.log(error);
            });
    },
    async unlockUserSubnet(context, payload) {
        const platformId = (!payload.platform_id) ? context.rootGetters['rules/getPlatformId'] : payload.platform_id;
        const targetUser = payload.idUser ? payload.idUser : payload.userId;
        const url = `${context.rootGetters['settings/getUrl']}users/unlockSubnet`

        const dataRequest = {
            platformId: platformId,
            idUser: targetUser,
            subnet: true
        }

        return await axios.post(`${url}`,
            {...dataRequest},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                console.log('La risposta è res:')
                console.log(res)



                if(res && res.data && res.data.result && res.data.result == true){
                    const payloadReq = {
                        idUser: targetUser,
                        platformId: platformId
                    }
                    context.commit('users/REMOVE_USER_IN_BLOCKED_STATUS_FOR_A_PLATFORM', payloadReq, {root: true});

                }
                return res
            }).catch((error) => {
                console.log(error);
            });
    },

};

const mutations = {
    setSkinsList(state, payload) {
        state.list = payload;
    },
    setSkinsListSimple(state, payload) {
        state.list_simple = payload;
    },
    setManager(state, payload) {
        state.manager = payload;
    },
    setRulesLives(state, payload) {
        state.ruleslives = payload;
    },

};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
