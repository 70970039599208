<template>
  <div id="betsReviewComponent" style="background: #EAF0F3">
    <div class="alert-container">

      <transition name="fade" v-if="alertSuccessEnabled">
        <v-alert type="success" >
          {{$t('successOperation')}}
        </v-alert>
      </transition>

      <transition name="fade" v-if="alertErrorEnabled">
        <v-alert type="error">
          {{$t('operationNotPerformedCorrectly')}}
        </v-alert>
      </transition>
    </div>
    <v-container fluid>
      <v-layout row>

        <!-- TODO AM STILL WORKING ON GRAPHICS MOBILE/TABLET:: CHECK ME BEFORE COMMIT -->
        <v-flex md3 class="content-bet" v-for="item in getReserve">
          <v-col cols="12">
            <v-card outlined elevation-0 v-bind:class="{'bk-live': (item.bet_context == 'live' || item.bet_context == 'mixed' ), 'bk-repeat': item.history[0].description.includes('repeated', 'ripetuta','repetida','repetida')}">
              <v-container fluid>
                <v-form :ref="'id_' + item.code">
                  <v-row>
                    <v-col cols="4" xl="4" lg="4" md="4" sm="4" class="text-left" style="padding:4px;">
                      <small class="font75"><strong>{{ (getTimezone != '') ? new Date(item.created_at).toLocaleString(undefined,{timeZone: getTimezone})+'*' : new Date(item.created_at).toLocaleString() }}</strong></small>
                    </v-col>
                    <v-col cols="4" xl="5" lg="5" md="5" sm="4" class="text-left" style="padding:4px;">
                      <small><strong>{{ item.code }}</strong></small>
                    </v-col>
                    <v-col cols="4" xl="3" lg="3" md="3" sm="4" class="text-center" style="padding:4px;">
                      <div class="context-bet" :class="item.bet_context">
                        <span>{{ item.bet_context }}</span>
                      </div>
                    </v-col>

                    <v-col cols="6" xl="6" lg="6" md="6" sm="6" class="pt-0">
                      <v-btn block depressed color="#4ecd00" size="x-small" class="white--text" @click="placeBet(item)" :disabled="item.loadingBetAction"><v-icon large>mdi-thumb-up</v-icon> {{ $t('risk.accept') }}</v-btn>
                    </v-col>

                    <v-col cols="6" xl="6" lg="6"  md="6" sm="6" class="pt-0">
                      <v-btn block depressed color="#f70034" size="x-small" class="white--text"  @click="rejectBet(item)" :disabled="item.loadingBetAction"><v-icon large>mdi-thumb-down</v-icon> {{ $t('risk.reject') }}</v-btn>
                    </v-col>

                    <!--                  <v-col md="4">-->
                    <!--                    <v-btn block depressed color="#eeeeee" size="x-small">{{ $t('generic.reset') }}</v-btn>-->
                    <!--                  </v-col>-->

                    <v-col cols="12" xl="4" lg="6" md="6" sm="6" class="pt-0">
                      <v-btn block depressed color="#eaa900" size="x-small" class="white--text" @click="getBetLists(item)">{{ $t('betList') }}</v-btn>
                    </v-col>

                    <v-col cols="12" xl="4" lg="6" md="6" sm="6"  class="pt-0">
                      <network-detail :platform_id="item.platform_id" :user_id="item.user_id"></network-detail>
                    </v-col>

                    <v-col cols="12" xl="4" lg="6" md="6" sm="6"  class="pt-0">
                      <user-info :platform_id="item.platform_id" :user_id="item.user_id" :bet="item" type_btn="btn"></user-info>
                    </v-col>


                  </v-row>

                  <v-row v-if="getPlatformId == 0">
                    <v-col md="4" class="pt-0" v-if="isUserLocked(item.user_id, item.platform_id)">
                      <span class="userInfoLabel" @click="unlockUser(item.user_id, item.platform_id)">
                        <v-icon size="small">mdi-lock</v-icon>
                        {{ item.login }}
                      </span>
                      <span class="blocked-info"> {{$t('generic.user')}} {{$t('user.blocked')}}</span>
                    </v-col>
                    <v-col md="4" class="pt-0" v-else>
                      <span class="userInfoLabel" @click="lockUser(item.user_id, item.platform_id)">
                        <v-icon size="small">mdi-lock</v-icon>
                        {{ item.login }}
                      </span>
                    </v-col>
                    <v-col md="4" class="pt-0  text-center">
                      <span class="userInfoLabel">
                       {{getPlatformName(item.platform_id)}}
                      </span>
                    </v-col>

                    <v-col md="4" class="text-right pt-0">
                      <span class="userInfoLabel">
                        <v-icon size="small">mdi-lock</v-icon>
                        {{ item.agencyLogin }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col md="4" class="pt-0" v-if="isUserLocked(item.user_id, item.platform_id)">
                      <span class="userInfoLabel text-f16"  @click="unlockUser(item.user_id, item.platform_id)">
                        <v-icon size="small">mdi-lock</v-icon>
                        {{ item.login }}
                      </span>
                      <span class="blocked-info"> {{$t('generic.user')}} {{$t('user.blocked')}}</span>
                    </v-col>
                    <v-col md="4" class="pt-0" v-else>
                      <span class="userInfoLabel text-f16"  @click="lockUser(item.user_id, item.platform_id)">
                        <v-icon size="small">mdi-lock-open</v-icon>
                        {{ item.login }}
                      </span>
                      <!--                      <span style="color:green"> {{$t('generic.user')}} {{$t('user.unlocked')}}</span>-->
                    </v-col>
                    <v-col md="4" class="pt-0  text-center">
                      <span class="userInfoLabel">
                       {{getPlatformName(item.platform_id)}}
                      </span>
                    </v-col>
                    <template v-if="item.ancestor != null && item.ancestorLogin != null" >
                      <v-col md="4" class="text-right pt-0" v-if="isUserLocked(item.ancestor, item.platform_id)">
                      <span class="userInfoLabel" @click="unlockUserSubnet(item.ancestor, item.platform_id)">
                        <v-icon size="small">mdi-lock</v-icon>
                        {{item.ancestorLogin}}
                      </span>
                        <span class="blocked-info"> {{$t('user.agency')}} {{$t('user.blocked')}}</span>
                      </v-col>
                      <v-col md="4" class="text-right pt-0" v-else>
                      <span class="userInfoLabel" @click="lockUserSubnet(item.ancestor, item.platform_id)">
                        <v-icon size="small">mdi-lock-open</v-icon>
                      {{item.ancestorLogin}}
                      </span>
                      </v-col>
                    </template>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="12" sm="12" class="pb-0 ptb">
                      <v-text-field class="text-capitalize " :label="$t('issuerLoginBet')" :value="item.issuer_login" dense outlined readonly ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" md="6" sm="6" class="pb-0 ptb">
                      <v-text-field class="text-capitalize " label="IP" :value="item.extra_data.ip" dense outlined readonly ></v-text-field>
                    </v-col>

                    <v-col cols="6" md="6" sm="6" class="pb-0 ptb">
                      <v-text-field :ref="item.code+'_amount'" @input="event => item.amount_real = event" class="text-capitalize " type="number" :prefix="item.currencySymbol" step="0.01" :label="$t('amount')" :value="item.amount_real" dense outlined style="font-weight: 700"></v-text-field>
                    </v-col>

                    <v-col  cols="6" md="6" sm="6" class="pt-0 pb-0 ptb">
                      <v-text-field class="text-capitalize " :label="$t('bets.winning')" :value="getWinning(item)" dense outlined readonly style="font-weight: 700"></v-text-field>
                    </v-col>

                    <v-col  cols="6" md="3" sm="6" class="pt-0 pb-0 ptb">
                      <v-text-field class="text-capitalize " :label="$t('bets.events')" :value="item.odds.length" dense outlined readonly ></v-text-field>
                    </v-col>

                    <v-col cols="6" md="3" sm="6" class="pt-0 pb-0 ptb">
                      <v-text-field v-if="item.type == 'single'" class="text-capitalize" :label="$t('bets.odds')" :value="item._multiple_detail.odds_total" dense outlined readonly ></v-text-field>
                      <v-text-field v-if="item.type == 'multiple'" class="text-capitalize" :label="$t('bets.odds')" :value="item._multiple_detail.odds_total" dense outlined readonly ></v-text-field>
                      <v-text-field v-if="item.type == 'integral'" class="text-capitalize" :label="$t('bets.odds')" :value="item._integral_detail.odds_total_max" dense outlined readonly ></v-text-field>
                      <v-text-field v-if="item.type == 'system'" class="text-capitalize" :label="$t('bets.odds')" value="N.D." dense outlined readonly ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0">
                    <v-col cols="12" md="12" sm="12"  class="pt-0 pb-0">
                      <v-flex class="red block text-center msg-error" v-for="(risk, index) in item.riskResult.data" :key="'riskErrorMessage_'+index"  v-if="risk.response == 'check'">{{ risk.message }}</v-flex>
                    </v-col>
                  </v-row>

                  <v-row class="mt-2"  v-if="item.agencyNote" @click="openMessageDialog(item, 'agency')" >
                    <v-col cols="12" md="12" sm="12" class="pt-1 pb-1">
                      <v-flex class="block text-center msg-note-agency">{{ item.agencyNote }}</v-flex>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2"  v-else @click="openMessageDialog(item, 'agency')">
                    <v-col cols="12" md="12" sm="12"  class="pt-1 pb-1">
                      <v-flex class="block text-center msg-note-agency">{{$t('addNote')}}</v-flex>
                    </v-col>
                  </v-row>

                  <v-row class="mt-2"  v-if="item.userNote" @click="openMessageDialog(item, 'user')">
                    <v-col cols="12" md="12" sm="12"  class="pt-1 pb-1">
                      <v-flex class="block text-center msg-note">{{ item.userNote }}</v-flex>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2" v-else @click="openMessageDialog(item, 'user')">
                    <v-col cols="12" md="12" sm="12"  class="pt-1 pb-1">
                      <v-flex class="block text-center msg-note">{{$t('addNote')}}</v-flex>
                    </v-col>
                  </v-row>
                  <v-row class="bet-info" >
                    <v-col cols="12" lg="12" md="12" sm="12"  class="pt-0 ptb" v-for="(odd, index) in item.odds" :key="index">
                      <span class="odd-event-description hoverTitle"  :title="'\n'+odd.sport.name+'\n'+odd.category.name+'\n'+odd.tournament.name+'\n'+odd.market_name + ' ' + getSpread(odd.market_unique_id) + '\n'+odd.outcome_alias">
                        <h5>
                          <span v-if="odd.review" ><v-icon small style="color:red">mdi-alert</v-icon> </span> {{ odd.event_description }}
                          <small v-if="odd.type == 'live'" style="font-size: 12px; color: #000;font-weight: 600;">
                            (<v-icon small>mdi-target</v-icon>{{(odd.result_live) ? odd.result_live : ''}} <v-icon small>mdi-clock-time-eight-outline</v-icon>{{ (odd.time_live) ? odd.time_live+"'" : ''}})
                        </small>
                        </h5>
                      </span>
                      <v-row class="bet-info">
                        <v-col cols="12" lg="4" md="4" sm="4"  class="pt-4 pt-0 pb-0">
                          <v-text-field :value="(odd.outcome_alias != undefined && odd.outcome_alias != '') ? odd.outcome_alias : odd.outcome_name" class="disabled-dark" dense outlined readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="4" md="4" sm="4"  class="pt-4 pt-0 pb-0">
                          <v-text-field :value="getMarketName(odd)" class="disabled-dark" dense outlined readonly ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="4" md="4" sm="4"  class="pt-4 pt-0 pb-0">
                          <v-text-field :ref="item.code+'_odds'" :value="odd.outcome_odd_value" @input="event => odd.outcome_odd_value = event" type="number" min="0" step="0.01" dense outlined></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card>
          </v-col>
        </v-flex>

      </v-layout>
    </v-container>

    <v-dialog transition="dialog-bottom-transition" max-width="90%" v-model="showModalBetDetail" @click:outside="removeModalBetLists">
      <v-card>
        <v-toolbar color="primary" dark class="text-uppercase" dense>
          {{ $t('betList') }}
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="removeModalBetLists">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-data-table :headers="headersBetLists" :items="getMyBetsAll" class="elevation-1 head-capitalize" :loading="getPreload" disable-pagination hide-default-footer>

            <template v-slot:item.device="{ item }">
              <v-icon v-if="item.extra_data && item.extra_data.isDesktop" title="Desktop">mdi-desktop-mac</v-icon>
              <v-icon v-else-if="item.extra_data && item.extra_data.isMobile" title="Smartphone">mdi-cellphone</v-icon>
              <v-icon v-else-if="item.extra_data && item.extra_data.isTablet" title="Tablet">mdi-tablet-ipad</v-icon>

              <v-icon v-if="item.extra_data && item.extra_data.isAndroid" :title="item.extra_data.platform + ' - ' + item.extra_data.os">mdi-android</v-icon>
              <v-icon v-else-if="item.extra_data && item.extra_data.isMac" :title="item.extra_data.platform + ' - ' + item.extra_data.os">mdi-apple-ios</v-icon>
              <v-icon v-else-if="item.extra_data && item.extra_data.isWindows" :title="item.extra_data.platform + ' - ' + item.extra_data.os">mdi-microsoft-windows</v-icon>

              <v-icon v-if="item.extra_data && item.extra_data.isChrome" :title="item.extra_data.browser">mdi-google-chrome</v-icon>
              <v-icon v-else-if="item.extra_data && item.extra_data.isEdge" :title="item.extra_data.browser">mdi-web</v-icon>
              <v-icon v-else-if="item.extra_data && item.extra_data.isFirefox" :title="item.extra_data.browser">mdi-firefox</v-icon>
              <v-icon v-else-if="item.extra_data && item.extra_data.isOpera" :title="item.extra_data.browser">mdi-opera</v-icon>
              <v-icon v-else-if="item.extra_data && item.extra_data.isSafari" :title="item.extra_data.browser">mdi-apple-safari</v-icon>
            </template>

            <template v-slot:item.code="{ item }">
              <span class="font-weight-bold"><v-icon small v-if="item.status == 'review'">mdi-alert</v-icon>{{ item.code }}</span>
            </template>

            <template v-slot:item.type="{ item }">
              <span class="text-uppercase">{{ item.type }}</span>
            </template>

            <template v-slot:item.odds_sources="{ item }">
              <span class="text-uppercase">{{ item.odds_sources.length }}</span>
            </template>

            <template v-slot:item.created_at="{ item }">
              <span>{{ (getTimezone != '') ? new Date(item.created_at).toLocaleString(undefined,{timeZone: getTimezone})+'*' : new Date(item.created_at).toLocaleString() }}</span>
            </template>

            <template v-slot:item.amount="{ item }">
              {{ item.currencySymbol +' '+parseFloat(item.amount_real).toFixed(2)}}
            </template>

            <template v-slot:item.winning_real="{ item }">
              {{item.currencySymbol}} {{ (item.winning_real != undefined) ? parseFloat(item.winning_real).toFixed(2) : 0.00 }}
            </template>

            <template v-slot:item.win="{ item }">
              {{ getWinning(item) }}
            </template>

            <template v-slot:item.payed="{ item }">
              {{ (item.payed == true) ? $t('generic.yes') : $t('generic.no') | capitalize}}
            </template>

            <template v-slot:item.result="{ item }" style="font-size: 10px">
        <span v-if="item.result == 'pending'" class="list-pending">
          <div class="circle-pending"></div> {{ $t('bets.'+item.result) | capitalize}}
        </span>
              <span v-if="item.result == 'lose'" class="red--text">
          <v-icon small color="red">mdi-close-circle</v-icon> {{ $t('bets.'+item.result) | capitalize}}
        </span>
              <span v-if="item.result == 'win'" class="green--text">
          <v-icon small color="green">mdi-checkbox-marked-circle</v-icon> {{ $t('bets.'+item.result) | capitalize}}
        </span>
              <span v-if="item.result == 'void'" class="blue--text">
          <v-icon small color="blue">mdi-alert-circle</v-icon> {{ $t('bets.'+item.result) | capitalize}}
        </span>
              <span v-if="item.result == 'cancelled'" class="blue-grey--text">
          <v-icon small color="blue-grey">mdi-alert-circle</v-icon> {{ $t('bets.'+item.result) | capitalize}}
        </span>
            </template>

            <!--      <template v-slot:item.defined="{ item }">-->
            <!--          <small>({{getDefined(item)}})</small>-->
            <!--      </template>-->

            <template v-slot:item.status="{ item }">
              {{ $t('bets.'+item.status) | capitalize}}
            </template>


            <template v-slot:item.platform_id="{ item }">
              {{ getPlatformName(item.platform_id) }}
            </template>

            <template v-slot:item.bet_context="{ item }">
        <span v-if="item.bet_context == 'live'" class="context live-label">
          {{ $t('bets.'+item.bet_context) }}
        </span>
              <span v-else-if="item.bet_context == 'prematch'"  class="context prematch-label">
          {{ $t('bets.'+item.bet_context) }}
        </span>
              <span v-else-if="item.bet_context == 'mixed'"  class="context mixed-label">
          {{ $t('bets.'+item.bet_context) }}
        </span>
            </template>

            <template v-slot:item.actions="{ item }">
        <span>
          <v-icon :title="$t('bets.detail')" @click="showDetail(item)">mdi-file-find</v-icon>
        </span>
            </template>

          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <bet-detail actionsbet="false"></bet-detail>

    <userNote v-if="userTargetNote != null" :user="userTargetNote" :userId="userIdTargetNote" :platformId="platformIdTargetNote"
              :updateUserNoteReserveBet="updateUserNoteStore" :updateAgencyNoteReserveBet="updateAgencyNoteStore">
    </userNote>

  </div>
</template>

<script>
  import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
  import _ from 'lodash';
  import BetsList from "@/components/risks/betsList";
  import axios from "axios";
  import BetDetail from "@/components/bets/betDetail";
  import NetworkDetail from "@/components/risks/networkDetail";
  import UserInfo from "@/components/risks/userInfo";
  import UserNote from "@/components/risks/userNote";
  import CryptoJS from 'crypto-js';

  export default {
    name: "betsReview",
    components: {NetworkDetail, BetsList, BetDetail, UserInfo, UserNote},
    data() {
      return {
        urlValidation: 'https://validate.srp.tools/',
        showModalBetDetail: false,
        interval: null,
        form: {
          amount: 0,
          odds: []
        },
        headersBetLists: [
          {text: '', value: 'device', width: '6%', sortable: false},
          {text: this.$t('bets.code'), value: 'code', width: '12%', sortable: false},
          {text: this.$t('bets.type'), value: 'type', width: "5%"},
          {text: this.$t('bets.events'), value: 'odds_sources', width: "5%"},
          {text: this.$t('bets.amount'), value: 'amount', width: "7%", sortable: false},
          {text: this.$t('bets.potential')+' '+this.$t('bets.winning'), value: 'win', width: "7%", sortable: false},
          {text: this.$t('bets.real_winning'), value: 'winning_real', width: "7%", sortable: false},
          {text: this.$t('bets.date'), value: 'created_at', width: "8%"},
          {text: this.$t('bets.platform'), value: 'platform_id', width: "4%"},
          {text: this.$t('generic.context'), value: 'bet_context', width: "5%"},
          {text: this.$t('bets.user'), value: 'user_id', width: "5%"},
          // {text: this.$t('bets.signDefined'), value: 'defined', width: "3%"},
          {text: this.$t('bets.result'), value: 'result', width: "7%"},
          {text: this.$t('bets.status'), value: 'status', width: "5%"},
          {text: this.$t('bets.payed'), value: 'payed', width: "5%"},
          {text: this.$t('bets.actions'), value: 'actions', sortable: false, width: "10%"},
        ],
        alertSuccessEnabled: false,
        alertErrorEnabled: false,
        userTargetNote: null,
        userIdTargetNote : null,
        platformIdTargetNote : null,
        updateUserNoteStore: false,
        updateAgencyNoteStore: false,
        isPlayingSound: false,
        intervalSound: null,
        myAudio: null
      }
    },
    computed: {
      ...mapGetters({
        'getReserve': 'bet/getReserve',
        'getPreload': 'utils/getPreload',
        'getShowDetail': 'bet/getShowDetail',
        'getOptions': 'bet/getOptions',
        'getSkinFilters': 'skins/getSkinFilters',
        'getCountsReserve': "bet/getCountsReserve",
        'getTimezone':'bet/getTimezone',
        'getPlatformId': 'rules/getPlatformId',
        'getToken':'profile/getToken',
        'getUrlApi': 'settings/getUrl',
        'getMyBetsAll': 'bet/getMyBetsAll',
        'getList':'skins/getList',
        'getBlockedUsers':'users/getBlockedUsers',
        'getContinueBeep':'risk/getContinueBeep',
        'getCountBetInReview':'bet/getCountBetInReview'
      }),
      async getList(){

      }
    },
    watch:{

      getCountBetInReview(newValue, oldValue) {
        if (this.getContinueBeep === true) {
          if (newValue != null && +newValue > 0 && this.isPlayingSound === false) {
            this.isPlayingSound = true;

            //create interval if not exist
            if (this.intervalSound == null) {
              this.intervalSound = setInterval(this.playReserveAudio, 5000);
            }

          } else if (newValue === 0) {
            if (this.intervalSound != null) {
              clearInterval(this.intervalSound);
              this.intervalSound = null;  // set to null after cancellation
            }
            this.isPlayingSound = false;
          }
        } else {
          if (this.intervalSound != null) {
            clearInterval(this.intervalSound);
            this.intervalSound = null;  // set to null after cancellation
          }
        }
      }
    },

    methods: {
      ...mapActions({
        'setBetDetail': 'bet/setBetDetail',
        'setShowDetail': 'bet/setShowDetail',
        'fetchBetsReserve': 'bet/fetchBetsReserve',
        'setPreload': 'utils/setPreload',
        'fetchMyBetsAll': 'bet/fetchMyBetsAll',
        /*'sendReviewBet': 'bet/sendReviewBet',*/
        'placeBetAction': 'bet/placeBet',
        'rejectBetAction': 'bet/rejectBet',
        'saveLogsReview': 'bet/saveLogsReview',
        'blockUserAction': 'skins/blockUser',
        'unlockUserAction':'skins/unlockUser',
        'blockUserActionSubnet': 'skins/blockUserSubnet',
        'unlockUserActionSubnet': 'skins/unlockUserSubnet',
        'fetchBlockedUsers':'users/fetchBlockedUsers',
        'sendAcceptBet':'bet/sendAcceptBet',
        'sendRejectBet':'bet/sendRejectBet',
      }),

      playReserveAudio() {
        // save in memory for avoid garbage collection
        if (!this.myAudio) {
          this.myAudio = new Audio("https://sirplay-amazon.s3.eu-west-3.amazonaws.com/positive.wav");
        }
        this.myAudio.play().catch(error => {
          console.error("Error playing audio beep:", error);
        });
      },
      openMessageDialog(bet, typeUser){

        let user = {};

        // fake commit

        if(typeUser == 'user'){


          user = {
            idUser: bet.user_id,
            login: bet.login,
          }

          this.userIdTargetNote = +bet.user_id;
          this.updateUserNoteStore = true

        }else if(typeUser == 'agency'){

          user = {
            idUser: bet.agencyId,
            login: bet.agencyLogin,
          }

          this.userIdTargetNote = +bet.agencyId;
          this.updateAgencyNoteStore = true;
        }

        this.platformIdTargetNote = this.getPlatformId;
        this.userTargetNote = user;
      },

      getMarketName(odd){
        let text = odd.market_name;

        let spread = this.getSpread(odd.market_unique_id);

        if(spread != ''){
          text = odd.market_name + ' (' + spread + ')';
        }

        return text;
      },

      getSpread(spec) {
        var result = ''

        if(spec.includes('#')){

          var split = spec.split('#')
          if(split[1].includes(':')){
            result = '('+split[1]+')'
          }else if(split[1].includes('(') && split[1].includes('.')){
            result = ' ['+split[1]+'] '
          }else if(split[1] != ''){
            result = split[1]
          }

          if(_.size(split) > 2){
            return result + ' ' + split[2]
          }else {
            return result
          }

        }else {
          return  result
        }
      },

      isUserLocked(idUser, platformId){

        const platf = platformId;
        const allBlockedUsers = this.getBlockedUsers;


        const blockedUsersForThisPlatform = allBlockedUsers.filter( blockedUserInfo => {
          return blockedUserInfo.platformId == platf
        });

        let idUsersBlocked = [];
        if(blockedUsersForThisPlatform != null && blockedUsersForThisPlatform.length > 0 && blockedUsersForThisPlatform[0].idUsersBlocked){
          idUsersBlocked = blockedUsersForThisPlatform[0].idUsersBlocked;
        }

        if(idUsersBlocked.includes(idUser.toString())){
          return true
        }else{
          return false
        }
      },

      showSuccessAlert(){
        this.alertSuccessEnabled = true;
        setTimeout(()=>{
          this.alertSuccessEnabled = false
        }, 2000)
      },
      showErrorAlert(){
        this.alertErrorEnabled = true;
        setTimeout(()=>{
          this.alertErrorEnabled = false
        }, 5000)
      },

      async getBetLists(item){
        await this.fetchMyBetsAll({all: false, user_id: item.user_id, platform_id: item.platform_id, in_progress: false});
        this.showModalBetDetail = true;
      },
      async placeBet(bet) {
        this.$set(bet, 'loadingBetAction', true);

        const amount = this.$refs[bet.code + '_amount'][0].value;
        const odds_form = this.$refs[bet.code + '_odds'];

        const old_value = {
          'amount': bet.amount_real,
          'odds': bet.odds
        };

        const hash_unique = bet.hash_unique;
        bet.amount_real = amount;

        _.forEach(bet.odds, (odd, index) => {
          if (odds_form[index].value != '' && odds_form[index].value > 0) {
            let new_value = odds_form[index].value;

            if (bet.odds[index].outcome_odd_value != bet.odds[index].outcome_odd_value_for_order) {
              bet.odds[index].outcome_odd_value = parseFloat(new_value).toFixed(2);
              bet.odds[index].outcome_odd_value_for_order = JSON.parse(parseFloat(new_value).toFixed(2));
              bet.odds[index].changed_reserved = 1;
            }
          } else {
            bet.odds[index].outcome_odd_value = 0;
            bet.odds[index].outcome_odd_value_for_order = 0;
          }
        });

        let new_value = {
          'amount': amount,
          'odds': bet.odds
        };

        const new_hash_unique = Buffer.from(bet.amount_real + JSON.stringify(bet.odds)).toString('base64');

        var betToVerify = {
          odds_sources: bet.odds_sources,
          bet: {
            odds: bet.odds,
            type: bet.type
          }
        };

        switch (bet.type) {
          case "single":
          case "multiple":
            betToVerify.bet._multiple_detail = bet._multiple_detail;
            betToVerify.bet._multiple_detail.amount = amount;
            break;
          case "integral":
            betToVerify.bet._integral_detail = bet._integral_detail;
            betToVerify.bet._integral_detail.amount_total = amount;
            betToVerify.bet._integral_detail.amount = amount;
            break;
          case "system":
            betToVerify.bet._system_detail = bet._system_detail;
            betToVerify.bet._system_detail.amount_system = amount;
            betToVerify.bet._system_detail.amount = amount;

            _.forEach(bet.system_parts, (part, index) => {
              if (part.checked == true) {
                bet.system_parts[index].amount = (amount / bet.system_parts.length);
              }
            });

            betToVerify.bet.system_parts = bet.system_parts;
            break;
        }

        const encryptedText = CryptoJS.AES.encrypt(JSON.stringify(bet.odds_sources), process.env.VUE_APP_HASH_FORM).toString();

        let payload_request = {
          "betContext": bet.bet_context,
          "bet_to_check": JSON.stringify(betToVerify),
          "lang": 'en',
          "accept_odds": true,
          "hash": encryptedText,
          "type_user": 'user',
          "user_id": bet.user_id,
          "platform_id": bet.platform_id,
          "parentBet": bet.code,
        };

        let action;

        try {
          if (hash_unique != new_hash_unique) {
            action = 'review';
            await this.placeBetAction(payload_request);
          } else {
            action = 'accept';
            new_value = '';
            await this.acceptBet(bet.code);
          }

          let data = {
            'user_id': localStorage.getItem('userLoggedId'),
            'platform_id': bet.platform_id,
            'action': action,
            'code': bet.code,
            'betCode': bet.betCode,
            'login': bet.login,
            'old_value': JSON.stringify(old_value),
            'new_value': JSON.stringify(new_value)
          };

          await this.saveLogsReview(data);

          this.$store.commit('bet/removeBetPLaced', bet.code);

        } catch (actionError) {
          console.error(`Error during place bet with code:  ${bet.code}:`, actionError);
        } finally {
          this.$set(bet, 'loadingBetAction', false);
        }
      },


      async acceptBet(code) {

        const vm = this;

        try {

          const result = await this.sendAcceptBet({ 'code': code });

          if(result.data && result.data.result === false) {

            const reason = result.data.reason ? result.data.reason : '';

            if(reason === 'Insufficient credit') {
              vm.$swal.fire(
                      vm.$t('operationNotPerformedCorrectly'),
                      `${vm.$t('insufficientCreditAcceptBet')} (${vm.$t('betCode')}: ${code})`,
                      'error'
              );
            } else {

              vm.$swal.fire(
                      vm.$t('operationNotPerformedCorrectly'),
                      `${reason || vm.$t('genericError')} (${vm.$t('betCode')}: ${code})`,
                      'error'
              );
            }
          }
        } catch (e) {

          console.log(e);
          vm.$swal.fire(
                  vm.$t('operationNotPerformedCorrectly'),
                  `${vm.$t('genericError')} (${vm.$t('betCode')}: ${code})`,
                  'error'
          );

          throw e;
        }

      },
      async rejectBet(item) {

        this.$set(item, 'loadingBetAction', true);

        try {
          const result = await this.sendRejectBet({ 'code': item.code });

          if (result.data.result) {
            let payload_request = {
              "code": item.code,
              "platform_id": item.platform_id,
              "betCode": item.betCode,
              "login": item.login
            }

            let data = {
              'user_id': localStorage.getItem('userLoggedId'),
              'platform_id': item.platform_id,
              'action': 'reject',
              'code': item.code,
              'betCode': item.betCode,
              'login': item.login,
              'old_value': '',
              'new_value': ''
            }

            await this.saveLogsReview(data);
            this.$store.commit('bet/removeBetPLaced', item.code);
          }

        } catch (e) {
          console.log(e);
        } finally {
          this.$set(item, 'loadingBetAction', false);
        }
      },

      async removeModalBetLists() {
        this.showModalBetDetail = false;
      },
      getWinning(bet) {
        switch (bet.type) {
          case('single'):
          case('multiple'):
            return bet.currencySymbol +' '+parseFloat(bet._multiple_detail.winning_total).toFixed(2)
          case('integral'):
            return bet.currencySymbol +' '+parseFloat(bet._integral_detail.winning_total_max).toFixed(2)
          case('system'):
            return bet.currencySymbol +' '+parseFloat(bet._system_detail.winning_all_total).toFixed(2)
        }
      },
      getPlatformName(platform_id) {
        if(platform_id == 0){
          return "Manager";
        }else{
          // if (process.env.NODE_ENV === 'local') {
          //   platform_id = 43;
          // }
          let platform = _.find(this.getSkinFilters, {platform_id: platform_id})
          return platform.name
        }
      },
      async showDetail(bet) {
        await this.setShowDetail(false)
        await this.setBetDetail(bet)
        await this.setShowDetail(true)
      },
      async timeoutReserve() {
        const user_id = localStorage.getItem('userLoggedId');

        if(user_id && localStorage.getItem('accept_reserve')){
          // await this.fetchBetsReserve( {platform: this.getPlatformId, user_id:  user_id } );
          /* await this.fetchBetsReserve( {platform: 1 } ); */
          await this.fetchBetsReserve( {platform: this.getPlatformId } );
        }
      },
      async lockUser (idUser, platform_id){


        const requestPayload = {
          idUser: idUser,
          platform_id: platform_id
        }

        const result = await this.blockUserAction(requestPayload);
        let executed = false;

        if (result != null && result.data != null) {
          executed = result.data.result;
        }

        if (executed == true) {
          this.showSuccessAlert();
        } else {
          this.showErrorAlert();
        }
      },
      async unlockUser(idUser, platform_id){

        const requestPayload = {
          idUser: idUser,
          platform_id: platform_id
        }

        const result = await this.unlockUserAction(requestPayload);
        let executed = false;

        if (result != null && result.data != null) {
          executed = result.data.result;
        }

        if (executed == true) {
          this.showSuccessAlert();
        } else {
          this.showErrorAlert();
        }
      },
      async lockUserSubnet (idUser, platform_id){


        const requestPayload = {
          idUser: idUser,
          platform_id: platform_id
        }

        const result = await this.blockUserActionSubnet(requestPayload);
        let executed = false;

        if (result != null && result.data != null) {
          executed = result.data.result;
        }

        if (executed == true) {
          this.showSuccessAlert();
        } else {
          this.showErrorAlert();
        }
      },
      async unlockUserSubnet(idUser, platform_id){

        const requestPayload = {
          idUser: idUser,
          platform_id: platform_id
        }

        const result = await this.unlockUserActionSubnet(requestPayload);
        let executed = false;

        if (result != null && result.data != null) {
          executed = result.data.result;
        }

        if (executed == true) {
          this.showSuccessAlert();
        } else {
          this.showErrorAlert();
        }
      }

      // async playSound () {
      //   let audio = new Audio('http://soundbible.com/mp3/analog-watch-alarm_daniel-simion.mp3');
      //   audio.play();
      // }
    },
    async mounted() {
      const user_id = localStorage.getItem('userLoggedId');
      if(user_id && localStorage.getItem('accept_reserve')) {
        // await this.fetchBetsReserve( {platform: this.getPlatformId, user_id:  user_id } );
        await this.fetchBetsReserve({platform: this.getPlatformId});
      }

      this.fetchBlockedUsers();

      //timeout new bet reserve
      this.interval = setInterval(this.timeoutReserve, 2000);

      this.$root.$on('closedWriteNoteDialog', () => {
        this.userTargetNote = null;
        this.userIdTargetNote = null;
        this.platformIdTargetNote = null;
        this.updateUserNoteStore = false;
        this.updateAgencyNoteStore = false;
      })
    },
    beforeDestroy() {
      if (this.interval != null) {
        clearInterval(this.interval);
      }

      if(this.intervalSound != null){
        clearInterval(this.intervalSound);
      }

      this.$root.$off('closedWriteNoteDialog');
    },
  }
</script>

<style>
  .content-bet .v-btn{
    font-size: 12px;
    max-height: 20px;
  }
  .context-bet{
    text-transform: capitalize;
    background-color: #eeeeee;
    padding: 4px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 12px;
  }
  .context-bet.prematch{
    background-color: #eeeeee;
  }
  .context-bet.live{
    background-color: #4ecd00;
    color: white;
  }
  .context-bet.mixed{
    background-color: #ff6600;
    color: white;
  }
  .userInfoLabel{
    background-color: #eeeeee;
    padding: 5px;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
  }
  .platfomInfoLabel{
    background-color: #297099;
    color: #fff;
    padding: 5px;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  .msg-error{
    color: white;
    border-radius: 4px;
    padding: 5px;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .mdi-thumb-up::before{
    font-size: 20px;
    padding-right: 5px;
  }
  .mdi-thumb-down::before{
    font-size: 20px;
    padding-right: 5px;
  }
  .bk-live{
    background: linear-gradient(0deg, rgba(3, 191, 51, 0.25) 0%, rgba(3, 191, 51, 0.25) 100%), #FFF;
  }
  .bk-repeat{
    background: linear-gradient(0deg, rgba(255, 0, 0, 0.15) 0%, rgba(255, 0, 0, 0.15) 100%), #FFF;
  }

  /*.bk-live .v-text-field--outlined fieldset{*/
  /*  background: #ffffff !important;*/
  /*}*/

  .msg-note{
    background: #eeeeee !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    color: #333333;
    cursor: pointer;
  }
  .msg-note-agency{
    background: #afacac !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    color: #333333;
    cursor: pointer;
  }

  /*@media screen and (max-width: 767px) {*/
  /*  #betsReviewComponent .bet-info{*/
  /*    display: block !important;*/
  /*  }*/
  /*}*/

  .odd-event-description{
    margin-top: 6px;
    margin-bottom: 10px;
    height: 10px;
    display: block;
  }
  .hoverTitle{
    cursor:pointer;
    float:left;
    width: 100%;
  }
  .row+.row {
    margin-top: 10px;
  }
  fieldset {
    height: 35px;
  }
  input{
    padding: 0 !important;
  }
  .pb-0.ptb {
    max-height: 45px;
  }
  .bet-info fieldset {
    height: 30px;
    padding: 0;
  }
  .bet-info .pt-4.pt-0 {
    max-height: 51px;
    padding-top: 11px !important;
  }
</style>
