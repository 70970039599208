<template>
  <v-col cols="12">
    <span class="title-table">Menu</span>
    <v-card outlined elevation-0 class="pa-1">
      <v-btn depressed :color="(this.$route.path != '/sports/keys') ? 'primary' : 'secondary'" to="/sports/keys" class="mr-2">
        GESTIONE KEY
      </v-btn>
      <v-btn depressed :color="(this.$route.path != '/sports/multiplicities') ? 'primary' : 'secondary'" to="/sports/multiplicities" class="mr-2">
        MOLTEPLICITA'
      </v-btn>
      <v-btn depressed :color="(this.$route.path != '/sports') ? 'primary' : 'secondary'" to="/sports" class="mr-2">
        GRUPPI DI QUOTE
      </v-btn>
      <v-btn depressed :color="(this.$route.path != '/sports/settings') ? 'primary' : 'secondary'" to="/sports/settings"
             class="mr-2">
        IMPOSTAZIONI ALBERO
      </v-btn>
      <v-btn depressed disabled :color="(this.$route.path != '/sports/competitors') ? 'primary' : 'secondary'"
             to="/sports/competitors" class="mr-2">
        COMPETITORS
      </v-btn>
    </v-card>
  </v-col>


</template>

<script>
export default {
  name: "menu"
}
</script>

<style scoped>

</style>
