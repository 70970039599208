<template>
  <v-app-bar app dark dense>
    <v-app-bar-nav-icon @click="drawer = !drawer" class="d-md-none"></v-app-bar-nav-icon>
    <!--Desktop-->
    <v-toolbar-items class="hidden-md-and-down">
      <v-btn icon large to="/" v-if="envMode === 'production'">
        <v-avatar size="32px" tile>
          <img src="@/assets/logo_admin.svg" :alt="appName">
        </v-avatar>
      </v-btn>
      <v-btn icon large to="/" v-else>
        <v-avatar size="32px" tile>
          <img class="logo-img-demo" src="@/assets/logo_demo.svg" :alt="appName">
        </v-avatar>
      </v-btn>
    </v-toolbar-items>
    <v-toolbar-items class="hidden-md-and-down" v-for="(menu,index) in menu_items" :key="index">
      <router-link :to="menu.path" class="flex-center" @click.native.prevent="handleMenu(menu)">
      <v-menu open-on-hover bottom offset-y v-if="menu.sub" tile>
        <template v-slot:activator="{ on, attrs }" >
          <div v-bind="attrs" v-on="on" class="menu-link" :class="menu.active ? 'active-top-menu' : ''" >
            <v-icon>{{ menu.icon }}</v-icon>
            {{ menu.text | capitalize }}
          </div>
        </template>

        <v-list v-if="_.size(menu.sub)>0">
          <v-list-item v-for="(sub, index) in menu.sub" :key="index" :to="sub.path" dense v-if="_.size(menu.sub)>0"
                       @click="checkActive(menu)">
            <router-link :to="sub.path" class="no-decoration">
              <v-list-item-title>
                {{ sub.text | capitalize }}
              </v-list-item-title>
            </router-link>
          </v-list-item>
        </v-list>
      </v-menu>
      <div v-else @click="checkActive(menu)"  class="div-menu-voice">

        <template v-if="menu.path == '/bets/list'" >
          <template v-if="getReviewCount > 0 && getPlatformId === 0">
            <v-badge overlap color="red" :content="getReviewCount">
              <v-icon>{{ menu.icon }}</v-icon>
              {{ menu.text | capitalize }}
            </v-badge>
          </template>

          <template v-else>
            <v-icon>{{ menu.icon }}</v-icon>
            {{ menu.text | capitalize }}
          </template>

        </template>
        <template v-else>
          <v-icon>{{ menu.icon }}</v-icon>
          {{ menu.text | capitalize }}
        </template>

      </div>
      </router-link>
    </v-toolbar-items>
    <v-spacer class="hidden-md-and-down"></v-spacer>
    <v-toolbar-items class="select-platform hidden-md-and-down" v-if="getPlatform === 0">
      <!--      <span class="hint">{{ $t('generic.selectPlatform') }}</span>-->
      <v-autocomplete
          v-model="getPlatformId"
          :items="getSkins"
          item-text="name"
          item-value="platform_id"
          dense
          filled
          @change="changedValue"
      ></v-autocomplete>
    </v-toolbar-items>
    <v-menu class="hidden-md-and-down" left bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          {{ getLanguage }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="lang in getLanguagesList" :key="lang.abbr" @click="changelang(lang.abbr)">
          <v-list-item-title>{{ lang.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu class="hidden-md-and-down" open-on-hover offset-y rounded="0">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" dark small color="primary" v-bind="attrs" v-on="on">
          <v-icon dark>mdi-account-circle</v-icon>
          <span class="name-profile">{{ getNameProfile | truncate(3) }}</span>
        </v-btn>
      </template>
      <v-list dense class="pt-0 pb-0 cpointer">

        <v-list-item dense @click="" v-clipboard:copy="getToken" v-if="getPlatform === 0">
          <v-list-item-icon class="mr-1">
            <v-icon dense>content_copy</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('generic.copyToken') | capitalize }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense to="/administrators" v-if="this.checkExist('/administrators')">
          <v-list-item-icon class="mr-1">
            <v-icon dense>mdi-contacts</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('generic.administrators') | capitalize }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense to="/administrators/permissions" v-if="this.checkExist('/administrators/permissions')">
          <v-list-item-icon class="mr-1">
            <v-icon dense>mdi-boom-gate</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('generic.permission') | capitalize }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense @click="logout">
          <v-list-item-icon class="mr-1">
            <v-icon dense>power_settings_new</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title @click="logout">{{ $t('generic.logout') | capitalize }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-menu>
    <!--Mobile-->
    <v-toolbar-title class="hidden-md-and-up"></v-toolbar-title>
    <div class="select-platform hidden-md-and-up" v-if="getPlatform === 0">
      <v-autocomplete
          v-model="getPlatformId"
          :items="getSkins"
          item-text="name"
          item-value="platform_id"
          dense
          filled
          @change="changedValue"
      ></v-autocomplete>
    </div>
    <v-navigation-drawer v-model="drawer" app temporary class="d-md-none">
      <v-list>
        <v-list-item>
          <router-link to="/" class="no-decoration" style="color:#fff">
            <v-icon>mdi-home</v-icon>
            HOME
          </router-link>
        </v-list-item>
        <v-list-item v-for="(item, i) in menu_items" :key="i" @click="location.href(item.path)">
          <v-list-item-title style="text-transform: uppercase;">
            <router-link :to="item.path" class="no-decoration" v-if="!item.sub" style="color:#fff">
              <v-icon>{{ item.icon }}</v-icon>
              {{ item.text }}
            </router-link>
            <v-menu v-else open-on-click offset-y tile>
              <template v-slot:activator="{ on, attrs }" >
                <div v-bind="attrs" v-on="on" :class="item.active ? 'active-top-menu' : ''">
                  <v-icon>{{ item.icon }}</v-icon>
                  {{ item.text | capitalize }}
                </div>
              </template>
              <v-list v-if="_.size(item.sub)>0">

                <v-list-item v-for="(sub, index) in item.sub" :key="index" :to="sub.path" dense v-if="_.size(item.sub)>0"
                             @click="checkActive(item)">
                  <router-link :to="sub.path" class="no-decoration">
                    <v-list-item-title> {{ sub.text | capitalize }}</v-list-item-title>
                  </router-link>
                </v-list-item>


              </v-list>
            </v-menu>
          </v-list-item-title>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

  </v-app-bar>
</template>
<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import axios from "axios";

export default {
  name: "Toolbar",
  data() {
    return {
      dialog: false,
      isMenuOpen: false,
      drawer: false,
      langs: {},
      list :[],
      menu_items: [
        {
          icon: 'mdi-trending-up', text: this.$t('bets.odds'), path: '/odds'
        },
        {
          icon: 'mdi-file-document', text: this.$t('bets.bet'), path: '/bets/list'
        },
        {
          icon: 'mdi-traffic-light', text: this.$t('risk.riskmanagement'), path: '/riskmanagement/reserve',
          sub: [
            {text: this.$t('risk.reserve'), path: '/riskmanagement/reserve-func'},
            {text: this.$t('risk.limits_platform'), path: '/riskmanagement/limits'},
            {text: this.$t('risk.sportLimits'), path: '/riskmanagement/entitylimits'},
            {text: this.$t('risk.eventReportLink'), path: '/riskmanagement/betreport'},
            {text: this.$t('risk.usersList'), path: '/riskmanagement/userslist'},
            {text: this.$t('risk.logs'), path: '/riskmanagement/logs'},
          ]
        },
        {
          icon: 'mdi-calendar-edit', text: this.$t('events'), path: '/events/list',
          sub: [
            {text: this.$t('list'), path: '/events/list'},
            {text: this.$t('importEvent'), path: '/events/create'},
            {text: this.$t('importOdds'), path: '/events/import/g-odds'},
            {text: this.$t('oddsNotDefined'), path: '/events/odds-not-defined'},
            {text: this.$t('eventDuplicates'), path: '/events/duplicates'},
            {text: this.$t('oddsNotDefinedManualMarket'), path: '/events/odds-not-defined-manual-market'},
          ]
        },
        {
          icon: 'mdi-sort-numeric', text: this.$t('bets.odds')+' '+this.$t('generic.settings'), path: '/sports/keys',
          sub: [
            {text: this.$t('bets.margin'), path: '/sports/keys'},
            {text: this.$t('bets.multiplicity'), path: '/sports/multiplicities'},
            {text: this.$t('manualmarket'), path: '/odds/marketmanual'},
            // {text: this.$t('manualmatch'), path: '/odds/matchmanual'},
          ]
        },
        {
          icon: 'mdi-monitor', text: this.$t('bets.betConsole'), path: '/betconsole/cart',
          sub: [
            {text: this.$t('bets.cart'), path: '/betconsole/cart'},
            {text: this.$t('bets.bonus'), path: '/betconsole/bonus'},
            {text: 'Shock Absorber', path: '/betconsole/shockabsorber'},
            {text: this.$t('generic.oddsGroup'), path: '/sports/odds-group'},
            // {text: this.$t('marketViewSetting'), path: '/betconsole/marketViewSetting'},
            {text: this.$t('marketViewSettingV2'), path: '/betconsole/marketViewSettingV2'},
            {text: this.$t('marketButtonSizeSettings'), path: '/betconsole/marketButtonSizeSettings'},
            {text: this.$t('bets.tree'), path: '/sports/settings'},
            {text: this.$t('generic.featuredTournament'), path: '/betconsole/featuredTournament'},
            {text: this.$t('combinableMarkets'), path: '/betconsole/combinableMarkets'}
          ]
        },

        {
          icon: 'mdi-settings', text: this.$t('generic.settings'), path: '/sports/markets',
          sub: [
            {text: this.$t('generic.markets'), path: '/sports/markets'},
            {text: this.$t('marketsTranslationsOverride'), path: '/sports/translationsmarkets'},
            {text: this.$t('generic.outcomeOrder'), path: '/sports/outcomeorder'},
            {text: this.$t('bets.competitor'), path: '/sports/competitors'},
            {text: this.$t('bets.codes'), path: '/sports/codes'},
            {text: this.$t('generic.cashout'), path: '/betconsole/cashout'},
            {text: 'Cache', path: '/betconsole/cache'},

          ]
        },
        // {
        //   icon: 'mdi-soccer', text: 'Live Betting', path: '#',
        //   sub: [
        //     {text: 'Gestione Eventi Live', path: '/live/events'},
        //     {text: 'Gestione Calendario', path: '/live/calendar'}
        //   ]
        // },

        {
          icon: 'mdi-account-multiple', text: this.$t('generic.management'), path: '/skins',
          sub: [
            {text: this.$t('bets.platform'), path: '/skins',platform:true},
            {text: this.$t('themes'), path: '/themes',platform:false},
            {text: this.$t('generic.log'), path: '/historyrules',platform:true}
          ]
        },
        {
          icon: 'mdi-camera-timer ', text: this.$t('live.live'), path: '/sports/live',
          sub: [
            {text: this.$t('live.setting'), path: '/sports/live'},
            {text: this.$t('live.switch'), path: '/sports/liveswitch'},

          ]
        },
        {
          icon: 'mdi-printer-outline', text: this.$t('generic.printodds'), path: '/stamps/list',
          sub: [
            {text: this.$t('generic.markets'), path: '/stamps/markets'},
            {text: "Multimedia", path: '/stamps/multimedia'},
            {text: "Template", path: '/stamps/list'},

          ]
        },
        {
          icon: 'mdi-chart-line', text: this.$t('generic.report'), path: '/reports/bets',
          sub: [
            {text: this.$t('generic.platform'), path: '/reports/bets'},
            {text: this.$t('trends'), path: '/reports/trends'}
          ]
        },
      ],
      appName: process.env.VUE_APP_SITE_NAME,
      envMode: process.env.NODE_ENV
    }
  },

  computed: {

    ...mapGetters({
      'getDrawer': 'settings/getDrawer',
      'getName': 'settings/getName',
      'getToken': 'profile/getToken',
      'getNameProfile': 'profile/getName',
      'getUrl': 'settings/getUrl',
      'getLanguage': 'profile/getLanguage',
      'getPlatform': 'profile/getPlatform',
      'getLanguagesList': 'profile/getLanguagesList',
      'getPlatformId': 'rules/getPlatformId',
      'getSkins': 'skins/getList',
      'getPermissions': 'profile/getPermissions',
      'getReviewCount': 'bet/getReviewCount',

    }),
  },

  mounted() {
    if (this.getLanguage === '') {
      this.setLanguage('en')
    }


    this.lists = this.getPermissions[0].permissions

    var arrayFilter = []
    //console.log(this.lists)
    _.forEach(this.menu_items,el =>{

      var check = _.includes(this.lists,el.path)

      if(check){

        var menusub = []
        if(_.hasIn(el,'sub')){
          //console.log('sub',el)
          var miniSub = []
          _.forEach(el.sub,mini=>{

            var checkSubMenu = _.includes(this.lists,mini.path)
            if(checkSubMenu){
              miniSub.push(mini)
            }
            //console.log(checkSubMenu)
          })
          _.assign(el,{sub:miniSub})
        }

        arrayFilter.push(el)

      }
    })
    this.menu_items=arrayFilter

  },

  updated() {
    // this.$forceUpdate();
  },

  async created() {
    await this.setPreload(true);
    await this.fetchSkins();
    await this.fetchLanguagesList();
    // await this.fetchReviewCounts()
    await this.setPreload(false);
    if(this.getPlatformId === 0){
      setInterval(async ()=> {
        await this.fetchReviewCounts()
      }, 60 * 1000);
    }

  },

  methods: {
    ...mapMutations({
      'setDrawer': 'settings/setDrawer',
      'setRuleId': 'rules/setRuleId',
      'setLanguage': 'profile/setLanguage'

    }),

    ...mapActions({
      'setPreload': 'utils/setPreload',
      'setLogout': 'profile/logout',
      'fetchSkins': 'skins/fetchSkins',
      'fetchLanguagesList': 'profile/fetchLanguagesList',
      'fetchReviewCounts': 'bet/fetchReviewCounts',
    }),
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    checkExist(route){
      return _.includes(this.lists,route)
    },
    changedValue: function (value) {
      this.$root.$store.commit('rules/setPlatformId', value)
      var rule_id = _.find(this.getSkins, {'platform_id': value})._id
      this.$root.$store.commit('rules/setRuleId', rule_id)
      this.$router.go(0);
    },
    toggleDrawer() {
      this.setDrawer(!this.getDrawer);
    },
    changelang(abbr) {
      this.setLanguage(abbr);
      setTimeout(function(){ window.location.reload() }, 250);
    },
    async logout() {
      await this.setLogout();
      this.$router.push({path: '/login'})
    },
    copyToken() {
      alert(this.getToken);
    },
    checkActive(menu) {
      //console.log(this.$router.currentRoute.path)
      _.forEach(this.menu_items, (menu) => {
        menu.active = false
      })
      var find = _.find(menu.sub, {'path': this.$router.currentRoute.path})
      if (find) {
        menu.active = true
      }
    },
    resetActive() {
      console.log('qui')
      _.forEach(this.menu_items, (menu) => {
        menu.active = false
      })
    },
    handleMenu(menu) {

      if(menu.sub && menu.sub.length > 0){
        _.forEach(this.menu_items, (menu) => {
          menu.active = false
        })
      }
      // if (this.IsV2User) {
      //   this.$router.push(route)
      // } else {
      //   this.openModal('changeUserType', 'user.changeUserType')
      // }
    }
  },

}
</script>

<style scoped>
.bg-dark{
  background: black;
  color: white;
}

.logo-img-demo{
  background-color: white !important;
}

.menu-link{
  padding:10px;
}
  .logo-img-demo{
    background-color: white !important;
  }
  a.flex-center {
    text-decoration: none;
    color: white;
  }

  .div-menu-voice {
    padding:10px;
  }

</style>
