import { render, staticRenderFns } from "./History.vue?vue&type=template&id=1b95792d&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml"
import script from "./History.vue?vue&type=script&lang=js"
export * from "./History.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b95792d",
  null
  
)

export default component.exports