import Sport from '@/views/sports/OddsGroup.vue'
import Settings from '@/views/sports/Settings.vue'
import Competitors from '@/views/sports/Competitors.vue'
import Multiplicities from "@/views/sports/Multiplicities";
import Keys from "@/views/sports/Keys";
import Markets from "@/views/sports/Markets";
import Codes from "@/views/sports/CodesV2";
import Live from "@/views/sports/Live";
import Switch from "@/views/live/Switch";
import OutcomeOrder from "@/views/sports/OutcomeOrder.vue"
import TranslationsMarketsOverride from "@/views/sports/TranslationsMarketsOverride.vue"
import {checkRoute} from "../Helper";

export default [
    {
        path: '/sports/odds-group',
        name: 'oddsGroup',
        component: Sport,
        beforeEnter(to, from, next) {
            checkRoute('/sports/odds-group').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    {
        path: '/sports/settings',
        name: 'settingsSport',
        component: Settings,
        beforeEnter(to, from, next) {
            checkRoute('/sports/settings').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    {
        path: '/sports/competitors',
        name: 'competitorsSport',
        component: Competitors,
        beforeEnter(to, from, next) {
            checkRoute('/sports/competitors').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    {
        path: '/sports/multiplicities',
        name: 'multiplicitiesSport',
        component: Multiplicities,
        beforeEnter(to, from, next) {
            checkRoute('/sports/multiplicities').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    {
        path: '/sports/keys',
        name: 'keysSport',
        component: Keys,
        beforeEnter(to, from, next) {
            checkRoute('/sports/keys').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    {
        path: '/sports/markets',
        name: 'markets',
        component: Markets,
        beforeEnter(to, from, next) {
            checkRoute('/sports/markets').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    {
        path: '/sports/codes',
        name: 'codes',
        component: Codes,
        beforeEnter(to, from, next) {
            checkRoute('/sports/codes').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    {
        path: '/sports/live',
        name: 'Live',
        component: Live,
        beforeEnter(to, from, next) {
            checkRoute('/sports/live').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    {
        path: '/sports/liveswitch',
        name: 'LiveSwitch',
        component: Switch,
        beforeEnter(to, from, next) {
            checkRoute('/sports/liveswitch').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    {
        path: '/sports/outcomeOrder',
        name: 'outcomeOrder',
        component: OutcomeOrder,
        beforeEnter(to, from, next) {
            checkRoute('/sports/outcomeorder').then(response => {
                    // console.log(response)
                    if (response) {
                        //console.log("login")
                        next();
                    } else {
                        //console.log("next")
                        next('/login');
                    }
                }
            )
        }
    },
    {
        path: '/sports/translationsmarkets',
        name: 'translationsmarkets',
        component: TranslationsMarketsOverride,
        beforeEnter(to, from, next) {
            checkRoute('/sports/translationsmarkets').then(response => {
                    if (response) {
                        next();
                    } else {
                        next('/login');
                    }
                }
            )
        }
    }
]
