<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md12>
          <v-col cols="12">
            <span class="title-table">{{ this.$t('generic.markets') | capitalize}}</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-col cols="2">
                    <v-autocomplete
                            v-model="searchSport"
                            :items="sports"
                            item-text="name"
                            item-value="_id"
                            outlined
                            dense
                            chips
                            small-chips
                            :label="$t('generic.sport')"
                            deletable-chips
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="8" v-if="Object.keys(this.marketToEdit).length !== 0">
                    <v-btn color="blue-grey" class="white--text" :style="'float: right;'" @click="updateMarket()">
                      <v-icon left dark>save</v-icon>
                      {{ $t('generic.save') }}
                    </v-btn>
                  </v-col>
                  <!--<v-col cols="2">
                    <v-btn color="blue-grey" class="white&#45;&#45;text text-right " @click="clearcache()">
                      <v-icon left dark>save</v-icon>
                      {{ $t('generic.clearcache') }}
                    </v-btn>
                  </v-col>-->
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-card>
                      <v-data-table
                              :headers="headers"
                          :items="getMarketsMini.markets"
                              class="elevation-1"
                              :items-per-page="30"
                              :search="search"
                              item-key="unique_id"
                      >
                        <template v-slot:top>
                          <v-text-field
                                  v-model="search"
                                  :label="$t('generic.search')"
                                  class="mx-4"
                          ></v-text-field>
                        </template>

                        <template v-slot:item.actions="{ item }">
                          <v-icon @click="editMarket(item)">mdi-magnify</v-icon>
                          <v-icon @click="deleteItem(item)" v-if="getPlatformId==0">mdi-delete-forever</v-icon>

                        </template>

                      </v-data-table>
                    </v-card>

                  </v-col>
                  <v-col cols="6">
                    <v-card v-if="Object.keys(this.marketToEdit).length !== 0">
                      <v-card-title>
                        <span class="market-name-box">{{ this.marketToEdit.name }}</span>
                        <span class="market-position">
                          <vue-numeric-input v-model="marketToEdit.sort" controlsType="updown"></vue-numeric-input>
                        </span>
                      </v-card-title>
                      <hr>
                      <v-row class="pa-3" :style="'max-height: 915px;overflow-y: scroll;'">
                        <v-col cols="12" :style="'background: aliceblue;'">{{this.$t('generic.name') | capitalize}}</v-col>
                        <v-col cols="3" v-for="(detail, i) in this.marketToEdit.detail" :key="i" :style="'background: aliceblue;'">


                          <v-checkbox
                                  v-model="detail.translated"
                                  :label="$t('generic.translated')|capitalize"
                                  color="red"
                                  hide-details
                                  class="mb-2"
                          ></v-checkbox>
                          <v-text-field
                                  :label="detail.lang"
                                  :placeholder="detail.lang"
                                  v-model="detail.text"
                                  outlined
                                  dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="9" :style="'background: #e0e0e0;'">{{this.$t('generic.outcomes') | capitalize}}</v-col>
                        <v-col cols="3" :style="'background: #e0e0e0;'" class="no-padding no-margin">
                          <v-checkbox
                                  v-model="showTranslations"
                                  :label="`${$t('showTranslations')}`"
                                  :style="'margin: 8px'"
                                  dense
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" v-for="(outcome, j) in this.marketToEdit.outcomes" :key="j">
                          <div class="outcome-row">
                            <span class="font-weight-bold">{{outcome.name}}</span>
                            <vue-numeric-input v-model="outcome.sort" controlsType="updown" :style="'float:right;'"></vue-numeric-input>
                            <hr class="mt-2">
                            <v-row v-if="showTranslations">
                              <v-col cols="3" v-for="(name, i) in outcome.detail" :key="i">
                                <v-checkbox
                                        v-model="name.translated"
                                        :label="$t('generic.translated')|capitalize"
                                        color="red"
                                        hide-details
                                        class="mb-2"
                                ></v-checkbox>
                                <v-text-field
                                        :label="name.lang"
                                        :placeholder="name.lang"
                                        v-model="name.text"
                                        outlined
                                        dense
                                ></v-text-field>
                              </v-col>
                            </v-row>

                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="dialogconfirm" max-width="290">
      <v-card>
        <v-card-title class="headline">{{$t('generic.areYouSure') | capitalize}}</v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogconfirm = false">{{ $t('generic.cancel') }}</v-btn>

          <v-btn color="secondary" @click="confirmDelete"> {{ $t('generic.ok') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
            v-model="snackbar.snackbar"
            :color="snackbar.color"
            :multi-line="snackbar.mode === 'multi-line'"
            :timeout="snackbar.timeout"
            :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <v-btn dark text @click="snackbar.snackbar = false"> {{ $t('generic.close') }} </v-btn>
    </v-snackbar>
  </div>

</template>
<style>

</style>
<script>
  import axios from 'axios'
  import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
  import _ from 'lodash'
  import menuSport from "@/components/sports/menu";

  export default {
    name: "Markets",
    data() {
      return {
        headers: [
          {text: this.$t('generic.id'), value: 'unique_id'},
          {text: this.$t('generic.name'), value: 'name'},
          {text: this.$t('generic.actions'), value: 'actions'},
        ],
        snackbar:{
          snackbar: false,
          color: '',
          mode: '',
          timeout: 6000,
          text: '',
        },
        search: '',
        searchSport: -1,
        dataGroups: {},
        dataPreview: [],
        showAdd: false,
        marketToEdit: {},
        showTranslations: false,
        dialogconfirm:false,
        sports: []
      }
    },

    watch: {
      getLanguage: async function (val) {
        await this.setPreload(true);
        await this.setLanguage(val);
        await this.filterSport({'language': val});
        await this.setPreload(false);
      },

      searchSport() {
        this.getData()
      },
      getItemsSport(newValue, oldValue){
        this.sports = newValue;
      }


    },

    computed: {
      ...mapGetters({
        'getToken': 'profile/getToken',
        'getUrl': 'settings/getUrl',
        'getLanguage': 'profile/getLanguage',
        'getItemsSport': 'sport/getItems',
        'getMarkets': 'utils/getMarkets',
        'getSettingsGroupsMarket': 'utils/getSettingsGroupsMarket',
        'getProducers': 'producers/getList',
        'getPlatformId': 'rules/getPlatformId',
        'getMarketsMini': 'utils/getMarketsMini',
      }),
    },
    async created() {
      await this.setPreload(true);
      await this.fetchSports({'language': this.getLanguage});
      //await this.getSettingsGroup();
      await this.setPreload(false);
    },


    methods: {

      ...mapActions({
        'fetchSports': 'sport/fetchSports',
        'filterSport': 'sport/filterSport',
        'fetchProducers': 'producers/fetchProducers',
        'getMarketsBySport': 'utils/getMarketsBySport',
        'getMarketsBySportList': 'utils/getMarketsBySportList',
        'setPreload': 'utils/setPreload',
        'fetchSettingsGroupsMarket': 'utils/fetchSettingsGroupsMarket',
        'createNewGroupOdds': 'utils/createNewGroupOdds',
        'updateGroupOdds': 'utils/updateGroupOdds',
        'refactoringSettingsOdds': 'utils/refactoringSettingsOdds',
        'updateSingleMarket': 'utils/updateSingleMarket',
        'cacheMarkets': 'cache/cacheMarket',
        'fetchMarketOutcomesAndTranslations': 'market/fetchMarketOutcomesAndTranslations',
        'fetchMarketBySport': 'market/fetchMarketBySport',
        'fetchMarketOutcomesAndTranslations':'market/fetchMarketOutcomesAndTranslations'
      }),

      ...mapMutations({}),

      getData() {
        this.dataGroups = {}
        this.getSettingsGroup();
      },
      confirmDelete(){
        this.dialogconfirm=false;

        this.setPreload(true);
        let url = "markets/delete/event/market";
        this.messages = [];

        axios.post(`${this.getUrl}${url}`,this.form,{ 'headers': { 'Authorization': `Bearer ${this.getToken}`}})

                .then( res => {
                  this.deleteitem='';
                  this.snackbar.color="success";
                  this.snackbar.text= "Odds delete form every events";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout=5000;
                  this.preload = false;
                })
                .catch(err => {

                  this.setPreload(false);

                  if(err.response) {
                    let errors = err.response.data.error;
                    if (errors) {
                      console.log(errors);
                      errors.forEach(value => {
                        console.log(value.msg);
                        this.messages[value.param] = value.msg
                      })

                    }
                    this.snackbar.color="error";
                    this.snackbar.text= err.message;
                    this.snackbar.snackbar = true;
                    this.snackbar.mode = 'multi-line';
                    this.snackbar.timeout=5000;
                    this.preload = false;
                    if(err.response.status===401){
                      this.setLogout();
                      this.$router.push('/login')
                    }
                  }
                });

      },

    // @Deprecated
    // async getSettingsGroup() {
    //   await this.setPreload(true);
    //   await this.getMarketsBySport({
    //     'sport': this.searchSport,
    //     'lang': this.getLanguage,
    //     'enableSpecifiers': false
    //   });
    //   await this.setPreload(false);
    // },

      async getSettingsGroup() {
        await this.setPreload(true);

      if (this.searchSport != null) {

        await this.getMarketsBySportList({
          'sport': this.searchSport,
          'lang': this.getLanguage,
          'enableSpecifiers': false
        });
      }

        await this.setPreload(false);
      },

      getNameTranslated(collection) {
        return _.find(collection, {"lang": this.getLanguage}).text;
      },

      // editMarket(item) {
      //   this.marketToEdit = {...item}
      //   _.forEach(this.marketToEdit.outcomes, (out) =>{
      //     _.forEach(out.detail, (detail) =>{
      //       if(detail.translated == undefined){
      //         detail.translated = false
      //       }
      //     })
      //   })
      //
      //   this.marketToEdit.outcomes = _.orderBy(this.marketToEdit.outcomes, ['sort'], ['asc'])
      //
      // },

      async editMarket(item) {
        const marketId = item.id

        const result = await this.fetchMarketOutcomesAndTranslations({
          sportId: this.searchSport,
          marketId: marketId,
          language: this.getLanguage
        })

        this.isAHugeMarket = result.isAHugeMarket
        this.marketToEdit = {...result}

      },
      deleteItem (item) {
        this.dialogconfirm=true;
        this.form = item;
        _.assignIn(this.form,{sport:this.searchSport})
        //console.log(this.form)
      },
      async clearcache(){
        await this.setPreload(true);
        //await this.cacheMarkets()
        await this.cacheMarkets()
        await this.setPreload(false);

      },
      async updateMarket(){
        await this.setPreload(true);
        await this.updateSingleMarket({'data':this.marketToEdit})
        await this.setPreload(false);
      },

      alreadyAdded(unique_id, index_content) {
        var check = _.find(this.dataGroups.specifiers[index_content].markets, (mark) => {
          return mark.unique_id.toString() == unique_id.toString()
        })
        return (check != undefined)
      },

      addMarket(item, index_content) {
        var lastIdx = this.dataGroups.specifiers[index_content].markets.length +1;
        item.sort = (lastIdx == 1) ? 1 : lastIdx+1
        item.unique_id = item.unique_id.toString()
        this.dataGroups.specifiers[index_content].markets.push(item)
      },

      removeMarket(unique_id, index_content) {
        var vue = this

        var index = _.findIndex(this.dataGroups.specifiers[index_content].markets, function (o) {
          return o.unique_id.toString() == unique_id.toString();
        });
        if (index > -1) {
          this.dataGroups.specifiers[index_content].markets.splice(index, 1)
        }

        var sort = 1;
        _.forEach(this.getSettingsGroupsMarket.specifiers[index_content].markets, (mark) => {
          mark.sort = sort
          sort++
        })

      },

      sortMarket(unique_id, index_content, mode) {

        var marketsArray = this.dataGroups.specifiers[index_content].markets;

        var index = _.findIndex(marketsArray, function(o) { return o.unique_id.toString() == unique_id.toString(); });

        if(mode == 'up' && index > -1){

          var tempMarket = {... marketsArray[index-1]}
          tempMarket.sort++
          var actualMarket = {... marketsArray[index]}
          actualMarket.sort--

          marketsArray[index-1] = actualMarket
          marketsArray[index] = tempMarket

        }else if(mode == 'down' && index > -1){

          var tempMarket = {... marketsArray[index+1]}
          tempMarket.sort--
          var actualMarket = {... marketsArray[index]}
          actualMarket.sort++

          marketsArray[index+1] = actualMarket
          marketsArray[index] = tempMarket

        }

        this.dataGroups.specifiers[index_content].markets = [...marketsArray]


      }

    },
  }
</script>

<style scoped>

</style>
