<template>
    <div id="update-entity-limit-dialog-component">
        <v-dialog transition="dialog-bottom-transition" max-width="60%" v-model="activeDialog"
                  @keydown.esc="closeUpdateEntityLimitDialog">
            <v-card>
                <v-toolbar color="primary" dark class="text-uppercase" dense>
                    {{ $t('generic.update') }}
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark>
                            <v-icon @click="closeUpdateEntityLimitDialog">mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <!--                    <v-row>-->
                    <!--                        <v-col md="12">-->
                    <!--                            <div style="height: 36px">-->
                    <!--                                <div class="alert-container">-->
                    <!--                                    <transition name="fade" v-if="alertSuccessEnabled">-->
                    <!--                                        <v-alert type="success">-->
                    <!--                                            {{$t('successOperation')}}-->
                    <!--                                        </v-alert>-->
                    <!--                                    </transition>-->
                    <!--                                    <transition name="fade" v-if="alertErrorEnabled">-->
                    <!--                                        <v-alert type="error">-->
                    <!--                                            {{$t('operationNotPerformedCorrectly')}}-->
                    <!--                                        </v-alert>-->
                    <!--                                    </transition>-->
                    <!--                                </div>-->
                    <!--                            </div>-->
                    <!--                        </v-col>-->
                    <!--                    </v-row>-->

                    <v-row>
                        <v-col md="12">
                            <v-col md="4" class="column-rule">
                                <v-text-field
                                        v-model="entityId"
                                        :label="this.$t('risk.entityId')"
                                        readonly
                                ></v-text-field>
                            </v-col>
                            <v-col md="4" class="column-rule">
                                <v-text-field
                                        v-model="entity_type"
                                        :label="this.$t('risk.entityType')"
                                        readonly
                                ></v-text-field>
                            </v-col>
                            <v-col md="4" class="column-rule">
                                <v-text-field
                                        v-model="entity_name"
                                        :label="this.$t('generic.name')"
                                        readonly
                                ></v-text-field>
                            </v-col>
                            <v-col md="4" class="column-rule">
                                <v-select class="text-capitalize" :items="limits_type" item-text="text"
                                          id="limits_type"
                                          item-value="value" label="limits_type" dense
                                          outlined
                                          v-model="limits_type_selected">
                                </v-select>
                            </v-col>
                            <v-col md="4" class="column-rule">

                                <v-text-field v-if="limits_type_selected == 'percentage'"
                                              class="text-capitalize like-select"
                                              v-model="limit_amount"
                                              id="limit_amount"
                                              label="limit_amount" dense outlined type="number"
                                              min="0" step="1" max="100">
                                </v-text-field>

                                <v-text-field v-else class="text-capitalize like-select"
                                              v-model="limit_amount"
                                              id="limit_amount"
                                              label="limit_amount" dense outlined type="number"
                                              min="0" step="1">
                                </v-text-field>

                            </v-col>
                            <v-col md="4" class="column-rule">
                                <v-select class="text-capitalize" :items="limit_action" item-text="text"
                                          id="limit_action"
                                          item-value="value" label="limit_action" dense
                                          outlined
                                          v-model="limit_action_selected">
                                </v-select>
                            </v-col>

                            <v-btn class="me-4" @click="updateEntityLimit()"
                                   type="submit">
                                {{$t('generic.update')}}
                            </v-btn>

                            <v-btn class="me-4" @click="deleteRule()"
                                   type="submit"
                                   color="error"
                            >
                                {{$t('generic.delete')}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';

    export default {
        name: "UpdateEntityLimitDialog",
        props: {
            entity_id: {
                type: String,
                required: true
            },
            entityLimitToEdit: {
                type: Object,
                required: true
            },
            userTarget: {
                type: Object,
                required: false,
                default: null
            }
        },
        data() {
            return {
                activeDialog: true,
                message: '',
                alertSuccessEnabled: false,
                alertErrorEnabled: false,
                entityId: null,
                entity_type: null,
                entity_name: '',
                limits_type: [
                    {text: this.$t('percentage'), value: 'percentage'},
                    {text: this.$t('bets.winning'), value: 'amount'}
                ],
                limits_type_selected: null,
                limit_amount: 1000,
                limit_action: ['check', 'reject'],
                limit_action_selected: 'check',
            }
        },
        async mounted() {
            this.entityId = this.entityLimitToEdit.entity_id;
            this.entity_name = this.entityLimitToEdit.entity_name;
            this.entity_type = this.entityLimitToEdit.entity_type;
            this.limits_type_selected = this.entityLimitToEdit.limits_type;
            this.limit_amount = this.entityLimitToEdit.limits;
            this.limit_action_selected = this.entityLimitToEdit.limits_action;
        },
        beforeDestroy() {

        },
        computed: {
            ...mapGetters({}),
        },
        watch: {
            limits_type_selected(newValue, oldValue) {
                if (newValue == 'percentage' && oldValue == 'amount') {
                    this.limit_amount = 100;
                }
            }
        },
        methods: {
            ...mapActions({
                'setPreload': 'utils/setPreload',
                'updateEntityRule': 'risk/updateEntityRule',
                'updateEntityUserRule': 'risk/updateEntityUserRule',
                'deleteEntityRule': 'risk/deleteEntityRule',
                'deleteEntityUserRule': 'risk/deleteEntityUserRule'
            }),
            showSuccessAlert() {
                this.alertSuccessEnabled = true;
                setTimeout(() => {
                    this.alertSuccessEnabled = false;
                }, 2000)
            },
            showErrorAlert() {
                this.alertErrorEnabled = true;
                setTimeout(() => {
                    this.alertErrorEnabled = false;
                }, 5000)
            },
            async closeUpdateEntityLimitDialog() {
                this.activeDialog = false;
                await this.$root.$emit('closedUpdateEntityLimitDialog');
                await this.$root.$emit('closedUpdateEntityUserLimitDialog');
            },
            async updateListAndCloseModal() {
                await this.$root.$emit('updateEntityRuleList');
                await this.closeUpdateEntityLimitDialog();
            },
            async updateEntityLimit() {

                await this.setPreload(true);
                const payload = {
                    _id: this.entityLimitToEdit._id,
                    limits_type: this.limits_type_selected,
                    limits: this.limit_amount,
                    limits_action: this.limit_action_selected
                }

                let response = null;

                if (this.userTarget == null) {
                    response = await this.updateEntityRule(payload);
                } else {
                    response = await this.updateEntityUserRule(payload);
                }

                await this.$root.$emit('updateEntityRuleList');
                await this.setPreload(false);

                if (response && response.data && response.data.messages && response.data.messages == "Success Operation") {
                    // this.showSuccessAlert();
                    this.$swal.fire(this.$t('successOperation'), '', 'success')
                    await this.closeUpdateEntityLimitDialog();
                } else {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: this.$t('operationNotPerformedCorrectly')
                    })
                    await this.closeUpdateEntityLimitDialog();
                }
            },
            async deleteRule() {
                const vm = this;

                const payload = {
                    _id: this.entityLimitToEdit._id,
                }

                this.$swal.fire({
                    title: vm.$t('generic.areYouSure'),
                    text: vm.$t('generic.youWontBeAbleToRevertThis'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: vm.$t('generic.yesDeleteIt'),
                    cancelButtonText: vm.$t('generic.noCancel'),
                    reverseButtons: true
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await this.setPreload(true);

                        let response = null;

                        if (this.userTarget == null) {
                            response = await this.deleteEntityRule(payload);
                        } else {
                            response = await this.deleteEntityUserRule(payload);
                        }

                        await this.setPreload(false);

                        if (response && response.data && response.data.messages && response.data.messages == 'Rules entity deleted') {
                            vm.$swal.fire(vm.$t('successOperation'), '', 'success');
                            await this.updateListAndCloseModal();
                        } else if (response && response.data && response.data.messages && response.data.messages == 'Rules entity user deleted') {
                            vm.$swal.fire(vm.$t('successOperation'), '', 'success');
                            await this.updateListAndCloseModal();
                        } else {
                            vm.$swal.fire(vm.$t('operationNotPerformedCorrectly'), '', 'error');
                            await this.updateListAndCloseModal();
                        }
                    } else if (result.dismiss === vm.$swal.DismissReason.cancel) {
                        vm.$swal.fire(vm.$t('changesHaveNotBeenSaved'), '', 'info');
                        await this.updateListAndCloseModal();
                    }
                })
            }
        },
    }
</script>

<style scoped>
    .column-rule {
        padding: 6px !important;
    }
</style>
