<template>
  <v-app>

    <toolbar v-if="getAutenticate"></toolbar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-dialog v-model="getPreload" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="this.getReviewNotification && this.getPlatformId == 0" right top rounded color="red" timeout="-1">
      {{ $t('newBetUnderReview') }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="closeNotification()">
          {{ $t('generic.close') }}
        </v-btn>
      </template>
    </v-snackbar>

  </v-app>
</template>


<script>


import {mapGetters, mapMutations, mapActions} from "vuex";

export default {
  name: 'App',
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      'getAutenticate': 'profile/getAutenticate',
      'getPreload': 'utils/getPreload',
      'getReviewNotification': 'bet/getReviewNotification',
      'getPlatformId': 'rules/getPlatformId',
    }),

  },

  methods: {
    ...mapActions({
      'setPreload': 'utils/setPreload',
      'fetchSkins': 'skins/fetchSkins',
      'setReviewNotification': 'bet/setReviewNotification',
    }),

    async closeNotification(){
      await this.setReviewNotification(false)
    },
  }
};
</script>
