<template>

  <div>
    <v-container fluid fill-height>
      <v-layout justify-center align-center>
        <v-card width="100%" outlined elevation-0>
          <v-card-title>
            {{ $t('manualmarket') }}

            <v-spacer></v-spacer>

          </v-card-title>

          <v-data-table :headers="headers" :items="getItems"  item-key="_id" class="elevation-0">
            <template v-slot:body="{ items }">
              <tbody>
              <tr v-for="(item,index) in items" :key="index">

                <td>{{ _.find(item.detail[0].detail,{lang:getLanguage}).text }}</td>
                <td>
                  <v-chip v-for="chip of item.odds" class="mr-2">
                    {{ chip.name}} : <b>{{ chip.value}}</b>
                  </v-chip>
                </td>

                <td width="120">
                  <v-icon class="mr-0" @click="openEdit(item)">
                    mdi-briefcase-edit
                  </v-icon>
                  <v-icon class="mr-0" @click="deleteItem(item)">
                    mdi-trash-can
                  </v-icon>
                  <v-icon class="mr-0" @click="showManua(item)">mdi-file-find</v-icon>
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>

        <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :vertical="snackbar.mode === 'vertical'">
          {{ snackbar.text }}
          <v-btn dark text @click="snackbar.snackbar = false">
            Chiudi
          </v-btn>
        </v-snackbar>

        <v-btn fab bottom right color="secondary" dark fixed @click.prevent="openNew">
          <v-icon>add</v-icon>
        </v-btn>
      </v-layout>
    </v-container>

    <v-dialog persistent v-model="dialog" width="1000px">
      <v-card>
        <v-card-title class="grey lighten-4 py-4 title">
          {{ titleDialog }}
        </v-card-title>
        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap>


            <v-flex xs6>
              <v-select
                      :error-messages="messages.sport"
                      v-model="form.sport"
                      :items="getItemsSport"
                      item-text="name"
                      item-value="id"
                      :label="$t('generic.sport')"
                      @change="sportSelect"
              ></v-select>

            </v-flex>
            <!--<v-flex xs6>
              <v-select :items="statuses"  item-text="text" item-value="value" label="Logo" v-model="form.logo"></v-select>
            </v-flex>-->


            <v-flex xs12>
              <v-autocomplete
                      :error-messages="messages.markets"
                      v-model="form.markets"
                      :items="sportMarkets"

                      clearable
                      :label="$t('market')"

                      item-value="id"
                      item-text="name"
                      @change="marketSelect"


              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 >
              <v-autocomplete
                      :error-messages="messages.categories"
                      v-model="form.categories"
                      :items="sportCategories"
                      :label="$t('country')"
                      item-value="id"
                      item-text="name"

                      chips
                      deletable-chips
                      multiple
                      small-chips

                      @change="categorySelect"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 v-if="form.categories.length>0">
              <v-autocomplete
                      :error-messages="messages.tournaments"
                      v-model="form.tournaments"
                      :items="categoryTournament"
                      :label="$t('tournament')"

                      item-value="id"
                      item-text="name"
                      chips
                      deletable-chips
                      multiple
                      small-chips


              ></v-autocomplete>
            </v-flex>

            <v-flex xs-12 v-if="form.markets" v-for="odd in this.form.odds">
              <v-text-field v-model="odd.value" hide-details :label="odd.name" type="number" ></v-text-field>

            </v-flex>



            <!--<v-flex xs6 >
              <v-select
                      v-model="form.header"
                      :items="selectHeaders"
                      label="Header"
                      item-value="id"
                      item-text="name"
                      clearable
              ></v-select>
            </v-flex>-->


        <!--    <v-flex xs6>
              <v-select :items="statuses"  item-text="text" item-value="value" label="Stato" v-model="form.active"></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select :items="statuses"  item-text="text" item-value="value" label="Quickbet" v-model="form.quickbet"></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select :items="statuses"  item-text="text" item-value="value" label="Transfer agnecy to Quickbet" v-model="form.showquickbet"></v-select>
            </v-flex>-->
          <!--  <v-flex xs6>
              <v-text-field label="Agent type" v-model="form.settings.agent_type" ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field label="Id partner" v-model="form.settings.id_partner" ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field label="Skin id" v-model="form.settings.skin_id" ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field label="Save endpoint" v-model="form.settings.save_endpoint" ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field label="Sircms endpoint" v-model="form.settings.sircms_endpoint" ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field label="Print endpoint" v-model="form.settings.print_endpoint" ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-autocomplete :items="timezones" label="Timezone" v-model="form.settings.timezone"></v-autocomplete>
            </v-flex>-->

          </v-layout>
        </v-container>

        <v-card-actions justify="end">
          <v-btn color="primary" @click="dialog = false">{{$t('generic.close')}}</v-btn>
          <v-btn color="secondary" @click.prevent="submit">{{$t('generic.save')}}</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-dialog v-model="dialogConfirm" max-width="290">
      <v-card>

        <v-card-title class="headline">{{ titleDialog }}</v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="dialogConfirm = false">
            {{$t('generic.close')}}
          </v-btn>

          <v-btn color="secondary" @click="confirmDelete">
            {{$t('generic.yes')}}
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import timezone from '@/assets/timezone.json'
export default {
  name: "MarketManual",
  data() {
    return {
      timezones: timezone,
      search: '',
      statuses: [
        {'text':'Attivo', value: true},
        {'text':'Non Attivo', value: false}
      ],
      pagination: {
        sortBy: 'platform_id',
        descending: true,
        rowsPerPage: 10
      },
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Odds', value: 'odds',sortable: false},
        {text: 'Action', value: '', sortable: false}
      ],

      snackbar:{
        snackbar: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
      },
      titleDialog: '',
      dialog: false,
      dialogConfirm: false,
      idSkin: -1,
      actionForm : '',
      textOrintation:'',
      form:{
        platform_id: '',
        sport : '',
        markets: [],
        categories: [],
        tournaments: [],
        odds:[]
      },
      textgroup:'',
      deleteitem: '',
      show1: false,
      messages: {
        name: [],
        sport: [],
        sheet: [],
        days: [],
        groupby: [],
        /*settings: {
          agent_type: '',
          id_partner: '',
          skin_id: '',
          save_endpoint: '',
          print_endpoint: ''
        }*/
      },
      orientations:[],
      sheets:[{size:"A4",value:9},{size:"A3",value:8}],
      textSheet:'',
      sportMarkets:[],
      textGroup:'',
      sportCategories:[],
      categoryTournament:[],
      selectGroup:[],
      selectLogos:[],
      selectBanners:[],
      selectHeaders:[],
      listTemplates:[],
      deleteItemJ:{},
      odds: []


    }
  },
  watch: {

  },
  async mounted() {
    //this.resetForm();
    await this.setPreload(true);

    await this.fetchSports({'language': this.getLanguage});
    await this.fetchMarketManualList({platform_id:this.getPlatformId})
    /*this.orientations = [{name:this.$t('generic.landscape'),value:'landscape'},{name:this.$t('generic.portrait'),value:'portrait'}]
    this.textOrintation= this.$t('generic.orientation')
    this.textSheet= this.$t('sheet')
    this.textGroup = this.$t('group')*/

       //await this.fetchSports({'language': this.getLanguage});





    await this.setPreload(false);



  },
  computed: {
    ...mapGetters({
      'getAutenticate': 'profile/getAutenticate',
      'getToken': 'profile/getToken',
      'getLanguage': 'profile/getLanguage',
      'getUrl': 'settings/getUrl',
      'getLists': 'stamps/getLists',
      'getPreload': 'utils/getPreload',
      'getSkins': 'skins/getList',
      'getItemsSport': 'sport/getItems',
      'getMultimedias': 'multimedia/getList',
      'getPlatformId': 'rules/getPlatformId',
      'getItems': 'market/getLists',
    }),
  },
  methods: {
    ...mapMutations({

    }),
    ...mapActions({
      'setPreload': 'utils/setPreload',
      'fetchStamps': 'stamps/fetchStamps',
      'fetchMarket': 'stamps/fetchMarketManual',
      'cacheRules': 'cache/cacheRules',
      'fetchSports': 'sport/fetchSports',
      'fetchCategorySport': 'sport/fetchCategorySport',
      //'fetchCategorySportManual': 'sport/fetchCategorySportManual',
      'fetchTournamentByCategory': 'sport/fetchTournamentByCategory',
      'fetchMultimedia': 'multimedia/fetchList',
      'fetchSkins': 'skins/fetchSkins',
      'fetchMarketOutcome': 'market/fetchMarket',
      'fetchMarketManualList': 'market/fetchMarketManualList',

    }),
    async clearcache(){
      await this.setPreload(true);
      //await this.cacheMarkets()
      await this.cacheRules()
      await this.setPreload(false);

    },
    openNew() {
      this.titleDialog = this.$t('generic.new');
      this.resetForm()

      this.actionForm = 'create';
      this.dialog = true;
    },
    async categorySelect(){
      await this.setPreload(true);
      var listTournaments = await this.fetchTournamentByCategory({categories:JSON.stringify(this.form.categories)})
      //console.log(listTournaments)
      this.categoryTournament=[]
      if(_.size(listTournaments)>0){
        _.forEach(listTournaments,list =>{
          if(list.active){
            let nameCat = _.find(list.detail,{lang:this.getLanguage}).text
            this.categoryTournament.push({id:list.id,name:nameCat})
          }
        })
      }

      await this.setPreload(false);
    },
    async marketSelect(){
      var odds = await this.fetchMarketOutcome({market:this.form.markets})

      this.form.odds = []
      _.forEach(odds.outcomes,out =>{
        this.form.odds.push({name:out.name,id:out.id,value:0})
      })

    },
    async sportSelect(){
      await this.setPreload(true);
      var markets = await this.fetchMarket({enableSpecifiers: true, lang: this.getLanguage, sport: -1, id: this.form.sport})
      this.sportMarkets = markets
      var listCategories = await this.fetchCategorySport({sport:this.form.sport})
      this.sportCategories=[]
      //sportCategories
      if(_.size(listCategories)>0){
        _.forEach(listCategories,list =>{
          if(list.active){
            let nameCat = _.find(list.detail,{lang:this.getLanguage}).text
            this.sportCategories.push({id:list.id,name:nameCat})
          }
        })
        this.sportCategories= _.sortBy(this.sportCategories,['name'])
      }
      await this.setPreload(false);

      //this.sportCategories

      //console.log(this.form.category)
      /*var listTournaments = await this.fetchTournamentByCategory({categories:JSON.stringify(this.form.category)})
      this.categoryTournament=[]
      if(_.size(listTournaments)>0){
        _.forEach(listTournaments,list =>{
          if(list.active){
            let nameCat = _.find(list.detail,{lang:this.getLanguage}).text
            this.categoryTournament.push({id:list.id,name:nameCat})
          }
        })
      }*/
      //categoryTournament
    },
    putOnOtherFields(){
      this.form.settings.save_endpoint = this.form.website+'/bookie/gaim/sport/'
      this.form.settings.print_endpoint = this.form.website+'/receipts/receiptGeneral8mm.jsp?externalId='
    },
    showManua(item){
      //console.log(`/odds/matchmanual/${item._id}`)
      //onsole.log(this.$router)
      this.$router.push("/odds/matchmanual/"+item._id)
      return
    },
    async openEdit(item) {
      await this.setPreload(true);
      this.titleDialog = this.$t('generic.edit');;
      this.actionForm = 'update';

      this.form = JSON.parse(JSON.stringify(item))
      var markets = await this.fetchMarket({enableSpecifiers: true, lang: this.getLanguage, sport: -1, id: this.form.sport})
      this.sportMarkets = markets
      var listCategories = await this.fetchCategorySport({sport:this.form.sport})
      this.sportCategories=[]
      //sportCategories
      if(_.size(listCategories)>0){
        _.forEach(listCategories,list =>{
          if(list.active){
            let nameCat = _.find(list.detail,{lang:this.getLanguage}).text
            this.sportCategories.push({id:list.id,name:nameCat})
          }
        })
        this.sportCategories= _.sortBy(this.sportCategories,['name'])
      }
      var odds = await this.fetchMarketOutcome({market:this.form.markets})

     /* this.form.odds = []
      _.forEach(odds.outcomes,out =>{
        this.form.odds.push({name:out.name,id:out.id,value:0})
      })*/
      var listTournaments = await this.fetchTournamentByCategory({categories:JSON.stringify(this.form.categories)})
      //console.log(listTournaments)
      this.categoryTournament=[]
      if(_.size(listTournaments)>0){
        _.forEach(listTournaments,list =>{
          if(list.active){
            let nameCat = _.find(list.detail,{lang:this.getLanguage}).text
            this.categoryTournament.push({id:list.id,name:nameCat})
          }
        })
      }

      /*var markets = await this.fetchMarket({enableSpecifiers: true, lang: this.getLanguage, sport: -1, id: this.form.sport})
      this.sportMarkets = markets
      var listCategories = await this.fetchCategorySport({sport:this.form.sport})
      this.sportCategories=[]
      //sportCategories
      if(_.size(listCategories)>0){
        _.forEach(listCategories,list =>{
          if(list.active){
            let nameCat = _.find(list.detail,{lang:this.getLanguage}).text
            this.sportCategories.push({id:list.id,name:nameCat})
          }
        })
        this.sportCategories= _.sortBy(this.sportCategories,['name'])
      }
      this.form.categories = item.categories
      var listTournaments = await this.fetchTournamentByCategory({categories:JSON.stringify(this.form.categories)})
      //console.log(listTournaments)
      this.categoryTournament=[]
      if(_.size(listTournaments)>0){
        _.forEach(listTournaments,list =>{
          if(list.active){
            let nameCat = _.find(list.detail,{lang:this.getLanguage}).text
            this.categoryTournament.push({id:list.id,name:nameCat})
          }
        })
      }
      this.form.tournaments = item.tournaments
      this.form.groupby = item.groupby
      this.form.header = item.header
      this.form.logo = item.logo
      this.form.banner = item.banner
      this.form.footer = item.footer
*/

      await this.setPreload(false);
      this.dialog = true;
    },

    async submit() {

      await this.setPreload(true);

      this.messages = {
        sport: '',
        markets: '',
        categories:'',
        tournaments: '',
        odds: ''
      }


      this.errors = []
      if (_.size(this.form.categories) == 0) {
        this.errors.push(this.$t('category') + ' ' + this.$t('error'));
        _.set(this.messages, `categories`, this.$t('category') + ' ' + this.$t('error'))
      }
      if (_.size(this.form.tournaments) == 0) {
        this.errors.push(this.$t('tournament') + ' ' + this.$t('error'));
        _.set(this.messages, `tournaments`, this.$t('tournament') + ' ' + this.$t('error'))
      }
      if (this.form.sport == '') {
        this.errors.push(this.$t('sport') + ' ' + this.$t('error'));
        _.set(this.messages, `sport`, this.$t('sport') + ' ' + this.$t('error'))
      }

      if (this.form.markets == '') {
        this.errors.push(this.$t('markets') + ' ' + this.$t('error'));
        _.set(this.messages, `markets`, this.$t('markets') + ' ' + this.$t('error'))
      }

      if (_.size(this.errors) > 0) {

        var testo = '';
        this.errors.forEach(value => {
          testo += value + '.';

        })


        this.snackbar.color = "error";
        this.snackbar.text = testo;
        this.snackbar.snackbar = true;
        this.snackbar.mode = 'multi-line';
        this.snackbar.timeout = 5000;

        await this.setPreload(false);
      } else {
        var url = '';
        if (this.actionForm == "create") {

          url = this.getUrl + 'markets/manual/store'
        } else if (this.actionForm == "update") {
          url = this.getUrl + 'markets/manual/update'
        }
        this.form.platform_id = this.getPlatformId
        let payload = this.form;
        this.messages = [];

        axios.post(url, payload, {'headers': {'Authorization': `Bearer ${this.getToken}`}})
                .then(async (res) => {

                  /*await this.fetchSkins({})
                  var rule = _.find(this.getSkins, {platform_id: this.getPlatformId})
                  this.listTemplates = (rule.templates) ? rule.templates : []
                  */
                  this.dialog = false;

                  this.snackbar.color = "success";
                  this.snackbar.text = this.$t("generic.successfully");
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;
                  await this.fetchMarketManualList({platform_id:this.getPlatformId})
                  this.setPreload(false);

                })

                .catch(err => {
                  console.log(err)
                  if (err.response) {

                    var msg_error = '';

                    if (err.response.data.error != undefined) {

                      err.response.data.error.forEach(value => {
                        this.messages[value.param] = value.msg
                      })

                      msg_error = err.response.statusText;

                    } else {

                      msg_error = err.response.data.message

                    }


                    this.snackbar.color = "error";
                    this.snackbar.text = msg_error;
                    this.snackbar.snackbar = true;
                    this.snackbar.mode = 'multi-line';
                    this.snackbar.timeout = 5000;

                    this.setPreload(false);

                    if (err.response.status === 401) {
                      this.setLogout();
                      this.$router.push('/login')
                    }

                  }

                });
      }


    },

    resetForm() {
      this.form={
              platform_id: 0,
                sport : 0,

                markets: [],
                categories: [],
                tournaments: []

      }
      this.messages= {
                name: [],
                sport: [],
                sheet: [],
                days: [],
                groupby: []
      }
    },

    deleteItem(item) {
      this.deleteItemJ = item
      this.dialogConfirm = true;
      this.titleDialog = this.$t("generic.areYouSure")

    },
    async confirmDelete() {

      this.dialogConfirm = false;
      await this.setPreload(true);
      var url = this.getUrl+'markets/manual/delete'
      this.messages = [];

      axios.post(url, this.deleteItemJ,{'headers': {'Authorization': `Bearer ${this.getToken}`}})

          .then(async(res) => {

            this.snackbar.color = "success";
            this.snackbar.text = this.$t("generic.successfully");
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;
            await this.fetchMarketManualList({platform_id:this.getPlatformId})

            this.setPreload(false);

          })
          .catch(err => {

            console.log(err)
            if (err.response) {

              var msg_error = '';

              if (err.response.data.error != undefined) {

                err.response.data.error.forEach(value => {
                  this.messages[value.param] = value.msg
                })

                msg_error = err.response.statusText;

              }else{

                msg_error = err.response.data.message

              }


              this.snackbar.color = "error";
              this.snackbar.text = msg_error;
              this.snackbar.snackbar = true;
              this.snackbar.mode = 'multi-line';
              this.snackbar.timeout = 5000;

              this.setPreload(false);

              if (err.response.status === 401) {
                this.setLogout();
                this.$router.push('/login')
              }

            }


          });

    }
  },
}
</script>

<style scoped>

</style>
