<template>
  <div>
    <v-container fluid>
      <v-layout row>

<!--        <v-flex md12>-->
<!--          <menu-sport></menu-sport>-->
<!--        </v-flex>-->

        <v-flex md12>
          <v-col cols="12">
            <span class="title-table">{{ this.$t('live.live') | capitalize}}</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" class="pt-0 pb-0">
                    <div class="title-table text-right"> * Elemento non tradotto nella lingua selezionata</div>
                  </v-col>

                  <v-col cols="4">
                    <div class="title-table">LISTA SPORTS ({{getItemsSport.length}})</div>
                    <v-progress-linear indeterminate color="secondary darken-2" v-if="getPreload"></v-progress-linear>
                    <v-simple-table dense class="table-theme border-all-theme">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">ID</th>
                            <th class="text-left">Nome</th>
                            <th class="text-left">Status</th>
                            <th class="text-left">Azioni</th>
                          </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in getItemsSport" :key="item.id" v-bind:class="{ active_element: (sportSearched == item.id) }">
                          <td :title="item.id">{{ item.id }}</td>
                          <td>{{ item.name }}</td>
                          <td class="actions-element">

                            <v-icon   title="Cambia status" :class="(checkElement(item.id,'sport')) ? 'active' : 'disabled'" @click="changeStatusDisable(item, 'sport',$event)">check_circle</v-icon>
                          </td>

                          <td class="actions-element">


                            <v-icon v-if="checkElement(item.id,'sport')" title="Mostra Categorie" @click="getCategoriesFromSport(item.id)" >zoom_in</v-icon>

                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                  </v-col>

                  <v-col cols="4">

                    <div class="title-table">LISTA CATEGORIE ({{getItemsCategories.length}})</div>

                    <v-progress-linear indeterminate color="secondary darken-2" v-if="getPreload"></v-progress-linear>
                    <v-simple-table dense class="table-theme border-all-theme">
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th class="text-left">ID</th>
                          <th class="text-left">Nome</th>
                          <th class="text-left">Status</th>
                          <th class="text-left">Azioni</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in getItemsCategories" :key="item.id" v-bind:class="{ active_element: (categorySearched == item.id) }">
                          <td :title="item.id">{{ item.id }}</td>
                          <td>{{ item.name }}</td>
                          <td class="actions-element">

                            <v-icon  title="Cambia status" :class="(checkElement(item.id,'category')) ? 'active' : 'disabled'" @click="changeStatusDisable(item, 'category', $event)">check_circle</v-icon>
                          </td>

                          <td class="actions-element">

                            <v-icon title="Mostra Tornei" v-if="checkElement(item.id,'category')" @click="getTournamentsFromCategories(item.id)" >zoom_in</v-icon>

                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                  </v-col>

                  <v-col cols="4">

                    <div class="title-table">LISTA TORNEI ({{getItemsTournaments.length}})</div>

                    <v-progress-linear indeterminate color="secondary darken-2" v-if="getPreload"></v-progress-linear>
                    <v-simple-table dense class="table-theme border-all-theme">
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th class="text-left">ID</th>
                          <th class="text-left">Nome</th>
                          <th class="text-left">Status</th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in getItemsTournaments" :key="item.id">
                          <td :title="item.id">{{ item.id }}</td>
                          <td class="name-element-tree">{{ item.name }}</td>
                          <td class="actions-element">

                            <v-icon  title="Cambia status" :class="(checkElement(item.id,'tournament')) ? 'active' : 'disabled'" @click="changeStatusDisable(item, 'tournament', $event)">check_circle</v-icon>
                          </td>

                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                  </v-col>

                </v-row>
              </v-container>

            </v-card>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>

    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :vertical="snackbar.mode === 'vertical'">
      {{ snackbar.text }}
      <v-btn dark text @click="snackbar.snackbar = false">
        Chiudi
      </v-btn>
    </v-snackbar>

    <v-dialog persistent v-model="dialog" width="1000px">
      <v-card>
        <v-card-title class="grey lighten-4 py-4 title">
          {{ titleDialog }}
        </v-card-title>
        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap>

            <v-flex xs6 v-for="(field, i) in this.formToSubmit" :key="i">
              <v-text-field :label="field.lang" v-model="field.text" outlined></v-text-field>
            </v-flex>

          </v-layout>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="dialog = false">Indietro</v-btn>
          <v-btn color="secondary" @click.prevent="submitRule">Conferma</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirm" max-width="550">
      <v-card>

        <v-card-title class="headline">{{ titleDialog }}</v-card-title>

        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap style="line-height: 50px">
            <h4>Desideri cambiare lo status dell'elemento selezionato ?</h4>
            <code>{{changeStatus}}</code>
            <h5>N.B. Tutti i figli di questo elemento subiranno la stessa variazione di status</h5>
          </v-layout>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="dialogConfirm = false">
            Annulla
          </v-btn>

          <v-btn color="secondary" @click="submitRule">
            Conferma
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogPub" max-width="500">
      <v-card>
        <v-card-title class="headline">{{ titleDialog }}</v-card-title>
        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap>
            <h4>Desideri pubblicare l'elemento con il seguente status ?</h4>
            <v-select v-model="statusToPub" :items="statuses" item-text="text" item-value="value" outlined>
            </v-select>
          </v-layout>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="dialogPub = false">
            Annulla
          </v-btn>

          <v-btn color="secondary" @click="confirmPublish()">
            Conferma
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>
<style>

</style>
<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import _ from 'lodash'
import menuSport from "@/components/sports/menu";

export default {
  name: "Settings",

  data() {
    return {
      dialog: false,
      dialogConfirm: false,
      dialogPub: false,
      titleDialog: "",
      changeStatus: "",
      formToSubmit:[],
      typeRule : '',
      source: '',
      scope: '',
      sportSearched: '',
      categorySearched: '',
      statusToPub: true,
      statuses: [
        {text:"Attivo", value: true},
        {text:"Disattivato", value: false}
      ],
      snackbar:{
        snackbar: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
      },

    }
  },

  components:{menuSport},

  watch: {
    getLanguage: async function (val) {
      await this.setPreload(true);
      await this.setLanguage(val);
      await this.filterSport({'language': val});
      await this.filterCategories({'language': val});
      await this.filterTournaments({'language': val});
      await this.setPreload(false);
    },
  },

  computed: {
    ...mapGetters({
      'getToken': 'profile/getToken',
      'getUrl': 'settings/getUrl',
      'getPreload': 'utils/getPreload',
      'getLanguage': 'profile/getLanguage',
      'getLanguagesList': 'profile/getLanguagesList',
      'getSports': 'sport/getSports',
      'getItemsSport': 'sport/getItems',
      'getCategories': 'category/getCategories',
      'getItemsCategories': 'category/getItems',
      'getTournaments': 'tournament/getTournaments',
      'getItemsTournaments': 'tournament/getItems',
      'getPlatformId': 'rules/getPlatformId',
      'getNotPublished': 'tree/getNotPublished',
      'getList': 'skins/getList',
      'getRulesLives': 'skins/getRulesLives',

    }),


  },
  async created() {
    await this.setPreload(true);
    await this.fetchSports({'language': this.getLanguage});
    await this.fetchNotPublished();
    await this.fetchRules();
    await this.setPreload(false);

  },


  methods: {

    ...mapActions({
      'setPreload': 'utils/setPreload',
      'fetchSports': 'sport/fetchSports',
      'filterSport': 'sport/filterSport',
      'fetchCategories': 'category/fetchCategories',
      'filterCategories': 'category/filterCategories',
      'fetchTournaments': 'tournament/fetchTournaments',
      'filterTournaments': 'tournament/filterTournament',
      'resetTournaments': 'tournament/resetTournaments',
      'updateRules': 'skins/updateRules',
      'publishElement': 'skins/publishElement',
      'fetchSkins': 'skins/fetchSkins',
      'fetchNotPublished': 'tree/fetchNotPublished',
      'updateRulesFavourite': 'skins/updateRulesFavourite',
      'updateRulesLive': 'skins/updateRulesLive',
      'fetchRules': 'skins/fetchRules',
    }),

    ...mapMutations({
      'setLanguage': 'profile/setLanguage',
    }),
    checkElement(id,scope){

     /* console.log("RULES",this.getRulesLives)
      console.log("id",id)
      console.log("scope",scope)
      console.log("platform",this.getPlatformId)*/

      var status = true

      var ruleManager = _.find(this.getRulesLives,(el)=>{
        //return el.type=="disable" && el.scope=="sport" && el.status==true && el.id==id
        return  el.id==+id  && el.scope==scope && el.platform==0
      })
      if(ruleManager && ruleManager.type=="disable"){
        status = false
      }

      var rule = _.find(this.getRulesLives,(el)=>{
        //return el.type=="disable" && el.scope=="sport" && el.status==true && el.id==id
        return  el.id==+id  && el.scope==scope
      })
      if(rule && rule.type=="disable"){
        status = false
      }else if(rule && rule.type=="active"){
        status = true
      }




      return status
    },
    checkCategory(id){
      //console.log(id)
      //console.log("RULES",this.getRulesLives)
      var status = true
      var rule = _.find(this.getRulesLives,(el)=>{
        //return el.type=="disable" && el.scope=="sport" && el.status==true && el.id==id
        return  el.id==+id && el.type=="disable" && el.scope=="category"
      })
      if(rule){
        status = rule.status
      }

      return status
    },
    checkFavourite(tournament){
      var manager = _.find(this.getList,{platform_id:this.getPlatformId})
      var check = _.find(manager.rules,{scope:'tournament',type:'favourite',source:tournament})
      if(check){

          return true

      }else{
        return false
      }

    },
    async getCategoriesFromSport(sport){
      await this.setPreload(true);
      this.sportSearched = sport
      await this.fetchCategories({'language': this.getLanguage, 'paramKey':sport});
      await this.resetTournaments()
      await this.setPreload(false);
    },

    async getTournamentsFromCategories(category){

      await this.setPreload(true);
      this.categorySearched = category
      await this.fetchTournaments({'language': this.getLanguage, 'paramKey':category});
      await this.fetchSkins();
      await this.setPreload(false);

    },

    checkPublished(source, scope){
      let result = false

      if(scope == "sport"){
        if(_.find(this.getNotPublished.categories, { 'sport': source})){
          result = true
        }else if(_.find(this.getNotPublished.tournaments, { 'sport': source})){
          result = true
        }
      }

      if(scope == "category"){
        if(_.find(this.getNotPublished.tournaments, { 'category': source})){
          result = true
        }
      }

      return result

    },
    translateDialog(item, type, scope, source){

      var vue = this
      vue.titleDialog = "Aggiungi nuova regola"
      vue.formToSubmit = []

      _.forEach(this.getLanguagesList, function (lang){

        let text = _.find(item.detail, {"lang": lang.abbr})

        let object = {
          lang: lang.abbr,
          text: (text) ? text.text : ''
        }

        vue.formToSubmit.push(object)

      });

      vue.source = source
      vue.typeRule = type
      vue.scope = scope
      vue.dialog = true;
    },

    async submitRule(){

      var vue = this;
      await this.setPreload(true);

      var payload = {
        "typeRule": this.typeRule,
        "source": this.source,
        "scope": this.scope,
        "rule": this.formToSubmit
      }

      await this.updateRules(payload)
          .then((res) => {

            this.snackbar.color = "success";
            this.snackbar.text = "Regola aggiornata con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            setTimeout(function(){

              vue.fetchSports({'language': vue.getLanguage});
              if(vue.sportSearched != ''){
                vue.fetchCategories({'language': vue.getLanguage, 'paramKey':vue.sportSearched});
              }

              if(vue.categorySearched != ''){
                vue.fetchTournaments({'language': vue.getLanguage, 'paramKey':vue.categorySearched});
              }

            }, 500);


          }).catch((err)=>{

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });

      this.dialogConfirm = false
      await this.fetchSkins()
      await this.setPreload(false)

    },


   /* changeStatus(item, type, scope, source){
      var vue = this

      vue.titleDialog = "Gestisci status"
      if(item.active){
        vue.changeStatus = "Attivo >> Disattivato"
      }else{
        vue.changeStatus = "Disattivato >> Attivo"
      }

      vue.formToSubmit = {'active': !item.active}

      vue.typeRule = type
      vue.scope = scope
      vue.source = source

      vue.dialogConfirm = true

    },*/
    async changeStatusDisable(item,scope,event) {
      //console.log("EVENT",event)
      var platformUsed = this.getPlatformId
      var ruleManager = _.find(this.getRulesLives, (el) => {
        //return el.type=="disable" && el.scope=="sport" && el.status==true && el.id==id
        return el.id == +item.id && el.scope == scope && el.platform == 0
      })

      if (platformUsed == 0) {

        if (ruleManager) {
          /*console.log(platformUsed,ruleManager)
          return*/
          await this.updateRulesLive({
            operation: 'delete',
            id: +item.id,
            type: 'disable',
            scope: scope,
            platform: this.getPlatformId
          })
          event.target.classList.remove('disabled');
          event.target.classList.add('active');



        } else {
          await this.updateRulesLive({
            operation: 'create',
            id: +item.id,
            type: 'disable',
            scope: scope,
            platform: this.getPlatformId
          })
          event.target.classList.remove('active');
          event.target.classList.add('disabled');
        }


      } else {
        var ruleSkin = _.find(this.getRulesLives, (el) => {
          //return el.type=="disable" && el.scope=="sport" && el.status==true && el.id==id
          return el.id == +item.id && el.platform == this.getPlatformId && el.scope == scope
        })
        if (ruleSkin && ruleSkin.type == 'disable') {
          await this.updateRulesLive({
            operation: 'update',
            id: +item.id,
            type: 'active',
            scope: scope,
            platform: this.getPlatformId
          })
          event.target.classList.remove('disabled');
          event.target.classList.add('active');
        }
        if (ruleSkin && ruleSkin.type == 'active') {
          await this.updateRulesLive({
            operation: 'update',
            id: +item.id,
            type: 'disable',
            scope: scope,
            platform: this.getPlatformId
          })
          event.target.classList.remove('active');
          event.target.classList.add('disabled');
        }
        if (!ruleSkin && !ruleManager) {
          await this.updateRulesLive({
            operation: 'create',
            id: +item.id,
            type: 'disable',
            scope: scope,
            platform: this.getPlatformId
          })
          event.target.classList.remove('active');
          event.target.classList.add('disabled');
        }else if(!ruleSkin && ruleManager){
          await this.updateRulesLive({
            operation: 'create',
            id: +item.id,
            type: 'active',
            scope: scope,
            platform: this.getPlatformId
          })
          event.target.classList.remove('disabled');
          event.target.classList.add('active');
        }


    }
    return
    //console.log("ruleSkin",ruleSkin)
      /*var ruleSkin = _.find(this.getRulesLives,(el)=>{
        //return el.type=="disable" && el.scope=="sport" && el.status==true && el.id==id
        return  el.id==+item.id && el.type=="disable" && el.platform == this.getPlatformId
      })

      console.log("ID",this.getPlatformId)
      if(rule){


          await this.updateRulesLive({operation:'delete',id:+item.id,type:'disable',scope:scope,platform: this.getPlatformId})
        event.target.classList.remove('disabled');
        event.target.classList.add('active');




      }else{
        await this.updateRulesLive({operation:'create',id:+item.id,type:'disable',scope:scope,platform: this.getPlatformId})
        event.target.classList.remove('active');
        event.target.classList.add('disabled');
      }*/
    },
    async changeStatusFavourite(item,event){
      var status = this.checkFavourite(item.source)
      //console.log("tournament",tournament)

      var payload = {status: status,type: 'favourite',platform:this.getPlatformId,scope:"tournament",source:item.id};
      var response = await this.updateRulesFavourite(payload)


      event.target.classList.toggle('active');
      event.target.classList.toggle('disabled');


    },

    publishDialog(scope, source){
      var vue = this

      vue.titleDialog = "Pubblica elemento"
      vue.scope = scope
      vue.source = source
      vue.formToSubmit = []

      vue.dialogPub = true
    },

    async publishAll(scope, source){

      var vue = this;
      vue.source = source
      vue.scope = scope
      vue.statusToPub = true

      await this.confirmPublish()
    },

    async confirmPublish(){
      var vue = this;

      var payload = {
        "source": vue.source,
        "scope": vue.scope,
        "active": vue.statusToPub
      }

      await this.setPreload(true);

      await this.publishElement(payload)
          .then((res) => {
            console.log('Tutto ok')

            this.snackbar.color = "success";
            this.snackbar.text = "Regola aggiornata con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            this.fetchNotPublished();

            setTimeout(function(){

              vue.fetchSports({'language': vue.getLanguage});
              if(vue.sportSearched != ''){
                vue.fetchCategories({'language': vue.getLanguage, 'paramKey':vue.sportSearched});
              }

              if(vue.categorySearched != ''){
                vue.fetchTournaments({'language': vue.getLanguage, 'paramKey':vue.categorySearched});
              }

              vue.dialogPub = false

            }, 500);


          }).catch((err)=>{

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });

      await this.setPreload(false);
    },


    async changeOrder(item, type, scope, source){
      var vue = this;

      var payload = {
        "typeRule": type,
        "source": source,
        "scope": scope,
        "rule": {
          "order": item.order
        }
      }

      await this.setPreload(true);

      await this.updateRules(payload)
          .then((res) => {
            console.log('Tutto ok')

            this.fetchSkins()
            this.snackbar.color = "success";
            this.snackbar.text = "Regola aggiornata con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            setTimeout(function(){

              vue.fetchSports({'language': vue.getLanguage});
              if(vue.sportSearched != ''){
                vue.fetchCategories({'language': vue.getLanguage, 'paramKey':vue.sportSearched});
              }

              if(vue.categorySearched != ''){
                vue.fetchTournaments({'language': vue.getLanguage, 'paramKey':vue.categorySearched});
              }

              vue.dialogPub = false

            }, 500);


          }).catch((err)=>{

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });

      await this.setPreload(false);
    },


  },
}
</script>

<style scoped>

</style>
