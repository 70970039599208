<template xmlns="http://www.w3.org/1999/html">
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md12 class="match-list-card">
          <v-card class="tournament-card-preview" elevation="1">
            <p>
              <v-text-field
                  v-model="search_messages"
                  append-icon="mdi-magnify"
                  label="Cerca"
                  single-line
                  hide-details
              ></v-text-field>
            </p>
            <p v-if="selected.length>0">
              <v-card-actions class="justify-start">

                <v-btn color="primary" @click.prevent="followfeed">Esegui</v-btn>
              </v-card-actions>
            </p>
            <v-data-table
                :search="search_messages"
                :headers="headers"
                :items="getHistoryDetail"
                :items-per-page="25"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                class="elevation-1"
                show-select
                v-model="selected"
            >
              <template v-slot:item.feed="props">
                <v-edit-dialog
                    large
                    style="width: 1100px"
                >
                  <span v-if="props.item.odds.length >0">*</span> Visualizza i dettagli del messaggio
                  <template v-slot:input>
                    <p>
                      <code>{{props.item._attributes}}</code> - <code>{{props.item.sport_event_status}}</code>
                    </p>

                    <p>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Cerca"
                        single-line
                        hide-details
                    ></v-text-field>
                    </p>

                    <h3>Tabella Odds</h3>
                    <v-data-table
                        :headers="headers_odds"
                        :items="props.item.odds"
                        :items-per-page="10"
                        :search="search"
                        class="elevation-1"
                    >
                    </v-data-table>


                  </template>
                </v-edit-dialog>
              </template>

            </v-data-table>
          </v-card>

        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import moment from 'moment';

export default {
  name: "History",
  data() {
    return {
      selected: [],
      search: '',
      search_messages: '',
      sortBy: 'data',
      sortDesc: true,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Feed', value: 'feed' },
        { text: 'Data', value: 'data' },
        { text: 'Tipo Messaggio', value: 'type' },
      ],
      headers_odds: [
        { text: 'market_id', value: 'market_id' },
        { text: 'specifiers', value: 'specifiers' },
        { text: 'market_status', value: 'market_status' },
        { text: 'outcome_id', value: 'outcome_id' },
        { text: 'outcome_value', value: 'outcome_value' },
        { text: 'active', value: 'active' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      'getLanguage': 'profile/getLanguage',
      'getToken': "profile/getToken",
      'getHistoryDetail': 'utils/getHistoryDetail',
    }),

  },
  async mounted() {

    await this.setPreload(true);
    await this.showHistory(this.$route.params.id)
    await this.setPreload(false);

  },
  methods: {
    ...mapActions({
      'setPreload': 'utils/setPreload',
      'setHistoryDetail': 'utils/setHistoryDetail',
      'setHistoryDetailDialog': 'utils/setHistoryDetailDialog',
      'fetchHistory': 'utils/fetchHistory',
      'followOddsSingle':'event/followOddsSingle'
    }),
    ...mapMutations({
      'setLanguage': 'profile/setLanguage',
    }),
    async followfeed() {
      var vue = this;
      await this.setPreload(true);
      this.selected = _.orderBy(this.selected,['data'],['asc'])
      for await (let sel of this.selected){

        await this.followOddsSingle({'_id':sel.id,'type':sel.type})
      }
      await this.setPreload(false);

      /*await this.followOddsEvent({'paramKey':source});
      await this.resetEventsPreview();
      await _.forEach(vue.getActiveTournaments, function (item) {
        vue.getEventsFromTournament(item)
      })*/
    },
    async showHistory(eventId) {
      await this.setPreload(true);
      await this.fetchHistory({'event': eventId});
      await this.setHistoryDetailDialog(true)
      await this.setPreload(false);
    },
  },
}
</script>

<style scoped>

</style>
