import { render, staticRenderFns } from "./OutcomeOrder.vue?vue&type=template&id=f06515e0&scoped=true"
import script from "./OutcomeOrder.vue?vue&type=script&lang=js"
export * from "./OutcomeOrder.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f06515e0",
  null
  
)

export default component.exports