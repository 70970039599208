<template xmlns="http://www.w3.org/1999/html">
  <div id="odds-index">
    <v-container fluid>
      <v-layout row>
        <v-flex md2 class="height-scroll">

          <!-- Treeview Component -->
          <Treeview></Treeview>
          <!-- Treeview Component -->

        </v-flex>
        <v-flex md10 class="match-list-card height-scroll">

          <!-- Preview Tournament Component -->
          <previewTournamentEvents></previewTournamentEvents>
          <!-- Preview Tournament Component -->

        </v-flex>
      </v-layout>
    </v-container>

    <v-row justify="center" v-if="getMatchDetailDialog != false">
      <v-dialog v-model="getMatchDetailDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark color="primary" dense>
            <v-btn icon dark
                   @click="setMatchDetailDialog(false); groupId=0; removeActive(matchDetail.events.id, matchDetail.events.type)">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-toolbar-title v-if="matchDetail.events.type == 'match'">
              {{ matchDetail.events.competitors.home.name }} - {{ matchDetail.events.competitors.away.name
              }}
            </v-toolbar-title>
            <v-toolbar-title v-else-if="matchDetail.events.type == 'antepost'">
              {{ matchDetail.tournament.name }}
            </v-toolbar-title>
            <v-toolbar-title v-else>
              {{ matchDetail.events.competitors.home.name }} - {{ matchDetail.events.competitors.away.name
              }}
            </v-toolbar-title>
            <router-link target="_blank" :to="`/history/${matchDetail.events.id}`" class="link-toolbar">
              <v-icon class="icon-action" title="Visualizza storico Aggiornamenti">timeline</v-icon>
            </router-link>

            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text
                     @click="setMatchDetailDialog(false); groupId=0; removeActive(matchDetail.events.id, matchDetail.events.type)">
                Salva e Chiudi
              </v-btn>
            </v-toolbar-items>

          </v-toolbar>

          <v-container class="container--fluid">
            <v-row>
              <v-col cols="6">
                <p class="match-desc">
                  <template v-if="matchDetail.events.active && matchDetail.events.type == 'antepost'">
                    <v-btn color="red" dark x-small
                           @click="updateStatus(matchDetail.events.id, !matchDetail.events.active)">
                      DISATTIVA EVENTO PER TUTTI
                    </v-btn>
                    <br/>
                  </template>

                  <template
                          v-else-if="!matchDetail.events.active && matchDetail.events.type == 'antepost'">
                    <v-btn color="green" dark x-small
                           @click="updateStatus(matchDetail.events.id, !matchDetail.events.active)">
                      ABILITA EVENTO PER TUTTI
                    </v-btn>
                    <br/>
                  </template>

                  <small>
                    {{ matchDetail.sport.name }}/{{ matchDetail.category.name }}/{{
                    matchDetail.tournament.name }}
                  </small>
                  <br/>
                  <span class="match-title" v-if="matchDetail.events.type == 'match'">
                    {{matchDetail.events.competitors.home.name }} - {{ matchDetail.events.competitors.away.name }}</span>
                  <span class="match-title" v-else>
                    Antepost {{matchDetail.tournament.name}}
                  </span>
                  <small>
                    <strong> [{{ matchDetail.events.id }}]</strong>
                    <v-card elevation="0" class="d-inline-flex ">
                      <v-switch color="red darken-3 " class="ma-0 ml-1 pa-0"
                                v-model="eventsDisables" :value="matchDetail.events.id" dense
                                hide-details
                                @change="changeStatusEvent('event',matchDetail.events.id,'')"></v-switch>
                      <v-btn x-small class="update-btn" @click="checkIfEventIsEnabled()">
                        <v-icon small color="blue">mdi-refresh</v-icon>
                      </v-btn>
                    </v-card>

                    <br/>

                    <span v-if="matchDetail.events.scheduled != null">
                      <v-icon class="small-icon">alarm</v-icon>
                      {{ matchDetail.events.scheduled |  moment("DD/MM/YYYY HH:mm") }}
                    </span>

                    <br/>


                  </small>
                </p>
              </v-col>
              <v-col cols="4">
                <p>Imposta un'unica legatura per tutti i Market di questo evento</p>
                <v-text-field label="Legatura di Default" placeholder="ad es. 1-30" outlined dense
                              append-outer-icon="mdi-content-save"
                              @click:append-outer="saveMassiveMultiplicity(matchDetail.sport,matchDetail.category, matchDetail.tournament, matchDetail.events.id, matchDetail.events.description)"
                              v-model="massiveMultiplicity"
                ></v-text-field>
              </v-col>
              <!--              <v-col cols="2">-->
              <!--                <v-checkbox v-model="showVoid" label="Mostra Market senza quote" class="checkbox-filters"></v-checkbox>-->
              <!--              </v-col>-->
            </v-row>

            <v-card v-if="matchDetail.events.type == 'match'">
              <v-row>
                <v-col cols="12">
                  <v-card flat class="pl-5 pr-5 pt-3 pb-3 groups-list" color="primary">
                    <v-btn class="mr-5" depressed color="secondary"
                           v-for="(group,i) in matchDetail.settings" :key="i"
                           :class="(groupId==i) ? 'active':''"
                           @click="showDetailMatch(matchDetail.events.id, matchDetail.events.type,matchDetail.sport.id,matchDetail.tournament.id, 1,i)">
                      {{ getNameTranslated(group.name) }}
                    </v-btn>
                  </v-card>
                </v-col>

              </v-row>
              <v-row>
                <v-col cols="12" v-for="(group,j) in matchDetail.events.odds" :key="j">
                  <v-card flat>
                    <div class="d-flex-row">
                      <div class="col-md-12 text-right">
                        <small>Market disponibili:
                          {{getAvailableOddsNumber(group.odds).true}}</small>
                        <!--                        <span>{{getAvailableOddsNumber(group.odds)}}</span>-->
                      </div>
                      <div
                              :class="(matchDetail.events.type != 'match' && oddsMarket.odds.length >= 4)? 'col-md-12': 'col-md-3'"
                              v-for="(oddsMarket,index) in group.odds" :key="index"
                              v-if="oddsMarket.showMarket || showVoid">
                        <div class="box-market">
                          <div class="box-header">
                            <div class="market-name" v-if="debug">[uID: {{
                              oddsMarket.unique_id }}] {{ oddsMarket.name_market }}
                              <span>
                                <v-switch color="red darken-3 " class="ma-0 ml-1 pa-0" v-model="marketsDisables"
                                          :value="oddsMarket.unique_id.toString()" dense hide-details
                                          @change="changeStatusEvent('market',matchDetail.events.id,oddsMarket.unique_id)"></v-switch>
                              </span>
                            </div>
                            <div class="market-name" v-else>{{ oddsMarket.name_market }}
                            </div>
                            <div class="key-market">
                              <span v-if="oddsMarket.key_percent" :title="oddsMarket.key_percent"
                                    class="key-input-detail">

<!--                                <template v-if="checkClosed(oddsMarket.odds)">-->
                                <!--                                  K<input :readonly="oddsMarket.key_locked" v-model.number="oddsMarket.key_percent_fixed" type="number" @change="checkNewKey(j, oddsMarket.id_market )"/>-->
                                <!--                                 <span @click="changeLockKey(j, oddsMarket.id_market)" class="cursor-pointer">-->
                                <!--                                   <v-icon v-if="oddsMarket.key_locked">lock</v-icon>-->
                                <!--                                   <v-icon v-else>lock_open</v-icon>-->
                                <!--                                 </span>-->
                                <!--                                </template>-->
                                <!--                                 <template v-else>-->
                                <!--                                  K<input :readonly="true" type="number"/>-->
                                <!--                                 <span class="cursor-pointer">-->
                                <!--                                   <v-icon>lock</v-icon>-->
                                <!--                                 </span>-->
                                <!--                                </template>-->


                                <template>
                                  K
                                  <input :readonly="oddsMarket.key_locked" v-model.number="oddsMarket.key_percent_fixed"
                                         type="number" @change="checkNewKey(j, oddsMarket.unique_id )"/>
                                 <span @click="changeLockKey(j, oddsMarket.unique_id)" class="cursor-pointer">
                                   <v-icon v-if="oddsMarket.key_locked">lock</v-icon>
                                   <v-icon v-else>lock_open</v-icon>
                                  </span>
                                </template>
                                <!--                                 <template v-else>-->
                                <!--                                  K<input :readonly="true" type="number"/>-->
                                <!--                                 <span class="cursor-pointer">-->
                                <!--                                   <v-icon>lock</v-icon>-->
                                <!--                                 </span>-->
                                <!--                                </template>-->
                                <span
                                        @click="freezeOddsRule(matchDetail.sport,matchDetail.category, matchDetail.tournament, matchDetail.events.id, oddsMarket.id_market, oddsMarket.specifiers, oddsMarket, matchDetail.events.description)"
                                        title="Congela le quote" style="float: right" class="cursor-pointer">
                                  <v-icon :class="(oddsMarket.freeze) ? 'freeze' : ''">ac_unit</v-icon>
                                </span>
                                <span
                                        @click="saveRuleKey(matchDetail.sport,matchDetail.category, matchDetail.tournament, matchDetail.events.id, oddsMarket.id_market, oddsMarket.specifiers, oddsMarket.key_percent_fixed, matchDetail.events.description)"
                                        title="Salva regola sulla Key" style="float: right" class="cursor-pointer">
                                  <v-icon>save</v-icon>
                                </span>
                              </span>
                            </div>
                            <div class="multi-input">
                              <span class="multi-input-detail">
                                M<input v-model="oddsMarket.multiplicity" type="text"/>
                                 <span
                                         @click="saveMultiplicity(matchDetail.sport,matchDetail.category, matchDetail.tournament, matchDetail.events.id, oddsMarket.id_market, oddsMarket.specifiers, oddsMarket.multiplicity, matchDetail.events.description)">
                                   <v-icon class="cursor-pointer" title="Salva Legatura">save</v-icon>
                                 </span>
                              </span>
                            </div>
                          </div>
                          <div class="box-body d-flex-row">
                              <span v-for="outcome in oddsMarket.odds" :key="outcome.id_outcome"
                                    :class="[(matchDetail.events.type !='match' && oddsMarket.odds.length >= 4)? 'odd-button-antepost': 'odd-button', (outcome.type_change != undefined) ? outcome.type_change:'equal']">

                                <span v-if="debug" class="sign" :title="outcome.id_outcome">
                                  [{{ outcome.id_outcome | truncate(5,'...') }}] {{ (outcome.outcome_name) ? outcome.outcome_name : outcome.outcome_alias | truncate(8,'..')}}
                                </span>
                                <span v-else class="sign" :title="outcome.id_outcome">
                                  {{ (outcome.outcome_name) ? outcome.outcome_name : outcome.outcome_alias | truncate(8,'..')}}
                                </span>

                                <span style="display: flex">
                                  <span>
                                     <v-switch color="red darken-3 " class="ma-0 ml-1 pa-0" v-model="outcomesDisables"
                                               :value="outcome.unique_id.toString()" dense hide-details
                                               @change="changeStatusEvent('outcome',matchDetail.events.id,outcome.unique_id)"
                                     ></v-switch>

                                  </span>
                                  <v-icon class="arrow arrow-down"
                                          v-if="outcome.type_change == 'down'">arrow_drop_down</v-icon>
                                  <v-icon class="arrow arrow-up"
                                          v-if="outcome.type_change == 'up'">arrow_drop_up</v-icon>
                                  <input v-if="outcome.active && outcome.outcome_odd_value != 'lock'"
                                         v-model.number="outcome.outcome_odd_value" type="number"
                                         step="0.01"
                                         @change="changeOddValue(j, oddsMarket.id_market, outcome.id_outcome)"/>
                                  <span class="lock" v-else>
                                    <v-icon>lock</v-icon>
                                  </span>
                                </span>
                              </span>

                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <v-card v-else-if="matchDetail.events.type == 'antepost'">
              <v-row>
                <v-col cols="12" v-for="(oddsMarket,j) in matchDetail.events.odds" :key="j">
                  <v-card flat>
                    <div class="d-flex-row">
                      <div class="col-md-12">
                        <div class="box-market">
                          <div class="box-header">
                            <div class="market-name" v-if="debug">[uID: {{
                              oddsMarket.unique_id }}] {{ oddsMarket.name_market }}
                              <span>
                                     <v-switch color="red darken-3 " class="ma-0 ml-1 pa-0" v-model="marketsDisables"
                                               :value="oddsMarket.unique_id" dense hide-details
                                               @change="changeStatusEvent('market',matchDetail.events.id.toString(),oddsMarket.unique_id)"></v-switch>

                                  </span>
                            </div>
                            <div class="market-name" v-else>{{ oddsMarket.name_market }}
                            </div>
                            <div class="key-market">
                              <span v-if="oddsMarket.key_percent" :title="oddsMarket.key_percent"
                                    class="key-input-detail">
                                K<input :readonly="oddsMarket.key_locked" v-model.number="oddsMarket.key_percent_fixed"
                                        type="number" @change="checkNewKey(j, oddsMarket.unique_id )"/>
                                 <span @click="changeLockKey(j, oddsMarket.unique_id)" class="cursor-pointer">
                                   <v-icon v-if="oddsMarket.key_locked">lock</v-icon>
                                   <v-icon v-else>lock_open</v-icon>
                                 </span>
                                <span
                                        @click="freezeOddsRule(matchDetail.sport,matchDetail.category, matchDetail.tournament, matchDetail.events.id, oddsMarket.id_market, oddsMarket.specifiers, oddsMarket)"
                                        title="Congela le quote" style="float: right" class="cursor-pointer">
                                  <v-icon :class="(oddsMarket.freeze) ? 'freeze' : ''">ac_unit</v-icon>
                                </span>
                                <span
                                        @click="saveRuleKey(matchDetail.sport,matchDetail.category, matchDetail.tournament, matchDetail.events.id, oddsMarket.id_market, oddsMarket.specifiers, oddsMarket.key_percent_fixed)"
                                        title="Salva regola sulla Key" style="float: right" class="cursor-pointer">
                                  <v-icon>save</v-icon>
                                </span>
                              </span>
                            </div>
                            <div class="multi-input">
                              <span class="multi-input-detail">
                                M<input v-model="oddsMarket.multiplicity" type="text"/>
                                 <span
                                         @click="saveMultiplicity(matchDetail.sport,matchDetail.category, matchDetail.tournament, matchDetail.events.id, oddsMarket.id_market, oddsMarket.specifiers, oddsMarket.multiplicity)">
                                   <v-icon class="cursor-pointer" title="Salva Legatura">save</v-icon>
                                 </span>
                              </span>
                            </div>
                          </div>
                          <div class="box-body d-flex-row">
                              <span v-for="outcome in oddsMarket.odds" :key="outcome.id_outcome"
                                    :class="[(matchDetail.events.type !='match' && oddsMarket.odds.length >= 4)? 'odd-button-antepost': 'odd-button', (outcome.type_change != undefined) ? outcome.type_change:'equal']">

                                <span v-if="debug" class="sign" :title="outcome.id_outcome">
                                  [{{ outcome.id_outcome | truncate(5,'...') }}] {{ (outcome.outcome_name != undefined )? outcome.outcome_name : outcome.outcome_alias }}
                                </span>
                                <span v-else class="sign" :title="outcome.id_outcome">
                                  {{ (outcome.outcome_name != undefined ) ? outcome.outcome_name : outcome.outcome_alias }}
                                </span>

                                <span style="display: flex">
                                  <span>
                                     <v-switch color="red darken-3 " class="ma-0 ml-1 pa-0" v-model="outcomesDisables"
                                               :value="outcome.unique_id" dense hide-details
                                               @change="changeStatusEvent('outcome',matchDetail.events.id,outcome.unique_id)"
                                     ></v-switch>

                                  </span>
                                  <v-icon class="arrow arrow-down"
                                          v-if="outcome.type_change == 'down'">arrow_drop_down</v-icon>
                                  <v-icon class="arrow arrow-up"
                                          v-if="outcome.type_change == 'up'">arrow_drop_up</v-icon>
                                  <input v-if="outcome.active" v-model.number="outcome.outcome_odd_value" type="number"
                                         step="0.01"
                                         @change="changeOddValue(j, oddsMarket.id_market, outcome.id_outcome)"/>
                                  <span class="lock" v-else>
                                    <v-icon>lock</v-icon>
                                  </span>
                                </span>
                              </span>

                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-container>

        </v-card>
      </v-dialog>
    </v-row>

    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'"
                :timeout="snackbar.timeout" :vertical="snackbar.mode === 'vertical'">
      {{ snackbar.text }}
      <v-btn dark text @click="snackbar.snackbar = false">
        Chiudi
      </v-btn>
    </v-snackbar>


  </div>
</template>

<script>
  import {mapActions, mapGetters, mapMutations} from 'vuex';
  import Treeview from "@/components/odds/treeview";
  import previewTournamentEvents from "@/components/odds/previewTournamentEvents";
  import _ from "lodash";

  export default {
    name: "Odds",
    components: {previewTournamentEvents, Treeview},
    data() {
      return {
        debug: true,
        tab: null,
        showVoid: false,
        massiveMultiplicity: "",
        groupId: 0,
        eventsDisables: [],
        outcomesDisables: [],
        marketsDisables: [],
        snackbar: {
          snackbar: false,
          color: '',
          mode: '',
          timeout: 6000,
          text: '',
        },
      }
    },
    computed: {
      ...mapGetters({
        'getLanguage': 'profile/getLanguage',
        'getToken': "profile/getToken",
        'getPlatformId': "rules/getPlatformId",
        'matchDetail': 'utils/getMatchDetail',
        'getMatchDetailDialog': 'utils/getMatchDetailDialog',
        'getManager': 'skins/getManager',
        'getList': 'skins/getList',

      }),

    },

    async mounted() {

      await this.setPreload(true);


      //var rules = _.find(listrules.rules,{scope:"event",type:"disable",source:this.matchDetail.source})

      await this.$store.commit('tournament/resetEventsPreview')
      await this.$store.commit('utils/resetMatchDetail')
      await this.$store.commit('tree/setActiveTournaments', [])
      //await this.filterMatch()

      this.$root.$on('filterMatchHook', () => {

        this.filterMatch()
      })

      // await this.filterMatch()
      await this.setPreload(false);

    },
    methods: {
      ...mapActions({
        'fetchEventsPreview': 'tournament/fetchEventsPreview',
        'setMatchDetailDialog': 'utils/setMatchDetailDialog',
        'setPreload': 'utils/setPreload',
        'resetMatchDetail': 'utils/resetMatchDetail',
        'updateRules': 'skins/updateRules',
        'updateRulesDisable': 'skins/updateRulesDisable',
        'fetchSkins': 'skins/fetchSkins',
        'getOddsEvent': 'utils/getOddsEvent',
        'removeTournament': 'tree/removeTournament',
        'deleteRules': 'skins/deleteRules',
        'disableEvent': 'event/disableEvent',
        'updateStatusEvent': 'event/updateStatusEvent',
        'cacheRules': 'cache/cacheRules',
        'checkIfEventIsEnabledAction': 'skins/checkIfEventIsEnabled'
      }),
      ...mapMutations({
        'setLanguage': 'profile/setLanguage',
      }),
      filterMatch() {

        this.eventsDisables = []
        this.marketsDisables = []
        this.outcomesDisables = []

        var cycles = []
        if (this.getPlatformId == 0) {
          cycles.push(this.getPlatformId)
        } else {
          cycles.push(0)
          cycles.push(this.getPlatformId)
        }

        _.forEach(cycles, cycle => {
          var listrules = _.find(this.getList, {platform_id: cycle})
          var detailRule = _.find(listrules.rules, {source: this.matchDetail.events.id.toString()})

          if (detailRule) {
            _.forEach(detailRule.detail, (odd) => {
              switch (odd.scope) {
                case "event":
                  if (odd.status) {
                    if (!this.eventsDisables.includes(this.matchDetail.events.id)) {
                      this.eventsDisables.push(this.matchDetail.events.id)
                    }
                  } else {
                    let index = _.findIndex(this.eventsDisables, function (o) {
                      return o == this.matchDetail.events.id;
                    });
                    if (index) {
                      this.eventsDisables.splice(index, 1);
                    }
                  }
                  break;
                case "market":
                  if (odd.status) {
                    if (!this.marketsDisables.includes(odd.id)) {
                      this.marketsDisables.push(odd.id)
                    }
                  } else {
                    let index = _.findIndex(this.marketsDisables, function (o) {
                      return o == odd.id;
                    });
                    if (index) {
                      this.marketsDisables.splice(index, 1);
                    }
                  }
                  break;
                case "outcome":
                  if (odd.status) {
                    if (!this.outcomesDisables.includes(odd.id)) {
                      this.outcomesDisables.push(odd.id)
                    }
                  } else {
                    let index = _.findIndex(this.outcomesDisables, function (o) {
                      return o == odd.id;
                    });
                    if (index) {
                      this.outcomesDisables.splice(index, 1);
                    }
                  }
                  break;

              }
            })
          }
        })

        //console.log("match",this.matchDetail.events.source)

      },
      async changeStatusEvent(type, source, id) {
        try {
          //console.log("source",source)
          var payload
          switch (type) {
            case "event":
              if (_.includes(this.eventsDisables, source)) {


                payload = {
                  "method": "event",
                  "type": "disable",
                  "source": source,
                  "scope": "event",
                  "platform": this.getPlatformId,
                  "detail": JSON.stringify([{
                    "scope": "event",
                    "id": id,
                    "status": true
                  }])
                }


              } else {
                payload = {
                  "method": "event",
                  "type": "disable",
                  "source": source,
                  "scope": "event",
                  "platform": this.getPlatformId,
                  "detail": JSON.stringify([{
                    "scope": "event",
                    "id": id,
                    "status": false
                  }])
                }
              }
              break;
            case "market":

              if (_.includes(this.marketsDisables, id)) {
                payload = {
                  "method": "market",
                  "type": "disable",
                  "source": source,
                  "scope": "event",
                  "platform": this.getPlatformId,
                  "detail": JSON.stringify([{
                    "scope": "market",
                    "id": id,
                    "status": true
                  }])
                }
              } else {
                payload = {
                  "method": "market",
                  "type": "disable",
                  "source": source,
                  "scope": "event",
                  "platform": this.getPlatformId,
                  "detail": JSON.stringify([{
                    "scope": "market",
                    "id": id,
                    "status": false
                  }])
                }
              }

              break;
            case "outcome":

              if (_.includes(this.outcomesDisables, id)) {
                payload = {
                  "method": "outcome",
                  "type": "disable",
                  "source": source,
                  "scope": "event",
                  "platform": this.getPlatformId,
                  "detail": JSON.stringify([{
                    "scope": "outcome",
                    "id": id,
                    "status": true
                  }])
                }
              } else {
                payload = {
                  "method": "outcome",
                  "type": "disable",
                  "source": source,
                  "scope": "event",
                  "platform": this.getPlatformId,
                  "detail": JSON.stringify([{
                    "scope": "outcome",
                    "id": id,
                    "status": false
                  }])
                }
              }

              break;
          }

          this.setPreload(true);

          await this.updateRulesDisable(payload)
                  .then(async (res) => {

                    this.setPreload(false);
                    this.snackbar.color = "success";
                    this.snackbar.text = "Regola aggiornata con successo";
                    this.snackbar.snackbar = true;
                    this.snackbar.mode = 'multi-line';
                    this.snackbar.timeout = 5000;
                    await this.cacheRules()
                  }).catch(e => {
                    console.log(e)
                  });


        } catch (err) {
          console.log(err);
        }


        //console.log(item)
      },
      getAvailableOddsNumber(group) {
        return _.countBy(group, 'showMarket')
      },

      checkNewKey(groupId, marketUniqueId) {

        var odds_collection = this.matchDetail.events.odds[groupId]
        var odds = _.find(odds_collection.odds, {'unique_id': marketUniqueId})

        odds.key_percent = odds.key_percent_fixed
        odds.odds_total = odds.odds_total_original * odds.key_percent_original / odds.key_percent_fixed

        _.forEach(odds.odds, function (odd) {

          var new_odd_value = odd.outcome_odd_percent * odds.odds_total / 100;
          odd.outcome_odd_value = new_odd_value.toFixed(2)
          odd.outcome_key_value = 100 / odd.outcome_odd_value

        });

        odds.override = parseFloat(odds.key_percent_fixed) != parseFloat(odds.key_percent_fixed_original);

        console.log('## Change Odds Key')
        console.log(odds)


      },

      changeLockKey(groupId, marketUniqueId) {

        var odds_collection = this.matchDetail.events.odds[groupId]
        var odds = _.find(odds_collection.odds, {'unique_id': marketUniqueId})

        odds.key_locked = !odds.key_locked;

      },

      changeOddValue(groupId, marketId, id_outcome) {
        var odds_collection = this.matchDetail.events.odds[groupId]
        var odds = _.find(odds_collection.odds, {'id_market': marketId})

        console.log('## Change Odds Value')

        if (odds.key_locked) {
          // Se la Key è bloccata
          console.log('## Key locked')

          // Conto le quote attive su cui distrubuire la differenza della key, esclusa quella variata (perciò -1)
          var countActiveOdds = _.countBy(odds.odds, 'active').true - 1;

          // Calcolo la key della quota cambiata
          var odd_changed = _.find(odds.odds, {'id_outcome': id_outcome});
          odd_changed.outcome_key_value = 100 / odd_changed.outcome_odd_value;

          // Reset Key total
          odds.key_percent = 0.00;

          // Somma di tutte le key aggiornata
          _.forEach(odds.odds, function (odd) {
            odds.key_percent += parseFloat(odd.outcome_key_value)
          });

          // Differenza key originale
          var value_diff = (odds.key_percent_original - odds.key_percent) / countActiveOdds;
          odds.key_percent += (odds.key_percent_original - odds.key_percent)
          odds.key_percent_fixed = odds.key_percent.toFixed(0)

          // Resetto Odds total
          odds.odds_total = 0.00

          // Aggiungo la differenza splittata per numero di quote attive e mi calcolo il nuovo valore della quota
          _.forEach(odds.odds, function (odd) {

            if (odd.id_outcome != id_outcome) {
              odd.outcome_key_value += parseFloat(value_diff)
              odd.outcome_odd_value = (100 / odd.outcome_key_value).toFixed(2);
            }

            // Ricalcolo quota totale
            odds.odds_total += parseFloat(odd.outcome_odd_value);

          });


          // Ricalcolo partizionamento quote su 100%
          _.forEach(odds.odds, function (odd) {
            odd.outcome_odd_percent = (odd.outcome_key_value / odds.odds_total) * 100
            odd.outcome_odd_percent = odd.outcome_odd_percent.toFixed(2)
          });


        } else {
          // Se la key non è bloccata aggiorno la key in base alla nuova quota

          console.log('## Key not locked')

          var key_percent = 0.00

          _.forEach(odds.odds, function (odd) {

            odd.outcome_key_value = 100 / odd.outcome_odd_value
            key_percent += odd.outcome_key_value

          });

          odds.key_percent = key_percent;
          odds.key_percent_fixed = key_percent.toFixed(0)


        }

        console.log(odds)

      },

      async saveMultiplicity(sport, category, tournament, matchSource, marketId, specifier, newMultiplicity, desc) {

        var path = '';
        path = sport.id + '#' + category.id + '#' + tournament.id + '#' + matchSource

        var description = ''
        description = sport.name + ' > ' + category.name + ' > ' + tournament.name + ' > ' + desc

        var market = (specifier != "not_spec" && specifier != "") ? marketId + '#' + specifier : marketId

        var payload = {
          "typeRule": 'multiplicity',
          "source": matchSource,
          "scope": "event#0",
          "rule": {
            "market": market.toString(),
            "multiplicity": newMultiplicity,
            'path': path,
            'description': description
          }
        }

        await this.updateRules(payload)
                .then(async (res) => {

                  this.snackbar.color = "success";
                  this.snackbar.text = "Molteplicità aggiornata correttamente";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;

                  await this.cacheRules()

                }).catch((err) => {


                  this.snackbar.color = "error";
                  this.snackbar.text = "C'è stato un errore nel salvataggio";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;

                  console.log(err)
                });

        await this.fetchSkins()
        await this.setPreload(false)

      },

      async saveMassiveMultiplicity(sport, category, tournament, matchSource, desc) {

        var path = '';
        path = sport.id + '#' + category.id + '#' + tournament.id + '#' + matchSource

        var description = ''
        description = sport.name + ' > ' + category.name + ' > ' + tournament.name + ' > ' + desc

        var payload = {
          "typeRule": 'multiplicity',
          "source": matchSource,
          "scope": "event#0",
          "rule": {
            "market": "all",
            "multiplicity": this.massiveMultiplicity,
            'path': path,
            'description': description
          }
        }

        await this.updateRules(payload)
                .then(async (res) => {

                  this.massiveMultiplicity = ''

                  this.snackbar.color = "success";
                  this.snackbar.text = "Molteplicità aggiornata correttamente";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;
                  await this.cacheRules()

                }).catch((err) => {

                  this.snackbar.color = "error";
                  this.snackbar.text = "C'è stato un errore nel salvataggio";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;
                  console.log(err)
                });

        await this.fetchSkins()
        await this.setPreload(false)

      },

      async saveRuleKey(sport, category, tournament, matchSource, marketId, specifier, newKey, desc) {

        var path = '';
        path = sport.id + '#' + category.id + '#' + tournament.id + '#' + matchSource

        var description = ''
        description = sport.name + ' > ' + category.name + ' > ' + tournament.name + ' > ' + desc

        var market = (specifier != "not_spec" && specifier != "") ? marketId + '#' + specifier : marketId

        var payload = {
          "typeRule": 'key',
          "source": matchSource,
          "scope": "event#0",
          "rule": {
            "market": market.toString(),
            "key": newKey.toString(),
            'path': path,
            'description': description
          }
        }

        await this.updateRules(payload)
                .then(async (res) => {

                  this.snackbar.color = "success";
                  this.snackbar.text = "Key aggiornata correttamente";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;
                  await this.cacheRules()

                }).catch((err) => {

                  this.snackbar.color = "error";
                  this.snackbar.text = "C'è stato un errore nel salvataggio";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;
                  console.log(err)
                });

        await this.fetchSkins()
        await this.setPreload(false)

      },

      async freezeOddsRule(sport, category, tournament, matchSource, marketId, specifier, odds, desc) {
        if (odds.freeze) {
          // deleteRule

          var market = (specifier != "not_spec" && specifier != "") ? marketId + '#' + specifier : marketId

          var payload = {
            "typeRule": 'freeze',
            "source": matchSource,
            "scope": 'event#0',
            "rule": {"detail": {"market": market.toString()}}
          }

          await this.deleteRules(payload)
                  .then(async (res) => {

                    odds.freeze = false
                    this.snackbar.color = "success";
                    this.snackbar.text = "Regola rimossa correttamente";
                    this.snackbar.snackbar = true;
                    this.snackbar.mode = 'multi-line';
                    this.snackbar.timeout = 5000;

                    await this.cacheRules()

                  }).catch((err) => {

                    this.snackbar.color = "error";
                    this.snackbar.text = "C'è stato un errore nel salvataggio";
                    this.snackbar.snackbar = true;
                    this.snackbar.mode = 'multi-line';
                    this.snackbar.timeout = 5000;

                    console.log(err)
                  });

          await this.fetchSkins()
          await this.setPreload(false)

        } else {


          var path = '';
          path = sport.id + '#' + category.id + '#' + tournament.id + '#' + matchSource

          var description = ''
          description = sport.name + ' > ' + category.name + ' > ' + tournament.name + ' > ' + desc

          var market = (specifier != "not_spec" && specifier != "") ? marketId + '#' + specifier : marketId

          odds.freeze = true

          var payload = {
            "typeRule": 'freeze',
            "source": matchSource,
            "scope": "event#0",
            "rule": {"market": market.toString(), "odds": odds, 'path': path, 'description': description}
          }

          await this.updateRules(payload)
                  .then(async (res) => {

                    this.snackbar.color = "success";
                    this.snackbar.text = "Quote congelate correttamente";
                    this.snackbar.snackbar = true;
                    this.snackbar.mode = 'multi-line';
                    this.snackbar.timeout = 5000;

                    await this.cacheRules()

                  }).catch((err) => {

                    this.snackbar.color = "error";
                    this.snackbar.text = "C'è stato un errore nel salvataggio";
                    this.snackbar.snackbar = true;
                    this.snackbar.mode = 'multi-line';
                    this.snackbar.timeout = 5000;

                    console.log(err)
                  });

          await this.fetchSkins()
          await this.setPreload(false)

        }


      },

      async showDetailMatch(eventId, type, sport, tournament, status, group = 0) {
        this.groupId = group
        await this.setPreload(true);
        await this.getOddsEvent({
          'paramKey': eventId,
          'type': type,
          'sport': sport,
          'tournament': tournament,
          'group': group,
          'status': status
        });
        await this.setMatchDetailDialog({'status': true})
        //await this.filterMatchHook();
        await this.$root.$emit('filterMatchHook')
        await this.setPreload(false);

      },

      getNameTranslated(collection) {
        var name = _.find(collection, {"lang": this.getLanguage})

        if (!name) {
          name = _.find(collection, {"lang": 'en'})
        }

        return name.text;
      },

      getMarketsDisabled(id) {
        return this.marketsDisables.includes(id)
      },

      getOutcomesDisabled(id) {
        return this.outcomesDisables.includes(id)
      },

      async removeActive(id, type) {
        if (type == "antepost") {
          await this.removeTournament(id + '#antepost')
        }
      },

      async updateStatus(id, status) {

        var payload = {
          "id": id,
          "status": status
        }

        await this.updateStatusEvent(payload)
                .then(async (res) => {

                  this.matchDetail.events.active = status
                  this.snackbar.color = "success";
                  this.snackbar.text = "Evento disabilitato correttamente";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;

                  await this.cacheRules()

                }).catch((err) => {

                  this.snackbar.color = "error";
                  this.snackbar.text = "C'è stato un errore nel salvataggio";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;


                });

        await this.setPreload(false)


      },

      checkClosed(odds) {
        var check = _.find(odds, {'outcome_odd_value': 'lock'})
        var check2 = _.find(odds, {'active': false})
        if (check || check2) {
          return false
        } else {
          return true
        }
      },
      async checkIfEventIsEnabled() {

        const sourceEventId = this.matchDetail.events.id;
        
        const payload = {
          platformId: this.getPlatformId,
          sourceEventId: sourceEventId
        }

        const response = await this.checkIfEventIsEnabledAction(payload);

        let isEnabled;

        if (response != null && response.data != null) {
          isEnabled = response.data.isEnabled;
          if (isEnabled === true) {
            this.eventsDisables = this.eventsDisables.filter(eventId => eventId !== sourceEventId)
          } else {
            const elementIndex = this.eventsDisables.findIndex((eventId) => {
              return eventId == sourceEventId
            });

            if (elementIndex == -1) {
              this.eventsDisables.push(sourceEventId)
            }
          }
        }
      }


    },
  }
</script>

<style scoped>

</style>
