<template>
    <div id="entityLimitCrate">
        <v-card outlined elevation-0 class="mt-1">
            <v-card-text class="pt-5">
                <template>
                    <div>
                        <v-container fluid class="pb-0">
                            <v-row align="center">
                                <v-col class="d-flex" cols="12" md="2">
                                    <v-autocomplete
                                            v-model="sport" :items="getItemsSport" item-text="name"
                                            item-value="id"
                                            outlined dense chips small-chips :label="$t('sport')"
                                            @input="getCategoriesFromSport()"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="d-flex" cols="12" md="2">
                                    <v-autocomplete
                                            v-model="category" :items="getItemsCategories" item-text="name"
                                            item-value="id"
                                            outlined dense chips small-chips :label="$t('category')"
                                            @input="getTournamentsFromCategories()" clearable
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="d-flex" cols="12" md="2">
                                    <v-autocomplete
                                            v-model="tournament" :items="getItemsTournaments"
                                            item-text="name" item-value="id"
                                            outlined dense chips small-chips :label="$t('tournament')" clearable
                                            @input="getEventsFromTournament()"
                                    ></v-autocomplete>
                                </v-col>

                                <v-col class="d-flex" cols="12" md="2"
                                       v-if="tournament && tournament != '' && tournament > 0">
                                    <v-btn color="secondary" id="loadEventsBtn" @click="loadEventsForTournament()">
                                        {{$t('loadEvents') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </template>
            </v-card-text>
        </v-card>

        <v-container fluid v-if="getPlatformId > 0">
            <v-layout row>
                <v-flex md12>
                    <v-col cols="4">
                        <v-card outlined elevation-0>
                            <v-row class="title-row">
                                <v-col md="12">
                                    <v-row class="ml-1">
                                        <v-col md="6">
                                            <h2 class="ml-1">{{getPlatformName()}}</h2>
                                        </v-col>


                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-card outlined elevation-0>
                            <v-row class="title-row">

                                <v-col md="6">
                                    <v-select class="text-capitalize" :items="groupType"
                                              :label="$t('risk.userTypeRules')"
                                              dense outlined small-chips v-model="groupTypeSelected"
                                    ></v-select>
                                </v-col>
                                <v-col md="6">
                                    <v-text-field class="text-capitalize like-select"
                                                  v-model="entity_type_selected"
                                                  id="entity_type_selected"
                                                  :label="$t('risk.entityType')" dense outlined readonly disabled
                                                  type="text"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="6">
                                    <v-text-field class="text-capitalize like-select"
                                                  :value="entity_name"
                                                  id="entity_name"
                                                  :label="$t('generic.name')" dense outlined readonly
                                                  type="text"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="6">
                                    <v-text-field class="text-capitalize like-select"
                                                  v-model="entity_id_selected"
                                                  id="entity_id_selected"
                                                  :label="$t('risk.entityId')" dense outlined readonly disabled
                                                  type="text"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="6">
                                    <v-select class="text-capitalize" :items="entity_context" item-text="text"
                                              id="entity_context"
                                              item-value="value" :label="$t('generic.context')" dense
                                              outlined
                                              v-model="entity_context_selected">
                                    </v-select>
                                </v-col>

                                <v-col md="6">
                                    <v-select class="text-capitalize" :items="limits_type" item-text="text"
                                              id="limits_type"
                                              item-value="value" :label="$t('risk.typeOfLimit')" dense
                                              outlined
                                              v-model="limits_type_selected">
                                    </v-select>
                                </v-col>
                                <v-col md="6">

                                    <v-text-field v-if="limits_type_selected == 'percentage'"
                                                  class="text-capitalize like-select"
                                                  v-model="limit_amount"
                                                  id="limit_amount"
                                                  :label="$t('winning')" dense outlined type="number"
                                                  min="0" step="0.01" max="100">
                                    </v-text-field>

                                    <v-text-field v-else class="text-capitalize like-select"
                                                  v-model="limit_amount"
                                                  id="limit_amount"
                                                  :label="$t('winning')" dense outlined type="number"
                                                  min="0" step="0.01">
                                    </v-text-field>

                                </v-col>
                                <v-col md="6">
                                    <v-select class="text-capitalize" :items="limit_action" item-text="text"
                                              id="limit_action"
                                              item-value="value" :label="$t('risk.typeOfAction')" dense
                                              outlined
                                              v-model="limit_action_selected">
                                    </v-select>
                                </v-col>
                                <v-col md="6">
                                    <v-btn block color="primary" class="save_rule_button" @click="saveRule()">
                                        {{$t('generic.save')}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="8">
                        <v-card outlined elevation-0 v-if="showEventsTable">
                            <v-row class="title-row">
                                <v-col md="12">
                                    <v-row class="ml-1">
                                        <v-col md="6">
                                            <h2 class="ml-1">{{$t('events')}}</h2>
                                        </v-col>
                                        <v-col class="d-flex" cols="12" md="6">
                                            <v-select class="text-capitalize" :items="typeOfStatus"
                                                      :label="$t('bets.status')"
                                                      dense outlined small-chips v-model="typeOfStatusSelected"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>

                        <template v-if="getEventsList.length > 0">
                            <v-card outlined elevation-0>
                                <v-row class="title-row">

                                    <v-col cols="12" class="pa-0">
                                        <v-data-table :headers="headers" :items="getEventsList"
                                                      class="elevation-1 head-capitalize" @click:row="handleClick"
                                                      :loading="getPreload" v-model="selectedEventItem"
                                                      disable-pagination
                                                      hide-default-footer :single-select="true"
                                                      item-key="id">

                                            <template v-slot:item.id="{ item }">
                                                <span class="font-weight-bold">{{ item.id }}</span>
                                            </template>

                                            <template v-slot:item.quickbet="{ item }">
                                                <span class="text-uppercase">{{ item.quickbet }}</span>
                                            </template>

                                            <template v-slot:item.scheduled="{ item }">

                                    <span v-if="item.scheduled != null">
                                      <!--{{ new Date(item.scheduled).toLocaleString('it-IT', { timeZone: 'UTC' }) }}-->
                                    {{ new Date(item.scheduled).toLocaleString() }}

                                    </span>

                                            </template>

                                            <template v-slot:item.event="{ item }">
                                                {{getDescription(item)}}
                                            </template>

                                            <template v-slot:item.type="{ item }">
                                                {{$t(getEventType(item.type)) | capitalize}}
                                            </template>

                                            <template v-slot:item.sport="{ item }">
                                                {{ getDetail(item.sport.detail) }}
                                            </template>

                                            <template v-slot:item.category="{ item }">
                                                {{ getDetail(item.category.detail) }}
                                            </template>

                                            <template v-slot:item.tournament="{ item }">
                                                {{ getDetail(item.tournament.detail) }}
                                            </template>
                                        </v-data-table>
                                    </v-col>

                                    <v-col cols="12" class="pa-0">
                                        <div class="text-center pt-3 pb-3">
                                            <v-pagination
                                                    v-model="getOptions.page"
                                                    :length="+(getCounts / getOptions.limit).toFixed(0)"
                                                    :total-visible="20"
                                                    @input="getNewPage()"
                                            ></v-pagination>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </template>
                    </v-col>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import _ from "lodash";
    import EventsList from "@/components/events/eventsList";

    export default {
        name: "EntityLimitsCreate",
        components: {EventsList},
        props: {
            userTarget: {
                type: Object,
                required: false,
                default: null
            }
        },
        data() {
            return {
                sport: 8412615417,
                category: '',
                tournament: '',
                eventId: null,
                groupType: [
                    {text: this.$t('generic.all'), value: 'all'},
                    {text: this.$t('user.agency'), value: 'agency'},
                    {text: this.$t('user.user'), value: 'user'},
                ],
                groupTypeSelected: 'all',
                entity_type: [
                    {text: this.$t('generic.all'), value: 'all'},
                    {text: this.$t('sport'), value: 'sport'},
                    {text: this.$t('category'), value: 'category'},
                    {text: this.$t('tournament'), value: 'tournament'},
                    {text: this.$t('event'), value: 'event'},
                ],
                entity_type_selected: null,
                entity_name: '',
                entity_id_selected: null,
                entity_context: [
                    {text: this.$t('bets.live'), value: 'live'},
                    {text: this.$t('bets.prematch'), value: 'prematch'},
                    {text: this.$t('bets.mixed'), value: 'mixed'},
                ],
                entity_context_selected: 'prematch',
                limits_type: [
                    {text: this.$t('percentage'), value: 'percentage'},
                    {text: this.$t('bets.winning'), value: 'amount'}
                ],
                limits_type_selected: 'amount',
                limit_amount: 1000,
                limit_action: [
                    {text: this.$t('risk.check'), value: 'check'},
                    {text: this.$t('risk.reject'), value: 'reject'}
                ],
                limit_action_selected: 'check',
                selectedEventItem: null,
                headers: [
                    {text: this.$t('generic.id'), value: 'id', width: '3%', sortable: false},
                    {text: this.$t('quickbet'), value: 'quickbet', width: "3%"},
                    {text: this.$t('bets.date'), value: 'scheduled', width: "7%"},
                    {text: this.$t('bets.event'), value: 'event', width: "20%"},
                    {text: this.$t('bets.type'), value: 'type', width: "3%"},
                    {text: this.$t('sport'), value: 'sport', width: "5%", sortable: false},
                    {text: this.$t('category'), value: 'category', width: "5%", sortable: false},
                    {text: this.$t('tournament'), value: 'tournament', width: "8%", sortable: false},
                ],
                typeOfStatus: [
                    // {text: this.$t('generic.selectOption'), value: ""},
                    {text: this.$t('nsy'), value: 1},
                    {text: this.$t('inProgress'), value: 2},
                    // {text: this.$t('finished'), value: 3},
                    // {text: this.$t('cancelled'), value: 4},
                    // {text: this.$t('postponed'), value: 5},
                    // {text: this.$t('interrupted'), value: 6},
                    // {text: this.$t('abandoned'), value: 7},
                    // {text: this.$t('coverageLost'), value: 8},
                    // {text: this.$t('aboutToStart'), value: 9},
                ],
                typeOfStatusSelected: 1,
                showEventsTable: false
            }
        },
        computed: {
            ...mapGetters({
                'getLanguage': 'profile/getLanguage',
                'getPlatformId': "rules/getPlatformId",
                'getOptions': 'bet/getOptions',
                'getEventsList': 'event/getEventsList',
                'getItemsSport': 'sport/getItems',
                'getCategories': 'category/getCategories',
                'getItemsCategories': 'category/getItems',
                'getTournaments': 'tournament/getTournaments',
                'getItemsTournaments': 'tournament/getItems',
                'getSkinFilters': 'skins/getSkinFilters',
                'getPreload': 'utils/getPreload',
                'getCounts': "event/getCounts",
                'getPlatform': "profile/getPlatform",
            }),

        },
        watch: {

            async sport(newValue, oldValue) {
                await this.selectEntityType();
            },
            async category(newValue, oldValue) {
                await this.selectEntityType();
            },
            async tournament(newValue, oldValue) {
                this.eventId = null;
                await this.selectEntityType();
            },
            async eventId(newValue, oldValue) {
                await this.selectEntityType();
            },
            async limits_type_selected(newValue, oldValue) {
                if (newValue == 'percentage' && oldValue == 'amount') {
                    this.limit_amount = 100;
                }
            },
            async typeOfStatusSelected(newValue, oldValue) {
                this.loadEventsForTournament();
            }
        },
        async mounted() {
            await this.setPreload(true);
            await this.setCountsEvent(0);
            await this.resetEventsList();
            await this.fetchSports({'language': this.getLanguage});
            await this.fetchCategories({'language': this.getLanguage, 'paramKey': this.sport});

            await this.setPreload(false);
            await this.selectEntityType();
        },
        methods: {
            ...mapActions({
                'setPreload': 'utils/setPreload',
                'fetchSports': 'sport/fetchSports',
                'fetchCategories': 'category/fetchCategories',
                'fetchTournaments': 'tournament/fetchTournaments',
                'fetchEventsList': 'event/fetchEventsList',
                'setCountsEvent': 'event/setCounts',
                'resetEventsList': 'event/resetEventsList',
                'saveEntityRule': 'risk/saveEntityRule',
                'saveEntityUserRule': 'risk/saveEntityUserRule',
                'setOptions': 'bet/setOptions',
                'setCriteria': 'event/setCriteria'
            }),

            getSportName(idSport) {

                const sportFound = this.getItemsSport.filter(sport => {
                    return sport.id == idSport
                })
                if (sportFound.length > 0) {
                    return sportFound[0].name
                } else {
                    return '';
                }
            },
            getCategoryName(idCategory) {

                const categoryFound = this.getItemsCategories.filter(category => {
                    return category.id == idCategory
                })
                if (categoryFound.length > 0) {
                    return categoryFound[0].name
                } else {
                    return '';
                }
            },
            getTournamentName(idTournament) {
                const tournamentFound = this.getItemsTournaments.filter(tournament => {
                    return tournament.id == idTournament
                })

                if (tournamentFound.length > 0) {
                    return tournamentFound[0].name
                } else {
                    return '';
                }
            },

            getPlatformName() {
                if (this.getPlatformId == 0) {
                    return "Manager";
                } else {
                    let platform = _.find(this.getSkinFilters, {platform_id: this.getPlatformId})
                    return (platform && platform.name) ? platform.name : 'Error name skin'
                }
            },

            async getCategoriesFromSport() {
                await this.setPreload(true);
                await this.fetchCategories({'language': this.getLanguage, 'paramKey': this.sport});

                this.category = ''
                this.tournament = ''
                this.events = null;
                await this.setPreload(false);
            },

            async getTournamentsFromCategories() {
                await this.setPreload(true);
                await this.fetchTournaments({'language': this.getLanguage, 'paramKey': this.category});

                this.tournament = '';
                this.events = null;
                await this.setPreload(false);
            },

            async getEventsFromTournament() {
                await this.setPreload(true);
                this.events = null;
                await this.setPreload(false);
            },

            async selectEntityType() {

                if (this.eventId && this.eventId != '' && this.eventId > 0) {
                    this.entity_type_selected = 'event';
                    this.entity_id_selected = this.eventId;
                    // this.entity_name = this.getEventName(this.entity_id_selected);
                } else if (this.tournament && this.tournament != '' && this.tournament > 0) {
                    this.entity_type_selected = 'tournament';
                    this.entity_id_selected = this.tournament;
                    this.entity_name = this.getTournamentName(this.entity_id_selected);
                } else if (this.category && this.category != '' && this.category > 0) {
                    this.entity_type_selected = 'category';
                    this.entity_id_selected = this.category;
                    this.entity_name = this.getCategoryName(this.entity_id_selected);
                } else if (this.sport && this.sport != '' && this.sport > 0) {
                    this.entity_type_selected = 'sport';
                    this.entity_id_selected = this.sport;
                    this.entity_name = this.getSportName(this.entity_id_selected);
                } else {
                    this.entity_type_selected = null;
                    this.entity_id_selected = null;
                    this.entity_name = ''
                }

                if (!this.eventId || this.eventId == '') {
                    await this.setCountsEvent(0);
                    await this.resetEventsList();
                    this.selectedEventItem = null;
                    this.showEventsTable = false;
                    this.setOptions({
                        limit: 10,
                        page: 1
                    });
                }
            },
            async loadEventsForTournament() {

                if (!this.sport || this.sport == '' || !this.category || this.category == '' || !this.tournament || this.tournament == '') {
                    alert('Error missing data !');
                    return;
                }

                this.showEventsTable = true;

                const criteria = {
                    "type": "match",
                    "status": this.typeOfStatusSelected,
                    "sport": this.sport,
                    "category": this.category,
                    "tournament": this.tournament,
                    "page": this.getOptions.page,
                    "limit": this.getOptions.limit
                }

                await this.setPreload(true);
                await this.fetchEventsList(criteria);
                await this.setPreload(false);
            },

            getDescription(item) {
                if (item.type == 'match') {
                    var home = _.find(item.competitors[0].detail, {'lang': this.getLanguage})
                    var away = _.find(item.competitors[1].detail, {'lang': this.getLanguage})
                    return home.text + ' - ' + away.text
                } else if (item.type == 'antepost') {
                    var tournament = _.find(item.tournament.detail, {'lang': this.getLanguage})
                    return 'Antepost ' + tournament.text
                }
            },

            getDetail(detail) {
                var detail = _.find(detail, {'lang': this.getLanguage})
                return detail.text
            },

            getStatus(status) {
                switch (status) {
                    case 1:
                        return 'nsy'
                    case 2:
                        return 'inProgress'
                    case 3:
                        return 'finished'
                    case 4:
                        return 'cancelled'
                    case 5:
                        return 'postponed'
                    case 6:
                        return 'interrupted'
                    case 7:
                        return 'abandoned'
                    case 8:
                        return 'coverageLost'
                    case 9:
                        return 'aboutToStart'
                }
            },
            getEventType(type) {
                switch (type) {
                    case 'match':
                        return 'prematch'
                    case 'antepost':
                        return 'antepost'
                }
            },

            async getNewPage() {

                const criteriaObj = {
                    limit: this.getOptions.limit,
                    page: this.getOptions.page,
                    platform_id: this.getPlatformId
                }

                await this.setCriteria(criteriaObj);
                await this.setPreload(true);
                await this.loadEventsForTournament();
                await this.setPreload(false);
            },

            handleClick(item, row) {
                this.eventId = item.id;
                row.select(true);
                this.entity_name = this.getDescription(item);
            },
            async buildPayloadForSaveGenericRule() {

                const payload = {
                    platform_id: this.getPlatformId,
                    group: this.groupTypeSelected,
                    entity_type: this.entity_type_selected,
                    entity_id: this.entity_id_selected.toString(),
                    entity_name: this.entity_name,
                    entity_context: this.entity_context_selected,
                    limits_type: this.limits_type_selected,
                    limits: +this.limit_amount,
                    limits_action: this.limit_action_selected,
                }
                return payload;
            },
            async buildPayloadForSaveUserRule() {

                const payload = {
                    platform_id: this.getPlatformId,
                    entity_type: this.entity_type_selected,
                    entity_id: this.entity_id_selected.toString(),
                    entity_name: this.entity_name,
                    entity_context: this.entity_context_selected,
                    limits_type: this.limits_type_selected,
                    limits: +this.limit_amount,
                    limits_action: this.limit_action_selected,
                    user_id: this.userTarget.idUser ? this.userTarget.idUser : this.userTarget.userId
                }
                return payload;
            },

            async buildPayloadForSaveRule() {
                let payload = null;

                if (this.userTarget == null) {
                    payload = await this.buildPayloadForSaveGenericRule();
                } else {
                    payload = await this.buildPayloadForSaveUserRule();
                }
                return payload;
            },
            async saveRule() {

                const payload = await this.buildPayloadForSaveRule();

                try {

                    let response = null;

                    if (this.userTarget == null) {
                        response = await this.saveEntityRule(payload);
                    } else {
                        response = await this.saveEntityUserRule(payload);
                    }

                    if (response && response.data && response.data.messages == 'Success Operation') {
                        this.$swal.fire(this.$t('successOperation'), '', 'success')
                        await this.$root.$emit('closedUpdateEntityUserLimitDialog');
                    } else if (response && response.data && response.data.data && (response.data.data == 'Rule entity exists' || response.data.data == 'Rule entity user exists')) {
                        this.$swal.fire(this.$t('risk.ruleAlreadyExists'), '', 'info')
                        await this.$root.$emit('closedUpdateEntityUserLimitDialog');
                    } else {
                        this.$swal.fire(this.$t('operationNotPerformedCorrectly'), '', 'error')
                        await this.$root.$emit('closedUpdateEntityUserLimitDialog');
                    }

                } catch (e) {
                    console.log(e)
                }
            }

        }
    }
</script>

<style scoped>

    #loadEventsBtn {
        margin-bottom: 24px;
        margin-left: 12px;
    }

    .flex.md12 {
        display: flex !important;
    }

    .title-row {
        padding: 0px !important;
        margin: 0 auto !important;
    }

</style>
