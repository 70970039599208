<template>
  <div id="chart" v-if="!this.getPreload">
    <label>Bets played current Month</label>
    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>

import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import _ from "lodash"

export default {
  name: "betPlayedForPlatform",
  data () {
    return {
      loading: true,
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar',
          events: {
            click: function(chart, w, e) {
              // console.log(chart, w, e)
            }
          }
        },
        colors: ['#77B6EA', '#545454'],
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          }
        },
        dataLabels: {
          enabled: true
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [
          ],
          labels: {
            style: {
              colors: ['#77B6EA', '#545454'],
              fontSize: '12px'
            }
          }
        }
      },
    }
  },
  methods: {
    ...mapActions({
      'setPreload': 'utils/setPreload',
    }),
    async getData() {

      var vue = this
      let url = `${vue.getUrl}bet/bet-stats-current-month`;

      return await axios.post(`${url}`,
          {'platform_id':this.getPlatformId},
          {'headers': {'Authorization': `Bearer ${vue.getToken}`}})
          .then((res) => {

            var array = []
            vue.series = []
            vue.chartOptions.xaxis.categories = []

            var ordered = _.orderBy(res.data.data, ['count'], ['desc'])

            _.forEach(ordered, (stats) =>{
              if(stats._id != 0 && stats._id != 1) {
                array.push(stats.count)
                var skin = _.find(vue.getSkins, {'platform_id':stats._id})
                if(skin){
                  vue.chartOptions.xaxis.categories.push(skin.name)
                }else {
                  vue.chartOptions.xaxis.categories.push(stats._id)
                }
              }
            })

            vue.series.push({
              data: array
            })

            return


          }).catch((error) => {
            vue.loading = false;
            console.log(error.message);
            console.log(error.stackTrace);
          });
    }
  },
  computed: {
    ...mapGetters({
      'getUrl': 'settings/getUrl',
      'getPreload': 'utils/getPreload',
      'getToken': 'profile/getToken',
      'getSkins': 'skins/getList',
      'getPlatformId': 'rules/getPlatformId',
    }),
  },
  async mounted () {

    setTimeout(async () =>{
      await this.setPreload(true)
      await this.getData()
      await this.setPreload(false)
    }, 500);

  },
  async created() {

  },
}
</script>

<style scoped>

</style>