import store from '../store';
import Tournament from '../views/tournaments/Index.vue'
import {checkauth, checkRoute} from "../Helper";
//import Category from '../views/user/Users.vue'
export default [
    {
        path: '/tournaments/:id',
        name: 'tournaments',
        component: Tournament,
        beforeEnter (to,from,next){
            checkRoute('/tournaments/:id').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },

]
