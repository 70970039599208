import store from '../store';
import Administrator from '../views/administrator/Administrators.vue'
import Permission from '../views/administrator/Permission.vue'
import {checkauth, checkauthManager, checkRoute} from "../Helper";
export default [
    {
        path: '/administrators',
        name: 'administrators',
        component: Administrator,
        beforeEnter (to,from,next){
            checkRoute('/administrators').then( response =>{
                    //console.log("response",response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },
    {
        path: '/administrators/permissions',
        name: 'administrators permission',
        component: Permission,
        beforeEnter (to,from,next){
            checkRoute('/administrators/permissions').then( response =>{
                    //console.log("response",response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    }

]
