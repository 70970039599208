import store from '@/store';
import Calendar from '@/views/live/Calendar.vue'
import Live from '@/views/live/Index.vue'

import {checkauth, checkauthManager, checkRoute} from "../Helper";

export default [

    {
        path: '/live/events',
        name: 'indexLive',
        component: Live,
        beforeEnter (to,from,next){
            checkRoute('/live/events').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },
    {
        path: '/live/calendar',
        name: 'calendarLive',
        component: Calendar,
        beforeEnter (to,from,next){
            checkRoute('/live/calendar').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },


]
