<template>
    <div>
        <v-btn block depressed color="#eeeeee" class="no-padding" size="x-small"
               @click="getDataUser(platform_id, user_id)">{{ $t('networkDetail') }}
        </v-btn>

        <v-dialog transition="dialog-bottom-transition" max-width="60%" v-model="modalNetwork"
                  @click:outside="removeModalNetwork">
            <v-card>
                <v-toolbar color="primary" dark class="text-uppercase" dense>
                    {{ $t('networkDetail') }}
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="removeModalNetwork">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-simple-table height="auto">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left">{{$t('user.login')}}</th>
                                <th class="text-left">{{$t('user.type_user')}}</th>
                            </tr>
                            </thead>
                            <tbody>

                            <tr v-for="item in ancestorsDetailsReverse">
                                <td>{{ item.userName != null ? item.userName : ''}}</td>
                                <td>{{ item.profileType != null ? item.profileType : ''}}</td>
                            </tr>

                            <tr>
                                <td>{{ userLogin != null ? userLogin : ''}}</td>
                                <td>{{ profileType != null ? profileType: ''}}</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import axios from "axios";

    export default {
        name: "networkDetail",
        props: {
            platform_id: {
                type: Number
            },
            user_id: {
                type: Number
            },
        },
        data() {
            return {
                modalNetwork: false,
                ancestor: null,
                agency: null,
                idUser: null,
                name: null,
                surname: null,
                operator: null,
                profileType: null,
                status: null,
                userLogin: null,
                ancestorsDetails: [],
                ancestorsDetailsReverse: []
            }
        },
        computed: {
            ...mapGetters({
                'getPreload': 'utils/getPreload',
                'getUrlApi': 'settings/getUrl',
                'getToken': 'profile/getToken',
            }),
        },
        methods: {
            ...mapActions({
                'setPreload': 'utils/setPreload',
            }),

            reset() {
                this.agency = null;
                this.ancestor = null;
                this.idUser = null;
                this.name = null;
                this.surname = null;
                this.operator = null;
                this.profileType = null;
                this.status = null;
                this.userLogin = null;
                this.ancestorsDetails = [];
                this.ancestorsDetailsReverse = [];
            },
            async getDataUser(platform_id, user_id) {

                const vm = this;

                await vm.setPreload(true);

                // if (process.env.NODE_ENV === 'local') {
                //     platform_id = 43;
                // }

                let url = `${this.getUrlApi}bookie/user-info/${platform_id}/${user_id}`; //todo use store actions or plugins
                axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${this.getToken}`}})
                    .then(async (res) => {
                        this.modalNetwork = true;

                        if (res != null && res.data != null && res.data.data != null) {
                            const dataResponse = res.data.data;

                            if (dataResponse != null) {

                                this.agency = dataResponse.agency;
                                this.ancestor = dataResponse.ancestor;
                                this.idUser = dataResponse.idUser;
                                this.name = dataResponse.name;
                                this.surname = dataResponse.surname;
                                this.operator = dataResponse.operator;
                                this.profileType = dataResponse.profileType;
                                this.status = dataResponse.status;
                                this.userLogin = dataResponse.user;
                            }

                            if (dataResponse != null && dataResponse.ancestorsDetail) {
                                const ancestorsDetail = dataResponse.ancestorsDetail;
                                this.ancestorsDetails = ancestorsDetail
                                this.ancestorsDetailsReverse = this.ancestorsDetails.reverse();
                            } else {
                                this.ancestorsDetails = [];
                                this.ancestorsDetailsReverse = [];
                            }
                            await this.setPreload(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        vm.reset();
                        vm.setPreload(false);
                    });
            },
            async removeModalNetwork() {
                const vm = this;
                vm.reset();
                vm.modalNetwork = false;
            },
        },
    }
</script>
