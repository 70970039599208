<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md12>
          <v-col cols="12">
            <span class="title-table" v-if="Object.keys(mixed).length > 0">
              <v-checkbox style="display: inline-block"
                      v-model="selectAll"
                      :label="(selectAll)?$t('deselectall'):$t('selectall')"
              ></v-checkbox>
              <v-btn style="display: inline-block; margin-left:20px " dense color="primary" v-if="selected.length>0"
                     @click.prevent="defineMulti()"
              >
                {{$t('bets.defineBet')}}
              </v-btn>
            </span>

            <v-card outlined elevation-0 class="mt-1" v-for="(match, name, index) in mixed" v-if="Object.keys(mixed).length > 0">

              <div class="" style="background: #dedede;">
                <span class="event-desc">
                  <v-icon @click="searchOnGoogle(match.bets[0].odds.event_description+' - '+new Date(match.bets[0].odds.time).toLocaleString())">mdi-card-search-outline</v-icon>
                  {{ match.bets[0].odds.event_description }} |
                  {{ new Date(match.bets[0].odds.time).toLocaleString() }} |
                  {{match.bets[0].odds.sport.name}} <v-icon x-small>mdi-chevron-right</v-icon>
                  {{match.bets[0].odds.category.name}} <v-icon x-small>mdi-chevron-right</v-icon>
                  {{match.bets[0].odds.tournament.name}}
                  <v-icon x-small>mdi-chevron-right</v-icon> {{match.bets[0].bet_context}}
                  <v-icon x-small>mdi-chevron-right</v-icon> Id: {{match.bets[0].odds.event_source}}

                </span>
              </div>
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>

                      <tr >

                        <td width="10%">

                          <v-checkbox v-if="match.markets[0].win!=''"
                                  v-model="selected"

                                  :value="match.bets[0].odds.event_source"
                          ></v-checkbox>

                        </td>
                        <td width="20%">

                          <v-text-field :label="$t('1st')" v-model="match.results[0].value" :readonly="match.results[0].readonly"></v-text-field>
                        </td>
                        <td width="20%">

                          <v-text-field :label="$t('2st')" v-model="match.results[1].value" :readonly="match.results[1].readonly" ></v-text-field>
                        </td>
                        <td width="20%">

                          <v-text-field :label="$t('full')" v-model="match.results[2].value" :readonly="match.results[2].readonly" ></v-text-field>
                        </td>
                        <td width="20%">

                          <v-select  v-for="market of match.markets " :label="market.text"
                                    v-model="market.win" :items="market.select" item-text="text" item-value="id"


                          ></v-select>
                        </td>
                        <td width="10%">

                          <v-btn style="display: inline-block; margin-left:20px " dense color="primary" v-if="match.markets[0].win!=''"
                                 @click.prevent="defineSingle(match)"
                          >
                            {{$t('bets.defineBet')}}
                          </v-btn>
                        </td>


                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

            </v-card>
            <div class="text-center pt-3 pb-3">

              <v-pagination
                      v-model="page"
                      :length="Math.ceil(total / limit)"
                      :total-visible="20"
                      @input="fetchOddsNotDefinedPaginate()"
              ></v-pagination>
            </div>


          </v-col>
        </v-flex>
      </v-layout>
    </v-container>
    <v-btn v-if="getPlatform==0"
            fab
            bottom
            right
            color="secondary"
            dark
            fixed
            @click.prevent="defineAll"
    >
      <v-icon>mdi-clipboard-check-outline</v-icon>
    </v-btn>
    <div class="text-center">
      <v-dialog v-model="dialogDefineSign" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{$t('bets.defineResult')}}
          </v-card-title>

          <v-card-text class="pt-5">
            <p class="font-weight-bold">{{this.descriptionToDefine}}</p>
            <v-select
                :items="results"
                item-text="name"
                item-value="value"
                filled
                v-model="resultToDefine"
            ></v-select>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions v-if="resultToDefine != ''">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="sendResult()">
              {{ $t('generic.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :vertical="snackbar.mode === 'vertical'">
      {{ snackbar.text }}


      <v-btn dark text @click="snackbar.snackbar = false">
        {{$t("generic.close")}}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import moment from 'moment';

export default {
  name: "OddsNotDefinedManualMarket",
  data() {
    return {
      antepost: [],
      mixed: [],
      selectAll:false,
      total:0,
      totalpending:0,
      skip:0,
      limit:10,
      page:1,
      pagepending:1,
      selected:[],
      listmarkets:[],
      results:[
        {'name': this.$t('generic.selectOption'), 'value':''},
        {'name': this.$t('bets.win'), 'value':'win'},
        {'name': this.$t('bets.lose'), 'value':'lose'},
        {'name': this.$t('bets.void'), 'value':'void'},
        {'name': this.$t('bets.cancelled'), 'value':'cancelled'},
        {'name': this.$t('bets.halfLost'), 'value':'halfLost'},
        {'name': this.$t('bets.halfWon'), 'value':'halfWon'},
      ],
      snackbar:{
        snackbar: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
      },
      dialogDefineSign: false,
      signToDefine:{},
      resultToDefine: "",
      descriptionToDefine: "",
      displayAntepost: false,
      template:{
        "Header": {
          "Type": 35,
          "MsgSeq": 1,
          "MsgGuid": "85527072-acf5-41aa-8ac9-6d4f478c560e",
          "CreationDate": "2022-06-28T10:50:36.526Z",
          "ServerTimestamp": 1656413436526
        },
        "Body": {
          "Events": [
            {
              "FixtureId": 8828050,
              "Livescore": null,
              "Markets": [
                {
                  "Id": 72,
                  "Name": "1st Period Odd/Even",
                  "Bets": [
                    {
                      "Id": 15553853538828050,
                      "Name": "Even",
                      "Status": 3,
                      "StartPrice": "1.0",
                      "Price": "1.88",
                      "Settlement": 2,
                      "ProviderBetId": "145",
                      "LastUpdate": "2022-06-28T10:50:36.496Z"
                    },
                    {
                      "Id": 20857602988828050,
                      "Name": "Odd",
                      "Status": 3,
                      "StartPrice": "1.0",
                      "Price": "1.93",
                      "Settlement": 1,
                      "ProviderBetId": "145",
                      "LastUpdate": "2022-06-28T10:50:36.496Z"
                    }
                  ]
                }
              ]
            }
          ]
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      'getLanguage': 'profile/getLanguage',
      'getToken': "profile/getToken",
      'getPlatform': "profile/getPlatform",
      'getPlatformId': "rules/getPlatformId",
      'getOptions': 'bet/getOptions',
      'getUrlApi': 'settings/getUrl',
      'getBetApiUrl': 'settings/getBetApiUrl',
    }),

  },
  watch: {
    selectAll: async function (val) {
      if(!val){
        this.selected=[]
      }else{
        this.mixed.forEach((value,index)=>{
          if(value.markets[0].win!='' ){
            this.selected.push(value.bets[0].odds.event_source)
          }
        })
      }
    },
  },
  async created() {
    await this.setPreload(true);
    //await this.fetchOddsNotDefined()
    this.skip=0
    this.skippending=0
    this.page=1
    this.pagepending=1
    await this.fetchOddsNotDefinedPaginate()
    await this.setPreload(false);
  },
  methods: {
    ...mapActions({
      'setPreload': 'utils/setPreload',
      'recoveryBetSettlement': 'bet/recoveryBetSettlement',
      'defineOdd': 'bet/defineOdd',
    }),
    ...mapMutations({
      'setLanguage': 'profile/setLanguage',
    }),
    getNewPage(){
      //console.log("PAGE",this.page)
    },

    async defineAll() {

      await this.setPreload(true);
      await this.fetchOddsNotDefinedPaginate()
      await this.fetchOddsNotDefinedPendingPaginate()
      this.setPreload(false);
    },
    uniqueArray(array){

      array = _.uniqBy(array, 'odds.id_outcome');
      return array
      //_.uni

    },
    async fetchOddsNotDefined() {

      var vue = this
      var mixed = []
      var mixed_sources = []
      var antepost = []
      var antepost_sources = []

      let url = `${this.getBetApiUrl}bet/odds-not-defined`;
      axios.post(`${url}`, {}, {'headers': {'Authorization': `Bearer ${this.getToken}`}}).then((result) => {

        _.forEach(result.data.odds, (odd) => {

          var oddObj = JSON.parse(JSON.stringify(odd))
          switch (oddObj.type) {
            case "antepost":
              if (!antepost_sources.includes(oddObj.unique_id)) {
                antepost.push(oddObj)
                antepost_sources.push(oddObj.unique_id)
              }
              break
            case "live":
            case "prematch":
              if (!mixed_sources.includes(oddObj.unique_id)) {
                mixed.push(oddObj)
                mixed_sources.push(oddObj.unique_id)
              }
              break
          }
        })

        var mixed_ordered = _.orderBy(mixed, ['time'], ['asc'])

        vue.mixed = _.groupBy(mixed_ordered, (item) => {
          return [item['event_id'], item['time']];
        });

        // vue.mixed = _.groupBy(mixed_ordered, ['event_id','time']);
        vue.antepost = _.groupBy(antepost, 'event_id');


      })

    },
    async fetchOddsNotDefinedPendingPaginate() {

      var vue = this
      var mixed = []
      var mixed_sources = []
      var antepost = []
      var antepost_sources = []
      this.skippending=(this.pagepending-1)*this.limit
      let url = `${this.getBetApiUrl}bet/odds-not-defined-antepost-paginate`;
      axios.post(`${url}`, {skip:this.skippending,limit:this.limit}, {'headers': {'Authorization': `Bearer ${this.getToken}`}}).then((result) => {

        vue.antepost = result.data.odds[0].paginateResult
        this.totalpending = result.data.odds[0].totalCount[0].count

      })

    },
    async defineMulti(){

      var promosis = []
      this.selected.forEach(value => {
        var match = _.find(this.mixed,{event:[{id:value}]})
        if(match){
          promosis.push(this.defineSingle(match,'multi'))

        }
        //console.log(match)
        //console.log(value)

      })
      Promise.all(promosis).then(async(result)=>{
        this.snackbar.color = "success";
        this.snackbar.text = this.$t("successDefinition");
        this.snackbar.snackbar = true;
        this.snackbar.mode = 'multi-line';
        this.snackbar.timeout = 5000;
        await this.setPreload(true);
        //await this.fetchOddsNotDefined()
        setTimeout(async() => await this.fetchOddsNotDefinedPaginate(), 10000);

        await this.setPreload(false);

      })
    },
    async defineSingle(match,type='single'){
      //console.log(match)
      //console.log("this.listmarkets",this.listmarkets);
      for await(var bet of match.bets){
      //match.bets.forEach(async(bet)=>{
        this.template.header={
          "Type": 35,
          "MsgSeq": 1,
          "MsgGuid": "85527072-acf5-41aa-8ac9-6d4f478c560e",
          "CreationDate": moment().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          "ServerTimestamp": moment().unix()
        }
        let ext_id = bet.odds.ext_id;
        let id_market = bet.odds.id_market;
        let id_outcome = bet.odds.id_outcome;
        let findOriginMarket = _.find(this.listmarkets,{id:id_market})
        if(!match.markets && !match.markets.length==0 && match.markets[0].win==''){
          return false
        }
        let win = match.markets[0].win
        if(!findOriginMarket){
          return false
        }
        let findOriginalOutcome = _.find(findOriginMarket.outcomes)

        let Id = findOriginMarket.source[0].value

        var Settlement
        if(win=="3"){
          Settlement = 3
        }else {
          if (win == id_outcome) {
            Settlement = 2
          } else {
            Settlement = 1
          }
        }
        var Bets = []


        this.template.Body= {
          "strategy": 'prematch',
          "Events": [
            {
              "FixtureId": match.event[0].source[0].value,
              "Livescore": null,
              "Markets": [
                {
                  "Id": +Id,
                  "Name": bet.odds.market_name,
                  "Bets": [
                    {
                      "Id": _.toString(ext_id),
                      "Name": bet.odds.outcome_name,
                      "Status": 3,
                      "StartPrice": _.toString(bet.odds.outcome_odd_value),
                      "Price": _.toString(bet.odds.outcome_odd_value),
                      "Settlement": Settlement,
                      "ProviderBetId": "0",
                      "LastUpdate": moment().format('YYYY-MM-DDTHH:mm:ss[Z]')
                    }

                  ]
                }
              ]
            }
          ]
        }

         let url = `${this.getBetApiUrl}bet/save-feed-fake`;
        await  axios.post(`${url}`, {template:JSON.stringify(this.template)}, {'headers': {'Authorization': `Bearer ${this.getToken}`}})

      }
      if(type=="single") {
        this.snackbar.color = "success";
        this.snackbar.text = this.$t("successDefinition");
        this.snackbar.snackbar = true;
        this.snackbar.mode = 'multi-line';
        this.snackbar.timeout = 5000;
        await this.setPreload(true);
        //await this.fetchOddsNotDefined()
        setTimeout(async() => await this.fetchOddsNotDefinedPaginate(), 10000);
        /*setTimeout(async function(){
          await this.fetchOddsNotDefinedPaginate()
        }, 3000);*/
        await this.setPreload(false);

      }

    },
    async fetchOddsNotDefinedPaginate() {

      var vue = this
      var mixed = []
      var mixed_sources = []
      var antepost = []
      var antepost_sources = []
      this.skip=(this.page-1)*this.limit
      let url = `${this.getBetApiUrl}bet/odds-not-defined-manual-market`;
      axios.post(`${url}`, {platform:this.getPlatformId,skip:this.skip,limit:this.limit}, {'headers': {'Authorization': `Bearer ${this.getToken}`}}).then((result) => {
        //console.log(result)
        //vue.mixed = result.data.odds
        this.listmarkets = result.data.markets
        var completeMarkets = []
        result.data.odds[0].paginateResult.forEach(event =>{
          if(!event.hasOwnProperty(result)){
            event.results=[]
          }
          if(event.event[0].livescore && event.event[0].livescore.Periods && _.size(event.event[0].livescore.Periods)>0) {
            // primo tempo

            const item = _.find(event.event[0].livescore.Periods, {source: 10})
            if (item) {
              let label = _.find(item.Type,{language:this.getLanguage})
              item.Results = _.orderBy(item.Results, ['Position'], ['asc']);
              event.results.push({
                source: 10,
                value: `${+item.Results[0].Value}-${+item.Results[1].Value}`,
                readonly: true
              })
            } else {
              event.results.push({source: 10, value: ``, readonly: true})
            }


            // secondo tempo
            const second = _.find(event.event[0].livescore.Periods, {source: 20})
            if (second) {

              second.Results = _.orderBy(second.Results, ['Position'], ['asc']);
              event.results.push({
                source: 20,
                value: `${+second.Results[0].Value}-${+second.Results[1].Value}`,
                readonly: true
              })
            } else {
              event.results.push({source: 20, value: ``, readonly: true})
            }


          // full time
            const final = _.find(event.event[0].livescore.Periods, {source: 100})
            if (final) {

              final.Results = _.orderBy(final.Results, ['Position'], ['asc']);
              event.results.push({
                source: 20,
                value: `${+final.Results[0].Value}-${+final.Results[1].Value}`,
                readonly: true
              })
            } else {
              event.results.push({source: 100, value: ``, readonly: true})
            }

          }else{
            event.results.push({source: 10, value: ``, readonly: true})
            event.results.push({source: 20, value: ``, readonly: true})
            event.results.push({source: 100, value: ``, readonly: true})
          }
          var markets = []
          event.bets.forEach(bet =>{
              if(bet) {
                if (!_.find(markets, {id_market: bet.odds.id_market})) {
                  let valueMarket = _.find(result.data.markets, {id: bet.odds.id_market})
                  //console.log("valueMarket",valueMarket)
                  if (valueMarket) {
                    var detail = _.find(valueMarket.detail, {lang: this.getLanguage})
                    var selectOurcomes = []
                    selectOurcomes.push({id: '', text: this.$t('selectOption')})
                    valueMarket.outcomes.forEach(out => {
                      let textOutcome = _.find(out.detail, {lang: this.getLanguage})
                      selectOurcomes.push({id: out.id, text: textOutcome.text})
                    })
                    selectOurcomes.push({id: '3', text: "Void"})
                    let win = this.findResult(bet.odds.id_market, event.results, selectOurcomes)
                    markets.push({id_market: bet.odds.id_market, text: detail.text, select: selectOurcomes, win: win})
                  }
                }
              }


          });
          event.markets = markets



        })

        this.selected=[]
        vue.mixed = result.data.odds[0].paginateResult
        this.total = result.data.odds[0].totalCount[0].count


      })

    },
    findResult(market,result,select){
      var win
      switch(market){
        case 4682834997:
          //console.log("RESULT",result)

          if(result.length>0){
            var sumFirst = result[0].value
            var one = _.split(sumFirst, '-');
            var goalOne=0
            if(one.length>1) {
              one.forEach(el => {
                goalOne = goalOne + _.toNumber(el)
              })
            }else{
              goalOne=''
            }

            var sumSecond = result[1].value
            var two = _.split(sumSecond, '-');
            var goalTwo=0
            if(two.length>1) {
              two.forEach(el => {
                goalTwo = goalTwo + _.toNumber(el)
              })
            }else{
              goalTwo=''
            }


            var sumFinal = result[2].value
            var tree = _.split(sumFinal, '-');
            var goalTree=0
            if(tree.length>1) {
              tree.forEach(el => {
                goalTree = goalTree + _.toNumber(el)
              })
            }else{
              goalTree=''
            }
           // console.log(goalOne,goalTwo,goalTree)
            if(_.isNumber(goalOne) && _.isNumber(goalTwo)) {
              if (goalOne === 0 && goalTwo === 0 && goalTree === 0) {
                win = 8629737623
                //win = 3230145657
              } else if (goalOne === goalTwo) {
                win = 8629737623
              } else if (goalOne > goalTwo) {
                win = 6510867491
              } else if (goalOne < goalTwo) {
                win = 8423602902
              } else if (goalOne == "" && goalTwo == "") {
                win = ""
              }
            }else{
              win = ""
            }



          }else{
            win=""
          }


          break;
      }
      //console.log("win",win)
      return win

    },
    async defineResult(eventId, unique_id, desc, alias, market){
      //console.log(eventId, unique_id, desc, alias, market)
      await this.setPreload(true);
      this.resultToDefine = ''
      this.signToDefine = {
        event_id: eventId,
        odd_unique_id: unique_id,
      }
      if(desc == undefined){
        desc = market
      }
      this.descriptionToDefine = desc + ' - '+ alias
      this.dialogDefineSign = true
      await this.setPreload(false);
    },

    async recoverySettlement(eventId){
      await this.setPreload(true);
      await this.recoveryBetSettlement({event_id : eventId})
      await this.setPreload(false);
    },

    async sendResult(){
      await this.setPreload(true);
      this.signToDefine.result = this.resultToDefine
      const result = await this.defineOdd(this.signToDefine)

      setTimeout(async () =>{

        if(result){
          this.dialogDefineSign = false
          await this.fetchOddsNotDefinedPaginate()
        }

        await this.setPreload(false);

      }, 1500);

    },
    searchOnGoogle(key){
      let url = 'https://www.google.com/search?q='+key
      window.open(url, '_blank').focus()
    },

    getSpread(spec) {
      if(spec.includes('#')){
        var split = spec.split('#')
        if(split[1].includes(':')){
          return '('+split[1]+')'
        }else if(split[1].includes('(') && split[1].includes('.')){
          return ' ['+split[1]+'] '
        }else if(split[1] != ''){
          return split[1]
        }else {
          return ''
        }
      }else {
        return ''
      }
    }


  },
}
</script>

<style scoped>
.event-desc{
  font-size: 14px;
  padding-left: 10px;
  font-weight: 700;
}
.info-label{
  font-size: 11px;
  color: #888888;
}
</style>
