//import moment from 'moment';
import momentyz from 'moment-timezone'
import store from './store'

import VueMoment from 'vue-moment';
export async function  checkauth () {
    if (!localStorage.getItem('token')){
        return false;
    }

    momentyz.tz.setDefault('Europe/Rome')
    var dateexpired = momentyz.utc(momentyz.unix(localStorage.getItem('expired')))
    var datenow = momentyz.utc(new Date())
    if(datenow>dateexpired){
            await store.dispatch("profile/refreshToken").then(resp => {
                if(resp.status){
                    return true;
                }else{
                    return false
                }
            //}
        });
    }
    //console.log(store.getters['profile/getAutenticate'])
    if(localStorage.getItem('token') && store.getters['profile/getAutenticate']==0){
        await store.dispatch("profile/refreshToken").then(resp => {
            if(resp.status){
                return true;
            }else{
                return false
            }
        });
    }
    return true;
}

export async function  checkauthManager () {

    if (!localStorage.getItem('token')){
        return false;
    }
   //console.log("profile/getPlatform",store.getters['profile/getPlatform'])
    //console.log("profile/getAutenticate",store.getters['profile/getAutenticate'])
    //console.log("fabio",store)
    if(store.getters['profile/getPlatform']!=0){
        return false
    }
    momentyz.tz.setDefault('Europe/Rome')
    var dateexpired = momentyz.utc(momentyz.unix(localStorage.getItem('expired')))
    var datenow = momentyz.utc(new Date())
    if(datenow>dateexpired){
        await store.dispatch("profile/refreshToken").then(resp => {
            if(resp.status ){
                return true;
            }else{
                return false
            }
            //}
        });
    }
    if(localStorage.getItem('token') && store.getters['profile/getAutenticate']==0){
        await store.dispatch("profile/refreshToken").then(resp => {
            if(resp.status ){
                return true;
            }else{
                return false
            }
        });
    }
    return true;
}

export async function  checkRoute (route) {

    if (!localStorage.getItem('token')){
        return false;
    }



    momentyz.tz.setDefault('Europe/Rome')
    var dateexpired = momentyz.utc(momentyz.unix(localStorage.getItem('expired')))
    var datenow = momentyz.utc(new Date())
    if(datenow>dateexpired){
        await store.dispatch("profile/refreshToken").then(resp => {
            if(resp.status ){
                return true;
            }else{
                return false
            }
            //}
        });
    }
    if(localStorage.getItem('token') && store.getters['profile/getAutenticate']==0){
        await store.dispatch("profile/refreshToken").then(resp => {
            if(resp.status ){
                return true;
            }else{
                return false
            }
        });
    }
    var listPermissions = store.getters['profile/getPermissions']
    // console.log(listPermissions)
    var permissions = listPermissions[0].permissions
    if(!_.includes(permissions,route)){
        return false
    }
    return true;
}
