import axios from "axios";

const state = {
    lists: [],
};

const getters = {

    getLists: state => {
        return state.lists;
    },
};

const actions = {
    //fetchCategories: (context, payload) => (paramKey) =>  {

    async fetchMarket(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}markets/show/${payload.market}`;
        // console.log(url);
        return await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                return res.data.data

                //this.title = responseshow[0].text
            })
            .catch((error) => {
                console.log(error);
            });
    },

    async fetchMarketBySport(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}api/sports/${payload.sportId}/markets/${payload.marketId}/detail/${payload.language}`;
        // console.log(url);
        return await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },

    async fetchMarketOutcomesAndTranslations(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}api/sports/${payload.sportId}/markets/${payload.marketId}/translations-settings/${payload.language}`;
        // console.log(url);
        return await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },

    async fetchMarketManualList(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}markets/manual/${payload.platform_id}`;
        // console.log(url);
        return await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {


                context.commit('setLists', res.data.data);
                return res.data.data
                //this.title = responseshow[0].text
            })
            .catch((error) => {
                console.log(error);
            });
    },

    async getCombinableMarketsByPlatformId(context, payload) {

        const platformId = payload.platformId != null ? payload.platformId : null;

        if (platformId == null) {
            return null
        }

        const language = payload.language != null ? payload.language : null;

        let urlWithLanguage = `${context.rootGetters['settings/getUrl']}markets/combinable-markets/platform/${platformId}/lang/${language}`;
        let url = `${context.rootGetters['settings/getUrl']}markets/combinable-markets/platform/${platformId}`;

        const urlSelected = language != null ? urlWithLanguage : url

        return await axios.get(`${urlSelected}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },

    async getCombinableMarketsByTournamentId(context, payload) {

        const platformId = payload.platformId;
        const sportId = payload.sportId;
        const categoryId = payload.categoryId;
        const tournamentId = payload.tournamentId != null ? payload.tournamentId : null;

        if (sportId == null) {
            return null
        }

        if (categoryId == null) {
            return null
        }

        if (tournamentId == null) {
            return null
        }

        let url = `${context.rootGetters['settings/getUrl']}markets/combinable-markets/platform/${platformId}/sport/${sportId}/category/${categoryId}/tournament/${tournamentId}`;

        return await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.log(error);
                return null
            });
    },

    async updateCombinableMarketsForTournament(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}markets/combinable-markets/`

        const response = await axios.put(`${url}`,
            payload,
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.log(error);
                throw error
            });

        return response;

    },

    async deleteCombinableMarketsById(context, payload) {

        const _id = payload._id != null ? payload._id : null;

        if (_id == null) {
            return null
        }

        let url = `${context.rootGetters['settings/getUrl']}markets/combinable-markets/` + _id
        const response = await axios.delete(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}});

        return response

    },
    async deleteCombinableMarketsByPlatformId(context, payload) {

        const platformId = payload.platformId != null ? payload.platformId : null;

        if (platformId == null) {
            return null
        }

        let url = `${context.rootGetters['settings/getUrl']}markets/combinable-markets/platform/` + platformId
        const response = await axios.delete(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}});

        return response
    },
    async fetchMarketTranslations(context, payload) {

        const platformId = payload.platformId;
        const sportId = payload.sportId;
        const marketId = payload.marketId;

        let url = `${context.rootGetters['settings/getUrl']}markets/translations/override/platform/${platformId}/sport/${sportId}/market/${marketId}`
        const response = await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}});

        return response
    },

    async saveOrUpdateMarketTranslation(context, payload) {
        const platformId = payload.platformId;
        const sportId = payload.sportId;
        const marketId = payload.marketId;

        const isValidId = (value) => {
            return value !== null && value !== undefined && value !== '';
        };

        const isValidTranslation = (value) => {
            return value !== undefined && value !== '';
        };

        if (!isValidId(platformId) || !isValidId(sportId) || !isValidId(marketId)) {
            throw new Error('platformId, sportId, and marketId must be non-null, non-undefined, and non-empty strings');
        }

        const languages = ['it', 'en', 'es', 'pt', 'fr', 'tr', 'cn', 'de'];
        languages.forEach(lang => {
            if (!isValidTranslation(payload[lang])) {
                payload[lang] = null;
            }
        });

        let url = `${context.rootGetters['settings/getUrl']}markets/translations/override/platform/${platformId}/sport/${sportId}/market/${marketId}`;
        const response = await axios.post(url, {
            it: payload.it,
            en: payload.en,
            es: payload.es,
            pt: payload.pt,
            fr: payload.fr,
            tr: payload.tr,
            cn: payload.cn,
            de: payload.de
        }, {
            headers: {
                'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`
            }
        });

        return response;
    }
};

const mutations = {
    setLists(state, payload) {
        state.lists = payload;
    }

};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
