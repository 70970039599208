<template>
  <div>
<!--    <v-card class="search" elevation="1" style="margin-bottom: 10px">-->
<!--      <v-container class="container&#45;&#45;fluid">-->
<!--        <v-col cols="12" class="no-padding">-->
<!--         &lt;!&ndash; <v-text-field-->
<!--              outlined-->
<!--              label="Cerca per match id"-->
<!--              append-outer-icon="mdi-file-find"-->
<!--              dense-->
<!--              v-model="searchById"-->
<!--              @click:append-outer="searchDetail()"-->
<!--              class="no-field-details"-->
<!--          ></v-text-field>&ndash;&gt;-->
<!--        &lt;!&ndash; //:search-input.sync="search" &ndash;&gt;-->
<!--          &lt;!&ndash;@change="searchDetail()"&ndash;&gt;-->


<!--          <v-autocomplete-->


<!--                  v-model="select"-->
<!--                  :items="items"-->
<!--                  :loading="isLoadingsearch"-->
<!--                  :search-input.sync="search"-->

<!--                  clearable-->

<!--                  :item-text="text"-->
<!--                  item-value="source"-->
<!--                  label="Search for match..."-->
<!--                  @change="searchDetail"-->

<!--                  dense-->

<!--          >-->
<!--            <template v-slot:no-data>-->
<!--              <v-list-item>-->
<!--                <v-list-item-title>-->
<!--                  Cerca per <strong>squadra</strong> or <strong>ID match</strong>-->

<!--                </v-list-item-title>-->
<!--              </v-list-item>-->
<!--            </template>-->
<!--            <template v-slot:selection="{ attr, on, item, selected }" >-->

<!--                <span v-text="item.title"></span>-->

<!--            </template>-->
<!--            <template  v-slot:item="{ item,index }" >-->
<!--              <v-list-item-content>-->
<!--                <v-list-item-title v-text="item.title"></v-list-item-title>-->
<!--                <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>-->
<!--              </v-list-item-content>-->
<!--              <v-list-item-action>-->
<!--                <v-list-item-subtitle v-text="item.data"></v-list-item-subtitle>-->
<!--                <v-list-item-subtitle v-text="item.ora"></v-list-item-subtitle>-->
<!--              </v-list-item-action>-->
<!--            </template>-->
<!--          </v-autocomplete>-->
<!--        </v-col>-->
<!--      </v-container>-->
<!--    </v-card>-->

    <transition-group name="card-in-out">
    <v-card class="tournament-card-preview" elevation="1" v-for="(tournament,index) in getEventsPreview" v-if="tournament.data.message.num_events > 0" :key="tournament.tournament">

      <div class="content-card match-group">

        <div class="tournament-desc">
          {{tournament.data.sport.name +' >'}} {{tournament.data.category.name+' > '}} {{tournament.data.tournament.name}}
          <span class="close-button" @click="removeActive(tournament.tournament)"><v-icon>close</v-icon></span>
        </div>
        <div class="tournament-desc text-right">
          Legenda status Match
          <v-badge dot inline left>Non iniziato</v-badge>
          <v-badge dot inline left color="green">Live</v-badge>
          <v-badge dot inline left color="deep-purple accent-4">Terminato</v-badge>
          <v-badge dot inline left color="error">Chiuso</v-badge>
        </div>

        <!-- Header -->

        <!--                    <div v-for="(eventByDate, dateToIterate) in tournament.data.events" :key="dateToIterate" class="match-group">-->

        <div class="header-table-group d-flex">
          <div class="header-match-group text-center col-md-1">
            Info
          </div>
          <div class="header-match-group text-center col-md-2">
            Match
          </div>
          <div v-if="Object.keys(tournament.data.markets).length == 0" class="header-match-group text-center col-md-8">
            Quote Preview
          </div>
          <div v-else class="header-match-group text-center" :class="(Object.keys(tournament.data.markets).length == 1) ? 'col-md-8': 'col-md-4'" v-for="(market, index) in tournament.data.markets" :key="market.id" v-if="index < 2">
            {{ market.name }}
            {{getSpecifiers(market.id)}}
          </div>
          <div class="header-match-group text-center col-md-1">
            Azioni
          </div>
        </div>

        <!-- Match for Date -->
        <div class="row-match d-flex" v-for="match in tournament.data.events" :key="match.id">
          <div class="col-md-1 cell info-match">
            {{match.id}}
            <v-icon>mdi-alarm</v-icon> {{match.scheduled |  moment("DD/MM/YYYY HH:mm")}}
          </div>
          <div class="col-md-2 cell cell-teams" v-if="match.type == 'match'">
            <v-badge dot inline left v-if="match.status == 1" :title="match.id +' - Non iniziato'">
              {{match.competitors.home.name}} - {{match.competitors.away.name}}
            </v-badge>
            <v-badge dot inline left color="green" v-else-if="match.status == 2" :title="match.id +' - Live'">
              {{match.competitors.home.name}} - {{match.competitors.away.name}}
            </v-badge>
            <v-badge dot inline left color="deep-purple accent-4" v-else-if="match.status == 3" :title="match.id +' - Terminato'">
              {{match.competitors.home.name}} - {{match.competitors.away.name}}
            </v-badge>
            <v-badge dot inline left color="error" v-else-if="match.status == 4" :title="match.id +' - Chiuso'">
              {{match.competitors.home.name}} - {{match.competitors.away.name}}
            </v-badge>
            <v-badge dot inline left v-else :title="match.id">
              {{match.competitors.home.name}} - {{match.competitors.away.name}}
            </v-badge>
          </div>
          <div class="col-md-2 cell cell-teams" v-else>
            <v-badge dot inline left v-if="match.status == 1" :title="match.id +' - Non iniziato'">
              {{match.description}}
            </v-badge>
            <v-badge dot inline left color="green" v-else-if="match.status == 2" :title="match.id +' - Live'">
              {{match.description}}
            </v-badge>
            <v-badge dot inline left color="deep-purple accent-4" v-else-if="match.status == 3" :title="match.id +' - Terminato'">
              {{match.description}}
            </v-badge>
            <v-badge dot inline left color="error" v-else-if="match.status == 4" :title="match.id +' - Chiuso'">
              {{match.description}}
            </v-badge>
            <v-badge dot inline left v-else :title="match.id">
              {{match.description}}
            </v-badge>
          </div>
<!--          <div class="col-md-1 cell">-->
<!--            <v-switch class="mt-0"-->
<!--                      v-model="match.status.status"-->
<!--            ></v-switch>-->
<!--          </div>-->

          <div class="col-md-8 cell" v-if="match.odds.void || match.odds_count == 0">
            Quote non disponibili
          </div>
          <div v-else :class="(Object.keys(match.odds).length == 1) ? 'col-md-8': 'col-md-4'" class="cell" v-for="(odd,index) in match.odds" v-if="index<2">

            <div v-if="odd.void == true || !odd.active">
              Quote non disponibili
            </div>
            <div v-else class="odd-button-group">
              <span v-for="outcome in odd.odds" :key="outcome.id_outcome" class="odd-button" :class="(outcome.type_change != undefined) ? outcome.type_change:'equal'">
                <span class="sign"  :title="outcome.outcome_name">
                  {{ (outcome.outcome_name) ? outcome.outcome_name : outcome.outcome_alias | truncate(8,'..')}}
                  <v-icon class="arrow arrow-down" v-if="outcome.type_change == 'down'">arrow_drop_down</v-icon>
                  <v-icon class="arrow arrow-up" v-if="outcome.type_change == 'up'">arrow_drop_up</v-icon>
                </span>
                <input v-if="outcome.active" v-model.number="outcome.outcome_odd_value" type="number" step="0.01" @change="changeOddValue(tournament.tournament, match.id, odd.id_market, outcome.id_outcome)"/>
                <span class="lock" v-else>
                  <v-icon>lock</v-icon>
                </span>
              </span>
              <span v-if="odd.key_percent" class="key-input">
                 <span class="sign">K
                   <span @click="changeLockKey(tournament.tournament, match.id, odd.id_market)" :title="odd.key_percent">
                     <v-icon v-if="odd.key_locked">lock</v-icon> <v-icon v-else>lock_open</v-icon>
                   </span>
                   <span @click="freezeOddsRule(tournament.data.sport,tournament.data.category, tournament.data.tournament, match.id, odd.id_market, odd.specifiers, odd, match.description)" title="Congela le quote" style="float: right" class="cursor-pointer">
                     <v-icon :class="(odd.freeze) ? 'freeze' : ''">ac_unit</v-icon>
                   </span>
                   <span @click="saveRuleKey(tournament.data.sport,tournament.data.category, tournament.data.tournament, match.id, odd.id_market, odd.specifiers, odd.key_percent_fixed, match.description)" title="Salva regola sulla Key" style="float: right" class="cursor-pointer">
                     <v-icon>save</v-icon>
                   </span>
                 </span>
                 <input :readonly="odd.key_locked" v-model.number="odd.key_percent_fixed" type="number" @change="checkNewKey(tournament.tournament, match.id, odd.id_market)"/>
              </span>
              <span class="multi-input">
                 <span class="sign">MULT <span @click="saveMultiplicity(tournament.data.sport,tournament.data.category, tournament.data.tournament, match.id, odd.id_market, odd.specifiers, odd.multiplicity, match.description)"><v-icon class="icon-action" title="Salva legatura">save</v-icon></span></span>
                 <input v-model="odd.multiplicity" type="text"/>
              </span>
            </div>
          </div>

          <div class="col-md-1">
            <v-icon class="icon-action" v-if="match.odds_count > 0" @click="showDetailMatch(match.id, match.type,tournament.data.sport.id,tournament.data.tournament.id, match.status)" title="Dettaglio match">pageview</v-icon>
            <v-icon class="icon-action" @click="updateOdds(match.id)" title="Forza il download delle quote per l'evento">update</v-icon>
<!--            <v-icon class="icon-action" @click="followOdds(match.id)" title="Esegui tutti gli ODD CHANGE delle quote per l'evento">menu</v-icon>-->
<!--            <router-link target="_blank" :to="`/history/${match.id}`" ><v-icon class="icon-action" title="Visualizza storico Aggiornamenti">timeline</v-icon></router-link>-->
          </div>
        </div>
        <!-- End Match for Date -->

        <!-- End Header -->
      </div>
    </v-card>
    <v-card v-else>
      <v-alert :value="true" color="warning" icon="priority_high" outlined>
        <strong>Attenzione</strong> Non sono presenti eventi per il torneo [ {{tournament.data.tournament.source}} ]
      </v-alert>
    </v-card>
    </transition-group>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'"
                :timeout="snackbar.timeout" :vertical="snackbar.mode === 'vertical'">
      {{ snackbar.text }}
      <v-btn dark text @click="snackbar.snackbar = false">
        Chiudi
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import moment from 'moment';
import debounce from 'debounce'
export default {
  name: "DetailTournamentEvents",
  data() {
    return {
      preload: false,
      defaultMultiplicity: "1-30",
      searchById:"",


      isLoadingsearch: false,
      items: [],
      model: null,
      search: null,
      select:null,
      arrayFilter:['title','source'],
      snackbar: {
        snackbar: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      'getLanguage': 'profile/getLanguage',
      'getToken': "profile/getToken",
      'getEventsPreview': 'tournament/getEventsPreview',
      'getActiveTournaments': 'tree/getActiveTournaments',
    }),
  },
  watch: {
    search (val) {

      if (_.isNull(val) && val=="null" && val.length<3 ) {
        return
      }
      //debounce(this.querySelections, 5000)(val)
        this.querySelections(val)
    },

  },
  async mounted() {

  },
  methods: {

    ...mapActions({
      'fetchEventsPreview': 'tournament/fetchEventsPreview',
      'setMatchDetailDialog': 'utils/setMatchDetailDialog',
      'updateOddsEvent': 'event/updateOddsEvent',
      'followOddsEvent': 'event/followOddsEvent',
      'findEvent': 'event/findEvent',
      'getOddsEvent': 'utils/getOddsEvent',
      'setPreload': 'utils/setPreload',
      'removeTournament': 'tree/removeTournament',
      'updateRules': 'skins/updateRules',
      'fetchSkins': 'skins/fetchSkins',
      'oddChangeSocket':'utils/oddChangeSocket',
      'deleteRules': 'skins/deleteRules',
      'cacheRules': 'cache/cacheRules',
    }),

    ...mapMutations({
      'resetEventsPreview': 'tournament/resetEventsPreview',
    }),
    querySelections: _.debounce(function(v) {

      this.isLoadingsearch = true
      // Simulated ajax query
      if (v && v.length >= 3) {
        this.findEvent({lang: this.getLanguage, search: v}).then(response => {

          this.items = response
          this.isLoadingsearch = false
        })
      }else{
        return
      }
      if (v <= 2) {
        this.select = null
        this.isLoadingsearch = false
      }
    },300),
    text(item){
      //console.log("item",item)
      return item.title + ' — ' + item.source +' - '+item.competitors + ' '+item.tournament
    },

    async updateOdds(source) {
      var vue = this;
      this.updateOddsEvent({'event':source, 'type':'ls'}).then((result)=>{
          this.snackbar.color = "success";
          this.snackbar.text = "Richiesta di aggiornamento inserita correttamente";
          this.snackbar.snackbar = true;
          this.snackbar.mode = 'multi-line';
          this.snackbar.timeout = 5000;
         this.cacheRules()
      })
    },
    async followOdds(source) {
      var vue = this;

      await this.followOddsEvent({'paramKey':source});
      await this.resetEventsPreview();
      await _.forEach(vue.getActiveTournaments, function (item) {
        vue.getEventsFromTournament(item)
      })
    },


    async getEventsFromTournament(tournamentId) {
      await this.setPreload(true);
      var split = tournamentId.split("#");
      var typeOdds = (split[1] == "antepost") ? 'antepostPreviewOdds': 'previewOdds';
      await this.fetchEventsPreview({'language': this.getLanguage, 'paramKey': split[0], 'type':typeOdds});

      await this.setPreload(false);
    },

    async showDetailMatch(eventId, type,sport,tournament, status) {

      await this.setPreload(true);
      await this.getOddsEvent({'paramKey': eventId, 'type':type,'sport':sport,'tournament':tournament, 'group':0, 'status':status});
      await this.setMatchDetailDialog({'status':true})
      //await this.filterMatchHook();

      await this.$root.$emit('filterMatchHook')
      await this.setPreload(false);

    },

    async saveMultiplicity(sport, category, tournament, matchSource, marketId, specifier, newMultiplicity, desc){

      var path = '';
      path = sport.id+'#'+category.id+'#'+tournament.id+'#'+matchSource

      var description = ''
      description = sport.name+' > '+category.name+' > '+tournament.name+' > '+desc

      var market = (specifier != "not_spec" && specifier != "" ) ? marketId+'#'+specifier : marketId

      var payload = {
        "typeRule":'multiplicity',
        "source": matchSource,
        "scope": "event#0",
        "rule": {"market":market, "multiplicity": newMultiplicity, 'path': path, 'description':description}
      }

      await this.updateRules(payload)
          .then((res) => {

            this.snackbar.color = "success";
            this.snackbar.text = "Molteplicità aggiornata correttamente";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;
            this.cacheRules()
          }).catch((err)=>{

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });

      await this.fetchSkins()
      await this.setPreload(false)

    },
    changeLockKey(tournamentId, matchId, marketId){

      var tournament = _.find(this.getEventsPreview, {'tournament':tournamentId})
      var event = _.find(tournament.data.events, {'id':matchId})
      var odds = _.find(event.odds, {'id_market':marketId})

      odds.key_locked = !odds.key_locked;

    },

    checkNewKey(tournamentId, matchId, marketId){

      var tournament = _.find(this.getEventsPreview, {'tournament':tournamentId})
      var event = _.find(tournament.data.events, {'id':matchId})
      var odds = _.find(event.odds, {'id_market':marketId})

      odds.key_percent = odds.key_percent_fixed
      odds.odds_total = odds.odds_total_original *  odds.key_percent_original / odds.key_percent_fixed

      _.forEach(odds.odds, function(odd) {

        var new_odd_value = odd.outcome_odd_percent*odds.odds_total/100;
        odd.outcome_odd_value = new_odd_value.toFixed(2)
        odd.outcome_key_value = 100/odd.outcome_odd_value

      });

      odds.override = parseFloat(odds.key_percent_fixed) != parseFloat(odds.key_percent_fixed_original);

      console.log('## Change Odds Key')
      console.log(odds)


    },

    changeOddValue(tournamentId, matchId, marketId, id_outcome){
      var tournament = _.find(this.getEventsPreview, {'tournament':tournamentId})
      var event = _.find(tournament.data.events, {'id':matchId})
      var odds = _.find(event.odds, {'id_market':marketId})

      console.log('## Change Odds Value')

      if(odds.key_locked){
        // Se la Key è bloccata
        console.log('## Key locked')

        // Conto le quote attive su cui distrubuire la differenza della key, esclusa quella variata (perciò -1)
        var countActiveOdds = _.countBy(odds.odds, 'active').true - 1;

        // Calcolo la key della quota cambiata
        var odd_changed = _.find(odds.odds, {'id_outcome':id_outcome});
        odd_changed.outcome_key_value = 100/odd_changed.outcome_odd_value;

        // Reset Key total
        odds.key_percent = 0.00;

        // Somma di tutte le key aggiornata
        for(let odd_key_sum of odds.odds){
          odds.key_percent += parseFloat(odd_key_sum.outcome_key_value)
        }

        // Differenza key originale
        var value_diff = (odds.key_percent_original - odds.key_percent)/countActiveOdds;
        odds.key_percent += (odds.key_percent_original - odds.key_percent)
        odds.key_percent_fixed = odds.key_percent.toFixed(0)

        // Resetto Odds total
        odds.odds_total = 0.00

        // Aggiungo la differenza splittata per numero di quote attive e mi calcolo il nuovo valore della quota
        for(let odd of odds.odds){
          if(odd.id_outcome != id_outcome){
            odd.outcome_key_value += parseFloat(value_diff)
            odd.outcome_key_value_original = odd.outcome_key_value;
            odd.outcome_odd_value = (100/odd.outcome_key_value).toFixed(2);
            odd.outcome_odd_value_original = odd.outcome_odd_value;
            odd.outcome_odd_value_for_order = odd.outcome_odd_value
          }

          // Ricalcolo quota totale
          odds.odds_total += parseFloat(odd.outcome_odd_value);
        }

        odds.odds_total_original = odds.odds_total;

        // Ricalcolo partizionamento quote su 100%
        for(let odd_percent of odds.odds){
          odd_percent.outcome_odd_percent = (odd_percent.outcome_odd_value / odds.odds_total)*100
          odd_percent.outcome_odd_percent = odd_percent.outcome_odd_percent.toFixed(2)
        }

        console.log(odds)

      }else{
        // Se la key non è bloccata aggiorno la key in base alla nuova quota

        console.log('## Key not locked')

        var key_percent = 0.00

        _.forEach(odds.odds, function(odd) {

          odd.outcome_key_value = 100/odd.outcome_odd_value
          key_percent += odd.outcome_key_value

        });

        odds.key_percent = key_percent;
        odds.key_percent_fixed = key_percent.toFixed(0)


      }

      console.log(odds)

    },

    async removeActive(tournamentSource){
      await this.removeTournament(tournamentSource)
    },

    searchDetail(){

      if(!_.isUndefined(this.select) && !_.isNull(this.select)) {
        var split = this.select.split(":")
        this.showDetailMatch(this.select, split[1])
      }
      this.isLoadingsearch = false
    },

    async saveRuleKey(sport, category, tournament, matchSource, marketId, specifier, newKey, desc){

      var path = '';
      path = sport.id+'#'+category.id+'#'+tournament.id+'#'+matchSource

      var description = ''
      description = sport.name+' > '+category.name+' > '+tournament.name+' > '+desc

      var market = (specifier != "not_spec" && specifier != "" ) ? marketId+'#'+specifier : marketId

      var payload = {
        "typeRule":'key',
        "source": matchSource,
        "scope": "event#0",
        "rule": {"market":market, "key": newKey.toString(), 'path': path, 'description': description}
      }

      await this.updateRules(payload)
          .then((res) => {

            this.snackbar.color = "success";
            this.snackbar.text = "Key aggiornata correttamente";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;
            this.cacheRules()

          }).catch((err)=>{

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });

      await this.fetchSkins()
      await this.setPreload(false)

    },

    getSpecifiers(spec){
      if(spec.includes('#')){
        var split = spec.split('#')
        if(split[1].includes(':')){
          return '('+split[1]+')'
        }else if(split[1].includes('(') && split[1].includes('.')){
          return ' ['+split[1]+'] '
        }else if(split[1] != ''){
          return split[1]
        }else {
          return ''
        }
      }else {
        return ''
      }

    },

    async freezeOddsRule(sport, category, tournament, matchSource, marketId, specifier, odds, desc) {

      if(odds.freeze){

        var market = (specifier != "not_spec" && specifier != "") ? marketId + '#' + specifier : marketId

        var payload = {
          "typeRule": 'freeze',
          "source": matchSource,
          "scope": 'event#0',
          "rule": {"detail":{"market": market.toString()}}
        }

        await this.deleteRules(payload)
            .then((res) => {

              this.snackbar.color = "success";
              this.snackbar.text = "Regola rimossa correttamente";
              this.snackbar.snackbar = true;
              this.snackbar.mode = 'multi-line';
              this.snackbar.timeout = 5000;


              odds.freeze = false
              this.cacheRules()

            }).catch((err) => {

              this.snackbar.color = "error";
              this.snackbar.text = "C'è stato un errore nel salvataggio";
              this.snackbar.snackbar = true;
              this.snackbar.mode = 'multi-line';
              this.snackbar.timeout = 5000;

              console.log(err)
            });

        await this.fetchSkins()
        await this.setPreload(false)

      }else {


        var path = '';
        path = sport.id + '#' + category.id + '#' + tournament.id + '#' + matchSource

        var description = ''
        description = sport.name+' > '+category.name+' > '+tournament.name+' > '+desc

        var market = (specifier != "not_spec" && specifier != "") ? marketId + '#' + specifier : marketId

        odds.freeze = true

        var payload = {
          "typeRule": 'freeze',
          "source": matchSource,
          "scope": "event#0",
          "rule": {"market": market.toString(), "odds": odds, 'path': path, 'description': description}
        }

        await this.updateRules(payload)
            .then((res) => {

              this.snackbar.color = "success";
              this.snackbar.text = "Quote congelate correttamente";
              this.snackbar.snackbar = true;
              this.snackbar.mode = 'multi-line';
              this.snackbar.timeout = 5000;

            }).catch((err) => {

              this.snackbar.color = "error";
              this.snackbar.text = "C'è stato un errore nel salvataggio";
              this.snackbar.snackbar = true;
              this.snackbar.mode = 'multi-line';
              this.snackbar.timeout = 5000;
              console.log(err)
            });

        await this.fetchSkins()
        await this.setPreload(false)

      }


    },

  }
}
</script>

<style scoped>

.card-in-out-enter-active, .card-in-out-leave-active {
  transition: all 0.5s;
}
.card-in-out-enter, .card-in-out-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
