import store from '../store';
import Risks from '../views/risks/Index.vue'
import Limits from '../views/risks/Limits.vue'
import UsersList from '../views/risks/UsersList'
import Logs from '../views/risks/Logs'
import EntityLimits from '../views/risks/EntityLimits'
import BetReport from '../components/risks/betReport'
import {checkauth, checkRoute} from "../Helper";
export default [
    {
        path: '/riskmanagement/reserve-func',
        name: 'risks',
        component: Risks,
        beforeEnter (to,from,next){
            checkRoute('/riskmanagement/reserve-func').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },
    {
        path: '/riskmanagement/limits',
        name: 'risks_limits',
        component: Limits,
        beforeEnter (to,from,next){
            checkRoute('/riskmanagement/limits').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },
    {
        path: '/riskmanagement/entitylimits',
        name: 'entity_limits',
        component: EntityLimits,
        beforeEnter (to,from,next){
            checkRoute('/riskmanagement/entitylimits').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },
    {
        path: '/riskmanagement/betreport',
        name: 'bet_report',
        component: BetReport,
        beforeEnter (to,from,next){
            checkRoute('/riskmanagement/betreport').then(response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }
            )
        }
    },
    {
        path: '/riskmanagement/userslist',
        name: 'users_list',
        component: UsersList,
        beforeEnter (to,from,next){
            checkRoute('/riskmanagement/userslist').then(response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }
            )
        }
    },    {
        path: '/riskmanagement/logs',
        name: 'logs',
        component: Logs,
        beforeEnter (to,from,next){
            checkRoute('/riskmanagement/logs').then(response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }
            )
        }
    },

]
