<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md12>
          <v-col cols="12">
            <span class="title-table">{{ this.$t('bets.codes') | capitalize }} </span>
            <v-card outlined elevation="1" class="pa-4">
              <v-container fluid>
                <v-row>
                  <v-col cols="6">
                    <v-data-table
                        :headers="headers"
                        :items="listMarkets.market"
                        class="elevation-1"
                        :footer-props="{'items-per-page-options': [25,50,100,200,500, -1]}"
                        :items-per-page="25"
                        :search="search"
                        item-key="unique_id"
                    >

                      <template v-slot:top>
                        <v-row align="center" class="mb-2">
                          <v-col cols="12" md="12" class="text-right mt-5">
                            <v-text-field v-model="search" :label="$t('generic.search')| capitalize" class="mx-4"
                                          hide-details outlined dense></v-text-field>
                          </v-col>
                        </v-row>
                      </template>

                      <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="`${item.id}${_.random(1, 10000)}`">
                          <td>{{ item.id }}</td>
                          <td>{{ item.name }}</td>
                          <td>
                            <v-icon @click="showMarkert(item.id)" color="primary">mdi-magnify</v-icon>
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="6">
                    <v-card v-if="Object.keys(this.marketToEdit).length !== 0" class="py-5">
                      <h2 class="text-center">{{marketNameSelected}}</h2>
                      <v-row v-for="(group, key) in marketToEdit" :key="key" class="pills">
                        <v-col cols="12">
                          <h3>{{ key | capitalize}}</h3>
                        </v-col>
                        <v-col cols="4" v-for="item in group" :key="item._id" class="editCodes">
                          <label class="bold">{{ item.outcomeName }}</label>
                          <input type="text" :value="item.code" :id="item.unique_id">
                        </v-col>
                      </v-row>
                      <v-row class="pa-4">
                        <v-btn dark class="mt-2"  @click="saveMarket()">{{ $t('generic.save') }}</v-btn>
                      </v-row>

                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<style scoped>
/* Layout styles */
.pills{
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 0px;
  box-shadow: 1px 1px 3px 2px #f0f0f0;
}
.editCodes input {
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  padding: 5px!important;
}
label.bold{
  font-weight: 500;
  padding-left: 5px;
}
.pa-4 {
  padding: 16px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.py-5 {
  padding: 10px !important;
}

/* Typography styles */
.title-table {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

/* Table styles */
.v-data-table {
  border-radius: 8px;
  overflow: hidden;
}

.v-data-table th {
  background-color: #f5f5f5;
  color: #555;
  font-weight: 600;
  text-transform: uppercase;
}

.v-data-table td {
  font-size: 0.9rem;
  color: #444;
  text-align: left;
  vertical-align: middle;
}

.v-data-table .v-btn {
  margin: 0;
  padding: 0;
}

.v-icon {
  transition: color 0.3s;
}

.v-icon:hover {
  color: #1976d2;
}

/* Input styles */
.v-text-field {
  max-width: 300px;
}

.text-right {
  text-align: right;
}
</style>
<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import _ from 'lodash'
import menuSport from "@/components/sports/menu";

export default {
  name: "CodesV2",
  data() {
    return {
      headers: [
        {text: this.$t('generic.id'), value: 'id'},
        {text: this.$t('generic.name'), value: 'name'},
        {text: this.$t('generic.actions'), value: 'actions'},
      ],
      search: '',
      searchSport: -1,
      dataGroups: {},
      dataPreview: [],
      messages: [],
      showAdd: false,
      marketToEdit: {},
      marketNameSelected: '',
      codes: [],
      groups: [],
      listMarkets: []
    }
  },

  watch: {
    getLanguage: async function (val) {
      await this.setPreload(true);
      await this.setLanguage(val);
      await this.setPreload(false);
    },

    searchSport() {
      this.getData()
    }

  },

  computed: {
    ...mapGetters({
      'getToken': 'profile/getToken',
      'getUrl': 'settings/getUrl',
      'getLanguage': 'profile/getLanguage',
      'getSports': 'sport/getSports',
      'getItemsSport': 'sport/getItems',
      'getMarkets': 'utils/getMarkets',
      'getSettingsGroupsMarket': 'utils/getSettingsGroupsMarket',
      'getProducers': 'producers/getList',
      'getPlatformId': 'rules/getPlatformId',
      'getPermissions': 'profile/getPermissions',
    }),

  },

  async created() {
    await this.setPreload(true);
    // await this.fetchSports({'language': this.getLanguage});
    await this.getSettingsGroup();
    await this.setPreload(false);
  },

  methods: {

    ...mapActions({
      'fetchSports': 'sport/fetchSports',
      'filterSport': 'sport/filterSport',
      'fetchProducers': 'producers/fetchProducers',
      'getMarketsBySport': 'utils/getMarketsBySport',
      'getAllMarkets': 'utils/getAllMarkets',
      'getOutcomeCodesByMarket': 'utils/getOutcomeCodesByMarket',
      'updateBulk': 'utils/updateBulk',
      'setPreload': 'utils/setPreload',
      'fetchSettingsGroupsMarket': 'utils/fetchSettingsGroupsMarket',
      'createNewGroupOdds': 'utils/createNewGroupOdds',
      'updateGroupOdds': 'utils/updateGroupOdds',
      'refactoringSettingsOdds': 'utils/refactoringSettingsOdds',
      'updateSingleCode': 'utils/updateSingleCode',
      'checkExistsCode': 'utils/checkExistsCode',
      'deletecodes': 'utils/deletecodes',
      'cacheSetting': 'cache/cacheQuickbet',
    }),

    getData() {
      this.dataGroups = {}
      this.getSettingsGroup()
    },

    async getSettingsGroup() {
      await this.setPreload(true);
      await this.getAllMarkets({excludeHugeMarkets: true}).then(async response => {
        if (response.data.market) {
          this.listMarkets = response.data
        }
      });
      await this.setPreload(false);
    },

    async showMarkert(id) {
      await this.getOutcomeCodesByMarket({marketId: id}).then(async response => {
        this.marketToEdit = {};
        this.groups = [];
        this.groups = response.data;
        if (response.data.length > 0) {
          await this.gruopSpread(response.data).then(async res =>{
            this.marketToEdit = res;
          })
        }
      });
    },

    async gruopSpread(data){
      return data.reduce((result, currentObj) => {
        this.marketNameSelected = currentObj.marketName;
        const spreadKey = currentObj.spread === null ? 'generic' : currentObj.spread;
        if (!result[spreadKey]) {
          result[spreadKey] = [];
        }
        result[spreadKey].push(currentObj);
        return result;
      }, {});
    },

    async saveMarket(){
      await this.setPreload(true);
      let array = [];
      let payload = {outcomeCodes:[]};
      for(const group of this.groups){
        let unique_id = group.unique_id;
        let value = document.getElementById(unique_id).value
        if(value.length > 0){
          group.code = value;
          group.platformId = this.getPlatformId;
          delete group._id
          delete group.__v
          array.push(group)
        }
      }
      payload.outcomeCodes = array;

      if(array.length > 0){
        await this.updateBulk({data: payload}).then(async response =>{
          let errors = [];
          if(response.data.success === false){
            for(const ec of response.data.errorOutcomeCodes){
              for(const error of ec.errors){
                if(error.alreadyUsedUniqueId !== error.unique_id){
                  errors.push(error)
                }
              }
            }
          }
          if(errors.length > 0){
            for(const err of errors){
              this.$swal.fire({
                title: this.$t('error'),
                text: this.$t(err.errorCode)+" : "+err.code,
                icon: 'error',
                confirmButtonText: 'OK',
              });
              return;
            }

          }
        }).catch(async error => {
          console.log(error)
        });
      }

      await this.setPreload(false);
    }

  },
}
</script>

<style scoped>

</style>
