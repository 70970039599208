<template>
    <v-text-field
            :label="label"
            v-model="localValue"
            outlined
            dense
    ></v-text-field>
</template>

<script>
    export default {
        name: 'TranslationField',
        props: {
            value: {
                type: String,
                default: '',
            },
            label: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                localValue: this.value,
            };
        },
        watch: {
            value(newValue) {
                this.localValue = newValue;
            },
            localValue(newValue) {
                this.$emit('input', newValue);
            },
        },
    };
</script>
