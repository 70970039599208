<template>
  <div>
    <div class="d-flex">
      <div class="flex-grow-1 text-left pa-3 text-help">
      </div>
      <div class="flex-grow-0 pa-3 text-help">
        {{$t('generic.totalResults')}} {{getCounts}}
      </div>
    </div>

    <v-data-table :headers="headers" :items="getEventsList" class="elevation-1 head-capitalize" :loading="getPreload" disable-pagination hide-default-footer>

      <template v-slot:item.id="{ item }">
        <span class="font-weight-bold">{{ item.id }}</span>
      </template>

      <template v-slot:item.quickbet="{ item }">
        <span class="text-uppercase">{{ item.quickbet }}</span>
      </template>

      <template v-slot:item.scheduled="{ item }">
        <span v-if="item.scheduled != null">
          <!--{{ new Date(item.scheduled).toLocaleString('it-IT', { timeZone: 'UTC' }) }}-->
        {{ new Date(item.scheduled).toLocaleString() }}

        </span>
      </template>

      <template v-slot:item.event="{ item }">
        {{getDescription(item)}}
      </template>

      <template v-slot:item.type="{ item }">
        {{$t(getEventType(item.type)) | capitalize}}
      </template>

      <template v-slot:item.sport="{ item }">
        {{ getDetail(item.sport.detail) }}
      </template>

      <template v-slot:item.category="{ item }">
        {{ getDetail(item.category.detail) }}
      </template>

      <template v-slot:item.tournament="{ item }">
        {{ getDetail(item.tournament.detail) }}
      </template>

      <template v-slot:item.status="{ item }">
        {{ $t(getStatus(item.sport_event_status._attributes.status)) | capitalize}}
      </template>

      <template v-slot:item.actions="{ item }">
        <span>
          <v-icon :title="$t('bets.detail')" v-if="getPlatform==0" @click="showDetail(item.id)">mdi-file-find</v-icon>
        </span>
        <span>
          <v-icon :title="$t('bets.detail')" v-if="getPlatform==0" @click="showDate(item)">mdi-calendar</v-icon>
        </span>
        <span>
          <v-icon :title="$t('bets.detail')" v-if="getPlatform==0" @click="showStatus(item)">mdi-eye-check-outline</v-icon>
        </span>
        <span>
          <v-icon :title="$t('bets.detail')" v-if="getPlatform==0" @click="showEvidence(item)">mdi-bookmark-multiple</v-icon>
        </span>
      </template>

    </v-data-table>

    <div class="text-center pt-3 pb-3">
      <v-pagination
          v-model="getOptions.page"
          :length="+(getCounts / getOptions.limit).toFixed(0)"
          :total-visible="20"
          @input="getNewPage()"
      ></v-pagination>
    </div>

    <EventDetail></EventDetail>

    <v-snackbar v-model="snackbar">
      {{$t(this.snackbar_message)}}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          {{$t('generic.close')}}
        </v-btn>
      </template>
    </v-snackbar>
    <v-btn
            fab
            bottom
            right
            color="secondary"
            dark
            fixed
            @click.prevent="openNew"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-dialog :value="getDialog" fullscreen hide-overlay transition="dialog-bottom-transition">

      <FormCreate v-if="operation=='FormCreate'" :key="componentKey" @message="propSetMessages" ></FormCreate><!--@event="getDataFromApi"-->

    </v-dialog>
    <div class="text-center">
      <v-dialog v-model="dialogDate" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{$t('scheduled')}}
          </v-card-title>

          <v-card-text class="pt-5">

            <v-menu
                    ref="startDatemenu"
                    v-model="startDate.menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate.date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    dense outlined
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                        v-model="startDate.date"
                        :label="$t('bets.date')+ ' '+ $t('scheduled')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense outlined

                ></v-text-field>
              </template>
              <v-date-picker
                      v-model="startDate.date"
                      no-title
                      scrollable
                      dense outlined

              >
                <v-spacer></v-spacer>
                <v-btn
                        text
                        color="primary"
                        @click="startDate.menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                        text
                        color="primary"
                        @click="$refs.startDatemenu.save(startDate.date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-menu
                    ref="startTimemenu"
                    v-model="startTime.menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="startTime.time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                        v-model="startTime.time"
                        :label="$t('hour')+' '+ $t('scheduled')"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense outlined

                ></v-text-field>
              </template>
              <v-time-picker

                      v-model="startTime.time"
                      full-width
                      @click:minute="$refs.startTimemenu.save(startTime.time)"
                      dense outlined
                      format="24hr"
              ></v-time-picker>
            </v-menu>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="confirmDate()">
              {{ $t('generic.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog v-model="dialogStatus" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{$t('bets.status')}}
          </v-card-title>

          <v-card-text class="pt-5">

            <v-select class="text-capitalize" :items="typeOfStatus" item-text="text" item-value="value"
                       :label="$t('bets.status')" dense outlined v-model="form.status"></v-select>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="confirmStatus()">
              {{ $t('generic.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center">
      <v-dialog v-model="dialogEvidence" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{$t('bets.evidence')}}
          </v-card-title>

          <v-card-text class="pt-5">

            <v-select class="text-capitalize" :items="typeOfEvidence" item-text="text" item-value="value"
                      :label="$t('bets.evidence')" dense outlined v-model="form.evidence"></v-select>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="confirmEvidence()">
              {{ $t('generic.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import _ from 'lodash';
import EventDetail from "@/components/events/eventDetail";
import axios from "axios";
const FormCreate = () => import('@/components/events/Create.vue');

export default {
  name: "eventsList",
  components: {EventDetail,FormCreate},
  data() {
    return {
      snackbar: false,
      snackbar_message: '',
      dialog: false,
      dialogDate:false,
      dialogStatus:false,
      dialogEvidence:false,
      eventToShow: {},
      headers: [
        {text: this.$t('generic.id'), value: 'id', width: '3%', sortable: false},
        {text: this.$t('quickbet'), value: 'quickbet', width: "3%"},
        {text: this.$t('bets.date'), value: 'scheduled', width: "7%"},
        {text: this.$t('bets.event'), value: 'event', width: "20%"},
        {text: this.$t('bets.type'), value: 'type', width: "3%"},
        {text: this.$t('sport'), value: 'sport', width: "5%", sortable: false},
        {text: this.$t('category'), value: 'category', width: "5%", sortable: false},
        {text: this.$t('tournament'), value: 'tournament', width: "8%", sortable: false},
        {text: this.$t('bets.status'), value: 'status', width: "8%"},
        {text: this.$t('bets.actions'), value: 'actions', sortable: false, width: "10%"},
      ],
      options: {},
      operation: '',
      componentKey: 0,
      formData:{

      },
      messages: {
        category: '',
        tournament: '',
        competitor:'',
        startDate: '',

        startTime: '',
        finishTime:'',
        addtournament: '',
        addmarket: '',
        addcompetitor : ''
      },
      startDate: {
        date: '',
        menu: false,
        modal: false,
        menu2: false,
      },
      startTime: {
        time: null,
        menu2: false,
        modal2: false,
      },
      item:{},
      form:{
        scheduled:'',
        _id:'',
        status:'',
        evidence:0
      },
      typeOfStatus: [

        {text: this.$t('nsy'), value: 1},
        {text: this.$t('inProgress'), value: 2},
        {text: this.$t('finished'), value: 3},
        {text: this.$t('cancelled'), value: 4},
        {text: this.$t('postponed'), value: 5},
        {text: this.$t('interrupted'), value: 6},
        {text: this.$t('abandoned'), value: 7},
        {text: this.$t('coverageLost'), value: 8},
        {text: this.$t('aboutToStart'), value: 9},
      ],
      typeOfEvidence: [
        {text: this.$t('yes'), value: 1},
        {text: this.$t('no'), value: 0}
      ],
    }
  },
  computed: {
    ...mapGetters({
      'getLanguage': 'profile/getLanguage',
      'getToken': 'profile/getToken',
      'getEventsList': 'event/getEventsList',
      'getPreload': 'utils/getPreload',
      'getShowDetail': 'bet/getShowDetail',
      'getOptions': 'event/getOptions',
      'getCounts': "event/getCounts",
      'getCriteria': "event/getEventsCriteria",
      'getUrl': "settings/getUrl",
      'getPlatform' : "profile/getPlatform",
      'getDialog': 'settings/getDialog',
    }),
  },

  methods: {
    ...mapActions({
      'setEventDetail': 'event/setEventDetail',
      'setShowDetail': 'event/setShowDetail',
      'fetchEventsList': 'event/fetchEventsList',
      'setPreload': 'utils/setPreload',
      'defineOdd': 'bet/defineOdd',


    }),
    ...mapMutations({
      'setDialog': 'settings/setDialog',

      'setSnackbar': 'snackbars/setSnackbar',
    }),
    async openNew() {
      this.componentKey += 1;

      //await this.resetMessages();
      //console.log(this.getOrders.indexOf(item));
      //console.log(item)
      this.operation = "FormCreate";
      //this.setMessages();
      this.setDialog(true);

    },
    async showStatus(event) {

      this.dialogStatus = true
      this.item = event._id

      this.form.status = event.sport_event_status._attributes.status
      this.form.evidence = event.evidence
      //console.log(event.scheduled)

    },
      async showEvidence(event) {

      this.dialogEvidence = true
      this.item = event._id

      this.form.status = event.sport_event_status._attributes.status
      this.form.evidence = event.evidence;
      //console.log(event.scheduled)

    },
    async showDate(event) {

      this.dialogDate = true
      this.item = event._id
      //console.log(event.scheduled)
      let data = new Date(event.scheduled)
      this.startDate.date = data.getFullYear().toString().padStart(2, "0")+"-"+(data.getMonth()+1).toString().padStart(2, "0")+"-"+data.getDate()
      this.startTime.time = data.getHours().toString().padStart(2, "0")+":"+(data.getMinutes()).toString().padStart(2, "0")
    },
    async confirmStatus(){
      //this.form.scheduled = `${this.startDate.date} ${this.startTime.time}`
      this.form._id = this.item
      let url = "events/store-status";
      let payload = this.form;
      //console.log(this.getCriteria)
      this.setPreload(true);
      this.messages = [];
      axios.post(`${this.getUrl}${url}`, payload, {'headers': {'Authorization': `Bearer ${this.getToken}`}})

              .then(async(res) => {

                //this.getCriteria
                console.log(this.getCriteria)

                await this.fetchEventsList(this.getCriteria)
                await this.setPreload(false);

                this.dialogStatus=false
                this.snackbar.color = "success";
                this.snackbar.text = "success";
                this.snackbar.snackbar = true;
                this.snackbar.mode = 'multi-line';
                this.snackbar.timeout = 5000;



              })
              .catch(err => {
                if (err.response) {
                  let errors = err.response.data.error;
                  if (errors) {
                    console.log(errors);
                    errors.forEach(value => {

                      this.messages[value.param] = value.msg
                    })

                  }


                  this.snackbar.color = "error";
                  this.snackbar.text = err.message;
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;
                  this.preload = false;
                  if (err.response.status === 401) {
                    this.setLogout();
                    this.$router.push('/login')
                  }
                }

              });
    },
    async confirmEvidence(){
      //this.form.scheduled = `${this.startDate.date} ${this.startTime.time}`
      this.form._id = this.item
      let url = "events/store-evidence";
      let payload = this.form;
      //console.log(this.getCriteria)
      this.setPreload(true);
      this.messages = [];
      axios.post(`${this.getUrl}${url}`, payload, {'headers': {'Authorization': `Bearer ${this.getToken}`}})

          .then(async(res) => {

            //this.getCriteria
            console.log(this.getCriteria)

            await this.fetchEventsList(this.getCriteria)
            await this.setPreload(false);

            this.dialogEvidence=false
            this.snackbar.color = "success";
            this.snackbar.text = "success";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;



          })
          .catch(err => {
            if (err.response) {
              let errors = err.response.data.error;
              if (errors) {
                console.log(errors);
                errors.forEach(value => {

                  this.messages[value.param] = value.msg
                })

              }


              this.snackbar.color = "error";
              this.snackbar.text = err.message;
              this.snackbar.snackbar = true;
              this.snackbar.mode = 'multi-line';
              this.snackbar.timeout = 5000;
              this.preload = false;
              if (err.response.status === 401) {
                this.setLogout();
                this.$router.push('/login')
              }
            }

          });
    },
    async confirmDate(){
      //this.form.scheduled = `${this.startDate.date} ${this.startTime.time}`
      //2022-07-15T15:00:00.000+00:00
      //this.form.scheduled = `${this.startDate.date}T${this.startTime.time}:00.000+00Z`
      this.form.scheduled = `${this.startDate.date} ${this.startTime.time}:00`
      this.form._id = this.item
      let url = "events/store-scheduled";
      let payload = this.form;
      //console.log(this.getCriteria)
      this.setPreload(true);
      this.messages = [];
      axios.post(`${this.getUrl}${url}`, payload, {'headers': {'Authorization': `Bearer ${this.getToken}`}})

              .then(async(res) => {

                //this.getCriteria
                console.log(this.getCriteria)

                await this.fetchEventsList(this.getCriteria)
                await this.setPreload(false);
                this.dialogDate=false

                this.snackbar.color = "success";
                this.snackbar.text = "success";
                this.snackbar.snackbar = true;
                this.snackbar.mode = 'multi-line';
                this.snackbar.timeout = 5000;



              })
              .catch(err => {
                if (err.response) {
                  let errors = err.response.data.error;
                  if (errors) {
                    console.log(errors);
                    errors.forEach(value => {

                      this.messages[value.param] = value.msg
                    })

                  }


                  this.snackbar.color = "error";
                  this.snackbar.text = err.message;
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;
                  this.preload = false;
                  if (err.response.status === 401) {
                    this.setLogout();
                    this.$router.push('/login')
                  }
                }

              });
    },
    async showDetail(event) {

      await this.setShowDetail(false)
      await this.setPreload(true)

      let url = `${this.getUrl}events/getevents/${event}`;
      await axios.get(`${url}`, {'headers': {'Authorization': `Bearer ${this.getToken}`}})
      .then(async (res) => {
        await this.setEventDetail(res.data.events)
      })
      .catch((error) => {
        console.log(error);
      });

      this.$root.$emit('getMarkets')
      await this.setPreload(false)
      await this.setShowDetail(true)

    },
    async getNewPage() {
      this.getCriteria.page = this.getOptions.page
      this.getCriteria.limit = this.getOptions.limit
      this.getCriteria.platform_id = this.getOptions.platform_id

      await this.setPreload(true);
      await this.fetchEventsList(this.getCriteria)
      await this.setPreload(false);
    },
    propSetMessages(text){
      this.snackbar=true
      this.snackbar_message=text
      this.setPreload(false)
      /*this.snackbar.color = "success";
      this.snackbar.text = text;
      this.snackbar.snackbar = true;
      this.snackbar.mode = 'multi-line';
      this.snackbar.timeout = 5000;*/

    },
    getDescription(item){
      if(item.type == 'match'){
        var home = _.find(item.competitors[0].detail, {'lang':this.getLanguage})
        var away = _.find(item.competitors[1].detail, {'lang':this.getLanguage})
        return home.text+' - '+away.text
      }else if(item.type == 'antepost'){
        var tournament = _.find(item.tournament.detail, {'lang':this.getLanguage})
        return 'Antepost '+tournament.text
      }
    },

    getDetail(detail){
      var detail = _.find(detail, {'lang':this.getLanguage})
      return detail.text
    },

    getStatus(status){
      switch (status) {
        case 1:
          return 'nsy'
        case 2:
          return 'inProgress'
        case 3:
          return 'finished'
        case 4:
          return 'cancelled'
        case 5:
          return 'postponed'
        case 6:
          return 'interrupted'
        case 7:
          return 'abandoned'
        case 8:
          return 'coverageLost'
        case 9:
          return 'aboutToStart'
      }
    },

    getEventType(type){
      switch (type){
        case 'match':
          return 'prematch'
        case 'antepost':
          return 'antepost'
      }
    }
  }

}
</script>

<style scoped>

</style>