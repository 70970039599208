<template>
    <div id="entityLimits-page">
        <v-container fluid v-if="getPlatformId > 0">
            <v-layout row>
                <v-flex md12>
                    <v-col cols="12">
                        <v-card outlined elevation-0>
                            <v-row class="title-row">
                                <v-col md="12">
                                    <v-row class="ml-1">
                                        <v-col md="4">
                                            <h2 class="ml-1">{{ getPlatformName()}}</h2>
                                        </v-col>
                                        <v-col md="2" offset="1">

                                        </v-col>
                                        <v-col md="2">

                                        </v-col>

                                        <v-col md="2" class="container-action-btn">
                                            <v-btn color="primary" v-if="enableRulesListComponent"
                                                   @click="showAddRuleComponent()"
                                                   class="mart-2 actionButton">
                                                {{ $t('risk.addRule') }}
                                            </v-btn>

                                            <v-btn color="primary" v-if="enableCreateRuleComponent"
                                                   @click="showRuleComponent()"
                                                   class="mart-2 actionButton">
                                                {{ $t('risk.showRules') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-flex>
            </v-layout>
        </v-container>
        <entity-limit-create v-if="enableCreateRuleComponent"></entity-limit-create>
        <entity-limit-list v-if="enableRulesListComponent"></entity-limit-list>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import _ from "lodash";

    import EntityLimitCreate from '@/components/risks/entityLimitCreate';
    import EntityLimitList from "@/components/risks/entityLimitList";

    export default {
        name: "EntityLimits",
        components: {EntityLimitList, EntityLimitCreate},
        data() {
            return {
                enableCreateRuleComponent: false,
                enableRulesListComponent: true
            }
        },
        computed: {
            ...mapGetters({
                'getPlatformId': "rules/getPlatformId",
                'getSkinFilters': 'skins/getSkinFilters',
                'getPlatform': "profile/getPlatform",
            }),
        },

        async mounted() {

        },
        methods: {
            ...mapActions({}),

            showRuleComponent() {
                this.enableCreateRuleComponent = false;
                this.enableRulesListComponent = true;
            },
            showAddRuleComponent() {
                this.enableCreateRuleComponent = true;
                this.enableRulesListComponent = false;
            },
            getPlatformName() {
                if (this.getPlatformId == 0) {
                    return "Manager";
                } else {
                    let platform = _.find(this.getSkinFilters, {platform_id: this.getPlatformId})
                    return (platform && platform.name) ? platform.name : 'Error name skin'
                }
            }
        }
    }
</script>

<style scoped>
    #entityLimits-page .title-row {
        height: 85px !important;
        padding: 10px !important;
    }

    .actionButton {
        margin-left: 30px !important;
    }

    .container-action-btn {
        display: flex !important;
    }
</style>
