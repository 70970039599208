import axios from "axios";
import _ from "lodash";

const state = {

    list: [],

};

const getters = {

    getList: state => {
        return state.list;
    },

};

const actions = {

    async fetchList (context,payload) {

        let url = `${context.rootGetters['settings/getUrlApi']}printodds/multimedia/list`;
        await axios.post(`${url}`,payload,{ 'headers': { 'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`} })
            .then( res => {


                context.commit('setList',res.data.data);

            })
            .catch(err => {
                console.log(err);

                if(err.response.status===401){
                    context.commit('profile/setLogout',null, {root:true});
                    window.location.href = '/#/login';
                }

            });
    },



};

const mutations = {
    setList(state,payload) {
        state.list = payload;
    }

};



export default {
    namespaced : true,
    state,
    mutations,
    getters,
    actions
}
