<template>
    <div >
        <v-navigation-drawer
                fixed
                v-model="navState"
                :clipped="$vuetify.breakpoint.lgAndUp"
                app
                :mini-variant.sync="mini"
                expand-on-hover
        >
            <v-list dense>
                <v-list-item  >

                    <v-list-item-content>
                        <v-list-item-title>

<!--                            Ciao {{ getName }}-->
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <template v-for="item in items">
                    <v-row
                            v-if="item.heading"
                            :key="item.heading"
                            align="center"
                    >
                        <v-col cols="6">
                            <v-subheader v-if="item.heading">
                                {{ item.heading }}
                            </v-subheader>
                        </v-col>
                        <v-col
                                cols="6"
                                class="text-center"
                        >
                            <a
                                    href="#!"
                                    class="body-2 black--text"
                            >EDIT</a>
                        </v-col>
                    </v-row>
                    <v-list-group
                            v-else-if="item.children"
                            :key="item.text"
                            v-model="item.model"
                            :prepend-icon="item.model ? item.icon : item['icon-alt']"
                            append-icon=""
                    >
                        <template v-slot:activator>
                            <v-list-item :to="item.path">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.text }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <v-list-item
                                v-for="(child, i) in item.children"
                                :key="i"
                                link
                                :to="child.path"
                        >
                            <v-list-item-action v-if="child.icon">
                                <v-icon v-if="child.icon">{{ child.icon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ child.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item
                            v-else
                            :key="item.text"
                            link
                            :to="item.path"
                            active-class="highlighted"
                            :class="item.path === $route.path ? 'highlighted' : ''"
                    >
                        <v-list-item-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <v-list-item @click="logout" >
                    <v-list-item-action>
                        <v-icon>power_settings_new</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            Logout
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>



    </div>
</template>

<script>
    import {mapGetters,mapMutations} from 'vuex';
    import axios from "axios";
    export default {
        props: {
            source: String,
        },

        data(){
            return {
                dialog: false,
                drawer: null,
                sport:{},
                menusport:{},
                mini: true,
                //changeLanguage:{},

                // usersa: this.$vuetify.lang.t('$vuetify.front.users'),
                items: [
                    {icon: 'mdi-contacts', text: 'Utenti', path:'/users' },
                    {icon: 'mdi-cellphone-link', text: 'Skin',path:'/skins'},
                    {icon: 'mdi-trending-up', text: 'Quote',path:'/odds'},
                    //{icon: 'mdi-settings', text: 'Settings'}
                    /*{
                        icon: 'mdi-chevron-up','icon-alt': 'mdi-chevron-down',
                        text: 'Labels',

                        children: [
                            { text: 'Create label',path:'users' },
                        ],
                    },*/
                    {
                        icon: 'mdi-soccer',
                        'icon-alt': 'mdi-soccer',
                        text: 'Sport',
                        model: false,
                        path:'/sports',
                        //children: this.changeLanguage,
                    },

                ],


            }
        },
        mounted() {

            /*let urlsport = "sports";
            axios.get(`${this.getUrl}${urlsport}`,{ 'headers': { 'Authorization': `Bearer ${this.getToken}`} })

                .then( res => {

                     this.sports = res.data.data;

                })
                .then(()=>{

                    let menus = []
                    if(this.sports.length>0) {
                        this.sports.forEach(item => {
                            //console.log(item);
                            let filter = item.detail.filter(itemF => {
                                return itemF.lang == this.getLanguage
                            })
                            //console.log(filter)
                            let json = {'path': `/categories/${item.id}`, 'text': filter[0].text}

                            menus.push(json);
                        });
                        //console.log(menus)
                        let polifyl = this.items.find(find =>
                            find.text=="Sport"
                        );
                        polifyl.children = menus;

                        //console.log(polifyl)

                        //this.menusport= menus;
                        // console.log(this.sports)
                    }
                })
                .catch(err => {
                    console.log(err);

                    /!*if(err.response.status===401){
                        this.setLogout();
                        this.$router.push('/login')
                    }*!/
                });*/
        },
        watch:{
            /*getLanguage(newvalue,oldvalue){
                //console.log(`to  ${newvalue} from ${oldvalue}` )
                let menus = []
                if(this.sports.length>0) {
                    this.sports.forEach(item => {
                        //console.log(item);
                        let filter = item.detail.filter(itemF => {
                            return itemF.lang == newvalue
                        })
                        //console.log(filter)
                        let json = {'path': `/categories/${item.id}`, 'text': filter[0].text}

                        menus.push(json);
                    });
                    let polifyl = this.items.find(find =>
                        find.text=="Sport"
                    );
                    polifyl.children = menus;
                }

            }*/
        },
        /*watch:{
            changeLanguage: (val) => {
                console.log(val);
                let menus = []
                if(this.sports.length>0) {
                    this.sports.forEach(item => {
                        //console.log(item);
                        let filter = item.detail.filter(itemF => {
                            return itemF.lang == val
                        })
                        //console.log(filter)
                        let json = {'path': item.id, 'text': filter[0].text}

                        menus.push(json);
                    });
                    //console.log(menus)
                    let polifyl = this.items.find(find =>
                        find.text=="Sport"
                    );
                    polifyl.children = menus;

                    //console.log(polifyl)

                    //this.menusport= menus;
                    // console.log(this.sports)
                }
            }
        },*/
        computed : {
            // drawer(){
            //     return this.$store.state.prova.drawer;
            //
            // },
            ...mapGetters({
                'getDrawer':'settings/getDrawer',
                //'getPermission':'profile/getPermission',
                'getName':'profile/getName',
                'getLanguage':'profile/getLanguage',
                'getUrl':'settings/getUrl',
                'getToken':'profile/getToken',
            }),
            changeLanguage:function(){
                //console.log(this.getLanguage)
                //return this.getLanguage;
                let menus = []
                if(this.sports.length>0) {
                    this.sports.forEach(item => {
                        //console.log(item);
                        let filter = item.detail.filter(itemF => {
                            return itemF.lang == this.getLanguage
                        })
                        //console.log(filter)
                        let json = {'to': `categories/${item.id}`, 'text': filter[0].text}

                        menus.push(json);
                    });

                }
                return menus;
            },
            navState:{
                get : function(){

                    return this.getDrawer;
                },
                set : function(newvalue){
                    return this.getDrawer;
                }
            }
            // drawer(){
            //     return this.$store.getters.getDrawer;
            //     // get :function(){
            //     //     return this.$store.state.prova.drawer;
            //     // } ,
            //     // set:function(){
            //     //     this.$store.state.prova.drawer = !this.$store.state.prova.drawer
            //     // }
            // }
        },
        methods:{
            ...mapMutations({
                'setLogout': 'profile/setLogout',
            }),
            logout(){
                this.setLogout();
                this.$router.push('/login')
            },

        },

    }
</script>
