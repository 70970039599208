import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/i18n/en.json';
import pt from '@/i18n/pt.json';
import es from '@/i18n/es.json';
import fr from '@/i18n/fr.json';
import it from '@/i18n/it.json';


Vue.use(VueI18n);

const messages = {
    en: {
        ...en
    },
    es: {
        ...es
    },
    fr: {
        ...fr
    },
    pt: {
        ...pt
    },
    it: {
        ...it
    }
};

export const i18n = new VueI18n({
    locale: localStorage.getItem('language') || 'en',
    fallbackLocale: "en",
    messages
});
