<template>
  <div>
    <v-container fluid>
      <v-layout row>

<!--        <v-flex md12>-->
<!--          <menuBetConsole></menuBetConsole>-->
<!--        </v-flex>-->

        <v-flex md12>
          <v-col cols="12">
            <span class="title-table">{{ this.$t('bets.bonus') | capitalize}}</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-flex md9>
                    <v-col cols="12" v-if="getPlatformId > 0 && rulesBonus.length == 0">
                      <v-btn block depressed color="secondary" class="mr-2" @click="importFromManager()">
                        IMPORTA DA MANAGER
                      </v-btn>
                    </v-col>
                    <v-col col="12" class="pt-0 pb-0">
                      <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Cerca"
                          single-line
                          hide-details
                      ></v-text-field>
                      <br/>
                      <v-data-table
                          :search="search"
                          :headers="headers"
                          :items="rulesBonus"
                          :items-per-page="30"
                          class="elevation-1"
                      >
                        <template v-slot:item.type="{ item }">
                          Bonus Carrello
                        </template>
                        <template v-slot:item.source="{ item }">
                          {{getProducer(item.source)}}
                        </template>
                        <template v-slot:item.detail.percentage="{ item }">
                          {{ item.detail.percentage }} %
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon
                              small
                              class="mr-2"
                              @click="editItem(item)"
                          >
                            mdi-pencil
                          </v-icon>
                          <v-icon
                              small
                              @click="deleteItem(item)"
                          >
                            mdi-delete
                          </v-icon>
                        </template>

                      </v-data-table>
                    </v-col>
                  </v-flex>
                  <v-flex md3>
                    <span class="title-table">Aggiungi un nuovo bonus</span>
                    <v-col cols="12" class="pb-0">
                      <v-autocomplete
                          v-model="producerSelected"
                          :items="getProducers"
                          item-text="name"
                          item-value="id"
                          outlined
                          dense
                          chips
                          small-chips
                          :label="this.$t('generic.context')"
                          deletable-chips
                          @change="getBonus()"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-text-field
                          label="Valore Molteplicità"
                          placeholder="ad es. 1"
                          outlined
                          dense
                          v-model="newMultiplicity"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-text-field
                          label="Valore Percentuale"
                          placeholder="ad es. 5"
                          outlined
                          dense
                          v-model="newPercentage"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-text-field
                          label="Valore minimo quota"
                          placeholder="ad es. 101"
                          outlined
                          dense
                          v-model="newMinOddValue"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-btn block depressed color="secondary" class="mr-2" @click="createNewRule">
                        {{ $t('generic.add') }}
                      </v-btn>
                    </v-col>
                  </v-flex>

                </v-row>
              </v-container>

            </v-card>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>

    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'"
                :timeout="snackbar.timeout" :vertical="snackbar.mode === 'vertical'">
      {{ snackbar.text }}
      <v-btn dark text @click="snackbar.snackbar = false">
        Chiudi
      </v-btn>
    </v-snackbar>

    <v-dialog persistent v-model="dialog" width="1000px">
      <v-card>
        <v-card-title class="grey lighten-4 py-4 title">
          {{ titleDialog }}
        </v-card-title>
        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap>
            <p>
              Aggiorna il valori della seguente regola del bonus
            </p>

            <v-flex xs12>
              <v-text-field label="Molteplicità" v-model="formToSubmit.multiplicity" outlined></v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-text-field label="Percentuale" v-model="formToSubmit.percentage" outlined></v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-text-field label="Valore minimo quota" v-model="formToSubmit.minOddValue" outlined></v-text-field>
            </v-flex>

          </v-layout>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="dialog = false">Indietro</v-btn>
          <v-btn color="secondary" @click.prevent="submitRule">Conferma</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirm" max-width="550">
      <v-card>

        <v-card-title class="headline">{{ titleDialog }}</v-card-title>

        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap style="line-height: 50px">
            <h4>Desideri eliminare la regola selezionata ?</h4>
          </v-layout>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="dialogConfirm = false">
            Annulla
          </v-btn>

          <v-btn color="secondary" @click="submitDelete">
            Conferma
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>
<style>

</style>
<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import _ from 'lodash'
import menuBetConsole from "@/components/betConsole/menu";

export default {
  name: "Bonus",

  data() {
    return {
      search: '',
      rulesBonus: [],
      newMultiplicity: null,
      newPercentage: null,
      newMinOddValue: null,
      producerSelected: 0,
      dialog: false,
      dialogConfirm: false,
      titleDialog: "",
      itemToEdit: {
        detail: {
          multiplicity: null,
          percentage: null,
          minOddValue: null,
        }
      },
      formToSubmit: {},
      typeRule: '',
      source: '',
      scope: '',
      snackbar: {
        snackbar: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
      },
      headers: [
        {text: 'Tipo', value:'type'},
        {text: 'Contesto', value:'source'},
        {text: 'Molteplicità', value: 'detail.multiplicity'},
        {text: 'Percentuale', value: 'detail.percentage'},
        {text: 'Valore Minimo Quota', value: 'detail.minOddValue'},
        {text: 'Azioni', value: 'actions', sortable: false},
      ],

    }
  },

  components: {menuBetConsole},

  computed: {
    ...mapGetters({
      'getToken': 'profile/getToken',
      'getUrl': 'settings/getUrl',
      'getUrlApi': 'settings/getUrlApi',
      'getPreload': 'utils/getPreload',
      'getPlatformId': 'rules/getPlatformId',
      'getManager': 'skins/getManager',
      'getListSkins': 'skins/getList',
      'getProducers': 'producers/getList',
    }),


  },
  async created() {
    await this.setPreload(true);
    await this.fetchSkins()
    // await this.fetchProducers();
    await this.getRulesBonus(this.getPlatformId, this.producerSelected)
    await this.setPreload(false);
  },


  methods: {

    ...mapActions({
      'setPreload': 'utils/setPreload',
      'updateRules': 'skins/updateRules',
      'deleteRules': 'skins/deleteRules',
      'fetchSkins': 'skins/fetchSkins',
      'importFromManagerRules': 'skins/importFromManagerRules',
      'fetchProducers': 'producers/fetchProducers',
      'cacheRules': 'cache/cacheRules',
    }),

    ...mapMutations({
      'setLanguage': 'profile/setLanguage',
    }),


    async getRulesBonus(platformId, producer){
      var vue = this
      var skin = await _.find(vue.getListSkins, {"platform_id": platformId});
      vue.rulesBonus = _.filter(skin.rules,(rule) =>{
        return (rule.type == 'cart_bonus' && rule.scope == 'cart' && rule.source.includes('#'+producer.toString()))
      })
    },

    async importFromManager(){

      var vue = this

      var payload = {
        "typeRule": "cart_bonus",
        "scope": "cart"
      }

      await this.importFromManagerRules(payload)
          .then((res) => {
            console.log('Tutto ok')

            this.snackbar.color = "success";
            this.snackbar.text = "Regole importate con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            setTimeout(function () {

              this.newMultiplicity = null
              this.newPercentage = null
              this.newMinOddValue = null
              vue.getRulesBonus(vue.getPlatformId, vue.producerSelected)


            }, 500);

          }).catch((err) => {

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });

      await this.fetchSkins()
      await this.setPreload(false)

    },
    getProducer(desc){
      var split = desc.split('#')
      switch (+split[1]){
        case 0:
          return 'Prematch';
        case 1:
          return 'Live'
      }
    },

    async createNewRule() {

      var vue = this
      vue.formToSubmit = {}
      vue.typeRule = 'cart_bonus'
      vue.scope = 'cart'
      vue.source = vue.newMultiplicity+'#'+vue.producerSelected

      if (vue.newMultiplicity === '' || vue.newMultiplicity == null) {
        alert('Errore: Inserire una molteplicità');
        return
      }

      if (vue.newPercentage === '' || vue.newPercentage == null) {
        alert('Errore: Inserire valore percentuale');
        return
      }

      if (vue.newMinOddValue === '' || vue.newMinOddValue == null) {
        alert('Errore: Inserire valore quota minima');
        return
      }

      if (vue.producerSelected === '' || vue.producerSelected == null) {
        alert('Errore: Inserire producer');
        return
      }

      var payload = {
        "typeRule": vue.typeRule,
        "source": vue.source,
        "scope": vue.scope,
        "rule": {"multiplicity": vue.newMultiplicity, "percentage": vue.newPercentage, 'minOddValue': vue.newMinOddValue}
      }

      await this.updateRules(payload)
          .then(async (res) => {
            console.log('Tutto ok')

            this.snackbar.color = "success";
            this.snackbar.text = "Regola aggiornata con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            await this.cacheRules()
            setTimeout(function () {

              this.newMultiplicity = null
              this.newPercentage = null
              this.newMinOddValue = null
              vue.getRulesBonus(vue.getPlatformId, vue.producerSelected)


            }, 500);

          }).catch((err) => {

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });

      await this.fetchSkins()
      await this.setPreload(false)

    },

    editItem(item) {

      var vue = this;
      vue.dialog = true;

      vue.titleDialog = "Modifica Impostazioni Bonus";

      vue.itemToEdit = item;

      vue.typeRule = item.type;
      vue.source = item.source;
      vue.scope = item.scope;

      vue.formToSubmit = {
        multiplicity: vue.itemToEdit.detail.multiplicity,
        percentage: vue.itemToEdit.detail.percentage,
        minOddValue: vue.itemToEdit.detail.minOddValue,
        old_rule: item.detail
      }

    },

    deleteItem(item) {

      var vue = this;
      vue.dialogConfirm = true;

      vue.titleDialog = "Elimina regola";

      vue.itemToEdit = item;


      vue.typeRule = item.type;
      vue.source = item.source;
      vue.scope = item.scope;

    },

    async submitRule() {

      var vue = this;
      await this.setPreload(true);

      var payload = {
        "typeRule": this.typeRule,
        "source": this.source,
        "scope": this.scope,
        "rule": this.formToSubmit
      }

      await this.updateRules(payload)
          .then(async (res) => {
            console.log('Tutto ok')

            this.snackbar.color = "success";
            this.snackbar.text = "Regola aggiornata con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            await this.cacheRules()

          }).catch((err) => {

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });


      await this.fetchSkins()
      await this.getRulesBonus(this.getPlatformId, this.producerSelected)
      vue.resetFields()
      vue.dialog = false;
      await this.setPreload(false)
    },

    async submitDelete() {

      var vue = this;
      await this.setPreload(true);

      var payload = {
        "typeRule": this.typeRule,
        "source": this.source,
        "scope": this.scope,
        "rule": this.itemToEdit
      }

      await this.deleteRules(payload)
          .then(async (res) => {
            console.log('Tutto ok')

            this.snackbar.color = "success";
            this.snackbar.text = "Regola aggiornata con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;
            await this.cacheRules()

          }).catch((err) => {

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });


      await this.fetchSkins()
      await this.getRulesBonus(this.getPlatformId, this.producerSelected)
      vue.resetFields()
      vue.dialogConfirm = false;
      await this.setPreload(false)
    },


    resetFields() {

      var vue = this;

      vue.itemToEdit = {
        detail: {
          multiplicity: null,
          percentage: null,
          minOddValue: null,
        }
      }

      vue.formToSubmit = {}
      vue.typeRule = ''
      vue.source = ''
      vue.scope = ''

    },

    async getBonus(){
      await this.setPreload(true);
      if(this.producerSelected != null){
        await this.getRulesBonus(this.getPlatformId, this.producerSelected)
      }
      await this.setPreload(false);
    }

  },
}
</script>

<style scoped>

</style>
