<template>
    <div id="combinableMarketsList">
        <v-container fluid>
            <v-layout row>
                <v-flex md12>
                    <v-col cols="12">
                        <v-card outlined elevation-0>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12">

                                        <div class="title-table" style="font-size: 16px;">
                                            {{this.$t('combinableMarkets') | capitalize}}
                                        </div>

                                        <v-btn elevated color="red" style="color: white"
                                               @click="deleteCombinableMarketsForPlatform()">
                                            {{this.$t('deleteAll')}}
                                        </v-btn>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-progress-linear indeterminate color="secondary darken-2"
                                                           v-if="getPreload"></v-progress-linear>
                                        <v-simple-table dense class="table-theme border-all-theme">
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th class="text-left">{{$t('sportId')}}</th>
                                                    <th class="text-left">{{$t('sportName')}}</th>
                                                    <th class="text-left">{{$t('categoryId')}}</th>
                                                    <th class="text-left">{{$t('categoryName')}}</th>
                                                    <th class="text-left">{{$t('tournamentId')}}</th>
                                                    <th class="text-left">{{$t('tournamentName')}}</th>
                                                    <th class="text-left">{{$t('marketIds')}}</th>

                                                    <th class="text-left" style="text-transform: capitalize">
                                                        {{$t('generic.edit')}}
                                                    </th>
                                                    <th class="text-left" style="text-transform: capitalize">
                                                        {{$t('generic.delete')}}
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in combinableMarkets" :key="item._id">
                                                    <td :title="item.sportId">{{ item.sportId }}</td>
                                                    <td>{{ item.sportName }}</td>
                                                    <td>{{ item.categoryId }}</td>
                                                    <td>{{ item.categoryName }}</td>
                                                    <td>{{ item.tournamentId }}</td>
                                                    <td>{{ item.tournamentName }}</td>

                                                    <td>{{item.markets}}</td>

                                                    <td class="actions-element">
                                                        <v-icon title="Edit" style="cursor:pointer"
                                                                @click="editThisTournament(item)">
                                                            check_circle
                                                        </v-icon>
                                                    </td>

                                                    <td class="actions-element">
                                                        <v-icon title="Delete" style="cursor:pointer"
                                                                @click="removeCombinableMarketsForTournament(item)">
                                                            mdi-trash-can
                                                        </v-icon>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from 'vuex';

    export default {
        name: "combinableMarketsList",

        data() {
            return {
                combinableMarkets: []
            }
        },

        components: {},

        watch: {},
        computed: {
            ...mapGetters({
                'getPreload': 'utils/getPreload',
                'getPlatformId': 'rules/getPlatformId',
                'getPlatform': 'profile/getPlatform'
            }),
        },
        async created() {

        },
        async mounted() {
            await this.loadCombinableMarkets();

        },
        methods: {

            ...mapActions({
                'getCombinableMarketsByPlatformId': 'market/getCombinableMarketsByPlatformId',
                'deleteCombinableMarketsById': 'market/deleteCombinableMarketsById',
                'deleteCombinableMarketsByPlatformId': 'market/deleteCombinableMarketsByPlatformId'
            }),

            ...mapMutations({
                'setLanguage': 'profile/setLanguage',
            }),

            async deleteCombinableMarketsForPlatform() {

                const vm = this;

                const messageWarning = this.$t('generic.areYouSureToDeleteCombinableMarketsForYourPlatform');

                let isExecuted = confirm(messageWarning);

                if (isExecuted === true) {
                    try {
                        const platformId = vm.getPlatformId;

                        if (platformId == null || platformId == '0' || platformId == 0) {
                            return;
                        }

                        const response = await vm.deleteCombinableMarketsByPlatformId({platformId: platformId})

                        await this.loadCombinableMarkets();

                        this.$swal.fire({
                            position: "center",
                            icon: "success",
                            title: this.$t('successOperation'),
                            showConfirmButton: false,
                            timer: 1000
                        });

                    } catch (e) {
                        console.log(e);
                        vm.$swal.fire(vm.$t('operationNotPerformedCorrectly'), '', 'error');
                    }
                }
            },

            async loadCombinableMarkets() {

                const platformId = this.getPlatformId
                const combinableMarkets = await this.getCombinableMarketsByPlatformId({platformId: platformId})

                this.combinableMarkets = combinableMarkets && combinableMarkets.combinableMarkets != null ? combinableMarkets.combinableMarkets : [];

            },

            async removeCombinableMarketsForTournament(item) {
                const vm = this;

                const id = item._id;

                try {
                    const response = await this.deleteCombinableMarketsById({_id: id});

                    await this.loadCombinableMarkets();

                    this.$swal.fire({
                        position: "center",
                        icon: "success",
                        title: vm.$t('successOperation'),
                        showConfirmButton: false,
                        timer: 1000
                    });

                } catch (e) {
                    console.log(e)
                    vm.$swal.fire(vm.$t('operationNotPerformedCorrectly'), '', 'error');
                }

            },

            editThisTournament(item) {

                const sportId = item.sportId;
                const sportName = item.sportName;
                const categoryId = item.categoryId;
                const categoryName = item.categoryName;
                const tournamentId = item.tournamentId;
                const tournamentName = item.tournamentName;

                const event = {
                    sportId: sportId,
                    sportName: sportName,
                    categoryId: categoryId,
                    categoryName: categoryName,
                    tournamentId: tournamentId,
                    tournamentName: tournamentName
                }

                this.$root.$emit('editCombinableMarketEvent', event);
            }

        },
    }
</script>

<style scoped>

    .v-icon.notranslate.v-icon--link.v-icon--dense.material-icons.theme--light.active.featuredTournament {
        color: red !important;
    }

    .buttonAction {

        max-width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
    }
</style>
