<template>
  <div id="list-templates">
    <v-container fluid fill-height>
      <v-layout justify-center align-center>
        <v-card width="100%" outlined elevation-0>
          <v-card-title>
            {{ $t('generic.printodds') }}

            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="search" label="Cerca.."></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="listTemplates" :search="search" item-key="_id"
                        class="elevation-0">
            <template v-slot:body="{ items }">
              <tbody>
              <tr v-for="(item,index) in items" :key="index">

                <td>{{ item.templateName }}</td>
                <td width="120">
                  <v-icon class="mr-0" @click="openEdit(item)">
                    mdi-briefcase-edit
                  </v-icon>
                  <v-icon class="mr-0" @click="deleteItem(item)">
                    mdi-trash-can
                  </v-icon>
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>

        <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color"
                    :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout"
                    :vertical="snackbar.mode === 'vertical'">
          {{ snackbar.text }}
          <v-btn dark text @click="snackbar.snackbar = false">
            {{$t('close')}}
          </v-btn>
        </v-snackbar>

        <v-btn fab bottom right color="secondary" dark fixed @click.prevent="openNew">
          <v-icon>add</v-icon>
        </v-btn>
      </v-layout>
    </v-container>

    <v-dialog persistent v-model="dialog" width="1000px">
      <v-card>
        <v-card-title class="grey lighten-4 py-4 title">
          {{ titleDialog }}
        </v-card-title>
        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap>

            <v-flex xs6>
              <v-text-field label="Nome" v-model="form.templateName"
                            :error-messages="messages.templateName"></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-select
                      :error-messages="messages.sportId"
                      v-model="form.sportId"
                      :items="getItemsSport"
                      item-text="name"
                      item-value="id"
                      :label="$t('generic.sport')"
                      @change="sportSelect">
              </v-select>

            </v-flex>

            <v-flex xs6>
              <v-select
                      v-model="form.orientation"
                      :items="orientations"
                      :label="textOrintation"
                      item-value="value"
                      item-text="name"
                      :error-messages="messages.orientation">
              </v-select>
            </v-flex>
            <v-flex xs6>
              <v-select
                      v-model="form.sheetType"
                      :items="sheets"
                      :label="textSheet"
                      item-value="value"
                      item-text="size"
                      :error-messages="messages.sheetType">
              </v-select>
            </v-flex>
            <v-flex xs12>
              <v-autocomplete
                      v-model="form.marketsUniqueIds"
                      :items="sportMarkets"
                      :label="textGroup"
                      chips
                      deletable-chips
                      multiple
                      small-chips
                      item-value="unique_id"
                      item-text="nameWithNumberOutcomes"
                      @change="calculateTotalOutcomes">
              </v-autocomplete>
            </v-flex>
            <v-flex xs12>
              <v-autocomplete
                      v-model="form.categoriesIds"
                      :items="sportCategories"
                      :label="$t('country')"
                      item-value="id"
                      item-text="name"
                      chips
                      deletable-chips
                      multiple
                      small-chips
                      @change="categorySelect">
              </v-autocomplete>
            </v-flex>
            <v-flex xs12 v-if="form.categoriesIds.length>0">
              <v-autocomplete
                      v-model="form.tournamentsIds"
                      :items="categoryTournament"
                      :label="$t('tournament')"
                      item-value="id"
                      item-text="name"
                      chips
                      deletable-chips
                      multiple
                      small-chips>
              </v-autocomplete>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                      v-model="form.days"
                      :label="$t('days')"
                      min="1"
                      max="30"
                      type="number"
                      :error-messages="messages.days">
              </v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-select
                      v-model="form.groupBy"
                      :items="selectGroup"
                      :label="$t('groupby')"
                      item-value="id"
                      item-text="name"
                      :error-messages="messages.groupBy">
              </v-select>
            </v-flex>

            <v-flex xs6>
              <v-select
                      v-model="form.language"
                      :items="getLanguagesList"
                      :label="$t('language')"
                      item-value="abbr"
                      item-text="text"
                      :error-messages="messages.language">
              </v-select>
            </v-flex>
<!--            <v-flex xs6>-->
<!--              <v-select-->
<!--                      v-model="form.logo"-->
<!--                      :items="selectLogos"-->
<!--                      :label="`${$t('logo')} `"-->
<!--                      item-value="value"-->
<!--                      item-text="value.name"-->
<!--                      clearable>-->
<!--              </v-select>-->
<!--            </v-flex>-->
<!--            <v-flex xs6>-->
<!--              <v-select-->
<!--                      v-model="form.banner"-->
<!--                      :items="selectBanners"-->
<!--                      :label="`${$t('banner')}`"-->
<!--                      item-value="value"-->
<!--                      item-text="value.name"-->
<!--                      clearable>-->
<!--              </v-select>-->
<!--            </v-flex>-->
<!--            <v-flex xs6>-->
<!--              <v-select-->
<!--                      v-model="form.footer"-->
<!--                      :items="selectBanners"-->
<!--                      :label="`${$t('footer')}`"-->
<!--                      item-value="value"-->
<!--                      item-text="value.name"-->
<!--                      clearable>-->
<!--              </v-select>-->
<!--            </v-flex>-->


            <v-flex xs6>
              <v-select
                      v-model="form.logoUrl"
                      :items="selectLogos"
                      :label="`${$t('logo')} `"
                      item-value="value.url"
                      item-text="value.name"
                      clearable>
              </v-select>
            </v-flex>


            <v-flex xs6>
              <v-text-field
                      v-model="form.headerText"
                      :label="$t('headerText')">
              </v-text-field>
            </v-flex>

            <v-flex xs6>
              <v-text-field
                      v-model="form.footerText"
                      :label="$t('footerText')">
              </v-text-field>
            </v-flex>


            <v-card
                    v-if="totalNumberOutcomes > 0"
                    class="mt-1"
                    outlined
                    tile>
              <v-card-title>
                <v-icon color="primary" class="mr-1">mdi-chart-bar</v-icon>
                {{$t('total_outcomes_selected')}}:
              </v-card-title>
              <v-card-text class="display-2 font-weight-bold text-center">
                <small class="text--secondary"> {{ totalNumberOutcomes }} Outcomes</small>
              </v-card-text>
            </v-card>
          </v-layout>
        </v-container>
        <v-card-actions justify="end">
          <v-btn color="primary" @click="dialog = false">{{$t('generic.close')}}</v-btn>
          <v-btn color="secondary" @click.prevent="submit">{{$t('generic.save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  /* TODO REFACT ME PLEASE; AND NEED BE TESTED */
  import {mapActions, mapGetters, mapMutations} from 'vuex';
  import timezone from '@/assets/timezone.json'

  export default {
    name: "PrintTemplates",
    data() {
      return {
        timezones: timezone,
        search: '',
        statuses: [
          {'text': 'Attivo', value: true},
          {'text': 'Non Attivo', value: false}
        ],
        pagination: {
          sortBy: 'platformId',
          descending: true,
          rowsPerPage: 10
        },
        headers: [
          {text: 'Name', value: 'name'},
          {text: 'Action', value: '', sortable: false}
        ],

        snackbar: {
          snackbar: false,
          color: '',
          mode: '',
          timeout: 6000,
          text: '',
        },

        titleDialog: '',

        dialog: false,
        dialogConfirm: false,

        idSkin: -1,
        actionForm: '',

        textOrintation: '',
        form: {
          platformId: 0,
          templateName: '',
          sportId: 0,
          sheetType: '',
          days: 1,
          marketsUniqueIds: [],
          categoriesIds: [],
          tournamentsIds: [],
          // logo: {},
          // banner: {},
          // footer: {},
          header: '',
          groupBy: '',
          _id: 0,
          language: "en",
          logoUrl: null,
          headerText: null,
          footerText: null
        },
        textgroup: '',
        deleteitem: '',
        show1: false,
        messages: {
          templateName: [],
          sportId: [],
          sheetType: [],
          days: [],
          groupBy: []
        },
        orientations: [],
        sheets: [{size: "A4", value: 9}, {size: "A3", value: 8}],
        textSheet: '',
        sportMarkets: [],
        textGroup: '',
        sportCategories: [],
        categoryTournament: [],
        selectGroup: [],
        selectLogos: [],
        selectBanners: [],
        selectHeaders: [],
        listTemplates: [],
        deleteItemJ: {},
        totalNumberOutcomes: 0,
        maxNumberOfOutcomesAllowed: 20,
        mySports: null,

      }
    },
    // async mounted() {
    //
    //   await this.setPreload(true);
    //
    //   await this.fetchSkins(); // todo what a shame remove me in future
    //
    //   this.orientations = [{
    //     name: this.$t('generic.landscape'),
    //     value: 'landscape'
    //   }, {name: this.$t('generic.portrait'), value: 'portrait'}]
    //
    //   this.textOrintation = this.$t('generic.orientation')
    //   this.textSheet = this.$t('sheet')
    //   this.textGroup = this.$t('group')
    //
    //   this.selectGroup.push({name: this.$t('groupday'), id: 'day'})
    //   this.selectGroup.push({name: this.$t('grouptournament'), id: 'tournament'})
    //
    //   this.selectHeaders.push({name: this.$t('firstpage'), id: 'firstpage'})
    //   this.selectHeaders.push({name: this.$t('allpage'), id: 'allpage'})
    //   this.selectHeaders.push({name: this.$t('never'), id: 'never'})
    //
    //   await this.fetchSports({'language': this.getLanguage});
    //   await this.loadTemplates();
    //
    //   await this.fetchMultimedia({id: this.getPlatformId}) // todo need refactor
    //
    //   const logos = _.filter(this.getMultimedias, {type: "Logo"});
    //   const banners = _.filter(this.getMultimedias, {type: "Banner"});
    //
    //   if (_.size(logos) > 0) {
    //     _.forEach(logos, logo => {
    //       this.selectLogos.push({id: logo._id, value: logo})
    //     })
    //
    //   }
    //   if (_.size(banners) > 0) {
    //     _.forEach(banners, banner => {
    //       this.selectBanners.push({id: banner._id, value: banner})
    //     })
    //
    //   }
    //
    //   await this.setPreload(false);
    //
    // },


    async mounted() {
      try {

        await this.setPreload(true);

        await this.fetchSkins(); // todo what a shame remove me in future


        const parallelCalls = [
          this.fetchSports({language: this.getLanguage}),   // Esegui fetchSports in parallelo
          this.loadTemplates(),                               // Esegui loadTemplates in parallelo
          this.fetchMultimedia({id: this.getPlatformId})    // Esegui fetchMultimedia in parallelo
        ];

        // Altre inizializzazioni sincrone
        this.orientations = [
          {name: this.$t('generic.landscape'), value: 'landscape'},
          {name: this.$t('generic.portrait'), value: 'portrait'}
        ];

        this.textOrintation = this.$t('generic.orientation');
        this.textSheet = this.$t('sheet');
        this.textGroup = this.$t('group');

        this.selectGroup.push({name: this.$t('groupday'), id: 'day'});
        this.selectGroup.push({name: this.$t('grouptournament'), id: 'tournament'});

        this.selectHeaders.push({name: this.$t('firstpage'), id: 'firstpage'});
        this.selectHeaders.push({name: this.$t('allpage'), id: 'allpage'});
        this.selectHeaders.push({name: this.$t('never'), id: 'never'});

        // Attendi il completamento di tutte le chiamate asincrone parallele
        await Promise.all(parallelCalls);

        // Ora che fetchMultimedia è completato, filtra loghi e banner
        const logos = _.filter(this.getMultimedias, {type: "Logo"});
        const banners = _.filter(this.getMultimedias, {type: "Banner"});

        this.selectLogos.push({id: null, value: {"name":"None","url":null}});
        if (_.size(logos) > 0) {
          _.forEach(logos, logo => {
            this.selectLogos.push({id: logo._id, value: logo});
          });
        }

        if (_.size(banners) > 0) {
          _.forEach(banners, banner => {
            this.selectBanners.push({id: banner._id, value: banner});
          });
        }

        // Imposta preload su false alla fine
        await this.setPreload(false);

      } catch (error) {
        console.error('error loading mounted: ', error);
        await this.setPreload(false); // Assicurati che il preload venga disattivato anche in caso di errore
      }
    },


    computed: {
      ...mapGetters({
        'getLanguage': 'profile/getLanguage',
        'getUrl': 'settings/getUrl',
        'getLists': 'stamps/getLists',
        'getPreload': 'utils/getPreload',
        'getSkins': 'skins/getList',
        'getItemsSport': 'sport/getItems',
        'getMultimedias': 'multimedia/getList',
        'getPlatformId': 'rules/getPlatformId',
        'getLanguagesList': 'profile/getLanguagesList',
      }),
    },
    methods: {
      ...mapMutations({}),
      ...mapActions({
        'setPreload': 'utils/setPreload',
        'fetchMarket': 'stamps/fetchMarket',
        'fetchMarketsBySportPlatform': 'stamps/fetchMarketsBySportPlatform',
        'fetchSports': 'sport/fetchSports',
        'fetchCategorySport': 'sport/fetchCategorySport',
        'fetchTournamentByCategory': 'sport/fetchTournamentByCategory',
        'fetchMultimedia': 'multimedia/fetchList',
        'fetchSkins': 'skins/fetchSkins',
        'fetchManagerPrintTemplates': 'stamps/fetchManagerPrintTemplates',
        'fetchPlatformPrintTemplates': 'stamps/fetchPlatformPrintTemplates',
        'createPrintTemplate': 'stamps/createPrintTemplate',
        'updatePrintTemplate': 'stamps/updatePrintTemplate',
        'deletePrintTemplate': 'stamps/deletePrintTemplate'
      }),

      calculateTotalOutcomes() {
        let total = 0;

        this.form.marketsUniqueIds.forEach(selectedId => {
          const market = this.sportMarkets.find(market => market.unique_id === selectedId);

          if (market && market.numberOutcomes) {
            total += market.numberOutcomes;
          }
        });

        this.totalNumberOutcomes = total;
      },

      async loadTemplates() {
        const vm = this;
        try {
          if (this.getPlatformId != 0 && this.getPlatformId > 0) {
            this.listTemplates = await this.fetchPlatformPrintTemplates({platformId: this.getPlatformId});
          } else {
            this.listTemplates = await this.fetchManagerPrintTemplates();
          }
        } catch (e) {
          console.log(e)
          vm.listTemplates = [];
        }

      },
      openNew() {
        this.titleDialog = this.$t('generic.new');
        this.resetForm();
        this.actionForm = 'create';
        this.dialog = true;
      },
      async categorySelect() {
        await this.setPreload(true);
        const listTournaments = await this.fetchTournamentByCategory({categories: JSON.stringify(this.form.categoriesIds)});

        this.categoryTournament = []
        if (_.size(listTournaments) > 0) {
          _.forEach(listTournaments, list => {
            if (list.active) {
              let nameCat = _.find(list.detail, {lang: this.getLanguage}).text
              this.categoryTournament.push({id: list.id, name: nameCat})
            }
          })
        }

        await this.setPreload(false);
      },
      async sportSelect() {
        await this.setPreload(true);

        let markets = [];

        if (this.getPlatformId != 0 && this.getPlatformId > 0) {
          markets = await this.fetchMarketsBySportPlatform({
            platformId: this.getPlatformId,
            language: this.getLanguage,
            sportId: this.form.sportId
          })
        } else {
          markets = await this.fetchMarket({  // todo this is very heavy avoid to call me
            enableSpecifiers: true,
            lang: this.getLanguage,
            sport: -1,
            id: this.form.sportId
          });
        }

        this.sportMarkets = markets
        const listCategories = await this.fetchCategorySport({sport: this.form.sportId});

        //sportCategories
        this.sportCategories = []
        if (_.size(listCategories) > 0) {
          _.forEach(listCategories, list => {
            if (list.active) {
              let nameCat = _.find(list.detail, {lang: this.getLanguage}).text
              this.sportCategories.push({id: list.id, name: nameCat})
            }
          })
          this.sportCategories = _.sortBy(this.sportCategories, ['name'])
        }

        this.form.tournamentsIds = [];
        await this.setPreload(false);
      },
      putOnOtherFields() {
        this.form.settings.save_endpoint = this.form.website + '/bookie/gaim/sport/'
        this.form.settings.print_endpoint = this.form.website + '/receipts/receiptGeneral8mm.jsp?externalId='
      },

      async openEdit(item) {
        await this.setPreload(true);
        this.titleDialog = this.$t('generic.edit');
        this.actionForm = 'update';
        this.form._id = item._id
        this.form.sportId = +item.sportId
        this.form.orientation = item.orientation
        this.form.sheetType = +item.sheetType
        this.form.marketsUniqueIds = item.marketsUniqueIds
        this.form.templateName = item.templateName
        this.form.id = item._id // todo delete me
        this.form.days = item.days
        this.form.language = item.language
        this.form.platformId = item.platformId

        this.form.logoUrl = item.logoUrl != null ? item.logoUrl : null;
        this.form.headerText = item.headerText != null ? item.headerText : null;
        this.form.footerText = item.footerText != null ? item.footerText : null;


        let markets = [];

        if (this.getPlatformId != 0 && this.getPlatformId > 0) {
          markets = await this.fetchMarketsBySportPlatform({
            platformId: this.getPlatformId,
            language: this.getLanguage,
            sportId: this.form.sportId
          })
        } else {
          markets = await this.fetchMarket({
            enableSpecifiers: true,
            lang: this.getLanguage,
            sport: -1,
            id: this.form.sportId
          })
        }

        this.sportMarkets = markets;
        this.calculateTotalOutcomes(markets);

        const listCategories = await this.fetchCategorySport({sport: this.form.sportId});
        this.sportCategories = [];

        //sportCategories
        if (_.size(listCategories) > 0) {
          _.forEach(listCategories, list => {
            if (list.active) {
              let nameCat = _.find(list.detail, {lang: this.getLanguage}).text
              this.sportCategories.push({id: list.id, name: nameCat})
            }
          })
          this.sportCategories = _.sortBy(this.sportCategories, ['name'])
        }

        this.form.categoriesIds = [];

        for (const category of item.categoriesIds) {
          this.form.categoriesIds.push(+category)
        }

        const listTournaments = await this.fetchTournamentByCategory({categories: JSON.stringify(this.form.categoriesIds)});

        this.categoryTournament = []
        if (_.size(listTournaments) > 0) {
          _.forEach(listTournaments, list => {
            if (list.active) {
              let nameCat = _.find(list.detail, {lang: this.getLanguage}).text
              this.categoryTournament.push({id: list.id, name: nameCat})
            }
          })
        }


        this.form.groupBy = item.groupBy
        this.form.header = null // todo
        this.form.logo = null // todo
        this.form.banner = null // todo
        this.form.footer = null // todo

        this.form.tournamentsIds = [];

        for (const tournament of item.tournamentsIds) {
          this.form.tournamentsIds.push(+tournament)
        }

        await this.setPreload(false);
        this.dialog = true;
      },

      async submit() {

        const vm = this;
        await this.setPreload(true);

        try {

          let payload = this.form;
          let response = null;

          if (this.form.categoriesIds.length >= 2) {
            this.form.tournamentsIds = []
          }

          if (vm.totalNumberOutcomes != null && +vm.totalNumberOutcomes > 20) {
            alert(vm.$t('please_select_max_n_signs', {N: this.maxNumberOfOutcomesAllowed}));
            await this.setPreload(false);
            return
          }

          if(vm.form.logoUrl === ''){
            vm.form.logoUrl = null;
          }

          if(vm.form.headerText === ''){
            vm.form.headerText = null;
          }
          if(vm.form.footerText === ''){
            vm.form.footerText = null;
          }


          if (this.actionForm == "create") {
            response = await this.createPrintTemplate(payload);
          } else if (this.actionForm == "update") {
            response = await this.updatePrintTemplate(payload);
          }

          vm.snackbar.color = "success";
          vm.snackbar.text = this.$t("generic.successfully");
          vm.snackbar.snackbar = true;
          vm.snackbar.mode = 'multi-line';
          vm.snackbar.timeout = 5000;
          await this.loadTemplates();
          vm.setPreload(false);

        } catch (e) {
          console.log(e);

          vm.snackbar.color = "error";
          vm.snackbar.text = e;
          vm.snackbar.snackbar = true;
          vm.snackbar.mode = 'multi-line';
          vm.snackbar.timeout = 5000;
          vm.setPreload(false);
        }
      },

      resetForm() {
        this.form = {
          platformId: this.getPlatformId,
          templateName: '',
          sportId: null,
          sheetType: '',
          days: 1,
          marketsUniqueIds: [],
          categoriesIds: [],
          tournamentsIds: [],
          logo: {},
          banner: {},
          header: '',
          groupBy: '',
          _id: null,
          language: 'en'
        }

        this.messages = {
          templateName: [],
          sportId: [],
          sheetType: [],
          days: [],
          groupBy: []
        }
      },

      async deleteItem(item) {
        const vm = this;
        const _id = item._id;

        const confirmed = confirm(this.$t("generic.areYouSure"));

        if (!confirmed) {
          return;
        }

        vm.snackbar.color = "success";
        vm.snackbar.text = this.$t("generic.successfully");
        vm.snackbar.snackbar = true;
        vm.snackbar.mode = 'multi-line';
        vm.snackbar.timeout = 5000;

        try {
          await this.deletePrintTemplate(_id);
          await this.loadTemplates();
        } catch (e) {
          console.log(e);
          vm.snackbar.color = "error";
          vm.snackbar.text = e;
          vm.snackbar.snackbar = true;
          vm.snackbar.mode = 'multi-line';
          vm.snackbar.timeout = 5000;
        }
      },
    },
  }
</script>

<style scoped>
  .v-card-title {
    font-size: 14px;
    color: #000;
  }

  .display-2 .text--secondary {
    font-size: 14px;
    color: #000;
  }
</style>
