<template>
  <div>
    <!--    <div class="d-flex">-->
    <!--      <div class="flex-grow-1 text-left pa-3 text-help" style="font-size: 15px; color:red; font-weight: bolder">-->
    <!--        <span class="font-weight-bold">{{$t('generic.total')}} {{$t('bets.amount')}}:</span> {{getTotalAmount().toFixed(2)}} |-->
    <!--        <span class="font-weight-bold">{{$t('generic.total')}} {{$t('bets.real_winning')}}:</span> {{getTotalRealWinning().toFixed(2)}} |-->
    <!--        <span class="font-weight-bold">{{$t('generic.total')}} {{$t('bets.net')}}:</span> {{getTotalNet().toFixed(2)}} |-->
    <!--        <span class="font-weight-bold">{{$t('bets.potential')}} {{$t('bets.winning')}}:</span> {{getPotentialWinning().toFixed(2)}} |-->
    <!--        <span class="font-weight-bold">{{$t('generic.totalPerPage')}}</span>-->
    <!--      </div>-->
    <!--      <div class="flex-grow-0 pa-3 text-help">-->
    <!--        {{$t('generic.totalResults')}} {{getCounts}}-->
    <!--      </div>-->
    <!--    </div>-->

    <v-data-table :headers="headers" :items="getBets" class="elevation-1 head-capitalize" :loading="getPreload"
                  disable-pagination hide-default-footer>

      <template v-slot:item.device="{ item }">
        <v-icon v-if="item.extra_data && item.extra_data.isDesktop" title="Desktop">mdi-desktop-mac</v-icon>
        <v-icon v-else-if="item.extra_data && item.extra_data.isMobile" title="Smartphone">mdi-cellphone
        </v-icon>
        <v-icon v-else-if="item.extra_data && item.extra_data.isTablet" title="Tablet">mdi-tablet-ipad</v-icon>

        <v-icon v-if="item.extra_data && item.extra_data.isAndroid"
                :title="item.extra_data.platform + ' - ' + item.extra_data.os">mdi-android
        </v-icon>
        <v-icon v-else-if="item.extra_data && item.extra_data.isMac"
                :title="item.extra_data.platform + ' - ' + item.extra_data.os">mdi-apple-ios
        </v-icon>
        <v-icon v-else-if="item.extra_data && item.extra_data.isWindows"
                :title="item.extra_data.platform + ' - ' + item.extra_data.os">mdi-microsoft-windows
        </v-icon>

        <v-icon v-if="item.extra_data && item.extra_data.isChrome" :title="item.extra_data.browser">
          mdi-google-chrome
        </v-icon>
        <v-icon v-else-if="item.extra_data && item.extra_data.isEdge" :title="item.extra_data.browser">
          mdi-web
        </v-icon>
        <v-icon v-else-if="item.extra_data && item.extra_data.isFirefox" :title="item.extra_data.browser">
          mdi-firefox
        </v-icon>
        <v-icon v-else-if="item.extra_data && item.extra_data.isOpera" :title="item.extra_data.browser">
          mdi-opera
        </v-icon>
        <v-icon v-else-if="item.extra_data && item.extra_data.isSafari" :title="item.extra_data.browser">
          mdi-apple-safari
        </v-icon>
      </template>

      <template v-slot:item.code="{ item }">
        <!--        <span class="font-weight-bold" v-if="item.betCode"><v-icon small v-if="item.status == 'review'">mdi-alert</v-icon>{{ item.betCode }}</span>-->
        <span class="font-weight-bold"><v-icon small v-if="item.status == 'review'">mdi-alert</v-icon>{{
            item.code
          }}</span>
      </template>

      <!--      <template v-slot:item.betCode="{ item }">-->
      <!--        <span class="font-weight-bold" v-if="item.betCode"><v-icon small v-if="item.status == 'review'">mdi-alert</v-icon>{{ item.betCode }}</span>-->
      <!--      </template>-->


      <template v-slot:item.type="{ item }">
        <span class="text-uppercase">{{ item.type }}</span>
      </template>

      <template v-slot:item.odds_sources="{ item }">
        <span class="text-uppercase">{{ item.odds_sources.length }}</span>
      </template>

      <template v-slot:item.created_at="{ item }">
        <span>{{
            (getTimezone != '') ? new Date(item.created_at).toLocaleString(undefined, {timeZone: getTimezone}) + '*' : new Date(item.created_at).toLocaleString()
          }}</span>
      </template>

      <template v-slot:item.amount="{ item }">
        <span class="text-right">{{ item.currencySymbol + ' ' + parseFloat(item.amount_real).toFixed(2) }}</span>
      </template>

      <template v-slot:item.winning_real="{ item }">
        <span class="text-right">{{ item.currencySymbol }} {{
            (item.winning_real != undefined) ? parseFloat(item.winning_real).toFixed(2) : 0.00
          }}</span>
      </template>

      <template v-slot:item.win="{ item }">
        <span class="text-right">{{ getWinning(item) }}</span>
      </template>

      <template v-slot:item.payed="{ item }">
        {{ (item.payed == true) ? $t('generic.yes') : $t('generic.no') | capitalize }}
      </template>

      <template v-slot:item.agencyLogin="{ item }">
        {{ item.agencyLogin | capitalize }}
      </template>

      <template v-slot:item.result="{ item }" style="font-size: 10px">
        <span v-if="item.result == 'pending'" class="list-pending">
          <div class="circle-pending"></div> {{ $t('bets.' + item.result) | capitalize }}
        </span>
        <span v-if="item.result == 'lose'" class="red--text">
          <v-icon small color="red">mdi-close-circle</v-icon> {{ $t('bets.' + item.result) | capitalize }}
        </span>
        <span v-if="item.result == 'win'" class="green--text">
          <v-icon small color="green">mdi-checkbox-marked-circle</v-icon> {{ $t('bets.' + item.result) | capitalize }}
        </span>
        <span v-if="item.result == 'void'" class="blue--text">
          <v-icon small color="blue">mdi-alert-circle</v-icon> {{ $t('bets.' + item.result) | capitalize }}
        </span>
        <span v-if="item.result == 'cancelled'" class="blue-grey--text">
          <v-icon small color="blue-grey">mdi-alert-circle</v-icon> {{ $t('bets.' + item.result) | capitalize }}
        </span>
      </template>

      <!--      <template v-slot:item.defined="{ item }">-->
      <!--          <small>({{getDefined(item)}})</small>-->
      <!--      </template>-->

      <template v-slot:item.status="{ item }">
        {{ $t('bets.' + item.status) | capitalize }}
      </template>


      <template v-slot:item.platform_id="{ item }">
        {{ getPlatformName(item.platform_id) }}
      </template>

      <template v-slot:item.bet_context="{ item }">
        <span v-if="item.bet_context == 'live'" class="context live-label">
          {{ $t('bets.' + item.bet_context) }}
        </span>
        <span v-else-if="item.bet_context == 'prematch'" class="context prematch-label">
          {{ $t('bets.' + item.bet_context) }}
        </span>
        <span v-else-if="item.bet_context == 'mixed'" class="context mixed-label">
          {{ $t('bets.' + item.bet_context) }}
        </span>
        <span v-else-if="item.bet_context == 'antepost'" class="context antepost-label">
          {{ $t('bets.' + item.bet_context) }}
        </span>
      </template>

      <template v-slot:item.actions="{ item }">
        <span>
          <user-info :platform_id="item.platform_id" :user_id="item.user_id" :bet="item" type_btn="icon"></user-info>
          <v-icon :title="$t('bets.detail')" @click="showDetail(item)">mdi-file-find</v-icon>
          <v-icon :title="$t('bets.defineBet')" @click="defineAuto(item)"
                  v-if="item.status != 'review' && item.result !='cancelled'">mdi-auto-fix</v-icon>
          <v-icon :title="$t('bets.makeVoid')" @click="makeVoid(item)"
                  v-if="item.status != 'review' && item.result == 'pending'">mdi-close-circle</v-icon>

           <v-icon :title="$t('bets.makeCancel')" @click="makeCancel(item)"
                   v-if="item.status != 'review' && item.result =='pending'">mdi-delete</v-icon>

          <v-icon :title="$t('rollback')" @click="sendRollback(item)"
                  v-if="item.status != 'review' && item.result !='cancelled' && item.result != 'pending'">mdi-backup-restore</v-icon>
          <v-icon v-if="item.sent_to_platform == undefined && item.result != 'pending' && item.result !='cancelled'"
                  :title="$t('bets.sendResult')" @click="sendToPlatform(item)">mdi-send</v-icon>
          <!--          <v-icon v-if="item.status == 'review'" @click="dialogReview = true;betToReview = item.code">mdi-thumbs-up-down</v-icon>-->
        </span>
      </template>

    </v-data-table>

    <div class="text-center pt-3 pb-3" v-if="this.getBets != null && this.getBets.length > 0">
      <v-pagination
              v-model="getOptions.page"
              :length="calculatePagination(getCounts,getOptions.limit)"
              :total-visible="20"
              @input="getNewPage()"
      ></v-pagination>
    </div>

    <template v-if="this.getBets != null && this.getBets.length > 0">
      <div>
        <v-simple-table dense>
          <thead>
          <tr>
            <th class="text-left text-uppercase">Bets</th>
            <th class="text-left text-uppercase">{{ this.$t('bets.amount') }}</th>
            <th class="text-left text-uppercase">{{ this.$t('bets.win') }}</th>
            <th class="text-left text-uppercase">{{ this.$t('bets.net') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ getTotalBets() }}</td>
            <td>{{ this.currency }}
              {{ this.getTotalAmountT.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") }}
            </td>
            <td>{{ this.currency }} {{
              this.getTotalWinT.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
              ",")
              }}
            </td>
            <td>{{ this.currency }} {{
              this.getTotalNetT.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
              ",")
              }}
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </div>
    </template>


    <bet-detail></bet-detail>

    <v-snackbar v-model="snackbar">
      {{ $t(this.snackbar_message) }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          {{ $t('generic.close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <!--    <v-dialog v-model="dialogReview" width="500" height="500">-->
    <!--      <v-card>-->
    <!--        <v-card-title>-->
    <!--          <span class="text-h5"></span>-->
    <!--        </v-card-title>-->
    <!--        <v-card-text>-->
    <!--          <v-flex style="display: flex">-->

    <!--            <div class="button-review" style="background: #748300" @click="betDecision('accept')">-->
    <!--              <v-icon large>mdi-thumb-up</v-icon> <span class="label-review">{{$t('accept')}}</span>-->
    <!--            </div>-->

    <!--            <div class="button-review" style="background: #c90000" @click="betDecision('reject')">-->
    <!--              <v-icon large>mdi-thumb-down</v-icon> <span class="label-review">{{$t('reject')}}</span>-->
    <!--            </div>-->

    <!--          </v-flex>-->

    <!--        </v-card-text>-->
    <!--      </v-card>-->
    <!--    </v-dialog>-->
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import _ from 'lodash';
  import BetDetail from "@/components/bets/betDetail";
  import UserInfo from "@/components/risks/userInfo";

  export default {
    name: "betsList",
    components: {BetDetail, UserInfo},
    data() {
      return {
        snackbar: false,
        snackbar_message: '',
        totalAmount: 0.00,
        totalWin: 0.00,
        totalPotWin: 0.00,
        totalNet: 0.00,
        getTotalAmountT: 0,
        getTotalWin: 0,
        getTotalNet: 0,
        dialog: false,
        currency: '',
        betToShow: {},
        headers: [
          {text: '', value: 'device', width: '7%', sortable: false},
          {text: this.$t('bets.code'), value: 'code', width: '12%', sortable: false},
          {text: this.$t('bets.betCode'), value: 'betCode', width: '4%', sortable: false},
          {text: this.$t('bets.type'), value: 'type', width: "5%"},
          {text: this.$t('bets.events'), value: 'odds_sources', width: "4%"},
          {text: this.$t('bets.amount'), value: 'amount', width: "6%", sortable: false},
          {
            text: this.$t('bets.potential') + ' ' + this.$t('bets.winning'),
            value: 'win',
            width: "7%",
            sortable: false
          },
          {text: this.$t('bets.real_winning'), value: 'winning_real', width: "5%", sortable: false},
          {text: this.$t('bets.date'), value: 'created_at', width: "9%"},
          {text: this.$t('bets.platform'), value: 'platform_id', width: "6%"},
          {text: this.$t('generic.context'), value: 'bet_context', width: "5%"},
          {text: this.$t('user.agency'), value: 'agencyLogin', width: "4%"},
          {text: this.$t('bets.user'), value: 'login', width: "4%"},

          // {text: this.$t('bets.signDefined'), value: 'defined', width: "3%"},
          {text: this.$t('bets.result'), value: 'result', width: "5%"},
          {text: this.$t('bets.status'), value: 'status', width: "5%"},
          {text: this.$t('bets.payed'), value: 'payed', width: "3%"},
          {text: this.$t('bets.actions'), value: 'actions', sortable: false, width: "9%"},
        ],
        options: {},
        betToReview: '',
        dialogReview: false
      }
    },
    computed: {
      ...mapGetters({
        'getBets': 'bet/getBets',
        'getBetsTotals': 'bet/getBetsTotals',
        'getPreload': 'utils/getPreload',
        'getShowDetail': 'bet/getShowDetail',
        'getOptions': 'bet/getOptions',
        'getSkinFilters': 'skins/getSkinFilters',
        'getCounts': "bet/getCounts",
        'getCriteria': "bet/getCriteria",
        'getTimezone': 'bet/getTimezone',
        'getPlatformId': 'rules/getPlatformId',
      }),
    },

    methods: {
      ...mapActions({
        'setBetDetail': 'bet/setBetDetail',
        'setShowDetail': 'bet/setShowDetail',
        'defineBet': 'bet/defineBet',
        'defineVoid': 'bet/defineVoid',
        'defineCancel': 'bet/defineCancel',
        'requestRollback': 'bet/requestRollback',
        'fetchBets': 'bet/fetchBets',
        'setPreload': 'utils/setPreload',
        'removeBetDetail': 'bet/removeBetDetail',
        'sendResultToPlatform': 'bet/sendResultToPlatform',
        /*'sendReviewBet': 'bet/sendReviewBet',*/
        'fetchReviewCounts': 'bet/fetchReviewCounts',
        'sendAcceptBet':'bet/sendAcceptBet',
        'sendRejectBet':'bet/sendRejectBet',
      }),
      async showDetail(bet) {
        await this.setShowDetail(false)
        await this.setBetDetail(bet)
        await this.setShowDetail(true)
      },
      async defineAuto(bet) {
        const vm = this;
        await this.setPreload(true);

        try {
          var result = await this.defineBet({'code': bet.code});
          if (result.data.data || result.data.updated) {
            this.snackbar = true
            this.snackbar_message = 'bets.betDefinedSuccessfully'
          } else {
            this.snackbar = true
            this.snackbar_message = 'bets.betNotDefinedAutomatically'
          }
        }catch (e) {
          console.log(e);
          vm.snackbar = false;
          vm.$swal.fire(vm.$t('operationNotPerformedCorrectly'), '', 'error');
        }

        // await this.fetchBets({page: this.getOptions.page, limit: this.getOptions.limit, platform_id: this.getPlatformId})
        await this.setPreload(false);
      },
      async sendRollback(bet) {
        const vm = this;
        await this.setPreload(true);
        try {
          await this.requestRollback({'code': bet.code, 'manual': true});
          this.getCriteria.page = this.getOptions.page
          this.getCriteria.limit = this.getOptions.limit
          this.getCriteria.platform_id = (this.getPlatformId > 0) ? this.getPlatformId : this.getOptions.platform_id
          await this.fetchBets(this.getCriteria)
        }catch (e) {
          console.log(e);
          vm.$swal.fire(vm.$t('operationNotPerformedCorrectly'), '', 'error');
        }
        await this.setPreload(false);
      },
      async makeVoid(bet) {
        await this.setPreload(true);
        await this.defineVoid({'code': bet.code});
        this.getCriteria.page = this.getOptions.page
        this.getCriteria.limit = this.getOptions.limit
        this.getCriteria.platform_id = (this.getPlatformId > 0) ? this.getPlatformId : this.getOptions.platform_id
        await this.fetchBets(this.getCriteria)
        await this.setPreload(false);
      },
      async makeCancel(bet) {

        const vm = this;
        await this.setPreload(true);


        const messageWarning = this.$t('generic.areYouSure');

        let isExecuted = confirm(messageWarning);

        if (isExecuted === true) {

          try {
            await this.defineCancel({'code': bet.code});

            vm.$swal.fire({
              position: "center",
              icon: "success",
              title: this.$t('successOperation'),
              showConfirmButton: false,
              timer: 1000
            });

            this.getCriteria.page = this.getOptions.page
            this.getCriteria.limit = this.getOptions.limit
            this.getCriteria.platform_id = (this.getPlatformId > 0) ? this.getPlatformId : this.getOptions.platform_id
            await this.fetchBets(this.getCriteria)
          } catch (e) {

            console.log(e);
            // vm.$swal.fire(vm.$t('operationNotPerformedCorrectly'), '', 'error');

            if (e.response.data.code) {

              let errorCode = e.response.data.code;

              if (errorCode == null) {
                errorCode = 'error'
              }

              vm.$swal.fire({
                title: vm.$t('warning'),
                type: "warning",
                html: vm.$t(errorCode),
                showCancelButton: false,
                confirmButtonText: vm.$t('generic.ok'),
                allowOutsideClick: () => {
                  return false
                }
              })

            } else {
              vm.$swal.fire({
                title: vm.$t('error'),
                type: "error",
                html: vm.$t('error'),
                showCancelButton: false,
                confirmButtonText: vm.$t('commons.ok'),
              })
            }
          }
        }
        await this.setPreload(false);
      },
      async sendToPlatform(bet) {
        await this.setPreload(true);
        const result = await this.sendResultToPlatform({'bet_code': bet.code});
        if (result.result) {
          this.snackbar = true
          this.snackbar_message = 'bets.betSentSuccessfully'
        }
        await this.setPreload(false);
      },
      async betDecision(mode) {
        await this.setPreload(true);
        //const result = await this.sendReviewBet({'code': this.betToReview, 'mode': mode});
        let result ;

        try {

          if(mode === 'accept'){
            result = await this.sendAcceptBet({'code':this.betToReview});
          }else if (mode === 'reject'){
            result = await this.sendRejectBet({'code':this.betToReview});
          }

          if (result.data.result) {
            this.snackbar = true
            this.snackbar_message = 'bets.betSentSuccessfully'
            this.betToReview = ''
            this.dialogReview = false
          } else {
            this.snackbar = true
            this.snackbar_message = 'error'
            this.betToReview = ''
            this.dialogReview = false
          }

        }catch (e) {
          console.log(e)
        }

        await this.fetchReviewCounts()
        await this.fetchBets(this.getCriteria)
        await this.setPreload(false);
      },
      getPlatformName(platform_id) {
        if (process.env.NODE_ENV === 'local') {
          platform_id = 43;
        }

        var platform = _.find(this.getSkinFilters, {platform_id: platform_id})
        return platform.name
      },
      getWinning(bet) {
        switch (bet.type) {
          case('single'):
          case('multiple'):
            return bet.currencySymbol + ' ' + parseFloat(bet._multiple_detail.winning_total).toFixed(2)
          case('integral'):
            return bet.currencySymbol + ' ' + parseFloat(bet._integral_detail.winning_total_max).toFixed(2)
          case('system'):
            return bet.currencySymbol + ' ' + parseFloat(bet._system_detail.winning_all_total).toFixed(2)
        }
      },
      getDefined(bet) {
        var defined = _.countBy(bet.odds, {'defined': true})
        if (defined.true != undefined) {
          return defined.true + '/' + bet.odds.length
        } else {
          return '0/' + bet.odds.length
        }

      },
      async getNewPage() {
        this.getCriteria.page = this.getOptions.page
        this.getCriteria.limit = this.getOptions.limit

        await this.setPreload(true);
        await this.fetchBets(this.getCriteria)
        await this.setPreload(false);
      },
      getTotalAmount() {
        var total = _.sumBy(this.getBets, 'amount_real')
        return (total != undefined) ? total : 0.00
      },
      getTotalRealWinning() {
        var total = _.sumBy(this.getBets, 'winning_real')
        return (total != undefined) ? total : 0.00
      },
      getPotentialWinning() {
        var result = 0.00;
        var pending_bets = _.filter(this.getBets, {'result': 'pending'})
        _.forEach(pending_bets, (bet) => {
          switch (bet.type) {
            case('single'):
            case('multiple'):
              result += bet._multiple_detail.winning_total
              break
            case('integral'):
              result += bet._integral_detail.winning_total_max
              break
            case('system'):
              result += bet._system_detail.winning_all_total
              break
          }
        })

        return result
      },
      getTotalBets() {

        let totalsBets = 0;

        try {
          let totals = this.getBetsTotals;
          console.log('totals is: ')
          console.log(totals)

          totalsBets = (totals != null && totals.bets != null) ? totals.bets : 0

          this.currency = (this.getBets != null && this.getBets.length > 0 && this.getBets[0].currencySymbol != null) ? this.getBets[0].currencySymbol : '';
          this.getTotalAmountT = (totals != null && totals.amount != null) ? totals.amount.toFixed(2) : 0.00;
          this.getTotalWinT = (totals != null && totals.winning != null) ? totals.winning.toFixed(2) : 0.00;
          this.getTotalNetT = (totals != null && totals.amount != null && totals.winning != null) ? (totals.amount - totals.winning).toFixed(2) : 0.00;

        } catch (e) {
          console.log(e);
          console.log('ERROR in getTotalBets');
          this.currency = '';
          this.getTotalAmountT = 0.00;
          this.getTotalWinT = 0.00;
          this.getTotalNetT = 0.00;
        }
        return totalsBets
      },

      // getTotalNet(){
      // var total_amount = _.sumBy(this.getBets, 'amount_real')
      //     if(!total_amount)
      //       total_amount = 0.00
      //
      // var total_winning = _.sumBy(this.getBets, 'winning_real')
      // if(!total_winning)
      //   total_winning = 0.00
      //
      // return total_amount - total_winning
      //   return totals
      // },
      calculatePagination(count, limit) {

        var divide = (count / limit)
        var rest = divide % 1

        if (rest > 0) {
          divide = divide - rest
          divide = +divide.toFixed(0) + 1
        }

        return +divide
      }
    }
  }
</script>

<style scoped>

  .context {
    font-weight: bold;
    padding: 0 5px 0 2px;
    cursor: default;
    font-size: 10px;
    text-transform: uppercase;
    margin-right: 5px;
  }

  .live-label {
    font-style: italic;
    background: #c90000;
    color: #fff;
  }

  .prematch-label {
    background: #748300;
    color: #fff;
  }

  .mixed-label {
    background: #0048cd;
    color: #fff;
  }

  .button-review {
    flex: 0 0 48%;
    border: 1px solid #7e7e7e;
    border-radius: 3px;
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
  }

  .label-review {
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    vertical-align: top !important;
    padding-top: 10px !important;
  }

</style>
