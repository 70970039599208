<template>
    <div>
        <v-dialog transition="dialog-bottom-transition" max-width="60%" v-model="activeDialog" @keydown.esc="closeWriteNoteDialog">
            <v-card>
                <v-toolbar color="primary" dark class="text-uppercase" dense>
                    {{ $t('writeNote') }}
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark>
                            <v-icon @click="closeWriteNoteDialog">mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col md="12">
                            <div style="height: 36px">
                                <div class="alert-container">

                                    <transition name="fade" v-if="alertSuccessEnabled">
                                        <v-alert type="success">
                                            {{$t('successOperation')}}
                                        </v-alert>
                                    </transition>

                                    <transition name="fade" v-if="alertErrorEnabled">
                                        <v-alert type="error">
                                            {{$t('operationNotPerformedCorrectly')}}
                                        </v-alert>
                                    </transition>

                                </div>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col md="12">
                            <v-text-field
                                    v-if="user.idUser"
                                    v-model="user.idUser"
                                    label="Id user"
                                    :disabled="true"
                            ></v-text-field>
                            <v-text-field
                                    v-else
                                    v-model="user.userId"
                                    label="Id user"
                                    :disabled="true"
                            ></v-text-field>

                            <v-text-field
                                    v-if="user.login"
                                    v-model="user.login"
                                    label="Login"
                                    :disabled="true"
                            ></v-text-field>
                            <v-text-field
                                    v-else
                                    v-model="user.userName"
                                    label="Login"
                                    :disabled="true"
                            ></v-text-field>

                            <v-textarea label="Note"
                                        v-model="message.message">
                            </v-textarea>

                            <v-btn
                                    class="me-4"
                                    type="submit"
                                    @click="message.message = ''"
                            >
                                clear
                            </v-btn>

                            <v-btn v-if="message._id != null" @click="updateUserNote()"
                                   class="me-4"
                                   type="submit"
                            >
                                {{$t('generic.update')}}
                            </v-btn>
                            <v-btn v-else class="me-4" @click="addANote()"
                                   type="submit">
                                {{$t('addNote')}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';

    export default {
        name: "userNote",
        props: {
            platformId: {
                type: Number,
                required: true
            },
            userId: {
                type: Number,
                required: true
            },
            user: {
                type: Object,
                required: true
            },
            updateUserNoteReserveBet: {
                type: Boolean,
                required: false,
                default: false
            },
            updateAgencyNoteReserveBet: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                activeDialog: true,
                message: '',
                alertSuccessEnabled: false,
                alertErrorEnabled: false
            }
        },
        async mounted() {

            const payloadRequest = {
                idUser: this.user.idUser  ? this.user.idUser : this.user.userId
            }

            this.message = await this.getLastUserNoteForUser(payloadRequest);

            if (this.message == null) {
                this.message = {
                    message: ''
                }
            }
        },
        computed: {
            ...mapGetters({}),
        },
        methods: {
            ...mapActions({
                'addANoteAction': 'users/addANote',
                'getLastUserNoteForUser': 'users/getLastUserNoteForUser',
                'updateUserNoteAction': 'users/updateUserNote',
                'updateUserNoteForBetInAcceptance': 'bet/updateUserNoteForBetInAcceptance',
                'updateAgencyNoteForBetInAcceptance': 'bet/updateAgencyNoteForBetInAcceptance'

            }),
            showSuccessAlert() {
                this.alertSuccessEnabled = true;
                setTimeout(() => {
                    this.alertSuccessEnabled = false;
                }, 2000)
            },
            showErrorAlert() {
                this.alertErrorEnabled = true;
                setTimeout(() => {
                    this.alertErrorEnabled = false;
                }, 5000)
            },
            async closeWriteNoteDialog() {
                this.activeDialog = false;
                await this.$root.$emit('closedWriteNoteDialog');
            },
            async updateUserNoteStored() {
                if (this.updateUserNoteReserveBet === true) {
                    const payload = {
                        userNote: this.message.message,
                        idUser: this.user.idUser
                    }
                    await this.updateUserNoteForBetInAcceptance(payload);
                }
            },
            async updateAgencyNoteStored() {
                if (this.updateAgencyNoteReserveBet === true) {

                    const payload = {
                        userNote: this.message.message,
                        idAgency: this.user.idUser ? this.user.idUser : this.user.userId
                    }
                    await this.updateAgencyNoteForBetInAcceptance(payload);
                }
            },
            async addANote() {

                const payload = {
                    idUser: this.user.idUser ? this.user.idUser : this.user.userId,
                    message: this.message.message
                }

                const response = await this.addANoteAction(payload);

                if (response && response.data && response.data.result && response.data.result._id) {
                    this.message = response.data.result
                    await this.updateUserNoteStored();
                    await this.updateAgencyNoteStored();
                    this.showSuccessAlert();
                } else {
                    this.showErrorAlert();
                }
            },
            async updateUserNote() {

                const requestPayload = {
                    _id: this.message._id,
                    idUser: this.message.idUser,
                    message: this.message.message
                }
                const response = await this.updateUserNoteAction(requestPayload);

                if (response && response.data && response.data.result && response.data.result._id) {
                    await this.updateUserNoteStored();
                    await this.updateAgencyNoteStored();
                    this.showSuccessAlert();
                } else {
                    this.showErrorAlert();
                }
            }
        },
    }
</script>
