import axios from "axios";

const state = {
    calendar: [],
    events: [],
    bookeds: [],
};

const getters = {
    getEvents(state, getters) {
        return state.events;
    },
    getCalendar(state, getters) {
        return state.calendar;
    },
    getBookeds(state, getters) {
        return state.bookeds;
    }
};

const actions = {

    async fetchCalendar(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}live-events/schedules`;

        return await axios.post(`${url}`, {'lang': payload.language, 'date': payload.date}, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                context.commit('setCalendar',res.data);

            }).catch((error) => {
                console.log(error);
            });
    },

    async fetchEvents(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}live-events/get-events`;

        return await axios.post(`${url}`, {'lang': payload.language, 'sport': payload.sport}, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                // console.log(res.data.events);
                context.commit('setEvents',res.data.events);

            }).catch((error) => {
                console.log(error);
            });
    },

    async autoBookAll(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}live-events/auto-book`;

        return await axios.post(`${url}`, {'lang': payload.language, 'date': payload.date}, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                alert('Aggiunta eventi in corso.. ricaricare la pagina tra qualche minuto')

            }).catch((error) => {
                console.log(error);
            });
    },

    async bookEvent(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}live-events/book`;

        return await axios.post(`${url}`, {'id': payload.id}, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                return res.data

            }).catch((error) => {
                console.log(error);
            });
    },
    async fetchEventsBooked(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}live-events/get-events-booked`;

        return await axios.post(`${url}`, {'sport': payload.data.sport,tournament:payload.data.tournament,category:payload.data.category}, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                console.log(res.data)
                context.commit('setBookeds',res.data.data);
                return res.data

            }).catch((error) => {
                console.log(error);
            });
    },


};

const mutations = {

    setEvents(state, payload) {
        state.events = payload;
    },
    setBookeds(state, payload) {
        state.bookeds = payload;
    },
    resetEvents(state) {
        state.events = [];
    },
    setCalendar(state, payload) {
        state.calendar = payload;
    },
};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
