import store from '../store';
import Odds from '../views/odds/Index.vue'
import MarketManual from '../views/odds/marketmanual'
import MatchManual from '../views/odds/Matchmanual'
import History from '../views/odds/History.vue'
import {checkauth, checkRoute} from "../Helper";
export default [
    {
        path: '/odds',
        name: 'odds',
        component: Odds,
        beforeEnter (to,from,next){
            checkRoute('/odds').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },
    {
        path: '/odds/marketmanual',
        name: 'marketmanual',
        component: MarketManual,
        beforeEnter (to,from,next){
            checkRoute('/odds/marketmanual').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },
    {
        path: '/history/:id',
        name: 'history',
        component: History,
        beforeEnter (to,from,next){
            checkRoute('/history').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },
    {
        path: '/odds/matchmanual/:id',
        name: 'matchmanual',
        component: MatchManual,
        beforeEnter (to,from,next){
            checkRoute('/odds/matchmanual').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },

]
