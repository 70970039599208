import store from '@/store';
import Stamps from "@/views/stamps/List";
import Markets from "@/views/stamps/Markets";
import Multimedia from "@/views/stamps/Multimedia";

import {checkauth,checkRoute,checkauthManager} from "../Helper";

export default [
    {
        path: '/stamps/list',
        name: 'List',
        component: Stamps,
        beforeEnter (to,from,next){
            checkRoute('/stamps/list').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },
    {
        path: '/stamps/markets',
        name: 'Markets',
        component: Markets,
        beforeEnter (to,from,next){
            checkRoute('/stamps/markets').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    },
    {
        path: '/stamps/multimedia',
        name: 'Multimedia',
        component: Multimedia,
        beforeEnter (to,from,next){
            checkRoute('/stamps/multimedia').then( response =>{
                    // console.log(response)
                    if(response){
                        //console.log("login")
                        next();
                    }else{
                        //console.log("next")
                        next('/login');
                    };
                }

            )
        }
    }


]
