const state = {
    platform_id: 0,
    rule_id: "5faea0fe6df64830cc437f93",
};

const getters = {
    getPlatformId: state => {
        return state.platform_id;
    },
    getRuleId: state => {
        return state.rule_id;
    },
};

const actions = {
    async setPlatformId(context, payload){
        context.commit("setPlatformId",payload)
    },

    async setRuleId(context, payload){
        context.commit("setRuleId",payload)
    }
};

const mutations = {
    setPlatformId: (state,value) =>{
        state.platform_id = value
    },
    setRuleId: (state,value) =>{
        state.rule_id = value
    }
};



export default {
    namespaced : true,
	state,
	mutations,
	getters,
	actions
}
