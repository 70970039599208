<template>
  <div>
<!--    <v-card class="mx-auto fav-card" elevation="3">-->
<!--      <v-list flat dense>-->
<!--        <v-list-item-group-->
<!--            dense-->
<!--            color="primary"-->
<!--            multiple-->
<!--            v-model="selectedFav"-->
<!--        >-->
<!--          <v-list-item-->
<!--              dense-->
<!--              v-for="(item, i) in fav_tournament"-->
<!--              :key="item.source"-->
<!--              @click="openFromFavourite(item.source)"-->
<!--              active-class="fav-active"-->
<!--              class="fav-row"-->
<!--              :value="item.source"-->
<!--              :disabled="item.locked"-->
<!--          >-->
<!--            <img height="20" width="20" :src="categoryPath+item.flag_id+'.png'"/> <span-->
<!--              class="text-tournament">{{ item.text }}</span>-->
<!--          </v-list-item>-->

<!--        </v-list-item-group>-->
<!--      </v-list>-->
<!--    </v-card>-->

    <v-card elevation="3" :loading="loading">
      <v-expansion-panels tile class="tree" v-if="!loading">

        <v-expansion-panel v-for="(sport,i) in getTree" :key="sport.id">

          <v-expansion-panel-header class="tree-sport-level" :title="sport.name+' - '+sport.icon_id">
            <template v-slot:actions>
              <div class="counter-nobg">{{ sport.count }}</div>
              <v-icon dense>mdi-chevron-down</v-icon>
            </template>

            <img v-if="sport.type == 'sport' && sport.name.includes('Antepost')" :src="sportPath+'antepost.svg'">
            <img v-else-if="sport.type == 'sport'" :src="sportPath+sport.icon_id+'.svg'">
            <div class="name">{{ sport.name }}</div>

          </v-expansion-panel-header>

          <v-expansion-panel-content class="no-padding">

            <v-expansion-panels tile class="tree-inner">

              <v-expansion-panel v-for="(category,i) in sport.children" :key="category.id">

                <v-expansion-panel-header expand-icon="mdi-menu-down" class="tree-category-level" :title="category.name+' - '+category.icon_id">
                  <template v-slot:actions>
                    <div class="counter-nobg">{{ category.count }}</div>
                    <v-icon dense>mdi-chevron-down</v-icon>
                  </template>

                  <img :src="categoryPath+category.icon_id+'.svg'">
                  <div class="name">{{ category.name }}</div>

                </v-expansion-panel-header>

                <v-expansion-panel-content>

                  <div v-if="category.typeOdds == 'prematch'" class="tree-tournament-level"
                       v-for="(tournament,i) in category.children" :key="tournament.id"
                       v-bind:class="{ selected: getActiveTournaments.includes(tournament.id)}"
                  >
                    <div class="name" @click="getEventsFromTournament(tournament.id)" :title="tournament.id">{{ tournament.name }}</div>
                    <div class="counter-nobg">
                      {{ tournament.count }}
                    </div>

                  </div>

                  <div v-else-if="category.typeOdds == 'antepost'"
                       class="tree-tournament-level" v-for="(antepost,i) in tournament.children"
                       :key="antepost.id"
                       @click="getDetail(antepost)"
                       v-bind:class="{ selected: getActiveTournaments.includes(antepost.id)}"
                  >
                    <div class="name" :title="antepost.id">{{ antepost.name }} </div>
                  </div>

                </v-expansion-panel-content>

              </v-expansion-panel>

            </v-expansion-panels>

          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>
      <div class="loading-tree" v-else>
        <v-progress-circular
            :size="100"
            color="primary"
            indeterminate
        ></v-progress-circular>
      </div>

<!--      <div class="search-input-box">-->
<!--        <v-text-field autocomplete="new-password" v-model="search_tree" append-icon="search" :label="$t('generic.search')|capitalize" dense></v-text-field>-->
<!--      </div>-->
<!--      <v-treeview class="tree-view" dense hoverable open-on-click :items="getTree" :search="search_tree"-->
<!--                  v-model="active_tournaments" selectable @input="getDetails($event)">-->
<!--        <template v-slot:label="{item}">-->
<!--          <div class="label-tree">-->
<!--            <img v-if="item.type == 'category'"-->
<!--                 height="20" width="20" :src="categoryPath+item.icon_id+'.png'">-->
<!--            <img v-else-if="item.type == 'sport' && item.name.includes('Antepost')"-->
<!--                 height="20" width="20" :src="sportPath+'antepost.png'">-->
<!--            <img v-else-if="item.type == 'sport'"-->
<!--                 height="20" width="20" :src="sportPath+item.icon_id+'.png'">-->
<!--            <p v-if="antepostType.includes(item.type)" class="label-tree-desc" :title="item.id+' - '+item.name">{{ item.name }}</p>-->
<!--            <p v-if="item.type == 'antepost'" class="label-tree-desc" :title="item.id+' - '+item.name">{{ item.name }}<span></span></p>-->
<!--            <p v-else class="label-tree-desc" :title="item.id+' - '+item.name+' ('+item.count+')'">{{ item.name }}<span> ({{ item.count }})</span></p>-->
<!--          </div>-->
<!--        </template>-->
<!--      </v-treeview>-->
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "treeview",
  data() {
    return {
      search_tree: '',
      categoryPath: 'https://rocky-revenge.s3-eu-west-1.amazonaws.com/svg/categories/rectangular_flags_category/',
      // categoryPath: process.env.VUE_APP_URL_CLOUDFRONT + 'rocky/images/flag_category/rectangular/',
      sportPath: 'https://rocky-revenge.s3-eu-west-1.amazonaws.com/svg/sports/dark/',
      // sportPath: process.env.VUE_APP_URL_CLOUDFRONT + 'rocky/images/sport_flat/',
      selectedFav: [],
      loading: true,
      antepostType: ['stage','season','simple_tournament'],

    }
  },
  computed: {
    ...mapGetters({
      'getLanguage': 'profile/getLanguage',
      'getTree': 'tree/getTree',
      'getToken': "profile/getToken",
      'getActiveTournaments': "tree/getActiveTournaments",
      'getPreload': "settings/getPreload"
    }),
  },
  async mounted() {
    await this.setPreload(true);
    await this.fetchTree({'language': this.getLanguage});

    this.loading = false
    await this.setPreload(false);
  },
  methods: {

    ...mapActions({
      'fetchTree': 'tree/fetchTree',
      'fetchEventsPreview': 'tournament/fetchEventsPreview',
      'oddChangeSocket': 'tournament/oddChangeSocket',
      'resetEventsPreview': 'tournament/resetEventsPreview',
      'setPreload': 'utils/setPreload',
      'removeTournament': 'tree/removeTournament',
      'getOddsEvent':'utils/getOddsEvent',
      'setMatchDetailDialog': 'utils/setMatchDetailDialog',
      'getOddsAntepostEvent': 'utils/getOddsAntepostEvent',
    }),

    ...mapMutations({
      'setActiveTournaments': 'tree/setActiveTournaments',

    }),

    async getDetails(tournament) {


      // var vue = this;
      //
      // console.log($event)
      //
      // var newTournaments = _.difference(vue.active_tournaments, vue.getActiveTournaments);
      //
      // console.log(newTournaments)
      //
      // // await this.resetEventsPreview();
      // await this.setActiveTournaments(vue.active_tournaments)
      //
      // _.forEach(newTournaments, function (item) {
      //   vue.getEventsFromTournament(item)
      //   let index = _.findIndex(vue.fav_tournament, function (o) {
      //     return o.source == item;
      //   });
      //   let index_active = _.findIndex(vue.selectedFav, function (o) {
      //     return o == item;
      //   });
      //   if (index >= 0 && index_active == -1) {
      //     vue.selectedFav.push(item);
      //   }
      // })


    },

    async getDetail(antepost){



      if(!this.getActiveTournaments.includes(antepost.id)){
        await this.setPreload(true);
        var idEvent = antepost.id.split('#')
        this.getActiveTournaments.push(antepost.id)
        await this.getOddsAntepostEvent({'paramKey': idEvent[0], 'type': 'antepost'});
        await this.$root.$emit('filterMatchHook')
        await this.setMatchDetailDialog({'status':true})

        // var split = tournamentId.split("#");
        // var typeOdds = (split[1] == "antepost") ? 'antepostPreviewOdds' : 'previewOdds';
        // await this.fetchEventsPreview({'language': this.getLanguage, 'paramKey': split[0], 'type': typeOdds, 'source':tournamentId});
        await this.setPreload(false);

      }

    },

    async getEventsFromTournament(tournamentId) {

      if(!this.getActiveTournaments.includes(tournamentId)){
        await this.setPreload(true);

        this.getActiveTournaments.push(tournamentId)
        var split = tournamentId.split("#");
        var typeOdds = (split[1] == "antepost") ? 'antepostPreviewOdds' : 'previewOdds';
        await this.fetchEventsPreview({'language': this.getLanguage, 'paramKey': split[0], 'type': typeOdds, 'source':tournamentId});

        var divListCard = document.getElementsByClassName('match-list-card');
        divListCard[0].scrollTop = 0;

        await this.setPreload(false);
      }

    },

  },
}
</script>

<style scoped>

</style>
