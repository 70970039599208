<template>
  <div>
    <v-container fluid class="pb-0">
      <v-row align="center">






        <v-col class="d-flex" cols="12" md="2">
          <v-autocomplete
              v-model="form.sport" :items="getItemsSport" item-text="name" item-value="id"
              outlined dense chips small-chips :label="$t('sport')" @input="getCategoriesFromSport()"

          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" md="2">
          <v-autocomplete
              v-model="form.category" :items="getItemsCategories" item-text="name" item-value="id"
              outlined dense chips small-chips :label="$t('category')" @input="getTournamentsFromCategories()" clearable
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" md="2">
          <v-autocomplete
              v-model="form.tournament" :items="getItemsTournaments" item-text="name" item-value="id"
              outlined dense chips small-chips :label="$t('tournament')" clearable
          ></v-autocomplete>
        </v-col>


        <v-col class="d-flex" cols="12" md="" style="margin-top: -20px; justify-content: flex-end;">
          <!--<v-btn depressed color="primary " @click="resetFilter" class="mr-5">{{ $t('generic.reset') }}</v-btn>-->
          <v-btn depressed color="secondary" @click="searchEvents" @keyup.enter="searchEvents" class="mr-5">{{ $t('generic.search') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';

export default {
  name: "eventsFilter",
  data() {
    return {
      dialogFromTime: false,
      dialogToTime: false,
      filterType: "match",
      filterStatus: "",
      filterQuickbet: "",
      filterSport: "",
      filterCategory: "",
      filterTournament: "",
      filterSearch: "",
      filterId: "",
      filterFromTime: "",
      filterToTime: "",
      form:{
        sport:8412615417,
        category:'',
        tournament:'',

      }
    }
  },
  computed: {
    ...mapGetters({
      'getOptions': 'event/getOptions',
      'getSports': 'sport/getSports',
      'getItemsSport': 'sport/getItems',
      'getCategories': 'category/getCategories',
      'getItemsCategories': 'category/getItems',
      'getTournaments': 'tournament/getTournaments',
      'getItemsTournaments': 'tournament/getItems',
      'getLanguage': 'profile/getLanguage',
    }),
  },
  async mounted() {
    await this.setPreload(true);
    await this.fetchSports({'language': this.getLanguage});
    await this.fetchCategories({'language': this.getLanguage, 'paramKey': this.form.sport});
    await this.fetchEventsBooked({'data': this.form});
    await this.setPreload(false);


  },
  methods: {
    ...mapActions({
      'setPreload': 'utils/setPreload',
      'fetchEventsList': 'event/fetchEventsList',
      'setCriteria': 'event/setCriteria',
      'fetchSports': 'sport/fetchSports',
      'fetchCategories': 'category/fetchCategories',
      'fetchTournaments': 'tournament/fetchTournaments',
      'resetTournaments': 'tournament/resetTournaments',
      'fetchEventsBooked': 'live/fetchEventsBooked'
    }),

    async searchEvents(){
      await this.setPreload(true);
      await this.fetchEventsBooked({'data': this.form});
      await this.setPreload(false);
     /* var criteria = {
        ...(this.filterId !== '') && {id: +this.filterId},
        ...(this.filterQuickbet !== '') && {quickbet: this.filterQuickbet},
        ...(this.filterType !== '') && {type: this.filterType},
        ...(this.filterStatus !== '') && {status: this.filterStatus},
        ...(this.filterSearch !== '') && {search: this.filterSearch},
        ...(this.filterSport !== '') && {sport: this.filterSport},
        ...(this.filterCategory !== '') && {category: this.filterCategory},
        ...(this.filterTournament !== '') && {tournament: this.filterTournament},
        ...(this.filterFromTime !== '') && {fromTime: new Date(this.filterFromTime).getTime()},
        ...(this.filterToTime !== '') && {toTime: new Date(this.filterToTime).getTime()+86399000},
      }

      this.getOptions.page = 1
      criteria.page = this.getOptions.page
      criteria.limit = this.getOptions.limit

      await this.setPreload(true);
      await this.setCriteria(criteria)
      await this.fetchEventsList(criteria)
      await this.setPreload(false);*/


    },

    async getCategoriesFromSport() {
      await this.setPreload(true);
      await this.fetchCategories({'language': this.getLanguage, 'paramKey': this.form.sport});
      this.form.category=''
      this.form.tournament=''
      await this.setPreload(false);
    },

    async getTournamentsFromCategories() {
      await this.setPreload(true);
      await this.fetchTournaments({'language': this.getLanguage, 'paramKey': this.form.category});
      this.form.tournament=''
      await this.setPreload(false);
    },

    async resetFilter(){

     /* this.filterId = ''
      this.filterQuickbet = ''
      this.filterType = ''
      this.filterStatus = ''
      this.filterSearch = ''
      this.filterSport = ''
      this.filterCategory = ''
      this.filterTournament = ''
      this.filterFromTime = ''
      this.filterToTime = ''

      await this.searchEvents()*/

    },
    async handleKeyDown(e) {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        await this.searchEvents()
      }
    },
  },
  created() {
    //window.addEventListener('keydown', this.handleKeyDown);
  },
  destroyed() {
    //window.removeEventListener('keydown', this.handleKeyDown);
  }
}
</script>

<style scoped>

</style>