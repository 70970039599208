import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import en from 'vuetify/lib/locale/en'
import es from 'vuetify/lib/locale/es'
import fr from 'vuetify/lib/locale/fr'
import pt from 'vuetify/lib/locale/pt'
import it from 'vuetify/lib/locale/it'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: '#575756',
                secondary: '#A0c528',
                accent: '#8c9eff',
                error: '#b71c1c',
            },
        },
        options: {
            customProperties: true,
        },
    },
    lang: {
        locales: { en, es, fr, pt, it },
        current: 'en'
    },

});
