<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md12>
          <v-col cols="12">
            <span class="title-table"></span>

            <v-card outlined elevation-0 class="mt-1">
              <v-data-table :headers="headers" :items="mixed" class="elevation-1 head-capitalize"  >

                <template v-slot:item.id="{ item }">
                  <span class="font-weight-bold">{{ item.id }}</span>
                </template>

                <template v-slot:item.quickbet="{ item }">
                  <span class="text-uppercase">{{ item.quickbet }}</span>
                </template>
                <template v-slot:item.source="{ item }">
                  <span class="text-uppercase">{{ item.source[0].value }}</span>
                </template>

                <template v-slot:item.scheduled="{ item }">
                  <span v-if="item.scheduled != null">{{ new Date(item.scheduled).toLocaleString() }}</span>
                </template>

                <template v-slot:item.event="{ item }">
                  {{getDescription(item)}}
                </template>

                <template v-slot:item.type="{ item }">
                  {{$t(getEventType(item.type)) | capitalize}}
                </template>

                <template v-slot:item.sport="{ item }">
                  {{ getDetail(item.sport.detail) }}
                </template>

                <template v-slot:item.category="{ item }">
                  {{ getDetail(item.category.detail) }}
                </template>

                <template v-slot:item.tournament="{ item }">
                  {{ getDetail(item.tournament.detail) }}
                </template>



                <template v-slot:item.actions="{ item }">
                  <span>
                    <v-icon :title="$t('bets.detail')"  @click="defineResult(item)">mdi-file-find</v-icon>
                  </span>
                </template>

              </v-data-table>


            </v-card>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>

    <div class="text-center">
      <v-dialog v-model="dialogDefineSign" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Status
          </v-card-title>

          <v-card-text class="pt-5">
            <p class="font-weight-bold">{{this.descriptionToDefine}}</p>
            <v-select
                :items="results"
                item-text="name"
                item-value="value"
                filled
                v-model="resultToDefine"
            ></v-select>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions v-if="resultToDefine != ''">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="sendResult()">
              {{ $t('generic.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import _ from "lodash";




export default {
  name: "Duplicate",
  components: {},
  data() {
    return {
      antepost: [],
      mixed: [],
      headers: [
        {text: this.$t('generic.id'), value: 'id', width: '3%', sortable: false},
        {text: this.$t('quickbet'), value: 'quickbet', width: "3%"},
        {text: "source", value: 'source', width: "3%"},
        {text: this.$t('bets.date'), value: 'scheduled', width: "7%"},
        {text: this.$t('bets.event'), value: 'event', width: "20%"},
        {text: this.$t('bets.type'), value: 'type', width: "3%"},
        {text: this.$t('sport'), value: 'sport', width: "5%", sortable: false},
        {text: this.$t('category'), value: 'category', width: "5%", sortable: false},
        {text: this.$t('tournament'), value: 'tournament', width: "8%", sortable: false},

        {text: this.$t('bets.actions'), value: 'actions', sortable: false, width: "10%"},
      ],

    results:[
        {'name': this.$t('generic.selectOption'), 'value':''},
        {'name': "Not started yet", 'value':1},
        {'name': "In progress", 'value':2},
        {'name': "Finished", 'value':3},
        {'name': "Cancelled", 'value':4},
        {'name': "Postponed", 'value':5},
        {'name': "Interrupted", 'value':6},
        {'name': "Abandoned", 'value':7},
        {'name': "Coverage lost", 'value':8},
        {'name': "About to start", 'value':9},
      ],
      dialogDefineSign: false,
      signToDefine:{},
      resultToDefine: "",
      descriptionToDefine: ""
    }
  },
  computed: {
    ...mapGetters({
      'getLanguage': 'profile/getLanguage',
      'getToken': "profile/getToken",
      'getPlatformId': "rules/getPlatformId",
      'getOptions': 'bet/getOptions',
      'getUrlApi': 'settings/getUrl',
      'getEventsList': 'event/getEventsList'
    }),

  },

  async created() {
    await this.setPreload(true);
    await this.fetchDuplicate()
    await this.setPreload(false);
  },
  methods: {
    ...mapActions({
      'setPreload': 'utils/setPreload',
      'recoveryBetSettlement': 'bet/recoveryBetSettlement',
      'defineOdd': 'bet/defineOdd',
    }),
    ...mapMutations({
      'setLanguage': 'profile/setLanguage',
    }),

    async fetchDuplicate() {

      var vue = this
      var mixed = []
      var mixed_sources = []
      var antepost = []
      var antepost_sources = []

      let url = `${this.getUrlApi}api/get-duplicates`;
      axios.get(`${url}`,  {'headers': {'Authorization': `Bearer ${this.getToken}`}}).then((result) => {

        //console.log(result.data)
        this.mixed = result.data.data

      })

    },

    async defineResult(item){
      await this.setPreload(true);
      this.signToDefine = {
        item: item,
        result: "",
      }

      // if(desc == undefined){
      //   desc = tournament.name + ' - ' + market
      // }
      this.descriptionToDefine = "Status"
      this.dialogDefineSign = true
      await this.setPreload(false);
    },

    async recoverySettlement(eventId){
      await this.setPreload(true);
      await this.recoveryBetSettlement({event_id : eventId})
      await this.setPreload(false);
    },
    async sendResult(){
      await this.setPreload(true);
      this.signToDefine.result= this.resultToDefine
      let url = `${this.getUrlApi}api/set-duplicates`;
      axios.post(`${url}`, this.signToDefine, {'headers': {'Authorization': `Bearer ${this.getToken}`}}).then(async(result) => {
        this.signToDefine = {}
        await this.fetchDuplicate()
        this.resultToDefine=""
        this.dialogDefineSign = false
        this.setPreload(false);
      })
    },
    searchOnGoogle(key){
      let url = 'https://www.google.com/search?q='+key
      window.open(url, '_blank').focus()
    },
    getDescription(item){
      if(item.type == 'match'){
        var home = _.find(item.competitors[0].detail, {'lang':this.getLanguage})
        var away = _.find(item.competitors[1].detail, {'lang':this.getLanguage})
        return home.text+' - '+away.text
      }else if(item.type == 'antepost'){
        var tournament = _.find(item.tournament.detail, {'lang':this.getLanguage})
        return 'Antepost '+tournament.text
      }
    },

    getDetail(detail){
      var detail = _.find(detail, {'lang':this.getLanguage})
      return detail.text
    },

    getStatus(status){
      switch (status) {
        case 1:
          return 'nsy'
        case 2:
          return 'inProgress'
        case 3:
          return 'finished'
        case 4:
          return 'cancelled'
        case 5:
          return 'postponed'
        case 6:
          return 'interrupted'
        case 7:
          return 'abandoned'
        case 8:
          return 'coverageLost'
        case 9:
          return 'aboutToStart'
      }
    },

    getEventType(type){
      switch (type){
        case 'match':
          return 'prematch'
        case 'antepost':
          return 'antepost'
      }
    },
    getSpread(spec) {
      if(spec.includes('#')){
        var split = spec.split('#')
        if(split[1].includes(':')){
          return '('+split[1]+')'
        }else if(split[1].includes('(') && split[1].includes('.')){
          return ' ['+split[1]+'] '
        }else if(split[1] != ''){
          return split[1]
        }else {
          return ''
        }
      }else {
        return ''
      }
    }


  },
}
</script>

<style scoped>
.event-desc{
  font-size: 14px;
  padding-left: 10px;
  font-weight: 700;
}
.info-label{
  font-size: 11px;
  color: #888888;
}
</style>
