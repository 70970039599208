import axios from "axios";
import _ from "lodash";

const state = {
    list: [],
    items: [],
};

const getters = {
    getList: state => {
        return state.list;
    },
    getItems(state, getters) {
        return state.items
    },
};

const actions = {
    async fetchCompetitors (context, payload) {

        let url = `${context.rootGetters['settings/getUrlApi']}get-competitors`;
        await axios.post(`${url}`,{'search': payload.search},{ 'headers': { 'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`} })

            .then( res => {
                //console.log(res.data)
                context.dispatch('applyRules',res.data).then((items_with_rules) => {
                //console.log("Fabio items_with_rules",items_with_rules)
                    // Salvo gli elementi con le regole applicate
                    context.commit('setCompetitorsList',items_with_rules);

                })

            })
            .catch(err => {
                console.log(err);
            });
    },
    async addCompetitor(context, payload) {

        state.items.push({
            active:true,
            name:payload.name
        });

        return

    },
    async filterItems(context, payload) {

        let items_filtered = [];

        await _.forEach(payload.items, function (item) {
            var response = _.find(item.detail, {"lang": payload.lang})
            var textDesc = (response !== undefined) ? response.text : '*' + item.detail[0].text
            items_filtered.push({
                'id': item.id,
                'name': textDesc,
                'source': item.source,
                'detail': item.detail,
                'active': item.active,
                'order': item.order
            });
        })

        return _.sortBy(items_filtered, ['order', 'name'])

    },
    async fetchCompetitorByTournaments(context, payload) {

        let url = `${context.rootGetters['settings/getUrlApi']}getcompetitorbytournament/${payload.paramKey}`;

        await axios.get(`${url}`,  {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

               // console.log(res.data.tournaments)
                // Applico le regole
                context.dispatch('applyRules', res.data.tournaments).then((items_with_rules) => {

                    // Salvo gli elementi con le regole applicate
                    context.commit('setCompetitorsList', items_with_rules);

                    // Filtro gli items da mostrare
                    context.dispatch('filterItems', {items: items_with_rules, lang: payload.language}).then((items) => {

                        context.commit('setItems', items);

                    })


                })

            })
            .catch((error) => {
                console.log(error);
            });
    },
    async applyRules(context, items){

        let scope = 'competitor';
        let onlyManager = (context.rootGetters['rules/getPlatformId'] == 0);

        var skinDetail
        if(!onlyManager){
            skinDetail = _.find(context.rootGetters['skins/getList'], {"platform_id":context.rootGetters['rules/getPlatformId']})
        }


        const checkRules = _.filter(context.rootGetters['skins/getManager'].rules, {'scope':scope})

        if(checkRules.length > 0){

            // console.log('Applico Regole Manager Sports')

            const rulesManager = _.groupBy(checkRules, 'type');

            _.forEach(rulesManager, function(rulesGroup, index) {

                if(index == "translation"){

                    _.forEach(rulesGroup, function(rule, index) {

                        let index_of_item =  _.find(items,{'id':+rule.source})
                        if(index_of_item){

                            index_of_item.detail = rule.detail
                        }
                        //console.log("index_of_item",index_of_item)
                        /*var index_of_item = _.findIndex(items,{'id':+rule.source});
                        if(index_of_item > -1)
                            items[index_of_item].detail = rule.detail*/

                    })

                }

            });


        }else{

            // console.log('Sport: Nessuna regola per il Manager')

        }

        if(!onlyManager){

            const checkRulesSkin = _.filter(skinDetail.rules, {'scope':scope})

            if(checkRulesSkin.length > 0 ){
                // console.log('Applico Regole Sports Skin ID: '+context.rootGetters['rules/getPlatformId'])

                const rulesSkin = _.groupBy(checkRulesSkin, 'type');

                _.forEach(rulesSkin, function(rulesGroup, index) {

                    if(index == "translation"){

                        _.forEach(rulesGroup, function(rule, index) {

                            let index_of_item =  _.find(items,{'id':+rule.source})
                            if(index_of_item){

                                index_of_item.detail = rule.detail
                            }

                        })

                    }

                });

            }else{

                // console.log('Sport: Nessuna regola per la Skin')

            }
        }

        return items

    }

};

const mutations = {
    setCompetitorsList(state,payload) {
        state.list = payload;
    },
    resetCompetitorsList(state) {
        state.list = [];
    },
    setItems(state, payload) {
        state.items = payload;
    },
};



export default {
    namespaced : true,
	state,
	mutations,
	getters,
	actions
}
