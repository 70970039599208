import axios from "axios";

const state = {
    events: [],

};

const getters = {
    getEvents(state,getters){
        return state.events;
    },


};

const actions = {



  async fetchEvents(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}events/get-event-manual`;

        return await axios.post(`${url}`,
            payload,
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                context.commit('setEvents', res.data.data);


            }).catch((error) => {
                console.log(error);
            });
    },



};

const mutations = {

    setEvents(state,payload) {
        state.events = payload;
    },

};



export default {
    namespaced : true,
	state,
	mutations,
	getters,
	actions
}
