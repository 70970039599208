if (location.protocol !== 'https:' && process.env.NODE_ENV == "production") {
    location.replace(`https:${location.href.substring(location.protocol.length)}`);
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import {i18n} from '@/plugins/vuei18n';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

Vue.use(require('vue-moment'));
Vue.config.productionTip = false

import {setupComponents} from './config';

setupComponents(Vue);


import axios from 'axios';
//axios.defaults.baseURL = 'http://127:0.0.1:3000';
//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//axios.defaults.headers.common['X-CSRF-TOKEN'] = Vue.csrf.get();
//console.dir(store.getters.settings/getName);
//const  accessToken  = store.state.profile.token

//if (accessToken) {
//  axios.defaults.headers.common['Authorization'] =  "Bearer "+accessToken
//}
import VueSocketio from 'vue-socket.io'
import io from 'socket.io-client';
const socketInstance = io(process.env.VUE_APP_URL_SOCKET, {
    transports: ['websocket'],
    upgrade: true
});

// //Vue.use(new VueSocketio,'http://127.0.0.1:4000',storeSocket)
Vue.use(new VueSocketio({
        debug: false,
        secure: true,
        connection: socketInstance, //options object is Optional
        vuex: {
            store,
            actionPrefix: "SOCKET_",
            mutationPrefix: "SOCKET_"
        }
    })
);

axios.interceptors.request.use(config => {
    //console.log('Request Interceptor', config);
    return config;
});

axios.interceptors.response.use(res => {
    //console.log('Response Interceptor', res);
    return res;
});

import VueLodash from 'vue-lodash'
import lodash from 'lodash'

Vue.use(VueLodash, {lodash: lodash})

import VueNumeric from 'vue-numeric'

Vue.use(VueNumeric)

import VueNumericInput from 'vue-numeric-input';

Vue.use(VueNumericInput)

import JsonViewer from 'vue-json-viewer'

Vue.use(JsonViewer)

import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('truncate', function (text, length, clamp) {
    clamp = clamp || '';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
})

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

new Vue({
    i18n,
    router,
    store,
    vuetify,
    axios,
    beforeCreate() {
          store.commit('socket/setSocket', this.$socket)
          store.dispatch('socket/socket_login', {})
    },

    render: h => h(App)
}).$mount('#app')
