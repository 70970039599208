import axios from "axios";

const state = {
    blockedUsers: []
};

const getters = {
    getBlockedUsers: state => {
        return state.blockedUsers;
    },
};

const actions = {

    async fetchBlockedUsers(context, payload) {

        const url = `${context.rootGetters['settings/getUrl']}users/blocked/getblockedusers`;
        const platformId = context.rootGetters['rules/getPlatformId'];

        if (platformId == null || platformId == 0 || platformId == '') {
            return
        }

        try {
            const response = await axios.get(`${url}` + `?platformId=` + platformId, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            let usersBlocked = [];

            if (response && response.data && response.data.usersBlocked) {
                usersBlocked = response.data.usersBlocked;
            }

            const idUsersBlocked = [];

            for (const user of usersBlocked) {
                idUsersBlocked.push(user.id);
            }

            const objSave = {
                platformId: platformId,
                idUsersBlocked: idUsersBlocked
            }

            context.commit('addBlockedUsers', objSave);

        } catch (e) {
            console.log(e)
        }
    },

    async isUserBlocked(context, payload) {

        const url = `${context.rootGetters['settings/getUrl']}users/blocked/check`;
        const platformId = payload.platformId;
        const idUser = payload.idUser;

        if (platformId == null || platformId == 0 || platformId == '') {
            return
        }

        if (idUser == null || idUser == '') {
            return
        }

        try {
            // console.log('[Debug] calling api for check if user is blocked')
            const response = await axios.get(`${url}` + `?platformId=` + platformId + '&idUser=' + idUser, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})

            let isBlocked = false;

            if (response && response.data && response.data.isBlocked) {
                // console.log('response...')
                isBlocked = response.data.isBlocked
                //console.log('idUser: ' + idUser + 'platform: ' + platformId + ' blocked: ' + isBlocked)
            }

            const payloadForUpdateStatus = {
                idUser: idUser,
                platformId: platformId,
                isBlocked: isBlocked
            }

            context.commit('UPDATE_USER_STATUS', payloadForUpdateStatus);

        } catch (e) {
            console.log(e)
        }
    },

    async addANote(context, payload) {

        const url = `${context.rootGetters['settings/getUrl']}users/notes`;
        const platformId = context.rootGetters['rules/getPlatformId'];
        const idUser = payload.idUser;
        const message = payload.message;

        if (platformId == null || platformId == 0 || platformId == '') {
            return
        }

        if (idUser == null || idUser == '') {
            return
        }

        const payloadRequest = {
            platformId: platformId,
            idUser: idUser,
            message: message
        }

        try {

            const response = axios.post(`${url}`,
                {...payloadRequest},
                {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})

            return response

        } catch (e) {
            console.log(e)
        }
    },
    async fetchUserNotes(context, payload) {

        const platformId = context.rootGetters['rules/getPlatformId'];
        const idUser = payload.idUser;
        const url = `${context.rootGetters['settings/getUrl']}users/notes?platformId=` + platformId + `&idUser=` + idUser;

        if (platformId == null || platformId == 0 || platformId == '') {
            return
        }

        try {

            const response = await axios.get(`${url}`, {
                'headers': {
                    'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })

            return response
        } catch (e) {
            console.log(e)
        }

    },
    async getLastUserNoteForUser(context, payload) {

        const platformId = context.rootGetters['rules/getPlatformId'];
        const idUser = payload.idUser;
        const url = `${context.rootGetters['settings/getUrl']}users/notes/lastcreated?platformId=` + platformId + `&idUser=` + idUser;

        if (platformId == null || platformId == 0 || platformId == '') {
            return
        }

        try {

            const response = await axios.get(`${url}`, {
                'headers': {
                    'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })

            let message = null;
            if (response.data && response.data.result) {
                message = response.data.result;
            }

            return message
        } catch (e) {
            console.log(e)
        }
    },

    async updateUserNote(context, payload) {

        const platformId = context.rootGetters['rules/getPlatformId'];
        const idUser = payload.idUser;
        const message = payload.message;
        const _id = payload._id;

        const url = `${context.rootGetters['settings/getUrl']}users/notes/`;

        if (_id == null || _id == '') {
            return
        }

        try {

            const payloadRequest = {
                _id: _id,
                platformId: platformId,
                idUser: idUser,
                message: message
            }

            const response = axios.put(`${url}`,
                {...payloadRequest},
                {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})

            return response

        } catch (e) {
            console.log(e)
        }
    }

};

const mutations = {

    setBlockedUsers(state, payload) {
        state.blockedUsers = payload;
    },

    UPDATE_USER_STATUS(state, payload) {

        const platformId = payload.platformId;
        const idUser = payload.idUser;
        const isBlocked = payload.isBlocked;
        const saveState = state.blockedUsers;

        const findIndexPlatform = saveState.findIndex(item => {
            return item.platformId == platformId
        })

        if (findIndexPlatform != -1) { //  platform found

            let idUsersBlocked = state.blockedUsers[findIndexPlatform].idUsersBlocked;

            if (isBlocked == true) {
                idUsersBlocked.push(idUser.toString())
            } else {
                idUsersBlocked = idUsersBlocked.filter(item => {
                    return item != idUser
                })
            }

            state.blockedUsers[findIndexPlatform].idUsersBlocked = idUsersBlocked;
        } else {

            if (isBlocked == true) {
                const idUsersBlocked = [];
                idUsersBlocked.push(idUser.toString())

                const dataToSave = {
                    platformId: platformId,
                    idUsersBlocked: idUsersBlocked
                }
                const saveState = state.blockedUsers;
                saveState.push(dataToSave);
                state.blockedUsers = null;
                state.blockedUsers = saveState;
            }
        }
    },

    addBlockedUsers(state, payload) {
        const saveState = state.blockedUsers;
        const platformId = payload.platformId;

        const findIndex = saveState.findIndex(item => {
            return item.platformId == platformId
        })

        if (findIndex != -1) {
            state.blockedUsers[findIndex] = payload;
        } else {
            state.blockedUsers.push(payload);
        }

    },

    ADD_USER_IN_BLOCKED_STATUS_FOR_A_PLATFORM(state, payload) {

        const platformId = payload.platformId;
        const idUser = payload.idUser;
        const saveState = state.blockedUsers;

        const findIndex = saveState.findIndex(item => {
            return item.platformId == platformId
        })

        if (findIndex != -1) { // platform found

            let currentIdUsersBlocked = state.blockedUsers[findIndex].idUsersBlocked;
            currentIdUsersBlocked.push(idUser.toString());
            state.blockedUsers[findIndex].idUsersBlocked = currentIdUsersBlocked;
        } else {

            const idUsersBlocked = [];
            idUsersBlocked.push(idUser.toString());

            state.blockedUsers.push({
                idUsersBlocked: idUsersBlocked,
                platformId: platformId
            });
        }
    },

    REMOVE_USER_IN_BLOCKED_STATUS_FOR_A_PLATFORM(state, payload) {

        const platformId = payload.platformId;
        const idUser = payload.idUser;
        const saveState = state.blockedUsers;

        const findIndex = saveState.findIndex(item => {
            return item.platformId == platformId
        })

        if (findIndex != -1) {

            let currentIdUsersBlocked = state.blockedUsers[findIndex].idUsersBlocked;

            currentIdUsersBlocked = currentIdUsersBlocked.filter(user => {
                return user != idUser
            })
            state.blockedUsers[findIndex].idUsersBlocked = currentIdUsersBlocked;
        }
    }
};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
