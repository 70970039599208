<template>
  <div>
    <v-container fluid class="pb-0">
      <v-row align="center">

        <v-col md="2">
          <v-text-field class="text-capitalize" :label="$t('id')" dense v-model="filterId" outlined></v-text-field>
        </v-col>

        <v-col md="2">
          <v-text-field class="text-capitalize" :label="$t('quickbet')" dense v-model="filterQuickbet" outlined></v-text-field>
        </v-col>

        <v-col md="2">
          <v-text-field class="text-capitalize" :label="$t('searchByCompetitor')" dense v-model="filterSearch" outlined></v-text-field>
        </v-col>

        <v-col md="2">
          <v-dialog ref="dialog" v-model="dialogFromTime" width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense v-model="filterFromTime" :label="$t('generic.from')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
            </template>
            <v-date-picker v-model="filterFromTime" scrollable @input="dialogFromTime = false"></v-date-picker>
          </v-dialog>
        </v-col>

        <v-col md="2">
          <v-dialog ref="dialog" v-model="dialogToTime" width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense v-model="filterToTime" :label="$t('generic.to')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
            </template>
            <v-date-picker v-model="filterToTime" scrollable @input="dialogToTime = false"></v-date-picker>
          </v-dialog>
        </v-col>

        <v-col md="2">
          <v-text-field class="text-capitalize" label="source" dense v-model="filterSource" outlined></v-text-field>
        </v-col>


        <v-col class="d-flex" cols="12" md="2">
          <v-select class="text-capitalize" :items="typeOfEvent" item-text="text" item-value="value"  :label="$t('generic.type')" dense outlined v-model="filterType"></v-select>
        </v-col>
        <v-col class="d-flex" cols="12" md="2">
          <v-autocomplete
              v-model="filterSport" :items="getItemsSport" item-text="name" item-value="id"
              outlined dense chips small-chips :label="$t('sport')" @input="getCategoriesFromSport()"
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" md="2">
          <v-autocomplete
              v-model="filterCategory" :items="getItemsCategories" item-text="name" item-value="id"
              outlined dense chips small-chips :label="$t('category')" @input="getTournamentsFromCategories()"
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" md="2">
          <v-autocomplete
              v-model="filterTournament" :items="getItemsTournaments" item-text="name" item-value="id"
              outlined dense chips small-chips :label="$t('tournament')"
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" md="2">
          <v-select class="text-capitalize" :items="typeOfStatus" item-text="text" item-value="value" :label="$t('bets.status')" dense outlined v-model="filterStatus"></v-select>
        </v-col>

        <v-col class="d-flex" cols="12" md="" style="margin-top: -20px; justify-content: flex-end;">
          <v-btn depressed color="primary " @click="resetFilter" class="mr-5">{{ $t('generic.reset') }}</v-btn>
          <v-btn depressed color="secondary" @click="searchEvents" @keyup.enter="searchEvents" class="mr-5">{{ $t('generic.search') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';

export default {
  name: "eventsFilter",
  data() {
    return {
      dialogFromTime: false,
      dialogToTime: false,
      filterType: "match",
      filterStatus: "",
      filterQuickbet: "",
      filterSport: "",
      filterCategory: "",
      filterTournament: "",
      filterSearch: "",
      filterSource: "",
      filterId: "",
      filterFromTime: "",
      filterToTime: "",
      typeOfEvent: [
        {text: this.$t('generic.selectOption'), value: ""},
        {text: this.$t('bets.prematch'), value: "match"},
        {text: this.$t('bets.antepost'), value: "antepost"},
      ],
      typeOfStatus: [
        {text: this.$t('generic.selectOption'), value: ""},
        {text: this.$t('nsy'), value: 1},
        {text: this.$t('inProgress'), value: 2},
        {text: this.$t('finished'), value: 3},
        {text: this.$t('cancelled'), value: 4},
        {text: this.$t('postponed'), value: 5},
        {text: this.$t('interrupted'), value: 6},
        {text: this.$t('abandoned'), value: 7},
        {text: this.$t('coverageLost'), value: 8},
        {text: this.$t('aboutToStart'), value: 9},
      ],


    }
  },
  computed: {
    ...mapGetters({
      'getOptions': 'event/getOptions',
      'getSports': 'sport/getSports',
      'getItemsSport': 'sport/getItems',
      'getCategories': 'category/getCategories',
      'getItemsCategories': 'category/getItems',
      'getTournaments': 'tournament/getTournaments',
      'getItemsTournaments': 'tournament/getItems',
      'getLanguage': 'profile/getLanguage',
    }),
  },
  async mounted() {
    await this.fetchSports({'language': this.getLanguage});
  },
  methods: {
    ...mapActions({
      'setPreload': 'utils/setPreload',
      'fetchEventsList': 'event/fetchEventsList',
      'setCriteria': 'event/setCriteria',
      'fetchSports': 'sport/fetchSports',
      'fetchCategories': 'category/fetchCategories',
      'fetchTournaments': 'tournament/fetchTournaments',
      'resetTournaments': 'tournament/resetTournaments',
    }),

    async searchEvents(){

      var criteria = {
        ...(this.filterId !== '') && {id: +this.filterId},
        ...(this.filterQuickbet !== '') && {quickbet: this.filterQuickbet},
        ...(this.filterType !== '') && {type: this.filterType},
        ...(this.filterStatus !== '') && {status: this.filterStatus},
        ...(this.filterSearch !== '') && {search: this.filterSearch},
        ...(this.filterSource !== '') && {source: this.filterSource},
        ...(this.filterSport !== '') && {sport: this.filterSport},
        ...(this.filterCategory !== '') && {category: this.filterCategory},
        ...(this.filterTournament !== '') && {tournament: this.filterTournament},
        ...(this.filterFromTime !== '') && {fromTime: new Date(this.filterFromTime).getTime()},
        ...(this.filterToTime !== '') && {toTime: new Date(this.filterToTime).getTime()+86399000},
      }

      this.getOptions.page = 1
      criteria.page = this.getOptions.page
      criteria.limit = this.getOptions.limit

      await this.setPreload(true);
      await this.setCriteria(criteria)
      await this.fetchEventsList(criteria)
      await this.setPreload(false);


    },

    async getCategoriesFromSport() {
      await this.setPreload(true);
      await this.fetchCategories({'language': this.getLanguage, 'paramKey': this.filterSport});
      await this.resetTournaments()
      await this.setPreload(false);
    },

    async getTournamentsFromCategories() {
      await this.setPreload(true);
      await this.fetchTournaments({'language': this.getLanguage, 'paramKey': this.filterCategory});
      await this.setPreload(false);
    },

    async resetFilter(){

      this.filterId = ''
      this.filterQuickbet = ''
      this.filterType = ''
      this.filterStatus = ''
      this.filterSearch = ''
      this.filterSport = ''
      this.filterCategory = ''
      this.filterTournament = ''
      this.filterFromTime = ''
      this.filterToTime = ''

      await this.searchEvents()

    },
    async handleKeyDown(e) {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        await this.searchEvents()
      }
    },
  },
  created() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }
}
</script>

<style scoped>

</style>