<template>
    <div id="manageMarketGroups">
        <v-container fluid>
            <v-row>
                <v-col cols="2">
                    <v-autocomplete
                            v-model="searchSport"
                            :items="getItemsSport"
                            item-text="name"
                            item-value="_id"
                            outlined
                            dense
                            chips
                            small-chips
                            :label="$t('generic.sport')"
                            deletable-chips
                    ></v-autocomplete>
                </v-col>
                <v-col cols="2">
                    <v-autocomplete
                            v-model="searchProducer"
                            :items="getProducers"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                            chips
                            small-chips
                            :label="$t('generic.context')"
                            deletable-chips
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </v-container>

        <v-container fluid class="pl-0">
            <v-row>
                <v-col cols="12">
                    <template>
                        <v-expansion-panels>
                            <v-expansion-panel
                                    v-for="(item,i) in 1"
                                    :key="i"
                            >
                                <v-expansion-panel-header style="padding:0px">
                                    <div class="col-md-1 col-xs-1">
                                        <v-btn elevation="2"
                                               raised
                                               small>
                                            {{$t('generic.otherActions')}}
                                        </v-btn>
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="pl-0">

                                    <div class="col-md-12 col-xs-12 ">
                                        <p>{{$t('generic.copyMarketGroupsAndOutcomeSettingToAnotherPlatform')}}</p>
                                    </div>
                                    <div style="display: flex">
                                        <div class="col-md-1 col-xs-6">
                                            <span class="hint">{{ $t('generic.selectDestinationPlatform') }}</span>
                                        </div>

                                        <div class="col-md-2 col-xs-6">
                                            <div class="select-platform">
                                                <v-autocomplete
                                                        v-model="destinationPlatformId"
                                                        :items="destinationPlatforms"
                                                        item-text="name"
                                                        item-value="platform_id"
                                                        dense
                                                        filled>
                                                </v-autocomplete>
                                            </div>
                                        </div>

                                        <v-col cols="2" class="pt-4">
                                            <v-btn color="green" class="white--text text-right "
                                                   @click="copyMarketGroup()" v-if="destinationPlatformId != null"
                                                   small
                                                   :title="$t('copy')">
                                                <v-icon left dark>save</v-icon>
                                                {{$t('copy')}}
                                            </v-btn>
                                        </v-col>
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </template>
                </v-col>
            </v-row>

        </v-container>
        <v-container fluid
                     v-if="searchSport != -1 && searchProducer != -1 ">
            <v-row align="center">
                <v-col cols="12">
                    <v-tabs vertical class="simple-border">
                        <v-tab v-for="(group, index) in dataGroups"
                               class="tab-group-left" :key="group._id">
                            <v-icon left>
                                mdi-view-dashboard
                            </v-icon>
                            {{ getNameTranslated(group)}}
                        </v-tab>

                        <v-tab-item v-for="(group_content, index_content) in dataGroups"
                                    :key="group_content._id">
                            <v-card flat>
                                <v-form>
                                    <v-container>
                                        <v-row justify="end" dense>
                                            <v-btn color="blue-grey" class="white--text text-right"
                                                   @click="updateGroupMarket(group_content)">
                                                <v-icon left dark>save</v-icon>
                                                {{ $t('generic.save') }}
                                            </v-btn>

                                            <v-btn color="red" class="white--text"
                                                   @click="removeFromGroupV2(group_content)"
                                                   style="margin-left: 10px;">
                                                <v-icon left dark>delete</v-icon>
                                                {{ $t('generic.delete') }}
                                            </v-btn>
                                        </v-row>

                                        <v-row justify="start" dense>
                                            <v-col cols="12"><h5>{{ $t('sort') }}</h5></v-col>
                                            <v-col cols="3">
                                                <vue-numeric-input v-model="group_content.sort"
                                                                   controlsType="updown"></vue-numeric-input>
                                            </v-col>
                                        </v-row>

                                        <v-row justify="center" dense>
                                            <v-col cols="12"><h5>{{ $t('groupName') }}</h5></v-col>
                                            <v-col cols="3"
                                                   v-for="(language,index) in languages"
                                                   :key="index">
                                                <v-text-field
                                                        :label="language.lang"
                                                        :placeholder="language.lang"
                                                        :value="getTranslatedValueFromMarketGroup(group_content, language.lang)"
                                                        @change="updateTranslation($event, group_content, language.lang )"
                                                        outlined
                                                        dense
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-card>
                        </v-tab-item>

                        <v-tab class="tab-group-left">
                            <v-icon left>
                                mdi-plus
                            </v-icon>
                            {{ $t('newGroup') }}
                        </v-tab>

                        <v-tab-item>
                            <v-card flat>
                                <v-form>
                                    <v-container>
                                        <v-row justify="center">
                                            <v-col cols="12" sm="12" md="8">

                                                <v-text-field :label="$t('groupName')"
                                                              placeholder="ad es. Popolari"
                                                              v-model="newGroupName">
                                                </v-text-field>
                                            </v-col>

                                            <v-row justify="center" dense>
                                                <v-col cols="12"><h5>{{ $t('sort') }}</h5></v-col>
                                                <v-col cols="12">
                                                    <vue-numeric-input v-model="sort"
                                                                       controlsType="updown">
                                                    </vue-numeric-input>
                                                </v-col>
                                                <v-col cols="12"><h5>{{ $t('groupName') }}</h5></v-col>
                                                <v-col cols="3"
                                                       v-for="(lang,index) in languages"
                                                       :key="index">
                                                    <v-text-field
                                                            :label="lang.lang"
                                                            :placeholder="lang.lang"
                                                            v-model="lang.text"
                                                            outlined
                                                            dense
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-col cols="12" sm="12" md="12" class="text-right">
                                                <v-btn color="blue-grey" class="white--text"
                                                       @click="createNewGroupV2()">
                                                    {{ $t('save') }}
                                                    <v-icon right dark>save</v-icon>
                                                </v-btn>
                                            </v-col>

                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-card>
                        </v-tab-item>
                    </v-tabs>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import _ from "lodash";

    export default {
        name: "manageMarketGroup",
        data() {
            return {
                search: '',
                searchSport: -1,
                searchProducer: -1,
                newGroupName: '',
                type: [
                    {text: "Gruppi di Quote", value: "groupOdds"},
                ],
                dataGroups: {},
                listPlatform: [],
                marketsList: [],
                languages: [
                    {
                        lang: 'it',
                        text: ''
                    },
                    {
                        lang: 'en',
                        text: ''
                    },
                    {
                        lang: 'es',
                        text: ''
                    },
                    {
                        lang: 'pt',
                        text: ''
                    },
                    {
                        lang: 'fr',
                        text: ''
                    },
                    {
                        lang: 'tr',
                        text: ''
                    },
                    {
                        lang: 'cn',
                        text: ''
                    },
                    {
                        lang: 'de',
                        text: ''
                    }
                ],
                sort: 1,
                destinationPlatformId: null
            }
        },

        watch: {
            getLanguage: async function (val) {
                await this.setPreload(true);
                await this.setLanguage(val);
                await this.filterSport({'language': val});
                await this.setPreload(false);
            },

            searchSport() {
                this.getData()
            },

            searchProducer() {
                this.getData()
            },

            getMarketsMini() {
                this.marketsList = this.getMarketsMini.markets
            }
        },

        computed: {
            ...mapGetters({
                'getLanguage': 'profile/getLanguage',
                'getItemsSport': 'sport/getItems',
                'getProducers': 'producers/getList',
                'getPlatformId': 'rules/getPlatformId',
                'getSkins': 'skins/getList',
                'getMarketsMini': 'utils/getMarketsMini'
            }),

            destinationPlatforms() {

                const destPlatform = this.getSkins.filter(skin => {
                    return skin.platform_id !== this.getPlatformId
                })

                return destPlatform;
            }

        },
        async created() {
            await this.setPreload(true);
            await this.fetchSports({'language': this.getLanguage});
            if (this.searchProducer != -1 && this.searchSport != -1) {
                await this.getSettingsGroup();
            }
            await this.setPreload(false);

            if (_.size(this.getSkins) > 0) {
                this.listPlatform = _.filter(this.getSkins, (el) => {
                    return el.platform_id != 0
                })
            }
            //console.log("this.listPlatform",this.listPlatform)
        },

        mounted() {
            this.marketsList = this.getMarketsMini.markets
        },

        methods: {

            ...mapActions({
                'fetchSports': 'sport/fetchSports',
                'filterSport': 'sport/filterSport',
                'setPreload': 'utils/setPreload',
                'createNewGroupOddsV2': 'utils/createNewGroupOddsV2',
                'fetchSettingsGroupsMarketV2': 'utils/fetchSettingsGroupsMarketV2',
                'updateMarketGroup': 'utils/updateMarketGroup',
                'deleteMarketGroup': 'utils/deleteMarketGroup',
                'getSportFrom_IdSport': 'sport/getSportFrom_IdSport',
                'copyMarketsGroupsWithOutcomeSettings': 'settings/copyMarketsGroupsWithOutcomeSettings'
            }),

            ...mapMutations({
                'setLanguage': 'profile/setLanguage',
            }),

            getData() {
                if (this.searchProducer != -1 && this.searchSport != -1) {
                    this.dataGroups = {}
                    this.getSettingsGroup();
                }
            },

            async getSettingsGroup() {

                await this.setPreload(true);

                const sport = await this.getSportFrom_IdSport({_id: this.searchSport});
                const sportId = sport.id;

                const marketsGroups = await this.fetchSettingsGroupsMarketV2({
                    'sportId': sportId,
                    'platformId': this.getPlatformId,
                    "type": this.getProducers[this.searchProducer].name == 'Prematch & Antepost' ? 'prematch' : 'live',
                });

                this.dataGroups = []

                if (marketsGroups != null && marketsGroups.length > 0) {
                    this.dataGroups = [];
                    this.dataGroups = _.orderBy(marketsGroups, 'sort', 'asc');
                }

                this.sort = this.dataGroups.length + 1;

                await this.setPreload(false);
            },

            async removeFromGroupV2(marketsGroup) {
                const messageWarning = this.$t('generic.areYouSureToDeleteThisMarketGroup')
                let isExecuted = confirm(messageWarning);
                if(isExecuted === true){
                    await this.setPreload(true);
                    await this.deleteMarketGroup({_id: marketsGroup._id});
                    await this.getSettingsGroup();
                    await this.setPreload(false);
                }
            },

            getNameTranslated(array) {
                let foundTranslation = _.find(array.languages, {'lang': this.getLanguage})
                if (foundTranslation) {
                    return foundTranslation.text
                } else {
                    return array.name
                }
            },

            async createNewGroupV2() {
                await this.setPreload(true);

                let valid = true;

                for (const language of this.languages) {
                    if (language.text == null || language.text == '') {
                        valid = false;
                    }
                }

                if (this.newGroupName == null || this.newGroupName == '') {
                    valid = false;
                }else if(this.newGroupName.includes('-')){
                    valid = false;
                    // alert('The group name cannot contain the character -')
                    alert(this.$t('groupNameCannotContainChar-'))
                }



                const sport = await this.getSportFrom_IdSport({_id: this.searchSport});
                const sportId = sport.id;

                if (valid) {
                    const payload = {
                        'platformId': this.getPlatformId,
                        'sportId': sportId,
                        "type": this.getProducers[this.searchProducer].name == 'Prematch & Antepost' ? 'prematch' : 'live',
                        "name": this.newGroupName,
                        "sort": this.sort,
                        "languages": JSON.stringify(this.languages)
                    };
                    await this.createNewGroupOddsV2(payload);
                    await this.getSettingsGroup();
                    this.newGroupName = '';
                    this.resetLanguages();
                } else {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: this.$t('fillAllRequiredFields')
                    })
                }
                await this.setPreload(false);
            },
            async updateGroupMarket(marketGroup) {

                await this.setPreload(true);

                let valid = true;

                for (const language of marketGroup.languages) {
                    if (language.text == null || language.text == '') {
                        valid = false;
                    }
                }
                if (valid) {
                    await this.updateMarketGroup(marketGroup);
                    await this.getSettingsGroup();
                } else {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: this.$t('fillAllRequiredFields')
                    })
                }
                await this.setPreload(false);
            },
            getTranslatedValueFromMarketGroup(marketGroup, language) {

                if (marketGroup.languages != null) {
                    let translationFound = marketGroup.languages.find(translatedLang => {
                        return translatedLang.lang == language
                    })

                    if (translationFound != null) {
                        return translationFound.text
                    } else {
                        return 'failed'
                    }
                } else {
                    return 'no translations'
                }
            },
            updateTranslation(event, group_content, lang) {

                let foundTranslation = group_content.languages.find(translatedLang => {
                    return translatedLang.lang == lang
                })

                if (foundTranslation != null) {
                    foundTranslation.text = event
                }
            },
            async copyMarketGroup() {

                const messageWarning = this.$t('generic.confirmWarningMessageForCopySettingsMarketsGroups')
                let isExecuted = confirm(messageWarning);

                if (isExecuted === true) {

                    const sourcePlatformId = this.getPlatformId;
                    const platformIdToSave = this.destinationPlatformId;

                    if(platformIdToSave === 0){
                        const messageWarningManagerPlatform = this.$t('generic.confirmWarningMessageManagerForCopySettingsMarketsGroups')
                        let isExecutedManagerConfirm = confirm(messageWarningManagerPlatform);
                        if(isExecutedManagerConfirm === false){
                            return
                        }
                    }

                    const type = this.searchProducer;

                    if (sourcePlatformId == null || sourcePlatformId === '' || platformIdToSave == null ||  platformIdToSave === '') {
                        console.warn('No sourcePlatformId or platformIdDestination selected');
                        return
                    }

                    const requestPayload = {
                        sourcePlatformId: sourcePlatformId,
                        platformIdToSave: platformIdToSave
                    }
                    const response = await this.copyMarketsGroupsWithOutcomeSettings(requestPayload);

                    if (response && response.data && response.data.success && response.data.success === true) {
                        this.$swal.fire(this.$t('successOperation'), '', 'success')
                    } else {
                        this.$swal.fire(this.$t('operationNotPerformedCorrectly'), '', 'error');
                    }
                }
            },
            resetLanguages(){
                this.languages = [
                    {
                        lang: 'it',
                        text: ''
                    },
                    {
                        lang: 'en',
                        text: ''
                    },
                    {
                        lang: 'es',
                        text: ''
                    },
                    {
                        lang: 'pt',
                        text: ''
                    },
                    {
                        lang: 'fr',
                        text: ''
                    },
                    {
                        lang: 'tr',
                        text: ''
                    },
                    {
                        lang: 'cn',
                        text: ''
                    },
                    {
                        lang: 'de',
                        text: ''
                    }
                ]
            }
        },
    }
</script>

<style scoped>

</style>