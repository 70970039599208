<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md12>
          <v-col cols="12">
            <span class="title-table" v-if="Object.keys(mixed).length > 0">{{ $t('prematch') | capitalize}} & {{ $t('bets.live') | capitalize}}</span>

            <v-card outlined elevation-0 class="mt-1" v-for="(match, name, index) in mixed" v-if="Object.keys(mixed).length > 0">

              <div class="" style="background: #dedede;">
                <span class="event-desc">
                  <v-icon @click="searchOnGoogle(match.bets[0].odds.event_description+' - '+new Date(match.bets[0].odds.time).toLocaleString())">mdi-card-search-outline</v-icon>
                  {{ match.bets[0].odds.event_description }} |
                  {{ new Date(match.bets[0].odds.time).toLocaleString() }} |
                  {{match.bets[0].odds.sport.name}} <v-icon x-small>mdi-chevron-right</v-icon>
                  {{match.bets[0].odds.category.name}} <v-icon x-small>mdi-chevron-right</v-icon>
                  {{match.bets[0].odds.tournament.name}}
                  <v-icon x-small>mdi-chevron-right</v-icon> {{match.bets[0].bet_context}}
                  <v-icon x-small>mdi-chevron-right</v-icon> Id: {{match.bets[0].odds.event_source}}

                </span>
              </div>
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="odd in uniqueArray(match.bets)" :key="odd._id">

                        <td width="40%">
                          <span class="info-label">

                          <v-badge dot inline v-if="odd.odds.type == 'live'" color="red">Live</v-badge>
                          [{{ $t('markets') | capitalize}}]


                          {{ odd.odds.market_name }} {{getSpread(odd.odds.market_unique_id)}}

                          </span>
                        </td>
                        <td width="40%"><span class="info-label">[{{ $t('sign') | capitalize}}]</span> {{ (odd.odds.outcome_alias) ? odd.odds.outcome_alias : odd.odds.outcome_name }}</td>
                        <td width="20%">

                          <v-icon :title="$t('bets.defineResult')" @click="defineResult(odd.odds.event_source, odd.odds.unique_id, odd.odds.event_description, odd.odds.outcome_alias, odd.odds.market_name)">mdi-auto-fix</v-icon>
                          <v-icon :title="$t('bets.recoveryOddsResult')" @click="recoverySettlement(odd.odds.event_id)">mdi-backup-restore</v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
            </v-card>
            <div class="text-center pt-3 pb-3">

              <v-pagination
                      v-model="page"
                      :length="Math.ceil(total / limit)"
                      :total-visible="20"
                      @input="fetchOddsNotDefinedPaginate()"
              ></v-pagination>
            </div>
            <v-col cols="12">

              <span class="title-table" v-if="Object.keys(antepost).length > 0">{{ $t('antepost') | capitalize}} </span>

              <v-card outlined elevation-0 class="mt-1" v-for="(match, name, index) in antepost" v-if="Object.keys(antepost).length > 0">

                <div class="" style="background: #dedede;">
                <span class="event-desc">
                  <v-icon @click="searchOnGoogle(match.bets[0].odds.event_description+' - '+new Date(match.bets[0].odds.time).toLocaleString())">mdi-card-search-outline</v-icon>
                  {{ match.bets[0].odds.event_description }} |

                  {{match.bets[0].odds.sport.name}} <v-icon x-small>mdi-chevron-right</v-icon>
                  {{match.bets[0].odds.category.name}} <v-icon x-small>mdi-chevron-right</v-icon>
                  {{match.bets[0].odds.tournament.name}}
                  <v-icon x-small>mdi-chevron-right</v-icon> {{match.bets[0].bet_context}}
                  <v-icon x-small>mdi-chevron-right</v-icon> Id: {{match.bets[0].odds.event_source}}

                </span>
                </div>
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                    <tr v-for="odd in uniqueArray(match.bets)" :key="odd._id">

                      <td width="40%">
                          <span class="info-label">

                          <v-badge dot inline v-if="odd.odds.type == 'live'" color="red">Live</v-badge>
                          [{{ $t('markets') | capitalize}}]


                          {{ odd.odds.market_name }} {{getSpread(odd.odds.market_unique_id)}}

                          </span>
                      </td>
                      <td width="40%"><span class="info-label">[{{ $t('sign') | capitalize}}]</span> {{ (odd.odds.outcome_alias) ? odd.odds.outcome_alias : odd.odds.outcome_name }}</td>
                      <td width="20%">
                        <v-icon :title="$t('bets.defineResult')" @click="defineResult(odd.odds.event_source, odd.odds.unique_id, odd.odds.event_description, odd.odds.outcome_alias, odd.odds.market_name)">mdi-auto-fix</v-icon>
                        <v-icon :title="$t('bets.recoveryOddsResult')" @click="recoverySettlement(odd.odds.event_id)">mdi-backup-restore</v-icon>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
              <div class="text-center pt-3 pb-3">
                <v-pagination
                        v-model="pagepending"
                        :length="Math.ceil(totalpending / limit)"
                        :total-visible="20"
                        @input="fetchOddsNotDefinedPaginate()"
                ></v-pagination>
              </div>
            </v-col>
            <!--<br/>
            <span class="title-table" style="margin-top: 10%" v-if="Object.keys(antepost).length > 0">{{ $t('antepost') | capitalize}} ( {{Object.keys(antepost).length}} )<v-icon @click="displayAntepost = !displayAntepost">mdi-magnify-plus</v-icon></span>
            <v-card outlined elevation-0 class="mt-1" v-for="(match, name, index) in antepost" v-if="Object.keys(antepost).length > 0 && displayAntepost">
              <div class="" style="background: #dedede;">
                <span class="event-desc">
&lt;!&ndash;                  <v-icon @click="searchOnGoogle(match[0].event_description+' - '+new Date(match[0].time).toLocaleString())">mdi-card-search-outline</v-icon>&ndash;&gt;
                  {{match[0].sport}}  <v-icon x-small>mdi-chevron-right</v-icon> {{match[0].category}}  <v-icon x-small>mdi-chevron-right</v-icon> {{match[0].tournament}}
                   <v-icon x-small>mdi-chevron-right</v-icon> Id: {{match[0].event_id}}
                </span>
              </div>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                  <tr v-for="odd in match" :key="odd._id">
                    <td width="40%"><span class="info-label">[{{ $t('markets') | capitalize}}]</span> {{ odd.market_name }} {{getSpread(odd.market_unique_id)}}</td>
                    <td width="40%"><span class="info-label">[{{ $t('sign') | capitalize}}]</span> {{ odd.outcome_alias }}</td>
                    <td width="20%">
                      <v-icon :title="$t('bets.defineResult')" @click="defineResult(odd.event_id, odd.unique_id, odd.event_description, odd.outcome_alias, odd.market_name)">mdi-auto-fix</v-icon>
                      <v-icon :title="$t('bets.recoveryOddsResult')" @click="recoverySettlement(odd.event_id)">mdi-backup-restore</v-icon></td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>-->
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>
    <v-btn v-if="getPlatform==0"
            fab
            bottom
            right
            color="secondary"
            dark
            fixed
            @click.prevent="defineAll"
    >
      <v-icon>mdi-clipboard-check-outline</v-icon>
    </v-btn>
    <div class="text-center">
      <v-dialog v-model="dialogDefineSign" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{$t('bets.defineResult')}}
          </v-card-title>

          <v-card-text class="pt-5">
            <p class="font-weight-bold">{{this.descriptionToDefine}}</p>
            <v-select
                :items="results"
                item-text="name"
                item-value="value"
                filled
                v-model="resultToDefine"
            ></v-select>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions v-if="resultToDefine != ''">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="sendResult()">
              {{ $t('generic.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';


export default {
  name: "OddsNotDefined",
  data() {
    return {
      antepost: [],
      mixed: [],
      total:0,
      totalpending:0,
      skip:0,
      limit:10,
      page:1,
      pagepending:1,
      results:[
        {'name': this.$t('generic.selectOption'), 'value':''},
        {'name': this.$t('bets.win'), 'value':'win'},
        {'name': this.$t('bets.lose'), 'value':'lose'},
        {'name': this.$t('bets.void'), 'value':'void'},
        {'name': this.$t('bets.cancelled'), 'value':'cancelled'},
        {'name': this.$t('bets.halfLost'), 'value':'halfLost'},
        {'name': this.$t('bets.halfWon'), 'value':'halfWon'},
      ],
      dialogDefineSign: false,
      signToDefine:{},
      resultToDefine: "",
      descriptionToDefine: "",
      displayAntepost: false
    }
  },
  computed: {
    ...mapGetters({
      'getLanguage': 'profile/getLanguage',
      'getToken': "profile/getToken",
      'getPlatform': "profile/getPlatform",
      'getPlatformId': "rules/getPlatformId",
      'getOptions': 'bet/getOptions',
      'getUrlApi': 'settings/getUrl',
      'getBetApiUrl': 'settings/getBetApiUrl',
    }),

  },

  async created() {
    await this.setPreload(true);
    //await this.fetchOddsNotDefined()
    this.skip=0
    this.skippending=0
    this.page=1
    this.pagepending=1
    await this.fetchOddsNotDefinedPaginate()
    await this.fetchOddsNotDefinedPendingPaginate()
    await this.setPreload(false);
  },
  methods: {
    ...mapActions({
      'setPreload': 'utils/setPreload',
      'recoveryBetSettlement': 'bet/recoveryBetSettlement',
      'defineOdd': 'bet/defineOdd',
    }),
    ...mapMutations({
      'setLanguage': 'profile/setLanguage',
    }),
    getNewPage(){
      //console.log("PAGE",this.page)
    },
    async defineAll() {

      await this.setPreload(true);
      await this.fetchOddsNotDefinedPaginate()
      await this.fetchOddsNotDefinedPendingPaginate()
      this.setPreload(false);
      /*let url = `${this.getUrlApi}bet/check-odds-not-defined`;
      axios.post(`${url}`, {}, {'headers': {'Authorization': `Bearer ${this.getToken}`}}).then(async(result) => {
          this.skip=0

          await this.fetchOddsNotDefinedPaginate()
          await this.fetchOddsNotDefinedPendingPaginate()
         this.setPreload(false);

      })*/

    },
    uniqueArray(array){

      array = _.uniqBy(array, 'odds.id_outcome');
      return array
      //_.uni

    },
    async fetchOddsNotDefined() {

      var vue = this
      var mixed = []
      var mixed_sources = []
      var antepost = []
      var antepost_sources = []

      let url = `${this.getBetApiUrl}bet/odds-not-defined`;
      axios.post(`${url}`, {}, {'headers': {'Authorization': `Bearer ${this.getToken}`}}).then((result) => {

        _.forEach(result.data.odds, (odd) => {

          var oddObj = JSON.parse(JSON.stringify(odd))
          switch (oddObj.type) {
            case "antepost":
              if (!antepost_sources.includes(oddObj.unique_id)) {
                antepost.push(oddObj)
                antepost_sources.push(oddObj.unique_id)
              }
              break
            case "live":
            case "prematch":
              if (!mixed_sources.includes(oddObj.unique_id)) {
                mixed.push(oddObj)
                mixed_sources.push(oddObj.unique_id)
              }
              break
          }
        })

        var mixed_ordered = _.orderBy(mixed, ['time'], ['asc'])

        vue.mixed = _.groupBy(mixed_ordered, (item) => {
          return [item['event_id'], item['time']];
        });

        // vue.mixed = _.groupBy(mixed_ordered, ['event_id','time']);
        vue.antepost = _.groupBy(antepost, 'event_id');


      })

    },
    async fetchOddsNotDefinedPendingPaginate() {

      var vue = this
      var mixed = []
      var mixed_sources = []
      var antepost = []
      var antepost_sources = []
      this.skippending=(this.pagepending-1)*this.limit
      let url = `${this.getBetApiUrl}bet/odds-not-defined-antepost-paginate`;
      axios.post(`${url}`, {skip:this.skippending,limit:this.limit}, {'headers': {'Authorization': `Bearer ${this.getToken}`}}).then((result) => {

        vue.antepost = result.data.odds[0].paginateResult
        this.totalpending = result.data.odds[0].totalCount[0].count
        /*_.forEach(result.data.odds, (odd) => {

          var oddObj = JSON.parse(JSON.stringify(odd))
          switch (oddObj.type) {
            case "antepost":
              if (!antepost_sources.includes(oddObj.unique_id)) {
                antepost.push(oddObj)
                antepost_sources.push(oddObj.unique_id)
              }
              break
            case "live":
            case "prematch":
              if (!mixed_sources.includes(oddObj.unique_id)) {
                mixed.push(oddObj)
                mixed_sources.push(oddObj.unique_id)
              }
              break
          }
        })

        var mixed_ordered = _.orderBy(mixed, ['time'], ['asc'])

        vue.mixed = _.groupBy(mixed_ordered, (item) => {
          return [item['event_id'], item['time']];
        });

        // vue.mixed = _.groupBy(mixed_ordered, ['event_id','time']);
        vue.antepost = _.groupBy(antepost, 'event_id');

      */
      })

    },
    async fetchOddsNotDefinedPaginate() {

      var vue = this
      var mixed = []
      var mixed_sources = []
      var antepost = []
      var antepost_sources = []
      this.skip=(this.page-1)*this.limit
      let url = `${this.getBetApiUrl}bet/odds-not-defined-paginate`;
      axios.post(`${url}`, {skip:this.skip,limit:this.limit}, {'headers': {'Authorization': `Bearer ${this.getToken}`}}).then((result) => {

        vue.mixed = result.data.odds[0].paginateResult
        this.total = result.data.odds[0].totalCount[0].count
        /*_.forEach(result.data.odds, (odd) => {

          var oddObj = JSON.parse(JSON.stringify(odd))
          switch (oddObj.type) {
            case "antepost":
              if (!antepost_sources.includes(oddObj.unique_id)) {
                antepost.push(oddObj)
                antepost_sources.push(oddObj.unique_id)
              }
              break
            case "live":
            case "prematch":
              if (!mixed_sources.includes(oddObj.unique_id)) {
                mixed.push(oddObj)
                mixed_sources.push(oddObj.unique_id)
              }
              break
          }
        })

        var mixed_ordered = _.orderBy(mixed, ['time'], ['asc'])

        vue.mixed = _.groupBy(mixed_ordered, (item) => {
          return [item['event_id'], item['time']];
        });

        // vue.mixed = _.groupBy(mixed_ordered, ['event_id','time']);
        vue.antepost = _.groupBy(antepost, 'event_id');

      */
      })

    },
    async defineResult(eventId, unique_id, desc, alias, market){
      //console.log(eventId, unique_id, desc, alias, market)
      await this.setPreload(true);
      this.resultToDefine = ''
      this.signToDefine = {
        event_id: eventId,
        odd_unique_id: unique_id,
      }
      if(desc == undefined){
        desc = market
      }
      this.descriptionToDefine = desc + ' - '+ alias
      this.dialogDefineSign = true
      await this.setPreload(false);
    },

    async recoverySettlement(eventId){
      await this.setPreload(true);
      await this.recoveryBetSettlement({event_id : eventId})
      await this.setPreload(false);
    },

    async sendResult(){
      await this.setPreload(true);
      this.signToDefine.result = this.resultToDefine
      const result = await this.defineOdd(this.signToDefine)

      setTimeout(async () =>{

        if(result){
          this.dialogDefineSign = false
          await this.fetchOddsNotDefinedPaginate()
        }

        await this.setPreload(false);

      }, 1500);

    },
    searchOnGoogle(key){
      let url = 'https://www.google.com/search?q='+key
      window.open(url, '_blank').focus()
    },

    getSpread(spec) {
      if(spec.includes('#')){
        var split = spec.split('#')
        if(split[1].includes(':')){
          return '('+split[1]+')'
        }else if(split[1].includes('(') && split[1].includes('.')){
          return ' ['+split[1]+'] '
        }else if(split[1] != ''){
          return split[1]
        }else {
          return ''
        }
      }else {
        return ''
      }
    }


  },
}
</script>

<style scoped>
.event-desc{
  font-size: 14px;
  padding-left: 10px;
  font-weight: 700;
}
.info-label{
  font-size: 11px;
  color: #888888;
}
</style>
