<template>
    <div id="marketViewSettings">
        <v-container fluid>
            <v-layout row>
                <v-flex md12>
                    <v-col cols="12">
                        <span class="title-table">{{ 'Select Component' | capitalize}}</span>
                        <v-card outlined elevation-0 style="padding: 14px">


                            <v-btn class="actions-button" depressed color="primary"
                                   @click="switchToOutcomeSettingsComponent()">{{ this.$t('outcomeSettings') |
                                capitalize}}
                            </v-btn>

                            <v-btn class="actions-button" depressed
                                   color="primary" @click="switchToMarketGroupComponent()">{{ this.$t('marketsGroups') |
                                capitalize}}
                            </v-btn>

                            <v-btn class="actions-button" depressed
                                   color="primary" @click="switchToLiveSettingInitialize">{{ this.$t('initializeLive') |
                                capitalize}}
                            </v-btn>

                        </v-card>
                    </v-col>

                    <v-col cols="12">
                        <span class="title-table">{{ this.$t('generic.oddsGroup') | capitalize}}</span>
                        <v-card outlined elevation-0>
                            <manage-market-group v-if="enableManageMarketGroup"></manage-market-group>
                            <outcome-settings-for-market-group
                                    v-if="enableOutcomeSettingsForMarketGroup"></outcome-settings-for-market-group>
                            <initialize-live-settings v-if="enableComponentInitializeLive"></initialize-live-settings>

                        </v-card>
                    </v-col>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>
<style>

</style>
<script>
    import {mapActions, mapGetters} from 'vuex';
    import ManageMarketGroup from '../../components/betConsole/manageMarketGroup'
    import OutcomeSettingsForMarketGroup from "../../components/betConsole/outcomeSettingsForMarketGroup";
    import InitializeLiveSettings from "../../components/betConsole/initializeLiveSettings"

    export default {
        name: "MarketViewSettingV2",
        components: {ManageMarketGroup, OutcomeSettingsForMarketGroup, InitializeLiveSettings},
        data() {
            return {
                enableManageMarketGroup: true,
                enableOutcomeSettingsForMarketGroup: false,
                enableComponentInitializeLive: false
            }
        },

        watch: {},

        computed: {
            ...mapGetters({}),

        },
        methods: {
            ...mapActions({}),
            switchComponent() {
                this.enableManageMarketGroup = !this.enableManageMarketGroup;
                this.enableOutcomeSettingsForMarketGroup = !this.enableOutcomeSettingsForMarketGroup;
            },
            switchToLiveSettingInitialize() {
                this.enableManageMarketGroup = false;
                this.enableOutcomeSettingsForMarketGroup = false;
                this.enableComponentInitializeLive = true;

            },
            switchToMarketGroupComponent() {
                this.enableManageMarketGroup = true;
                this.enableOutcomeSettingsForMarketGroup = false;
                this.enableComponentInitializeLive = false;

            },
            switchToOutcomeSettingsComponent() {
                this.enableComponentInitializeLive = false;
                this.enableManageMarketGroup = false;
                this.enableOutcomeSettingsForMarketGroup = true;
            }
        },
    }
</script>

<style scoped>

    .actions-button {
        padding: 10px;
        margin-right: 6px;
    }
</style>
